import { useUserContext } from "Authentication";
import { DatabaseRecipe, RecipeModel } from "Recipes";
import {
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  writeBatch,
  collection,
} from "firebase/firestore";
import toast, { Toaster } from "react-hot-toast";
import { db } from "Config";

export function useRecipes() {
  const { getRecipesCollection, user } = useUserContext();
  const recipesCollection = getRecipesCollection();

  function recipeToDbRecipe(recipe: RecipeModel) {
    const dbRecipe: DatabaseRecipe = {
      name: recipe.name,
      days: recipe.days,
      tags: recipe.tags,
      directions: recipe.directions,
      recipeUrl: recipe.recipeUrl,
      ingredients: recipe.ingredients,
    };
    return dbRecipe;
  }

  async function addRecipe(recipe: RecipeModel) {
    const dbRecipe = recipeToDbRecipe(recipe);
    await addDoc(recipesCollection, dbRecipe);
  }

  async function deleteRecipe(id: string) {
    await deleteDoc(doc(recipesCollection, id));
  }

  async function updateRecipe(recipe: RecipeModel) {
    const dbRecipe = recipeToDbRecipe(recipe);
    //@ts-ignore
    await updateDoc(doc(recipesCollection, recipe.id), dbRecipe);
  }

  async function loadDefaultRecipes(recipes: RecipeModel[]) {
    const batchRecipes = writeBatch(db);

    recipes.forEach((recipe) => {
      const recipeRef = doc(recipesCollection, recipe.id);
      batchRecipes.set(recipeRef, recipe);
    });

    await batchRecipes.commit();
    toast("Classic Recipes Added", {
      duration: 2000,
      position: "top-center",
      style: {
        backgroundColor: "lightgreen",
        border: "1px solid #713200",
        fontSize: "16px",
        padding: "10px",
        color: "black",
      },
    });
  }

  async function assignRecipe(id: string, days: number[]) {
    await updateDoc(doc(recipesCollection, id), {
      days,
    });
  }

  async function assignManualItems(itemsByDay: Record<number, string[]>) {
    await updateDoc(doc(collection(db, "groups"), user?.group?.id), {
      itemsByDay: itemsByDay,
    });
  }

  async function updateManualItems(itemsByDay: Record<number, string[]>) {
    await updateDoc(doc(recipesCollection, "items"), {
      itemsByDay,
    });
  }

  function getAssignedRecipes(recipes: RecipeModel[]) {
    return recipes.filter((r) => r.days && r.days.length > 0);
  }

  async function unassignRecipes(ids: string[]) {
    const batch = writeBatch(db);

    for (const id of ids) {
      const ref = doc(recipesCollection, id);
      batch.update(ref, { days: [] });
    }

    await batch.commit();
  }

  return {
    addRecipe,
    deleteRecipe,
    loadDefaultRecipes,
    assignRecipe,
    getAssignedRecipes,
    unassignRecipes,
    updateRecipe,
    assignManualItems,
  };
}

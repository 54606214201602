import { useState } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, ModalProps } from "Common";
import { ItemModel, useItems } from "Lists";
import classes from "./QuickAddModal.module.css";
import toast, { Toaster } from "react-hot-toast";

export interface QuickAddModalProps extends ModalProps {
  items: ItemModel[];
}

export const QuickAddModal = (props: QuickAddModalProps) => {
  const { isOpen, items, onRequestClose } = props;
  const { addItem } = useItems();
  const getTopItems = (items: ItemModel[]) =>
    items
      .filter((i) => (i.added ? i.completed && i.isFavorite : i.isFavorite))
      .sort((a, b) => a.name.localeCompare(b.name))
      .slice(0, 25);

  const [topItems, setTopItems] = useState(getTopItems(items));

  const handleClickAdd = async (itemName: string) => {
    setTopItems((items) => items.filter((i) => i.name !== itemName));
    await addItem(itemName);
    toast("Item Added", {
      duration: 2000,
      position: "top-center",
      style: {
        backgroundColor: "lightgreen",
        border: "1px solid #713200",
        fontSize: "16px",
        padding: "10px",
        color: "black",
      },
    });
  };

  const ReloadData = () => {
    if (isOpen) {
      setTopItems(getTopItems(items));
      //console.log("did this work?")
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onAfterOpen={ReloadData}
      onAfterClose={ReloadData}
      style={{ overlay: { zIndex: 100 } }}
    >
      <Modal.Body>
        <div style={{ paddingLeft: 10, paddingRight: 10 }}>
          <Modal.Header>
            <h2>Quick Add</h2>
          </Modal.Header>
          {/*}   <span>Quickly add your favorite items. </span>*/}
          <div className={classes.items}>
            {topItems.length === 0 ? (
              <span>
                <p>You have no favorite items to add. </p>{" "}
                <p>
                  To add a favorite item, please select any item in your list,
                  then select the favorite star below the notes section.
                </p>{" "}
              </span>
            ) : null}
            {topItems.map((i, index) => (
              <div key={index} style={{ padding: "5px 0" }}>
                <Button
                  onClick={() => handleClickAdd(i.name)}
                  className={classes.add}
                >
                  <FontAwesomeIcon icon={faPlus} size="lg" />
                </Button>
                <span title={`Added ${i.count} times`} className={classes.name}>
                  {i.name}
                </span>
              </div>
            ))}
          </div>

          <Button
            mode="primary"
            style={{ fontSize: 16, padding: 10 }}
            onClick={onRequestClose}
          >
            Close
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

import React from "react";
import { Link, useLocation } from "react-router-dom";

export const Breadcrumb = ({ color }) => {
  const location = useLocation();
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");

  // Create an array of breadcrumb items
  const breadcrumbs = pathSegments.map((segment, index) => {
    const path = `/${pathSegments.slice(0, index + 1).join("/")}`;
    return (
      <span key={segment}>
        <Link
          to={path}
          style={{
            color: "blue",
            textDecoration: "underline",
            fontSize: 14,
            textTransform: "capitalize",
          }}
        >
          {segment}
        </Link>
        {index < pathSegments.length - 1 && " >> "}
      </span>
    );
  });

  return (
    <div
      style={{
        // paddingBottom: isExpired ? 0 : 40,
        width: "100%",
      }}
    >
      <div
        // className="breadcrumb"
        style={{
          position: "fixed",
          top: 30,
          zIndex: 2,
          width: "100%",

          paddingRight: "30px",
          backgroundColor: color,
          // fontStyle: "italic",
          paddingTop: 40,
          paddingBottom: 10,
          paddingLeft: 10,
          paddingRight: 10,

          opacity: 1,
          fontSize: 14,
          fontWeight: "bolder",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          maxWidth: "100%",
          display: "inline-block",
        }}
      >
        You are here: <span style={{ paddingRight: 2 }} />{" "}
        <Link
          to="/"
          style={{ color: "blue", textDecoration: "underline", fontSize: 14 }}
        >
          Home
        </Link>
        {" >> "}
        {breadcrumbs}
      </div>
    </div>
  );
};

import React, {
  useState,
  SetStateAction,
  Dispatch,
  useRef,
  useEffect,
} from "react";
import {
  faClose,
  faPen,
  faPenToSquare,
  faPlus,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Button, Modal } from "Common";
import { ItemModel } from "Lists";
import { useItemSubscriptionToDo } from "ListToDo/useItemSubscriptionToDo";
import { ListItemToDo } from "../../ListItem/ListItemToDo";
import { CategoryEditModalToDo } from "../../CategoryModal/CategoryEditModalToDo";
import classes from "./Category.module.css";
import { SelectRef } from "../../../../ListToDo/List/AddItem";
//import { AddItemToDoCat } from "ListToDo/List/AddItem/AddItemToDoCat";
import { AddItemToDo } from "../../../../ListToDo/List/AddItem";
import { useItemsToDo } from "ListToDo/useItemsToDo";
import { useTranslation } from "react-i18next";

export interface CategoryItems {
  items: ItemModel[];
  id?: string | undefined;
  name: string;
  colour: string;
  order: number;
  isDarkMode: boolean;
}

export interface CategoryProps {
  category: CategoryItems;
  showAllCat: boolean;
  onClickAddCategoryItem: (id: string | undefined) => void;
  onClickItem: (item: ItemModel) => void;
  onClickItemCategory: (item: ItemModel) => void;
  isDarkMode: boolean;
}

const SortableItem = SortableElement(
  ({ value, category, onClickItem, onClickItemCategory }) => (
    <div
      style={{
        paddingBottom: 10,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        border: 1,
        borderStyle: "dashed",
        borderColor: "rgba(128, 128, 128, 0.6)",
        backgroundColor: value.colour,
        //  color: "white",
      }}
    >
      <ListItemToDo
        key={value.id}
        item={value}
        category={category}
        onClickCategory={() => onClickItemCategory(value)}
        onClickItem={() => onClickItem(value)}
      />
    </div>
  )
);

const SortableList = SortableContainer(
  ({ items, onClickItem, onClickItemCategory, category }) => {
    const min = 1000;
    const max = 100000;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    const filteredItems = items.filter((item) => item.name !== undefined);
    filteredItems.sort((a, b) => a.sortIndex - b.sortIndex);

    return (
      <div>
        {filteredItems.map((value, index) => (
          <SortableItem
            key={`item-${value.id}`}
            index={index}
            value={value}
            onClickItemCategory={onClickItemCategory}
            onClickItem={onClickItem}
            category={category}
          />
        ))}
      </div>
    );
  }
);

export const Category = (props: CategoryProps) => {
  const {
    category,
    onClickAddCategoryItem,
    onClickItem,
    onClickItemCategory,
    showAllCat,
    isDarkMode,
  } = props;
  const { id, colour, items, name } = category;
  const [isCatEditModalOpen, setIsCatEditModalOpen] = useState(false);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [isAddItemOpen, setIsAddItemOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [selectedCategory, setSelectedCategoryx] = useState(id);
  const [selectedCategoryName, setSelectedCategoryNamex] = useState(name);
  const [selectedCategoryxx, setSelectedCategory] = useState(id);
  const [selectedCategoryNamexx, setSelectedCategoryName] = useState(name);
  const inputRef = useRef<SelectRef>(null);
  const { addedItemsToDo, allItemsToDo } = useItemSubscriptionToDo();
  const [showAllCategory, setShowAllCategory] = useState(true);
  const [isAddingNewItem, setIsAddingNewItem] = useState(false);
  const [isOpenItems, setIsOpenItems] = useState(
    localStorage.getItem(`iscattodo-${name}`) != undefined
      ? JSON.parse(localStorage.getItem(`iscattodo-${name}`))
      : true
  );
  const close = () => {
    setIsAddItemOpen(false);
  };
  const { t } = useTranslation();

  const Randomposition = Math.round(Math.random()) * 0.5 - 0.25;
  // const [newSortItems, setNewSortItems] = useState([]);

  const hanldeShowItems = () => {
    // console.log("click");
    if (isOpenItems == true) {
      setIsOpenItems(false);
      localStorage.setItem(`iscattodo-${name}`, "false");
    } else {
      setIsOpenItems(true);
      localStorage.setItem(`iscattodo-${name}`, "true");
    }
  };

  const { removeItem, setSortIndex } = useItemsToDo();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    // Create a copy of the items array
    //Check to make sure there is more then 1 item
    if (resetDB.current.length > 1 && oldIndex != newIndex) {
      var updatedItems = [...resetDB.current];

      // Update the copy of the list using arrayMoveImmutable
      const movedItem = updatedItems.splice(oldIndex, 1)[0];
      updatedItems.splice(newIndex, 0, movedItem);

      // Update the order property based on the new index
      const updatedItemsWithNewOrder = updatedItems.map((item, index) => ({
        ...item,
        sortIndex: index, // Assign the order based on the index
      }));

      //console.log("Updated items with new order:", updatedItemsWithNewOrder);

      const filteredItems = updatedItemsWithNewOrder.filter(
        (item) => item !== undefined
      );
      filteredItems.sort((a, b) => a.sortIndex - b.sortIndex);

      filteredItems.forEach((item, index) => {
        // console.log("which item", item);
        if (item.id != undefined) {
          setSortIndex(item.id, item.sortIndex);
        }
      });

      // Set the state using the updated copy of the list with the new order
      resetDB.current = filteredItems;
      //  setNewSortItems(filteredItems);
      // console.log("Did I fire?");
    } else {
      // console.log("Did I skip?");
    }
  };

  const resetDB = useRef(items);
  const sortDBItems = () => {
    // Check and assign a default sortIndex to items with undefined sortIndex
    const min = 1000;
    const max = 100000;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

    resetDB.current.forEach((item) => {
      //@ts-ignore
      if (item.sortIndex === undefined) {
        //@ts-ignore
        item.sortIndex = randomNumber;

        // console.log("which item", item);
        //@ts-ignore
        //   setSortIndex(item.id, item.sortIndex);
        //  console.log("updateing DB");
        // Assign a high value for undefined sortIndex
      }
    });

    // Sort the items based on sortIndex
    //@ts-ignore
    resetDB.current.sort((a, b) => a.sortIndex - b.sortIndex);
    // setNewSortItems(resetDB.current);
  };

  useEffect(() => {
    resetDB.current = items;
    sortDBItems();
    return () => {};
  }, [items]);

  return (
    <>
      {items.length >= 1 || showAllCat ? (
        <div
          className={classes.category}
          // style={{  transform: `rotate(${Randomposition}deg)`, backgroundColor: `${colour}40` }}
          style={{ backgroundColor: `${colour}40` }}
        >
          <div style={{ paddingBottom: 10, flexDirection: "row" }}>
            <div className={classes.categoryHeader}>
              <div>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "dimgray",
                    paddingLeft: 5,
                  }}
                >
                  {name.toLocaleUpperCase()}{" "}
                </span>
              </div>

              <div style={{ minWidth: 85 }}>
                {!isAdding && name != "Priority Items" ? (
                  <button
                    style={{
                      backgroundColor: "transparent",
                      borderStyle: "none",
                      marginTop: -15,
                      marginRight: -5,
                      float: "right",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsCatEditModalOpen(true)}
                  >
                    <FontAwesomeIcon icon={faPenToSquare} size="sm" />
                  </button>
                ) : (
                  <span></span>
                )}
                {/*     <Button style={{paddingLeft: 20}} onClick={() => onClickAddCategoryItem(id)}>
          <FontAwesomeIcon icon={faPlus} size="lg" />
 </Button>*/}{" "}
              </div>
            </div>
          </div>
          <Modal
            isOpen={isAddingNewItem}
            onRequestClose={() => setIsAddingNewItem(false)}
            style={{ overlay: { zIndex: 100 } }}
            //background-color: rgba(255, 255, 255, 0.5); /* Set the desired overlay color and opacity */
          >
            <div style={{ filter: "invert(100%)" }}>
              <AddItemToDo
                addedItemsToDo={addedItemsToDo}
                allItemsToDo={allItemsToDo}
                categoryToDo={id}
                //  categoryNameToDo={selectedCategoryName}
                ref={inputRef}
                setCategoryToDo={setSelectedCategory}
                // setCategoryNameToDo={setSelectedCategory}
                isDarkMode={isDarkMode}
                setIsAddingNewItemCompleted={() => setIsAddingNewItem(false)}
                disableCategorySelect={true}
              />
            </div>
          </Modal>

          <div
            onClick={hanldeShowItems}
            style={{
              fontSize: 14,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span
              style={{
                color: "blue",
                textDecoration: "underline",
                paddingLeft: 5,
              }}
            >
              {!isOpenItems ? "Show" : "Hide"}
            </span>

            <span style={{ fontSize: 14, paddingRight: 5, paddingLeft: 5 }}>
              {" "}
              Total: {items.length}
            </span>
            <span
              onClick={() => setIsAddingNewItem(true)}
              style={{
                // float: "right",
                paddingLeft: 5,
                paddingRight: 5,
                textDecoration: "underline",
                color: "blue",
                cursor: "pointer",
                fontSize: 14,
              }}
            >
              {t("common-button-addnew")}
            </span>
          </div>

          {isOpenItems ? (
            <div>
              <SortableList
                items={resetDB.current}
                onSortEnd={onSortEnd}
                onClickItemCategory={onClickItemCategory}
                onClickItem={onClickItem}
                category={category}
                useWindowAsScrollContainer={true}
                //pressDelay={2}
                useDragHandle
              />
            </div>
          ) : null}
          {isCatEditModalOpen ? (
            <CategoryEditModalToDo
              isOpen={isCatEditModalOpen}
              close={() => setIsCatEditModalOpen(false)}
              isDarkMode={isDarkMode}
            />
          ) : null}
        </div>
      ) : null}
      {name === "Priority Items" ? (
        <div>
          <hr />
          <h4
            style={{
              textAlign: "center",
              marginTop: -4,
            }}
          >
            All Tasks
          </h4>
          <hr
            style={{
              marginTop: -20,
            }}
          />
        </div>
      ) : null}
    </>
  );
};

import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
//import DOMPurify from "dompurify";
//import linkifyHtml from "linkify-html";
//import moment from "moment";
import { ReadRecipeLanding } from "./RecipeModal/Read/ReadRecipeLanding";
import { useTranslation } from "react-i18next";

export const RecipeLanding = ({ data }) => {
  const { productId } = useParams();
  const recipe = data.find((p) => p.id === productId);
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {};
  }, []);

  let productData;

  if (recipe) {
    productData = (
      <div>
        <ReadRecipeLanding recipe={recipe} addedItems={[]} />
        <div style={{ paddingBottom: 150 }} />
      </div>
    );
  } else {
    productData = <h2> Sorry. Recipe doesn't exist </h2>;
  }

  return <div>{productData}</div>;
};

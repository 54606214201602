export const commonGroceryItemsUS = [
  // baby stuff
  { category: "100100", name: "Baby Food" },
  { category: "100100", name: "Baby Wash" },
  { category: "100100", name: "Baby Diapers" },
  { category: "100100", name: "Baby Formula" },
  { category: "100100", name: "Baby Lotion" },
  { category: "100100", name: "Baby Wipes" },
  // Dairy and Eggs
  { category: "100700", name: "Milk" },
  { category: "100700", name: "Eggs" },
  { category: "100700", name: "Butter" },
  { category: "100700", name: "Cheese" },
  { category: "100700", name: "Yogurt" },
  { category: "100700", name: "Cream" },
  { category: "100700", name: "Sour Cream" },
  { category: "100700", name: "Cottage Cheese" },
  { category: "100700", name: "Whipping Cream" },
  { category: "100700", name: "Half and Half" },

  // Produce
  { category: "100900", name: "Apples" },
  { category: "100900", name: "Bananas" },
  { category: "100900", name: "Oranges" },
  { category: "100900", name: "Lettuce" },
  { category: "100900", name: "Tomatoes" },
  { category: "100900", name: "Carrots" },
  { category: "100900", name: "Potatoes" },
  { category: "100900", name: "Onions" },
  { category: "100900", name: "Broccoli" },
  { category: "100900", name: "Cucumbers" },
  { category: "100900", name: "Iceberg Lettuce" },
  { category: "100900", name: "Jalapeno Peppers" },
  { category: "100900", name: "Kiwis" },
  { category: "100900", name: "Leeks" },
  { category: "100900", name: "Lemons" },
  { category: "100900", name: "Limes" },

  // Meat and Poultry
  { category: "101100", name: "Chicken Breast" },
  { category: "101100", name: "Ground Beef" },
  { category: "101100", name: "Pork Chops" },
  { category: "101100", name: "Steak" },
  { category: "101100", name: "Bacon" },
  { category: "101100", name: "Sausages" },
  { category: "101100", name: "Turkey" },
  { category: "101100", name: "Salmon" },
  { category: "101100", name: "Shrimp" },
  { category: "101100", name: "Lamb" },

  // Personal Care
  { category: "101600", name: "Shampoo" },
  { category: "101600", name: "Conditioner" },
  { category: "101600", name: "Body Wash" },
  { category: "101600", name: "Toothpaste" },
  { category: "101600", name: "Deodorant" },
  { category: "101600", name: "Razors" },
  { category: "101600", name: "Feminine Hygiene Products" },
  { category: "101600", name: "Hand Soap" },
  { category: "101600", name: "Lotion" },
  { category: "101600", name: "Toilet Paper" },

  // Baking
  { category: "100300", name: "Flour" },
  { category: "100300", name: "Sugar" },
  { category: "100300", name: "Baking Powder" },
  { category: "100300", name: "Vanilla Extract" },
  { category: "100300", name: "Chocolate Chips" },
  { category: "100300", name: "Cake Mix" },
  { category: "100300", name: "Brown Sugar" },
  { category: "100300", name: "Cocoa Powder" },
  { category: "100300", name: "Shortening" },
  { category: "100300", name: "Cornstarch" },

  // Beverages
  { category: "100400", name: "Water" },
  { category: "100400", name: "Coffee" },
  { category: "100400", name: "Tea" },
  { category: "100400", name: "Soda" },
  { category: "100400", name: "Juice" },
  { category: "100400", name: "Energy Drinks" },
  { category: "100400", name: "Milk Alternatives" },
  { category: "100400", name: "Sports Drinks" },
  { category: "100400", name: "Smoothies" },
  { category: "100400", name: "Alcohol" },

  // Cleaning
  { category: "100500", name: "Dish Soap" },
  { category: "100500", name: "Laundry Detergent" },
  { category: "100500", name: "Paper Towels" },
  { category: "100500", name: "Trash Bags" },
  { category: "100500", name: "All-Purpose Cleaner" },
  { category: "100500", name: "Bathroom Cleaner" },
  { category: "100500", name: "Glass Cleaner" },
  { category: "100500", name: "Broom" },
  { category: "100500", name: "Mop" },
  { category: "100500", name: "Vacuum Cleaner" },

  { category: "100500", name: "Air Freshener" },

  { category: "100500", name: "Bathroom Cleaner" },
  { category: "100500", name: "Bleach" },

  { category: "100500", name: "Disinfectant Spray" },
  { category: "100500", name: "Fabric Softener" },

  { category: "100500", name: "Garbage Bags" },
  { category: "100500", name: "Glass Cleaner" },
  { category: "100500", name: "Laundry Detergent" },

  { category: "100500", name: "Kitchen Sponges" },

  { category: "100500", name: "White Vinegar" },

  // Spices
  { category: "102000", name: "Salt" },
  { category: "102000", name: "Pepper" },
  { category: "102000", name: "Garlic Powder" },
  { category: "102000", name: "Paprika" },
  { category: "102000", name: "Cinnamon" },
  { category: "102000", name: "Cumin" },
  { category: "102000", name: "Chili Powder" },
  { category: "102000", name: "Oregano" },
  { category: "102000", name: "Thyme" },
  { category: "102000", name: "Rosemary" },

  // Snacks
  { category: "101900", name: "Potato Chips" },
  { category: "101900", name: "Chocolate Bars" },
  { category: "101900", name: "Popcorn" },
  { category: "101900", name: "Pretzels" },
  { category: "101900", name: "Nuts" },
  { category: "101900", name: "Trail Mix" },
  { category: "101900", name: "Granola Bars" },
  { category: "101900", name: "Candy" },
  { category: "101900", name: "Cookies" },
  { category: "101900", name: "Dried Fruit" },

  // Frozen
  { category: "100800", name: "Frozen Pizza" },
  { category: "100800", name: "Ice Cream" },
  { category: "100800", name: "Frozen Vegetables" },
  { category: "100800", name: "Frozen Fruits" },
  { category: "100800", name: "Frozen Chicken Nuggets" },
  { category: "100800", name: "Frozen Breakfast Burritos" },
  { category: "100800", name: "Frozen Fish" },
  { category: "100800", name: "Frozen Desserts" },
  { category: "100800", name: "Frozen French Fries" },
  { category: "100800", name: "Frozen Dinners" },

  // Seafood
  { category: "101800", name: "Salmon Fillet" },
  { category: "101800", name: "Shrimp" },
  { category: "101800", name: "Tuna Steak" },
  { category: "101800", name: "Cod" },
  { category: "101800", name: "Crab Legs" },
  { category: "101800", name: "Lobster" },
  { category: "101800", name: "Mussels" },
  { category: "101800", name: "Clams" },
  { category: "101800", name: "Scallops" },
  { category: "101800", name: "Sardines" },

  // Pantry
  { category: "101500", name: "Pasta" },
  { category: "101500", name: "Canned Soup" },
  { category: "101500", name: "Cereal" },
  { category: "101500", name: "Rice" },
  { category: "101500", name: "Canned Vegetables" },
  { category: "101500", name: "Peanut Butter" },
  { category: "101500", name: "Jam" },
  { category: "101500", name: "Honey" },
  { category: "101500", name: "Pasta Sauce" },
  { category: "101500", name: "Olive Oil" },

  // Beverages
  { category: "100400", name: "Sparkling Water" },
  { category: "100400", name: "Energy Drinks" },
  { category: "100400", name: "Iced Tea" },
  { category: "100400", name: "Hot Chocolate" },
  { category: "100400", name: "Lemonade" },
  { category: "100400", name: "Flavored Water" },
  { category: "100400", name: "Sports Drinks" },
  { category: "100400", name: "Fruit Juice" },
  { category: "100400", name: "Smoothies" },
  { category: "100400", name: "Kombucha" },
];

import { useEffect, useState } from "react";
//import firebase from "firebase/app";
import { useUserContext } from "Authentication";
import { db } from "Config";
import { onSnapshot, doc } from "firebase/firestore";

export function useUserSubscription() {
  const [userProfile, setUserProfile] = useState([]);
  // const { getGroupUsersCollection } = useUserContext()

  const user = useUserContext();

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, "users", user.user.id),
      (doc) => {
        const convert = doc.data();
        setUserProfile(convert);
      },
      (error) => {
        window.location.reload();
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return { userProfile };
}

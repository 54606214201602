import React from "react";
import { useState, useEffect } from "react";
import {
  useParams,
  Link,
  Route,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import DOMPurify from "dompurify";
import linkifyHtml from "linkify-html";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { JournalLanding } from "./JournalLanding";
import Linkify from "linkify-react";
import { Search } from "Notes/components/Search";
import { EventsModalAddNew } from "./EventsModalAddNew";

import { DailyReminder } from "Reminders/DailyReminder";

export const JournalLog = ({ data, isExpired, isDarkMode, isOnline }) => {
  const { url } = useRouteMatch();
  const { productId } = useParams();
  const events = data;
  const { t } = useTranslation();
  //add new entry
  const [selectedEvent, setSelectedEvent] = useState({});
  var selectSlotStart = new Date();
  const [isEventsModalAddNewOpen, setIsEventsModalAddNewOpen] = useState(false);

  const history = useHistory();

  const goToJournalCalendar = () => {
    history.push("/journal/calendar");
  };

  const [searchText, setSearchText] = useState("");
  const SearchResults = events.filter((item) => {
    return (
      item.title.toLowerCase().includes(searchText.toLowerCase()) ||
      item.description.includes(searchText.toLowerCase())
    );
  });

  const convertDate = (date) => {
    const update = new Date(date.seconds * 1000);
    const updateFormatted = moment(update).format("MMMM DD, YYYY");
    //  const update = date.toDate()
    return updateFormatted;
  };

  //for reminders
  const [isSchedulingReminder, setIsSchedulingReminder] = useState(false);

  const CloseMenu = () => {
    setIsSchedulingReminder(false);
  };

  const linkList = SearchResults.map((event) => {
    return (
      <div key={event.id}>
        <Link to={`../journal/${event.id}`}>
          <div style={{ paddingBottom: 10 }} key={event.id}>
            <div
              style={{
                paddingLeft: 30,
                paddingRight: 30,
                paddingBottom: 10,
                border: 1,
                borderColor: "grey",
                borderRadius: 10,
                backgroundColor: event?.color,
                color: "black",
                boxShadow: "2px 3px 3px grey, 0 0 5px #FCFBF4 inset",
              }}
              key={event.id}
            >
              <Linkify options={{ target: "_blank" }}>
                <p style={{ paddingTop: 20 }}>
                  <span style={{ color: "saddlebrown " }}>
                    {convertDate(event.start)}{" "}
                  </span>
                </p>
                {/*<p>Entry Title:<br/>*/}{" "}
                <span>
                  <div
                    style={{
                      //      color: "navy",
                      letterSpacing: 2,
                      wordSpacing: 2,
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: linkifyHtml(DOMPurify.sanitize(event.title)),
                      }}
                    />
                  </div>
                  <div style={{ paddingBottom: 10 }} />
                </span>
                {event.imageURL != "" || undefined || null ? (
                  <div
                    style={{
                      paddingTop: 10,
                      paddingBottom: 0,
                      padding: 0,
                      marginLeft: -10,
                    }}
                  >
                    <div class="polaroid">
                      <img
                        src={event.imageURL}
                        //  width="245px"
                        style={{
                          borderRadius: 5,

                          filter: isDarkMode ? "invert(100%)" : "none",
                        }}
                      />
                      <span style={{ fontSize: 10, color: "blue" }}>
                        {event.imageCaption != undefined
                          ? event.imageCaption
                          : ""}
                      </span>
                    </div>
                  </div>
                ) : null}
                {/* <p> Details:<br/>*/}{" "}
                <span>
                  <div
                    style={{
                      //   color: "navy",
                      letterSpacing: 2,
                      wordSpacing: 2,
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: linkifyHtml(
                          DOMPurify.sanitize(event.description)
                        ),
                      }}
                    />
                  </div>
                </span>
                <div style={{ paddingBottom: 10, textAlign: "center" }}>
                  <button
                    style={{
                      fontFamily: "inherit",
                      fontSize: "inherit",
                      backgroundColor: "transparent",
                      color: "teal",
                      border: 0,
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    {t("common-button-view")}
                  </button>
                </div>
              </Linkify>
            </div>
          </div>
        </Link>
      </div>
    );
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {};
  }, [data]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 0,
          paddingTop: 10,
          paddingBottom: 20,
          fontSize: 24,
          fontWeight: "bold",
          userSelect: "none",
        }}
      >
        {" "}
        <h2 className="h2SlideIn">{t("journal-log-title")} &#9997;</h2>
      </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={{ paddingLeft: 5, paddingTop: 10 }}>
          <button
            style={{
              paddingLeft: 15,
              paddingRight: 15,
              paddingTop: 5,
              paddingBottom: 5,
              fontSize: 18,
              fontFamily: "inherit",
              borderRadius: 40,
              border: 0,
              color: "white",
              backgroundColor: "#3e98c7",
            }}
            onClick={() => setIsEventsModalAddNewOpen(true)}
          >
            {t("common-button-addnew")}
          </button>
        </div>
        <div style={{ paddingLeft: 5, paddingTop: 10 }}>
          <button
            style={{
              paddingLeft: 15,
              paddingRight: 15,
              paddingTop: 5,
              paddingBottom: 5,
              fontSize: 18,
              fontFamily: "inherit",
              borderRadius: 40,
              border: 0,
              color: "white",
              backgroundColor: "#3e98c7",
            }}
            onClick={goToJournalCalendar}
          >
            {t("common-button-calendar")}
          </button>
        </div>
        <div style={{ paddingLeft: 5, paddingTop: 10 }}>
          <button
            style={{
              paddingLeft: 15,
              paddingRight: 15,
              paddingTop: 5,
              paddingBottom: 5,
              fontSize: 18,
              fontFamily: "inherit",
              borderRadius: 40,
              border: 0,
              color: "white",
              backgroundColor: "#3e98c7",
            }}
            onClick={() => setIsSchedulingReminder(true)}
          >
            {t("common-button-reminder")}
          </button>
        </div>
      </div>
      <div style={{ paddingBottom: 10 }} />
      {isEventsModalAddNewOpen ? (
        <EventsModalAddNew
          isOpen={isEventsModalAddNewOpen}
          event={selectedEvent}
          isDarkMode={isDarkMode}
          selectedStartTime={selectSlotStart}
          isOnline={isOnline}
          close={() => setIsEventsModalAddNewOpen(false)}
        />
      ) : null}
      <Search handleSearchNote={setSearchText} />

      {SearchResults.length > 0 ? (
        <div>{linkList}</div>
      ) : (
        <p style={{ textAlign: "center", userSelect: "none" }}>
          {t("journal-log-noresults")}
        </p>
      )}
      <div style={{ paddingBottom: 150 }} />
      <Route path={`${url}/:productId`}>
        <JournalLanding
          data={events}
          isExpired={isExpired}
          isOnline={isOnline}
        />
      </Route>
      {isSchedulingReminder ? (
        <DailyReminder
          isOpen={isSchedulingReminder}
          close={CloseMenu}
          title={t("journal-log-remindertitle")}
          description={t("journal-log-remindertext")}
        />
      ) : null}
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { Market } from "./Market";
import { goods } from "../data/goods";
import { islands } from "../data/islands";
import { TravelOptions } from "./TravelOptions";
import { LoanModal } from "./loanModal";
import { TransactionModal } from "./TransactionModal";
import { TravelModal } from "./TravelModal";

export const Game = () => {
  const [day, setDay] = useState(1);
  const [money, setMoney] = useState(1000);
  const [inventory, setInventory] = useState({});
  const [currentIsland, setCurrentIsland] = useState(islands[0]);
  const [marketPrices, setMarketPrices] = useState({});
  const MAX_DAYS = 30;
  const [isFirstDay, setIsFirstDay] = useState(true);
  const [loanAmount, setLoanAmount] = useState(0);
  const [loanRepayAmount, setLoanRepayAmount] = useState(0);
  const [hasTakenLoan, setHasTakenLoan] = useState(false);
  const [isLoanModalOpen, setIsLoanModalOpen] = useState(false);

  const openLoanModal = () => setIsLoanModalOpen(true);
  const closeLoanModal = () => setIsLoanModalOpen(false);
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);
  const [isTravelModalOpen, setIsTravelModalOpen] = useState(false);
  const [transactionType, setTransactionType] = useState("buy"); // 'buy' or 'sell'
  const [selectedItem, setSelectedItem] = useState("");

  const openTransactionModal = (type, itemName) => {
    setTransactionType(type);
    setSelectedItem(itemName);
    setIsTransactionModalOpen(true);
  };

  const openTravelModal = () => {
    setIsTravelModalOpen(true);
  };

  const closeTravelModal = () => {
    setIsTravelModalOpen(false);
  };

  const openBuyModal = (itemName) => {
    openTransactionModal("buy", itemName);
  };
  //sell all inventory items
  const sellAllInventory = () => {
    let totalSaleAmount = 0;

    Object.entries(inventory).forEach(([item, quantity]) => {
      if (quantity > 0) {
        const saleAmount = marketPrices[item] * quantity;
        totalSaleAmount += saleAmount;
      }
    });

    // Update the money state with the total sale amount
    setMoney(money + totalSaleAmount);

    // Reset inventory to empty
    setInventory({});
  };

  //Net Worth

  const calculateNetWorth = () => {
    let inventoryValue = 0;
    Object.entries(inventory).forEach(([item, quantity]) => {
      inventoryValue += (marketPrices[item] || 0) * quantity;
    });

    return money + inventoryValue - loanAmount;
  };

  //set max inventory

  const totalInventoryItems = () => {
    return Object.values(inventory).reduce(
      (total, quantity) => total + quantity,
      0
    );
  };

  //loan option

  const takeLoan = (amount) => {
    if (hasTakenLoan) {
      alert("You can only take a loan once!");
      closeLoanModal();
      return;
    }

    if ([5000, 10000, 25000].includes(amount)) {
      setLoanAmount(amount);
      //monthly payment = principal x (interest rate / 12).
      setLoanRepayAmount(amount * 0.0833);
      // console.log("what is it", loanRepayAmount);
      setMoney(money + amount);
      setHasTakenLoan(true);
      closeLoanModal();
    } else {
      alert("Invalid loan amount!");
      closeLoanModal();
    }
  };

  //supply and demand

  const initialMarketState = () => {
    if (isFirstDay) {
      const marketState = {};
      goods.forEach((good) => {
        marketState[good.name] = {
          supply: Math.floor(Math.random() * 100 + 50), // Random supply between 50 and 150
          demand: Math.floor(Math.random() * 100 + 50), // Random demand between 50 and 150
        };
      });
      //  console.log("market state", marketState);
      setIsFirstDay(false);
      return marketState;
    }
  };

  // In your Game component:
  const [marketState, setMarketState] = useState(initialMarketState());

  useEffect(() => {
    // Initialize market prices
    if (day > MAX_DAYS) {
      // Handle end of game scenario
      // Show player's final score, offer restart option, etc.
    }
    const initialPrices = {};
    goods.forEach((item) => {
      initialPrices[item.name] = item.basePrice; // Set initial market price
    });
    setMarketPrices(initialPrices);
    updateMarketPrices();
  }, [currentIsland, day]);

  /*const buyItem = (itemName, quantity) => {
    const cost = marketPrices[itemName] * quantity;
    if (cost > money) {
      alert("Not enough money!");
      return;
    }

    setMoney(money - cost);
    setInventory({
      ...inventory,
      [itemName]: (inventory[itemName] || 0) + quantity,
    });
    const itemMarketState = marketState[itemName];
    setMarketState({
      ...marketState,
      [itemName]: {
        ...itemMarketState,
        supply: Math.max(0, itemMarketState.supply - quantity), // Reduce supply
        demand: itemMarketState.demand + quantity, // Increase demand
      },
    });

    // Update market prices after the transaction
    updateMarketPrices();
  };*/

  const buyItem = (itemName, quantity) => {
    const cost = marketPrices[itemName] * quantity;
    if (cost > money) {
      alert("Not enough money!");
      return;
    }

    if (totalInventoryItems() + quantity > 100) {
      alert(
        "Buying this item will exceed your maximum inventory capacity of 100."
      );
      return;
    }

    setMoney(money - Math.floor(cost));
    setInventory({
      ...inventory,
      [itemName]: (inventory[itemName] || 0) + quantity,
    });

    // Update only the supply for the specific item in marketState
    const newSupply = marketState[itemName].supply - 1; // Reduce supply by 1
    const newMarketState = {
      ...marketState,
      [itemName]: {
        ...marketState[itemName],
        supply: Math.max(0, newSupply), // Ensure supply doesn't go negative
      },
    };

    setMarketState(newMarketState);
    setIsTransactionModalOpen(false);
  };

  const sellItem = (itemName, quantity) => {
    if (inventory[itemName] < quantity) {
      alert("Not enough items in inventory!");
      return;
    }

    setMoney(money + marketPrices[itemName] * quantity);
    setInventory({
      ...inventory,
      [itemName]: inventory[itemName] - quantity,
    });

    const newSupply = marketState[itemName].supply + 1; // Reduce supply by 1
    const newMarketState = {
      ...marketState,
      [itemName]: {
        ...marketState[itemName],
        supply: Math.max(0, newSupply), // Ensure supply doesn't go negative
      },
    };

    setMarketState(newMarketState);
    setIsTransactionModalOpen(false);
  };

  //optional, to be added at the end of the day for testing
  const randomFluctuation = () => {
    const newMarketState = { ...marketState };
    Object.keys(newMarketState).forEach((itemName) => {
      // Calculate random fluctuation for supply and demand
      const supplyChange = Math.floor(Math.random() * 41) - 20; // Random change between -20 and +20
      const demandChange = Math.floor(Math.random() * 41) - 20; // Random change between -20 and +20

      // Update supply and demand, ensuring they don't fall below 0
      newMarketState[itemName] = {
        supply: Math.max(0, newMarketState[itemName].supply + supplyChange),
        demand: Math.max(0, newMarketState[itemName].demand + demandChange),
      };
    });

    setMarketState(newMarketState);
    updateMarketPrices();
  };

  // Function to advance to the next day
  const advanceDay = () => {
    setDay(day + 1);
    // Add logic here to update market prices for the new day
  };

  // Function to travel to a different island
  const travelToIsland = (islandName) => {
    const island = islands.find((island) => island.name === islandName);
    setCurrentIsland(island);
    updateMarketPrices();
    // Add logic to update market prices based on the new island
  };

  const handleTravel = (islandName) => {
    // Logic to handle traveling to a new island
    const travelfunds = 100 + loanRepayAmount;
    if (money < travelfunds) {
      alert(
        `Not enough money to travel, you need at least $${travelfunds} to travel`
      );
      return;
    }
    travelToIsland(islandName);
    handleNewDay();
    closeTravelModal();
  };

  const calculatePrice = (basePrice, supply, demand) => {
    if (supply === 0) return basePrice * 2; // Avoid division by zero, assume high price if no supply
    const supplyDemandRatio = demand / supply;
    return Math.round(
      basePrice * Math.max(0.5, Math.min(2, supplyDemandRatio))
    ); // Price fluctuates between 50% and 200% of base price
  };

  // Update market prices (for simplicity, we'll adjust prices randomly)
  /* const updateMarketPrices = () => {
    const newPrices = {};
    goods.forEach((item) => {
      // Here you could add more complex logic based on island, events, etc.
      const randomFactor = 0.9 + Math.random() * 0.2; // 10% fluctuation
      newPrices[item.name] = calculatePrice(
        item.basePrice,
        100,
        100 * randomFactor
      );
    });
    setMarketPrices(newPrices);
  };*/

  const updateMarketPrices = () => {
    const newPrices = {};
    goods.forEach((item) => {
      const { supply, demand } = marketState[item.name];
      newPrices[item.name] = calculatePrice(item.basePrice, supply, demand);
    });
    setMarketPrices(newPrices);
  };

  const triggerRandomEvent = (currentIsland, marketPrices, setMarketPrices) => {
    const island = islands.find((island) => island.name === currentIsland.name);
    if (!island || !island.events || island.events.length === 0) {
      return null; // No events available for this island
    }

    // Randomly select an event
    const randomEvent =
      island.events[Math.floor(Math.random() * island.events.length)];

    // Process each affected good
    const affectedGoodsUpdates = randomEvent.affectedGoods
      .map(({ good, impact }) => {
        if (!marketPrices.hasOwnProperty(good)) {
          return null; // Skip if affected good not found in the current market
        }

        // Calculate new price based on the event's impact
        const currentPrice = marketPrices[good];
        const newPrice = Math.round(currentPrice * impact);

        // Update for return object
        return { affectedGood: good, newPrice };
      })
      .filter((update) => update !== null); // Filter out any null updates

    // Update market prices
    setMarketPrices((prevPrices) => ({
      ...prevPrices,
      ...affectedGoodsUpdates.reduce(
        (acc, { affectedGood, newPrice }) => ({
          ...acc,
          [affectedGood]: newPrice,
        }),
        {}
      ),
    }));

    // Returning event details for UI updates or additional logic
    return {
      eventName: randomEvent.name,
      affectedGoodsUpdates,
    };
  };

  const handleNewDay = () => {
    setDay((prevDay) => prevDay + 1);
    const eventResult = triggerRandomEvent(
      currentIsland,
      marketPrices,
      setMarketPrices
    );
    if (eventResult) {
      // Use eventResult for UI updates or additional game logic
      console.log("what", eventResult);
      //  console.log("inventory", inventory);
    }
    if (loanAmount > 0) {
      // const interest = loanAmount * 0.15; // 10% interest
      setMoney(money - 100 - Math.floor(loanRepayAmount));
      setLoanAmount(loanAmount - Math.floor(loanRepayAmount));
      //  console.log("repay", loanRepayAmount);
    } else {
      setMoney(money - 100);
    }
    updateMarketPrices();
    randomFluctuation();
  };

  return (
    <div>
      {/*} <h1>Island Traders</h1>*/}
      <div className="game-row">
        <div className="status-item">
          <span className="status-label">Day</span>
          <span className="status-value">{day}</span>
        </div>
        <div className="status-item">
          <span className="status-label">Cash</span>
          <span className="status-value">${money}</span>
        </div>
        <div className="status-item" onClick={openLoanModal}>
          <span className="status-label">Loan</span>
          <span className="status-value">${loanAmount}</span>{" "}
          {/* Assuming you have a 'loan' state */}
        </div>
        <div className="status-item">
          <span className="status-label">Net Worth</span>
          <span className="status-value">{calculateNetWorth()}</span>
        </div>
      </div>
      <div className="game-row">
        <div className="game-column">
          {" "}
          <Market
            marketPrices={marketPrices}
            openTransactionModal={openTransactionModal}
            inventory={inventory}
            marketState={marketState}
          />
        </div>
        {/*}    <div
          className="game-column"
          style={{ width: "150px", height: "35vh", overflow: "scroll" }}
        >
          <div className="inventory-section">
            <h3>Inventory</h3>
            <ul>
              {Object.entries(inventory).map(([item, quantity]) => (
                <li key={item} onClick={() => sellItem(item, 1)}>
                  {item}: {quantity}
                </li>
              ))}
            </ul>
          </div>
              </div>*/}
      </div>
      <div>
        Total Inventory: {totalInventoryItems()} |{" "}
        <span
          style={{
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={sellAllInventory}
        >
          Sell All
        </span>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 20,
        }}
      >
        {" "}
        <button onClick={openTravelModal} className="game-next-button">
          Next Day {" >>> "}
        </button>
      </div>

      <TravelModal
        isOpen={isTravelModalOpen}
        onClose={closeTravelModal}
        islands={islands}
        currentIsland={currentIsland}
        handleTravel={handleTravel}
      />

      {/*}   <button onClick={handleNewDay}>Next Day</button>*/}
      <LoanModal
        isOpen={isLoanModalOpen}
        onClose={closeLoanModal}
        onTakeLoan={takeLoan}
      />
      <TransactionModal
        isOpen={isTransactionModalOpen}
        onClose={() => setIsTransactionModalOpen(false)}
        type={transactionType}
        itemName={selectedItem}
        onConfirm={transactionType === "buy" ? buyItem : sellItem}
        maxQuantity={
          transactionType === "buy"
            ? money / (marketPrices[selectedItem] || 1) // Avoid division by zero
            : inventory[selectedItem] || 0
        }
        totalCost={
          transactionType === "sell"
            ? (inventory[selectedItem] || 0) * (marketPrices[selectedItem] || 0)
            : 0
        }
      />
    </div>
  );
};

import { useEffect, useState } from "react";
import { EmailAuthProvider, getAuth, linkWithCredential } from "firebase/auth";
import { useUserContext } from "Authentication";
import toast, { Toaster } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "Authentication";

export const GuestUserRegister = ({ close }) => {
  const [emailFBLink, setEmailFBLink] = useState("");
  const [passwordFBLink, setPasswordFBLink] = useState("");
  const [IsPasswordReset, setIsPasswordReset] = useState(false);
  const [emailPasswordReset, setEmailPasswordReset] = useState("");
  const { linkEmailToGuest, refreshUser } = useUserContext();
  const { resetEmailPassword } = useAuth();

  const { t } = useTranslation();

  const lostPasswordReset = (e) => {
    e.preventDefault();
    resetEmailPassword(emailPasswordReset);
    setIsPasswordReset(false);
    setEmailPasswordReset("");
  };

  const handleLinkWithEmail = (e) => {
    e.preventDefault();
    const credential = EmailAuthProvider.credential(
      emailFBLink,
      passwordFBLink
    );

    const auth = getAuth();
    linkWithCredential(auth.currentUser, credential)
      .then((usercred) => {
        const user = usercred.user;
        //  console.log("Account linking success", user);
        linkEmailToGuest(emailFBLink);
        close();
        toast(t("settings-toast-newaccountadded"), {
          duration: 2500,
          position: "top-center",
          style: {
            backgroundColor: "lightgreen",
            border: "1px solid #713200",
            fontSize: "16px",
            padding: "10px",
            color: "black",
          },
        });
        setTimeout(() => {
          refreshUser();
          window.location.reload();
        }, 2500);
      })
      .catch((error) => {
        window.alert(t("link-user-account-error"));
        // console.log("Account linking error", error);
      });
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: "lavender",
        borderRadius: 5,
        // color: "white",
      }}
    >
      {!IsPasswordReset ? (
        <form onSubmit={handleLinkWithEmail}>
          <label
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              fontWeight: "normal",
              textAlign: "left",
            }}
          >
            {t("login-input-email")}{" "}
          </label>
          <input
            type="email"
            placeholder="myemail@gmail.com"
            value={emailFBLink}
            onChange={(e) => setEmailFBLink(e.target.value)}
          />
          <div style={{ paddingTop: 5 }} />
          <label style={{ textAlign: "left" }}>
            {t("login-input-password")}{" "}
            <span style={{ fontSize: 10 }}>
              {" "}
              {t("login-input-password-helper")}
            </span>
          </label>
          <input
            type="password"
            placeholder="MyCoolPassword"
            required
            minLength={6}
            value={passwordFBLink}
            onChange={(e) => setPasswordFBLink(e.target.value)}
          />
          <div style={{ paddingTop: 5 }} />
          {/*}  <p
            style={{
              textAlign: "center",
              color: "blue",
              fontSize: 12,
              fontWeight: "bold",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => setIsPasswordReset(true)}
          >
            Reset Password
          </p>*/}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <button
              //onClick={handleLinkWithEmail}
              type="submit"
              style={{
                fontSize: 16,
                color: "white",
                backgroundColor: "blue",
                borderColor: "transparent",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 5,
                paddingBottom: 5,
                borderRadius: 5,
              }}
            >
              {t("login-button-register")}
            </button>

            <div style={{ paddingLeft: 10, paddingTop: 5 }} />
            <span
              onClick={close}
              className="actions_link_default"
              style={{ marginTop: 5, fontSize: 14, textAlign: "center" }}
            >
              {t("common-button-close")}
            </span>
          </div>
        </form>
      ) : (
        <form onSubmit={lostPasswordReset}>
          <input
            style={{ width: "95%" }}
            placeholder="name@email.com"
            type="email"
            name="email"
            value={emailPasswordReset}
            required
            onChange={(e) => setEmailPasswordReset(e.target.value)}
          />
          <input
            type="submit"
            value="Reset"
            style={{
              textAlign: "center",
              backgroundColor: "blue",
              fontSize: 16,
              fontWeight: "bold",
              cursor: "pointer",
            }}
          />
          <p
            onClick={() => setIsPasswordReset(false)}
            style={{
              textAlign: "center",
              // color: "blue",
              fontSize: 16,
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            {t("common-button-back")}
          </p>
        </form>
      )}
    </div>
  );
};

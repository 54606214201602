import React from "react";
import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import DOMPurify from "dompurify";
import linkifyHtml from "linkify-html";
import moment from "moment";
import { EventsModalEdit } from "./EventsModalEdit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faClose,
  faImage,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Modal } from "Common";

export const Journal = ({ data, isDarkMode, isOnline }) => {
  const { productId } = useParams();
  const event = data;
  const [title, setTitle] = useState(event?.title);
  const [description, setDescription] = useState(event?.description);
  const [isAllDay, setIsAllDay] = useState(event?.allDay);
  const [startTime, setStartTime] = useState(event?.start);
  const [endTime, setEndTime] = useState(event?.end);
  const [imageURL, setImageURL] = useState(event?.imageURL);
  const [isOpenImage, setIsOpenImage] = useState(false);
  var validatedHtmlDescription = DOMPurify.sanitize(description);
  var validatedHtmlTitle = DOMPurify.sanitize(title);
  const [isEventsModalEditOpen, setIsEventsModalEditOpen] = useState(false);
  const [isEditTop, setIsEditTop] = useState(true);

  const history = useHistory();

  const convertDate = (date) => {
    if (moment(date).isValid()) {
      const update = new Date(date);
      const updateFormatted = moment(update).format("MMMM DD, YYYY");
      return updateFormatted;
    } else {
      const update = new Date(date.seconds * 1000);
      const updateFormatted = moment(update).format("MMMM DD, YYYY");
      return updateFormatted;
    }
    //  const update = date.toDate()
  };

  const closeImage = () => {
    setIsOpenImage(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.innerWidth > 450) {
      setIsEditTop(true);
    } else {
      setIsEditTop(false);
    }
    setTitle(event.title);
    setDescription(event.description);
    validatedHtmlDescription = DOMPurify.sanitize(description);
    validatedHtmlTitle = DOMPurify.sanitize(title);

    return () => {};
  }, [event]);

  return (
    <div>
      {isEditTop ? (
        <div style={{ paddingTop: 10, paddingRight: 0, textAlign: "right" }}>
          <button
            style={{
              cursor: "pointer",

              fontFamily: "inherit",
              fontSize: 16,
              border: 0,
              fontWeight: "bold",
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 10,
              paddingBottom: 10,
              borderRadius: 10,
              color: "blue",
              backgroundColor: "transparent",
            }}
            type="button"
            onClick={() => history.push(`../journal/edit/${event.id}`)}
          >
            Edit
          </button>
        </div>
      ) : null}
      <div
        style={{
          textAlign: "center",
          paddingBottom: 5,
          fontSize: 20,
          fontStyle: "italic",
        }}
      >
        {convertDate(startTime)}
        <hr style={{ opacity: 0.6 }} />
      </div>

      <div
        style={{ padding: 10, borderRadius: 10 }}
        onDoubleClick={() => history.push(`../journal/edit/${event.id}`)}
      >
        <div
          style={{
            //width: "85%",
            fontSize: "inherit",
            padding: 5,
            marginTop: 0,
            fontFamily: "inherit",
            // padding: 5,
            paddingBottom: 0,
            border: 0,
            //        color: "navy",
            backgroundColor: "transparent",
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: linkifyHtml(validatedHtmlTitle),
            }}
          />
        </div>
        <div style={{ padding: 10 }} />
        {imageURL != "" || undefined || null ? (
          <div
            style={{
              cursor: "pointer",
              marginLeft: 0,
            }}
            onClick={() => setIsOpenImage(true)}
          >
            {" "}
            <div class="polaroid">
              <img
                src={imageURL}
                // width="80%"
                style={{
                  borderRadius: 5,
                  filter: isDarkMode ? "invert(100%)" : "none",
                }}
              />
              <span
                style={{
                  fontSize: 12,
                  fontFamily: "inherit",
                  color: "grey",
                }}
              >
                {event.imageCaption != undefined ? event.imageCaption : ""}
              </span>
            </div>
          </div>
        ) : null}

        {/*}  <label style={{paddingTop: 5, paddingBottom: 0,  fontSize:16,  color: "grey"}}>Details</label>*/}

        <div
          style={{
            width: "85%",
            fontSize: 20,
            marginTop: 0,
            paddingBottom: 5,
            backgroundColor: "transparent",
            // color: "navy",
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: linkifyHtml(validatedHtmlDescription),
            }}
          />
        </div>
        {!isEditTop ? (
          <div style={{ paddingTop: 10, paddingRight: 0, textAlign: "center" }}>
            <button
              style={{
                cursor: "pointer",

                fontFamily: "inherit",
                fontSize: 16,
                border: 0,
                fontWeight: "bold",
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: 10,
                color: "blue",
                backgroundColor: "transparent",
              }}
              type="button"
              onDoubleClick={() => history.push(`../journal/edit/${event.id}`)}
            >
              Edit
            </button>
          </div>
        ) : null}
        <div style={{ paddingBottom: 50 }} />
      </div>
      <Modal
        isOpen={isOpenImage}
        onRequestClose={closeImage}
        style={{ overlay: { zIndex: 100 } }}
      >
        <div style={{ padding: 10 }}>
          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 20,
              border: 1,
              borderColor: "grey",
              borderRadius: 5,
              backgroundColor: "black",
              fontWeight: "bold",
              color: "grey",
              //   boxShadow: "2px 3px 3px grey, 0 0 5px #FCFBF4 inset",
              fontSize: 16,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "right",
                marginTop: 0,
                marginRight: 10,
              }}
            >
              <FontAwesomeIcon
                icon={faClose}
                size="1x"
                onClick={closeImage}
                style={{ marginTop: 15, cursor: "pointer" }}
              />
            </div>
            <div class="polaroidfull">
              <img
                onDoubleClick={() => closeImage()}
                src={imageURL}
                width="100%"
                style={{
                  borderRadius: 10,
                  paddingTop: 20,
                  filter: "invert(100%)",
                }}
              />
              <span
                style={{
                  fontSize: 12,
                  fontFamily: "inherit",
                  color: "grey",
                }}
              >
                {event.imageCaption != undefined ? event.imageCaption : ""}
              </span>
            </div>
          </div>
          <div style={{ paddingTop: 10, paddingRight: 0, textAlign: "center" }}>
            <button
              style={{
                cursor: "pointer",

                fontFamily: "inherit",
                fontSize: 20,
                border: 0,
                fontWeight: "bold",
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: 10,
                color: "blue",
                backgroundColor: "transparent",
                textDecoration: "underline",
              }}
              type="button"
              onClick={() => closeImage()}
            >
              Close
            </button>
          </div>
          <div style={{ paddingBottom: 50 }} />
        </div>
      </Modal>

      <div style={{ paddingBottom: 100 }} />
    </div>
  );
};

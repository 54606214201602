import { useEffect, useState } from "react";
import { collection, query, onSnapshot } from "@firebase/firestore";
import { useUserContext } from "Authentication";

import { PlannerModel } from "./models/plannerModel";

export function usePlannerSubscription() {
  const [eventsPlanner, setEvents] = useState<PlannerModel[]>([]);
  const { getPlannerCollection } = useUserContext(); //need to setup Tim

  useEffect(() => {
    const plannerCollection = getPlannerCollection();
    const plannerQuery = query(plannerCollection);

    const unsubscribe = onSnapshot(
      plannerQuery,
      (snapshot) => {
        const addedEvents: PlannerModel[] = [];

        snapshot.docs.forEach((doc) => {
          const item = { ...doc.data(), id: doc.id } as PlannerModel;
          addedEvents.push(item);
        });

        setEvents(addedEvents);
      },
      (error) => {
        window.location.reload();
      }
    );

    return () => unsubscribe();
  }, [getPlannerCollection]);

  // Convert date to epoch
  eventsPlanner.sort((a, b) => (a.start < b.start ? 1 : -1));

  return { eventsPlanner };
}

import React, { useState, useEffect, useRef } from "react";
//import { Link, Route, useRouteMatch, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  //faPaintRoller,
  //faTrash,
  faCalendarDay,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { Modal } from "Common";
import classes from "./reminder.module.css";

import moment from "moment";
import TimePicker from "react-time-picker";

import { google, outlook, office365, yahoo, ics } from "calendar-link";

export const DailyReminder = ({ title, description, isOpen, close }) => {
  const [isNative, setIsNative] = useState(false);
  //const [icsURL, seticsURL] = useState("");
  //reminder and uploader
  const [reminderDoneModal, setReminderDoneModal] = useState(false);
  const [startTime, setStartTime] = useState(new Date());

  // const [selectSlotStart, setSelectSlotStart] = useState(new Date())

  const [isAlohaPlanner, setIsAlohaPlanner] = useState(false);
  const [isDopeNotesApp, setIsDopeNotesApp] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    var current = new Date();
    const time = current.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    setValueTime(time);

    window.scrollTo({ top: 0, behavior: "smooth" });

    if (window.isNative) {
      setIsNative(true);
    } else {
      setIsNative(false);
    }

    if (window.isAloha) {
      setIsAlohaPlanner(true);
    }

    if (window.isDopeNotesApp) {
      setIsDopeNotesApp(true);
    }

    if (window.isIOS) {
      setIsIOS(true);
    }

    return () => {};
  }, []);

  const CloseMenu = () => {
    close();
  };

  const [valueTime, setValueTime] = useState("12:00");

  const [reminder, setReminder] = useState({
    title: title,
    description: description,
    start: moment(startTime).format("YYYY-MM-DD") + valueTime,
    duration: [1, "hour"],
    rRule: "FREQ=DAILY",
  });

  const notificationScheduler = (e) => {
    if (valueTime != null) {
      if (window.isNative) {
        // Post the message back to expo
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "NoficationSelected",
            name: title,
            days: [0, 1, 2, 3, 4, 5, 6],
            time: valueTime,
            id: "dnjournal001",
            isAdded: "create",
          })
        );

        //alert(data.name, data.days, valueTime)
      } else {
        // Your old login method
        //  console.log("clicked")
      }
    }
    //generateICAL();

    setReminderDoneModal(true);

    setReminder({
      title: title,
      description: description,
      start: moment(startTime).format("YYYY-MM-DD") + valueTime,
      duration: [1, "hour"],
      rRule: "FREQ=DAILY",
    });
  };

  const closeReminderFinish = () => {
    var current = new Date();
    const time = current.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    setValueTime(time);
    setReminderDoneModal(false);
    close();
  };

  const GoogleCalendar = () => {
    //@ts-ignore
    window.open(google(reminder));
  };

  const ICSCalendar = () => {
    //@ts-ignore
    window.open(ics(reminder));
  };

  const addEventDeviceCalendar = () => {
    var days = [1, 2, 3, 4, 5, 6, 7];
    var today = new Date();
    const [hours, minutes] = valueTime.split(":");
    const startDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      parseInt(hours),
      parseInt(minutes),
      0
    );

    const durationTimeInMinutes = 60;
    const durationTimeInMillis = durationTimeInMinutes * 60 * 1000;

    const endDate = new Date(today.getTime() + durationTimeInMillis);

    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "addEventDeviceCalendar",
        titleName: title,
        notes: description,
        startDate: moment(startDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        endDate: moment(endDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        allDay: false,
        interval: 1,
        weekPositionInMonth: 0,
        dateForMonthlyYearly: 0,
        repeatValue: "daily",
        monthlyType: "day",
        days: days,
      })
    );
  };

  const YahooCalendar = () => {
    //@ts-ignore
    window.open(yahoo(reminder));
  };

  const OutLookCalendar = () => {
    //@ts-ignore
    window.open(outlook(reminder));
  };

  const Office365Calendar = () => {
    //@ts-ignore
    window.open(office365(reminder));
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={close}
        style={{ overlay: { zIndex: 20 } }}
      >
        <div style={{ paddingTop: 5, paddingBottom: 20, paddingLeft: 5 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
              alignItems: "right",
              paddingLeft: 20,
              marginTop: -10,
              marginRight: 10,
              paddingBottom: 10,
            }}
          >
            <button
              onClick={CloseMenu}
              style={{
                backgroundColor: "transparent",
                border: 0,
                marginTop: 0,
                marginRight: 20,
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon icon={faClose} size="3x" />
            </button>
          </div>
        </div>
        <h2 style={{ textAlign: "center", lineHeight: 1.5 }}>
          <FontAwesomeIcon icon={faCalendarDay} size="sm" /> Create Daily
          Reminder
        </h2>
        <p
          style={{
            fontSize: "inherit",
            fontFamily: "inherit",
            lineHeight: 1.5,
            textAlign: "center",
          }}
        >
          Please select a time for your daily reminder.
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: 10,
          }}
        ></div>

        <div
          style={{
            paddingBottom: 0,
            paddingTop: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TimePicker
            onChange={setValueTime}
            value={valueTime}
            maxDetail="minute"
            disableClock
            clearIcon={null}
            className={classes.timepicker}
          />
        </div>
        <div className={classes.modalActions}>
          {/*}   <button
                type="button"
                onClick={notificationCancel}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "tomato",
                  cursor: "pointer",
                }}
                //   className={classes.secondaryButton}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  size="2x"
                  style={{ fontSize: 20, paddingRight: 2 }}
                />
              </button>*/}

          <button
            type="button"
            onClick={notificationScheduler}
            style={{
              cursor: "pointer",
              fontFamily: "inherit",
              fontSize: "inherit",
            }}
            //   className={classes.secondaryButton}
          >
            Set Reminder
          </button>

          {/*}    <button
              type="button"
              onClick={CloseMenu}
              style={{cursor:"pointer"}}
             // className={classes.secondaryButton}
            >
              Close
 </button> */}
        </div>
      </Modal>
      <Modal
        isOpen={reminderDoneModal}
        onRequestClose={close}
        style={{ overlay: { zIndex: 20 } }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "right",
            justifyContent: "right",
            margin: 10,
          }}
        >
          {" "}
          <button
            style={{
              backgroundColor: "transparent",
              border: 0,
              marginTop: 0,
              marginRight: 20,
              cursor: "pointer",
            }}
            onClick={closeReminderFinish}
          >
            <FontAwesomeIcon icon={faClose} size="3x" />
          </button>
        </div>
        <div style={{ paddingBottom: 150, paddingLeft: 20, paddingRight: 20 }}>
          <h2 style={{ lineHeight: 1.5 }}>
            <FontAwesomeIcon icon={faCalendarDay} size="sm" /> Reminder Created
          </h2>
          <p
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              lineHeight: 1.5,
            }}
          >
            Title: {title}
          </p>
          <p
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              lineHeight: 1.5,
            }}
          >
            Body: {description}
          </p>

          <p
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              lineHeight: 1.5,
            }}
          >
            Repeats Daily
          </p>

          <p
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              lineHeight: 1.5,
            }}
          >
            Time: {valueTime}
          </p>
          <hr />
          <p
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              lineHeight: 1.5,
            }}
          >
            Would you like to add the reminder to a different calendar? Please
            select the calendar where you would like to add this reminder:
          </p>
          <div className="reminder-button-grid">
            {isAlohaPlanner || isDopeNotesApp ? (
              <button
                className="reminder-calendar-buttons"
                onClick={addEventDeviceCalendar}
              >
                {isIOS ? "iPhone " : "Android "}Calendar
              </button>
            ) : isNative && !isAlohaPlanner && !isDopeNotesApp ? (
              <div>
                <button
                  className="reminder-calendar-buttons"
                  style={{ opacity: 0.6 }}
                  disabled
                  // onClick={addEventDeviceCalendar}
                >
                  {isIOS ? "iPhone " : "Android "}Calendar
                </button>
                <br />
                <span
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    paddingLeft: 10,
                  }}
                >
                  Please update your app to enable!
                </span>
              </div>
            ) : null}
            <button
              className="reminder-calendar-buttons"
              onClick={GoogleCalendar}
            >
              Google Calendar
            </button>
            {!isNative ? (
              <button
                className="reminder-calendar-buttons"
                onClick={ICSCalendar}
              >
                ICS/ICAL
              </button>
            ) : null}
            <button
              style={{
                fontSize: "inherit",
                fontFamily: "inherit",
                color: "white",
                backgroundColor: "#1e79c3",
                borderColor: "white",
                padding: 10,
                borderRadius: 10,
                paddingRight: 30,
                paddingLeft: 30,
                width: 200,
              }}
              onClick={closeReminderFinish}
            >
              Close
            </button>
          </div>
          <div style={{ paddingBottom: 150 }} />
        </div>
      </Modal>
    </>
  );
};

export const commonGroceryItemsGermany = [
  { category: "100100", name: "Baby Food" },
  { category: "100100", name: "Baby Wash" },
  { category: "100100", name: "Baby Diapers" },
  { category: "100100", name: "Baby Formula" },
  { category: "100100", name: "Baby Lotion" },
  { category: "100200", name: "Brot" }, // Bread
  { category: "100200", name: "Brötchen" }, // Rolls
  { category: "100200", name: "Croissants" },
  { category: "100200", name: "Vollkornbrot" }, // Whole grain bread
  { category: "100200", name: "Baguette" },
  { category: "100300", name: "Mehl" }, // Flour
  { category: "100300", name: "Zucker" }, // Sugar
  { category: "100300", name: "Backpulver" }, // Baking powder
  { category: "100300", name: "Vanillezucker" }, // Vanilla sugar
  { category: "100300", name: "Kakao" }, // Cocoa
  { category: "100400", name: "Mineralwasser" }, // Mineral water
  { category: "100400", name: "Orangensaft" }, // Orange juice
  { category: "100400", name: "Kaffee" }, // Coffee
  { category: "100400", name: "Tee" }, // Tea
  { category: "100400", name: "Bier" }, // Beer
  { category: "100500", name: "Waschmittel" }, // Laundry Detergent
  { category: "100500", name: "Spülmittel" }, // Dishwashing Liquid
  { category: "100500", name: "Allzweckreiniger" }, // All-purpose Cleaner
  { category: "100500", name: "Papierhandtücher" }, // Paper Towels
  { category: "100500", name: "Müllbeutel" }, // Trash Bags
  { category: "100600", name: "Tomatensoße" }, // Tomato sauce
  { category: "100600", name: "Sojasoße" }, // Soy sauce
  { category: "100600", name: "Senf" }, // Mustard
  { category: "100600", name: "Ketchup" },
  { category: "100600", name: "Mayonnaise" },
  { category: "100700", name: "Milch" }, // Milk
  { category: "100700", name: "Butter" },
  { category: "100700", name: "Käse" }, // Cheese
  { category: "100700", name: "Joghurt" }, // Yogurt
  { category: "100700", name: "Sahne" }, // Cream
  { category: "100800", name: "Tiefkühlpizza" }, // Frozen Pizza
  { category: "100800", name: "Tiefkühlgemüse" }, // Frozen Vegetables
  { category: "100800", name: "Eiscreme" }, // Ice Cream
  { category: "100800", name: "Fertiggerichte" }, // Ready meals
  { category: "100800", name: "Tiefkühlbeeren" }, // Frozen Berries
  { category: "100900", name: "Äpfel" }, // Apples
  { category: "100900", name: "Bananen" }, // Bananas
  { category: "100900", name: "Orangen" }, // Oranges
  { category: "100900", name: "Trauben" }, // Grapes
  { category: "100900", name: "Erdbeeren" }, // Strawberries
  { category: "101100", name: "Rindfleisch" }, // Beef
  { category: "101100", name: "Schweinefleisch" }, // Pork
  { category: "101100", name: "Hühnchen" }, // Chicken
  { category: "101100", name: "Wurst" }, // Sausage
  { category: "101100", name: "Speck" }, // Bacon
  { category: "101200", name: "Kopfschmerztabletten" }, // Headache Pills
  { category: "101200", name: "Pflaster" }, // Band-Aids
  { category: "101200", name: "Hustensaft" }, // Cough Syrup
  { category: "101200", name: "Fieberthermometer" }, // Thermometer
  { category: "101200", name: "Vitamine" }, // Vitamins
  { category: "101500", name: "Nudeln" }, // Pasta
  { category: "101500", name: "Reis" }, // Rice
  { category: "101500", name: "Olivenöl" }, // Olive Oil
  { category: "101500", name: "Konserven" }, // Canned Goods
  { category: "101500", name: "Hülsenfrüchte" }, // Legumes
  { category: "101700", name: "Hundefutter" }, // Dog Food
  { category: "101700", name: "Katzenfutter" }, // Cat Food
  { category: "101700", name: "Vogelfutter" }, // Bird Food
  { category: "101700", name: "Streu" }, // Litter
  { category: "101700", name: "Leckerlies" }, // Treats
  { category: "101800", name: "Lachs" }, // Salmon
  { category: "101800", name: "Garnelen" }, // Shrimp
  { category: "101800", name: "Kabeljau" }, // Cod
  { category: "101800", name: "Forelle" }, // Trout
  { category: "101800", name: "Thunfisch" }, // Tuna
  { category: "101900", name: "Chips" },
  { category: "101900", name: "Schokolade" }, // Chocolate
  { category: "101900", name: "Kekse" }, // Cookies
  { category: "101900", name: "Nüsse" }, // Nuts
  { category: "101900", name: "Gummibärchen" }, // Gummy Bears
  { category: "102000", name: "Salz" }, // Salt
  { category: "102000", name: "Pfeffer" }, // Pepper
  { category: "102000", name: "Currypulver" }, // Curry Powder
  { category: "102000", name: "Paprikapulver" }, // Paprika
  { category: "102000", name: "Zimt" }, // Cinnamon
  { category: "102500", name: "Basilikum" }, // Basil
  { category: "102500", name: "Petersilie" }, // Parsley
  { category: "102500", name: "Thymian" }, // Thyme
  { category: "102500", name: "Dill" },
  { category: "102500", name: "Rosmarin" }, // Rosemary
  { category: "100900", name: "Champignons" }, // Mushrooms
  { category: "100900", name: "Gurken" }, // Cucumbers
  { category: "100900", name: "Kürbis" }, // Pumpkin
  { category: "100900", name: "Auberginen" }, // Eggplants
  { category: "100900", name: "Paprika" }, // Bell Peppers
  { category: "100900", name: "Süßkartoffeln" }, // Sweet Potatoes
  { category: "100900", name: "Rote Beete" }, // Beetroot
  { category: "100900", name: "Spinat" }, // Spinach
  { category: "100900", name: "Artischocken" }, // Artichokes
  { category: "100900", name: "Lauch" }, // Leeks
  { category: "101100", name: "Lammfleisch" }, // Lamb
  { category: "101100", name: "Leber" }, // Liver
  { category: "101100", name: "Wurst" }, // Sausage
  { category: "101100", name: "Blutwurst" }, // Blood Sausage
  { category: "101100", name: "Schinken" }, // Ham
  { category: "101100", name: "Garnelen" }, // Shrimp
  { category: "101100", name: "Krake" }, // Octopus
  { category: "101100", name: "Muscheln" }, // Mussels
  { category: "101100", name: "Kabeljau" }, // Cod
  { category: "101100", name: "Lachs" }, // Salmon
  { category: "101200", name: "Olivenöl" }, // Olive Oil
  { category: "101200", name: "Essig" }, // Vinegar
  { category: "101200", name: "Marmelade" }, // Jam
  { category: "101200", name: "Honig" }, // Honey
  { category: "101200", name: "Nougat" }, // Nougat
  { category: "101500", name: "Reis" }, // Rice
  { category: "101500", name: "Linsen" }, // Lentils
  { category: "101500", name: "Kichererbsen" }, // Chickpeas
  { category: "101500", name: "Nudeln" }, // Pasta
  { category: "101500", name: "Mehl" }, // Flour
  { category: "101700", name: "Hundefutter" }, // Dog Food
  { category: "101700", name: "Katzenfutter" }, // Cat Food
  { category: "101700", name: "Katzenstreu" }, // Cat Litter
  { category: "101700", name: "Haustierspielzeug" }, // Pet Toys
  { category: "101700", name: "Haustiershampoo" }, // Pet Shampoo
  { category: "101800", name: "Thunfisch" }, // Tuna
  { category: "101800", name: "Forelle" }, // Trout
  { category: "101800", name: "Seezunge" }, // Sole
  { category: "101800", name: "Jakobsmuscheln" }, // Scallops
  { category: "101800", name: "Austern" }, // Oysters
  { category: "101900", name: "Kuchen" }, // Cake
  { category: "101900", name: "Gebäck" }, // Pastries
  { category: "101900", name: "Lebkuchen" }, // Gingerbread
  { category: "101900", name: "Brezen" }, // Pretzels
  { category: "101900", name: "Marzipan" }, // Marzipan
  { category: "102000", name: "Paprika" }, // Paprika
  { category: "102000", name: "Safran" }, // Saffron
  { category: "102000", name: "Kümmel" }, // Caraway
  { category: "102000", name: "Zimt" }, // Cinnamon
  { category: "102000", name: "Lorbeerblätter" },
];

import React, {
  useState,
  SetStateAction,
  Dispatch,
  useRef,
  useEffect,
} from "react";
import {
  faClose,
  faPen,
  faPenToSquare,
  faPlus,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button, Modal } from "Common";
import { ItemModel } from "Lists";

import { ListItem } from "../../ListItem";
import { CategoryEditModal } from "../../CategoryModal/CategoryEditModal";
import classes from "./Category.module.css";
import { useItemSubscription } from "Lists";
import { SelectRef } from "../../AddItem";
import { AddItemCat } from "Lists/List/AddItem/AddItemCat";
//import { AddItemToDo } from "ListToDo/List/AddItem";
import { useTranslation } from "react-i18next";

export interface CategoryItems {
  items: ItemModel[];
  id?: string | undefined;
  name: string;
  colour: string;
  order: number;
}

export interface CategoryProps {
  category: CategoryItems;
  showAllCat: boolean;
  onClickAddCategoryItem: (id: string | undefined) => void;
  onClickItem: (item: ItemModel) => void;
  onClickItemCategory: (item: ItemModel) => void;
  isDarkMode: boolean;
  categories: any[];
}

export const Category = (props: CategoryProps) => {
  const {
    category,
    onClickAddCategoryItem,
    onClickItem,
    onClickItemCategory,
    isDarkMode,
    showAllCat,
    categories,
  } = props;
  const { id, colour, items, name } = category;
  const [isCatEditModalOpen, setIsCatEditModalOpen] = useState(false);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [selectedCategory, setSelectedCategoryx] = useState(id);
  const [selectedCategoryName, setSelectedCategoryNamex] = useState(name);
  const [isAddingNewItem, setIsAddingNewItem] = useState(false);
  const [selectedCategoryxx, setSelectedCategory] = useState(id);
  const [selectedCategoryNamexx, setSelectedCategoryName] = useState(name);
  const inputRef = useRef<SelectRef>(null);
  const { addedItems, allItems } = useItemSubscription();
  const [isAdding, setIsAdding] = useState(false);
  const [showAllCategory, setShowAllCategory] = useState(false);
  const [isOpenItems, setIsOpenItems] = useState(
    localStorage.getItem(`iscattodo-${name}`) != undefined
      ? JSON.parse(localStorage.getItem(`iscattodo-${name}`))
      : true
  );
  const { t } = useTranslation();

  useEffect(() => {
    return () => {};
  }, []);

  //console.log("item count", items.length)

  const hanldeShowItems = () => {
    // console.log("click");
    if (isOpenItems == true) {
      setIsOpenItems(false);
      localStorage.setItem(`iscattodo-${name}`, "false");
    } else {
      setIsOpenItems(true);
      localStorage.setItem(`iscattodo-${name}`, "true");
    }
  };

  return (
    <>
      {items.length >= 1 || showAllCat ? (
        <div
          className={classes.category}
          style={{ backgroundColor: `${colour}40` }}
        >
          <div style={{ paddingBottom: 10 }}>
            <div
              className={classes.categoryHeader}
              style={{ paddingBottom: 0 }}
            >
              <div>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "dimgray",
                    paddingLeft: 5,
                  }}
                >
                  {name.toLocaleUpperCase()}{" "}
                </span>
              </div>
            </div>
          </div>
          {isAddingNewItem ? (
            <Modal
              isOpen={isAddingNewItem}
              onRequestClose={() => setIsAddingNewItem(false)}
              style={{ overlay: { zIndex: 100 } }}
              //background-color: rgba(255, 255, 255, 0.5); /* Set the desired overlay color and opacity */
            >
              <div style={{ filter: "invert(100%)" }}>
                <AddItemCat
                  addedItems={addedItems}
                  allItems={allItems}
                  category={selectedCategory}
                  categoryName={selectedCategoryName}
                  ref={inputRef}
                  setCategory={setSelectedCategory}
                  setCategoryName={setSelectedCategoryName}
                  isDarkMode={isDarkMode}
                  setIsAddingNewItemCompleted={() => setIsAddingNewItem(false)}
                  categories={categories}
                />
              </div>
            </Modal>
          ) : null}

          <div
            onClick={hanldeShowItems}
            style={{
              fontSize: 14,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span
              style={{
                color: "blue",
                textDecoration: "underline",
                paddingLeft: 5,
              }}
            >
              {!isOpenItems ? "Show" : "Hide"}
            </span>

            <span style={{ fontSize: 14, paddingRight: 5, paddingLeft: 5 }}>
              {" "}
              Total: {items.length}
            </span>
            <span
              onClick={() => setIsAddingNewItem(true)}
              style={{
                // float: "right",
                paddingLeft: 5,
                paddingRight: 5,
                textDecoration: "underline",
                color: "blue",
                cursor: "pointer",
                fontSize: 14,
              }}
            >
              {t("common-button-addnew")}
            </span>
          </div>

          {isOpenItems ? (
            <div className={classes.items}>
              {items.map((item, index) => (
                <div
                  key={index}
                  style={{
                    borderStyle: "dashed !important",
                    border: 1,
                    borderColor: "rgba(128, 128, 128, 0.6)",
                    padding: 10,
                  }}
                >
                  <ListItem
                    key={item.id}
                    item={item}
                    category={category}
                    onClickCategory={() => onClickItemCategory(item)}
                    onClickItem={() => onClickItem(item)}
                  />
                </div>
              ))}
            </div>
          ) : null}
          {isCatEditModalOpen ? (
            <CategoryEditModal
              isOpen={isCatEditModalOpen}
              close={() => setIsCatEditModalOpen(false)}
              isDarkMode={isDarkMode}
              categories={categories}
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
};

import { useIsAuthenticated } from "Authentication";

import { AnonymousContent } from "./AnonymousContent";
import { AuthenticatedContent } from "./AuthenticatedContent";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import classes from "./Content.module.css";

interface ContentProps {
  isColorPickerOpen: boolean;
  setIsColorPickerOpen: Dispatch<SetStateAction<boolean>>;
  isDarkMode: boolean;
  setIsDarkMode: Dispatch<SetStateAction<boolean>>;
  isOnline: boolean;
  //  stepCount: number;
}

export const Content = (props: ContentProps) => {
  const {
    isColorPickerOpen,
    setIsColorPickerOpen,
    isDarkMode,
    setIsDarkMode,
    isOnline,
  } = props;
  var getDarkMode = JSON.parse(localStorage.getItem("isdarkmode"));
  //console.log("what is darkmode", getDarkMode);

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div
      className={classes.content}
      style={
        isDarkMode ? { filter: "invert(100%)" } : { backgroundColor: "ivory" }
      }
    >
      {useIsAuthenticated() ? (
        <AuthenticatedContent
          isDarkMode={isDarkMode}
          setIsDarkMode={setIsDarkMode}
          isColorPickerOpen={isColorPickerOpen}
          setIsColorPickerOpen={setIsColorPickerOpen}
          isOnline={isOnline}
          // stepCount={stepCount}
        />
      ) : (
        <AnonymousContent />
      )}
    </div>
  );
};

import { useEffect, useState, useRef } from "react";
//import { useUserSubscription } from "./useUserSubscription";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { DiApple, DiAndroid } from "react-icons/di";
import { Modal } from "Common";
import { Link, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

//import emailjs from '@emailjs/browser';

export const AppUpdateNoticeModal = ({ isUpdateNotice, close }) => {
  //  const [isBuyNow, setIsBuyNow] = useState(false);
  //const userProfile = useUserSubscription();

  const [isMobile, setIsMobile] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [appVersion, setAppVersion] = useState();
  var isAndroid = isMobile && !isIOS ? true : false;
  var isIOSDevice = isMobile && isIOS ? true : false;

  const CheckPlatform = () => {
    if (window.isNative) {
      // Post the message back to expo
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    if (window.isIOS) {
      // Post the message back to expo
      setIsIOS(true);
    } else {
      setIsIOS(false);
    }
  };

  const appStoreURL = () => {
    var url = isAndroid
      ? "https://play.google.com/store/apps/details?id=com.holmgren.dopenotes"
      : "https://apps.apple.com/us/app/dope-notes-life-organizer/id1631683070";

    // window.open(url, "_blank");
    if (isAndroid) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  useEffect(() => {
    var getAppVersion = parseFloat(window.isAppVersion);
    setAppVersion(getAppVersion);
    //  console.log("what is it?", appVersion);
    CheckPlatform();
    // console.log("what is the user id", user.id);
    return () => {};
  }, []);

  // console.log("subDate", subscription)
  //  console.log("today", today)

  return (
    <div style={{ marginTop: 0 }}>
      <Modal isOpen={isUpdateNotice} style={{ overlay: { zIndex: 20 } }}>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              alignItems: "right",
              justifyContent: "right",
              marginTop: -50,
              marginRight: 10,
            }}
          >
            <FontAwesomeIcon
              icon={faClose}
              size="1x"
              onClick={() => close()}
              style={{ marginTop: 15, cursor: "pointer" }}
            />
          </div>
          <Modal.Header>
            <p
              style={{
                fontSize: 24,
                fontFamily: "inherit",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: 10,
              }}
            >
              Update Available
            </p>
          </Modal.Header>
          <div
            style={{
              textAlign: "center",
              color: "black",
              paddingBottom: 150,
              paddingRight: 20,
            }}
          >
            <>
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Link
                    to={{
                      pathname: isAndroid
                        ? "https://play.google.com/store/apps/details?id=com.holmgren.dopenotes"
                        : "https://apps.apple.com/us/app/dope-notes-life-organizer/id1631683070",
                    }}
                    target="_blank"
                  >
                    <dd
                      style={{
                        padding: 10,
                        paddingLeft: 0,
                        // textDecoration: "underline",
                        // fontSize: 14,
                        userSelect: "none",
                        color: "blue",
                        // marginTop: -5,
                      }}
                      // onClick={openDeviceSettings}
                    >
                      <b>
                        Before completing your purchase, please update your{" "}
                        {isAndroid ? " Android app " : " IOS app "} to ensure
                        compatibility with the latest improvements.
                      </b>
                    </dd>
                  </Link>

                  <div
                    onClick={appStoreURL}
                    style={{
                      padding: 10,
                      marginTop: 10,
                      color: "white",
                      borderRadius: 10,
                      textAlign: "center",
                      backgroundColor: "blue",
                      width: 200,
                      cursor: "pointer",
                    }}
                  >
                    Update Now
                  </div>
                </div>
              </div>
            </>

            <button
              style={{
                marginTop: 40,
                color: "blue",
                border: 1,
                borderStyle: "dashed",
                padding: 10,
                width: 175,
                textAlign: "center",
                cursor: "pointer",
                fontSize: 18,
                fontFamily: "inherit",
              }}
              onClick={() => close()}
            >
              Close
            </button>
            <Toaster containerStyle={{ marginTop: 225 }} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

import { faCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button } from "Common";

import classes from "./Ingredient.module.css";

interface IngredientProps {
  name: string;
  measurement: string;
  added: boolean;
  toggle: () => void;
}

export const Ingredient = (props: IngredientProps) => {
  const { added, name, measurement, toggle } = props;

  return (
    <div style={{ paddingBottom: 5, paddingTop: 3 }}>
      <button
        title={added ? "Added to list (click to remove)" : "Add to list"}
        onClick={toggle}
        className={classes.toggle}
        style={{
          backgroundColor: "transparent",
          borderStyle: "none",
          cursor: "pointer",
          fontFamily: "inherit",
        }}
      >
        <FontAwesomeIcon size="xl" icon={added ? faCheck : faPlus} />
      </button>
      <span className={classes.name} style={{ fontSize: 20, color: "navy" }}>
        {measurement}
      </span>
      <span
        className={classes.name}
        style={{
          fontSize: 20,
          //color: "navy"
        }}
      >
        {name}
      </span>
    </div>
  );
};

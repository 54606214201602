import { useRef, useState, useEffect } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { useUserContext } from "Authentication";
import { useItems } from "Lists";
import { useHistory } from "react-router-dom";
import { CategoryModel, ItemModel } from "../models";
//import { Modal } from "Common";
import { CheckGroupStatus } from "Authentication/checkGroupStatus";
import { AddItemHeader } from "./AddItem/AddItemHeader";
import toast, { Toaster } from "react-hot-toast";
//import { SelectRef } from "./AddItem";
import { CategoryModal } from "./CategoryModal";
import { ItemModal } from "./ItemModal";
import { ListActions } from "./ListActions";
import { Categories } from "./Categories";
import { ConfirmationModal, Modal } from "Common";
//import LightSpeed from "react-reveal/LightSpeed";
//import { useCategorySubscription } from "../../Lists/useCatgorySubscription";
//import { SubscriptionChecker } from "Authentication/SubscriptionChecker";
import { FirebaseAnalytics } from "Config/FirebaseAnalytics";
import { EditDefaultItemsModal } from "./Common/Item/EditDefaultItemsModal";
import { useTranslation } from "react-i18next";
import { InviteMember } from "Household/InviteMember";
import { BannerAd } from "GoogleAdSense/BannerAd";

export interface ListProps {
  addedItems: ItemModel[];
  allItems: ItemModel[];
  isExpired: boolean;
  isDarkMode: boolean;
  isGroupValid: boolean;
  isOnline: boolean;
  categories: CategoryModel[];
}

export const List = (props: ListProps) => {
  const {
    addedItems,
    allItems,
    isExpired,
    isDarkMode,
    isGroupValid,
    isOnline,
    categories,
  } = props;
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isItemModalOpen, setIsItemModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ItemModel>();
  const [showCompleted, setShowCompleted] = useState(checkShowCommpleted);
  const [showAllCat, setShowAllCat] = useState(checkShowAllCat);
  const { batchRemoveItems } = useItems();
  const { getDefaultItemsCollection, removeGroupFromMemberByOwner } =
    useUserContext();
  const [selectedCategory, setSelectedCategory] = useState("");
  const inputRef = null;

  const { t } = useTranslation();

  const completedItems = addedItems.filter((i) => i.completed);
  const [isEditDefaultItem, setIsEditDefaultItem] = useState(false);
  const [isAddingNewItem, setIsAddingNewItem] = useState(false);
  const [confirmationModal, setConfirmModalOpen] = useState(false);
  const [isTabClosed, setIsTabClosed] = useState(true);
  const [toolBarPadding, setToolBarPadding] = useState(120);
  const [hideAddInput, setHideAddInput] = useState(
    localStorage.getItem("hideGroceryAddItem") != undefined
      ? JSON.parse(localStorage.getItem("hideGroceryAddItem"))
      : true
  );

  const [addItemPadding, setAddItemPadding] = useState(hideAddInput ? -40 : 80);

  const [isInvitation, setIsInvitation] = useState(false);

  const toggleAddInput = () => {
    if (!hideAddInput) {
      setHideAddInput(true);
      localStorage.setItem("hideGroceryAddItem", "true");
      setAddItemPadding(-40);
    } else {
      setHideAddInput(false);
      localStorage.setItem("hideGroceryAddItem", "false");
      setAddItemPadding(100);
    }
  };

  const history = useHistory();

  const handleOpenNewItem = () => {
    setIsAddingNewItem(true);
  };

  const handleClickItemCategory = (item: ItemModel) => {
    setSelectedItem(item);
    setIsCategoryModalOpen(true);
  };

  const handleClickItem = (item: ItemModel) => {
    setSelectedItem(item);
    setIsItemModalOpen(true);
  };

  const handleSelectCategory = async (category: string) => {
    const itemsRef = await getDefaultItemsCollection();
    const itemRef = doc(itemsRef, selectedItem?.id);
    await updateDoc(itemRef, {
      category,
    });
  };

  const handleClearCompleted = async () => {
    // console.log("clear completed", completedItems);
    const addedIds = completedItems.map((i) => i.id);
    await batchRemoveItems(addedIds);
  };

  const handleClickAddCategoryItem = (category: string | undefined) => {
    setSelectedCategory(category ?? "");
    inputRef.current?.focus();
  };

  const uncategorisedItems = addedItems.filter(
    (i) => !i.category || categories.findIndex((c) => c.id === i.category) < 0
  );

  const groupedItems = [...categories]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((c) => ({
      ...c,
      items: addedItems
        .filter((i) => i.category === c.id)
        .sort((a, b) => a.lowerName.localeCompare(b.lowerName)),
    }));

  if (uncategorisedItems.length > 0) {
    groupedItems.splice(0, 0, {
      id: "-1",
      name: t("generic-list-general"),
      order: -1,
      colour: "var(--colour-uncategorised)",
      items: uncategorisedItems.sort((a, b) =>
        a.lowerName.localeCompare(b.lowerName)
      ),
    });
  }

  const exportMail = () => {
    const Cat = groupedItems.filter((i) => i.items.length >= 1);
    const CatName = () => {
      return Cat.map((a) => a.name + a.items.map((b) => b.name));
    };

    //  window.location.href = window.location.href = `mailto:?subject=My%20Shopping%20List&body=Shopping%20list%20%0A${Cat.map((a) =>  "%0A" + a.name + "%0A" + a.items.map((b) => "-" + b.name + (b.notes != undefined ? ("%20-%20Notes:%20" + b.notes + "%0A"): "%0A")).join("")).join("")}`

    window.location.href =
      window.location.href = `mailto:?subject=My%20Shopping%20List&body=Shopping%20list%20%0A${Cat.map(
        (a) =>
          "%0A" +
          a.name +
          "%0A" +
          a.items
            .map((b) =>
              b.completed != true
                ? "-" +
                  b.name +
                  (b.quantity != undefined
                    ? "%20-%20Quantity:%20" + b.quantity + "%0A"
                    : "%0A") +
                  (b.notes != undefined
                    ? "%20-%20Notes:%20" + b.notes + "%0A"
                    : "%0A")
                : null
            )
            .join("")
      ).join("")}`;
  };

  const exportWidgetData = () => {
    const Cat = groupedItems.filter((i) => i.items.length >= 1);
    const CatName = () => {
      return Cat.map((a) => a.name + a.items.map((b) => b.name));
    };

    //  window.location.href = window.location.href = `mailto:?subject=My%20Shopping%20List&body=Shopping%20list%20%0A${Cat.map((a) =>  "%0A" + a.name + "%0A" + a.items.map((b) => "-" + b.name + (b.notes != undefined ? ("%20-%20Notes:%20" + b.notes + "%0A"): "%0A")).join("")).join("")}`

    const widgetShoppingList = Cat.map(
      (a) =>
        a.name.slice(0, 3).toUpperCase() +
        ": " +
        a.items
          .map((b) =>
            b.completed != true ? "()" + b.name.slice(0, 20) + " " : null
          )
          .join("")
    ).join("\r");

    //alert(widgetShoppigList);

    if ((window as any).isNative) {
      // Post the message back to expo

      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "shoppingListWidgetUpdate",
          widgetShoppingList: widgetShoppingList,
        })
      );
      // console.log("sent via React");
    } else {
    }
  };

  var checkShowCommpleted = JSON.parse(localStorage.getItem("ShowCompleted"));
  var checkShowAllCat = JSON.parse(localStorage.getItem("ShowAllCat"));

  //get tool bar padding for devices
  const getDeviceToolBarPadding = () => {
    // console.log("did I fire?");
    // console.log("what is the device width", window.innerWidth);
    var deviceWindow = window.innerWidth;
    var padding = 160;
    if (deviceWindow < 300) {
      padding = 190;
    }
    if (deviceWindow < 380) {
      padding = 170;
    }
    if (deviceWindow > 640) {
      padding = 160;
    }
    if (isTabClosed) {
      padding = 120;
    }
    setToolBarPadding(padding);
  };

  const handleClearBtnToggle = () => {
    const value = completedItems.length > 1 ? true : false;
    return value;
  };

  const lengthCheckedItems = completedItems.length;

  useEffect(() => {
    //console.log("showcompleted", checkShowCommpleted)
    // exportWidgetData();

    if (checkShowCommpleted != null) {
      setShowCompleted(checkShowCommpleted);
    } else {
      setShowCompleted(true);
    }
    getDeviceToolBarPadding();

    //console.log("showAllCat", checkShowAllCat)
    //I'm using the checkShowCompleted so that they match
    if (checkShowAllCat != null) {
      setShowAllCat(checkShowAllCat);
    } else {
      setShowAllCat(true);
    }

    window.addEventListener("resize", getDeviceToolBarPadding);
    // window.scrollTo({ top: 0, behavior: "smooth" });

    return () => {
      window.removeEventListener("resize", getDeviceToolBarPadding);
    };
  }, [allItems, addedItems, isTabClosed, isAddingNewItem]);

  return (
    <article>
      <FirebaseAnalytics />
      <Toaster containerStyle={{ marginTop: 125 }} />
      <div
        // className="grocery_list_add_bg"
        style={{
          position: "fixed",
          //backgroundColor: "lavender",

          opacity: 1,
          zIndex: 1,
          width: "100vw",
          marginTop: -15,
        }}
      >
        {!isTabClosed ? (
          <Modal
            isOpen={!isTabClosed}
            style={{
              overlay: {
                zIndex: 100,
                opacity: 0.98,
              },
            }}
          >
            <ListActions
              //  disableClearCompleted={completedItems.length === 0}
              items={allItems}
              showCompleted={showCompleted}
              setShowCompleted={setShowCompleted}
              showAllCat={showAllCat}
              setShowAllCat={setShowAllCat}
              emailList={exportMail}
              clearCompleted={handleClearCompleted}
              isDarkMode={isDarkMode}
              setIsAddingNewItem={() => setIsAddingNewItem(true)}
              isTabClosed={isTabClosed}
              setIsTabClosed={setIsTabClosed}
              LengthCheckedItems={lengthCheckedItems}
              categories={categories}
            />
          </Modal>
        ) : null}

        <div
          style={{
            backgroundColor: "ivory",
            marginLeft: -10,
            zIndex: 2,
            width: "100vw",
          }}
        >
          <div>
            {!hideAddInput ? (
              <AddItemHeader
                addedItems={addedItems}
                allItems={allItems}
                category={selectedCategory}
                ref={inputRef}
                setCategory={setSelectedCategory}
                isDarkMode={isDarkMode}
                setIsAddingNewItemCompleted={() => setIsAddingNewItem(false)}
                categories={categories}
              />
            ) : null}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            //  marginRight: 25,
            // maxWidth: 1048,
          }}
        >
          <button
            onClick={toggleAddInput}
            style={{
              //  width: "90vw",
              maxWidth: "1000px",
              marginTop: 0,
              padding: 15,
              paddingBottom: 10,
              backgroundColor: hideAddInput ? "purple" : "lightblue",
              color: hideAddInput ? "white" : "navy",
              cursor: "pointer",
              border: 0,
              //  boxShadow: "2px 3px 3px lightgrey",
              borderRadius: "0px 0px 10px 10px",
              fontSize: 14,
              marginLeft: 1,
            }}
          >
            {!hideAddInput
              ? t("generic-list-add-input-hide")
              : t("generic-list-add-input-show")}
          </button>
          {lengthCheckedItems >= 1 ? (
            <button
              onClick={() => setConfirmModalOpen(true)}
              style={{
                //  width: "90vw",
                maxWidth: "1000px",
                marginTop: 0,
                padding: 15,
                paddingBottom: 10,
                backgroundColor: "coral",
                color: "white",
                cursor: "pointer",
                border: 0,
                // boxShadow: "2px 3px 3px lightgrey",
                borderRadius: "0px 0px 10px 10px",
                fontSize: 14,
                marginLeft: 1,
              }}
            >
              {t("generic-list-clear-checked")}
            </button>
          ) : null}
          <button
            onClick={() => setIsTabClosed(false)}
            style={{
              //  width: "90vw",
              maxWidth: "1000px",
              marginTop: 0,
              padding: 15,
              paddingBottom: 10,
              backgroundColor: "cornflowerblue",
              color: "white",
              cursor: "pointer",
              border: 0,
              //  boxShadow: "2px 3px 3px lightgrey",
              borderRadius: "0px 0px 10px 10px",
              fontSize: 14,
              marginLeft: 1,
            }}
          >
            Options
          </button>
        </div>
      </div>

      <div style={{ paddingTop: toolBarPadding + addItemPadding + 30 }} />
      <h3 style={{ textAlign: "center", userSelect: "none" }}>
        {t("grocery-list-title")}
      </h3>
      {allItems.length === 0 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isOnline ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              {t("grocery-list-noitems")} 😞
              <p
                onClick={() => setIsEditDefaultItem(true)}
                className="actions_link_default"
                style={{
                  width: 175,
                  backgroundColor: "yellow",
                  padding: 20,
                  borderRadius: 5,
                }}
              >
                {t("grocery-list-loaditems")}
              </p>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              {t("grocery-list-noitems")} 😞 <br />
              <br />
              {t("grocery-list-offline-noitems")}
            </div>
          )}
        </div>
      ) : null}

      <Categories
        addedItems={addedItems}
        showCompleted={showCompleted}
        showAllCat={showAllCat}
        onClickAddCategoryItem={handleClickAddCategoryItem}
        onClickItem={handleClickItem}
        onClickItemCategory={handleClickItemCategory}
        isDarkMode={isDarkMode}
        addNewItem={handleOpenNewItem}
        categories={categories}
      />
      {isOnline ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontSize: 14,
              fontWeight: "bold",
              cursor: "pointer",
              color: "blue",
              textDecoration: "underline",
            }}
            onClick={() => setIsEditDefaultItem(true)}
          >
            {t("grocery-list-edit-list")}
          </p>
          {!isInvitation ? (
            <button
              className="metroButton"
              onClick={() => setIsInvitation(true)}
              style={{ backgroundColor: "cornflowerblue", color: "white" }}
            >
              Share Grocery List and Meal Planner
            </button>
          ) : (
            <span
              style={{
                fontSize: 14,
                fontWeight: "bold",
                paddingTop: 10,
                marginTop: 20,
                textAlign: "center",
                // backgroundColor: "yellow",
              }}
            >
              Click on the icon below to create a shareable invitation code for
              adding another member to Grocery List. <br />
              <br />
              <InviteMember />
              <p
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => setIsInvitation(false)}
              >
                Close Invitation
              </p>
            </span>
          )}
        </div>
      ) : (
        <p
          style={{
            textAlign: "center",
            fontSize: 14,
            fontWeight: "bold",
            cursor: "pointer",
            color: "gray",
            textDecoration: "underline",
          }}
          // onClick={() => setIsEditDefaultItem(true)}
        >
          {t("grocery-list-edit-list-no-internet")}
        </p>
      )}

      <div style={{ paddingTop: 20 }} />

      {/*</div>*/}

      {isCategoryModalOpen ? (
        <CategoryModal
          isOpen={isCategoryModalOpen}
          selectedCategoryId={selectedItem?.category}
          close={() => setIsCategoryModalOpen(false)}
          select={handleSelectCategory}
          isDarkMode={isDarkMode}
          categories={categories}
        />
      ) : null}

      {isItemModalOpen ? (
        <ItemModal
          isOpen={isItemModalOpen}
          item={selectedItem}
          close={() => setIsItemModalOpen(false)}
        />
      ) : null}

      {isEditDefaultItem ? (
        <EditDefaultItemsModal
          isOpen={isEditDefaultItem}
          close={() => setIsEditDefaultItem(false)}
        />
      ) : null}
      <CheckGroupStatus isGroupValid={isGroupValid} />
      <ConfirmationModal
        isOpen={confirmationModal}
        text={t("generic-list-action-del-warning")}
        onClickCancel={() => setConfirmModalOpen(false)}
        onClickConfirm={() => {
          handleClearCompleted();
          setConfirmModalOpen(false);
        }}
      />
      <div style={{ paddingBottom: 200 }} />
    </article>
  );
};

import { Button } from "Common";

import { Modal, ModalProps } from "../Modal";
import { useTranslation } from "react-i18next";
import classes from "./ConfirmationModal.module.css";

export interface ConfirmationModalProps extends Omit<ModalProps, "children"> {
  text: string;
  onClickCancel: () => void;
  onClickConfirm: () => void;
}

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { text, onClickCancel, onClickConfirm, ...modalProps } = props;
  const { t } = useTranslation();
  return (
    <Modal
      onRequestClose={onClickCancel}
      {...modalProps}
      style={{ overlay: { zIndex: 100 } }}
    >
      <h3 style={{ paddingLeft: 20, paddingRight: 20, fontSize: 24 }}>
        Confirm
      </h3>
      <p style={{ paddingLeft: 20, paddingRight: 20, fontSize: 20 }}>{text}</p>
      <div
        className={classes.actions}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button
          onClick={onClickCancel}
          className={classes.secondaryButton}
          style={{
            fontFamily: "inherit",
            fontSize: "inherit",
            fontWeight: "bold",
            border: 0,
          }}
        >
          {t("common-button-cancel")}
        </button>
        <button
          style={{
            fontFamily: "inherit",
            fontSize: "inherit",
            fontWeight: "bold",
            border: 0,
          }}
          onClick={onClickConfirm}
        >
          {t("common-button-confirm")}
        </button>
      </div>
    </Modal>
  );
};

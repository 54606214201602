//Add Habit

import { useState } from "react";

import {
  Button,
  ButtonsContainer,
  CenterLoader,
  NewHabit,
  WeekDays,
} from "../HabitsPage/style";

import { DayOfTheWeek } from "../DayOfTheWeek/index";

import toast, { Toaster } from "react-hot-toast";
import { Modal } from "Common";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faClose } from "@fortawesome/free-solid-svg-icons";
import { ReminderForAddNewHabit } from "../generic/ReminderForAddNewHabit";
import { useTranslation } from "react-i18next";

export const HabitForm = ({
  isOpen,
  newHabit,
  setNewHabit,
  close,
  addTracking,
  addPlanner,
  user,
  isEdit,
}) => {
  const [isReminder, setIsReminder] = useState(false);
  const { t } = useTranslation();

  const next = (e) => {
    e.preventDefault();
    setIsReminder(true);
    close();
  };

  const CloseReminder = () => {
    setNewHabit({
      name: "",
      days: [0, 1, 2, 3, 4, 5, 6],
      isTrackingReps: false,
      RepsLabel: "",
      isPlanner: true,
    });
    setIsReminder(false);
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        // onRequestClose={close}
        style={{ overlay: { zIndex: 100 } }}
      >
        <div style={{ marginTop: -30, display: "flex" }}>
          <Toaster containerStyle={{ marginTop: 125 }} />
          <NewHabit>
            <form onSubmit={next}>
              <span style={{ marginTop: 5 }}>
                <h2 style={{ lineHeight: "1.3em", userSelect: "none" }}>
                  {" "}
                  {t("sections-habits-form-newhabit")}
                </h2>
              </span>
              <input
                type="text"
                value={newHabit.name}
                maxLength={40}
                onChange={(e) =>
                  setNewHabit({ ...newHabit, ["name"]: e.target.value })
                }
                style={{
                  fontFamily: "inherit",
                  fontSize: "inherit",
                  padding: 10,
                  width: "90%",
                }}
                placeholder={t("sections-habits-form-newhabit-placeholder")}
                required
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "left",
                  justifyContent: "left",
                  paddingleft: 10,
                  paddingTop: 30,
                  paddingBottom: 10,
                }}
              >
                <input
                  type="checkbox"
                  style={{
                    transform: "scale(2.3)",
                    marginLeft: 20,
                    marginTop: 0,
                  }}
                  onChange={addTracking}
                  checked={newHabit.isTrackingReps}
                />
                <span style={{ paddingLeft: 20, userSelect: "none" }}>
                  {t("sections-habits-form-reps-checkbox")}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "left",
                  justifyContent: "left",
                  paddingleft: 10,
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                <input
                  type="checkbox"
                  style={{
                    transform: "scale(2.3)",
                    marginLeft: 20,
                    marginTop: 0,
                  }}
                  onChange={addPlanner}
                  checked={newHabit.isPlanner}
                />
                <span style={{ paddingLeft: 20, userSelect: "none" }}>
                  {t("sections-habits-form-showplanner-checkbox")}
                </span>
              </div>

              {newHabit.isTrackingReps ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "left",
                    justifyContent: "left",
                    flexDirection: "column",
                    paddingleft: 10,
                    paddingTop: 20,
                    paddingBottom: 10,
                  }}
                >
                  <span style={{ marginTop: 5, userSelect: "none" }}>
                    {t("sections-habits-form-trackunit")}{" "}
                  </span>
                  <input
                    type="text"
                    value={newHabit.RepsLabel}
                    onChange={(e) =>
                      setNewHabit({
                        ...newHabit,
                        ["RepsLabel"]: e.target.value,
                      })
                    }
                    style={{ padding: 10, fontSize: 16, width: "100%" }}
                    placeholder="e.g. km, miles, reps, laps, hours ..."
                  />
                </div>
              ) : null}

              <p style={{ paddingBottom: 0, userSelect: "none" }}>
                {t("sections-habits-form-daynotes")}
              </p>
              <WeekDays>
                {Array.from(Array(7).keys()).map((element, index) => (
                  <div style={{ paddingLeft: 5 }} key={index}>
                    <DayOfTheWeek
                      key={element}
                      state={newHabit}
                      setState={setNewHabit}
                      createHabit={true}
                      weekday={element}
                    />
                  </div>
                ))}
              </WeekDays>
              <ButtonsContainer>
                {/*}  <Button
                  smallerFontSize={true}
                  fontColor={true}
                  width={"95px"}
                  style={{ fontFamily: "inherit", fontSize: "inherit" }}
                  background={"#FFF"}
                  onClick={() => {
                    setCreateHabit(false);
                  }}
                >
                  Cancel
                </Button>*/}
                <Button
                  smallerFontSize={true}
                  width={"95px"}
                  style={{ fontFamily: "inherit", fontSize: "inherit" }}
                  background={"#52B6FF"}
                  type="submit"
                >
                  {t("sections-habits-form-next-button")}
                </Button>
              </ButtonsContainer>
            </form>
          </NewHabit>
        </div>
        <div style={{ padding: 100 }} />
      </Modal>
      <ReminderForAddNewHabit
        data={newHabit}
        user={user}
        isReminder={isReminder}
        setIsReminder={CloseReminder}
        isEdit={isEdit}
      />
    </div>
  );
};

export const commonGroceryItemsMiddleEast = [
  { category: "100100", name: "طعام الأطفال" }, // Baby Food
  { category: "100100", name: "مناديل مبللة للأطفال" }, // Baby Wipes
  { category: "100100", name: "حفاضات أطفال" }, // Baby Diapers
  { category: "100100", name: "حليب الأطفال" }, // Baby Formula
  { category: "100100", name: "لوشن الأطفال" }, // Baby Lotion
  { category: "100200", name: "خبز" }, // Bread
  { category: "100200", name: "كرواسون" }, // Croissants
  { category: "100200", name: "خبز القمح الكامل" }, // Whole Wheat Bread
  { category: "100200", name: "خبز الباغيت" }, // Baguette
  { category: "100200", name: "خبز الطحين" }, // Flour Bread
  { category: "100300", name: "طحين" }, // Flour
  { category: "100300", name: "سكر" }, // Sugar
  { category: "100300", name: "بيكنج بودر" }, // Baking Powder
  { category: "100300", name: "فانيلا" }, // Vanilla
  { category: "100300", name: "مسحوق الكاكاو" }, // Cocoa Powder
  { category: "100400", name: "ماء معدني" }, // Mineral Water
  { category: "100400", name: "عصير برتقال" }, // Orange Juice
  { category: "100400", name: "قهوة" }, // Coffee
  { category: "100400", name: "شاي" }, // Tea
  { category: "100400", name: "مشروبات غازية" }, // Soda
  { category: "100500", name: "مسحوق غسيل" }, // Detergent
  { category: "100500", name: "سائل غسيل الصحون" }, // Dishwashing Liquid
  { category: "100500", name: "منظف متعدد الاستخدامات" }, // All-purpose Cleaner
  { category: "100500", name: "مناشف ورقية" }, // Paper Towels
  { category: "100500", name: "أكياس القمامة" }, // Trash Bags
  { category: "100600", name: "صلصة الطماطم" }, // Tomato Sauce
  { category: "100600", name: "خردل" }, // Mustard
  { category: "100600", name: "مايونيز" }, // Mayonnaise
  { category: "100600", name: "تتبيلة السلطة" }, // Salad Dressing
  { category: "100600", name: "كاتشب" }, // Ketchup
  { category: "100700", name: "حليب" }, // Milk
  { category: "100700", name: "زبدة" }, // Butter
  { category: "100700", name: "جبن" }, // Cheese
  { category: "100700", name: "زبادي" }, // Yogurt
  { category: "100700", name: "كريمة" }, // Cream
  { category: "100800", name: "بيتزا مجمدة" }, // Frozen Pizza
  { category: "100800", name: "خضروات مجمدة" }, // Frozen Vegetables
  { category: "100800", name: "آيس كريم" }, // Ice Cream
  { category: "100800", name: "وجبات مجمدة جاهزة" }, // Frozen Prepared Meals
  { category: "100800", name: "بطاطا مجمدة" }, // Frozen Fries
  { category: "100900", name: "تفاح" }, // Apples
  { category: "100900", name: "موز" }, // Bananas
  { category: "100900", name: "طماطم" }, // Tomatoes
  { category: "100900", name: "خس" }, // Lettuce
  { category: "100900", name: "جزر" }, // Carrots
  { category: "100900", name: "بصل" }, // Onions
  { category: "100900", name: "فلفل" }, // Bell Peppers
  { category: "100900", name: "بطاطس" }, // Potatoes
  { category: "100900", name: "ثوم" }, // Garlic
  { category: "100900", name: "سبانخ" }, // Spinach
  { category: "101100", name: "لحم بقر" }, // Beef
  { category: "101100", name: "لحم خنزير" }, // Pork
  { category: "101100", name: "دجاج" }, // Chicken
  { category: "101100", name: "ديك رومي" }, // Turkey
  { category: "101100", name: "لحم غنم" }, // Lamb
  { category: "101200", name: "أدوية للصداع" }, // Headache Medicine
  { category: "101200", name: "ضمادات" }, // Bandages
  { category: "101200", name: "مقياس حرارة" }, // Thermometer
  { category: "101200", name: "مطهر" }, // Antiseptic
  { category: "101200", name: "كمامات" }, // Face Masks
  { category: "101500", name: "أرز" }, // Rice
  { category: "101500", name: "معكرونة" }, // Pasta
  { category: "101500", name: "زيت زيتون" }, // Olive Oil
  { category: "101500", name: "عدس" }, // Lentils
  { category: "101500", name: "فاصولياء" }, // Beans
  { category: "101700", name: "طعام الكلاب" }, // Dog Food
  { category: "101700", name: "طعام القطط" }, // Cat Food
  { category: "101700", name: "رمل القطط" }, // Cat Litter
  { category: "101700", name: "ألعاب الحيوانات الأليفة" }, // Pet Toys
  { category: "101700", name: "شامبو الحيوانات الأليفة" }, // Pet Shampoo
  { category: "101800", name: "سمك السلمون" }, // Salmon
  { category: "101800", name: "جمبري" }, // Shrimp
  { category: "101800", name: "سمك القد" }, // Cod
  { category: "101800", name: "حبار" }, // Squid
  { category: "101800", name: "سرطان البحر" }, // Crab
  { category: "101900", name: "شيبس" }, // Chips
  { category: "101900", name: "شوكولاتة" }, // Chocolate
  { category: "101900", name: "بسكويت" }, // Biscuits
  { category: "101900", name: "فواكه مجففة" }, // Dried Fruits
  { category: "101900", name: "مكسرات" }, // Nuts
  { category: "102000", name: "ملح" }, // Salt
  { category: "102000", name: "فلفل" }, // Pepper
  { category: "102000", name: "كاري" }, // Curry
  { category: "102000", name: "بابريكا" }, // Paprika
  { category: "102000", name: "قرفة" }, // Cinnamon
  { category: "102500", name: "ريحان" }, // Basil
  { category: "102500", name: "بقدونس" }, // Parsley
  { category: "102500", name: "زعتر" }, // Thyme
  { category: "102500", name: "روزماري" }, // Rosemary
  { category: "102500", name: "نعناع" }, // Mint
  { category: "100200", name: "معجنات" }, // Pastries
  { category: "100200", name: "كعك" }, // Cake
  { category: "100200", name: "مناقيش" }, // Manakish
  { category: "100300", name: "سكر بني" }, // Brown Sugar
  { category: "100300", name: "خلاصة الفانيليا" }, // Vanilla Extract
  { category: "100400", name: "خمر أحمر" }, // Red Wine
  { category: "100400", name: "خمر أبيض" }, // White Wine
  { category: "100400", name: "بيرة" }, // Beer
  { category: "100400", name: "ماء غازي" }, // Sparkling Water
  { category: "100500", name: "صابون" }, // Soap
  { category: "100500", name: "شامبو" }, // Shampoo
  { category: "100500", name: "منظف النوافذ" }, // Window Cleaner
  { category: "100500", name: "معطر الجو" }, // Air Freshener
  { category: "100500", name: "إسفنج" }, // Sponges
  { category: "100600", name: "صلصة ثوم" }, // Garlic Sauce
  { category: "100600", name: "حمص" }, // Hummus
  { category: "100600", name: "طحينة" }, // Tahini
  { category: "100600", name: "صلصة الباربكيو" }, // Barbecue Sauce
  { category: "100600", name: "صلصة الصويا" }, // Soy Sauce
  { category: "100900", name: "فطر" }, // Mushrooms
  { category: "100900", name: "خيار" }, // Cucumbers
  { category: "100900", name: "كوسا" }, // Zucchini
  { category: "100900", name: "باذنجان" }, // Eggplant
  { category: "100900", name: "فلفل حار" }, // Hot Peppers
  { category: "100900", name: "فلفل ألوان" }, // Bell Peppers
  { category: "100900", name: "بطاطا حلوة" }, // Sweet Potatoes
  { category: "100900", name: "لفت" }, // Turnips
  { category: "100900", name: "بقدونس" }, // Parsley
  { category: "100900", name: "كزبرة" }, // Coriander
  { category: "101100", name: "لحم الضأن" }, // Mutton
  { category: "101100", name: "كبدة" }, // Liver
  { category: "101100", name: "كفتة" }, // Kofta
  { category: "101100", name: "سمك" }, // Fish
  { category: "101100", name: "جمبري" }, // Prawns
  { category: "101100", name: "سمك الهامور" }, // Grouper
  { category: "101100", name: "سمك التونة" }, // Tuna
  { category: "101100", name: "سردين" }, // Sardines
  { category: "101100", name: "سمك السلمون" }, // Salmon
  { category: "101100", name: "سمك البلطي" }, // Tilapia
  { category: "101200", name: "زيت الزيتون" }, // Olive Oil
  { category: "101200", name: "طحينة" }, // Tahini
  { category: "101200", name: "حلاوة" }, // Halva
  { category: "101200", name: "مربى" }, // Jam
  { category: "101200", name: "عسل" }, // Honey
  { category: "101500", name: "برغل" }, // Bulgur
  { category: "101500", name: "فريكة" }, // Freekeh
  { category: "101500", name: "كسكس" }, // Couscous
  { category: "101500", name: "أرز بسمتي" }, // Basmati Rice
  { category: "101500", name: "معكرونة" }, // Pasta
  { category: "101700", name: "أغذية الحيوانات الأليفة" }, // Pet Food
  { category: "101700", name: "مستلزمات الحيوانات الأليفة" }, // Pet Supplies
  { category: "101700", name: "لعب الحيوانات الأليفة" }, // Pet Toys
  { category: "101700", name: "منتجات العناية بالحيوانات الأليفة" }, // Pet Care Products
  { category: "101800", name: "بلح البحر" }, // Mussels
  { category: "101800", name: "استاكوزا" }, // Lobster
  { category: "101800", name: "كابوريا" }, // Crab
  { category: "101800", name: "كاليماري" }, // Calamari
  { category: "101800", name: "أخطبوط" }, // Octopus
  { category: "101900", name: "كعك" }, // Cakes
  { category: "101900", name: "حلويات شرقية" }, // Oriental Sweets
  { category: "101900", name: "بقلاوة" }, // Baklava
  { category: "101900", name: "معمول" }, // Maamoul
  { category: "101900", name: "كنافة" }, // Kunafa
  { category: "102000", name: "كمون" }, // Cumin
  { category: "102000", name: "كركم" }, // Turmeric
  { category: "102000", name: "زعفران" }, // Saffron
  { category: "102000", name: "فلفل أسود" }, // Black Pepper
  { category: "102000", name: "قرفة" }, // Cinnamon
];

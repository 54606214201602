import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
//import firebase from "firebase/app";
//import "firebase/firestore";
//import "firebase/functions";
import { getFunctions, httpsCallable } from "firebase/functions";
import { doc, getDoc } from "firebase/firestore";
import { Timestamp } from "firebase/firestore";

//import firebase from "firebase/compat/app";
//import "firebase/compat/functions";
//import "firebase/compat/firestore";

import { useGroupSubscription } from "Authentication/useGroupSubscription";
import { useUserContext } from "Authentication";
import { useGroup } from "Authentication";
import { FullPageSpinner } from "Common";
import { db } from "Config";
import { Invitation } from "../models";

import classes from "./JoinHousehold.module.css";

interface Params {
  id: string;
}

var isWarning = false;

const functions = getFunctions();
const acceptInvitation = httpsCallable(functions, "acceptInvitation");

export const JoinHousehold = () => (
  <article>
    <h2>Join New Group</h2>
    <JoinHouseholdContent />
  </article>
);

export const JoinHouseholdContent = () => {
  const { id } = useParams<Params>();
  const history = useHistory();
  const { refreshUser } = useUserContext();
  const [error, setError] = useState<string>();
  const [working, setWorking] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [invite, setInvite] = useState<Invitation>();
  //const { deleteUserFromGroup } = useUserContext()
  const user = useUserContext();
  const { groupUser } = useGroupSubscription();
  const { deleteGroupUsers } = useUserContext();

  var groupMemberID = "";

  const checkMemberID = (memberName, memberID) => {
    if (memberName === user.user.name) {
      groupMemberID = memberID;
      //console.log("Did it set", memberID)
      isWarning = true;
    }
  };

  const routeChange = (path) => {
    history.push(path);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docSnapshot = await getDoc(doc(db, "invites", id));

        if (!docSnapshot.exists()) {
          setError("Invalid invitation URL");
        } else {
          setInvite(docSnapshot.data() as Invitation);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [id]);

  const handleAccept = async () => {
    try {
      setWorking(true);
      //remove the user from any existing group

      //    await deleteUserFromGroup(user.user.id)

      await acceptInvitation({ inviteId: id });
      deleteGroupUsers(groupMemberID);
      await refreshUser();
      //@ts-ignore
      window.location.reload(true);
      history.push("/settings");
    } catch (err) {
      setError((err as Error).message);
      setWorking(false);
    }
  };

  if (fetching || working) {
    return <FullPageSpinner />;
  }

  if (error || !invite) {
    //@ts-ignore
    if (error != "Invalid invitation url") {
      //@ts-ignore
      history.push("/settings");
    }

    if (error === "Invalid invitation url") {
      //@ts-ignore
      history.push("/settings");
    }

    return <span>There was a problem... {error}</span>;
  }

  if (Timestamp.now().seconds - invite.created.seconds > 60 * 60 * 240) {
    return (
      <span>Invitation expired. Please request a new invitation code.</span>
    );
  }

  return (
    <div className={classes.accept}>
      <div style={{ fontSize: 24 }}>
        You've been invited to join {invite.group.name} by {invite.inviter.name}
      </div>
      <div>
        <button
          style={{
            fontFamily: "inherit",
            fontSize: "inherit",
            padding: 10,
            paddingLeft: 20,
            paddingRight: 20,
            backgroundColor: "blue",
            color: "white",
            border: 0,
            borderRadius: 5,
          }}
          onClick={handleAccept}
        >
          Accept
        </button>
      </div>
      {isWarning ? (
        <h4>If you accept, you'll be leaving the following group.</h4>
      ) : null}
      {groupUser.map((member) => (
        <span key={member.id}>
          <p key={member.id}> {member.name}</p>
          {checkMemberID(member.name, member.id)}
        </span>
      ))}
    </div>
  );
};

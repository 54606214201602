import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { EventsModalEdit } from "./EventsModalEdit";

export const JournalEditLanding = ({
  data,
  isExpired,
  isDarkMode,
  isOnline,
}) => {
  const { productId } = useParams();
  const event = data.find((p) => p.id === productId);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {};
  }, []);

  let productData;

  if (event) {
    productData = (
      <div>
        <EventsModalEdit
          isOpen={true}
          event={event}
          //  close={() => setIsEventsModalEditOpen(false)}
          isDarkMode={isDarkMode}
          isOnline={isOnline}
        />
      </div>
    );
  } else {
    productData = <h2> Sorry. Journal doesn't exist. </h2>;
  }

  return <div>{productData}</div>;
};

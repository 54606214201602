import React from "react";
import { useParams } from "react-router-dom";
import { Note } from "./Note";

export const NoteLanding = ({
  data,
  isExpired,
  handleDeleteNote,
  handleEditNote,
}) => {
  const { productId } = useParams();
  const product = data.find((p) => p.id === productId);

  //const handleDeleteNote = () => console.log("Deletefired");
  //const handleEditNote = () => console.log("Editfired");
  //const isExpired = false;

  let productData;

  if (product) {
    productData = (
      <div>
        <Note
          key={product.id}
          id={product.id}
          text={product.text}
          tags={product.tags}
          date={product.date}
          color={product.color}
          handleDeleteNote={handleDeleteNote}
          handleEditNote={handleEditNote}
          isExpired={{ isExpired }}
          isLanding={true}
        />
      </div>
    );
  } else {
    productData = <h2> Sorry. page doesn't exist </h2>;
  }

  return (
    <div>
      <div>{productData}</div>
    </div>
  );
};

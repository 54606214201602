import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FeedbackWidget } from "Splash/RegisteredSplash/sections/FeedbackWidget";
//import { Accordion } from "./Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
//import ReactPlayer from "react-player";
//@ts-ignore

import helpicon from "../../../src/Assets/help.png";

export const Help = () => {
  const accordionData = [
    {
      title: "Can I share my account with my family",
      content: `Absolutely, it provides a convenient method for sharing with your entire family.`,
    },

    {
      title: "Exporting Notes or Journal Entries",
      content: `You can now export all your notes or journals using the WebApp at alohaplanner.app.  Please log into your account then select Export to CSV at the top. `,
    },
    {
      title: "Change theme border color",
      content: `Select the paint roller icon in the Profile/Settings screen section. `,
    },
    {
      title: "Change system font",
      content: `Go to the Profile/Settings screen.  Look for "Font", select the pencil icon to change the font.   `,
    },
    {
      title: "Subscription options",
      content: `We offer 1 month, 3 month, 6 month, 12 month and lifetime subscription options.  All subscriptions include full access, data, storage, support and future updates. The 12 month subscription is roughly $0.58 USD per month.`,
    },
    {
      title: "What other device can I use",
      content: `We offer WebApp, IOS and Android app.  Login with your email and password.`,
    },
    {
      title: "Do you sell data",
      content: `We do not.  Revenue is generated via subscriptions.`,
    },
    {
      title: "I see double entries, how can I fix this?",
      content: `Please press and hold the screen, then pull down.  This will reload the data.  Alternatively, you can also try to close then reopen Dope Notes.  If you are still experiencing issues, please contact us at support@dopenotes.io`,
    },
  ];
  const { t } = useTranslation();
  //const [isActiveVideo, setIsActiveVideo] = useState(false);
  //const [isUnsubscribed, setIsUnsubscribed] = useState(false);
  const [isNative, setIsNative] = useState(true);
  const [isIOS, setIsIOS] = useState(false);
  const [isFeedback, setIsFeedback] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (window.isNative) {
      setIsNative(true);
    } else {
      setIsNative(false);
    }
    if (window.isIOS) {
      setIsIOS(true);
    } else {
      setIsIOS(false);
    }

    return () => {};
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      <div>
        <h2 className="h2SlideIn">{t("legal-title")}</h2>
        {isFeedback ? (
          <div style={{ backgroundColor: "lavender", paddingTop: 20 }}>
            <button
              style={{
                float: "right",
                backgroundColor: "transparent",
                borderStyle: "none",
                cursor: "pointer",
                marginTop: -10,
                marginRight: 10,
              }}
              onClick={() => setIsFeedback(false)}
            >
              <FontAwesomeIcon icon={faClose} size="1x" />
            </button>
            <FeedbackWidget close={() => setIsFeedback(false)} />
          </div>
        ) : (
          <p
            className="actions_link_default"
            onClick={() => setIsFeedback(true)}
          >
            {t("introhelp-button-contact")}
          </p>
        )}

        <p className="actions_link_default">
          {" "}
          <Link
            className="actions_link_default"
            to={{ pathname: "https://forms.gle/7iPExMZtYcBLudcP7" }}
            target="_blank"
          >
            {t("legal-feedback")}
          </Link>
        </p>

        <p className="actions_link_default">
          {" "}
          <Link
            className="actions_link_default"
            to={{ pathname: "https://apps.apple.com/account/subscriptions" }}
            target="_blank"
          >
            Apple {t("buynow-subscription-management")}
          </Link>
        </p>

        {!isIOS ? (
          <p className="actions_link_default">
            {" "}
            <Link
              className="actions_link_default"
              to={{
                pathname: "https://play.google.com/store/account/subscriptions",
              }}
              target="_blank"
            >
              Google {t("buynow-subscription-management")}
            </Link>
          </p>
        ) : null}

        <p className="actions_link_default">
          {" "}
          <NavLink
            to="/terms"
            title="Terms and Conditions"
            className="actions_link_default"
          >
            {t("legal-terms")}
          </NavLink>
        </p>

        <p style={{ textAlign: "center" }} className="actions_link_default">
          {" "}
          <NavLink
            to="/privacy"
            title="Privacy Policy"
            className="actions_link_default"
          >
            {t("legal-privacy")}
          </NavLink>
        </p>

        {!isNative ? (
          <div className="actions_link_default" style={{ paddingBottom: 20 }}>
            {" "}
            <NavLink
              to="/cookiepolicy"
              title="Cookie Policy"
              className="actions_link_default"
            >
              {t("legal-cookie")}
            </NavLink>
          </div>
        ) : null}

        {isIOS ? (
          <>
            {" "}
            <label style={{ textAlign: "center" }}>Apple EULA</label>
            <p className="actions_link_default">
              <a
                className="actions_link_default"
                href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
                target="_blank"
              >
                https://www.apple.com/legal/internet-services/itunes/dev/stdeula/
              </a>
            </p>
          </>
        ) : (
          <>
            {" "}
            <label style={{ textAlign: "center" }}>Apple EULA</label>
            <p className="actions_link_default">
              <a
                className="actions_link_default"
                href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
                target="_blank"
              >
                https://www.apple.com/legal/internet-services/itunes/dev/stdeula/
              </a>
            </p>
            <label style={{ textAlign: "center" }}>Google Terms</label>
            <p className="actions_link_default">
              <a
                className="actions_link_default"
                href="https://play.google.com/intl/en_us/about/play-terms/index.html"
                target="_blank"
              >
                https://play.google.com/intl/en_us/about/play-terms/index.html
              </a>
            </p>
          </>
        )}
      </div>

      <div style={{ paddingBottom: 100 }} />
    </div>
  );
};

export const classicRecipesFR = [
  {
    id: "dnr003",
    recipeUrl: "",
    name: "Spaghetti et Boulettes de Viande",
    directions: `
            <ol>
              <li>Préchauffez le four à 175 degrés C (350 degrés F).</li>
              <li>Dans un grand bol, combinez le bœuf haché, la chapelure, le fromage Parmesan, les œufs, l'oignon, l'ail, le sel et le poivre. Mélangez bien.</li>
              <li>Formez le mélange de viande en boulettes d'environ 2,5 cm de diamètre.</li>
              <li>Faites chauffer l'huile d'olive dans une grande poêle à feu moyen.</li>
              <li>Faites cuire les boulettes dans l'huile chaude pendant 10-12 minutes, ou jusqu'à ce qu'elles soient dorées de tous les côtés.</li>
              <li>Transférez les boulettes dans un plat à four.</li>
              <li>Dans une casserole, combinez la sauce tomate, l'eau et l'assaisonnement italien. Portez à ébullition à feu moyen.</li>
              <li>Verser la sauce tomate sur les boulettes.</li>
              <li>Faites cuire au four préchauffé pendant 20 minutes, ou jusqu'à ce que les boulettes soient bien cuites.</li>
              <li>Servez sur des spaghettis cuits.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Italien",
        color: "indigo",
      },
      {
        text: "Dîner",
        color: "indigo",
      },
      {
        text: "Plat Principal",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "bœuf haché",
        measurement: "1 livre",
      },
      {
        name: "chapelure",
        measurement: "1/2 tasse",
      },
      {
        name: "fromage Parmesan râpé",
        measurement: "1/4 tasse",
      },
      {
        name: "œufs",
        measurement: "2 œufs battus",
      },
      {
        name: "oignon",
        measurement: "1/2 tasse, haché",
      },
      {
        name: "ail",
        measurement: "2 gousses, émincées",
      },
      {
        name: "sel",
        measurement: "1 cuillère à café",
      },
      {
        name: "poivre noir",
        measurement: "1/2 cuillère à café",
      },
      {
        name: "huile d'olive",
        measurement: "1/4 tasse",
      },
      {
        name: "Tomates concassées en conserve",
        measurement: "1 (28 onces)",
      },
      {
        name: "eau",
        measurement: "1 tasse",
      },
      {
        name: "assaisonnement italien",
        measurement: "1 cuillère à café",
      },
      {
        name: "spaghetti",
        measurement: "12 onces",
      },
    ],
  },

  {
    id: "X7gH5Kl2pQ",
    recipeUrl: "",
    name: "Ratatouille",
    directions:
      "<ol><li>Coupez tous les légumes en rondelles fines.</li><li>Faites revenir les oignons et l'ail dans de l'huile d'olive.</li><li>Ajoutez les autres légumes et faites-les cuire à feu doux.</li><li>Assaisonnez avec du sel, du poivre et des herbes de Provence.</li><li>Laissez mijoter jusqu'à ce que les légumes soient tendres.</li></ol>",
    days: [],
    tags: [
      { text: "Végétarien", color: "indigo" },
      { text: "Cuisine française", color: "indigo" },
      { text: "Plat principal", color: "indigo" },
    ],
    ingredients: [
      { name: "Tomates", measurement: "4" },
      { name: "Courgettes", measurement: "2" },
      { name: "Aubergines", measurement: "2" },
      { name: "Poivrons", measurement: "2" },
      { name: "Oignon", measurement: "1" },
      { name: "Ail", measurement: "2 gousses" },
      { name: "Huile d'olive", measurement: "2 cuillères à soupe" },
      { name: "Herbes de Provence", measurement: "1 cuillère à café" },
      { name: "Sel", measurement: "Au goût" },
      { name: "Poivre", measurement: "Au goût" },
    ],
  },
  {
    id: "L8m9Zd3KvP",
    recipeUrl: "",
    name: "Bouillabaisse",
    directions:
      "<ol><li>Faites revenir l'oignon et l'ail dans de l'huile d'olive dans une grande casserole.</li><li>Ajoutez les tomates, le laurier, le fenouil, le safran, et un peu de sel et de poivre, puis faites cuire pendant quelques minutes.</li><li>Ajoutez le poisson et les fruits de mer, puis couvrez avec de l'eau et portez à ébullition.</li><li>Réduisez le feu et laissez mijoter pendant environ 20 minutes.</li><li>Servez chaud avec du pain grillé et de la rouille.</li></ol>",
    days: [],
    tags: [
      { text: "Fruits de mer", color: "indigo" },
      { text: "Cuisine française", color: "indigo" },
      { text: "Soupe", color: "indigo" },
    ],
    ingredients: [
      { name: "Oignon", measurement: "1" },
      { name: "Ail", measurement: "3 gousses" },
      { name: "Huile d'olive", measurement: "3 cuillères à soupe" },
      { name: "Tomates", measurement: "400g" },
      { name: "Feuille de laurier", measurement: "2" },
      { name: "Fenouil", measurement: "1 bulbe" },
      { name: "Safran", measurement: "1 pincée" },
      { name: "Sel", measurement: "Au goût" },
      { name: "Poivre", measurement: "Au goût" },
      { name: "Poisson varié", measurement: "1.5kg" },
      { name: "Fruits de mer", measurement: "400g" },
      { name: "Eau", measurement: "Selon besoin" },
      { name: "Pain grillé", measurement: "Accompagnement" },
      { name: "Rouille", measurement: "Accompagnement" },
    ],
  },
  {
    id: "Q4hF9zB3Wp",
    recipeUrl: "",
    name: "Coq au Vin",
    directions:
      "<ol><li>Faites dorer les morceaux de poulet dans une cocotte avec du beurre.</li><li>Ajoutez les lardons, l'oignon émincé, l'ail, et faites revenir jusqu'à ce qu'ils soient dorés.</li><li>Saupoudrez de farine, puis ajoutez le vin rouge, le bouillon de poule, les champignons, les carottes, le thym, la feuille de laurier, du sel et du poivre.</li><li>Laissez mijoter à feu doux pendant environ 1 heure 30 minutes.</li><li>Retirez les morceaux de poulet et réduisez la sauce si nécessaire. Remettez le poulet dans la cocotte pour le réchauffer.</li><li>Servez chaud avec des pommes de terre ou du pain frais.</li></ol>",
    days: [],
    tags: [
      { text: "Plat principal", color: "indigo" },
      { text: "Cuisine française", color: "indigo" },
      { text: "Viande", color: "indigo" },
    ],
    ingredients: [
      { name: "Poulet", measurement: "1 entier coupé en morceaux" },
      { name: "Beurre", measurement: "50g" },
      { name: "Lardons", measurement: "150g" },
      { name: "Oignon", measurement: "1" },
      { name: "Ail", measurement: "2 gousses" },
      { name: "Farine", measurement: "2 cuillères à soupe" },
      { name: "Vin rouge", measurement: "750 ml" },
      { name: "Bouillon de poule", measurement: "250 ml" },
      { name: "Champignons", measurement: "250g" },
      { name: "Carottes", measurement: "2" },
      { name: "Thym", measurement: "1 branche" },
      { name: "Feuille de laurier", measurement: "1" },
      { name: "Sel", measurement: "Au goût" },
      { name: "Poivre", measurement: "Au goût" },
      { name: "Pommes de terre", measurement: "Accompagnement" },
      { name: "Pain frais", measurement: "Accompagnement" },
    ],
  },
  {
    id: "T5pR8kZ2Lm",
    recipeUrl: "",
    name: "Quiche Lorraine",
    directions:
      "<ol><li>Préchauffez votre four à 180°C.</li><li>Étalez la pâte brisée dans un moule à tarte et piquez le fond avec une fourchette.</li><li>Faites revenir les lardons dans une poêle jusqu'à ce qu'ils soient croustillants. Répartissez-les sur le fond de tarte.</li><li>Battez les œufs avec la crème fraîche, le sel, le poivre et la noix de muscade. Versez ce mélange sur les lardons.</li><li>Cuire au four pendant environ 30 minutes jusqu'à ce que la quiche soit dorée et ferme au toucher.</li><li>Servez chaud ou froid.</li></ol>",
    days: [],
    tags: [
      { text: "Tarte", color: "indigo" },
      { text: "Cuisine française", color: "indigo" },
      { text: "Entrée", color: "indigo" },
    ],
    ingredients: [
      { name: "Pâte brisée", measurement: "1 rouleau" },
      { name: "Lardons", measurement: "200g" },
      { name: "Œufs", measurement: "3" },
      { name: "Crème fraîche", measurement: "200 ml" },
      { name: "Sel", measurement: "Au goût" },
      { name: "Poivre", measurement: "Au goût" },
      { name: "Noix de muscade", measurement: "Une pincée" },
    ],
  },
  {
    id: "Z3fK6Vw1pY",
    recipeUrl: "",
    name: "Tarte Tatin",
    directions:
      "<ol><li>Préchauffez le four à 180°C.</li><li>Dans une poêle allant au four, faites caraméliser le sucre et le beurre.</li><li>Ajoutez les pommes épluchées et coupées en quartiers. Faites-les dorer de chaque côté.</li><li>Recouvrez les pommes avec la pâte brisée en tuckant les bords à l'intérieur de la poêle.</li><li>Enfournez pendant environ 25 minutes jusqu'à ce que la pâte soit dorée.</li><li>Laissez tiédir quelques minutes puis retournez la tarte sur un plat.</li><li>Servez tiède, éventuellement avec une boule de glace à la vanille.</li></ol>",
    days: [],
    tags: [
      { text: "Dessert", color: "indigo" },
      { text: "Cuisine française", color: "indigo" },
      { text: "Tarte", color: "indigo" },
    ],
    ingredients: [
      { name: "Sucre", measurement: "150g" },
      { name: "Beurre", measurement: "75g" },
      { name: "Pommes", measurement: "6" },
      { name: "Pâte brisée", measurement: "1 rouleau" },
      { name: "Glace à la vanille", measurement: "Pour servir" },
    ],
  },
  {
    id: "B5cJ9lR8wQ",
    recipeUrl: "",
    name: "Crêpes",
    directions:
      "<ol><li>Dans un saladier, mélangez la farine, le sucre, une pincée de sel, les œufs, puis progressivement le lait et le beurre fondu pour obtenir une pâte lisse.</li><li>Laissez reposer la pâte au moins une heure.</li><li>Faites chauffer une poêle antiadhésive et versez-y une louche de pâte, en inclinant la poêle pour répartir la pâte uniformément.</li><li>Faites cuire chaque crêpe environ 1 minute de chaque côté jusqu'à ce qu'elles soient dorées.</li><li>Servez chaud avec du sucre, de la confiture, du Nutella, ou du citron.</li></ol>",
    days: [],
    tags: [
      { text: "Dessert", color: "indigo" },
      { text: "Cuisine française", color: "indigo" },
      { text: "Rapide", color: "indigo" },
    ],
    ingredients: [
      { name: "Farine", measurement: "250g" },
      { name: "Sucre", measurement: "50g" },
      { name: "Sel", measurement: "1 pincée" },
      { name: "Œufs", measurement: "4" },
      { name: "Lait", measurement: "500 ml" },
      { name: "Beurre fondu", measurement: "50g" },
      { name: "Sucre, confiture, Nutella, citron", measurement: "Pour servir" },
    ],
  },
  {
    id: "N2fL8kZ5Wt",
    recipeUrl: "",
    name: "Soupe à l'Oignon",
    directions:
      "<ol><li>Émincez finement les oignons. Dans une grande casserole, faites-les caraméliser avec du beurre à feu moyen.</li><li>Ajoutez le vin blanc et laissez réduire. Saupoudrez de farine et remuez bien.</li><li>Ajoutez le bouillon de bœuf, le thym, et la feuille de laurier. Salez et poivrez. Laissez mijoter pendant 30 minutes.</li><li>Préchauffez le gril du four. Versez la soupe dans des bols à soupe résistants à la chaleur. Ajoutez une tranche de pain grillé sur chaque bol et saupoudrez de fromage râpé.</li><li>Grillez jusqu'à ce que le fromage soit fondu et légèrement doré.</li><li>Servez chaud.</li></ol>",
    days: [],
    tags: [
      { text: "Soupe", color: "indigo" },
      { text: "Cuisine française", color: "indigo" },
      { text: "Réconfortant", color: "indigo" },
    ],
    ingredients: [
      { name: "Oignons", measurement: "6 grands" },
      { name: "Beurre", measurement: "50g" },
      { name: "Vin blanc", measurement: "100 ml" },
      { name: "Farine", measurement: "2 cuillères à soupe" },
      { name: "Bouillon de bœuf", measurement: "1.5 litres" },
      { name: "Thym", measurement: "1 branche" },
      { name: "Feuille de laurier", measurement: "1" },
      { name: "Sel", measurement: "Au goût" },
      { name: "Poivre", measurement: "Au goût" },
      { name: "Pain grillé", measurement: "6 tranches" },
      { name: "Fromage râpé", measurement: "200g" },
    ],
  },
  {
    id: "P4rT9cD2Lq",
    recipeUrl: "",
    name: "Cassoulet",
    directions:
      "<ol><li>Faites tremper les haricots blancs dans l'eau toute la nuit.</li><li>Dans une grande cocotte, faites revenir les saucisses et le confit de canard jusqu'à ce qu'ils soient dorés. Retirez-les et réservez.</li><li>Dans la même cocotte, faites revenir l'oignon et l'ail, puis ajoutez les haricots égouttés, le bouquet garni, et couvrez d'eau.</li><li>Laissez mijoter pendant 1 heure, puis ajoutez les saucisses, le confit de canard, et continuez la cuisson pendant encore 1 heure.</li><li>Ajoutez la chapelure et faites gratiner au four pendant 15 minutes avant de servir.</li></ol>",
    days: [],
    tags: [
      { text: "Plat principal", color: "indigo" },
      { text: "Cuisine française", color: "indigo" },
      { text: "Confort", color: "indigo" },
    ],
    ingredients: [
      { name: "Haricots blancs", measurement: "500g" },
      { name: "Saucisses", measurement: "4" },
      { name: "Confit de canard", measurement: "4 cuisses" },
      { name: "Oignon", measurement: "1" },
      { name: "Ail", measurement: "2 gousses" },
      { name: "Bouquet garni", measurement: "1" },
      { name: "Chapelure", measurement: "100g" },
      { name: "Sel", measurement: "Au goût" },
      { name: "Poivre", measurement: "Au goût" },
    ],
  },
  {
    id: "R8zD5Kq2Lf",
    recipeUrl: "",
    name: "Salade Niçoise",
    directions:
      "<ol><li>Faites cuire les œufs durs, puis laissez-les refroidir et écalez-les.</li><li>Faites cuire les haricots verts dans de l'eau bouillante salée, puis égouttez-les et rafraîchissez-les sous l'eau froide.</li><li>Dans un grand saladier, mélangez les tomates coupées, les haricots verts, les oignons nouveaux émincés, les olives noires, les anchois et le thon émietté.</li><li>Ajoutez les œufs durs coupés en quartiers.</li><li>Préparez une vinaigrette avec de l'huile d'olive, du vinaigre, du sel et du poivre, puis versez-la sur la salade.</li><li>Mélangez délicatement et servez frais.</li></ol>",
    days: [],
    tags: [
      { text: "Salade", color: "indigo" },
      { text: "Cuisine française", color: "indigo" },
      { text: "Léger", color: "indigo" },
    ],
    ingredients: [
      { name: "Œufs", measurement: "4" },
      { name: "Haricots verts", measurement: "200g" },
      { name: "Tomates", measurement: "4" },
      { name: "Oignons nouveaux", measurement: "2" },
      { name: "Olives noires", measurement: "50g" },
      { name: "Anchois", measurement: "8 filets" },
      { name: "Thon en conserve", measurement: "1 boîte" },
      { name: "Huile d'olive", measurement: "Pour la vinaigrette" },
      { name: "Vinaigre", measurement: "Pour la vinaigrette" },
      { name: "Sel", measurement: "Au goût" },
      { name: "Poivre", measurement: "Au goût" },
    ],
  },
  {
    id: "M9zD4Ft2Wp",
    recipeUrl: "",
    name: "Mousse au Chocolat",
    directions:
      "<ol><li>Faites fondre le chocolat au bain-marie ou au micro-ondes et laissez-le tiédir.</li><li>Séparez les blancs des jaunes d'œufs. Battez les jaunes avec le sucre jusqu'à ce que le mélange blanchisse.</li><li>Incorporez le chocolat fondu dans le mélange de jaunes d'œufs et de sucre.</li><li>Battez les blancs d'œufs en neige ferme avec une pincée de sel.</li><li>Incorporez délicatement les blancs en neige dans le mélange au chocolat.</li><li>Répartissez la mousse dans des coupes et laissez reposer au réfrigérateur pendant au moins 2 heures.</li><li>Servez frais.</li></ol>",
    days: [],
    tags: [
      { text: "Dessert", color: "indigo" },
      { text: "Cuisine française", color: "indigo" },
      { text: "Chocolat", color: "indigo" },
    ],
    ingredients: [
      { name: "Chocolat noir", measurement: "200g" },
      { name: "Œufs", measurement: "4" },
      { name: "Sucre", measurement: "50g" },
      { name: "Sel", measurement: "1 pincée" },
    ],
  },
];

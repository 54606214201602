import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import linkifyHtml from "linkify-html";
import { HabitSingelEvent } from "./HabitSingelEvent";

export const HabitLanding = ({ habits, isExpired, stepCount, journal }) => {
  const { productId } = useParams();
  const event = habits.find((p) => p.id === productId);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {};
  }, []);

  let productData;

  if (event) {
    productData = (
      <div>
        <HabitSingelEvent
          data={event}
          stepCount={stepCount}
          journal={journal}
        />
      </div>
    );
  } else {
    productData = <h2> Sorry. Habit doesn't exist. </h2>;
  }

  return <div>{productData}</div>;
};

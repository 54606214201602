import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useUser, useUserContext } from "Authentication";
import { Spinner, Modal } from "Common";
//import Spinner from "react-bootstrap/Spinner";
import { createGroup } from "./CreateGroupForm.service";
import { PreloadNewTodo } from "ListToDo/PreloadNewTodo";

import classes from "./CreateGroupForm.module.css";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

//import Button from "react-bootstrap/Button";

interface Form {
  name: string;
}

function clearAllTimers() {
  const allTimers = [];

  // Capture all active timers
  for (let i = 1; i < 1000; i++) {
    const timer = setTimeout(() => {}, i);
    allTimers.push(timer);
    //   console.log("what are they ", allTimers);
    clearTimeout(timer); // Clear the timeout immediately after capturing
  }

  // Clear all captured timers
  allTimers.forEach((timer) => clearTimeout(timer));
  //console.log("what are they after ", allTimers);
}

export const CreateGroupForm = () => {
  const [folderName, setFolderName] = useState("My Home");
  const { formState, handleSubmit, register } = useForm<Form>({
    defaultValues: {
      name: folderName,
    },
    mode: "onChange",
  });
  const { setUserGroup, refreshUser } = useUserContext();
  const user = useUser();
  const [inviteCode, setInviteCode] = useState("");
  const [isReloadButtonShown, setIsReloadButtonShown] = useState(false);
  const [isJoinActive, setIsJoinActive] = useState(false);
  const { t } = useTranslation();

  const checkInvitationCode = () => {
    var link = "/join/" + inviteCode;
    window.open(link);
  };

  if (!user) {
    throw new Error("Must be authenticated to use CreateGroupForm");
  }

  const { errors, isValid, isSubmitting } = formState;
  const [show, setShow] = useState(true);
  const [nickName, setUserNickName] = useState(user.name);
  const { setNickName } = useUserContext();

  var deviceOS = "web";

  if ((window as any).isNative) {
    if ((window as any).isIOS) {
      deviceOS = "iOS";
    } else {
      deviceOS = "Android";
    }
  }

  var app = "Dope Notes";

  if ((window as any).isAloha) {
    app = "Aloha Planner";
  }

  const {
    addItem,
    JournalExample,
    HabitExample,
    NoteExample,
    PlannerExample,
    addToDoCategoryDB,
    //LoadGroceryItems,
  } = PreloadNewTodo();

  const PreloadNewTodoJournal = async () => {
    addItem(t("example-tasks-start-journal"), "");
  };
  const PreloadNewTodoShopping = async () => {
    addItem(t("example-tasks-create-grocery"), "");
  };

  const PreloadNewTodoAIMagic = async () => {
    addItem(t("whatsnew-widget-item-6"), "");
  };

  async function handleCreateGroup(data: Form) {
    if (!user) {
      throw new Error(
        "Should not be able to create group if user is undefined"
      );
    }
    setShow(true);
    var today = new Date();
    setNickName(nickName, true, deviceOS, app);
    const group = await createGroup(data.name, user.id, user.name);

    await setUserGroup(group);

    await PlannerExample();
    await PreloadNewTodoJournal();
    await PreloadNewTodoShopping();
    await PreloadNewTodoAIMagic();
    await JournalExample();
    await HabitExample();
    await NoteExample();
    await addToDoCategoryDB();

    refreshUser();
  }

  //const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);

  const setUpUser = async () => {
    setShow(true);
    // var today = new Date();

    setTimeout(() => {
      //@ts-ignore
      //window.location.reload(true);
      setIsReloadButtonShown(true);
    }, 30000);

    //await setNickName(nickName, true, deviceOS, app);
    const group = await createGroup(folderName, user.id, user.name);
    await setUserGroup(group);
    await preloadItems();
    //@ts-ignore
    //  window.location.reload(true);

    //console.log("group finished");
    // await createSampleCateogories();
    //LoadGroceryItems();
  };

  const preloadItems = async () => {
    await PlannerExample();
    await PreloadNewTodoJournal();
    await PreloadNewTodoAIMagic();
    await JournalExample();
    await HabitExample();
    await NoteExample();
    await PreloadNewTodoShopping();
    await addToDoCategoryDB();
    // console.log("preload finished");
    refreshUser();
  };

  const tryAgain = () => {
    refreshUser();
    //@ts-ignore
    window.location.reload(true);
  };

  const enterInvitationCode = (e) => {
    setInviteCode(e.target.value);
    if (e.target.value.length > 4) {
      setIsJoinActive(true);
    } else {
      setIsJoinActive(false);
    }
  };

  //GroupType and addItem should be updated together if changed.
  const [addItems, setAddItems] = useState(true);
  const [groupType, setGroupType] = useState("category");

  const handleChangeItemsCheckbox = () => {
    if (addItems === true) {
      setAddItems(false);
    } else {
      setAddItems(true);
    }
  };

  const handleSelectGroupType = (e) => {
    setGroupType(e.target.value);
    if (e.target.value === "category") {
      setAddItems(true);
    } else {
      setAddItems(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      // console.log("fired setup");
      try {
        setUpUser();
      } catch (error) {
        window.location.reload();
      }
    }, 3000);

    return () => {
      clearTimeout(timer);
      clearAllTimers();
    };
  }, []);

  return (
    <>
      {isSubmitting ? (
        <div
          style={{
            display: "flex",

            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      ) : (
        <span />
      )}
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            display: "flex",

            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
        {/*}    <form onSubmit={checkInvitationCode}>
          <div style={{paddingTop:40}} className={classes.formGroup}>
            <label htmlFor="name">Invitation Code</label>
            <input
              id="name"
              style={{fontSize: 18, padding: 10}}
              minLength={6}
              required
              onChange={enterInvitationCode}
            />
         
          </div>
          <button
            type="submit"
          style={{fontSize: 18}}
            className={classes.submit}
            disabled={!isJoinActive}
          >
            Join
          </button>
  </form> */}

        <Modal isOpen={show} style={{ overlay: { zIndex: 100 } }}>
          <div
            style={{
              marginTop: 50,
              marginBottom: 350,
              paddingLeft: 20,
              paddingRight: 20,
              fontFamily: "Ubuntu",
            }}
          >
            <h2 style={{ textAlign: "center" }}>Aloha! &#127796;</h2>
            <p style={{ textAlign: "center", fontSize: 20 }}>
              {" "}
              {t("register-loader-setup")}
            </p>

            <div
              style={{
                display: "flex",

                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </div>
            {isReloadButtonShown ? (
              <p
                onClick={tryAgain}
                style={{
                  color: "blue",
                  textAlign: "center",
                  textDecoration: "underline",
                  cursor: "pointer",
                  paddingTop: 20,
                }}
              >
                Slow Connection. Please Tap Here To Reload.
              </p>
            ) : null}
          </div>
        </Modal>
      </div>
    </>
  );
};

import React, { useState, useEffect, useRef, useCallback } from "react";
import classes from "./scoresheet.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faPlus,
  faTrashCan,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { ToggleSwitch } from "Common/ToggleSwitch/ToggleSwitch";
import { useUserContext } from "Authentication";
import moment from "moment";
import { ConfirmationModal } from "Common";
import toast, { Toaster } from "react-hot-toast";
import { Button, Modal } from "Common";
import firstPlace from "../Assets/1st-place.png";
import secondPlace from "../Assets/2nd-place.png";
import thirdPlace from "../Assets/3rd-place.png";
import ReactCanvasConfetti from "react-canvas-confetti";

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 10,
  left: 0,
};

export const ScoreSheet = (data) => {
  // console.log(data);
  //  console.log(data.data.players);

  const { addNewGame, deleteGame, editPlayers } = useUserContext();

  const [players, setPlayers] = useState([]);
  const [newRoundScore, setNewRoundScore] = useState([]);
  const [state, setState] = useState(0);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isShowRounds, setIsShowRounds] = useState(true);
  const [isHideAddPlayer, setIsHideAddPlayer] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalOpenReset, setConfirmModalOpenReset] = useState(false);
  const [tempRemovalID, setTempRemovalID] = useState();
  const [leaderList, setLeaderList] = useState([]);
  const [isLeaderBoardOpen, setIsLeaderBoardOpen] = useState(false);

  const [newPlayerName, setNewPlayerName] = useState({
    name: "",
    Score: [],
    Round: "",
    Total: 0,
  });

  const close = () => {
    setIsLeaderBoardOpen(false);
  };

  const convertDate = (date) => {
    const update = new Date(date.seconds * 1000);
    const updateFormatted = moment(update).format("MMMM DD, YYYY");
    //  const update = date.toDate()
    return updateFormatted;
  };

  const addNewPlayer = (e) => {
    e.preventDefault();
    //Check for unique value in the array
    const index = players.findIndex((item) => item.name === newPlayerName.name);
    if (index > -1) {
      alert("Please enter a unique name");
    } else {
      const newPlayer = {
        name: newPlayerName.name,
        Round: "",
        Score: [{ round: "", score: "" }],
        Total: 0,
      };
      const newPlayerAdd = [...players, newPlayer];

      setPlayers(newPlayerAdd);
      editPlayers(data.data.id, newPlayerAdd);
      setNewPlayerName({
        name: "",
        Round: "",
        Score: [{ round: "", score: "" }],
        Total: 0,
      });
      toast("New player added", {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "lightgreen",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
    }
  };

  const DeleteReminderConfirmation = () => {
    const index = players.findIndex((item) => item.name === tempRemovalID);
    if (index > -1) {
      setPlayers([...players.splice(index, 1)]);
      //setPlayers({array});
      //  console.log("what is players", players)
      //   console.log(index)
      setPlayers([...players]);
      editPlayers(data.data.id, players);
      setConfirmModalOpen(false);
      toast("Player Removed", {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "lightgreen",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
    }
  };

  const removePlayer = (e) => {
    setTempRemovalID(e);
    setConfirmModalOpen(true);
  };

  const leaderBoard = () => {
    setIsLeaderBoardOpen(true);
    var leaders = [...players];
    //leaders.sort((a, b) => (a.total > b.total ? 1 : -1))

    if (data.data.isHighestScore) {
      leaders.sort((a, b) => Number(b.Total) - Number(a.Total));
      //  console.log("who are the leaders Highest?", leaders);
      setLeaderList(leaders);
    } else {
      leaders.sort((a, b) => Number(a.Total) - Number(b.Total));
      // console.log("who are the leaders Lowest?", leaders);
      setLeaderList(leaders);
    }
  };

  const ResetGame = () => {
    var newRoundValue = players.map((player) => ({
      name: player.name,
      Round: "",
      Score: [{ round: "", score: "" }],
      Total: 0,
    }));
    setPlayers(newRoundValue);
    editPlayers(data.data.id, newRoundValue);
    setConfirmModalOpenReset(false);
  };

  const ResetGameConfirmation = () => {
    setConfirmModalOpenReset(true);
  };

  const confetti = () => {
    // fire();
  };

  const checkScore = (data) => {
    const totals = data;

    if (data === leaderList[0].Total) {
      return (
        <div>
          <img
            style={{ height: "64px", width: "64px", paddingRight: 0 }}
            src={firstPlace}
            alt="Medal"
          />
        </div>
      );
    } else if (data === leaderList[1].Total) {
      return (
        <div>
          <img
            style={{
              height: "64px",
              width: "64px",
              cursor: "pointer",
              paddingRight: 10,
            }}
            src={secondPlace}
            alt="Medal"
          />
        </div>
      );
    } else if (data === leaderList[2].Total) {
      return (
        <div>
          <img
            style={{
              height: "64px",
              width: "64px",
              cursor: "pointer",
              paddingRight: 10,
            }}
            src={thirdPlace}
            alt="Medal"
          />
        </div>
      );
    } else {
      <div style={{ padding: 10 }} />;
    }
  };

  const toggleAddPlayer = () => {
    if (isHideAddPlayer === false) {
      setIsHideAddPlayer(true);
    } else {
      setIsHideAddPlayer(false);
    }
  };

  const toggleShowRounds = () => {
    if (isShowRounds === false) {
      setIsShowRounds(true);
    } else {
      setIsShowRounds(false);
    }
  };

  const toggleEditMode = () => {
    if (isEditMode === false) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  };

  const sumArray = (array) => {
    let sum = 0;
    //console.log("what is the array", array)
    array.forEach((item) => {
      sum += Number(item.score);
    });

    //console.log(sum);
    return sum;
  };

  const addNewRound = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = parseFloat(event.target.value);

    // console.log("what is fieldName", fieldName)
    // console.log("what is fieldValue", fieldValue)

    // new Round Value only
    var newRoundValue = players.map((player) =>
      player.name === fieldName
        ? {
            name: player.name,
            Round: fieldValue,
            Score: player.Score,
            Total: sumArray(player.Score),
          }
        : {
            name: player.name,
            Round: "",
            Score: player.Score,
            Total: sumArray(player.Score),
          }
    );
    setPlayers(newRoundValue);

    //Preload data for submit button, will update score and add round value then reset it.
    var newRound = players.map((player) =>
      player.name === fieldName
        ? {
            name: player.name,
            Round: "",
            Score: [
              ...player.Score,
              { round: player.Score.length - 1, score: fieldValue },
            ],
            Total: sumArray(player.Score) + fieldValue,
          }
        : {
            name: player.name,
            Round: "",
            Score: player.Score,
            Total: sumArray(player.Score),
          }
    );

    //loopig it again so that it'll update the total game score with the latest score

    //console.log("what is new round", newRound)

    setNewRoundScore(newRound);
  };

  const editRoundScore = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldRound = Number(event.target.getAttribute("round")) + 1;
    const fieldValue = parseFloat(event.target.value);

    //  console.log("what is fieldName", fieldName)
    //  console.log("what is fieldName", fieldRound)
    //  console.log("what is fieldValue", fieldValue)

    // 1. Make a shallow copy of the items
    let items = [...players];
    // 2. fix the index of the items I want to change
    const index = players.findIndex((item) => item.name === fieldName);
    // 2. Make a shallow copy of the item you want to mutate
    let item = { ...items[index] };
    // 3. Replace the property you're intested in
    let score = { ...item[fieldRound] };

    score = { round: fieldRound - 1, score: fieldValue };
    // 4. Put it back into our array. N.B. we *are* mutating the array here,
    //    but that's why we made a copy first
    item.Score[fieldRound] = score;
    items[index] = item;
    // 5. Set the state to our new copy

    setPlayers(items);
    editPlayers(data.data.id, items);
    // console.log("What are the score now", score)
    // console.log("What are the item now", item)
    //console.log("What are the items now", items)
  };

  const UpdateScores = (e) => {
    e.preventDefault();
    setPlayers(newRoundScore);
    editPlayers(data.data.id, newRoundScore);
  };

  const handleAddFormChange = (e) => {
    setNewPlayerName({
      name: e.target.value,
      Score: { round: 0, score: 0 },
      Round: "",
    });
  };

  useEffect(() => {
    setPlayers(data.data.players);

    return () => {};
  }, []);

  //confetti

  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  return (
    <div style={{ textAlign: "center", fontWeight: "bold" }}>
      <h1>{data.data.name} Scorepad</h1>
      Start date: {convertDate(data.data.date)}
      <div style={{ paddingBottom: 5 }} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: 20,
        }}
      >
        <ToggleSwitch label="Hide Rounds" onChange={() => toggleShowRounds()} />
        {isShowRounds ? (
          <ToggleSwitch label="Edit Rounds" onChange={() => toggleEditMode()} />
        ) : (
          <span />
        )}
        <ToggleSwitch
          label="Hide Add Player"
          onChange={() => toggleAddPlayer()}
        />
      </div>
      {!isHideAddPlayer ? (
        <>
          <form onSubmit={addNewPlayer}>
            <input
              type="text"
              name="name"
              value={newPlayerName.name}
              required="required"
              minLength={1}
              style={{
                fontSize: "inherit",
                fontFamily: "inherit",
                padding: 20,
              }}
              placeholder="Enter player's name"
              onChange={handleAddFormChange}
            />
            <br />
            {/* This is dynamic to show the button when the user starts typing.
       {newPlayerName.name.length >=1 ? (<div style={{padding: 20}}><button type="submit" style={{fontSize: "inherit", fontFamily: "inherit", padding: 20, backgroundColor: "#3e98c7", border:1, color:"white", borderRadius: 10, boxShadow: '2px 3px 3px grey, 0 0 5px #FCFBF4 inset'}} >Add Player</button></div>): <span/>} 
       */}
            <div style={{ padding: 20 }}>
              <button
                type="submit"
                style={{
                  fontSize: "inherit",
                  fontFamily: "inherit",
                  padding: 20,
                  backgroundColor: "#3e98c7",
                  border: 1,
                  color: "white",
                  borderRadius: 5,
                  cursor: "pointer",
                  borderColor: "white",
                  // boxShadow: "2px 3px 3px grey, 0 0 5px #FCFBF4 inset",
                }}
              >
                Add Player
              </button>
            </div>
            <hr />
          </form>
        </>
      ) : (
        <hr />
      )}
      <div style={{ padding: 10 }}>
        <div className={classes.grid}>
          {players.map((player) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 5,
                color: "black",
              }}
              key={player.name}
            >
              <div
                style={{ fontSize: 22, fontFamily: "inherit" }}
                onDoubleClick={() => removePlayer(player.name)}
              >
                {player.name}{" "}
              </div>
              <div
                style={{
                  fontSize: 22,
                  fontFamily: "inherit",
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                {sumArray(player.Score)}
              </div>
              <div style={{ fontSize: 14, fontWeight: "normal" }}>
                {" "}
                On round: {player.Score.length}
              </div>

              <hr />
              <form onSubmit={addNewRound}>
                <input
                  type="number"
                  name={player.name}
                  value={player.Round}
                  inputMode="negative"
                  placeholder="score"
                  onChange={addNewRound}
                  style={{
                    width: 50,
                    fontFamily: "inherit",
                    fontSize: "inherit",
                    textAlign: "center",
                    padding: 10,
                  }}
                />{" "}
                <div style={{ paddingTop: 5 }} />
                <button
                  style={{
                    fontSize: "inherit",
                    fontFamily: "inherit",
                    padding: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                    backgroundColor: "#3e98c7",
                    border: 1,
                    color: "white",
                    borderRadius: 3,
                    cursor: "pointer",
                    borderColor: "white",
                    //  boxShadow: "2px 3px 3px grey, 0 0 5px #FCFBF4 inset",
                  }}
                  onClick={UpdateScores}
                >
                  +Add
                </button>
              </form>
              <div style={{ paddingBottom: 20 }} />
              {isShowRounds ? (
                <>
                  {!isEditMode
                    ? player.Score.map((a) =>
                        a.score !== "" ? (
                          <div style={{ fontWeight: "normal" }} key={a.round}>
                            {" "}
                            <div style={{ textAlign: "left", marginLeft: 30 }}>
                              R{(Number(a.round) + 1).toString()}
                              <span style={{ fontWeight: "bold" }}>
                                : {a.score}
                              </span>{" "}
                            </div>
                          </div>
                        ) : (
                          <span />
                        )
                      )
                    : player.Score.map((a) =>
                        a.score !== "" ? (
                          <div style={{ paddingBottom: 10 }}>
                            <div>Round: {(Number(a.round) + 1).toString()}</div>
                            <input
                              type="number"
                              key={a.round}
                              name={player.name}
                              round={a.round}
                              placeholder={a.score}
                              inputMode="negative"
                              // pattern="[0-9]*"
                              onChange={editRoundScore}
                              style={{
                                width: 50,
                                fontFamily: "inherit",
                                fontSize: "inherit",
                                textAlign: "center",
                                padding: 10,
                              }}
                            />
                          </div>
                        ) : (
                          <span></span>
                        )
                      )}{" "}
                </>
              ) : (
                <span />
              )}
              <hr />
            </div>
          ))}
        </div>
        <ConfirmationModal
          isOpen={confirmModalOpen}
          text="Are you sure you want to remove this player?"
          onClickCancel={() => setConfirmModalOpen(false)}
          onClickConfirm={DeleteReminderConfirmation}
        />
        <ConfirmationModal
          isOpen={confirmModalOpenReset}
          text="Are you sure you want to reset this game?"
          onClickCancel={() => setConfirmModalOpenReset(false)}
          onClickConfirm={ResetGame}
        />

        <Modal
          isOpen={isLeaderBoardOpen}
          onAfterOpen={confetti}
          onRequestClose={close}
        >
          <div
            style={{
              display: "flex",
              alignItems: "right",
              justifyContent: "right",
              margin: 10,
            }}
          >
            {" "}
            <button
              style={{
                backgroundColor: "transparent",
                borderStyle: "none",
                cursor: "pointer",
                marginTop: 10,
                marginRight: 10,
              }}
              onClick={close}
            >
              <FontAwesomeIcon icon={faClose} size="3x" />
            </button>
          </div>
          <div
            style={{ paddingBottom: 150, paddingLeft: 20, paddingRight: 20 }}
          >
            <h1 style={{ textAlign: "center", lineHeight: 1.2 }}>
              {data.data.name} Leaderboard
            </h1>
            <div style={{ padding: 20 }}>
              {leaderList.map((player) => (
                <div style={{ textAlign: "center", verticalAlign: "center" }}>
                  <h2 style={{ fontFamily: "inherit", lineHeight: 1.2 }}>
                    {checkScore(player.Total)} {player.name}{" "}
                    <span
                      style={{
                        fontWeight: "normal",
                        fontSize: 18,
                        fontFamily: "inherit",
                      }}
                    >
                      {" "}
                      |{" "}
                    </span>
                    {player.Total}
                  </h2>
                </div>
              ))}
            </div>
            <div style={{ paddingBottom: 150 }} />
            <ReactCanvasConfetti
              refConfetti={getInstance}
              style={canvasStyles}
            />
          </div>
        </Modal>
      </div>
      {players.length >= 1 ? (
        <div style={{ paddingBottom: 20 }}>
          <button
            onClick={leaderBoard}
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              padding: 20,
              backgroundColor: "#3e98c7",
              border: 1,
              color: "white",
              borderRadius: 5,
              borderColor: "white",
              //  boxShadow: "2px 3px 3px grey, 0 0 5px #FCFBF4 inset",
              cursor: "pointer",
            }}
          >
            See Leaderboard
          </button>
          <div style={{ paddingBottom: 10 }} />
          <button
            onClick={ResetGameConfirmation}
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              padding: 20,
              backgroundColor: "#3e98c7",
              border: 1,
              color: "white",
              borderRadius: 5,
              // boxShadow: "2px 3px 3px grey, 0 0 5px #FCFBF4 inset",
              cursor: "pointer",
              borderColor: "white",
            }}
          >
            Reset Game
          </button>
        </div>
      ) : (
        <span />
      )}
      <div style={{ paddingBottom: 20, textAlign: "center" }}>
        Tips. For IOS, please use the "return" button on your keyboard, not the
        "Done" button to enter each score. If you use "Done", you'll have to use
        the +Add to submit the score. Remove a player by double clicking/tapping
        their name. Need more room on your phone, turn it landscape!
      </div>
    </div>
  );
};

import styled from "styled-components";

const Container = styled.div`
    box-sizing: border-box;
      
    width: 25px;
    height: 40px;
    
    display: flex;
    justify-content: center;
    align-items: center;

    padding-bottom: 2px;

    background-color: ${(props) => (props.isSelected ? "lightgreen" : "#FFF")};
    border: 1px solid ${(props) =>
      props.isSelected ? "lightgreen" : "#D5D5D5"};
    
    border-radius: 20px;
    text-shadow: 5px 10px #888888:  
    font-size: 12px;
    line-height: 25px;
    color: ${(props) => (props.isSelected ? "black" : "#DBDBDB")};
`;

export { Container };

import { useEffect, useState } from "react";

import { collection, onSnapshot } from "firebase/firestore";

import { useUserContext } from "Authentication";
import { ReminderModel } from "./models/reminders";

export function useReminderSubscription() {
  const [reminders, setReminders] = useState<ReminderModel[]>([]);
  const { getReminderCollection } = useUserContext();

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(getReminderCollection(), "reminders"),
      (snapshot) => {
        const addedReminders: ReminderModel[] = [];

        snapshot.docs.forEach(
          (doc) => {
            const item = { ...doc.data(), id: doc.id } as ReminderModel;
            addedReminders.push(item);
          },
          (error) => {
            window.location.reload();
          }
        );

        setReminders(addedReminders);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [getReminderCollection]);

  // Sort reminders by reminderOnDate in descending order
  reminders.sort((a, b) => b.reminderOnDate - a.reminderOnDate);

  return { reminders };
}

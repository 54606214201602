import { Modal } from "Common";
import { useHistory } from "react-router-dom";
import { useUserContext } from "./UserContext";
import { useState } from "react";

export const CheckGroupStatus = ({ isGroupValid }) => {
  const { removeGroupFromMemberByOwner } = useUserContext();
  const [groupRemoved, setGroupRemoved] = useState(false);
  const history = useHistory();

  //console.log("what is the permission", isGroupValid);

  const retryGroupCheck = () => {
    //@ts-ignore
    window.location.reload(true);
  };

  const createNewGroup = () => {
    removeGroupFromMemberByOwner("123");
    setGroupRemoved(true);
    // window.location.reload(true);
  };

  const goToHome = () => {
    history.push("/");
    setGroupRemoved(false);
  };

  return (
    <>
      <Modal
        isOpen={!isGroupValid}
        style={{ overlay: { zIndex: 100 } }}
        //background-color: rgba(255, 255, 255, 0.5); /* Set the desired overlay color and opacity */
      >
        <div style={{ fontSize: 20, fontFamily: "inherit" }}>
          Access to this group has been restricted by the group's owner. To
          resolve this issue, kindly reach out to the owner for assistance or
          consider creating a new group.
          <br />
          <br />
          <button
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 5,
              paddingBottom: 5,
              fontSize: 16,
              fontFamily: "inherit",
            }}
            onClick={retryGroupCheck}
          >
            Try Again
          </button>{" "}
          <span style={{ paddingLeft: 10 }} />
          {!groupRemoved ? (
            <button
              onClick={createNewGroup}
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 5,
                paddingBottom: 5,
                fontSize: 16,
                fontFamily: "inherit",
              }}
            >
              Create New Group
            </button>
          ) : null}
          <span style={{ paddingLeft: 10 }} />
          <button
            onClick={goToHome}
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 5,
              paddingBottom: 5,
              fontSize: 16,
              fontFamily: "inherit",
            }}
          >
            Go Home
          </button>
        </div>
      </Modal>
    </>
  );
};

export const classicRecipesCN = [
  {
    id: "abcwewewfndef1234",
    recipeUrl: "",
    name: "宫保鸡丁 (Kung Pao Chicken)",
    directions:
      "<ol><li>将鸡肉切成小块。</li><li>将葱、姜、蒜切末，青椒、红椒切段。</li><li>热锅凉油，将鸡肉翻炒至变色，取出备用。</li><li>锅中再加一些油，加入葱姜蒜爆炒出香味。</li><li>加入青椒、红椒翻炒均匀。</li><li>将炒好的鸡肉放回锅中，加入花生和调味汁。</li><li>继续翻炒至所有食材均匀包裹在酱汁中。</li><li>起锅前撒上花生和香葱末。</li><li>宫保鸡丁完成！</li></ol>",
    days: [],
    tags: [
      { text: "主菜", color: "indigo" },
      { text: "川菜", color: "indigo" },
    ],
    ingredients: [
      { name: "鸡肉", measurement: "300克" },
      { name: "葱", measurement: "适量" },
      { name: "姜", measurement: "适量" },
      { name: "蒜", measurement: "适量" },
      { name: "青椒", measurement: "适量" },
      { name: "红椒", measurement: "适量" },
      { name: "花生", measurement: "适量" },
      { name: "调味汁", measurement: "适量" },
    ],
  },
  {
    id: "a1bww2c3wefed4e5",
    recipeUrl: "",
    name: "蒜蓉白肉 (Shredded Pork with Garlic Sauce)",
    directions:
      "<ol><li>准备食材：瘦肉切成丝，蒜蓉剁碎，生姜切末，葱切葱花。</li><li>将切好的瘦肉焯水，捞出备用。</li><li>热锅凉油，将蒜蓉、生姜煸炒至香气散发。</li><li>加入焯水后的瘦肉，继续煸炒至变色。</li><li>加入生抽、白糖、盐，继续翻炒均匀。</li><li>最后，撒上葱花，翻炒均匀即可。</li></ol>",
    days: [],
    tags: [
      { text: "主菜", color: "indigo" },
      { text: "川菜", color: "indigo" },
    ],
    ingredients: [
      { name: "瘦肉", measurement: "200克" },
      { name: "蒜蓉", measurement: "适量" },
      { name: "生姜", measurement: "适量" },
      { name: "葱", measurement: "适量" },
      { name: "生抽", measurement: "适量" },
      { name: "白糖", measurement: "适量" },
      { name: "盐", measurement: "适量" },
    ],
  },
  {
    id: "1awef2b3c4d5e",
    recipeUrl: "",
    name: "麻婆豆腐 (Mapo Tofu)",
    directions:
      "<ol><li>准备食材：嫩豆腐切块，肉末剁碎，蒜蓉和姜蓉备好。</li><li>热锅凉油，加入蒜蓉和姜蓉煸炒至香气散发。</li><li>加入剁碎的肉末，煸炒至变色。</li><li>加入郫县豆瓣酱、辣酱、生抽，煮出红油。</li><li>加入适量的水，烧开后加入切好的嫩豆腐块，煮熟。</li><li>最后，加入盐、鸡精，撒上葱花，搅拌均匀即可。</li></ol>",
    days: [],
    tags: [
      { text: "主菜", color: "indigo" },
      { text: "川菜", color: "indigo" },
    ],
    ingredients: [
      { name: "嫩豆腐", measurement: "400克" },
      { name: "肉末", measurement: "100克" },
      { name: "蒜蓉", measurement: "适量" },
      { name: "姜蓉", measurement: "适量" },
      { name: "郫县豆瓣酱", measurement: "适量" },
      { name: "辣酱", measurement: "适量" },
      { name: "生抽", measurement: "适量" },
      { name: "盐", measurement: "适量" },
      { name: "鸡精", measurement: "适量" },
      { name: "葱花", measurement: "适量" },
    ],
  },
  {
    id: "a1b2dsfdfgdg4ee5",
    recipeUrl: "",
    name: "回锅肉 (Twice-cooked Pork)",
    directions:
      "<ol><li>准备食材：瘦肉切薄片，青蒜切段，泡辣椒剁碎，姜蒜切末。</li><li>将薄片瘦肉用开水焯水，捞出沥干。</li><li>锅中热油，将焯过水的瘦肉煎至微黄色，捞出备用。</li><li>锅中再次热油，加入泡辣椒、姜蒜末，煸炒出香味。</li><li>加入青蒜段和煎好的瘦肉，翻炒均匀。</li><li>加入郫县豆瓣酱、甜面酱、生抽、糖，翻炒均匀。</li><li>最后，加入一些水，焖煮片刻，收汁即可。</li></ol>",
    days: [],
    tags: [
      { text: "主菜", color: "indigo" },
      { text: "川菜", color: "indigo" },
    ],
    ingredients: [
      { name: "瘦肉", measurement: "300克" },
      { name: "青蒜", measurement: "适量" },
      { name: "泡辣椒", measurement: "适量" },
      { name: "姜蒜", measurement: "适量" },
      { name: "郫县豆瓣酱", measurement: "适量" },
      { name: "甜面酱", measurement: "适量" },
      { name: "生抽", measurement: "适量" },
      { name: "糖", measurement: "适量" },
    ],
  },
  {
    id: "z1y2x3w4234f4gghv5",
    recipeUrl: "",
    name: "德州扒鸡 (Dezhou Braised Chicken)",
    directions:
      "<ol><li>准备食材：一只鸡，生姜切片，蒜末，黄瓜切段。</li><li>将鸡煮熟，捞出晾凉，用刀切成小块。</li><li>在碗中加入鸡块、生姜片、蒜末，淋上酱油、醋、香油，撒上花生粉搅拌均匀。</li><li>将黄瓜段放在碗里，然后将拌好的鸡块放在黄瓜上面。</li><li>最后，撒上香菜末，即可享用。</li></ol>",
    days: [],
    tags: [
      { text: "主菜", color: "indigo" },
      { text: "德州菜", color: "indigo" },
    ],
    ingredients: [
      { name: "鸡", measurement: "1只" },
      { name: "生姜", measurement: "适量" },
      { name: "蒜", measurement: "适量" },
      { name: "黄瓜", measurement: "适量" },
      { name: "酱油", measurement: "适量" },
      { name: "醋", measurement: "适量" },
      { name: "香油", measurement: "适量" },
      { name: "花生粉", measurement: "适量" },
      { name: "香菜", measurement: "适量" },
    ],
  },
  {
    id: "a1b2lplgr5g6dfec3d4e5",
    recipeUrl: "",
    name: "糖醋大黄鱼 (Stir-fried Large Yellow Croaker with Sugar and Vinegar)",
    directions:
      "<ol><li>准备食材：大黄鱼、生姜切丝、葱切段。</li><li>将大黄鱼切段，用盐和料酒拌匀，腌制片刻。</li><li>将大黄鱼段沥干水分，裹上淀粉。</li><li>热锅加油，将大黄鱼段煎至金黄酥脆，捞出备用。</li><li>锅中留少许底油，加入姜丝、葱段煸炒出香味，然后加入白糖翻炒至糖溶化。</li><li>加入醋、料酒、酱油、盐、水混合成的调味汁，煮至汁液浓稠。</li><li>将煎好的大黄鱼段倒入锅中，翻炒均匀，让鱼块裹上汁汁。</li><li>最后，撒上葱花，即可出锅享用。</li></ol>",
    days: [],
    tags: [
      { text: "主菜", color: "indigo" },
      { text: "家常菜", color: "indigo" },
      { text: "川菜", color: "indigo" },
    ],
    ingredients: [
      { name: "大黄鱼", measurement: "1条" },
      { name: "生姜", measurement: "适量" },
      { name: "葱", measurement: "适量" },
      { name: "盐", measurement: "适量" },
      { name: "料酒", measurement: "适量" },
      { name: "淀粉", measurement: "适量" },
      { name: "食用油", measurement: "适量" },
      { name: "白糖", measurement: "适量" },
      { name: "醋", measurement: "适量" },
      { name: "酱油", measurement: "适量" },
    ],
  },
  {
    id: "b5a4c3d2e1dfr43fr",
    recipeUrl: "",
    name: "葱烧海参 (Braised Sea Cucumber with Green Scallion)",
    directions:
      "<ol><li>准备食材：海参、葱姜蒜切末，红椒切圈。</li><li>将海参焯水，去除涂层，切段。</li><li>热锅凉油，加入葱姜蒜末煸炒至香味出来。</li><li>加入切好的海参段，翻炒均匀。</li><li>加入料酒、酱油、糖、盐、清水，烧开后转小火焖煮15分钟。</li><li>最后，加入红椒圈，翻炒均匀，出锅前撒上葱花即可。</li></ol>",
    days: [],
    tags: [
      { text: "主菜", color: "indigo" },
      { text: "家常菜", color: "indigo" },
      { text: "川菜", color: "indigo" },
    ],
    ingredients: [
      { name: "海参", measurement: "1条" },
      { name: "葱", measurement: "适量" },
      { name: "姜", measurement: "适量" },
      { name: "蒜", measurement: "适量" },
      { name: "红椒", measurement: "适量" },
      { name: "料酒", measurement: "适量" },
      { name: "酱油", measurement: "适量" },
      { name: "糖", measurement: "适量" },
      { name: "盐", measurement: "适量" },
      { name: "清水", measurement: "适量" },
    ],
  },
  {
    id: "d9e8c9j8j7b6a5",
    recipeUrl: "",
    name: "蒸釀豆腐 (Steamed Stuffed Tofu)",
    directions:
      "<ol><li>准备食材：嫩豆腐切段，虾仁剁成虾茸，葱姜蒜切末，香菇切末。</li><li>将虾茸、香菇末、葱姜蒜末，加入碗中，搅拌均匀。</li><li>将豆腐段的中间挖空，留下外壳。</li><li>将搅拌均匀的虾茸馅填入豆腐壳中。</li><li>上锅蒸10-15分钟，至豆腐熟透。</li><li>蒸好后，撒上葱花，淋上热香油即可。</li></ol>",
    days: [],
    tags: [
      { text: "主菜", color: "indigo" },
      { text: "家常菜", color: "indigo" },
      { text: "川菜", color: "indigo" },
    ],
    ingredients: [
      { name: "嫩豆腐", measurement: "300克" },
      { name: "虾仁", measurement: "100克" },
      { name: "香菇", measurement: "3朵" },
      { name: "葱", measurement: "适量" },
      { name: "姜", measurement: "适量" },
      { name: "蒜", measurement: "适量" },
      { name: "香油", measurement: "适量" },
    ],
  },
  {
    id: "c4e3b2a1lkj90d9",
    recipeUrl: "",
    name: "叉烧 (Char Siu)",
    directions:
      "<ol><li>准备食材：叉烧肉切成长条形，蒜蓉、生姜切末。</li><li>制作腌料：在一个碗中混合蒜蓉、生姜末、蜂蜜、料酒、酱油、五香粉、盐和糖。</li><li>将腌料均匀涂抹在叉烧肉上，尽量使肉均匀裹上腌料。</li><li>将叉烧肉放入密封袋中，封好袋口，冷藏腌制至少 4 小时，最好过夜。</li><li>预热烤箱至 200°C（392°F）。</li><li>将叉烧肉放在烤盘上，入烤箱烤约 25-30 分钟，期间翻面，直到叉烧变成金黄色和微焦。</li><li>取出烤好的叉烧，切成薄片，即可享用。</li></ol>",
    days: [],
    tags: [
      { text: "主菜", color: "indigo" },
      { text: "广东菜", color: "indigo" },
      { text: "烧腊", color: "indigo" },
    ],
    ingredients: [
      { name: "叉烧肉", measurement: "500克" },
      { name: "蒜蓉", measurement: "适量" },
      { name: "生姜末", measurement: "适量" },
      { name: "蜂蜜", measurement: "2汤匙" },
      { name: "料酒", measurement: "1汤匙" },
      { name: "酱油", measurement: "2汤匙" },
      { name: "五香粉", measurement: "1茶匙" },
      { name: "盐", measurement: "1/2茶匙" },
      { name: "糖", measurement: "2汤匙" },
    ],
  },
  {
    id: "a723fh6b1c9d2e5",
    recipeUrl: "",
    name: "姜蒜虾 (Shrimp with Ginger and Garlic Sauce)",
    directions:
      "<ol><li>准备食材：虾去壳去头，姜和大蒜切末，青蒜切段。</li><li>用盐和淀粉腌制虾肉，静置约15分钟。</li><li>热锅凉油，加入姜末和蒜末炒香。</li><li>加入虾肉翻炒至变色，取出备用。</li><li>锅中再加入少许油，加入蒜末和姜末爆炒至香气散发。</li><li>加入虾肉，继续翻炒。</li><li>加入生抽、糖、鸡精，继续翻炒均匀。</li><li>最后加入青蒜段，翻炒均匀，即可出锅装盘。</li></ol>",
    days: [],
    tags: [
      { text: "主菜", color: "indigo" },
      { text: "中华菜", color: "indigo" },
      { text: "海鲜", color: "indigo" },
    ],
    ingredients: [
      { name: "大虾", measurement: "300克" },
      { name: "姜末", measurement: "适量" },
      { name: "蒜末", measurement: "适量" },
      { name: "青蒜段", measurement: "适量" },
      { name: "盐", measurement: "适量" },
      { name: "淀粉", measurement: "适量" },
      { name: "生抽", measurement: "适量" },
      { name: "糖", measurement: "适量" },
      { name: "鸡精", measurement: "适量" },
    ],
  },
  {
    id: "b4a945g23dc2d7e1",
    recipeUrl: "",
    name: "糖醋里脊 (Sweet and Sour Pork)",
    directions:
      "<ol><li>准备食材：里脊肉切块，青红椒、洋葱切块，姜蒜切末。</li><li>用盐、胡椒粉、料酒腌制里脊肉，静置约15分钟。</li><li>制作糖醋汁：在碗中加入白糖、白醋、番茄酱、生抽、盐，搅拌均匀。</li><li>将腌制好的里脊肉裹上淀粉。</li><li>热锅凉油，将里脊肉炸至金黄色，取出备用。</li><li>锅中再加入少许油，加入姜蒜末爆炒至香气散发。</li><li>加入青红椒、洋葱翻炒至变软。</li><li>将糖醋汁倒入锅中，翻炒均匀，直至汁液变稠。</li><li>最后加入炸好的里脊肉，翻炒均匀，即可出锅装盘。</li></ol>",
    days: [],
    tags: [
      { text: "主菜", color: "indigo" },
      { text: "中华菜", color: "indigo" },
      { text: "糖醋口味", color: "indigo" },
    ],
    ingredients: [
      { name: "里脊肉", measurement: "300克" },
      { name: "青红椒", measurement: "适量" },
      { name: "洋葱", measurement: "适量" },
      { name: "姜末", measurement: "适量" },
      { name: "蒜末", measurement: "适量" },
      { name: "盐", measurement: "适量" },
      { name: "胡椒粉", measurement: "适量" },
      { name: "料酒", measurement: "适量" },
      { name: "淀粉", measurement: "适量" },
      { name: "白糖", measurement: "适量" },
      { name: "白醋", measurement: "适量" },
      { name: "番茄酱", measurement: "适量" },
      { name: "生抽", measurement: "适量" },
    ],
  },
  {
    id: "d9b8e3a445gg7f6",
    recipeUrl: "",
    name: "白切鸡 (White Cut Chicken)",
    directions:
      "<ol><li>准备食材：鸡一只，葱段、姜片、大蒜切末。</li><li>将鸡整体放入沸水中，加入葱段和姜片，煮沸后改小火，继续煮20-25分钟。</li><li>将煮熟的鸡取出，马上放入冰水中，让鸡皮更爽脆。</li><li>用擀面杖轻轻敲打鸡肉，让鸡肉更松软。</li><li>将大蒜末、盐、生抽、香醋混合成蘸料。</li><li>切好的鸡肉切片，摆盘，撒上香葱末。</li><li>可加入蘸料享用。</li></ol>",
    days: [],
    tags: [
      { text: "主菜", color: "indigo" },
      { text: "中华菜", color: "indigo" },
      { text: "清淡口味", color: "indigo" },
    ],
    ingredients: [
      { name: "鸡", measurement: "1只" },
      { name: "葱段", measurement: "适量" },
      { name: "姜片", measurement: "适量" },
      { name: "大蒜", measurement: "适量" },
      { name: "盐", measurement: "适量" },
      { name: "生抽", measurement: "适量" },
      { name: "香醋", measurement: "适量" },
    ],
  },
  {
    id: "a4e2b1csdfde9d8",
    recipeUrl: "",
    name: "佛跳墙 (Buddha Jumps Over the Wall)",
    directions:
      "<ol><li>准备食材：瑶柱、鸽蛋、花菇、虫草花、鱼翅、瑶柱、蹄筋、鲍鱼、海参、花胶、海参、瑶柱、花菇、虫草花、瑶柱、蹄筋、鲍鱼、鸽蛋、鱼翅、鲍鱼、海参、花胶、瑶柱。</li><li>将所有材料清洗干净，准备好。</li><li>在大锅中加入清水，放入材料，开火烧开后改小火，炖煮4小时。</li><li>调味：加入适量的盐和鸡精。</li><li>即可享用，佛跳墙烹饪完成！</li></ol>",
    days: [],
    tags: [
      { text: "主菜", color: "indigo" },
      { text: "闽南菜", color: "indigo" },
      { text: "高档菜", color: "indigo" },
    ],
    ingredients: [
      { name: "瑶柱", measurement: "适量" },
      { name: "鸽蛋", measurement: "适量" },
      { name: "花菇", measurement: "适量" },
      { name: "虫草花", measurement: "适量" },
      { name: "鱼翅", measurement: "适量" },
      { name: "蹄筋", measurement: "适量" },
      { name: "鲍鱼", measurement: "适量" },
      { name: "海参", measurement: "适量" },
      { name: "花胶", measurement: "适量" },
    ],
  },
  {
    id: "b6a3d9e5cfe3fh68",
    recipeUrl: "",
    name: "五彩虾 (Five Colours Shrimp)",
    directions:
      "<ol><li>准备食材：虾仁、红椒、黄椒、绿椒、青豆、蒜蓉。</li><li>将虾仁焯水至熟透，捞出备用。</li><li>热锅加入适量食用油，加入蒜蓉煸炒至香味出来。</li><li>依次加入红椒、黄椒、绿椒、青豆，快速翻炒均匀。</li><li>最后加入虾仁，继续翻炒至虾仁变色。</li><li>调味：加入盐、鸡精，翻炒均匀。</li><li>即可享用，五彩虾烹饪完成！</li></ol>",
    days: [],
    tags: [
      { text: "主菜", color: "indigo" },
      { text: "川菜", color: "indigo" },
      { text: "家常菜", color: "indigo" },
    ],
    ingredients: [
      { name: "虾仁", measurement: "适量" },
      { name: "红椒", measurement: "适量" },
      { name: "黄椒", measurement: "适量" },
      { name: "绿椒", measurement: "适量" },
      { name: "青豆", measurement: "适量" },
      { name: "蒜蓉", measurement: "适量" },
      { name: "盐", measurement: "适量" },
      { name: "鸡精", measurement: "适量" },
    ],
  },
  {
    id: "a9b1c2d3e4",
    recipeUrl: "",
    name: "扬州炒饭 (Yangzhou Fried Rice)",
    directions:
      "<ol><li>准备食材：熟饭、鸡蛋、虾仁、青豆、火腿肠、胡萝卜、鸡胸肉。</li><li>将熟饭用手分散成颗粒状，备用。</li><li>热锅加入适量食用油，加入鸡蛋翻炒均匀，捞出备用。</li><li>同一锅热油，加入虾仁、火腿肠、鸡胸肉翻炒均匀。</li><li>加入青豆、胡萝卜翻炒至熟透。</li><li>将炒好的鸡蛋加入锅中，继续翻炒均匀。</li><li>最后加入分散的熟饭，继续翻炒至饭粒均匀上色。</li><li>调味：加入盐、鸡精、生抽，翻炒均匀。</li><li>即可享用，扬州炒饭烹饪完成！</li></ol>",
    days: [],
    tags: [
      { text: "主食", color: "indigo" },
      { text: "中华料理", color: "indigo" },
      { text: "家常菜", color: "indigo" },
    ],
    ingredients: [
      { name: "熟饭", measurement: "适量" },
      { name: "鸡蛋", measurement: "适量" },
      { name: "虾仁", measurement: "适量" },
      { name: "青豆", measurement: "适量" },
      { name: "火腿肠", measurement: "适量" },
      { name: "胡萝卜", measurement: "适量" },
      { name: "鸡胸肉", measurement: "适量" },
      { name: "盐", measurement: "适量" },
      { name: "鸡精", measurement: "适量" },
      { name: "生抽", measurement: "适量" },
    ],
  },
  {
    id: "b5a6c7d8e9",
    recipeUrl: "",
    name: "糖醋桂鱼 (Sweet and Sour Mandarin Fish)",
    directions:
      "<ol><li>准备食材：桂鱼、糖、醋、料酒、淀粉、番茄酱、盐、食用油、姜、蒜、红椒、青椒、胡萝卜。</li><li>将桂鱼去鳞、去腮、去内脏，洗净切段，用盐和料酒腌制片刻。</li><li>将淀粉均匀涂抹在桂鱼上。</li><li>热锅加入食用油，将桂鱼炸至金黄色，捞出备用。</li><li>同一锅中加入姜和蒜煸炒出香味，加入胡萝卜、红椒、青椒翻炒均匀。</li><li>加入糖、醋、番茄酱、盐调味，翻炒均匀。</li><li>将炸好的桂鱼加入锅中，轻轻翻炒均匀，确保每块桂鱼都裹上酱汁。</li><li>即可享用，糖醋桂鱼烹饪完成！</li></ol>",
    days: [],
    tags: [
      { text: "主食", color: "indigo" },
      { text: "中华料理", color: "indigo" },
      { text: "家常菜", color: "indigo" },
    ],
    ingredients: [
      { name: "桂鱼", measurement: "适量" },
      { name: "糖", measurement: "适量" },
      { name: "醋", measurement: "适量" },
      { name: "料酒", measurement: "适量" },
      { name: "淀粉", measurement: "适量" },
      { name: "番茄酱", measurement: "适量" },
      { name: "盐", measurement: "适量" },
      { name: "食用油", measurement: "适量" },
      { name: "姜", measurement: "适量" },
      { name: "蒜", measurement: "适量" },
      { name: "红椒", measurement: "适量" },
      { name: "青椒", measurement: "适量" },
      { name: "胡萝卜", measurement: "适量" },
    ],
  },
];

export const islands = [
  {
    name: "Hawai'i",
    description:
      "The Big Island of Hawaii is known for its active volcanoes, Orchid Farms, and the historic Kailua-Kona.",

    events: [
      {
        name: "Volcano Festival",
        affectedGoods: [{ good: "Kona Coffee", impact: 1.15 }],
      },
      {
        name: "Ironman Triathlon",
        affectedGoods: [{ good: "Macadamia Nuts", impact: 1.1 }],
      },
      {
        name: "Mauna Loa's Volcano Eruption",
        affectedGoods: [{ good: "Kona Coffee", impact: 1.5 }],
      },
    ],
  },
  {
    name: "Maui",
    description:
      'Maui, the "Valley Isle," is known for its world-famous beaches, the sacred Iao Valley, and views of migrating humpback whales.',

    events: [
      {
        name: "Whale Festival",
        affectedGoods: [{ good: "Hawaiian Quilts", impact: 1.2 }],
      },
      {
        name: "Lahaina Halloween Party",
        affectedGoods: [{ good: "Pineapples", impact: 0.85 }],
      },
    ],
  },
  {
    name: "Oahu",
    description:
      'Known as "The Gathering Place," Oahu is famous for iconic Waikiki Beach and the cultural hub of Honolulu.',
    events: [
      {
        name: "Honolulu Festival",
        affectedGoods: [{ good: "Aloha Shirts", impact: 1.25 }],
      },
      {
        name: "Triple Crown Surfing",
        affectedGoods: [{ good: "Surfboards", impact: 1.9 }],
      },
      {
        name: "Tourist Season",
        affectedGoods: [
          { good: "Aloha Shirts", impact: 1.2 },
          { good: "Ukuleles", impact: 1.15 },
        ],
      },
    ],
  },
  {
    name: "Kauai",
    description:
      'Kauai, the "Garden Isle," is covered in lush greenery and tropical plants, fed by abundant rainfall.',

    events: [
      {
        name: "Waimea Town Celebration",
        affectedGoods: [{ good: "Tropical Flowers", impact: 1.05 }],
      },
      {
        name: "Kauai Marathon",
        affectedGoods: [{ good: "Koa Wood", impact: 0.9 }],
      },
    ],
  },
  {
    name: "Molokai",
    description:
      'Molokai, the "Friendly Isle," is known for its deep Hawaiian traditions, towering sea cliffs, and the Kalaupapa National Historical Park.',

    events: [
      {
        name: "Molokai Ka Hula Piko",
        affectedGoods: [{ good: "Kona Coffee", impact: 1.1 }],
      },
      {
        name: "Molokai Hoe Canoe Race",
        affectedGoods: [{ good: "Sea Salt", impact: 1.15 }],
      },
    ],
  },
  {
    name: "Lanai",
    description:
      'Lanai, the "Private Isle," offers luxury, adventure, and a place for quiet escape with two high-end resorts and natural beauty.',

    events: [
      {
        name: "Lanai Pineapple Festival",
        affectedGoods: [{ good: "Pineapples", impact: 1.2 }],
      },
      {
        name: "Lanai Film Festival",
        affectedGoods: [{ good: "Macadamia Nuts", impact: 0.95 }],
      },
    ],
  },
  {
    name: "Niihau",
    description:
      'Niihau, the "Forbidden Isle," is largely unpopulated, with access limited to those with permission from the owners of the island.',

    events: [
      {
        name: "Niihau Cultural Heritage Day",
        affectedGoods: [{ good: "Niihau Shell Jewelry", impact: 1.35 }],
      },
      {
        name: "Niihau Hunting Season",
        affectedGoods: [{ good: "Black Pearls", impact: 1.2 }],
      },
    ],
  },
  /* {
    name: "Kahoolawe",
    events: [
      {
        name: "Kahoolawe Island Reserve Day",
        affectedGood: "Reef Conservation Kits",
        impact: 1.05,
      },
      {
        name: "Annual Kahoolawe Regatta",
        affectedGood: "Nautical Equipment",
        impact: 1.2,
      },
    ],
  },*/
];

export const islandsalt = [
  {
    name: "Oahu",
    events: ["Tourist Season", "Surfing Competition", "International Market"],
    specialGoods: ["Aloha Shirts", "Surfboards"],
    description:
      'Known as "The Gathering Place," Oahu is famous for iconic Waikiki Beach and the cultural hub of Honolulu.',
  },
  {
    name: "Maui",
    events: ["Whale Watching", "Cultural Festival", "Agricultural Fair"],
    specialGoods: ["Maui Coffee", "Maui Onions"],
    description:
      'Maui, the "Valley Isle," is known for its world-famous beaches, the sacred Iao Valley, and views of migrating humpback whales.',
  },
  {
    name: "Kauai",
    events: ["Film Festival", "Art Walk", "Garden Tours"],
    specialGoods: ["Koa Wood", "Tropical Flowers"],
    description:
      'Kauai, the "Garden Isle," is covered in lush greenery and tropical plants, fed by abundant rainfall.',
  },
  {
    name: "Hawaii",
    events: ["Volcano Viewing", "Ironman Triathlon", "Coffee Festival"],
    specialGoods: ["Kona Coffee", "Macadamia Nuts"],
    description:
      "The Big Island of Hawaii is known for its active volcanoes, Orchid Farms, and the historic Kailua-Kona.",
  },
  {
    name: "Molokai",
    events: ["Ka Hula Piko Festival", "Fishing Tournament", "Molokai Rodeo"],
    specialGoods: ["Molokai Sweet Bread", "Sea Salt"],
    description:
      'Molokai, the "Friendly Isle," is known for its deep Hawaiian traditions, towering sea cliffs, and the Kalaupapa National Historical Park.',
  },
  {
    name: "Lanai",
    events: [
      "Pineapple Festival",
      "Sporting Clay Shooting",
      "Cat Sanctuary Visit",
    ],
    specialGoods: ["Lanai Pineapples", "Lanai Venison"],
    description:
      'Lanai, the "Private Isle," offers luxury, adventure, and a place for quiet escape with two high-end resorts and natural beauty.',
  },
  {
    name: "Niihau",
    events: ["Private Island Tour", "Shell Lei Workshop"],
    specialGoods: ["Niihau Shell Jewelry", "Makaloa Mats"],
    description:
      'Niihau, the "Forbidden Isle," is largely unpopulated, with access limited to those with permission from the owners of the island.',
  },
];

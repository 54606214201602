export const commonGroceryItemsSE = [
  { category: "100100", name: "Barnmat" }, // Baby Food
  { category: "100100", name: "Barnschampo" }, // Baby Wash
  { category: "100100", name: "Blöjor" }, // Baby Diapers
  { category: "100200", name: "Mjölk" }, // Milk
  { category: "100200", name: "Fil" }, // Sour Milk
  { category: "100200", name: "Yoghurt" }, // Yogurt
  { category: "100200", name: "Ost" }, // Cheese
  { category: "100200", name: "Smör" }, // Butter
  { category: "100300", name: "Bröd" }, // Bread
  { category: "100300", name: "Kakor" }, // Cookies
  { category: "100300", name: "Kanelbullar" }, // Cinnamon Buns
  { category: "100300", name: "Knäckebröd" }, // Crispbread
  { category: "100400", name: "Pasta" }, // Pasta
  { category: "100400", name: "Ris" }, // Rice
  { category: "100400", name: "Mjöl" }, // Flour
  { category: "100400", name: "Havregryn" }, // Oatmeal
  { category: "100500", name: "Kyckling" }, // Chicken
  { category: "100500", name: "Nötkött" }, // Beef
  { category: "100500", name: "Fläskkött" }, // Pork
  { category: "100500", name: "Fisk" }, // Fish
  { category: "100500", name: "Skaldjur" }, // Seafood
  { category: "100600", name: "Äpplen" }, // Apples
  { category: "100600", name: "Bananer" }, // Bananas
  { category: "100600", name: "Apelsiner" }, // Oranges
  { category: "100600", name: "Päron" }, // Pears
  { category: "100600", name: "Druvor" }, // Grapes
  { category: "100700", name: "Tomater" }, // Tomatoes
  { category: "100700", name: "Gurka" }, // Cucumber
  { category: "100700", name: "Paprika" }, // Bell Pepper
  { category: "100700", name: "Sallad" }, // Lettuce
  { category: "100700", name: "Morötter" }, // Carrots
  { category: "100800", name: "Kaffe" }, // Coffee
  { category: "100800", name: "Te" }, // Tea
  { category: "100800", name: "Saft" }, // Juice
  { category: "100800", name: "Läsk" }, // Soda
  { category: "100900", name: "Choklad" }, // Chocolate
  { category: "100900", name: "Godis" }, // Candy
  { category: "100900", name: "Glass" }, // Ice Cream
  { category: "100900", name: "Chips" }, // Chips
  { category: "101000", name: "Tvål" }, // Soap
  { category: "101000", name: "Schampo" }, // Shampoo
  { category: "101000", name: "Tandkräm" }, // Toothpaste
  { category: "101000", name: "Deodorant" }, // Deodorant
  { category: "101100", name: "Tvättmedel" }, // Laundry Detergent
  { category: "101100", name: "Diskmedel" }, // Dishwashing Liquid
  { category: "101100", name: "Rengöringsmedel" }, // Cleaning Agent
  { category: "101100", name: "Soppåsar" }, // Trash Bags
  { category: "101200", name: "Ägg" }, // Eggs
  { category: "101200", name: "Bacon" }, // Bacon
  { category: "101200", name: "Korv" }, // Sausage
  { category: "101200", name: "Tofu" }, // Tofu
  { category: "101200", name: "Färdigmat" }, // Ready Meals
  { category: "101300", name: "Olja" }, // Oil
  { category: "101300", name: "Vinäger" }, // Vinegar
  { category: "101300", name: "Kryddor" }, // Spices
  { category: "101300", name: "Salt" }, // Salt
  { category: "101300", name: "Peppar" }, // Pepper
  { category: "101400", name: "Sylt" }, // Jam
  { category: "101400", name: "Honung" }, // Honey
  { category: "101400", name: "Nutella" }, // Nutella
  { category: "101400", name: "Jordnötssmör" }, // Peanut Butter
  { category: "101500", name: "Potatis" }, // Potatoes
  { category: "101500", name: "Sötpotatis" }, // Sweet Potatoes
  { category: "101500", name: "Lök" }, // Onion
  { category: "101500", name: "Vitlök" }, // Garlic
  { category: "101600", name: "Toalettpapper" }, // Toilet Paper
  { category: "101600", name: "Hushållspapper" }, // Paper Towels
  { category: "101600", name: "Tandborstar" }, // Toothbrushes
  { category: "101600", name: "Munskölj" }, // Mouthwash
  { category: "101700", name: "Kattmat" }, // Cat Food
  { category: "101700", name: "Hundmat" }, // Dog Food
  { category: "101700", name: "Fågelmat" }, // Bird Food
  { category: "101700", name: "Fiskmat" }, // Fish Food
  { category: "101800", name: "Ljus" }, // Candles
  { category: "101800", name: "Batterier" }, // Batteries
  { category: "101800", name: "Glödlampor" }, // Light Bulbs
  { category: "101800", name: "Tejp" }, // Tape
  { category: "101900", name: "Frisallad" }, // Salad Mix
  { category: "101900", name: "Sparris" }, // Asparagus
  { category: "101900", name: "Broccoli" }, // Broccoli
  { category: "101900", name: "Blomkål" }, // Cauliflower
  { category: "101900", name: "Spenat" }, // Spinach
  { category: "102000", name: "Lax" }, // Salmon
  { category: "102000", name: "Räkor" }, // Shrimp
  { category: "102000", name: "Torsk" }, // Cod
  { category: "102000", name: "Sill" }, // Herring
  { category: "102000", name: "Makrill" }, // Mackerel
  { category: "102100", name: "Frukostflingor" }, // Breakfast Cereals
  { category: "102100", name: "Müsli" }, // Muesli
  { category: "102100", name: "Gröt" }, // Porridge
  { category: "102200", name: "Soda" }, // Soda
  { category: "102200", name: "Mineralvatten" }, // Mineral Water
  { category: "102200", name: "Energidryck" }, // Energy Drink
  { category: "102200", name: "Sportdryck" }, // Sports Drink
  { category: "102300", name: "Köttbullar" }, // Meatballs
  { category: "102300", name: "Falukorv" }, // Falu Sausage
  { category: "102300", name: "Hamburgare" }, // Hamburgers
  { category: "102300", name: "Rökt Lax" }, // Smoked Salmon
  { category: "102300", name: "Pannkakor" }, // Pancakes
];

import { useCallback } from "react";
import ICAL from "ical.js"; // Assuming ICAL.js is imported
import { useTranslation } from "react-i18next";

export const ICSImporter = () => {
  const { t } = useTranslation();
  const parseFreq = (rrule) => {
    const parts = rrule.split(";");
    const frequencyPart = parts.find((part) => part.includes("FREQ"));

    if (frequencyPart) {
      const frequencyValue = frequencyPart.split("=")[1];

      switch (frequencyValue) {
        case "DAILY":
          return "daily";
        case "WEEKLY":
          return "weekly";
        case "MONTHLY":
          return "monthly";
        case "YEARLY":
          return "yearly";
        default:
          return "none";
      }
    }

    return "none";
  };

  const parseInterval = (rrule) => {
    const a = rrule.split(";");
    const b = a.find((b) => b.includes("INTERVAL"));

    if (b) {
      const value = b.split("=")[1];

      return value;
    }

    return 1;
  };

  const parseMonthyValue = (rrule) => {
    const a = rrule.split(";");
    const b = a.find((b) => b.includes("BYMONTHDAY"));
    const c = a.find((c) => c.includes("BYDAY"));

    if (b) {
      return "date";
    } else if (c) {
      return "day";
    } else {
      return "day";
    }
  };

  function parseDaysFromRRULE(rrule) {
    // Define a mapping from day abbreviations to numbers
    const dayMap = { SU: 0, MO: 1, TU: 2, WE: 3, TH: 4, FR: 5, SA: 6 };

    // Find the BYDAY part of the RRULE
    const byDayMatch = rrule.match(/BYDAY=([A-Za-z,]+)/);

    if (byDayMatch && byDayMatch.length > 1) {
      // Split the days and map them to numbers
      const days = byDayMatch[1].split(",").map((day) => dayMap[day]);
      return days;
    }

    // Return an empty array if BYDAY is not found or no days are listed
    return [];
  }

  function convertDateToEpoch(date) {
    const dateObj = new Date(date);
    return Math.floor(dateObj.getTime() / 1000);
  }

  const handleFileUpload = useCallback((event, onUploadComplete) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        const jcalData = ICAL.parse(data);
        const comp = new ICAL.Component(jcalData);
        const vevents = comp.getAllSubcomponents("vevent");
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 10);

        const importedEvents = vevents
          .map((vevent) => {
            const event = new ICAL.Event(vevent);
            const eventEndDate = new Date(event.endDate);
            const dateObj = new Date(event.startDate);

            // Get hours and minutes
            const hours = dateObj.getHours();
            const minutes = dateObj.getMinutes();

            // Format minutes to always be two digits
            const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

            const start = new Date(event.startDate);
            const end = new Date(event.endDate);

            // Calculate difference in milliseconds and then convert to minutes
            const durationInMinutes = (end - start) / (1000 * 60);

            // Round up to the nearest 15 minutes
            const roundedDuration = Math.ceil(durationInMinutes / 15) * 15;

            const recurrenceRule =
              event.component.getFirstPropertyValue("rrule");

            if (
              recurrenceRule &&
              parseFreq(recurrenceRule.toString()) != "none"
            ) {
              return {
                id: event.uid,
                // allDay: true,
                color: "lavenderblush",
                note: event.description,
                start: new Date(event.startDate),
                time: hours + ":" + formattedMinutes,
                duration: {
                  label: roundedDuration.toString() + t("common-text-minutes"),
                  value: roundedDuration,
                },
                end: eventEndDate,
                name: event.summary,
                isTask: false,
                //  event: event,
                isRepeats: true,
                repeatMonthlyValue: parseMonthyValue(recurrenceRule.toString()),
                days: parseDaysFromRRULE(recurrenceRule.toString()),
                repeatValue: parseFreq(recurrenceRule.toString()),
                repeatWeeklyIntervalValue: parseInterval(
                  recurrenceRule.toString()
                ),
                rRule: recurrenceRule.toString(),
              };
            } else if (
              convertDateToEpoch(eventEndDate) >
                convertDateToEpoch(yesterday) &&
              event.summary != null
            ) {
              return {
                id: event.uid,
                // allDay: true,
                color: "lightgreen",
                note: event.description,
                start: new Date(event.startDate),
                time: hours + ":" + formattedMinutes,
                duration: {
                  label: roundedDuration.toString() + t("common-text-minutes"),
                  value: roundedDuration,
                },
                end: eventEndDate,
                name: event.summary,
                isTask: false,
                //event: event,
                isRepeats: false,
                repeatMonthlyValue: "",
                days: [],
                repeatValue: "",
                repeatWeeklyIntervalValue: "",
                rRule: "",
              };
            }

            return null;
          })
          .filter((event) => event !== null);

        // Callback to pass the imported events back to the component
        onUploadComplete(importedEvents);
      };
      reader.readAsText(file);
    }
  }, []);

  return handleFileUpload;
};

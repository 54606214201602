import { useEffect } from "react";
import {
  faBolt,
  faEnvelope,
  faEye,
  faEyeSlash,
  faListCheck,
  faCheck,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, SetStateAction, useState } from "react";
import { CategoryEditModal } from "../CategoryModal/CategoryEditModal";
import { Button } from "Common";
import { ItemModel, useItems } from "Lists";
import { ConfirmationModal } from "Common";

import { QuickAddModal } from "./QuickAddModal";
import { ToggleSwitch } from "Common/ToggleSwitch/ToggleSwitch";
import classes from "./ListActions.module.css";
import { useTranslation } from "react-i18next";

interface ListActionsProps {
  // disableClearCompleted: boolean;
  items: ItemModel[];
  showCompleted: boolean;
  showAllCat: boolean;
  isDarkMode: boolean;
  clearCompleted: () => void;
  emailList: () => void;
  setShowCompleted: Dispatch<SetStateAction<boolean>>;
  setShowAllCat: Dispatch<SetStateAction<boolean>>;
  setIsAddingNewItem: () => void;
  isTabClosed: boolean;
  LengthCheckedItems: number;
  setIsTabClosed: Dispatch<SetStateAction<boolean>>;
  categories: any[];
}

export const ListActions = (props: ListActionsProps) => {
  const {
    // disableClearCompleted,
    items,
    showCompleted,
    showAllCat,
    isDarkMode,
    clearCompleted,
    emailList,
    setShowCompleted,
    setShowAllCat,
    setIsAddingNewItem,
    isTabClosed,
    setIsTabClosed,
    LengthCheckedItems,
    categories,
  } = props;
  useItems();
  const [modalOpen, setModalOpen] = useState(false);
  const [isCatEditModalOpen, setIsCatEditModalOpen] = useState(false);
  // const [isTabClosed, setIsTabClosed] = useState(false);
  const [isNative, setIsNative] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { t } = useTranslation();
  const hideItCompleted = () => {
    setShowCompleted((old) => !old);
    // console.log("What is it here", showCompleted)
    localStorage.setItem("ShowCompleted", JSON.stringify(!showCompleted));
  };

  const hideItCategories = () => {
    setShowAllCat((old) => !old);
    // console.log("What is it here", showAllCat)
    localStorage.setItem("ShowAllCat", JSON.stringify(!showAllCat));
  };

  const updateTabStatus = () => {
    setIsTabClosed((old) => !old);
    // console.log("What is it here", showAllCat)
    localStorage.setItem("tabstatus", JSON.stringify(!showAllCat));
  };

  //console.log("what is LengthCheckedItems", LengthCheckedItems);

  useEffect(() => {
    if ((window as any).isNative) {
      // Post the message back to expo
      setIsNative(true);
    }
    return () => {};
  }, [showCompleted, showAllCat]);

  return (
    <>
      <QuickAddModal
        isOpen={modalOpen}
        items={items}
        onRequestClose={() => setModalOpen(false)}
      />
      <div
        //  className={classes.container}
        style={{
          height: "100vh",
          width: "100vw",
          filter: "invert(100%)",
          overflow: "scroll",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: 10,
            width: "100%",
            borderRadius: "0px 0px 10px 10px",
            overflow: "scroll",
          }}
        >
          <label style={{ marginTop: 20, color: "white", paddingBottom: 10 }}>
            List Options Menu
          </label>
          <div
            className="metroButton"
            style={{
              borderRadius: 10,
              color: "white",
              backgroundColor: "cornflowerblue",
              // width: 200,
              marginTop: 5,
              cursor: "pointer",
            }}
            onClick={() => setModalOpen(true)}
          >
            Quick Add Favorites
            <div style={{ fontSize: 12 }}>Select from your favorite items</div>
          </div>
          <div
            className="metroButton"
            style={{
              borderRadius: 10,
              color: "white",
              backgroundColor: showCompleted ? "cornflowerblue" : "darksalmon",
              //  width: 200,
              marginTop: 5,
              cursor: "pointer",
            }}
            onClick={hideItCompleted}
          >
            {showCompleted ? "Showing All Items" : "Hiding Checked Items"}
            <div style={{ fontSize: 12 }}>
              Toggle the visibility of checked items
            </div>
          </div>
          <div
            className="metroButton"
            style={{
              borderRadius: 10,
              color: "white",
              backgroundColor: showAllCat ? "cornflowerblue" : "darksalmon",
              //   width: 200,
              marginTop: 5,
              cursor: "pointer",
            }}
            onClick={hideItCategories}
          >
            {showAllCat ? "Showing All Lists" : "Hiding Empty Lists"}
            <div style={{ fontSize: 12 }}>
              Toggle the visibility of empty lists
            </div>
          </div>

          <div
            className="metroButton"
            style={{
              borderRadius: 10,
              color: "white",
              backgroundColor: "cornflowerblue",
              // width: 200,
              marginTop: 5,
              cursor: "pointer",
            }}
            onClick={emailList}
          >
            Compile Email with Uncompleted List Items
            <div style={{ fontSize: 12 }}>Share your items via Email</div>
          </div>

          <div
            className="metroButton"
            style={{
              borderRadius: 10,
              color: "white",
              backgroundColor: "cornflowerblue",
              // width: 200,
              marginTop: 5,
              cursor: "pointer",
            }}
            onClick={() => setIsCatEditModalOpen(true)}
          >
            Add, Edit, or Delete Lists
            <div style={{ fontSize: 12 }}></div>
          </div>

          <div
            className="metroButton"
            style={{
              borderRadius: 10,
              color: "blue",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
            onClick={() => setIsTabClosed(true)}
          >
            Close Menu
            <div style={{ fontSize: 14 }}></div>
          </div>
        </div>
        {isCatEditModalOpen ? (
          <CategoryEditModal
            isOpen={isCatEditModalOpen}
            close={() => setIsCatEditModalOpen(false)}
            isDarkMode={isDarkMode}
            categories={categories}
          />
        ) : null}
        <ConfirmationModal
          isOpen={confirmModalOpen}
          text={t("generic-list-action-del-warning")}
          onClickCancel={() => setConfirmModalOpen(false)}
          onClickConfirm={() => {
            clearCompleted();
            setConfirmModalOpen(false);
          }}
        />
      </div>
      <div style={{ paddingBottom: 100 }} />
    </>
  );
};

import React, { useState, useEffect } from "react";

export const CountdownTimer = ({ count, setCount }) => {
  //  const [count] = useState(start);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return <span> {count}</span>;
};

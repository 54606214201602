import {
  faExclamationCircle,
  faMagnifyingGlass,
  faClose,
  faImages,
  faInfo,
  faInfoCircle,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { doc, updateDoc } from "firebase/firestore";
import { useUserContext } from "Authentication";
import { CategoryModel, ItemModel } from "Lists";
import { useState, useEffect } from "react";
import { Item } from "../Common/Item";
import { useAuth } from "../../../Authentication/AuthContext";
import { useItems } from "Lists";
import classes from "./ListItem.module.css";
import { Link, useHistory } from "react-router-dom";
import { Modal } from "Common";
import TextareaAutosize from "react-textarea-autosize";
import toast, { Toaster } from "react-hot-toast";
import { ConfirmationModal } from "Common";
import { CategoryButton } from "../Common";

export interface ListItemProps {
  item: ItemModel;
  category: CategoryModel | undefined;
  onClickCategory: () => void;

  onClickItem: () => void;
}

export const ListItem = (props: ListItemProps) => {
  const { item, category, onClickCategory, onClickItem } = props;
  const { getDefaultItemsCollection } = useUserContext();
  const user = useUserContext();
  const [isImageSearch, setIsImageSearch] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const imageURL = `https://www.bing.com/images/search?q=${item.name}`;
  const [isDone, setIsDone] = useState(item.completed);

  const close = () => {
    setIsImageSearch(false);
  };

  const { removeItem } = useItems();

  const DeleteItemConfirmation = () => {
    removeItem(item.id);
  };

  const handleChange = async () => {
    setIsDone(!isDone);

    toast("Item Status Updated", {
      duration: 1000,
      position: "top-center",
      style: {
        backgroundColor: "lightgreen",
        border: "1px solid #713200",
        fontSize: "16px",
        padding: "10px",
        color: "black",
      },
    });

    const itemsRef = await getDefaultItemsCollection();
    const itemRef = doc(itemsRef, item.id);
    await updateDoc(itemRef, {
      completed: !isDone,
      lastCompleted: Date(),
      completedBy: user.user.name,
    });
  };

  useEffect(() => {
    setIsDone(item.completed);

    return () => {};
  }, [item]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "right",
          justifyContent: "right",
          paddingBottom: 10,
        }}
      >
        <FontAwesomeIcon
          style={{
            marginBottom: 1,

            color: "darkblue",
            paddingRight: 10,
            paddingLeft: 7,
            cursor: "pointer",
            alignSelf: "center",
          }}
          icon={faTrashCan}
          size="sm"
          onClick={() => setConfirmModalOpen(true)}
        />
        <CategoryButton
          buttonColour={category?.colour}
          onClick={onClickCategory}
        />

        <span
          style={{
            marginRight: 5,
            paddingLeft: 7,
            cursor: "pointer",
            fontSize: 14,
            textAlign: "center",
            color: "blue",
            marginTop: 0,
            alignSelf: "center",
          }}
          onClick={onClickItem}
        >
          {" "}
          <FontAwesomeIcon icon={faInfoCircle} size="lg" />
        </span>
      </div>

      <div className={classes.content} style={{ flexDirection: "row" }}>
        <input
          type="checkbox"
          checked={isDone}
          style={{ marginTop: -12 }}
          onChange={() => {
            handleChange();
          }}
          //  className={classes.details}
        />
        {item.flagged ? (
          <div style={{ alignSelf: "center", marginLeft: 5 }}>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              color={"var(--colour-danger)"}
              className={classes.flag}
            />
          </div>
        ) : null}
        <div className={classes.details} onClick={() => handleChange()}>
          <>
            {isDone ? (
              <div>
                <div
                  style={{
                    // width: "95%",
                    fontSize: 16,
                    backgroundColor: "transparent",
                    //      color: "navy",
                    paddingLeft: 3,
                    paddingRight: 2,
                    lineHeight: 1.2,
                    border: 0,
                    verticalAlign: "center",
                    fontWeight: "normal",
                    textDecorationLine: "line-through",
                  }}
                >
                  {item.name}
                  <span style={{ fontSize: 12 }}>
                    {item.quantity != undefined
                      ? "(" + item.quantity + ")"
                      : "(1X)"}
                  </span>
                </div>
              </div>
            ) : (
              <div>
                <div
                  style={{
                    // width: "95%",
                    fontSize: 16,
                    backgroundColor: "transparent",
                    lineHeight: 1.2,
                    //     color: "navy",
                    paddingLeft: 3,
                    paddingRight: 2,
                    border: 0,

                    fontWeight: "normal",
                  }}
                >
                  {item.name}
                  <span style={{ paddingLeft: 5, fontSize: 12 }}>
                    {item.quantity != undefined
                      ? "(" + item.quantity + ")"
                      : "(1X)"}
                  </span>
                </div>
              </div>
            )}
          </>

          {isDone ? (
            <p
              style={{
                textDecorationLine: "line-through",
                textDecorationStyle: "solid",
                paddingLeft: 3,
                backgroundColor: "transparent",
                lineHeight: 1.2,
                paddingRight: 2,
                //   color: "navy",
                marginTop: -2,
                paddingTop: 5,
                fontSize: 10,
                fontFamily: "inherit",
                fontStyle: "italic",
              }}
            >
              {item.notes != null ? item.notes : "No Notes"}
            </p>
          ) : (
            <p
              style={{
                paddingRight: 2,
                paddingLeft: 3,
                textDecorationLine: "",
                textDecorationStyle: "solid",
                lineHeight: 1.2,
                // backgroundColor: "lightblue",

                paddingTop: 5,
                color: "black",
                fontSize: 10,
                marginTop: -2,
                fontFamily: "inherit",
                fontStyle: "italic",
              }}
            >
              {item.notes != null ? item.notes : "No Notes"}
            </p>
          )}
        </div>
      </div>
      {/*   {!item.completed ? (
      <div style={{fontSize:10, marginLeft: 42, paddingTop: 5, fontStyle:"italic"}}>Added: {item.addedDate}</div>) : 
      <div style={{fontSize:10, marginLeft: 42,}}>Completed</div> }
     */}

      <ConfirmationModal
        isOpen={confirmModalOpen}
        //@ts-ignore
        text={"Are you sure you want to remove " + item.name + "?"}
        onClickCancel={() => setConfirmModalOpen(false)}
        onClickConfirm={DeleteItemConfirmation}
      />
    </div>
  );
};

import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faStar,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import cx from "classnames/bind";

import { Button, Modal } from "Common";
import { ItemModel, useItems } from "Lists";

import classes from "./ItemModal.module.css";
import { useUserContext } from "Authentication";
import TextareaAutosize from "react-textarea-autosize";
import { ConfirmationModal } from "Common";
import { useTranslation } from "react-i18next";

const classnames = cx.bind(classes);

interface ItemModalProps {
  isOpen: boolean;
  item: ItemModel | undefined;
  close: () => void;
}

export const ItemModal = (props: ItemModalProps) => {
  const { isOpen, item, close } = props;
  const [name, setName] = useState(item?.name);
  const [notes, setNotes] = useState(item?.notes);
  const [isFavorite, setIsFavorite] = useState(item?.isFavorite);
  const [flagged, setFlagged] = useState(item?.flagged);
  const [addedDate, setAddedDate] = useState(item?.addedDate);
  const [addedBy, setAddedBy] = useState(item?.addedBy);
  const [lastCompleted, setLastCompleted] = useState(item?.lastCompleted);
  const { deleteItem, removeItem, setItemFlag, setItemFavorite, updateItem } =
    useItems();
  const user = useUserContext();
  const [hideMoreInfo, setHideMoreInfo] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const { t } = useTranslation();
  // var name = item?.name

  useEffect(() => {
    // Prevents old values being displayed when modal fades out.
    if (isOpen) {
      setName(item?.name);
      setNotes(item?.notes);
      setAddedDate(item?.addedDate);
      setAddedBy(item?.addedBy);
      setFlagged(item?.flagged);
      setIsFavorite(item?.isFavorite);
    }
  }, [item, isOpen]);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (notes !== item?.notes && item?.id) {
      await updateItem(item.id, {
        notes,
      });
    }

    if (name !== item?.name && item?.id) {
      await updateItem(item.id, {
        name,
        addedBy: user.user.name,
      });
    }
    setIsUpdated(false);
    close();
  }

  async function handleRemove() {
    item?.id && (await removeItem(item.id));
    close();
  }

  async function handleDelete() {
    item?.id && (await deleteItem(item?.id));
    close();
  }

  function handleFlag() {
    setFlagged((f) => !f);
    item?.id && setItemFlag(item.id, !flagged);
  }

  function handleFavorite() {
    setIsFavorite((f) => !f);
    item?.id && setItemFavorite(item.id, !isFavorite);
  }

  const DeleteItemConfirmation = () => {
    handleDelete();
    setConfirmModalOpen(false);
  };

  const updateNameValue = (e) => {
    setName(e);
    if (name !== item?.name || notes !== item?.notes) {
      setIsUpdated(true);
    } else {
      setIsUpdated(false);
    }
  };

  const updateNotesValue = (e) => {
    setNotes(e);
    if (name !== item?.name || notes !== item?.notes) {
      setIsUpdated(true);
    } else {
      setIsUpdated(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      style={{ overlay: { zIndex: 100 } }}
    >
      <div style={{ paddingBottom: 100, filter: "invert(100%)" }}>
        <Modal.Body>
          <div style={{ padding: 10 }}>
            <div
              style={{
                paddingLeft: 30,
                paddingRight: 30,
                paddingBottom: 10,
                border: 1,
                borderColor: "grey",
                borderRadius: 10,
                backgroundColor: "snow",
                color: "grey",
                boxShadow: "2px 3px 3px grey, 0 0 5px #FCFBF4 inset",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "right",
                  justifyContent: "right",
                  margin: 10,
                }}
              >
                <button
                  style={{
                    // backgroundColor: "white",
                    borderStyle: "none",
                    cursor: "pointer",
                    marginRight: -25,
                    marginTop: 10,
                    backgroundColor: "transparent",
                  }}
                  onClick={close}
                >
                  <FontAwesomeIcon icon={faClose} size="1x" />
                </button>
              </div>

              <form onSubmit={handleSubmit}>
                <label style={{ color: "darkred" }}>
                  {t("grocery-list-details-item-name")}
                </label>
                <TextareaAutosize
                  value={name}
                  onChange={(e) => updateNameValue(e.target.value)}
                  style={{
                    width: "85%",
                    fontSize: 22,
                    //       color: "navy",
                    backgroundColor: "transparent",
                    padding: 10,
                    border: 0,
                  }}
                />
                <label
                  style={{ paddingTop: 10, paddingBottom: 5, color: "darkred" }}
                >
                  {t("grocery-list-details-notes")}
                </label>
                <TextareaAutosize
                  value={notes}
                  // rows={3}
                  placeholder={t("grocery-list-notes-placeholder")}
                  onChange={(e) => updateNotesValue(e.target.value)}
                  style={{
                    width: "85%",
                    fontSize: 20,
                    padding: 10,
                    //   color: "navy",
                    backgroundColor: "transparent",
                  }}
                />
                <div>
                  {/*}  <Button
                    type="button"
                    title={flagged ? "Item flagged" : "Flag item"}
                    onClick={handleFlag}
                    className={classnames(classes.flagButton, {
                      [classes.flagged]: flagged,
                    })}
                  >
                    <FontAwesomeIcon icon={faExclamationCircle} size="3x" />
                  </Button>*/}
                  <label
                    style={{
                      paddingTop: 10,
                      paddingBottom: 5,
                      color: "darkred",
                    }}
                  >
                    {t("grocery-list-favorite-item")}
                  </label>
                  <Button
                    type="button"
                    title={isFavorite ? "Item Favorite" : "Make Favorite"}
                    onClick={handleFavorite}
                    className={classnames(classes.isFavoriteButton, {
                      [classes.isFavorite]: isFavorite,
                    })}
                  >
                    <FontAwesomeIcon
                      style={{ marginBottom: 1 }}
                      icon={faStar}
                      size="3x"
                    />
                  </Button>
                </div>

                {hideMoreInfo ? (
                  <div>
                    <hr />
                    <div style={{ paddingBottom: 0 }}>
                      <button
                        style={{
                          fontFamily: "inherit",
                          fontSize: "inherit",
                          backgroundColor: "#1e79c3",
                          cursor: "pointer",
                          border: 0,
                          borderRadius: 10,
                          color: "white",
                          paddingLeft: 20,
                          paddingRight: 20,
                        }}
                        onClick={() => setHideMoreInfo(false)}
                      >
                        {t("grocery-list-hide-details")}
                      </button>
                    </div>
                    <p style={{ fontWeight: 600, color: "darkred" }}>
                      {t("grocery-list-added-by")}
                    </p>{" "}
                    <span style={{ color: "black" }}>{item?.addedBy}</span>
                    {item?.completed ? (
                      <>
                        <p style={{ fontWeight: 600, color: "darkred" }}>
                          {t("grocery-list-completed-date")}
                        </p>{" "}
                        <span style={{ color: "black" }}>
                          {item?.lastCompleted}
                        </span>
                        <p style={{ fontWeight: 600, color: "darkred" }}>
                          {t("grocery-list-completed-by")}
                        </p>{" "}
                        <span style={{ color: "black" }}>
                          {item?.completedBy}
                        </span>
                      </>
                    ) : null}
                    <h4 style={{ color: "darkred" }}>
                      {t("grocery-list-use-count")}{" "}
                      <span style={{ color: "black" }}> {item?.count}</span>
                    </h4>
                    <div>
                      <button
                        style={{
                          fontFamily: "inherit",
                          fontSize: "inherit",
                          color: "blue",
                          paddingBottom: 10,
                          paddingTop: 10,
                          backgroundColor: "transparent",
                          borderStyle: "none",
                        }}
                        type="button"
                        onClick={handleRemove}
                      >
                        {t("grocery-list-remove-list")}
                      </button>
                    </div>
                    <div>
                      <button
                        style={{
                          fontFamily: "inherit",
                          fontSize: "inherit",
                          color: "blue",
                          backgroundColor: "transparent",
                          borderStyle: "none",
                        }}
                        type="button"
                        onClick={() => setConfirmModalOpen(true)}
                      >
                        {t("grocery-list-delete-permanently")}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div style={{ paddingTop: 20 }}>
                    <button
                      style={{
                        fontFamily: "inherit",
                        fontSize: "inherit",
                        backgroundColor: "#1e79c3",
                        cursor: "pointer",
                        border: 0,
                        borderRadius: 10,
                        color: "white",
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}
                      onClick={() => setHideMoreInfo(true)}
                    >
                      {t("grocery-list-show-more")}
                    </button>
                  </div>
                )}
                {isUpdated ? (
                  <p
                    className="blinkingButton"
                    style={{
                      textAlign: "center",
                      // color: "white",
                      color: "firebrick",
                      fontWeight: "bold",
                      fontSize: 16,
                      marginTop: 20,
                    }}
                  >
                    Changes not saved. Remember to save before exiting.
                  </p>
                ) : null}
                <div className={classes.modalActions} style={{ paddingTop: 0 }}>
                  <button
                    type="button"
                    onClick={handleRemove}
                    className={classes.button}
                    style={{
                      fontFamily: "inherit",
                      fontSize: "inherit",
                      padding: 10,
                      width: 125,
                      border: 0,
                      marginTop: 30,
                    }}
                  >
                    {t("common-button-remove")}
                  </button>
                  {isUpdated ? (
                    <button
                      type="submit"
                      style={{
                        fontFamily: "inherit",
                        fontSize: "inherit",
                        padding: 10,
                        width: 125,
                        border: 0,
                        marginTop: 30,
                      }}
                    >
                      {t("common-button-save")}
                    </button>
                  ) : (
                    <span />
                  )}
                </div>
                <div className={classes.modalActions} style={{ paddingTop: 0 }}>
                  <button
                    type="button"
                    onClick={close}
                    className={classes.secondaryButton}
                    style={{
                      fontFamily: "inherit",
                      fontSize: "inherit",
                      padding: 10,
                      cursor: "pointer",
                      backgroundColor: "transparent",
                      color: "blue",
                      width: 200,
                      border: 0,
                    }}
                  >
                    {t("common-button-cancel")}
                  </button>
                </div>
              </form>
              <div style={{ paddingBottom: 150 }} />
            </div>
          </div>
        </Modal.Body>
      </div>
      <ConfirmationModal
        isOpen={confirmModalOpen}
        text={t("grocery-list-delete-warning")}
        onClickCancel={() => setConfirmModalOpen(false)}
        onClickConfirm={DeleteItemConfirmation}
      />
    </Modal>
  );
};

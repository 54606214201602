import React, {
  createRef,
  FormEvent,
  useRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import CreatableSelect from "react-select/async-creatable";

//import { useCategories } from "../../CategoriesContext"
//import { useCategorySubscription } from "../../../Lists/useCatgorySubscription";
import { ItemModel } from "../../models";

import { Item } from "../Common/Item";
import { CategoryModal } from "../CategoryModal";
//import { CategoryEditModal } from "../CategoryModal/CategoryEditModal";
import { CategoryAddItemModal } from "../CategoryModal/CategoryAddItemModal";
import { useItemInput } from "./useItemInput";
import { LoadOptionsCallback, SelectRef } from "./types";
import toast, { Toaster } from "react-hot-toast";
import classes from "./AddItem.module.css";
import { useTranslation } from "react-i18next";

export interface AddItemsProps {
  addedItems: ItemModel[];
  allItems: ItemModel[];
  category: string;
  isDarkMode: boolean;
  setCategory: React.Dispatch<React.SetStateAction<string>>;
  categories: any[];
}

export const AddItemWidget = forwardRef<SelectRef, AddItemsProps>(
  (props, ref) => {
    const {
      addedItems,
      category,
      allItems,
      setCategory,
      isDarkMode,
      categories,
    } = props;
    const { alreadyAdded, isValid, previouslyAdded, value, addItem, setValue } =
      useItemInput(addedItems, category, setCategory, allItems);
    const inputRef = createRef<SelectRef>();
    useImperativeHandle(ref, () => inputRef.current as SelectRef);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCatEditModalOpen, setIsCatEditModalOpen] = useState(false);
    const [isCatAddItemModalOpen, setIsCatAddItemModalOpen] = useState(false);
    //const categories = useCategories()
    //const { categories } = useCategorySubscription();
    const [addIT, setAddIT] = useState(false);
    const [quantity, setQuantity] = useState("1");
    const { t } = useTranslation();

    var quickAddLock = 0;

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        fontWeight: state.data.label === "Create" ? "bold" : "bold",
        color: "blue",
        textDecoration: "underline",
      }),
    };

    const formatCreateLabel = (inputValue) => {
      return (
        <span
          style={{
            fontWeight: "bold",
            backgroundColor: "yellow",
            padding: 5,
            borderRadius: 5,
          }}
        >
          {t("grocery-list-create-item")}: "<em>{inputValue}</em>"
        </span>
      );
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
      //   console.log("what is category on submit", category)
      //  console.log("what is the item name", value)
      event.preventDefault();
      addItem(value, quantity, category);
      setQuantity("1");
      inputRef.current?.focus();
    };

    const options = useMemo(
      () =>
        allItems
          .map((i) => ({ value: i.name, label: i.name }))
          .sort((a, b) =>
            a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
          ),
      [allItems]
    );

    const handleLoadOptions = (
      inputValue: string,
      callback: LoadOptionsCallback
    ) =>
      callback(
        options.filter((o) =>
          o.label.toLowerCase().includes(inputValue.toLowerCase())
        )
      );

    function quickAdd() {
      setAddIT(false);
      // console.log ("start", quickAddLock)

      if (value != "") {
        quickAddLock = quickAddLock + 1;

        if (quickAddLock == 1) {
          setTimeout(() => {
            quickAddLock = quickAddLock + 1;
            if (previouslyAdded) {
              addItem(value, quantity);
              setQuantity("1");
              //   console.log("re-add",value)
            } else {
              addItem(value, quantity);
              setQuantity("1");
              //  setIsCatAddItemModalOpen(true)
            }
            setAddIT(false);
          }, 300);

          quickAddLock = quickAddLock + 1;
        } else {
          // console.log("end", quickAddLock);
        }
      }
      toast(t("generic-list-added-toast"), {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "lightgreen",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
    }

    return (
      <>
        <form
          onSubmit={handleSubmit}
          className={classes.form}
          // style={{ backgroundColor: "#DFFFC2" }}
          style={{ backgroundColor: "darkblue" }}
        >
          <div className={classes.actions}>
            <div
              //  type="submit"
              // disabled={!isValid}
              className={classes.add}
              style={{
                width: "100%",
                fontFamily: "inherit",
                textAlign: "center",

                fontSize: 14,
                color: "white",
                backgroundColor: "transparent",
                fontWeight: 500,
                marginTop: 0,
              }}
            ></div>
            {addIT && { isValid } ? quickAdd() : null}
          </div>
          <input
            type="text"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            style={{
              padding: 10,
              fontSize: 12,
              width: 75,
              border: 1,
              borderRadius: 5,
              borderColor: "lightgrey",
              borderStyle: "inherit",
            }}
          />
          <span style={{ fontSize: 12, paddingLeft: 5, color: "white" }}>
            {t("grocery-list-how-many")}
          </span>
          <div style={{ paddingTop: 2 }} />
          <Item
            onClickCategory={() => setIsModalOpen(true)}
            categoryButton={false}
            buttonColour={
              categories.find((c) => c.id === category)?.colour ||
              "var(--colour-uncategorised)"
            }
          >
            <CreatableSelect
              placeholder={t("grocery-list-add-placeholder")}
              value={{ label: "", value }}
              onChange={(newValue) => {
                setValue(newValue?.value ?? "");
                setAddIT(true);
              }}
              className={classes.myform}
              loadOptions={handleLoadOptions}
              noOptionsMessage={() => null}
              isClearable
              ref={inputRef}
              blurInputOnSelect={true}
              formatCreateLabel={formatCreateLabel}
              styles={customStyles}
            />
          </Item>

          {/*    {alreadyAdded ? (
            <small className={classes.error}>
              {alreadyAdded.name} has already been added
            </small>
     ) : null} */}
        </form>
        {/*  <div style={{display: 'flex',
          alignItems: 'center',
          justifyContent: 'center', marginTop: 10 }} onClick={()=>setIsCatEditModalOpen(true)}>Manage Categories</div>*/}
        {isModalOpen ? (
          <CategoryModal
            isOpen={isModalOpen}
            selectedCategoryId={category}
            close={() => setIsModalOpen(false)}
            isDarkMode={isDarkMode}
            categories={categories}
            select={(category) => {
              setCategory(category);
              return Promise.resolve();
            }}
          />
        ) : null}

        {isCatAddItemModalOpen ? (
          <CategoryAddItemModal
            isOpen={isCatAddItemModalOpen}
            value={value}
            quantity={quantity}
            close={() => setIsCatAddItemModalOpen(false)}
            categories={categories}
          />
        ) : null}

        {/*   <CategoryEditModal
          isOpen={isCatEditModalOpen}
          selectedCategoryId={category}
          close={() => setIsCatEditModalOpen(false)}
          select={(category) => {
            setCategory(category)
            return Promise.resolve()
          }}
        /> */}
      </>
    );
  }
);

export const commonGroceryCategories = [
  { id: "100100", name: "Baby", colour: `#f4a460`, order: 10 },
  { id: "100200", name: "Baked Goods", colour: `#e6e6fa`, order: 10 },
  { id: "100300", name: "Baking", colour: `#9370db`, order: 10 },
  { id: "100400", name: "Beverages", colour: `#d3d3d3`, order: 10 },
  { id: "100500", name: "Cleaning", colour: `#66cdaa`, order: 10 },
  { id: "100600", name: "Sauces", colour: "#f0e68c", order: 10 },
  { id: "100700", name: "Dairy", colour: `#fffff0`, order: 10 },
  { id: "100800", name: "Frozen", colour: `#e0ffff`, order: 10 },
  { id: "100900", name: "Produce", colour: `#ff69b4`, order: 10 },
  // { id: "101000", name: "Kitchen", colour: `#98fb98`, order: 10 },
  { id: "101100", name: "Meat and Poultry", colour: `#fff0f5`, order: 10 },
  { id: "101200", name: "Medicine", colour: `#dda0dd`, order: 10 },
  // { id: "101300", name: "Misc", colour: `#7cfc00`, order: 10 },
  // { id: "101400", name: "Office", colour: `#48d1cc`, order: 10 },
  { id: "101500", name: "Pantry", colour: `#add8e6`, order: 10 },
  //{ id: "101600", name: "Personal", colour: `#b0c4de`, order: 10 },
  { id: "101700", name: "Pet Supplies", colour: `#ffe4e1`, order: 10 },
  { id: "101800", name: "Seafood", colour: `#fa8072`, order: 10 },
  { id: "101900", name: "Snacks", colour: `#fffacd`, order: 10 },
  { id: "102000", name: "Spices", colour: `#21b2ba`, order: 10 },
  { id: "102500", name: "Herbs", colour: `#20b2aa`, order: 10 },
  // { id: "102100", name: "Travel", colour: `#87ceeb`, order: 10 },
  //  { id: "102200", name: "Errands", colour: `#00ff7f`, order: 10 },
];

export const commonGroceryCategoriesFR = [
  { id: "100100", name: "Bébé", colour: `#f4a460`, order: 10 }, // Baby
  {
    id: "100200",
    name: "Produits de boulangerie",
    colour: `#e6e6fa`,
    order: 10,
  }, // Baked Goods
  { id: "100300", name: "Pâtisserie", colour: `#9370db`, order: 10 }, // Baking
  { id: "100400", name: "Boissons", colour: `#d3d3d3`, order: 10 }, // Beverages
  { id: "100500", name: "Nettoyage", colour: `#66cdaa`, order: 10 }, // Cleaning
  { id: "100600", name: "Sauces", colour: "#f0e68c", order: 10 }, // Sauces
  { id: "100700", name: "Produits laitiers", colour: `#fffff0`, order: 10 }, // Dairy
  { id: "100800", name: "Surgelés", colour: `#e0ffff`, order: 10 }, // Frozen
  { id: "100900", name: "Produits frais", colour: `#ff69b4`, order: 10 }, // Produce
  // { id: "101000", name: "Cuisine", colour: `#98fb98`, order: 10 }, // Kitchen
  { id: "101100", name: "Viandes et volailles", colour: `#fff0f5`, order: 10 }, // Meat and Poultry
  { id: "101200", name: "Médicaments", colour: `#dda0dd`, order: 10 }, // Medicine
  // { id: "101300", name: "Divers", colour: `#7cfc00`, order: 10 }, // Misc
  // { id: "101400", name: "Bureau", colour: `#48d1cc`, order: 10 }, // Office
  { id: "101500", name: "Garde-manger", colour: `#add8e6`, order: 10 }, // Pantry
  //{ id: "101600", name: "Personnel", colour: `#b0c4de`, order: 10 }, // Personal
  {
    id: "101700",
    name: "Fournitures pour animaux de compagnie",
    colour: `#ffe4e1`,
    order: 10,
  }, // Pet Supplies
  { id: "101800", name: "Fruits de mer", colour: `#fa8072`, order: 10 }, // Seafood
  { id: "101900", name: "Collations", colour: `#fffacd`, order: 10 }, // Snacks
  { id: "102000", name: "Épices", colour: `#21b2ba`, order: 10 }, // Spices
  { id: "102500", name: "Herbes", colour: `#20b2aa`, order: 10 }, // Herbs
  // { id: "102100", name: "Voyage", colour: `#87ceeb`, order: 10 }, // Travel
  //  { id: "102200", name: "Courses", colour: `#00ff7f`, order: 10 }, // Errands
];

export const commonGroceryCategoriesES = [
  { id: "100100", name: "Bebé", colour: `#f4a460`, order: 10 }, // Baby
  { id: "100200", name: "Panadería", colour: `#e6e6fa`, order: 10 }, // Baked Goods
  { id: "100300", name: "Repostería", colour: `#9370db`, order: 10 }, // Baking
  { id: "100400", name: "Bebidas", colour: `#d3d3d3`, order: 10 }, // Beverages
  { id: "100500", name: "Limpieza", colour: `#66cdaa`, order: 10 }, // Cleaning
  { id: "100600", name: "Salsas", colour: "#f0e68c", order: 10 }, // Sauces
  { id: "100700", name: "Productos lácteos", colour: `#fffff0`, order: 10 }, // Dairy
  { id: "100800", name: "Congelados", colour: `#e0ffff`, order: 10 }, // Frozen
  { id: "100900", name: "Productos frescos", colour: `#ff69b4`, order: 10 }, // Produce
  // { id: "101000", name: "Cocina", colour: `#98fb98`, order: 10 }, // Kitchen
  { id: "101100", name: "Carne y Aves", colour: `#fff0f5`, order: 10 }, // Meat and Poultry
  { id: "101200", name: "Medicamentos", colour: `#dda0dd`, order: 10 }, // Medicine
  // { id: "101300", name: "Misceláneos", colour: `#7cfc00`, order: 10 }, // Misc
  // { id: "101400", name: "Oficina", colour: `#48d1cc`, order: 10 }, // Office
  { id: "101500", name: "Despensa", colour: `#add8e6`, order: 10 }, // Pantry
  //{ id: "101600", name: "Personal", colour: `#b0c4de`, order: 10 }, // Personal
  {
    id: "101700",
    name: "Suministros para Mascotas",
    colour: `#ffe4e1`,
    order: 10,
  }, // Pet Supplies
  { id: "101800", name: "Mariscos", colour: `#fa8072`, order: 10 }, // Seafood
  { id: "101900", name: "Snacks", colour: `#fffacd`, order: 10 }, // Snacks
  { id: "102000", name: "Especias", colour: `#21b2ba`, order: 10 }, // Spices
  { id: "102500", name: "Hierbas", colour: `#20b2aa`, order: 10 }, // Herbs
  // { id: "102100", name: "Viaje", colour: `#87ceeb`, order: 10 }, // Travel
  //  { id: "102200", name: "Tareas", colour: `#00ff7f`, order: 10 }, // Errands
];

export const commonGroceryCategoriesDE = [
  { id: "100100", name: "Baby", colour: `#f4a460`, order: 10 }, // Baby
  { id: "100200", name: "Backwaren", colour: `#e6e6fa`, order: 10 }, // Baked Goods
  { id: "100300", name: "Backen", colour: `#9370db`, order: 10 }, // Baking
  { id: "100400", name: "Getränke", colour: `#d3d3d3`, order: 10 }, // Beverages
  { id: "100500", name: "Reinigung", colour: `#66cdaa`, order: 10 }, // Cleaning
  { id: "100600", name: "Soßen", colour: "#f0e68c", order: 10 }, // Sauces
  { id: "100700", name: "Milchprodukte", colour: `#fffff0`, order: 10 }, // Dairy
  { id: "100800", name: "Tiefkühlkost", colour: `#e0ffff`, order: 10 }, // Frozen
  { id: "100900", name: "Obst und Gemüse", colour: `#ff69b4`, order: 10 }, // Produce
  // { id: "101000", name: "Küche", colour: `#98fb98`, order: 10 }, // Kitchen
  { id: "101100", name: "Fleisch und Geflügel", colour: `#fff0f5`, order: 10 }, // Meat and Poultry
  { id: "101200", name: "Medizin", colour: `#dda0dd`, order: 10 }, // Medicine
  // { id: "101300", name: "Verschiedenes", colour: `#7cfc00`, order: 10 }, // Misc
  // { id: "101400", name: "Büro", colour: `#48d1cc`, order: 10 }, // Office
  { id: "101500", name: "Speisekammer", colour: `#add8e6`, order: 10 }, // Pantry
  //{ id: "101600", name: "Persönlich", colour: `#b0c4de`, order: 10 }, // Personal
  { id: "101700", name: "Tierbedarf", colour: `#ffe4e1`, order: 10 }, // Pet Supplies
  { id: "101800", name: "Meeresfrüchte", colour: `#fa8072`, order: 10 }, // Seafood
  { id: "101900", name: "Snacks", colour: `#fffacd`, order: 10 }, // Snacks
  { id: "102000", name: "Gewürze", colour: `#21b2ba`, order: 10 }, // Spices
  { id: "102500", name: "Kräuter", colour: `#20b2aa`, order: 10 }, // Herbs
  // { id: "102100", name: "Reisen", colour: `#87ceeb`, order: 10 }, // Travel
  //  { id: "102200", name: "Besorgungen", colour: `#00ff7f`, order: 10 }, // Errands
];

export const commonGroceryCategoriesJP = [
  { id: "100100", name: "ベビー", colour: `#f4a460`, order: 10 }, // Baby
  { id: "100200", name: "ベーカリー", colour: `#e6e6fa`, order: 10 }, // Baked Goods
  { id: "100300", name: "ベーキング", colour: `#9370db`, order: 10 }, // Baking
  { id: "100400", name: "飲料", colour: `#d3d3d3`, order: 10 }, // Beverages
  { id: "100500", name: "クリーニング", colour: `#66cdaa`, order: 10 }, // Cleaning
  { id: "100600", name: "ソース", colour: "#f0e68c", order: 10 }, // Sauces
  { id: "100700", name: "乳製品", colour: `#fffff0`, order: 10 }, // Dairy
  { id: "100800", name: "冷凍食品", colour: `#e0ffff`, order: 10 }, // Frozen
  { id: "100900", name: "生鮮食品", colour: `#ff69b4`, order: 10 }, // Produce
  // { id: "101000", name: "キッチン", colour: `#98fb98`, order: 10 }, // Kitchen
  { id: "101100", name: "肉と鶏肉", colour: `#fff0f5`, order: 10 }, // Meat and Poultry
  { id: "101200", name: "薬", colour: `#dda0dd`, order: 10 }, // Medicine
  // { id: "101300", name: "その他", colour: `#7cfc00`, order: 10 }, // Misc
  // { id: "101400", name: "オフィス", colour: `#48d1cc`, order: 10 }, // Office
  { id: "101500", name: "パントリー", colour: `#add8e6`, order: 10 }, // Pantry
  //{ id: "101600", name: "個人用品", colour: `#b0c4de`, order: 10 }, // Personal
  { id: "101700", name: "ペット用品", colour: `#ffe4e1`, order: 10 }, // Pet Supplies
  { id: "101800", name: "シーフード", colour: `#fa8072`, order: 10 }, // Seafood
  { id: "101900", name: "スナック", colour: `#fffacd`, order: 10 }, // Snacks
  { id: "102000", name: "スパイス", colour: `#21b2ba`, order: 10 }, // Spices
  { id: "102500", name: "ハーブ", colour: `#20b2aa`, order: 10 }, // Herbs
  // { id: "102100", name: "旅行", colour: `#87ceeb`, order: 10 }, // Travel
  //  { id: "102200", name: "用事", colour: `#00ff7f`, order: 10 }, // Errands
];

export const commonGroceryCategoriesKR = [
  { id: "100100", name: "베이비", colour: `#f4a460`, order: 10 }, // Baby
  { id: "100200", name: "베이킹 재료", colour: `#e6e6fa`, order: 10 }, // Baked Goods
  { id: "100300", name: "베이킹", colour: `#9370db`, order: 10 }, // Baking
  { id: "100400", name: "음료", colour: `#d3d3d3`, order: 10 }, // Beverages
  { id: "100500", name: "청소", colour: `#66cdaa`, order: 10 }, // Cleaning
  { id: "100600", name: "소스", colour: "#f0e68c", order: 10 }, // Sauces
  { id: "100700", name: "유제품", colour: `#fffff0`, order: 10 }, // Dairy
  { id: "100800", name: "냉동식품", colour: `#e0ffff`, order: 10 }, // Frozen
  { id: "100900", name: "생산품", colour: `#ff69b4`, order: 10 }, // Produce
  // { id: "101000", name: "주방", colour: `#98fb98`, order: 10 }, // Kitchen
  { id: "101100", name: "육류 및 가금류", colour: `#fff0f5`, order: 10 }, // Meat and Poultry
  { id: "101200", name: "의약품", colour: `#dda0dd`, order: 10 }, // Medicine
  // { id: "101300", name: "기타", colour: `#7cfc00`, order: 10 }, // Misc
  // { id: "101400", name: "사무용품", colour: `#48d1cc`, order: 10 }, // Office
  { id: "101500", name: "식품 저장실", colour: `#add8e6`, order: 10 }, // Pantry
  //{ id: "101600", name: "개인용품", colour: `#b0c4de`, order: 10 }, // Personal
  { id: "101700", name: "애완동물 용품", colour: `#ffe4e1`, order: 10 }, // Pet Supplies
  { id: "101800", name: "해산물", colour: `#fa8072`, order: 10 }, // Seafood
  { id: "101900", name: "과자", colour: `#fffacd`, order: 10 }, // Snacks
  { id: "102000", name: "향신료", colour: `#21b2ba`, order: 10 }, // Spices
  { id: "102500", name: "허브", colour: `#20b2aa`, order: 10 }, // Herbs
  // { id: "102100", name: "여행", colour: `#87ceeb`, order: 10 }, // Travel
  //  { id: "102200", name: "용무", colour: `#00ff7f`, order: 10 }, // Errands
];

export const commonGroceryCategoriesCN = [
  { id: "100100", name: "婴儿", colour: `#f4a460`, order: 10 }, // Baby
  { id: "100200", name: "烘培食品", colour: `#e6e6fa`, order: 10 }, // Baked Goods
  { id: "100300", name: "烘焙材料", colour: `#9370db`, order: 10 }, // Baking
  { id: "100400", name: "饮料", colour: `#d3d3d3`, order: 10 }, // Beverages
  { id: "100500", name: "清洁用品", colour: `#66cdaa`, order: 10 }, // Cleaning
  { id: "100600", name: "酱料", colour: "#f0e68c", order: 10 }, // Sauces
  { id: "100700", name: "乳制品", colour: `#fffff0`, order: 10 }, // Dairy
  { id: "100800", name: "冷冻食品", colour: `#e0ffff`, order: 10 }, // Frozen
  { id: "100900", name: "新鲜食品", colour: `#ff69b4`, order: 10 }, // Produce
  // { id: "101000", name: "厨房", colour: `#98fb98`, order: 10 }, // Kitchen
  { id: "101100", name: "肉类和家禽", colour: `#fff0f5`, order: 10 }, // Meat and Poultry
  { id: "101200", name: "药品", colour: `#dda0dd`, order: 10 }, // Medicine
  // { id: "101300", name: "其他", colour: `#7cfc00`, order: 10 }, // Misc
  // { id: "101400", name: "办公室", colour: `#48d1cc`, order: 10 }, // Office
  { id: "101500", name: "储藏室", colour: `#add8e6`, order: 10 }, // Pantry
  //{ id: "101600", name: "个人用品", colour: `#b0c4de`, order: 10 }, // Personal
  { id: "101700", name: "宠物用品", colour: `#ffe4e1`, order: 10 }, // Pet Supplies
  { id: "101800", name: "海鲜", colour: `#fa8072`, order: 10 }, // Seafood
  { id: "101900", name: "零食", colour: `#fffacd`, order: 10 }, // Snacks
  { id: "102000", name: "香料", colour: `#21b2ba`, order: 10 }, // Spices
  { id: "102500", name: "草药", colour: `#20b2aa`, order: 10 }, // Herbs
  // { id: "102100", name: "旅行", colour: `#87ceeb`, order: 10 }, // Travel
  //  { id: "102200", name: "任务", colour: `#00ff7f`, order: 10 }, // Errands
];

export const commonGroceryCategoriesAR = [
  { id: "100100", name: "Bébé", couleur: `#f4a460`, ordre: 10 },
  { id: "100200", name: "Pâtisseries", couleur: `#e6e6fa`, ordre: 10 },
  { id: "100300", name: "Pâtisserie", couleur: `#9370db`, ordre: 10 },
  { id: "100400", name: "Boissons", couleur: `#d3d3d3`, ordre: 10 },
  { id: "100500", name: "Nettoyage", couleur: `#66cdaa`, ordre: 10 },
  { id: "100600", name: "Sauces", couleur: "#f0e68c", ordre: 10 },
  { id: "100700", name: "Produits laitiers", couleur: `#fffff0`, ordre: 10 },
  { id: "100800", name: "Surgelés", couleur: `#e0ffff`, ordre: 10 },
  { id: "100900", name: "Produits frais", couleur: `#ff69b4`, ordre: 10 },
  // { id: "101000", name: "Cuisine", couleur: `#98fb98`, ordre: 10 },
  { id: "101100", name: "Viandes et volailles", couleur: `#fff0f5`, ordre: 10 },
  { id: "101200", name: "Médicaments", couleur: `#dda0dd`, ordre: 10 },
  // { id: "101300", name: "Divers", couleur: `#7cfc00`, ordre: 10 },
  // { id: "101400", name: "Bureau", couleur: `#48d1cc`, ordre: 10 },
  { id: "101500", name: "Garde-manger", couleur: `#add8e6`, ordre: 10 },
  //{ id: "101600", name: "Personnel", couleur: `#b0c4de`, ordre: 10 },
  {
    id: "101700",
    name: "Fournitures pour animaux",
    couleur: `#ffe4e1`,
    ordre: 10,
  },
  { id: "101800", name: "Fruits de mer", couleur: `#fa8072`, ordre: 10 },
  { id: "101900", name: "Collations", couleur: `#fffacd`, ordre: 10 },
  { id: "102000", name: "Épices", couleur: `#21b2ba`, ordre: 10 },
  { id: "102500", name: "Herbes", couleur: `#20b2aa`, ordre: 10 },
  // { id: "102100", name: "Voyages", couleur: `#87ceeb`, ordre: 10 },
  //  { id: "102200", name: "Courses", couleur: `#00ff7f`, ordre: 10 },
];

export const commonGroceryCategoriesSE = [
  { id: "100100", name: "Barn", colour: `#f4a460`, order: 10 },
  { id: "100200", name: "Bagerivaror", colour: `#e6e6fa`, order: 10 },
  { id: "100300", name: "Bakning", colour: `#9370db`, order: 10 },
  { id: "100400", name: "Drycker", colour: `#d3d3d3`, order: 10 },
  { id: "100500", name: "Städning", colour: `#66cdaa`, order: 10 },
  { id: "100600", name: "Såser", colour: "#f0e68c", order: 10 },
  { id: "100700", name: "Mejeri", colour: `#fffff0`, order: 10 },
  { id: "100800", name: "Frusna varor", colour: `#e0ffff`, order: 10 },
  { id: "100900", name: "Produkter", colour: `#ff69b4`, order: 10 },
  // { id: "101000", name: "Kök", colour: `#98fb98`, order: 10 },
  { id: "101100", name: "Kött och fågel", colour: `#fff0f5`, order: 10 },
  { id: "101200", name: "Medicin", colour: `#dda0dd`, order: 10 },
  // { id: "101300", name: "Diverse", colour: `#7cfc00`, order: 10 },
  // { id: "101400", name: "Kontor", colour: `#48d1cc`, order: 10 },
  { id: "101500", name: "Skafferi", colour: `#add8e6`, order: 10 },
  //{ id: "101600", name: "Personliga", colour: `#b0c4de`, order: 10 },
  { id: "101700", name: "Djurförnödenheter", colour: `#ffe4e1`, order: 10 },
  { id: "101800", name: "Skaldjur", colour: `#fa8072`, order: 10 },
  { id: "101900", name: "Snacks", colour: `#fffacd`, order: 10 },
  { id: "102000", name: "Kryddor", colour: `#21b2ba`, order: 10 },
  { id: "102500", name: "Örter", colour: `#20b2aa`, order: 10 },
  // { id: "102100", name: "Resor", colour: `#87ceeb`, order: 10 },
  //  { id: "102200", name: "Ärenden", colour: `#00ff7f`, order: 10 },
];

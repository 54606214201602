import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaintRoller,
  faTrash,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import classes from "./reminder.module.css";
import toast, { Toaster } from "react-hot-toast";
import { useReminderSubscription } from "./useReminderSubscription";
import moment from "moment";
import logoimage from "../Assets/logo192.png";
import { Reminder } from "./Reminder.js";

export const ReminderHistory = () => {
  const { reminders } = useReminderSubscription();
  const [isNative, setIsNative] = useState(true);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (window.isNative) {
      setIsNative(true);
    } else {
      setIsNative(false);
    }

    return () => {};
  }, []);

  return (
    <section className={classes.section}>
      <h2
        style={{
          padding: 10,
          fontWeight: "bold",
          textAlign: "center",
          marginTop: -15,
        }}
      >
        Reminder History
      </h2>
      <hr />

      {reminders.length >= 1 ? (
        <div className={classes.reminderList} style={{ paddingBottom: 10 }}>
          {reminders.map((reminder) => (
            <Reminder
              key={reminder.messageID}
              id={reminder.id}
              messageID={reminder.messageID}
              title={reminder.title}
              body={reminder.body}
              created={reminder.dateCreated}
              scheduled={reminder.reminderOnDate}
              isDaily={reminder.isDaily}
              atTime={reminder.atTime}
              icsURL={reminder.icsURL}
            />
          ))}
        </div>
      ) : (
        <p style={{ textAlign: "center", width: "100%" }}>
          No custom messages found in your history
        </p>
      )}
    </section>
  );
};

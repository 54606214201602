import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";

export const LanguageSwitcher = () => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const getlanguage = localStorage.getItem("userLanguage");
  const [isDisclaimer, setIsDisclaimer] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    getlanguage != undefined ? getlanguage : "en"
  );

  const checkStoredLanguage = () => {
    var setLanguage = getlanguage != undefined ? getlanguage : "en";
    changeLanguage(setLanguage);
  };

  //console.log("what is getisHabits", getIsHabitsPref);

  useEffect(() => {
    checkStoredLanguage();

    return () => {};
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
    localStorage.setItem("userLanguage", lng);
    //save to localstorage... update on useEffect... add when you get back.
  };

  return (
    <div>
      <div
        className="actions_link_default"
        style={{
          fontSize: 14,
          display: "flex",
          flexWrap: "wrap",
          padding: 10,
          alignItems: location.pathname != "/" ? "left" : "center",
          justifyContent: location.pathname != "/" ? "left" : "center",
        }}
      >
        <span
          style={{
            padding: 10,
            backgroundColor:
              selectedLanguage === "en" ? "yellow" : "transparent",
          }}
          onClick={() => {
            changeLanguage("en");
            setIsDisclaimer(false);
          }}
        >
          English
        </span>
        <span
          style={{
            padding: 10,
            backgroundColor:
              selectedLanguage === "cn" ? "yellow" : "transparent",
          }}
          onClick={() => {
            changeLanguage("cn");
            setIsDisclaimer(true);
          }}
        >
          中国人
        </span>
        <span
          style={{
            padding: 10,
            backgroundColor:
              selectedLanguage === "de" ? "yellow" : "transparent",
          }}
          onClick={() => {
            changeLanguage("de");
            setIsDisclaimer(true);
          }}
        >
          Deutsch
        </span>
        <span
          style={{
            padding: 10,
            backgroundColor:
              selectedLanguage === "es" ? "yellow" : "transparent",
          }}
          onClick={() => {
            changeLanguage("es");
            setIsDisclaimer(true);
          }}
        >
          Español
        </span>
        <span
          style={{
            padding: 10,
            backgroundColor:
              selectedLanguage === "fr" ? "yellow" : "transparent",
          }}
          onClick={() => {
            changeLanguage("fr");
            setIsDisclaimer(true);
          }}
        >
          Français
        </span>
        <span
          style={{
            padding: 10,
            backgroundColor:
              selectedLanguage === "se" ? "yellow" : "transparent",
          }}
          onClick={() => {
            changeLanguage("se");
            setIsDisclaimer(true);
          }}
        >
          Svenska
        </span>
        <span
          style={{
            padding: 10,
            backgroundColor:
              selectedLanguage === "jp" ? "yellow" : "transparent",
          }}
          onClick={() => {
            changeLanguage("jp");
            setIsDisclaimer(true);
          }}
        >
          日本語
        </span>
        <span
          style={{
            padding: 10,
            backgroundColor:
              selectedLanguage === "kr" ? "yellow" : "transparent",
          }}
          onClick={() => {
            changeLanguage("kr");
            setIsDisclaimer(true);
          }}
        >
          한국인
        </span>
        <span
          style={{
            padding: 10,
            backgroundColor:
              selectedLanguage === "ar" ? "yellow" : "transparent",
          }}
          onClick={() => {
            changeLanguage("ar");
            setIsDisclaimer(true);
          }}
        >
          عربي
        </span>{" "}
        {!isDisclaimer ? (
          <FontAwesomeIcon
            style={{ marginTop: 0 }}
            icon={faInfoCircle}
            size="lg"
            onClick={() => setIsDisclaimer(true)}
          />
        ) : null}
      </div>
      <div>
        {isDisclaimer ? (
          <p
            onClick={() => setIsDisclaimer(false)}
            style={{
              fontSize: 14,
              textAlign: location.pathname != "/" ? "left" : "center",
              paddingLeft: 20,
              paddingRight: 20,
              marginTop: -5,
            }}
          >
            {t("settings-language-warning")}
          </p>
        ) : null}
        {/* Add buttons for other languages don't forget to update the recipe creator */}
      </div>
    </div>
  );
};

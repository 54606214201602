//import firebase from "firebase/app";
//import firebase from "firebase/compat/app";

import {
  getFirestore,
  doc,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  Timestamp,
  writeBatch,
} from "firebase/firestore";
import { singular } from "pluralize";

import { useUserContext } from "Authentication";
import { DatabaseItem } from "./models";
import toast, { Toaster } from "react-hot-toast";
import { db } from "Config";
import { Item } from "./List/Common/Item";
import { userInfo } from "os";
import { commonGroceryItemsUS } from "Register/CreateGroupForm/data/common_grocery_items_en";
import { commonGroceryItemsAsia } from "Register/CreateGroupForm/data/common_grocery_items_cn";
import { commonGroceryItemsFrance } from "Register/CreateGroupForm/data/common_grocery_items_fr";
import { commonGroceryItemsGermany } from "Register/CreateGroupForm/data/common_grocery_items_de";
import { commonGroceryItemsJapan } from "Register/CreateGroupForm/data/common_grocery_items_jp";
import { commonGroceryItemsKorea } from "Register/CreateGroupForm/data/common_grocery_items_kr";
import { commonGroceryItemsMiddleEast } from "Register/CreateGroupForm/data/common_grocery_items_ar";
import { commonGroceryItemsSpainSouthAmerica } from "Register/CreateGroupForm/data/common_grocery_items_es";
import { commonGroceryItemsSE } from "Register/CreateGroupForm/data/common_grocery_items_se";
import {
  commonGroceryCategories,
  commonGroceryCategoriesFR,
  commonGroceryCategoriesDE,
  commonGroceryCategoriesES,
  commonGroceryCategoriesJP,
  commonGroceryCategoriesKR,
  commonGroceryCategoriesCN,
  commonGroceryCategoriesAR,
  commonGroceryCategoriesSE,
} from "Register/CreateGroupForm/data/commonCategories";

export function useItems() {
  const { getDefaultItemsCollection, getDefaultItemsCategoryCollection } =
    useUserContext();
  const collection = getDefaultItemsCollection();
  const collectionCategory = getDefaultItemsCategoryCollection();
  const user = useUserContext();

  async function addItem(value: string, quantity?: string, category?: string) {
    const name = value.trim();
    const lowerName = singular(name.toLowerCase());

    const q = query(collection, where("lowerName", "==", lowerName));
    const existing = await getDocs(q);

    const fieldsToUpdate = {
      name,
      added: true,
      completed: false,
      addedDate: Timestamp.fromDate(new Date()),
      addedBy: user.user.name,
      quantity: quantity,
      category:
        category === undefined
          ? existing.docs?.[0]?.data().category || ""
          : category,
    };

    if (existing.empty) {
      await addDoc(collection, { ...fieldsToUpdate, lowerName, count: 1 });
    } else {
      const docId = existing.docs[0].id;
      const currentCount = existing.docs[0].data().count ?? 0;
      const docRef = doc(collection, docId);
      await updateDoc(docRef, {
        ...fieldsToUpdate,
        count: currentCount + 1,
      });
    }
  }

  function deleteItem(id: string) {
    return deleteDoc(doc(collection, id));
  }

  function removeItem(id: string) {
    const deletionFields = {
      // Define your deletion fields as needed
      completed: false,
      added: false,
      lastCompleted: new Date().toString(),
      //addedDate: firebase.firestore.FieldValue.delete(),
      //notes: firebase.firestore.FieldValue.delete(),
      flagged: false,
    };

    return updateDoc(doc(collection, id), deletionFields);
  }

  function updateItem(id: string, item: Partial<DatabaseItem>) {
    return updateDoc(doc(collection, id), item);
  }

  async function addCommonGroceryItems() {
    const batch = writeBatch(db);

    const addToBatch = (items) => {
      items.forEach((item) => {
        const name = item.name.trim();
        const lowerName = singular(name.toLowerCase());

        const fieldsToUpdate = {
          name,
          added: false,
          completed: false,
          addedDate: Timestamp.fromDate(new Date()),
          category: item.category,
        };

        const itemRef = doc(collection);
        batch.set(itemRef, { ...fieldsToUpdate, lowerName, count: 1 });
      });
    };

    const whichRecipeVersion = () => {
      const getlanguage = localStorage.getItem("userLanguage");
      var language = getlanguage != undefined ? getlanguage : "en";

      if (language == "en") {
        return commonGroceryItemsUS;
      } else if (language == "cn") {
        return commonGroceryItemsAsia;
      } else if (language == "de") {
        return commonGroceryItemsGermany;
      } else if (language == "fr") {
        return commonGroceryItemsFrance;
      } else if (language == "es") {
        return commonGroceryItemsSpainSouthAmerica;
      } else if (language == "jp") {
        return commonGroceryItemsJapan;
      } else if (language == "kr") {
        return commonGroceryItemsKorea;
      } else if (language == "ar") {
        return commonGroceryItemsMiddleEast;
      } else if (language == "se") {
        return commonGroceryItemsSE;
      } else {
        return commonGroceryItemsUS;
      }
    };

    addToBatch(whichRecipeVersion());
    //TODO might need to split
    //  addToBatch(commonGroceryItems2);

    try {
      await batch.commit();
      toast("All Items Added", {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "lightgreen",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
    } catch (error) {
      alert("Unable to connect. Please check your internet settings.");
      // console.log("Error committing batch:", error);
    }
  }

  async function deleteAllGroceryItems() {
    try {
      // Query the collection to get all items
      const querySnapshot = await getDocs(collection);

      // Process items in chunks
      const batchSize = 450;
      let currentBatchSize = 0;

      // Function to process a batch of delete operations
      const processBatch = async (batch) => {
        // console.log("Delete Batch started");
        await batch.commit();
        //console.log("Delete Batch completed");
      };

      // Iterate over each item and add delete operations to the batch
      for (const doc of querySnapshot.docs) {
        if (!doc.exists) continue;

        // Create a batch if it doesn't exist
        if (!deleteBatch) {
          var deleteBatch = writeBatch(db);
        }

        deleteBatch.delete(doc.ref);
        currentBatchSize++;

        // Commit the batch once the chunk size limit is reached
        if (currentBatchSize === batchSize) {
          currentBatchSize = 0;
          await processBatch(deleteBatch);
          deleteBatch = null; // Reset the batch
        }
      }

      // Commit the remaining batch if there are any remaining delete operations
      if (deleteBatch && currentBatchSize > 0) {
        await processBatch(deleteBatch);
      }

      toast("All Items Removed", {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "lightgreen",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });

      // console.log("Batch delete successful");
    } catch (error) {
      console.error("Error performing batch delete", error);
    }
  }

  async function AddCommonCategories() {
    const whichRecipeVersion = () => {
      const getlanguage = localStorage.getItem("userLanguage");
      var language = getlanguage != undefined ? getlanguage : "en";

      if (language == "en") {
        return commonGroceryCategories;
      } else if (language == "cn") {
        return commonGroceryCategoriesCN;
      } else if (language == "de") {
        return commonGroceryCategoriesDE;
      } else if (language == "fr") {
        return commonGroceryCategoriesFR;
      } else if (language == "es") {
        return commonGroceryCategoriesES;
      } else if (language == "jp") {
        return commonGroceryCategoriesJP;
      } else if (language == "kr") {
        return commonGroceryCategoriesKR;
      } else if (language == "ar") {
        return commonGroceryCategoriesAR;
      } else if (language == "se") {
        return commonGroceryCategoriesSE;
      } else {
        return commonGroceryCategories;
      }
    };

    const batchCategory = writeBatch(db);

    whichRecipeVersion().forEach((category) => {
      const categoryRef = doc(collectionCategory, category.id);
      batchCategory.set(categoryRef, category);
      //  console.log("category", category.id, category.name)
    });

    try {
      await batchCategory.commit();
      toast("Common categories added", {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "lightgreen",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
    } catch (error) {
      alert("Unable to connect. Please check your internet settings.");
    }
  }

  async function deleteAllGroceryCategories() {
    try {
      // Query the collection to get all items
      const querySnapshot = await getDocs(collectionCategory);

      // Create a batch to store delete operations
      const deleteBatch = writeBatch(db);

      // Process items in chunks
      const batchSize = 450;
      let currentBatchSize = 0;

      // Iterate over each item and add delete operations to the batch
      querySnapshot.forEach((doc) => {
        deleteBatch.delete(doc.ref);
        currentBatchSize++;

        // Commit the batch once the chunk size limit is reached
        if (currentBatchSize === batchSize) {
          currentBatchSize = 0;
          deleteBatch.commit();
        }
      });

      // Commit the remaining batch if there are any remaining delete operations
      if (currentBatchSize > 0) {
        deleteBatch.commit();
      }

      toast("All Categories Removed", {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "lightgreen",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
      //  console.log("Batch delete successful");
    } catch (error) {
      console.error("Error performing batch delete", error);
    }
  }

  function batchRemoveItems(ids: string[]) {
    const batch = writeBatch(db);

    const deletionFields = {
      // Define your deletion fields as needed
      completed: false,
      added: false,
      lastCompleted: new Date().toString(),
      //addedDate: firebase.firestore.FieldValue.delete(),
      //notes: firebase.firestore.FieldValue.delete(),
      flagged: false,
    };

    for (const id of ids) {
      // console.log("what is the ID", id);
      const ref = doc(collection, id);
      batch.update(ref, deletionFields);
    }

    return batch.commit();
  }

  function setItemFlag(id: string, flagged: boolean) {
    return updateDoc(doc(collection, id), {
      flagged,
    });
  }

  function setItemFavorite(id: string, isFavorite: boolean) {
    return updateDoc(doc(collection, id), {
      isFavorite,
    });
  }

  return {
    addItem,
    addCommonGroceryItems,
    deleteAllGroceryItems,
    deleteAllGroceryCategories,
    AddCommonCategories,
    batchRemoveItems,
    deleteItem,
    removeItem,
    setItemFlag,
    setItemFavorite,
    updateItem,
  };
}

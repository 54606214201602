import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaintRoller,
  faTrash,
  faClose,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import { useUserContext } from "Authentication";
import TimePicker from "react-time-picker";
//import DateTimePicker from 'react-datetime-picker';
import DatePicker from "react-date-picker";
import notificationimage from "../Assets/customremindersample.jpg";
import classes from "./reminder.module.css";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
//import { useReminderSubscription } from './useReminderSubscription';
import { storage } from "Config";
import { google, outlook, office365, yahoo, ics } from "calendar-link";
import { Button, Modal } from "Common";

export const CustomNotificationMyList = ({
  title,
  item,
  CloseMenu,
  setDate,
}) => {
  //new reminder start.  This is a work in progree, I need to update the APP for remove habit language first.

  //const { reminders } = useReminderSubscription()

  //console.log("reminder list", reminders)

  const [isSchedulingReminder, setIsSchedulingReminder] = useState(false);
  const [reminderTitle, setReminderTitle] = useState(title + " Reminder");
  const [reminderBody, setReminderBody] = useState("");
  const [startTime, setStartTime] = useState(new Date());
  const [valueTime, setValueTime] = useState("12:00");
  const [valueTimeDue, setValueTimeDue] = useState("17:00");
  const [reminderDays, setReminderDays] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const [repeatReminder, setRepeatReminder] = useState(false);
  const [isNative, setIsNative] = useState(true);
  const [isDaily, setIsDaily] = useState(false);
  const { addNewReminder, ToDoDueDateUpdate } = useUserContext();
  //const [icsURL, seticsURL] = useState("");
  const user = useUserContext();
  const [reminderDoneModal, setReminderDoneModal] = useState(false);

  const Reminder = useRef();
  const icsURL = useRef("");
  var rmessageID = Math.floor(Math.random() * 10000000);

  const [isAlohaPlanner, setIsAlohaPlanner] = useState(false);
  const [isDopeNotesApp, setIsDopeNotesApp] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  const history = useHistory();
  const routeChange = (path) => {
    history.push(path);
  };

  const changeReminderDays = (e) => {
    setReminderDays(e);
  };

  //New Reminder options
  const [reminderOption, setReminderOption] = useState("");
  const reminderOptions = [
    { label: "15 minutes", value: 15 },
    { label: "30 minutes", value: 30 },
    { label: "45 minutes", value: 45 },
    { label: "1 hour", value: 60 },
    { label: "3 hours", value: 180 },
    { label: "6 hours", value: 360 },
    { label: "1 day", value: 1440 },
    { label: "3 days", value: 4320 },
  ];

  const handleDatePickerChange = (date) => {
    // Check if the selected date is in the future
    if (date > new Date()) {
      setDueDate(date);
    } else {
      setDueDate(new Date());
    }
  };

  const handleTimePickerChange = (time) => {
    setValueTimeDue(time);
  };

  const notificationScheduler = async (e) => {
    e.preventDefault(e);

    //set New Planner Item to ReminderTitle

    const selectedOption = reminderOptions.find(
      (option) => option.value === parseInt(reminderOption)
    );

    // Use the selected option value for setting the reminder
    const reminderTimeInMinutes = selectedOption ? selectedOption.value : 0;
    const reminderTimeInMillis = reminderTimeInMinutes * 60 * 1000;

    if (valueTimeDue != null) {
      rmessageID = Math.floor(Math.random() * 100000);
      var today = new Date();
      var scheduleDate = reminderDays;
      const timeParts = valueTimeDue.split(":");
      const hoursDue = Number(timeParts[0]);
      const minutesDue = Number(timeParts[1]);

      //calculate new options for reminder

      dueDate.setHours(hoursDue);
      dueDate.setMinutes(minutesDue);
      dueDate.setSeconds(0);

      const reminderDate = new Date(dueDate.getTime() - reminderTimeInMillis);

      // console.log("reminderDate", reminderDate);

      scheduleDate = reminderDate;
      setReminderDays(reminderDate);

      //get time only from new reminder option selected

      const currentReminderDate = reminderDate;
      const currentReminderHours = String(
        currentReminderDate.getHours()
      ).padStart(2, "0");
      const currentReminderMinutes = String(
        currentReminderDate.getMinutes()
      ).padStart(2, "0");
      const currentReminderTime = `${currentReminderHours}:${currentReminderMinutes}:00`;

      //  console.log("reminderTimeonly", currentReminderTime);
      setValueTime(currentReminderTime);

      rmessageID = rmessageID.toString() + "dnotes";
      let seconds = Math.abs(reminderDate.getTime() - today.getTime()) / 1000;

      if (window.isNative) {
        //Convert the difference between today and a fture date

        //var d = new Date();
        //@ts-ignore

        Reminder.current = {
          title: reminderTitle,
          description: reminderBody,
          start: moment(scheduleDate).format("YYYY-MM-DD") + valueTimeDue,
          duration: [1, "hour"],
        };
        try {
          // await generateICAL(scheduleDate, false);
        } catch (error) {}

        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "NoficationSelectedCustom",
            name: reminderTitle,
            body: reminderBody,
            days: 1,
            seconds: Math.round(seconds),
            id: rmessageID,
            repeats: false,
            isAdded: "create",
          })
        );

        addNewReminder(
          today,
          rmessageID,
          reminderTitle,
          reminderBody,
          dueDate,
          valueTimeDue,
          isDaily,
          icsURL.current,
          "none",
          "day",
          1
        );
      } else {
        // browser reminder creates ICS only

        Reminder.current = {
          title: reminderTitle,
          description: reminderBody,
          start: moment(scheduleDate).format("YYYY-MM-DD") + valueTimeDue,
          duration: [1, "hour"],
        };
        try {
          // await generateICAL(scheduleDate, false);
        } catch (error) {}

        setTimeout(() => {
          addNewReminder(
            today,
            rmessageID,
            reminderTitle,
            reminderBody,
            dueDate,
            valueTimeDue,
            isDaily,
            icsURL.current,
            "none",
            "day",
            1
          );
        }, 1500);
      }
    }
    // console.log("item id", item.id);

    ToDoDueDateUpdate(item.id, dueDate, valueTimeDue);

    setReminderDoneModal(true);
  };

  const notificationCancel = (e) => {
    if (window.isNative) {
      // Post the message back to expo
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "NoficationSelectedCustom",
          id: rmessageID,
          isAdded: "remove",
        })
      );
      setIsSchedulingReminder(false);
      //alert(data.name, data.days, valueTime)
    } else {
      // Your old login method
      //  console.log("clicked")
    }
  };

  ///new reminder end

  //button for the end modal

  const close = () => {
    setReminderDoneModal(false);
    setIsSchedulingReminder(false);
    setReminderTitle("");
    setReminderBody("");
    var current = new Date();
    const time = current.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    setValueTime(time);
    setRepeatReminder(false);
    setReminderDays(new Date());
    setValueTimeDue("17:00");
    setDueDate(new Date());
    CloseMenu();
  };

  const addEventDeviceCalendar = () => {
    const durationTimeInMinutes = 15;
    const durationTimeInMillis = durationTimeInMinutes * 60 * 1000;
    const endDate = new Date(dueDate.getTime() + durationTimeInMillis);

    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "addEventDeviceCalendar",
        titleName: reminderTitle,
        notes: "",
        startDate: moment(dueDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        endDate: moment(endDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        allDay: false,
        interval: 1,
        weekPositionInMonth: 0,
        dateForMonthlyYearly: 0,
        repeatValue: "none",
        monthlyType: "day",
        days: [0],
      })
    );
  };

  const GoogleCalendar = () => {
    //@ts-ignore
    window.open(google(Reminder.current));
  };

  const ICSCalendar = () => {
    //@ts-ignore
    window.open(ics(Reminder.current));
  };

  useEffect(() => {
    var current = new Date();
    const time = current.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    // console.log("what is setDate", setDate);
    if (setDate != undefined) {
      const convertDate = new Date(setDate.seconds * 1000);
      setDueDate(convertDate);
      var tempHours = convertDate.getHours();
      var tempMinutes = convertDate.getMinutes().toString().padStart(2, "0");

      var tempTime = tempHours + ":" + tempMinutes;
      setValueTimeDue(tempTime);
      // console.log("what is time", tempTime);
    } else {
      setValueTime(time);
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
    if (window.isNative) {
      setIsNative(true);
    } else {
      setIsNative(false);
    }

    if (window.isAloha) {
      setIsAlohaPlanner(true);
    }

    if (window.isDopeNotesApp) {
      setIsDopeNotesApp(true);
    }

    if (window.isIOS) {
      setIsIOS(true);
    }

    return () => {};
  }, []);

  return (
    <section className={classes.section}>
      <h2
        style={{
          textAlign: "center",
          padding: 20,
          lineHeight: 1.2,
          userSelect: "none",
        }}
      >
        {" "}
        Set Task Due Date for {title}
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: 10,
        }}
      ></div>{" "}
      <hr />
      <form onSubmit={notificationScheduler}>
        <div
          style={{
            paddingBottom: 0,
            paddingTop: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <label style={{ paddingTop: 10, paddingBottom: 5 }}>Due Date </label>
          <DatePicker
            clearIcon={null}
            onChange={handleDatePickerChange}
            value={dueDate}
            minDate={new Date()}
          />
          <label style={{ paddingTop: 10, paddingBottom: 5 }}>Due Time</label>
          <TimePicker
            onChange={handleTimePickerChange}
            value={valueTimeDue}
            maxDetail="minute"
            disableClock
            clearIcon={null}
            className={classes.timepicker}
          />
        </div>
        <div
          style={{
            paddingBottom: 0,
            paddingTop: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          {isNative ? (
            <>
              {" "}
              <label style={{ paddingTop: 10, paddingBottom: 5 }}>
                Set Device Reminder
              </label>
              <select
                value={reminderOption}
                onChange={(e) => setReminderOption(e.target.value)}
                style={{ fontSize: 14, marginTop: 10, padding: 10 }}
              >
                {/*}  <option value={5}>5 Minutes</option>*/}
                {reminderOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>{" "}
            </>
          ) : null}
        </div>
        <div className={classes.modalActions}>
          {/*}  <button
            type="button"
            onClick={notificationCancel}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "tomato",
              cursor: "pointer",
            }}
            //   className={classes.secondaryButton}
          >
            <FontAwesomeIcon
              icon={faTrash}
              size="2x"
              style={{ fontSize: 20, paddingRight: 2 }}
            />
          </button> */}

          <button
            type="submit"
            style={{
              cursor: "pointer",
              fontFamily: "inherit",
              fontSize: "inherit",
              borderColor: "transparent",
            }}
            //   className={classes.secondaryButton}
          >
            Set Task Due Date
          </button>
        </div>
      </form>
      {/*} <div
        onClick={() => routeChange("/reminderhistory")}
        style={{
          cursor: "pointer",
          fontFamily: "inherit",
          fontSize: "inherit",
          textAlign: "center",
          color: "blue",
        }}
      >
        {" "}
        See Reminder History
    </div>*/}
      <Modal
        isOpen={reminderDoneModal}
        onRequestClose={close}
        style={{ overlay: { zIndex: 100 } }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "right",
            justifyContent: "right",
            margin: 10,
          }}
        >
          {" "}
          <button
            style={{
              backgroundColor: "transparent",
              borderStyle: "none",
              cursor: "pointer",
              marginTop: 10,
              marginRight: 10,
            }}
            onClick={close}
          >
            <FontAwesomeIcon icon={faClose} size="3x" />
          </button>
        </div>
        <div
          style={{
            paddingBottom: 150,
            paddingLeft: 20,
            paddingRight: 20,
            userSelect: "none",
          }}
        >
          <h2 style={{ lineHeight: 1.5 }}>
            <FontAwesomeIcon icon={faCalendarDay} size="sm" /> Due Date Set
          </h2>
          <p
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              lineHeight: 1.5,
            }}
          >
            Title: {reminderTitle}
          </p>

          {isDaily ? (
            <p
              style={{
                fontSize: "inherit",
                fontFamily: "inherit",
                lineHeight: 1.5,
              }}
            >
              Repeats Daily
            </p>
          ) : (
            <p
              style={{
                fontSize: "inherit",
                fontFamily: "inherit",
                lineHeight: 1.5,
              }}
            >
              Date: {moment(new Date(reminderDays)).format("MMMM DD, YYYY")}
            </p>
          )}
          <p
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              lineHeight: 1.5,
            }}
          >
            Time: {valueTimeDue}
          </p>
          <hr />
          <p
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              lineHeight: 1.5,
            }}
          >
            Would you like to add the reminder to a different calendar? Please
            select the calendar where you would like to add this reminder:
          </p>
          <div className="reminder-button-grid">
            {isAlohaPlanner || isDopeNotesApp ? (
              <button
                className="reminder-calendar-buttons"
                onClick={addEventDeviceCalendar}
              >
                {isIOS ? "iPhone " : "Android "}Calendar
              </button>
            ) : isNative && !isAlohaPlanner && !isDopeNotesApp ? (
              <div>
                <button
                  className="reminder-calendar-buttons"
                  disabled
                  style={{ opacity: 0.6 }}
                  // onClick={addEventDeviceCalendar}
                >
                  {isIOS ? "iPhone " : "Android "}Calendar
                </button>
                <br />
                <span
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    paddingLeft: 10,
                  }}
                >
                  Please update your app to enable!
                </span>
              </div>
            ) : null}

            <button
              className="reminder-calendar-buttons"
              onClick={GoogleCalendar}
            >
              Google Calendar
            </button>
            {!isNative ? (
              <button
                className="reminder-calendar-buttons"
                onClick={ICSCalendar}
              >
                ICS/ICAL
              </button>
            ) : null}
            <button
              style={{
                fontSize: "inherit",
                fontFamily: "inherit",
                color: "white",
                backgroundColor: "#1e79c3",
                borderColor: "white",
                padding: 10,
                borderRadius: 10,
                paddingRight: 30,
                paddingLeft: 30,
                width: 200,
              }}
              onClick={close}
            >
              Close
            </button>
          </div>
          <div style={{ paddingBottom: 150 }} />
        </div>
      </Modal>
    </section>
  );
};

export const classicRecipesSE = [
  {
    id: "S1wE8dR2gF",
    recipeUrl: "",
    name: "Svenska Köttbullar (Swedish Meatballs)",
    directions:
      "<ol><li>Blanda färsen: I en skål, blanda nötfärs, lök, mjölk, ströbröd, salt, och peppar.</li><li>Rulla köttbullar: Forma små köttbullar med händerna.</li><li>Stek köttbullarna: Stek dem i smör tills de är gyllenbruna och genomstekta.</li><li>Servera: Servera köttbullarna med gräddsås, lingonsylt och potatismos.</li></ol>",
    days: [],
    tags: [
      { text: "Huvudrätt", color: "indigo" },
      { text: "Svensk", color: "indigo" },
      { text: "Köttbullar", color: "indigo" },
    ],
    ingredients: [
      { name: "Nötfärs", measurement: "500g" },
      { name: "Lök (finhackad)", measurement: "1 st" },
      { name: "Mjölk", measurement: "1 dl" },
      { name: "Ströbröd", measurement: "1 dl" },
      { name: "Salt", measurement: "1 tsk" },
      { name: "Peppar", measurement: "1/2 tsk" },
      { name: "Smör", measurement: "till stekning" },
      { name: "Gräddsås", measurement: "till servering" },
      { name: "Lingonsylt", measurement: "till servering" },
      { name: "Potatismos", measurement: "till servering" },
    ],
  },
  {
    id: "R7tT5jU2pK",
    recipeUrl: "",
    name: "Raggmunk",
    directions:
      "<ol><li>Vispa ihop mjöl och hälften av mjölken till en jämn smet.</li><li>Tillsätt ägg, resten av mjölken, och en nypa salt. Vispa till en slät smet.</li><li>Skala och riv potatisen grovt. Lägg i en bunke med kallt vatten och pressa ur vätskan.</li><li>Blanda den rivna potatisen i smeten.</li><li>Stek tunna pannkakor i smör tills de är gyllenbruna på båda sidor.</li><li>Servera med lingonsylt och eventuellt stekt fläsk.</li></ol>",
    days: [],
    tags: [
      { text: "Huvudrätt", color: "indigo" },
      { text: "Svensk", color: "indigo" },
      { text: "Raggmunk", color: "indigo" },
    ],
    ingredients: [
      { name: "Vetemjöl", measurement: "2 dl" },
      { name: "Mjölk", measurement: "4 dl" },
      { name: "Ägg", measurement: "2 st" },
      { name: "Potatis", measurement: "4-5 st (medelstora)" },
      { name: "Salt", measurement: "1 nypa" },
      { name: "Smör", measurement: "till stekning" },
      { name: "Lingonsylt", measurement: "till servering" },
      { name: "Stekt fläsk", measurement: "till servering (valfritt)" },
    ],
  },
  {
    id: "P5yI8tT3iP",
    recipeUrl: "",
    name: "Pyttipanna",
    directions:
      "<ol><li>Skala och tärna potatisen, löken, och korven i små bitar.</li><li>Stek potatisen i smör tills den är gyllenbrun och nästan klar.</li><li>Tillsätt löken och korven och stek i ytterligare några minuter.</li><li>Smaka av med salt och peppar.</li><li>Servera med stekt ägg och rödbetor.</li></ol>",
    days: [],
    tags: [
      { text: "Huvudrätt", color: "indigo" },
      { text: "Svensk", color: "indigo" },
      { text: "Pyttipanna", color: "indigo" },
    ],
    ingredients: [
      { name: "Potatis", measurement: "4-5 st (kokta och kalla)" },
      { name: "Lök", measurement: "1 st" },
      { name: "Korv (t.ex. falukorv)", measurement: "300g" },
      { name: "Smör", measurement: "till stekning" },
      { name: "Salt", measurement: "efter smak" },
      { name: "Peppar", measurement: "efter smak" },
      { name: "Stekt ägg", measurement: "till servering" },
      { name: "Rödbetor", measurement: "till servering" },
    ],
  },
  {
    id: "K7öT8fäR2l",
    recipeUrl: "",
    name: "Köttfärslimpa",
    directions:
      "<ol><li>Blanda köttfärs, lök, ströbröd, mjölk, ägg, salt, och peppar i en skål.</li><li>Forma smeten till en limpa och lägg den i en ugnsform.</li><li>Grädda i ugnen i ca 60 minuter eller tills köttfärslimpan är genomstekt.</li><li>Gör såsen genom att smälta smör i en kastrull, tillsätt mjöl och spad från köttfärslimpan. Låt koka upp under omrörning.</li><li>Servera köttfärslimpan med såsen och kokt potatis.</li></ol>",
    days: [],
    tags: [
      { text: "Huvudrätt", color: "indigo" },
      { text: "Svensk", color: "indigo" },
      { text: "Köttfärslimpa", color: "indigo" },
    ],
    ingredients: [
      { name: "Köttfärs (nötfärs)", measurement: "500g" },
      { name: "Lök (finhackad)", measurement: "1 st" },
      { name: "Ströbröd", measurement: "1 dl" },
      { name: "Mjölk", measurement: "1 dl" },
      { name: "Ägg", measurement: "1 st" },
      { name: "Salt", measurement: "1 tsk" },
      { name: "Peppar", measurement: "1/2 tsk" },
      { name: "Smör", measurement: "25g (till såsen)" },
      { name: "Mjöl", measurement: "2 msk (till såsen)" },
      { name: "Köttfärslimpa", measurement: "till servering" },
      { name: "Kokt potatis", measurement: "till servering" },
    ],
  },
  {
    id: "S3iLl7oP9t",
    recipeUrl: "",
    name: "Sill och Potatis (Herring and Potatoes)",
    directions:
      "<ol><li>Koka potatisen tills den är mjuk. Låt den svalna och skiva den sedan i tunna skivor.</li><li>Skär sillen i mindre bitar.</li><li>Blanda gräddfil, majonnäs, senap, socker, och äppelcidervinäger i en skål till en sås.</li><li>Lägg ett lager potatisskivor i en serveringsskål, följt av ett lager sill och sås.</li><li>Upprepa lagren tills ingredienserna är slut. Avsluta med ett lager sås och garnera med dill.</li><li>Låt stå i kylen i minst ett par timmar innan servering.</li></ol>",
    days: [],
    tags: [
      { text: "Huvudrätt", color: "indigo" },
      { text: "Svensk", color: "indigo" },
      { text: "Sill och Potatis", color: "indigo" },
    ],
    ingredients: [
      { name: "Potatis", measurement: "500g (kokt och skivad)" },
      { name: "Sill", measurement: "250g" },
      { name: "Gräddfil", measurement: "200ml" },
      { name: "Majonnäs", measurement: "2 msk" },
      { name: "Senap", measurement: "1 msk" },
      { name: "Socker", measurement: "1 tsk" },
      { name: "Äppelcidervinäger", measurement: "1 msk" },
      { name: "Dill (för garnering)", measurement: "till servering" },
    ],
  },
  {
    id: "Ä6rT9sP5aM",
    recipeUrl: "",
    name: "Ärtsoppa med Pannkakor (Pea Soup with Pancakes)",
    directions:
      "<ol><li>Koka ärtsoppan enligt anvisningarna på förpackningen.</li><li>Vispa ihop mjöl, ägg, mjölk, och en nypa salt till en pannkakssmet.</li><li>Stek tunna pannkakor i smör.</li><li>Servera ärtsoppan med pannkakor och en sked dijonsenap.</li></ol>",
    days: [],
    tags: [
      { text: "Huvudrätt", color: "indigo" },
      { text: "Svensk", color: "indigo" },
      { text: "Ärtsoppa med Pannkakor", color: "indigo" },
    ],
    ingredients: [
      { name: "Ärtsoppa", measurement: "1 förpackning (enligt anvisningarna)" },
      { name: "Vetemjöl", measurement: "2 dl" },
      { name: "Ägg", measurement: "2 st" },
      { name: "Mjölk", measurement: "4 dl" },
      { name: "Salt", measurement: "1 nypa" },
      { name: "Smör", measurement: "till stekning" },
      { name: "Dijonsenap", measurement: "till servering" },
    ],
  },
  {
    id: "S7mÖrG8åS1t",
    recipeUrl: "",
    name: "Smörgåstårta (Swedish Sandwich Cake)",
    directions:
      "<ol><li>Blanda majonnäs och crème fraîche i en skål.</li><li>Skär bröden i tre lika stora delar på längden.</li><li>Lägg ett brödlager på ett fat och bred ett tunt lager av majonnässmeten över.</li><li>Lägg på skivad skinka och tomater. Täck med majonnässmet.</li><li>Lägg nästa brödlager, bred på majonnässmet och lägg på räkor och salladsblad. Täck med majonnässmet.</li><li>Lägg det sista brödlagret och täck hela tårtan med resten av majonnässmeten.</li><li>Dekorera med ägg, räkor, grönsaker och dill. Kyl i minst 3 timmar innan servering.</li></ol>",
    days: [],
    tags: [
      { text: "Huvudrätt", color: "indigo" },
      { text: "Svensk", color: "indigo" },
      { text: "Smörgåstårta", color: "indigo" },
    ],
    ingredients: [
      { name: "Majonnäs", measurement: "1 dl" },
      { name: "Crème fraîche", measurement: "1 dl" },
      { name: "Vitt bröd", measurement: "6 skivor" },
      { name: "Skinka", measurement: "150g (skivad)" },
      { name: "Tomater", measurement: "2 st (skivade)" },
      { name: "Räkor", measurement: "150g" },
      { name: "Salladsblad", measurement: "några blad" },
      { name: "Ägg", measurement: "2 st (skivade)" },
      { name: "Grönsaker (gurka, paprika)", measurement: "efter önskemål" },
      { name: "Dill (för dekoration)", measurement: "några kvistar" },
    ],
  },
  {
    id: "K3aN6eL8bU",
    recipeUrl: "",
    name: "Kanelbullar (Swedish Cinnamon Rolls)",
    directions:
      "<ol><li>Smula jästen i en bunke och tillsätt mjölken. Rör om tills jästen lösts upp.</li><li>Tillsätt socker, salt, smör, och kardemumma. Blanda väl.</li><li>Tillsätt det mesta av vetemjölet och arbeta degen smidig. Låt jäsa i ca 30 minuter, täckt med en handduk.</li><li>Knåda degen på mjölat bord och kavla ut den till en rektangel.</li><li>Bred på smör, strö över kanel och socker.</li><li>Rulla ihop degen från långsidan och skär den i bitar. Lägg på bakplåtspapper och låt jäsa i ytterligare 30 minuter.</li><li>Pensla bullarna med uppvispat ägg och strö pärlsocker över.</li><li>Grädda i ugnen i 225 grader i ca 8-10 minuter eller tills de är gyllenbruna.</li><li>Låt svalna och njut!</li></ol>",
    days: [],
    tags: [
      { text: "Efterrätt", color: "indigo" },
      { text: "Svensk", color: "indigo" },
      { text: "Kanelbullar", color: "indigo" },
    ],
    ingredients: [
      { name: "Jäst", measurement: "25g (färsk)" },
      { name: "Mjölk", measurement: "2.5 dl" },
      { name: "Socker", measurement: "75g" },
      { name: "Salt", measurement: "1/2 tsk" },
      { name: "Smör", measurement: "75g (rumstempererat)" },
      { name: "Kardemumma", measurement: "1 tsk" },
      { name: "Vetemjöl", measurement: "ca 7 dl" },
      { name: "Smör (till fyllningen)", measurement: "75g (rumstempererat)" },
      { name: "Kanel (till fyllningen)", measurement: "2 msk" },
      { name: "Socker (till fyllningen)", measurement: "100g" },
      { name: "Ägg (uppvispat, till pensling)", measurement: "1 st" },
      { name: "Pärlsocker (till dekoration)", measurement: "efter önskemål" },
    ],
  },
  {
    id: "G9rA4vA6dL",
    recipeUrl: "",
    name: "Gravad Lax (Swedish Gravlax)",
    directions:
      "<ol><li>Blanda socker, salt, vitpeppar och dill i en skål.</li><li>Skär laxfilén på längden i två delar och lägg den ena halvan med skinnsidan nedåt i en form.</li><li>Strö hälften av blandningen över laxen.</li><li>Lägg den andra laxhalvan ovanpå med skinnsidan uppåt och strö resten av blandningen över.</li><li>Täck formen med plastfolie och lägg något tungt ovanpå, som en tallrik med vikter.</li><li>Låt laxen gravas i kylskåp i 2-3 dagar, vänd den och skeda över vätskan några gånger om dagen.</li><li>När laxen är färdiggravad, skär den i tunna skivor och servera med hovmästarsås, färskpotatis och enbärssill.</li></ol>",
    days: [],
    tags: [
      { text: "Förrätt", color: "indigo" },
      { text: "Svensk", color: "indigo" },
      { text: "Gravad Lax", color: "indigo" },
    ],
    ingredients: [
      { name: "Laxfilé (med skinn)", measurement: "ca 500g" },
      { name: "Strösocker", measurement: "2 dl" },
      { name: "Salt", measurement: "1 dl" },
      { name: "Vitpeppar", measurement: "1 tsk" },
      { name: "Färsk dill (hackad)", measurement: "1/2 kruka" },
      { name: "Hovmästarsås (till servering)", measurement: "till servering" },
      { name: "Färskpotatis (till servering)", measurement: "till servering" },
      { name: "Enbärssill (till servering)", measurement: "till servering" },
    ],
  },
  {
    id: "Äp9pElK3aK",
    recipeUrl: "",
    name: "Äppelkaka med Vaniljsås (Swedish Apple Cake with Vanilla Sauce)",
    directions:
      "<ol><li>Sätt ugnen på 175 grader C. Smörj och bröa en rund form.</li><li>Skala, kärna ur och skiva äpplena tunt.</li><li>Vispa ägg och socker pösigt. Tillsätt smält smör, mjöl och bakpulver. Rör till en slät smet.</li><li>Häll hälften av smeten i formen. Lägg ett lager äppelskivor och strö över kanel och socker. Häll över resten av smeten.</li><li>Grädda i mitten av ugnen i ca 35-40 minuter, tills kakan fått fin färg och är genomgräddad.</li><li>Under tiden kan du göra vaniljsåsen genom att koka upp mjölk och vispgrädde med vaniljsocker. Servera den varm till äppelkakan.</li></ol>",
    days: [],
    tags: [
      { text: "Efterrätt", color: "indigo" },
      { text: "Svensk", color: "indigo" },
      { text: "Äppelkaka med Vaniljsås", color: "indigo" },
    ],
    ingredients: [
      { name: "Äpplen", measurement: "4 st (skivade)" },
      { name: "Ägg", measurement: "2 st" },
      { name: "Strösocker", measurement: "2 dl" },
      { name: "Smör (smält)", measurement: "100g" },
      { name: "Vetemjöl", measurement: "2 dl" },
      { name: "Bakpulver", measurement: "2 tsk" },
      { name: "Kanel", measurement: "1 tsk" },
      { name: "Mjölk (till vaniljsås)", measurement: "2 dl" },
      { name: "Vispgrädde (till vaniljsås)", measurement: "1 dl" },
      { name: "Vaniljsocker (till vaniljsås)", measurement: "1 tsk" },
    ],
  },
  {
    id: "Sv5n8sK2PnK",
    recipeUrl: "",
    name: "Svensk Pannkaka (Swedish Pancakes)",
    directions:
      "<ol><li>Vispa ihop mjölk, mjöl och salt till en slät smet.</li><li>Tillsätt äggen och vispa igen till smeten är helt jämn.</li><li>Hetta upp en stekpanna och smält smör i den.</li><li>Häll i en liten mängd smet och luta pannan så smeten täcker botten tunt.</li><li>Stek pannkakan tills den får fin gyllenbrun färg på undersidan.</li><li>Vänd pannkakan och stek den även på den andra sidan.</li><li>Upprepa med resten av smeten.</li><li>Servera med sylt, grädde eller färska bär.</li></ol>",
    days: [],
    tags: [
      { text: "Frukost", color: "indigo" },
      { text: "Svensk", color: "indigo" },
      { text: "Svensk Pannkaka", color: "indigo" },
    ],
    ingredients: [
      { name: "Mjölk", measurement: "4 dl" },
      { name: "Vetemjöl", measurement: "2 dl" },
      { name: "Salt", measurement: "1/2 tsk" },
      { name: "Ägg", measurement: "2 st" },
      { name: "Smör (till stekning)", measurement: "till stekning" },
      {
        name: "Sylt, grädde eller färska bär (till servering)",
        measurement: "till servering",
      },
    ],
  },
];

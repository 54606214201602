import { useEffect, useState } from "react"
import { singular } from "pluralize"

import { ItemModel, useItemsToDo } from "../../index"

export function useItemInputToDo(
  addedItemsToDo: ItemModel[],
  categoryToDo: string,
  setCategoryToDo: React.Dispatch<React.SetStateAction<string>>,
  allItemsToDo: ItemModel[]
) {
  const [value, setValue] = useState("")
  const { addItem: baseAddItemToDo } = useItemsToDo()

  const lowerName = singular(value.trim().toLowerCase())
  const previouslyAdded = allItemsToDo.find((i) => i.lowerName === lowerName)
  const alreadyAdded = addedItemsToDo.find((i) => i.lowerName === lowerName)
  const isValid = Boolean(value) && !alreadyAdded

  useEffect(() => {
    if (previouslyAdded) {
      setCategoryToDo(previouslyAdded.category)
    }
  }, [previouslyAdded, setCategoryToDo])

  // TODO May want to improve this so that if the category is manually set
  // then it isn't cleared so easily.
  useEffect(() => {
    if (value === "") {
      setCategoryToDo("")
    }
  }, [value, setCategoryToDo])

  const addItemToDo = (value, categoryToDo?) => {
    baseAddItemToDo(value, categoryToDo)
    setValue("")
  }

  const clear = () => {
    setValue("")
    setCategoryToDo("")
  }

  return {
    alreadyAdded,
    categoryToDo,
    isValid,
    previouslyAdded,
    value,
    addItemToDo,
    clear,
    setCategoryToDo,
    setValue,
  }
}

///My naming got crazy on this file, need to update fix it when I get time.  durationDate is the enddate.  DueDate is the startDate.  valueTimeDue is the startime, valueTimeEnd is the endTime

import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaintRoller,
  faTrash,
  faClose,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import { useUserContext } from "Authentication";
import { useItemsToDo } from "ListToDo";
import TimePicker from "react-time-picker";
//import DateTimePicker from 'react-datetime-picker';
import DatePicker from "react-date-picker";
//import "./DatePicker.css";
//import "./TimePicker.css";
import classes from "../Reminders/reminder.module.css";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
//import { useReminderSubscription } from './useReminderSubscription';
//import { storage } from "Config";
import { google, outlook, office365, yahoo, ics } from "calendar-link";
import { Button, Modal } from "Common";
import { ConfirmationModal } from "Common";
import TextareaAutosize from "react-textarea-autosize";
import { WeekDays } from "Habits/components/HabitsPage/style";
import { CirclePicker, GithubPicker } from "react-color";
import { DayOfTheWeek } from "Habits/components/DayOfTheWeek";
import { useTranslation } from "react-i18next";
//import RRule from "rrule";

export const AddPlanner = ({
  title,
  event,
  item,
  closeModal,
  start,
  end,
  isEdit,
}) => {
  //new reminder start.  This is a work in progree, I need to update the APP for remove habit language first.

  //const { reminders } = useReminderSubscription()

  //console.log("reminder list", reminders)
  //const { addItemPlanner } = useItemsToDo();
  const [isSchedulingReminder, setIsSchedulingReminder] = useState(false);
  const [reminderTitle, setReminderTitle] = useState(title + " Reminder");
  const [reminderBody, setReminderBody] = useState(
    event?.note ? event?.note : ""
  );
  const [startTime, setStartTime] = useState(new Date());
  const [valueTime, setValueTime] = useState(
    event?.time ? event?.time : "17:00"
  );
  const [valueTimeDue, setValueTimeDue] = useState(
    event?.time ? event?.time : "17:00"
  );
  const [valueTimeEnd, setValueTimeEnd] = useState("17:00");

  const [reminderDays, setReminderDays] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date(start));
  const [durationDate, setDurationDate] = useState(
    end != 0 ? new Date(end) : new Date()
  );
  const [repeatReminder, setRepeatReminder] = useState(false);
  const [isNative, setIsNative] = useState(true);
  const [isDaily, setIsDaily] = useState(false);
  const {
    addNewReminder,
    addNewPlannerEvent,
    editPlannerEvent,
    deletePlannerEvent,
  } = useUserContext();
  //const [icsURL, seticsURL] = useState("");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const user = useUserContext();
  const [reminderDoneModal, setReminderDoneModal] = useState(false);
  const [whatAddType, setWhatAddType] = useState("NoTask");
  const [valueName, setValueName] = useState(event?.name ? event?.name : "");
  const [valueNote, setValueNote] = useState(event?.note ? event?.note : "");
  const Reminder = useRef();
  const icsURL = useRef("");
  var rmessageID = Math.floor(Math.random() * 10000000);
  const { t } = useTranslation();

  //aloha planner default calendar
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [eventTitle, setEventTitle] = useState("");
  const [eventNotes, setEventNotes] = useState("");
  const [isAlohaPlanner, setIsAlohaPlanner] = useState(false);
  const [isDopeNotesApp, setIsDopeNotesApp] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  //planner color code
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const Newcolors = [
    "#EB9694",
    "pink",
    "#FAD0C3",
    "#fef68a",
    "#C1E1C5",
    "#BEDADC",
    "#C4DEF6",
    "#BED3F3",
    "#D4C4FB",
    "plum",
    "azure",
    "floralwhite",
  ];

  //console.log("event color", event?.color);

  //console.log("what is end", end);

  const checkValueInArray = (array, value) => {
    return array.includes(value);
  };

  const formatDateWithTime = (date) => {
    return moment(date).format("MMMM DD, YYYY @ hh:mm A");
  };

  const calculateDurationInMinutes = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const differenceInMilliseconds = end - start;
    const differenceInMinutes = Math.floor(
      differenceInMilliseconds / 1000 / 60
    );

    return differenceInMinutes;
  };

  const [eventBGColor, setEventBGColor] = useState(
    event?.color ? event?.color : "#fef68a"
  );

  const handleColorChangeComplete = (color, event) => {
    //  console.log("which color", color.hex);
    //  editNoteColor(id, color.hex);
    // setIsColorPickerOpen(false);
    setEventBGColor(color.hex);
  };

  const [isRepeats, setIsRepeats] = useState(
    event?.isRepeats ? event?.isRepeats : false
  );

  //new recurrence options
  const [repeatValue, setRepeatValue] = useState(
    event?.repeatValue ? event?.repeatValue : "none"
  );
  const [repeatMonthlyValue, setRepeatMonthlyValue] = useState(
    event?.repeatMonthlyValue ? event?.repeatMonthlyValue : "date"
  );
  const [repeatWeeklyIntervalValue, setRepeatWeeklyIntervalValue] = useState(
    event?.repeatWeeklyIntervalValue ? event?.repeatWeeklyIntervalValue : 1
  );

  const [repeatDays, setRepeatDays] = useState({
    name: "days",
    days: event?.days ? event?.days : [0, 1, 2, 3, 4, 5, 6],
  });

  const changeRepeat = (data) => {
    // console.log("what is being sent", data);

    if (data === "none") {
      setIsRepeats(false);
      setRepeatValue("none");
    }

    if (data === "daily") {
      setIsRepeats(true);
      setRepeatValue("daily");
    }

    if (data === "weekly") {
      setIsRepeats(true);
      setRepeatValue("weekly");
    }
    //new frequency

    if (data === "monthly") {
      setIsRepeats(true);
      setRepeatValue("monthly");
      findDayAndWeek(dueDate);
    }
    //new frequency

    if (data === "yearly") {
      setIsRepeats(true);
      setRepeatValue("yearly");
    }

    // console.log("what is repeat value", repeatValue);
  };

  const changeRepeatMonthlyValue = (data) => {
    // console.log("what is being sent", data);

    if (data === "date") {
      setRepeatMonthlyValue("date");
    }

    if (data === "day") {
      setRepeatMonthlyValue("day");
    }

    // console.log("what is repeat value", repeatValue);
  };

  const changeRepeatWeeklyIntervalValue = (data) => {
    // console.log("what is being sent", data);

    setRepeatWeeklyIntervalValue(data);

    // console.log("what is repeat value", repeatValue);
  };

  const history = useHistory();
  const routeChange = (path) => {
    history.push(path);
  };

  const changeReminderDays = (e) => {
    setReminderDays(e);
  };

  const convertDayofWeek = (data) => {
    if (data === 6) {
      return "SU";
    }
    if (data === 0) {
      return "MO";
    }
    if (data === 1) {
      return "TU";
    }
    if (data === 2) {
      return "WE";
    }
    if (data === 3) {
      return "TH";
    }
    if (data === 4) {
      return "FR";
    }
    if (data === 5) {
      return "SA";
    }
  };

  const convertDayofWeekFull = (data) => {
    if (data === 6) {
      return t("planner-form-sunday");
    }
    if (data === 0) {
      return t("planner-form-monday");
    }
    if (data === 1) {
      return t("planner-form-tuesday");
    }
    if (data === 2) {
      return t("planner-form-wednesday");
    }
    if (data === 3) {
      return t("planner-form-thursday");
    }
    if (data === 4) {
      return t("planner-form-friday");
    }
    if (data === 5) {
      return t("planner-form-saturday");
    }
  };

  const convertMonthlyPlace = (data) => {
    if (data === 1) {
      return t("planner-form-first");
    }
    if (data === 2) {
      return t("planner-form-second");
    }
    if (data === 3) {
      return t("planner-form-third");
    }
    if (data === 4) {
      return t("planner-form-fourth");
    }
    if (data === 5) {
      return t("planner-form-fifth");
    }
    if (data === 6) {
      return t("planner-form-sixth");
    }
  };

  const findDayAndWeek = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const dayLabel = (date.getDay() + 6) % 7;

    const firstDay = new Date(year, month, 1);
    const dayOfWeek = (firstDay.getDay() + 6) % 7; // Convert monday (0) to sunday 6
    const week = Math.ceil((day - dayOfWeek) / 7);

    const dayShort = convertDayofWeek(dayLabel);
    //const dayFull = convertDayofWeekFull(dayOfWeek);
    const weekPlace = convertMonthlyPlace(week);
    const dayFull = convertDayofWeekFull(dayLabel);

    //console.log("what is day?", day);

    return { day, week, dayShort, dayFull, weekPlace, dayLabel };
  };

  //Duration options
  const [customDuration, setCustomDuration] = useState(0);
  //New Reminder options
  const [durationOption, setDurationOption] = useState(
    event?.duration.value ? event?.duration.value : 15
  );

  const handleUpdateDurationDate = (e) => {
    //  console.log("what is valueTime", valueTimeEnd);
    //  console.log("what is e", e);

    const hoursDue = parseInt(valueTimeEnd.split(":")[0]);
    const minutesDue = parseInt(valueTimeEnd.split(":")[1] || 0);

    //calculate new options for reminder

    e.setHours(hoursDue);
    e.setMinutes(minutesDue);
    e.setSeconds(0);
    // console.log("what is e with time", e);
    setDurationDate(e);
    // selectedDurationdOption = { label: "Custom", value: 0 };
    checkDurationValue(dueDate, e);
  };

  const handleUpdateEndTime = (e) => {
    setValueTimeEnd(e);
    //console.log("what is e", e);
    const timeParts = e.split(":");
    const hoursDue = Number(timeParts[0]);
    const minutesDue = Number(timeParts[1]);

    //calculate new options for reminder

    durationDate.setHours(hoursDue);
    durationDate.setMinutes(minutesDue);
    durationDate.setSeconds(0);
    checkDurationValue(dueDate, durationDate);
  };

  const checkDurationValue = (a, b) => {
    if (end != 0) {
      const startDateCalc = new Date(a);
      const endDateCalc = new Date(b);

      var duration = calculateDurationInMinutes(startDateCalc, endDateCalc);
      // console.log("what is end", duration);
      setDurationOption(duration);

      const hours = b.getHours();
      const minutes = b.getMinutes();
      setValueTimeEnd(hours + ":" + minutes);
    }
  };

  const durationOptions = [
    { label: "Custom", value: 1 },
    { label: "5" + t("common-text-minutes"), value: 5 },
    { label: "10" + t("common-text-minutes"), value: 10 },
    { label: "15" + t("common-text-minutes"), value: 15 },
    { label: "20" + t("common-text-minutes"), value: 20 },
    { label: "30" + t("common-text-minutes"), value: 30 },
    { label: "45" + t("common-text-minutes"), value: 45 },
    { label: "1" + t("common-text-hours"), value: 60 },
    { label: "90" + t("common-text-minutes"), value: 90 },
    { label: "2" + t("common-text-hours"), value: 120 },
    { label: "3" + t("common-text-hours"), value: 180 },
    { label: "6" + t("common-text-hours"), value: 360 },
    { label: "1" + t("common-text-days"), value: 1440 },
    { label: "2" + t("common-text-days"), value: 2880 },
    { label: "3" + t("common-text-days"), value: 4320 },
    { label: "4" + t("common-text-days"), value: 5760 },
    { label: "5" + t("common-text-days"), value: 7200 },
    { label: "6" + t("common-text-days"), value: 8640 },
    { label: "7" + t("common-text-days"), value: 10080 },
    { label: "8 " + t("common-text-days"), value: 11520 },
    { label: "9" + t("common-text-days"), value: 12960 },
    { label: "10" + t("common-text-days"), value: 14400 },
    { label: "11" + t("common-text-days"), value: 15840 },
    { label: "12" + t("common-text-days"), value: 17280 },
    { label: "13" + t("common-text-days"), value: 18720 },
    { label: "14" + t("common-text-days"), value: 20160 },
  ];

  //New Reminder options
  const [reminderOption, setReminderOption] = useState("");
  const reminderOptions = [
    { label: "15" + t("common-text-minutes"), value: 15 },
    { label: "30" + t("common-text-minutes"), value: 30 },
    { label: "45" + t("common-text-minutes"), value: 45 },
    { label: "1" + t("common-text-hours"), value: 60 },
    { label: "90" + t("common-text-minutes"), value: 90 },
    { label: "2" + t("common-text-hours"), value: 120 },
    { label: "3" + t("common-text-hours"), value: 180 },
    { label: "6" + t("common-text-hours"), value: 360 },
    { label: "1" + t("common-text-days"), value: 1440 },
    { label: "3" + t("common-text-days"), value: 4320 },
  ];

  const createrRule = () => {
    if (repeatValue == "daily") {
      return "FREQ=DAILY;INTERVAL=1";
    }
    if (repeatValue == "weekly") {
      const dayAbbreviations = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
      const shortDayAbbreviations = repeatDays.days.map(
        (dayIndex) => dayAbbreviations[dayIndex]
      );
      return `FREQ=WEEKLY;BYDAY=${shortDayAbbreviations};INTERVAL=${repeatWeeklyIntervalValue}`;
    }

    if (repeatValue == "monthly") {
      if (repeatMonthlyValue == "date") {
        const getDay = dueDate.getDate();
        return `FREQ=MONTHLY;BYMONTHDAY=${getDay};INTERVAL=1`;
      } else {
        return `FREQ=MONTHLY;BYSETPOS=${findDayAndWeek(dueDate).week};BYDAY=${
          findDayAndWeek(dueDate).dayShort
        };INTERVAL=1`;
      }
    }

    if (repeatValue == "yearly") {
      const getDay = dueDate.getDate();
      const getMonth = dueDate.getMonth();
      return `FREQ=YEARLY;BYMONTH=${getMonth};BYMONTHDAY=${getDay}`;
    }
    if (repeatValue == "none") {
      return "FREQ=";
    }
  };

  const notificationScheduler = async (e) => {
    // var durationDate = new Date();
    e.preventDefault(e);

    //set New Planner Item to ReminderTitle
    setReminderTitle(valueName);

    var selectedDurationdOption = durationOptions.find(
      (option) => option.value === parseInt(durationOption)
    );

    if (selectedDurationdOption == undefined) {
      selectedDurationdOption = { label: "custom", value: 0 };
    }

    const selectedOption = reminderOptions.find(
      (option) => option.value === parseInt(reminderOption)
    );

    //Duration is used for End event Time for the planner
    const durationTimeInMinutes = selectedDurationdOption
      ? selectedDurationdOption.value
      : 15;
    const durationTimeInMillis = durationTimeInMinutes * 60 * 1000;

    // Use the selected option value for setting the reminder
    const reminderTimeInMinutes = selectedOption ? selectedOption.value : 0;
    const reminderTimeInMillis = reminderTimeInMinutes * 60 * 1000;

    if (valueTimeDue != null) {
      rmessageID = Math.floor(Math.random() * 100000);
      var today = new Date();
      var scheduleDate = reminderDays;
      const timeParts = valueTimeDue.split(":");
      const hoursDue = Number(timeParts[0]);
      const minutesDue = Number(timeParts[1]);

      //calculate new options for reminder

      dueDate.setHours(hoursDue);
      dueDate.setMinutes(minutesDue);
      dueDate.setSeconds(0);

      const reminderDate = new Date(dueDate.getTime() - reminderTimeInMillis);

      //Duration (End meeting Time)
      setDurationDate(new Date(dueDate.getTime() + durationTimeInMillis));

      //Set Var for Add To Calendar Devices
      setStartDate(dueDate);
      setEndDate(durationDate);
      setEventTitle(valueName);
      setEventNotes(valueNote);

      //  console.log("reminderDate", reminderDate);
      //  console.log("durationDate", durationDate);
      //Save it for Add planner, this is for Planner endTime

      scheduleDate = reminderDate;
      setReminderDays(reminderDate);

      //get time only from new reminder option selected

      const currentReminderDate = reminderDate;
      const currentReminderHours = String(
        currentReminderDate.getHours()
      ).padStart(2, "0");
      const currentReminderMinutes = String(
        currentReminderDate.getMinutes()
      ).padStart(2, "0");
      const currentReminderTime = `${currentReminderHours}:${currentReminderMinutes}:00`;

      //  console.log("reminderTimeonly", currentReminderTime);
      setValueTime(currentReminderTime);
      const hours = Number(valueTime.slice(0, 2));
      // console.log(event.time.slice(-2))
      const minutes = Number(valueTime.slice(-2));

      rmessageID = rmessageID.toString() + "dnotes";
      let seconds = Math.abs(reminderDate.getTime() - today.getTime()) / 1000;

      //Convert the difference between today and a fture date

      //var d = new Date();
      //@ts-ignore

      Reminder.current = {
        title: valueName,
        description: valueNote,
        start: moment(scheduleDate).format("YYYY-MM-DD") + valueTimeDue,
        duration: [durationOption, "minute"],
        allDay: false,
        rRule: createrRule(),
      };

      ///TODO notification needs work.
      if (repeatValue == "none" || repeatValue == "daily")
        if (window.isNative) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: isRepeats
                ? "NoficationSelected"
                : "NoficationSelectedCustom",
              name: valueName,
              body: valueNote,
              days: repeatDays.days,
              time: valueTimeDue,
              seconds: Math.round(seconds),
              id: rmessageID,
              repeats: isRepeats,
              isAdded: "create",
            })
          );
        }

      setTimeout(() => {
        addNewReminder(
          today,
          rmessageID,
          valueName,
          valueNote,
          dueDate,
          valueTimeDue,
          isDaily,
          "",
          repeatValue,
          repeatMonthlyValue,
          repeatWeeklyIntervalValue
        );
      }, 1500);
    }
    // console.log("item id", item.id);

    //  ToDoDueDateUpdate(item.id, dueDate, valueTimeDue);
    if (whatAddType == "Task") {
      // addItemPlanner(valueName, dueDate, valueTimeDue, durationDate);
    }
    const isTask = whatAddType == "Task" ? true : false;
    // sorry bad naming on my part value is name, dueDate is start, duration date is end
    if (isEdit) {
      editPlannerEvent(
        event?.id,
        valueName,
        valueNote,
        dueDate,
        durationDate,
        valueTimeDue,
        isTask,
        isRepeats,
        repeatDays.days,
        selectedDurationdOption,
        repeatValue,
        repeatMonthlyValue,
        repeatWeeklyIntervalValue,
        eventBGColor
      );
    } else {
      addNewPlannerEvent(
        valueName,
        valueNote,
        dueDate,
        durationDate,
        valueTimeDue,
        isTask,
        isRepeats,
        repeatDays.days,
        selectedDurationdOption,
        repeatValue,
        repeatMonthlyValue,
        repeatWeeklyIntervalValue,
        eventBGColor
      );
    }
    setReminderDoneModal(true);
  };

  const notificationCancel = (e) => {
    if (window.isNative) {
      // Post the message back to expo
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "NoficationSelectedCustom",
          id: rmessageID,
          isAdded: "remove",
        })
      );
      setIsSchedulingReminder(false);
      //alert(data.name, data.days, valueTime)
    } else {
      // Your old login method
      //  console.log("clicked")
    }
  };

  ///new reminder end

  //button for the end modal

  const close = () => {
    setReminderDoneModal(false);
    setIsSchedulingReminder(false);
    setReminderTitle("");
    setReminderBody("");
    setValueNote("");
    var current = new Date();
    const time = current.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    setValueTime(time);
    setRepeatReminder(false);
    setReminderDays(new Date());
    setValueTimeDue("");
    setDueDate(new Date());
    closeModal();
  };

  const addEventDeviceCalendar = () => {
    var days = repeatDays.days.map((number) => number + 1);
    var dateForMonthlyYearly;
    if (repeatValue == "daily" || repeatValue == "weekly") {
      days = repeatDays.days.map((number) => number + 1);
    }
    if (repeatValue == "monthly") {
      if (repeatMonthlyValue == "date") {
        dateForMonthlyYearly = dueDate.getDate();
        setRepeatWeeklyIntervalValue(1);
      } else {
        days = findDayAndWeek(dueDate).dayLabel + 2;
        setRepeatWeeklyIntervalValue(1);
      }
    }

    if (repeatValue == "yearly") {
      dateForMonthlyYearly = dueDate.getDate();
      setRepeatWeeklyIntervalValue(1);
    }

    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "addEventDeviceCalendar",
        titleName: eventTitle,
        notes: eventNotes,
        startDate: moment(startDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        endDate: moment(endDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        allDay: false,
        interval: repeatWeeklyIntervalValue,
        weekPositionInMonth: findDayAndWeek(dueDate).week,
        dateForMonthlyYearly: dateForMonthlyYearly,
        repeatValue: repeatValue,
        monthlyType: repeatMonthlyValue,
        days: days,
      })
    );
  };

  const GoogleCalendar = () => {
    //@ts-ignore
    window.open(google(Reminder.current));
  };
  const YahooCalendar = () => {
    //@ts-ignore
    window.open(yahoo(Reminder.current));
  };

  const ICSCalendar = () => {
    if (!isNative) {
      window.open(ics(Reminder.current));
    } else {
    }
    //@ts-ignore
    window.open(ics(Reminder.current));
  };

  const OutlookCalendar = () => {
    //@ts-ignore
    window.open(outlook(Reminder.current));
  };

  const Office365Calendar = () => {
    //@ts-ignore
    window.open(office365(Reminder.current));
  };
  //image uploader end

  // end filer uploader

  const changeAddType = (e) => {
    setWhatAddType(e.target.value);
    // console.log("what type", whatAddType);
  };

  const handleEnterPressed = (event) => {
    if (event.key === "Enter") {
      // Enter key was pressed
      // You can perform any desired action here
    }
  };

  var current = new Date();
  const time = current.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  const DeleteItemConfirmation = () => {
    // console.log("delete function here");
    deletePlannerEvent(event.id);
    setConfirmModalOpen(false);
  };

  useEffect(() => {
    var fixZeroMinutes =
      start.getMinutes() != 0 ? start.getMinutes().toString() : "00";
    var roundedMinutes = Math.ceil(start.getMinutes() / 30) * 30;
    if (roundedMinutes === 60) {
      start.setHours(start.getHours() + 1);
      start.setMinutes(0);
    } else {
      start.setMinutes(roundedMinutes);
    }

    var fixZeroMinutes = start.getMinutes().toString().padStart(2, "0");

    var fixMorningHours =
      start.getHours() > 9
        ? start.getHours().toString()
        : "0" + start.getHours().toString();

    var getTimeFromSlot = fixMorningHours + ":" + fixZeroMinutes;
    setValueTimeDue(getTimeFromSlot);

    checkDurationValue(start, end);
    // console.log("what is valuetimedue", valueTimeDue);

    window.scrollTo({ top: 0, behavior: "smooth" });
    if (window.isNative) {
      setIsNative(true);
    } else {
      setIsNative(false);
    }

    if (window.isAloha) {
      setIsAlohaPlanner(true);
    }

    if (window.isDopeNotesApp) {
      setIsDopeNotesApp(true);
    }

    if (window.isIOS) {
      setIsIOS(true);
    }

    return () => {};
  }, []);

  //console.log("what are the days", repeatDays);

  return (
    <section className={classes.section}>
      <div
        style={{
          display: "flex",
          alignItems: "right",
          justifyContent: "right",
          marginTop: -20,
        }}
      >
        {" "}
        <button
          style={{
            backgroundColor: "transparent",
            borderStyle: "none",
            cursor: "pointer",
            marginTop: -10,
            marginRight: 10,
          }}
          onClick={closeModal}
        >
          <FontAwesomeIcon icon={faClose} size="1x" />
        </button>
      </div>
      <h3 style={{ textAlign: "center", marginTop: -10 }}>
        {" "}
        {!isEdit
          ? t("planner-form-title-createnew")
          : t("planner-form-title-editmode")}
      </h3>
      <div style={{ overflow: "scroll" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: 10,
          }}
        ></div>{" "}
        <form onSubmit={notificationScheduler}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <label style={{ paddingTop: 10, paddingBottom: 5 }}>
              {t("planner-form-name")}{" "}
            </label>
            <TextareaAutosize
              // placeholder="Enter items here to add to your list below ... "
              //  textContentType="none"
              onKeyDown={handleEnterPressed}
              onBlur={handleEnterPressed}
              type="text"
              required
              maxLength={144}
              value={isEdit ? valueName : null}
              onChange={(e) => setValueName(e.target.value)}
              style={{
                fontFamily: "inherit",
                fontSize: "inherit",
                padding: 10,
                width: "80%",
              }}
              placeholder=""
              className={classes.myform}
            />
            <label style={{ paddingTop: 10, paddingBottom: 5 }}>
              {t("planner-form-details")}
            </label>
            <TextareaAutosize
              // placeholder="Enter items here to add to your list below ... "
              // textContentType="none"
              onKeyDown={handleEnterPressed}
              onBlur={handleEnterPressed}
              minRows={2}
              type="text"
              value={isEdit ? valueNote : null}
              onChange={(e) => setValueNote(e.target.value)}
              style={{
                fontFamily: "inherit",
                fontSize: "inherit",
                padding: 10,
                width: "80%",
              }}
              placeholder="optional"
              className={classes.myform}
            />
          </div>
          <div
            style={{
              paddingBottom: 0,
              paddingTop: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label style={{ paddingTop: 10, paddingBottom: 5 }}>
                {t("planner-landing-start-label")} {t("planner-form-date")}{" "}
              </label>
              <DatePicker
                clearIcon={null}
                disableCalendar={false}
                style={{ padding: 0 }}
                onChange={(e) => {
                  setDueDate(e);
                  findDayAndWeek(dueDate);
                }}
                value={dueDate}
                format="y MMM dd"
              />
              <label style={{ paddingTop: 10, paddingBottom: 5 }}>
                {t("planner-form-time")}
              </label>
              <TimePicker
                onChange={(e) => setValueTimeDue(e)}
                value={valueTimeDue}
                maxDetail="minute"
                disableClock
                clearIcon={null}
                className={classes.timepicker}
              />
            </div>
            {checkValueInArray(durationOptions, durationOption) ? (
              <div
                style={{
                  paddingBottom: 0,
                  paddingTop: 10,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <label style={{ paddingTop: 10, paddingBottom: 0 }}>
                  {t("planner-form-duration")}
                </label>
                <div>
                  <select
                    value={durationOption}
                    onChange={(e) => setDurationOption(e.target.value)}
                    style={{ fontSize: 14, marginTop: 10, padding: 10 }}
                  >
                    {durationOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : (
              <div
                style={{
                  paddingTop: 10,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <hr style={{ borderStyle: "dashed", width: 185 }} />
                <label
                  style={{
                    paddingTop: 10,
                    paddingBottom: 5,
                    textAlign: "center",
                  }}
                >
                  {t("planner-landing-end-label")} {t("planner-form-date")}
                </label>
                <DatePicker
                  clearIcon={null}
                  disableCalendar={false}
                  style={{ padding: 0 }}
                  onChange={(e) => {
                    handleUpdateDurationDate(e);
                  }}
                  value={durationDate}
                  format="y MMM dd"
                />
                <label style={{ paddingTop: 10, paddingBottom: 5 }}>
                  {t("planner-form-time")}
                </label>
                <TimePicker
                  onChange={(e) => handleUpdateEndTime(e)}
                  value={valueTimeEnd}
                  maxDetail="minute"
                  disableClock
                  clearIcon={null}
                  className={classes.timepicker}
                />
              </div>
            )}
            <hr style={{ borderStyle: "dashed", width: 185, marginTop: 20 }} />
            <div style={{ paddingTop: 20 }} />
            <label>{t("planner-form-doesrepeat")}</label>
            <select
              value={repeatValue}
              onChange={(e) => changeRepeat(e.target.value)}
              style={{ fontSize: 14, marginTop: 10, padding: 10 }}
            >
              <option value="none">{t("planner-form-repeatno")}</option>
              <option value="daily">{t("planner-form-daily")}</option>
              <option value="weekly">{t("planner-form-weekly")}</option>
              <option value="monthly">{t("planner-form-monthly")}</option>
              <option value="yearly">{t("planner-form-yearly")}</option>
            </select>
            {repeatValue === "weekly" ? (
              <div style={{ paddingTop: 20 }}>
                <label>{t("planner-form-whichdays")}</label>
                <div style={{ paddingTop: 10 }} />
                <WeekDays>
                  {Array.from(Array(7).keys()).map((element, index) => (
                    <div style={{ paddingLeft: 5 }} key={index}>
                      <DayOfTheWeek
                        key={element}
                        state={repeatDays}
                        setState={setRepeatDays}
                        createHabit={true}
                        weekday={element}
                        AddPlannerWeeklyDay={findDayAndWeek(dueDate).dayLabel}
                      />
                    </div>
                  ))}
                </WeekDays>
                <div style={{ paddingTop: 20, textAlign: "center" }}>
                  <label>{t("planner-form-interval")}</label>
                  <select
                    value={repeatWeeklyIntervalValue}
                    onChange={(e) =>
                      changeRepeatWeeklyIntervalValue(e.target.value)
                    }
                    style={{ fontSize: 14, marginTop: 10, padding: 10 }}
                  >
                    <option value={1}>{t("planner-form-week1")}</option>
                    <option value={2}>{t("planner-form-week2")}</option>
                    <option value={3}>{t("planner-form-week3")}</option>
                    <option value={4}>{t("planner-form-week4")}</option>
                  </select>
                </div>
              </div>
            ) : null}
            {repeatValue === "monthly" ? (
              <div style={{ paddingTop: 20, textAlign: "center" }}>
                <label>{t("planner-form-monthdayorweek")}</label>
                <select
                  value={repeatMonthlyValue}
                  onChange={(e) => changeRepeatMonthlyValue(e.target.value)}
                  style={{ fontSize: 14, marginTop: 10, padding: 10 }}
                >
                  <option value="date">{t("planner-form-month1")}</option>
                  <option value="day">{t("planner-form-month2")}</option>
                </select>
                <br />
                {repeatMonthlyValue === "day" ? (
                  <span
                    style={{
                      fontSize: 14,
                      fontStyle: "italic",
                      color: "green",
                    }}
                  >
                    {t("planner-form-monthlyonthe")}{" "}
                    {findDayAndWeek(dueDate).weekPlace}{" "}
                    {findDayAndWeek(dueDate).dayFull}
                  </span>
                ) : null}
              </div>
            ) : null}
            <label style={{ paddingTop: 20, paddingBottom: 0 }}>
              {t("planner-form-color")}
            </label>
            <div
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                filter: "invert(100%)",
              }}
            >
              <div
                style={{
                  backgroundColor: eventBGColor,
                  padding: 20,
                }}
              />
              <div
                style={{
                  padding: 5,
                }}
              />
              <CirclePicker
                onChangeComplete={handleColorChangeComplete}
                colors={Newcolors}
              />
            </div>

            {isNative && !isRepeats ? (
              <>
                {" "}
                <label style={{ paddingTop: 20, paddingBottom: 5 }}>
                  {t("planner-form-mobile-reminder")}
                </label>
                <select
                  value={reminderOption}
                  onChange={(e) => setReminderOption(e.target.value)}
                  style={{ fontSize: 14, marginTop: 10, padding: 10 }}
                >
                  {/*}  <option value={5}>5 Minutes</option>*/}
                  {reminderOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>{" "}
              </>
            ) : null}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 20,
            }}
          >
            <button
              className="actions_button"
              type="submit"
              style={{
                textAlign: "center",
                //  paddingLeft: 65,
                //  paddingRight: 65,
                minWidth: 75,
                maxWidth: 400,
                width: "95%",
              }}
              //   className={classes.secondaryButton}
            >
              {!isEdit ? t("common-button-save") : t("common-button-update")}
            </button>
          </div>
        </form>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 10,
            // marginLeft: 25,
          }}
        >
          <div
            //  className={classes.modalActions}
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              display: "flex",
              flexWrap: "wrap",

              userSelect: "none",
            }}
          >
            {isEdit ? (
              <div style={{ flex: 1, paddingTop: 10 }}>
                <span
                  onClick={() => setConfirmModalOpen(true)}
                  className="actions_button"
                  style={{ backgroundColor: "gray" }}

                  //   className={classes.secondaryButton}
                >
                  <FontAwesomeIcon icon={faTrash} size="1x" />
                </span>
              </div>
            ) : null}
            <div style={{ flex: 2, paddingTop: 10 }}>
              <span
                //  className={classes.blinkingButton}
                className="actions_button"
                onClick={closeModal}
                style={{
                  backgroundColor: "grey",
                  textAlign: "center",
                }}
                //   className={classes.secondaryButton}
              >
                {t("common-button-cancel")}
              </span>
            </div>
          </div>
        </div>
        <Modal
          isOpen={reminderDoneModal}
          onRequestClose={close}
          style={{ overlay: { zIndex: 100 } }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "right",
              justifyContent: "right",
              margin: 10,
            }}
          >
            {" "}
            <button
              style={{
                backgroundColor: "transparent",
                borderStyle: "none",
                cursor: "pointer",
                marginTop: -40,
                marginRight: 10,
              }}
              onClick={close}
            >
              <FontAwesomeIcon icon={faClose} size="1x" />
            </button>
          </div>
          <div
            style={{ paddingBottom: 150, paddingLeft: 20, paddingRight: 20 }}
          >
            <h2 style={{ lineHeight: 1.5, marginTop: -20 }}>
              <FontAwesomeIcon icon={faCalendarDay} size="sm" />{" "}
              {!isEdit
                ? t("common-label-eventcreated")
                : t("common-label-eventupdated")}
            </h2>
            <p
              style={{
                fontSize: "inherit",
                fontFamily: "inherit",
                lineHeight: 1.5,
              }}
            >
              {t("planner-form-name")}: {reminderTitle}
            </p>
            {/*}    <p
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              lineHeight: 1.5,
            }}
          >
            Body: {reminderBody}
          </p>*/}
            {isDaily ? (
              <p
                style={{
                  fontSize: "inherit",
                  fontFamily: "inherit",
                  lineHeight: 1.5,
                }}
              >
                {t("planner-form-repeatsdaily")}
              </p>
            ) : (
              <p
                style={{
                  fontSize: "inherit",
                  fontFamily: "inherit",
                  lineHeight: 1.5,
                }}
              >
                {t("planner-form-date")}:{" "}
                {moment(new Date(reminderDays)).format("MMMM DD, YYYY")}
              </p>
            )}
            <p
              style={{
                fontSize: "inherit",
                fontFamily: "inherit",
                lineHeight: 1.5,
              }}
            >
              {t("planner-form-time")}: {valueTimeDue}
            </p>
            <hr />
            <p
              style={{
                fontSize: "inherit",
                fontFamily: "inherit",
                lineHeight: 1.5,
              }}
            >
              {t("planner-form-thirdparty")}
            </p>
            <div className="reminder-button-grid">
              {isAlohaPlanner || isDopeNotesApp ? (
                <button
                  className="reminder-calendar-buttons"
                  onClick={addEventDeviceCalendar}
                >
                  {isIOS ? "iPhone " : "Android "}
                  {t("common-button-calendar")}
                </button>
              ) : isNative && !isAlohaPlanner && !isDopeNotesApp ? (
                <div>
                  <button
                    className="reminder-calendar-buttons"
                    disabled
                    style={{ opacity: 0.6 }}
                    // onClick={addEventDeviceCalendar}
                  >
                    {isIOS ? "iPhone " : "Android "}
                    {t("common-button-calendar")}
                  </button>
                  <br />
                  <span
                    style={{
                      textAlign: "center",
                      fontSize: 12,
                      paddingLeft: 10,
                    }}
                  >
                    Please update your app to enable!
                  </span>
                </div>
              ) : null}
              <button
                className="reminder-calendar-buttons"
                onClick={GoogleCalendar}
              >
                Google {t("common-button-calendar")}
              </button>
              {!isRepeats ? (
                <>
                  <button
                    className="reminder-calendar-buttons"
                    onClick={YahooCalendar}
                  >
                    Yahoo {t("common-button-calendar")}
                  </button>
                  <button
                    className="reminder-calendar-buttons"
                    onClick={OutlookCalendar}
                  >
                    Outlook {t("common-button-calendar")}
                  </button>
                  <button
                    className="reminder-calendar-buttons"
                    onClick={Office365Calendar}
                  >
                    Office365 {t("common-button-calendar")}
                  </button>
                </>
              ) : null}
              {!isNative ? (
                <button
                  className="reminder-calendar-buttons"
                  onClick={ICSCalendar}
                >
                  ICS/ICAL
                </button>
              ) : null}
              <button
                style={{
                  fontSize: "inherit",
                  fontFamily: "inherit",
                  color: "white",
                  backgroundColor: "#1e79c3",
                  borderColor: "white",
                  padding: 10,
                  borderRadius: 10,
                  paddingRight: 30,
                  paddingLeft: 30,
                  width: 200,
                }}
                onClick={close}
              >
                {t("common-button-close")}
              </button>
            </div>
            <div style={{ paddingBottom: 150 }} />
          </div>
        </Modal>
        {confirmModalOpen ? (
          <ConfirmationModal
            isOpen={confirmModalOpen}
            text={t("planner-form-removeconfirmation")}
            onClickCancel={() => setConfirmModalOpen(false)}
            onClickConfirm={DeleteItemConfirmation}
          />
        ) : null}
        <div style={{ paddingBottom: 150 }} />
      </div>
    </section>
  );
};

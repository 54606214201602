import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaintRoller,
  faTrash,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import classes from "./reminder.module.css";
import toast, { Toaster } from "react-hot-toast";
import { useReminderSubscription } from "./useReminderSubscription";
import moment from "moment";
import logoimage from "../Assets/logo192.png";
import { useUserContext } from "Authentication";
import { ConfirmationModal } from "Common";
import { google, outlook, office365, yahoo, ics } from "calendar-link";

export const Reminder = ({
  id,
  messageID,
  title,
  body,
  created,
  scheduled,
  isDaily,
  atTime,
  icsURL,
}) => {
  const { deleteReminder } = useUserContext();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const deleteReminderBtn = () => {
    setConfirmModalOpen(true);
  };

  const DeleteReminderConfirmation = () => {
    try {
      deleteReminder(id);
      toast("Reminder Removed From History", {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "lightgreen",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });

      if (window.isNative) {
        // Post the message back to expo

        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "NoficationSelectedCustom",
            messageID: messageID,
            isAdded: "remove",
          })
        );

        //alert(data.name, data.days, valueTime)
      } else {
        // Your old login method
        //  console.log("clicked")
      }
    } catch (error) {
      console.log(error);
    }
  };

  const convertDate = (date) => {
    var update;
    if (date !== undefined) {
      update = moment(new Date(date.seconds * 1000)).format("MMMM DD, YYYY");
    }
    //  const update = date.toDate()
    return update;
  };

  const icsDownload = () => {
    //window.open({ icsURL });
    window.location.href = icsURL;
    //console.log("what is the URL", icsURL);
  };

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 20,
            borderRadius: 10,
            backgroundColor: "whitesmoke",

            fontSize: 16,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 0,
              borderRadius: 10,
              backgroundColor: "whitesmoke",
            }}
          >
            {isDaily ? (
              <span
                style={{
                  fontSize: "inherit",
                  fontFamily: "inherit",
                  textAlign: "center",
                  paddingBottom: 20,
                  color: "darkgreen",
                }}
              >
                Repeats Daily
              </span>
            ) : (
              <span
                style={{
                  fontSize: "inherit",
                  fontFamily: "inherit",
                  textAlign: "center",
                  paddingBottom: 20,
                  color: "darkgreen",
                  marginTop: 0,
                }}
              >
                Single Reminder
              </span>
            )}
            <span>Created: {convertDate(created)} </span>
            <span>Title: {title}</span>
            <span>Body: {body}</span>
            <span>Date: {convertDate(scheduled)}</span>
            <span>Time: {atTime} </span>

            {icsURL != "" ? (
              <button
                style={{
                  backgroundColor: "transparent",
                  color: "blue",
                  fontSize: "inherit",
                  fontFamily: "inherit",
                  marginTop: 10,
                  border: 0,
                  cursor: "pointer",
                }}
                onClick={icsDownload}
              >
                Download Calendar ICS
              </button>
            ) : (
              <span style={{ marginTop: 10, textAlign: "center" }}>
                No Calendar ICS
              </span>
            )}

            <button
              type="button"
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                padding: 5,
                marginTop: 20,
                cursor: "pointer",
              }}
              onClick={() => deleteReminderBtn(id)}
            >
              <FontAwesomeIcon icon={faTrash} size="1x" />
            </button>
          </div>
        </div>

        <div style={{ paddingBottom: 10 }} />
      </div>
      <ConfirmationModal
        isOpen={confirmModalOpen}
        text="Are you sure you want to remove this reminder from history?"
        onClickCancel={() => setConfirmModalOpen(false)}
        onClickConfirm={DeleteReminderConfirmation}
      />
    </>
  );
};

export const classicRecipesJP = [
  {
    id: "J4pK9sF2wN",
    recipeUrl: "",
    name: "寿司 (Sushi)",
    directions:
      "<ol><li>準備する具材：新鮮な魚（マグロ、サケ、エビなど）、しょうが、わさび、しょうゆ、すし飯。</li><li>すし飯を握る：すし飯を手に水をつけながら握り、上に魚をのせます。</li><li>切る：寿司を切り分け、お皿に盛り付けます。</li><li>おたまでしょうゆをつけながら食べます。わさびやしょうがも添えてお楽しみください。</li></ol>",
    days: [],
    tags: [
      { text: "主菜", color: "indigo" },
      { text: "日本料理", color: "indigo" },
      { text: "寿司", color: "indigo" },
    ],
    ingredients: [
      { name: "新鮮な魚（マグロ、サケ、エビなど）", measurement: "お好みで" },
      { name: "しょうが", measurement: "適量" },
      { name: "わさび", measurement: "適量" },
      { name: "しょうゆ", measurement: "適量" },
      { name: "すし飯", measurement: "お好みで" },
    ],
  },
  {
    id: "R3pA6kE1wS",
    recipeUrl: "",
    name: "天ぷら (Tempura)",
    directions:
      "<ol><li>材料の下ごしらえ：新鮮な野菜（キャベツ、エビ、かぼちゃなど）と天ぷら粉を用意します。</li><li>油を熱します：中火でサラダ油を熱し、約170°Cにします。</li><li>天ぷら粉を作ります：氷水を使い、天ぷら粉をさっくり混ぜます。</li><li>揚げます：野菜やエビを天ぷら粉にくぐらせ、熱した油で揚げます。きつね色になるまで揚げます。</li><li>油をきります：キッチンペーパーで余分な油を取り除きます。</li><li>塩と天つゆでお楽しみください。</li></ol>",
    days: [],
    tags: [
      { text: "主菜", color: "indigo" },
      { text: "日本料理", color: "indigo" },
      { text: "天ぷら", color: "indigo" },
    ],
    ingredients: [
      {
        name: "新鮮な野菜（キャベツ、エビ、かぼちゃなど）",
        measurement: "お好みで",
      },
      { name: "サラダ油", measurement: "適量" },
      { name: "天ぷら粉", measurement: "150g" },
      { name: "氷水", measurement: "50ml" },
      { name: "塩", measurement: "お好みで" },
      { name: "天つゆ", measurement: "お好みで" },
    ],
  },
  {
    id: "Y2zP7kW4rT",
    recipeUrl: "",
    name: "味噌汁 (Miso Soup)",
    directions:
      "<ol><li>材料の下ごしらえ：具材として、わかめ、豆腐、長ねぎ、だし、味噌を用意します。</li><li>だしを作ります：水とだしの素を鍋に入れ、中火で温めます。</li><li>具材を加えます：わかめ、豆腐、長ねぎをだしに加え、煮ます。</li><li>味噌を溶かします：味噌を少しずつ加え、溶かします。味を調整します。</li><li>完成：器に注ぎ、熱いうちにお楽しみください。</li></ol>",
    days: [],
    tags: [
      { text: "スープ", color: "indigo" },
      { text: "日本料理", color: "indigo" },
      { text: "味噌汁", color: "indigo" },
    ],
    ingredients: [
      { name: "わかめ", measurement: "適量" },
      { name: "豆腐", measurement: "1/2 ブロック" },
      { name: "長ねぎ", measurement: "1本" },
      { name: "だしの素", measurement: "1袋" },
      { name: "味噌", measurement: "大さじ2" },
      { name: "水", measurement: "600ml" },
    ],
  },
  {
    id: "K1tC5nD7gR",
    recipeUrl: "",
    name: "親子丼 (Oyakodon)",
    directions:
      "<ol><li>材料の下ごしらえ：鶏もも肉を薄切りにし、たまごを溶いておきます。</li><li>だしを作ります：だしの素を水に溶かし、鍋で温めます。</li><li>具材を煮ます：鶏肉をだしに加え、鶏肉が白くなるまで煮ます。</li><li>たまごを加えます：たまごを鶏肉の上に流し入れ、蓋をして蒸し煮します。</li><li>完成：ご飯の上に親子丼を盛り付け、青ねぎを散らしてお楽しみください。</li></ol>",
    days: [],
    tags: [
      { text: "主菜", color: "indigo" },
      { text: "日本料理", color: "indigo" },
      { text: "親子丼", color: "indigo" },
    ],
    ingredients: [
      { name: "鶏もも肉", measurement: "150g" },
      { name: "たまご", measurement: "2個" },
      { name: "だしの素", measurement: "1袋" },
      { name: "水", measurement: "200ml" },
      { name: "青ねぎ", measurement: "適量" },
    ],
  },
  {
    id: "M9zP5kE8jR",
    recipeUrl: "",
    name: "焼きそば (Yakisoba)",
    directions:
      "<ol><li>材料の下ごしらえ：豚肉、野菜（キャベツ、にんじん、きのこなど）を切ります。</li><li>焼きそば麺をゆでます：麺を茹でて冷水で洗い、水気を切ります。</li><li>炒めます：フライパンで豚肉と野菜を炒め、焼きそば麺を加えて炒めます。</li><li>ソースをかけます：焼きそばソースを加え、よく混ぜます。</li><li>完成：お皿に盛り付け、青ねぎや紅しょうがをトッピングしてお楽しみください。</li></ol>",
    days: [],
    tags: [
      { text: "主菜", color: "indigo" },
      { text: "日本料理", color: "indigo" },
      { text: "焼きそば", color: "indigo" },
    ],
    ingredients: [
      { name: "豚肉", measurement: "100g" },
      { name: "キャベツ", measurement: "1/4ヘッド" },
      { name: "にんじん", measurement: "1本" },
      { name: "きのこ", measurement: "適量" },
      { name: "焼きそば麺", measurement: "150g" },
      { name: "焼きそばソース", measurement: "大さじ2" },
      { name: "青ねぎ", measurement: "適量" },
      { name: "紅しょうが", measurement: "適量" },
    ],
  },
  {
    id: "N8zA1kD4wY",
    recipeUrl: "",
    name: "おにぎり (Onigiri)",
    directions:
      "<ol><li>材料の下ごしらえ：ごはんを炊き、具材（梅干し、鮭、昆布など）を用意します。</li><li>おにぎりを作ります：手に水をつけ、ごはんを取り、中に具材を入れておにぎりを作ります。</li><li>海苔で巻きます：おにぎりを海苔で巻きます。</li><li>完成：ラップで包み、持ち運びやすくしてお楽しみください。</li></ol>",
    days: [],
    tags: [
      { text: "おかず", color: "indigo" },
      { text: "日本料理", color: "indigo" },
      { text: "おにぎり", color: "indigo" },
    ],
    ingredients: [
      { name: "ごはん", measurement: "適量" },
      { name: "梅干し", measurement: "1個" },
      { name: "鮭", measurement: "1切れ" },
      { name: "昆布", measurement: "適量" },
      { name: "海苔", measurement: "適量" },
    ],
  },
  {
    id: "H6zO9kI2jR",
    recipeUrl: "",
    name: "とんかつ (Tonkatsu)",
    directions:
      "<ol><li>材料の下ごしらえ：豚肉を薄切りにし、小麦粉、卵、パン粉を用意します。</li><li>豚肉を調理します：豚肉を小麦粉、卵、パン粉の順につけ、中火の油で揚げます。</li><li>切り分けます：とんかつを切り分け、キャベツと一緒に盛り付けます。</li><li>ソースをかけます：とんかつソースをかけてお楽しみください。</li></ol>",
    days: [],
    tags: [
      { text: "主菜", color: "indigo" },
      { text: "日本料理", color: "indigo" },
      { text: "とんかつ", color: "indigo" },
    ],
    ingredients: [
      { name: "豚肉", measurement: "4枚" },
      { name: "小麦粉", measurement: "適量" },
      { name: "卵", measurement: "2個" },
      { name: "パン粉", measurement: "適量" },
      { name: "油", measurement: "適量" },
      { name: "とんかつソース", measurement: "適量" },
      { name: "キャベツ", measurement: "適量" },
    ],
  },
  {
    id: "T4zN8kU6kA",
    recipeUrl: "",
    name: "お好み焼き (Okonomiyaki)",
    directions:
      "<ol><li>材料の下ごしらえ：キャベツ、豚肉、天かす、紅しょうがを切ります。生地を作ります。</li><li>焼きます：フライパンで生地を流し入れ、キャベツと豚肉をのせて両面を焼きます。</li><li>ソースをかけます：お好み焼きソースをかけ、マヨネーズを絞り、紅しょうがをトッピングします。</li><li>完成：お皿に盛り付け、青ねぎとかつお節を散らしてお楽しみください。</li></ol>",
    days: [],
    tags: [
      { text: "主菜", color: "indigo" },
      { text: "日本料理", color: "indigo" },
      { text: "お好み焼き", color: "indigo" },
    ],
    ingredients: [
      { name: "キャベツ", measurement: "1/4ヘッド" },
      { name: "豚肉", measurement: "100g" },
      { name: "天かす", measurement: "適量" },
      { name: "紅しょうが", measurement: "適量" },
      { name: "お好み焼きソース", measurement: "適量" },
      { name: "マヨネーズ", measurement: "適量" },
      { name: "青ねぎ", measurement: "適量" },
      { name: "かつお節", measurement: "適量" },
    ],
  },
  {
    id: "S2zK6nT8kY",
    recipeUrl: "",
    name: "唐揚げ (Karaage)",
    directions:
      "<ol><li>材料の下ごしらえ：鶏もも肉を食べやすい大きさに切り、調味料を混ぜたものに漬け込みます。</li><li>揚げます：鍋に油を熱し、鶏肉を揚げます。</li><li>完成：お皿に盛り付け、レモンを添えてお楽しみください。</li></ol>",
    days: [],
    tags: [
      { text: "主菜", color: "indigo" },
      { text: "日本料理", color: "indigo" },
      { text: "唐揚げ", color: "indigo" },
    ],
    ingredients: [
      { name: "鶏もも肉", measurement: "400g" },
      { name: "しょうゆ", measurement: "大さじ2" },
      { name: "酒", measurement: "大さじ2" },
      { name: "しょうが", measurement: "1片（すりおろし）" },
      { name: "にんにく", measurement: "1片（すりおろし）" },
      { name: "小麦粉", measurement: "適量" },
      { name: "揚げ油", measurement: "適量" },
      { name: "レモン", measurement: "お好みで" },
    ],
  },
  {
    id: "R9zP4kD6jT",
    recipeUrl: "",
    name: "もんじゃ焼き (Monjayaki)",
    directions:
      "<ol><li>材料の下ごしらえ：キャベツ、もんじゃ粉、だし、ネギ、イカ、豚バラ肉を切ります。</li><li>もんじゃ粉を混ぜます：もんじゃ粉とだしを混ぜて生地を作ります。</li><li>具材を加えます：生地にキャベツ、ネギ、イカ、豚バラ肉を加えて炒めます。</li><li>完成：お皿に盛り付け、ソースをかけてお楽しみください。</li></ol>",
    days: [],
    tags: [
      { text: "主菜", color: "indigo" },
      { text: "日本料理", color: "indigo" },
      { text: "もんじゃ焼き", color: "indigo" },
    ],
    ingredients: [
      { name: "キャベツ", measurement: "1/4ヘッド" },
      { name: "もんじゃ粉", measurement: "200g" },
      { name: "だし", measurement: "400ml" },
      { name: "ネギ", measurement: "2本" },
      { name: "イカ", measurement: "適量" },
      { name: "豚バラ肉", measurement: "適量" },
      { name: "もんじゃ焼きソース", measurement: "適量" },
    ],
  },
];

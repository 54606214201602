export const goods = [
  { name: "Kona Coffee", basePrice: 50 },
  { name: "Macadamia Nuts", basePrice: 15 },
  { name: "Hawaiian Quilts", basePrice: 15 },
  { name: "Pineapples", basePrice: 20 },
  { name: "Aloha Shirts", basePrice: 60 },
  { name: "Surfboards", basePrice: 340 },
  { name: "Tropical Flowers", basePrice: 25 },
  { name: "Koa Wood", basePrice: 40 },
  // { name: "Molokai Sweet Bread", basePrice: 15 },
  { name: "Sea Salt", basePrice: 10 },
  { name: "Ukuleles", basePrice: 235 },
  { name: "Niihau Shell", basePrice: 80 },
  { name: "Black Pearls", basePrice: 55 },

  // ... add other goods
];

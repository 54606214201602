import { ItemModel } from "Lists";
//import { useCategorySubscription } from "../../../Lists/useCatgorySubscription";
import { useHistory } from "react-router-dom";
import { Category } from "./Category";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import classes from "./Categories.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faListCheck,
  faCheck,
  faEye,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export interface CategoriesProps {
  addedItems: ItemModel[];
  showCompleted: boolean;
  showAllCat: boolean;
  onClickAddCategoryItem: (id: string | undefined) => void;
  onClickItem: (item: ItemModel) => void;
  onClickItemCategory: (item: ItemModel) => void;
  isDarkMode: boolean;
  addNewItem?: () => void;
  categories: any[];
}

export const Categories = (props: CategoriesProps) => {
  const {
    addedItems,
    showCompleted,
    showAllCat,
    isDarkMode,
    onClickAddCategoryItem,
    onClickItem,
    onClickItemCategory,
    addNewItem,
    categories,
  } = props;

  // const categories = useCategories()

  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const goToProfile = () => {
    history.push("/settings");
  };

  // Check if the current URL matches '/hello'
  const showNoResultsHelp = location.pathname === "/";

  const uncategorisedItems = addedItems.filter(
    (i) => !i.category || categories.findIndex((c) => c.id === i.category) < 0
  );

  const groupedItems = [...categories]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((c) => ({
      ...c,
      items: addedItems
        .filter((i) => i.category === c.id)
        .sort((a, b) => a.lowerName.localeCompare(b.lowerName)),
    }));

  if (uncategorisedItems.length > 0) {
    groupedItems.splice(0, 0, {
      id: "-1",
      name: t("generic-list-general"),
      order: -1,
      colour: "var(--colour-uncategorised)",
      items: uncategorisedItems.sort((a, b) =>
        a.lowerName.localeCompare(b.lowerName)
      ),
    });
  }

  if (!showCompleted) {
    groupedItems.forEach((c) => {
      c.items = c.items.filter((i) => !i.completed);
    });
  }

  return (
    <div className={classes.list}>
      {groupedItems.map((c) => (
        <Category
          key={c.id}
          category={c}
          showAllCat={showAllCat}
          onClickAddCategoryItem={onClickAddCategoryItem}
          onClickItem={onClickItem}
          onClickItemCategory={onClickItemCategory}
          isDarkMode={isDarkMode}
          categories={categories}
        />
      ))}
    </div>
  );
};

import { useState, useEffect } from "react";

import { useAuth } from "Authentication";
import { Link } from "react-router-dom";

import { DiApple, DiAndroid } from "react-icons/di";

import { LanguageSwitcher } from "Locales/LanguageSwitcher";

import "./ImageSlider.css";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "Common";
import { ConfirmationModal } from "Common";
import { QuoteBox } from "./QuoteBox";
import { FAQ } from "Splash/RegisteredSplash/sections/FAQ";

export const AnonymousSplash = () => {
  const {
    //  loginApple,
    //  loginFB,
    //  loginGO,
    //  loginTW,
    //  loginLink,
    //  loginPhone,
    loginEmailPassword,
    createEmailPassword,
    resetEmailPassword,
    loginGuest,
  } = useAuth();
  const [isButtonSelected, setIsButtonSelected] = useState(false);
  // const [errorMessages, setErrorMessages] = useState({});
  //const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("returningUser");
  const [isCreateNewUserOpen, setIsCreateNewUserOpen] = useState(false);
  const [emailPasswordReset, setEmailPasswordReset] = useState("");
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isNative, setIsNative] = useState(true);
  const [isIOS, setIsIOS] = useState(false);
  const [isExistingUser, setIsExistingUser] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmationGuest, setConfirmationGuest] = useState(false);
  const [isAlohaPlanner, setIsAlohaPlanner] = useState(false);
  const [isPromoVideo, setIsPromoVideo] = useState(true);
  const { t } = useTranslation();
  const handleSubmit = (event) => {
    // Prevent page reload
    event.preventDefault();

    if (!isCreateNewUserOpen) {
      loginEmailPassword(email, password);
    } else {
      createEmailPassword(email, password);
    }
    //setIsCreateNewUserOpen(false)
  };

  /* const images = [
    {
      originalTitle: "",
      original: "./img/3-phones-dn.png",
      //   description: 'Habit Tracker',
    },
    {
      originalTitle: "Build Good Habits",
      original: "./img/habittracker.png",
      // description: 'Start building good habits',
    },
    {
      original: "./img/journal_dn.png",
      //  description: 'Quickly record your day and review it later',
    },

    {
      original: "./img/shopping.png",
      // description: 'Create and share shopping list',
    },
  ]; */

  const lostPasswordReset = (e) => {
    e.preventDefault();
    resetEmailPassword(emailPasswordReset);
    setIsPasswordReset(false);
    setEmailPasswordReset("");
  };

  const CloseModal = () => {
    setIsCreateNewUserOpen(false);
  };

  const showVideo = () => {
    if ((window as any).isNative) {
      // Post the message back to expo

      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "ExternalRecipeURL",
          url: "https://youtu.be/d9xfv4nMBDQ",
        })
      );
    } else {
      // window.location.href = recipe.recipeUrl
      window.open("https://youtu.be/d9xfv4nMBDQ", "_blank");
    }
  };

  useEffect(() => {
    if ((window as any).isNative) {
      setIsNative(true);
    } else {
      setIsNative(false);
    }

    if ((window as any).isAloha) {
      setIsAlohaPlanner(true);
    }

    if ((window as any).isIOS) {
      setIsIOS(true);
    } else {
      setIsIOS(false);
    }

    return () => {};
  }, []);

  return (
    <article
      style={{
        fontFamily: "Ubuntu",
        lineHeight: 1.5,
        boxSizing: "inherit",
        overflowY: "hidden",
      }}
    >
      <div style={{ marginTop: "0px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            // onClick={() => loginGuest()}
            src="./img/alohaplannerheaderlr.jpg" // Replace with the actual path to your image
            alt="Aloha Planner Image"
            style={{
              width: "100%", // Make the image fill the entire width
              height: "auto", // Maintain aspect ratio
            }}
          />
          <div
            style={{
              fontWeight: "bold",
              fontSize: 28,
              textAlign: "center",
              color: "black",
              padding: 20,
            }}
          >
            {isAlohaPlanner
              ? "Welcome to the Aloha Daily Planner"
              : t("login-tagline")}
          </div>

          {/*}    <p
            style={{
              fontSize: 18,
              textAlign: "center",
              color: "black",
              marginTop: -20,
            }}
          >
            {isAlohaPlanner ? "Free" : ""} Daily Planner, Grocery List Manager,
            Recipe Manager, Journal, Habit Tracker and Much More!
          </p> */}

          <p
            style={{
              fontSize: 24,
              padding: 10,
              textAlign: "center",
              color: "black",
              marginTop: -10,
            }}
          >
            {t("login-intro-1")}
          </p>

          {/*}  <Modal
            isOpen={isPromoVideo}
            onRequestClose={CloseModal}
            style={{ overlay: { zIndex: 100 } }}
          >
            <div style={{ filter: "invert(100%)" }}>
              <p
                onClick={() => setIsPromoVideo(false)}
                style={{
                  textAlign: "center",
                  color: "blue",
                  textDecoration: "underline",
                  fontSize: 36,
                  marginTop: -20,
                  cursor: "pointer",
                }}
              >
                Close
              </p>

              <YoutubeEmbed embedId="Fij4yyo0YcU" />
              <p
                onClick={() => setIsPromoVideo(false)}
                style={{
                  textAlign: "center",
                  color: "blue",
                  textDecoration: "underline",
                  fontSize: 36,
                  marginBottom: 80,
                  cursor: "pointer",
                }}
              >
                Close
              </p>
            </div>
              </Modal>*/}

          {/*}  <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "blue",
                cursor: "pointer",
                fontSize: 22,
                fontWeight: "bold",
              }}
              onClick={() => setConfirmModalOpen(true)}
            >
              Change Language? Click here.
            </div> /*}

            {/*}  <YoutubeEmbed embedId="PUGKD1Vt5As" />*/}
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          id="recaptcha-container"
        ></div>

        <div style={{ paddingBottom: 0, marginTop: 0 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 0,
              border: 1,
              borderColor: "black",
              borderRadius: 20,
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 0,
              paddingBottom: 0,
              flexDirection: "column",
            }}
          >
            <h2
              style={{
                textAlign: "center",
                fontSize: 22,
                fontWeight: "bold",
                marginTop: 10,
              }}
            >
              {!isPasswordReset ? "" : t("login-passwordreset-label")}
            </h2>

            {!isPasswordReset ? (
              <>
                <div
                  className="metroButton"
                  onClick={() => loginGuest()}
                  style={{
                    textAlign: "center",
                    color: "white",
                    fontSize: 30,
                    fontWeight: "bold",
                    cursor: "pointer",
                    backgroundColor: "violet",
                    borderRadius: 20,
                    padding: 20,
                    marginTop: -20,
                    // textDecoration: "underline",
                  }}
                >
                  <div>Start Here</div>
                  <div
                    style={{
                      textAlign: "center",
                      color: "white",
                      fontSize: 18,

                      // textDecoration: "underline",
                    }}
                  >
                    New Users
                  </div>
                </div>

                <div
                  onClick={() => setIsExistingUser(true)}
                  style={{
                    textAlign: "center",
                    fontSize: 22,
                    paddingTop: 20,
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "blue",
                    // border: 1,
                    // marginTop: -20,
                  }}
                >
                  {t("login-signin-label")}
                </div>
                <br />
                {isExistingUser ? (
                  <div className="game-modal-overlay">
                    <div className="game-modal">
                      <div>ALOHA</div>
                      <form onSubmit={handleSubmit}>
                        <div>
                          <br />
                          <label> {t("login-input-email")} </label>
                          <input
                            style={{ width: "90%" }}
                            type="email"
                            name="email"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div style={{ paddingTop: 15 }}>
                          <label> {t("login-input-password")} </label>
                          <input
                            style={{ width: "90%" }}
                            type="password"
                            name="pass"
                            required
                            minLength={6}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        <div>
                          {userType === "newUser" ? (
                            <input type="submit" value="Register" />
                          ) : null}
                          {userType === "returningUser" ? (
                            <input
                              type="submit"
                              value={t("login-button-signin")}
                              style={{
                                padding: 20,

                                borderRadius: 30,
                                fontFamily: "inherit",
                              }}
                            />
                          ) : null}
                        </div>
                        <p
                          style={{
                            textAlign: "center",
                            color: "blue",
                            fontSize: 14,
                            fontWeight: "bold",
                            cursor: "pointer",
                            //marginTop: -1,
                          }}
                          onClick={() => setIsPasswordReset(true)}
                        >
                          {t("login-button-resetpassword")}
                        </p>
                        {/*}     <p
                    style={{
                      textAlign: "center",
                      color: "white",
                      fontSize: 18,
                      fontWeight: "bold",
                      cursor: "pointer",
                      backgroundColor: "purple",
                      borderRadius: 30,
                      padding: 20,
                      // textDecoration: "underline",
                    }}
                    onClick={() => setIsCreateNewUserOpen(true)}
                  >
                    {t("login-button-createaccount")}
                  </p> */}
                      </form>
                      <button
                        onClick={() => setIsExistingUser(false)}
                        className="game-close-button"
                        style={{ backgroundColor: "blue" }}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                ) : null}
                <div
                  style={{
                    border: 2,
                    color: "grey",
                    width: "100%",
                    borderStyle: "inherit",
                  }}
                />
                {/*}
                {!isNative ? (
                  <div
                    className="image-container-screenshots"
                    style={{ paddingTop: 20 }}
                  >
                    <img
                      src="./img/alohascreenshots.jpg"
                      alt="Descriptive Alt Text"
                    />
                  </div>
                ) : null}
                <QuoteBox />*/}

                {/* Removing App store buttons

                {!isNative ? (
                  <div
                    style={{
                      paddingTop: 20,
                      textAlign: "center",
                      paddingBottom: 20,
                    }}
                  >
                    <h3 style={{ fontSize: 18 }}>
                      {" "}
                      {t("login-availableon-label")}
                    </h3>
                    <Link
                      to={{
                        pathname:
                          "https://apps.apple.com/us/app/dope-notes-life-organizer/id1631683070",
                      }}
                      target="_blank"
                    >
                      {" "}
                      <div
                        style={{
                          padding: 10,
                          fontSize: 16,
                          marginTop: 0,
                          color: "white",
                          borderRadius: 10,
                          textAlign: "center",
                          backgroundColor: "blue",
                          fontWeight: "bold",
                          width: 200,
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        <DiApple /> Apple IOS{" "}
                      </div>
                    </Link>
                    <Link
                      to={{
                        pathname:
                          "https://play.google.com/store/apps/details?id=com.holmgren.dopenotes",
                      }}
                      target="_blank"
                    >
                      {" "}
                      <div
                        style={{
                          padding: 10,
                          fontSize: 16,
                          marginTop: 20,
                          color: "white",
                          borderRadius: 10,
                          textAlign: "center",
                          backgroundColor: "blue",
                          fontWeight: "bold",
                          width: 200,
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        <DiAndroid /> Google Android
                      </div>
                    </Link>
                  </div>
                ) : null}
                      */}
              </>
            ) : null}
            {isPasswordReset ? (
              <form onSubmit={lostPasswordReset}>
                <input
                  style={{ width: "95%" }}
                  placeholder="name@email.com"
                  type="email"
                  name="email"
                  value={emailPasswordReset}
                  required
                  onChange={(e) => setEmailPasswordReset(e.target.value)}
                />
                <input type="submit" value={t("login-button-reset")} />
                <p
                  onClick={() => setIsPasswordReset(false)}
                  style={{
                    textAlign: "center",
                    color: "blue",
                    fontSize: 16,
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  {t("login-button-back")}
                </p>
              </form>
            ) : null}
          </div>
        </div>
        <FAQ />
        <LanguageSwitcher />
        <p
          style={{ marginBottom: -1, fontWeight: "bold", textAlign: "center" }}
        >
          Made with Aloha in Hawai'i &#127796; 2023
        </p>

        {isButtonSelected ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 40,
            }}
          >
            {" "}
            <button
              style={{ width: 150 }}
              onClick={() => setIsButtonSelected(false)}
            >
              <h4 style={{ textAlign: "center" }}> {t("login-button-back")}</h4>
            </button>
          </div>
        ) : null}

        <section>{/*   <Features />*/}</section>
      </div>
      <Modal isOpen={isCreateNewUserOpen} onRequestClose={CloseModal}>
        <Modal.Body>
          <div style={{ padding: 40, fontFamily: "Ubuntu" }}>
            <Modal.Header>
              <h2> {t("login-createaccount-label")}</h2>
            </Modal.Header>

            <div>
              <form onSubmit={handleSubmit}>
                <div style={{ paddingBottom: 20 }}></div>

                <div>
                  <label>{t("login-input-email")}</label>
                  <input
                    type="email"
                    name="email"
                    style={{ width: "98%" }}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div style={{ paddingTop: 10 }}>
                  <label>
                    {t("login-input-password")}{" "}
                    <span style={{ fontSize: 16, fontWeight: "normal" }}>
                      {" "}
                      {t("login-input-password-helper")}
                    </span>
                  </label>
                  <input
                    type="password"
                    name="pass"
                    style={{ width: "98%" }}
                    required
                    minLength={4}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <div style={{ marginTop: 10 }}>
                  <button
                    type="submit"
                    value="Register"
                    style={{
                      paddingTop: 20,
                      paddingBottom: 20,
                      borderRadius: 30,
                      backgroundColor: "limegreen",
                      color: "black",
                      fontWeight: "bold",
                      width: "100%",
                      fontSize: 20,
                      fontFamily: "inherit",
                      border: 0,
                      cursor: "pointer",
                    }}
                  >
                    {t("login-button-register")}
                  </button>
                </div>
              </form>
              <br />
              <hr />

              <div style={{ paddingTop: 10 }} />
              <button
                onClick={() => loginGuest()}
                style={{
                  paddingTop: 20,
                  paddingBottom: 20,
                  borderRadius: 30,
                  backgroundColor: "violet",
                  color: "black",
                  width: "100%",
                  fontSize: 20,
                  fontFamily: "inherit",
                  border: 0,
                  cursor: "pointer",
                }}
              >
                {t("login-button-guestlogin")}
              </button>

              <div style={{ paddingTop: 10 }} />
              <button
                onClick={CloseModal}
                style={{
                  paddingTop: 20,
                  paddingBottom: 20,
                  borderRadius: 30,
                  backgroundColor: "coral",
                  color: "black",
                  width: "100%",
                  fontSize: 20,
                  fontFamily: "inherit",
                  border: 0,
                  cursor: "pointer",
                }}
              >
                {t("login-button-existinguser")}
              </button>
              {isAlohaPlanner ? (
                <p style={{ fontSize: 14, marginTop: 0, paddingTop: 20 }}>
                  <br />*{t("login-sellnodata-text")}
                  <br />*{t("login-guestlogin-text")}
                  <br />*{t("login-removeads-text")}
                  <br />*{t("login-tracking-text")}
                </p>
              ) : (
                <p style={{ marginTop: 0, paddingTop: 20 }}>
                  *{t("login-30days-text")} <br />*
                  {t("login-nocreditcard-text")}
                  <br />*{t("login-sharing-text")}
                  <br />*{t("login-sellnodata-text")}
                  {/*}  <br />*{t("login-nobannerads-text")}*/}
                  <br />*{t("login-subscriptions-text")}
                  <br />*{t("login-cancel-text")}
                </p>
              )}
              {isAlohaPlanner ? (
                ""
              ) : (
                <>
                  <hr />

                  {isNative ? (
                    <div>
                      {isIOS ? (
                        <p>
                          Apple/IOS EULA: <br />
                          <a
                            href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
                            target="_blank"
                          >
                            https://www.apple.com/legal/internet-services/itunes/dev/stdeula/
                          </a>
                        </p>
                      ) : (
                        <>
                          <p>
                            Apple/IOS EULA: <br />
                            <a
                              href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
                              target="_blank"
                            >
                              https://www.apple.com/legal/internet-services/itunes/dev/stdeula/
                            </a>
                          </p>
                          <p>
                            Google/Android:
                            <br />
                            <a
                              href="https://play.google.com/intl/en_us/about/play-terms/index.html"
                              target="_blank"
                            >
                              https://play.google.com/intl/en_us/about/play-terms/index.html
                            </a>
                          </p>
                        </>
                      )}
                    </div>
                  ) : null}
                </>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 0,
                }}
              >
                <div>
                  <p
                    style={{
                      textAlign: "center",
                      color: "blue",
                      textDecoration: "underline",
                    }}
                  >
                    <a
                      style={{ color: "blue" }}
                      href="https://alohaplanner.app/terms"
                    >
                      Terms
                    </a>{" "}
                    |
                    <a
                      style={{ color: "blue" }}
                      href="https://alohaplanner.app/privacy"
                    >
                      {" "}
                      Privacy
                    </a>{" "}
                    |
                    <a
                      style={{ color: "blue" }}
                      href="https://alohaplanner.app/cookiepolicy"
                    >
                      {" "}
                      Cookie
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ConfirmationModal
        isOpen={confirmationGuest}
        text={t("login-guestlogin-confirmation")}
        onClickCancel={() => setConfirmationGuest(false)}
        onClickConfirm={() => {
          loginGuest();
          setIsButtonSelected(true);
        }}
      />
    </article>
  );
};

import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import {
  //Link,
  //Route,
  //useRouteMatch,
  useHistory,
  // useLocation,
} from "react-router-dom";
import { Modal } from "Common";
//import "react-big-calendar/lib/css/react-big-calendar.css";
import DatePicker from "react-date-picker";
import moment from "moment";
import "react-date-picker/dist/DatePicker.css";
import { AddPlanner } from "./AddPlanner";
import { useTranslation } from "react-i18next";
import { BannerAd } from "GoogleAdSense/BannerAd";

const localizer = momentLocalizer(moment);

export const SimplePlanner = ({ data, isExpired }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedStart, setSelectedStart] = useState(new Date());
  const [selectedEnd, setSelectedEnd] = useState(new Date());
  const { t } = useTranslation();
  const history = useHistory();
  // const [showEvents, setShowEvents] = useState(false);

  const [events, setEvents] = useState(data);
  const [isAddingNew, setIsAddingNew] = useState(false);

  const emptyEvent = {
    note: "",
    time: "17:00",
    name: "",
    isTask: false,
    duration: { lable: "15 Minutes", value: 15 },
    isRepeats: false,
    repeatValue: "none",
    repeatMonthlyValue: "day",
    repeatWeeklyIntervalValue: 1,

    days: [0, 1, 2, 3, 4, 5, 6],
  };

  const convertDate = (data) => {
    //convert habit start and end to epoch for sorting
    var date = new Date(data);
    date = date.getTime();
    //  console.log("what is new date", date);
    return date;
  };

  const close = () => {
    setIsAddingNew(false);
  };

  const addNewEvent = () => {
    setIsAddingNew(true);
  };

  const getTime = (data) => {
    //console.log("what is data", data);

    // Check if data is in epoch format
    const isEpoch = data.seconds !== undefined;

    // Convert data to Date object
    const date = isEpoch ? new Date(data.seconds * 1000) : new Date(data);

    // Get time string
    const timeString = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    return timeString;
  };

  const [showEvents, setShowEvents] = useState(false);
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedStart(date);
    setSelectedEnd(date);
    setShowEvents(true);
  };

  const handleBackButtonClick = () => {
    const newDate = moment(selectedDate).subtract(1, "days").toDate();
    handleDateChange(newDate);
  };

  const handleClickItem = (event) => {
    //  console.log(event);
    if (event.isTask != undefined) {
      history.push(`planner/${event.id}`);
    } else if (event.isToDo) {
      history.push(`todo`);
    } else {
      history.push(`habits/${event.id}`);
    }

    // setCount(count + 1);
  };

  const handleForwardButtonClick = () => {
    const newDate = moment(selectedDate).add(1, "days").toDate();
    handleDateChange(newDate);
  };

  const renderEvents = () => {
    // Render events for the selected date
    const selectedEvents = events.filter(
      (event) =>
        moment(event.start).isSame(selectedDate, "day") ||
        moment(event.end).isSame(selectedDate, "day") ||
        (moment(event.start).isBefore(selectedDate, "day") &&
          moment(event.end).isAfter(selectedDate, "day"))
    );

    selectedEvents.sort((a, b) => {
      const startDateA = convertDate(a.start);
      const startDateB = convertDate(b.start);
      return startDateA - startDateB;
    });
    if (selectedEvents.length > 0) {
      return selectedEvents.map((event, index) => (
        <div
          key={index}
          onClick={() => handleClickItem(event)}
          style={{
            // margin: 10,
            color: "black",
            display: "flex",
            flexDirection: "column",
            borderStyle: "groove",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            userSelect: "none",
            backgroundColor:
              event.color !== undefined ? event.color : "lavender",
            padding: 10,
            borderRadius: 5,
          }}
        >
          <div
            style={{
              fontSize: 12,
              //  width: 100,
              paddingRight: 20,
            }}
          >
            {getTime(
              event.startDate != undefined ? event.startDate : event.start
            )}
            {" - "}
            {getTime(event.endDate != undefined ? event.endDate : event.end)}
            <br />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "right",
              justifyContent: "left",
              //   whiteSpace: "nowrap" /* keeps the text in a single line */,
              //  overflowX: "auto" /* adds horizontal scrollbar when needed */,
              //  overflowY: "hidden",
              textAlign: "left",
            }}
          >
            {" "}
            <div>{event.title}</div>
          </div>
        </div>
      ));
    } else {
      return (
        <div style={{ textAlign: "center", paddingTop: 20 }}>
          {t("planner-noevents-msg")} &#128542;
        </div>
      );
    }
  };

  useEffect(() => {
    // This will run only once when the component mounts
    if (data && data.length > 0) {
      setEvents(data);
      setShowEvents(true);
    } else {
      setShowEvents(false);
    }
  }, [data]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          // justifyContent: "space-between",
          paddingTop: 10,
          flexDirection: "column",
          marginBottom: "10px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ paddingBottom: 5 }}>
          <DatePicker
            onChange={handleDateChange}
            value={selectedDate}
            clearIcon={false}
          />
        </div>
        <div style={{ flexDirection: "column" }}>
          <button
            style={{
              fontSize: 20,
              backgroundColor: "lightblue",
              paddingLeft: 20,
              paddingRight: 20,

              // border: 1,
              // borderStyle: "groove",
              borderColor: "black",
            }}
            onClick={handleBackButtonClick}
          >
            {"<<<"}
          </button>{" "}
          <span style={{ paddingRight: 10 }} />
          <button
            style={{
              fontSize: 20,
              backgroundColor: "lightblue",
              paddingLeft: 20,
              paddingRight: 20,

              // border: 1,
              // borderStyle: "groove",
              borderColor: "black",
            }}
            onClick={handleForwardButtonClick}
          >
            {">>>"}
          </button>
        </div>
        <div
          style={{
            padding: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            onClick={addNewEvent}
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 5,
              paddingBottom: 5,
              borderRadius: 5,
              border: 0,
              cursor: "pointer",
              userSelect: "none",
              color: "white",
              backgroundColor: "purple",
              fontFamily: "inherit",
              fontSize: 16,
            }}
          >
            {t("planner-add-event-today")}
          </button>
        </div>
      </div>

      {showEvents && <div>{renderEvents()}</div>}
      <Modal
        isOpen={isAddingNew}
        onRequestClose={close}
        style={{ overlay: { zIndex: 100 } }}
      >
        <AddPlanner
          closeModal={close}
          start={selectedStart}
          end={0}
          isEdit={false}
          event={emptyEvent}
        />
      </Modal>
      <div style={{ paddingTop: 20 }} />
    </div>
  );
};

import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faClose,
  faImage,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import cx from "classnames/bind";
import { Button, Modal } from "Common";
import { EventModel } from "../models/events";
import {
  BtnBold,
  BtnItalic,
  Editor,
  BtnBulletList,
  HtmlButton,
  BtnNumberedList,
  BtnUnderline,
  BtnStrikeThrough,
  EditorProvider,
  Toolbar,
} from "react-simple-wysiwyg";
import classes from "./EventsModal.module.css";
import { useUserContext } from "Authentication";
import DateTimePicker from "react-datetime-picker";
//import Linkify from 'linkify-react';
import {
  getStorage,
  ref,
  deleteObject,
  getDownloadURL,
  uploadBytesResumable,
} from "@firebase/storage";

import { storage } from "Config";
//import TextareaAutosize from "react-textarea-autosize";
import { ConfirmationModal } from "Common";
import { CirclePicker, GithubPicker } from "react-color";
import { useTranslation } from "react-i18next";

const classnames = cx.bind(classes);

interface EventsModalAddNewProps {
  isOpen: boolean;
  event: EventModel | undefined;
  selectedStartTime: Date;
  isDarkMode: boolean;
  isOnline: boolean;
  close: () => void;
}

var today = new Date();

export const EventsModalAddNew = (props: EventsModalAddNewProps) => {
  const { isOpen, event, selectedStartTime, isDarkMode, isOnline, close } =
    props;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isAllDay, setIsAllDay] = useState(true);
  const [startTime, setStartTime] = useState(selectedStartTime);
  const [imageURL, setImageURL] = useState("");
  const [endTime, setEndTime] = useState(
    new Date(startTime.getTime() + 864000)
  );
  const [tempImage, setTempImage] = useState("");
  const [isAddingImage, setIsAddImage] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  // const [startTime, setStartTime] = useState(event.start.toString())
  // const [endTime, setEndTime] = useState(event.end.toString())
  const [addedBy, setAddedBy] = useState("");
  // const { deleteItem, removeItem, setItemFlag, setItemFavorite, updateItem } = useItems()
  const user = useUserContext();
  const { addNewEventModal, editCalendarColor } = useUserContext();
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  //color is snow #fffafa
  const [color, setColor] = useState("#fffafa");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  const [isIdeas, setIdeas] = useState(false);
  const [options, setOptions] = useState(false);
  const [imageCaption, setImageCaption] = useState("");
  const { t } = useTranslation();

  const ToggleIdeas = () => {
    isIdeas ? setIdeas(false) : setIdeas(true);
  };
  // var name = item?.name

  //console.log("What is the event", event);

  //console.log("selectStartTime", selectedStartTime)
  //console.log("startTime", startTime)
  //console.log("endtime", endTime)

  //Image Uploader

  const handleColorChangeComplete = (color, event) => {
    //	console.log("which color", color.hex)
    setColor(color.hex);
    //setIsColorPickerOpen(false)
  };

  const Newcolors = [
    "snow",
    "azure",
    "floralwhite",
    "#EB9694",
    "pink",
    "#FAD0C3",
    "#fef68a",
    "#C1E1C5",
    "#BEDADC",
    "#C4DEF6",
    "#BED3F3",
    "#D4C4FB",
    "plum",
  ];

  const [progress, setProgress] = useState(0);

  const formHandler = (e) => {
    e.preventDefault();
    setIsUploading(true);

    // Handle removing the temporary image if it exists
    if (tempImage !== "") {
      const storageRef = ref(storage, tempImage);
      deleteObject(storageRef)
        .then(() => {
          // File deleted successfully
          //   console.log("Removed temporary image:", tempImage);
        })
        .catch((error) => {
          // console.error("Error deleting temporary image:", error);
        });
    }

    // Get the selected file from the form
    const file = e.target.files[0];

    // Upload the file using the 'uploadFiles' function
    uploadFiles(file);
  };

  const uploadFiles = (file) => {
    // Set the temporary image URL
    setTempImage(`images/${user.user.id}/${startTime.toString()}/${file.name}`);

    // Create a reference to the file location in Cloud Storage
    const storageRef = ref(
      storage,
      `images/${user.user.id}/${startTime.toString()}/${file.name}`
    );

    // Upload the file to Cloud Storage
    const uploadTask = uploadBytesResumable(storageRef, file);

    // Monitor the upload task's progress and state changes
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error("Error uploading file: ", error);
        setIsUploading(false);
        setProgress(0);
      },
      () => {
        // Upload complete, get download URL
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setImageURL(url);
          setIsUploading(false);
          setProgress(0);
          setIsAddImage(false);

          setIsImage(true);
        });
      }
    );
  };

  //image uploader end

  const removeImage = () => {
    if (tempImage != "") {
      const storageRef = ref(storage, tempImage);
      deleteObject(storageRef)
        .then(() => {
          // File deleted successfully
          //  console.log("remove image", tempImage);
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
        });
    }
    setTempImage("");
    setImageURL("");
    setIsImage(false);
    setProgress(0);
    setIsUploading(false);
    // console.log("what is image at delete", imageURL)
  };

  const SetNewStartTime = (e) => {
    const newTime = e;

    setStartTime(newTime);

    const newEndTime = new Date(e.getTime() + 86400);
    // console.log("what Time after", newEndTime)
    setEndTime(newEndTime);
    // console.log("what Time", e)
  };

  const SetNewEndTime = (e) => {
    const newTime = e;
    if (newTime > startTime) {
      setEndTime(newTime);
      // console.log("what Time", e)
    }
  };

  useEffect(() => {
    // Prevents old values being displayed when modal fades out.
    if (isOpen) {
      setTitle("");
      setDescription("");
      setIsAllDay(true);
      setStartTime(selectedStartTime);
      setEndTime(new Date(selectedStartTime.getTime() + 864000));
      // setStartTime(event.start.toString())
      // setEndTime(event.end.toString())
      setAddedBy("");
    }
  }, [event, isOpen]);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (endTime > startTime) {
      addNewEventModal(
        title,
        description,
        startTime,
        endTime,
        isAllDay,
        imageURL,
        imageCaption,
        color
      );

      close();
    } else {
      window.alert(
        "Please check your dates?  The end date must be after the start date."
      );
    }
  }

  function handleIsAllDay() {
    setIsAllDay((f) => !f);
    //   event?.id && setIsAllDay(event.id, !isAllDay)
  }

  const CloseConfirmation = () => {
    close();
    setIsAddImage(false);
    setIsImage(false);
    setImageCaption("");
    removeImage();
    setProgress(0);
    setIsUploading(false);
    setConfirmModalOpen(false);
  };

  const toolbar = {
    container: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ color: [] }, { background: [] }], // add color and background tools
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };

  const modules = {
    toolbar: toolbar,
  };

  const handleContentChange = (value) => {
    setTitle(value);
    if (value.length > 11) {
      setEnableButton(true);
    } else {
      setEnableButton(false);
    }

    //  console.log("what is the length", value.length);
  };

  const textColor = isDarkMode ? "darkred" : "darkblue";

  const handleAddImage = () => {
    setIsAddImage(true);
    if ((window as any).isIOS) {
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "requestCameraPermission",
          // value: "on",
        })
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={CloseConfirmation}
      style={{ overlay: { zIndex: 100 } }}
    >
      <Modal.Body>
        <div
          style={{ padding: 0, filter: isDarkMode ? "" : "invert(100%)" }}
          data-openreplay-obscured
        >
          <div
            style={{
              marginTop: -20,
              paddingLeft: 10,
              paddingRight: 10,
              paddingBottom: 10,
              border: 1,
              borderColor: "grey",
              borderRadius: 10,
              backgroundColor: color,
              fontWeight: "bold",
              color: textColor,
              boxShadow: "2px 3px 3px grey, 0 0 5px #FCFBF4 inset",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "right",
                marginTop: 0,
                marginRight: -10,
              }}
            >
              {" "}
              <FontAwesomeIcon
                icon={faClose}
                size="1x"
                onClick={CloseConfirmation}
                style={{
                  marginTop: 10,
                  marginRight: 15,
                  cursor: "pointer",
                  color: "black",
                }}
              />
            </div>

            {isAddingImage ? (
              <div style={{ padding: 20, userSelect: "none" }}>
                <p>{t("journal-form-fileformats")} </p>
                <p style={{ marginTop: -15, paddingBottom: 20 }}>
                  {t("journal-form-filesize")}{" "}
                </p>
                <input
                  type="file"
                  accept="image/*"
                  className="input"
                  required
                  onChange={formHandler}
                  style={{
                    paddingBottom: 10,
                    fontSize: "inherit",
                    fontFamily: "inherit",
                  }}
                />

                <hr />

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <button
                    style={{
                      fontFamily: "inherit",
                      fontSize: "inherit",
                      fontWeight: "bold",
                      border: 0,
                      paddingLeft: 40,
                      paddingRight: 40,
                      color: "blue",
                      cursor: "pointer",
                      backgroundColor: "transparent",
                    }}
                    onClick={() => setIsAddImage(false)}
                  >
                    {t("common-button-cancel")}
                  </button>
                </div>
                {isUploading ? (
                  <p>
                    {t("journal-form-uploading")} {progress}%
                  </p>
                ) : null}
              </div>
            ) : null}

            {!isAddingImage ? (
              <form onSubmit={handleSubmit}>
                <h2 style={{ textAlign: "center", color: "black" }}>
                  {t("journal-log-title")}
                </h2>
                {!isIdeas ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: -10,
                    }}
                  >
                    {" "}
                    <button
                      style={{
                        backgroundColor: "#3e98c7",
                        color: "white",
                        padding: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                        borderColor: "white",
                        borderRadius: 40,
                        border: 0,
                      }}
                      onClick={ToggleIdeas}
                    >
                      {t("journal-form-ideas-button")}
                    </button>
                  </div>
                ) : (
                  <span />
                )}

                {isIdeas ? (
                  <div
                    style={{
                      display: "flex",
                      // width: "90%",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      marginTop: -10,
                    }}
                  >
                    <div
                      style={{
                        padding: 20,
                        borderRadius: 5,
                        border: 1,
                        //  backgroundColor: "lightyellow",
                      }}
                    >
                      <p
                        style={{
                          paddingBottom: 10,
                          fontSize: 18,
                          fontFamily: "inherit",
                          // lineHeight: 1.2,
                          fontWeight: "bold",
                          marginTop: -5,
                          lineHeight: 1.5,
                          textAlign: "left",
                        }}
                      >
                        * {t("journal-form-idea1")} <br />*{" "}
                        {t("journal-form-idea2")} <br />*{" "}
                        {t("journal-form-idea3")} <br />*{" "}
                        {t("journal-form-idea4")} <br />*{" "}
                        {t("journal-form-idea5")} <br />*{" "}
                        {t("journal-form-idea6")} <br />*{" "}
                        {t("journal-form-idea7")} <br />*{" "}
                        {t("journal-form-idea8")} <br />*{" "}
                        {t("journal-form-idea9")} <br />
                      </p>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          style={{
                            backgroundColor: "#3e98c7",
                            color: "white",
                            padding: 5,
                            borderRadius: 40,
                            paddingLeft: 10,
                            paddingRight: 10,
                            border: 0,
                          }}
                          onClick={ToggleIdeas}
                        >
                          {t("common-button-close")}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <span />
                )}
                <label
                  style={{
                    fontStyle: "inherit",
                    paddingTop: 20,
                    paddingLeft: 10,
                    paddingBottom: 20,
                    userSelect: "none",
                    color: "black",
                    textAlign: "left",
                    fontSize: "inherit",
                  }}
                >
                  {t("journal-form-text-label")}:{" "}
                  <span style={{ fontSize: 14 }}></span>
                </label>
                <div
                  style={{
                    display: "flex !important",
                    backgroundColor: "snow",
                    padding: 10,
                  }}
                >
                  <EditorProvider>
                    <Editor
                      value={title}
                      placeholder={t("journal-form-text-placeholder")}
                      containerProps={{
                        style: {
                          backgroundColor: "snow",
                          resize: "both",
                          overflow: "auto",
                        },
                      }}
                      onChange={(e) => setTitle(e.target.value)}
                    >
                      <Toolbar>
                        <BtnBold />
                        <BtnItalic />
                        <BtnUnderline />
                        <BtnBulletList />
                        <BtnNumberedList />
                        <BtnStrikeThrough />
                        <HtmlButton />
                      </Toolbar>
                    </Editor>
                  </EditorProvider>
                </div>

                {options ? (
                  <>
                    <div
                      style={{ paddingBottom: 15, paddingTop: 10, padding: 0 }}
                    >
                      {imageURL != "" || undefined || null ? (
                        <div className="polaroid">
                          <img
                            src={imageURL}
                            // width="100%"
                            // height="100%"
                            style={{
                              borderRadius: 10,
                              //  filter: "invert(100%)",
                            }}
                          />
                          <span
                            style={{
                              fontSize: 12,
                              fontFamily: "inherit",
                              color: "grey",
                            }}
                          >
                            {imageCaption}
                          </span>
                        </div>
                      ) : null}
                    </div>

                    {imageURL != "" || undefined || null ? (
                      <div style={{ paddingTop: 0, paddingBottom: 10 }}>
                        <label style={{ paddingTop: 10, paddingBottom: 5 }}>
                          {t("journal-form-captiontext")}
                        </label>
                        <input
                          type="text"
                          value={imageCaption}
                          style={{
                            fontSize: 16,
                            fontFamily: "inherit",
                            padding: 10,
                          }}
                          onChange={(e) => setImageCaption(e.target.value)}
                        />
                      </div>
                    ) : null}

                    {/* <label
                  style={{
                    fontStyle: "inherit",
                    paddingTop: 0,
                    paddingBottom: 20,
                  }}
                >
                  Optional: Additional Text
                </label>
                <div style={{ backgroundColor: "lightyellow", padding: 10 }}>
                  <ReactQuill
                    theme="snow"
                    modules={modules}
                    // style={{ backgroundColor: noteColor }}
                    value={description}
                    onChange={setDescription}
                  />
                </div>*/}
                    <div style={{ paddingTop: 20, paddingBottom: 10 }}>
                      {" "}
                      {isOnline ? (
                        <button
                          onClick={handleAddImage}
                          style={{
                            fontFamily: "inherit",
                            fontSize: "inherit",
                            backgroundColor: "transparent",
                            borderStyle: "none",
                            paddingBottom: 10,
                            paddingTop: 10,
                            cursor: "pointer",
                            color: textColor,
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faImage}
                            size="2x"
                            style={{ paddingRight: 10, color: textColor }}
                          />
                          {t("journal-form-upload-image")}
                        </button>
                      ) : null}
                      {isImage && isOnline ? (
                        <div>
                          <button
                            onClick={removeImage}
                            style={{
                              fontFamily: "inherit",
                              fontSize: "inherit",
                              backgroundColor: "transparent",
                              borderStyle: "none",
                              paddingBottom: 10,
                              paddingTop: 10,
                              marginLeft: 0,
                              cursor: "pointer",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              size="2x"
                              style={{ paddingRight: 2 }}
                            />
                            {t("journal-form-delete-image")}
                          </button>
                        </div>
                      ) : null}
                    </div>

                    <div style={{ paddingBottom: 20 }}>
                      <label style={{ paddingTop: 10, paddingBottom: 5 }}>
                        {t("journal-form-date")}
                      </label>
                      <DateTimePicker
                        onChange={SetNewStartTime}
                        value={new Date(startTime)}
                        clearIcon={null}
                        disableClock
                        format="MMMM dd"
                        disableCalendar={true}
                        calendarIcon={null}
                      />
                      <div style={{ paddingTop: 30 }} />
                      <label style={{ paddingTop: 10, paddingBottom: 15 }}>
                        {t("journal-form-color")}
                      </label>
                      <GithubPicker
                        onChangeComplete={handleColorChangeComplete}
                        width={150}
                        colors={Newcolors}
                      />
                    </div>
                    <button
                      onClick={() => setOptions(false)}
                      style={{
                        textDecoration: "underline",
                        backgroundColor: "transparent",
                        color: "blue",
                        border: 0,
                        fontSize: 14,
                        fontFamily: "inherit",
                        cursor: "pointer",
                      }}
                    >
                      {t("journal-form-hide")}
                    </button>
                  </>
                ) : (
                  <button
                    onClick={() => setOptions(true)}
                    style={{
                      textDecoration: "underline",
                      backgroundColor: "transparent",
                      color: "blue",
                      border: 0,
                      fontSize: 14,
                      fontFamily: "inherit",
                      cursor: "pointer",
                    }}
                  >
                    {t("journal-form-showoptions")}
                  </button>
                )}

                {/*<div style={{paddingBottom: 10}}>
<label style={{paddingTop: 10, paddingBottom: 5}}>End Time</label>
<DateTimePicker onChange={SetNewEndTime} value={endTime} />
  </div> */}

                <div className={classes.modalActions}>
                  <button
                    type="button"
                    onClick={CloseConfirmation}
                    //  className={classes.secondaryButton}
                    style={{
                      cursor: "pointer",
                      fontFamily: "inherit",
                      fontSize: "inherit",
                      fontWeight: "bold",
                      border: 0,
                    }}
                  >
                    {t("common-button-cancel")}
                  </button>

                  <button
                    // disabled={title.length > 1}
                    style={{
                      cursor: "pointer",
                      fontFamily: "inherit",
                      fontSize: "inherit",
                      border: 0,
                      paddingLeft: 30,
                      paddingRight: 30,
                      fontWeight: "bold",
                      //  opacity: enableButton ? 1 : 0.3,
                    }}
                    type="submit"
                  >
                    {t("common-button-save")}
                  </button>
                </div>
                <div style={{ paddingBottom: 100 }} />
              </form>
            ) : null}
          </div>
        </div>
      </Modal.Body>
      <ConfirmationModal
        isOpen={confirmModalOpen}
        text={t("journal-form-exitconfirmation")}
        onClickCancel={() => setConfirmModalOpen(false)}
        onClickConfirm={CloseConfirmation}
      />
      <div style={{ paddingTop: 0, paddingRight: 0, textAlign: "center" }}>
        <button
          style={{
            cursor: "pointer",

            fontFamily: "inherit",
            fontSize: 20,
            border: 0,
            fontWeight: "bold",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: 10,
            color: "blue",
            backgroundColor: "transparent",
            textDecoration: "underline",
          }}
          type="button"
          onClick={() => CloseConfirmation()}
        >
          {t("common-button-close")}
        </button>
      </div>
      <div style={{ paddingBottom: 50 }} />
    </Modal>
  );
};

import { useEffect, useState } from "react";

import { useUserContext } from "Authentication";
import { collection, query, onSnapshot } from "@firebase/firestore";
import { EventModel } from "./models/events";

export function useEventSubscription() {
  const [events, setEvents] = useState<EventModel[]>([]);
  const { getEventCollection } = useUserContext(); //need to setup Tim

  useEffect(() => {
    const plannerCollection = getEventCollection();
    const plannerQuery = query(plannerCollection);

    const unsubscribe = onSnapshot(
      plannerQuery,
      (snapshot) => {
        const addedEvents: EventModel[] = [];

        snapshot.docs.forEach((doc) => {
          const item = { ...doc.data(), id: doc.id } as EventModel;
          addedEvents.push(item);
        });

        setEvents(addedEvents);
      },
      (error) => {
        window.location.reload();
      }
    );

    return () => unsubscribe();
  }, [getEventCollection]);

  // Convert date to epoch
  events.sort((a, b) => (a.start < b.start ? 1 : -1));

  return { events };
}

import classes from "./AnonymousSplash.module.css"
import { Link} from "react-router-dom"
  import { useAuth } from "Authentication"

  import { EmailLinkLoginButton } from "./EmailLinkLoginButton"
 

  export const EmailLinkAuthSplash = () => {

    const { emailAuthLink } = useAuth()

   // console.log("hello, emailauth")

   
  

  return (
    <article>
      <h1 style={{textAlign: "center", marginBottom: -15}}>Email Comfirmation</h1>
      

      <h2 style={{textAlign: "center"}}>Please select Sign In below to finish your validation.</h2>
      <div className={classes.login} style={{paddingBottom: 30}}>
        
        <EmailLinkLoginButton onClick={emailAuthLink} />
      </div>

      <Link to="/" ><h4 style={{textAlign: "center"}}>Back</h4></Link>


      <section>
     {/*   <Features />*/}
      </section>
    </article>
  )
}

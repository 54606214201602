import React, { useEffect, useState, useRef } from "react";
import { Modal, Spinner } from "Common";
//import { ReadRecipeLanding } from "Recipes/RecipeModal/Read/ReadRecipeLanding";
import { useRecipes } from "Recipes/useRecipes";
import DOMPurify from "dompurify";
import linkifyHtml from "linkify-html";
import toast, { Toaster } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faClose,
  faImage,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const RecipeSearch = () => {
  const [recipeName, setRecipeName] = useState("");
  const [recipeInstructions, setRecipeInstructions] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [convertedRecipe, setConvertedRecipe] = useState(null);
  const [isShowSearch, setIsShowSearch] = useState(true);
  const { t } = useTranslation();
  const { addRecipe } = useRecipes();
  const [message, setMessage] = useState("New Recipe Saved");

  useEffect(() => {
    // console.log("Updated convertedRecipe:", convertedRecipe);
  }, [convertedRecipe]);

  var resultArray;

  const convertLanguageCode = (data) => {
    if (data == "fr") {
      return "french";
    } else if (data == "es") {
      return "spanish";
    } else if (data == "de") {
      return "german";
    } else if (data == "en") {
      return "english";
    } else if (data == "jp") {
      return "japanese";
    } else if (data == "kr") {
      return "korean";
    } else if (data == "ar") {
      return "arabic";
    } else if (data == "se") {
      return "swedish";
    } else if (data == "cn") {
      return "chinese";
    } else {
      return "english";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsModalOpen(true);
    const getlanguage = localStorage.getItem("userLanguage");
    var language =
      getlanguage != undefined ? convertLanguageCode(getlanguage) : "english";

    //  console.log("which language", language);
    try {
      const response = await fetch(
        "https://us-central1-dopenotes-20ee4.cloudfunctions.net/recipesv3_6",
        // "http://127.0.0.1:5001/dopenotes-20ee4/us-central1/recipesv3_6",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ recipeName, language }),
        }
      );

      const data = await response.json();
      if (response.status !== 200) {
        setIsShowSearch(true);
        setIsLoading(false);
        setIsModalOpen(false);
        throw (
          data.error ||
          new Error(`Request failed with status ${response.status}`)
        );
      }

      try {
        resultArray = JSON.parse("[" + data.result + "]");
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setIsModalOpen(false);
        setIsShowSearch(true);
      }
      setIsShowSearch(false);
      //  resultArray = Object.values(JSON.parse("[" + data.result + "]"));

      //console.log("what is it", resultArray);

      var newVar = resultArray;
      //   addRecipe(resultArray);
      // console.log("what is it newVar", newVar[0]);
      setConvertedRecipe(newVar[0]);
      //console.log("what is it converted", convertedRecipe);
      setIsLoading(false);
      setMessage("New Recipe Saved");

      //sting to an array
    } catch (error) {
      setIsLoading(false);
      console.error("Error retrieving recipe instructions:", error);
      setMessage("Error retrieving data from ChatGPT.  Please try again.");
    }
  };

  const CloseModal = () => {
    setIsModalOpen(false);
    setRecipeName("");
    setIsLoading(false);
    setConvertedRecipe([]);
    setRecipeInstructions("");
    setIsShowSearch(false);
  };

  const AddToUserRecipe = (recipe) => {
    addRecipe(recipe);
    toast(t("recipelist-chatgpt-added-toast"), {
      duration: 2000,
      position: "top-center",
      style: {
        backgroundColor: "lightgreen",
        border: "1px solid #713200",
        fontSize: "16px",
        padding: "10px",
        color: "black",
      },
    });
    setIsModalOpen(false);
    setIsLoading(false);
    setRecipeName("");
  };

  return (
    <div
      style={{
        paddingTop: 10,
        paddingBottom: 10,
        display: "flex",
      }}
    >
      {isShowSearch ? (
        <div
          style={{
            padding: 20,
            //   backgroundColor: "lightgreen",
            borderRadius: 5,
          }}
        >
          <form onSubmit={handleSubmit}>
            <label style={{ fontSize: 16, paddingBottom: 10 }}>
              {t("recipelist-chatgpt-title")}
            </label>
            <div style={{ paddingRight: 10 }}>
              <input
                type="text"
                placeholder="e.g. turkey meatloaf"
                value={recipeName}
                minLength={1}
                maxLength={100}
                required
                style={{
                  fontSize: 16,
                  fontFamily: "inherit",
                  padding: 10,
                  cursor: "pointer",
                  width: "90%",
                }}
                onChange={(e) => setRecipeName(e.target.value)}
              />
            </div>
            <div style={{ paddingTop: 10 }} />
            <button
              style={{
                fontSize: 14,
                fontFamily: "inherit",
                paddingLeft: 30,
                paddingRight: 30,
                paddingTop: 10,
                paddingBottom: 10,

                color: "white",
                backgroundColor: "#1e79c3",
                cursor: "pointer",
              }}
              type="submit"
            >
              {t("recipelist-chatgpt-search")}
            </button>
            <p style={{ fontSize: 12, lineHeight: 1.6 }}>
              {t("recipelist-chatgpt-disclaimer")}{" "}
              <Link
                to={{
                  pathname: "https://openai.com/policies/privacy-policy",
                }}
                target="_blank"
              >
                <span
                  style={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {t("recipelist-chatgpt-policylink")}
                </span>
              </Link>
            </p>
          </form>
          <div style={{ paddingBottom: 200 }} />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            lineHeight: "26px",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: "95%",
          }}
        >
          <h2>{message}</h2>
          <Link to="/recipes">
            <br />
            <button
              className="metroButton"
              style={{
                fontSize: 16,

                fontFamily: "inherit",
                paddingLeft: 30,
                paddingRight: 30,
                paddingTop: 10,
                paddingBottom: 10,
                border: 0,
                color: "white",
                backgroundColor: "blue",
                cursor: "pointer",
                width: 200,
              }}
              //  onClick={() => setIsShowSearch(true)}
            >
              Go To Meal Planner
            </button>
          </Link>
          <br />
          <button
            className="metroButton"
            style={{
              fontSize: 16,
              width: 200,
              fontFamily: "inherit",
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 10,
              paddingBottom: 10,
              border: 0,
              color: "white",
              backgroundColor: "blue",
              cursor: "pointer",
            }}
            onClick={() => setIsShowSearch(true)}
          >
            Create Another
          </button>
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={CloseModal}
        style={{ overlay: { zIndex: 100 } }}
      >
        {!isLoading ? (
          <div style={{ padding: 20 }}>
            <div
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "right",
                marginTop: 0,
                marginRight: 5,
              }}
            >
              <FontAwesomeIcon
                icon={faClose}
                size="2x"
                onClick={CloseModal}
                style={{ marginTop: -10, cursor: "pointer" }}
              />
            </div>

            {convertedRecipe && convertedRecipe.length > 0
              ? convertedRecipe.map((recipe, index) => (
                  <div key={index}>
                    <h2>
                      {recipe.name != undefined ? recipe.name : "Not Available"}
                    </h2>
                    <label>{t("recipelist-chatgpt-results-directions")}</label>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: linkifyHtml(
                          DOMPurify.sanitize(
                            recipe.directions != undefined
                              ? recipe.directions
                              : t("recipelist-chatgpt-results-notavailable")
                          )
                        ),
                      }}
                    />
                    <label>{t("recipelist-chatgpt-results-ingredients")}</label>
                    <ol>
                      {recipe.ingredients != undefined
                        ? recipe.ingredients.map((i) => (
                            <li>
                              {i.measurement} {i.name}
                            </li>
                          ))
                        : t("recipelist-chatgpt-results-notavailable")}
                    </ol>
                    <button
                      style={{
                        fontSize: "inherit",
                        fontFamily: "inherit",
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 10,
                        paddingBottom: 10,
                        color: "white",
                        backgroundColor: "blue",
                        cursor: "pointer",
                        borderRadius: 40,
                      }}
                      onClick={() => AddToUserRecipe(recipe)}
                    >
                      {t("recipelist-chatgpt-results-addrecipe-button")}
                    </button>
                    <button
                      style={{
                        fontSize: "inherit",
                        fontFamily: "inherit",
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 10,
                        paddingBottom: 10,
                        cursor: "pointer",
                        backgroundColor: "transparent",
                        color: "blue",
                        border: 0,
                      }}
                      onClick={() => CloseModal()}
                    >
                      {t("common-button-cancel")}
                    </button>
                  </div>
                ))
              : null}

            <div style={{ paddingBottom: 180 }} />
          </div>
        ) : null}

        {isLoading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <label style={{ padding: 20 }}>
              {t("recipelist-chatgpt-results-searchingtop")}
            </label>
            <Spinner />
            <label style={{ padding: 20, fontSize: 16 }}>
              {t("recipelist-chatgpt-results-searchingbottom")}
            </label>
            <button
              style={{
                textDecoration: "underline",
                color: "blue",
                cursor: "pointer",
                paddingTop: 20,
                fontSize: 20,
                fontFamily: "inherit",
                backgroundColor: "transparent",
                border: 0,
              }}
              onClick={CloseModal}
            >
              {t("common-button-cancel")}
            </button>
          </div>
        ) : null}
      </Modal>
    </div>
  );
};

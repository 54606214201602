import { EventModel } from "Journal/models/events";
import { Link, useHistory } from "react-router-dom";
import classes from "./RegisteredSplash.module.css";
import moment from "moment";
import { useEffect, useState, memo } from "react";
import { EventsModalAddNew } from "Journal/modals/EventsModalAddNew";
//import mylisticon from "../../../src/Assets/checklist.png";
//import habiticon from "../../../src/Assets/lifestyle.png";
//import journalicon from "../../../src/Assets/notebook.png";
//import recipeicon from "../../../src/Assets/recipe.png";
//import notesicon from "../../../src/Assets/sticky-notes.png";
import settingsicon from "../../../src/Assets/user-profile.png";
//import shoppingicon from "../../../src/Assets/wishlist.png";
//import notificationicon from "../../../src/Assets/notifications.png";
//import helpicon from "../../../src/Assets/help.png";
import { User } from "Authentication/models/user";
import dayjs from "dayjs";
//import { RecipeModel } from "Recipes";
//import { RecipeWidget } from "./sections/RecipeWidget";
import { HabitModel } from "Habits/habitmodel";
import { PlannerModel } from "Planner/models/plannerModel";
//import { SortableComponent } from "Splash/RegisteredSplash/SortableComponent";
import { useUserContext } from "Authentication";
//import { Modal } from "Common";
//import { ConfirmationModal } from "Common";
import { useTranslation } from "react-i18next";
import { RemoteControl } from "./RemoteControl";
import { SortableListWidget } from "./SortableListWidget";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/* import {
  faPaintRoller,
  faTrash,
  faClose,
  faCalendarDay,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons"; */

interface DopeSummaryWidgetProps {
  itemCount: number;
  //recipes: RecipeModel[];
  notes: number;
  todo: number;
  dueDate: number;
  habits: HabitModel[];
  events: EventModel[];
  eventsPlanner: PlannerModel[];
  isDarkMode: boolean;
  user: User;
  isOnline: boolean;
  // mealPlannerData: any[];
}

const styles = {
  button: {
    borderColor: "grey",
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 10,
    paddingLeft: 10,
    borderRadius: 5,
    backgroundColor: "white",
    //borderRadius: 10,
    boxShadow: "1px 5px 9px grey",
    // borderStyle: 'groove',
    opacity: 0.85,
    color: "black",
  },
  buttonContainer: {
    paddingRight: 10,
    paddingLeft: 10,
    fontSize: 14,
    paddingBottom: 5,
    cursor: "pointer",
    //userSelect: "none",
  },
  buttonTitle: {
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 16,
    //userSelect: "none",
    textDecoration: "underline",
    fontWeight: "bold",
    color: "midnightblue",
  },
  buttonResult: {
    color: "purple",
    paddingLeft: 2,
    fontSize: 16,
    fontWeight: "bold",
    overflow: "no-wrap",
    //  userSelect: "none",
  },
  spacer: {
    marginTop: -2,
  },
};

export const DopeSummaryWidget = memo((props: DopeSummaryWidgetProps) => {
  const {
    itemCount,
    //  recipes,
    notes,
    todo,
    dueDate,
    events,
    habits,
    user,
    isDarkMode,
    eventsPlanner,
    isOnline,
    //mealPlannerData,
  } = props;
  const [isMobile, setIsMobile] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [tempDeleteIndex, setTempDeleteIndex] = useState(0);
  const [isCreateNewList, setIsCreateNewList] = useState(false);
  const [newListName, setNewListName] = useState("");
  const { updateSortableLists } = useUserContext();
  const { t } = useTranslation();
  //sortable list
  //@ts-ignore
  const [lists, setLists] = useState(
    //@ts-ignore
    user.sortableLists != undefined
      ? //@ts-ignore
        user.sortableLists
      : [
          {
            name: t("dashboard-summary-goals"),
            items: [
              t("dashboard-summary-goals-water"),
              t("dashboard-summary-goals-exercise"),
              t("dashboard-summary-goals-eat"),
              t("dashboard-summary-goals-money"),
            ],
          },
        ]
  );

  useEffect(() => {
    if ((window as any).isNative) {
      // Post the message back to expo
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    //window.scrollTo({ top: 0, behavior: "smooth" });
    //@ts-ignore

    return () => {};
  }, []);

  return (
    <div>
      <div
        style={{
          paddingBottom: 20,
        }}
      >
        <RemoteControl />
      </div>

      <div
        style={{
          paddingTop: 10,
          paddingBottom: 10,
          display: "flex",
          flexFlow: "wrap",
          flexGrow: "initial",
          userSelect: "none",
        }}
      >
        <div
          style={{
            flex: 5,
          }}
        >
          <PlannerSummary eventsPlanner={eventsPlanner} />
        </div>
        <div style={{ flex: 5 }}>
          <EventsSummary
            events={events}
            isDarkMode={isDarkMode}
            isOnline={isOnline}
          />
        </div>
        <div style={{ flex: 5 }}>
          <HabitsCompleted habits={habits} user={user} />
        </div>
        <div style={{ flex: 5 }}>
          <ListSummary itemCount={itemCount} />
        </div>
        <div style={{ flex: 5 }}>
          <ToDoSummary todo={todo} dueDate={dueDate} />
        </div>
        <div style={{ flex: 5 }}>
          <NotesSummary notes={notes} />
        </div>

        {/*   {isMobile ? <Notification /> : null}
       <InviteFriend />

  <Help />*/}
      </div>

      {/*  <RecipeSummary recipes={recipes} />
      <h3 style={{ userSelect: "none", fontSize: 18 }}>
        Meal Planner / Recipes
      </h3>
      <RecipeWidget mealPlannerData={mealPlannerData} />*/}
    </div>
  );
});

interface ListSummaryProps {
  itemCount: number;
}

const ListSummary = (props: ListSummaryProps) => {
  const { itemCount } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const routeChange = (path) => {
    history.push(path);
  };

  return (
    <section className={classes.sectionDopeSummary}>
      <div style={styles.button}>
        <div
          style={styles.buttonContainer}
          onClick={() => routeChange("/shopping")}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              {/*}   <img
                style={{
                  height: "40px",
                  width: "40px",
                  verticalAlign: "middle",
                  cursor: "pointer",
                  paddingRight: 10,
                }}
                src={shoppingicon}
                alt="Shopping List"
              />*/}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                verticalAlign: "middle",
                alignContent: "center",
                alignSelf: "center",
              }}
            >
              <div style={styles.buttonTitle}>{t("dashboard-groceries")}</div>
              <div style={styles.spacer}>
                <b style={styles.buttonResult}>{itemCount}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

interface HabitsCompletedProps {
  habits: HabitModel[];
  user: User;
}

const HabitsCompleted = (props: HabitsCompletedProps) => {
  const { habits, user } = props;
  const history = useHistory();
  const [isCurrent, setIsCurrent] = useState(false);
  //const { habits } = useHabitSubscription();
  const { t } = useTranslation();
  const routeChange = (path) => {
    history.push(path);
  };

  const today = moment(new Date()).format("dddd");
  //@ts-ignore
  if (user.habitSummary != undefined) {
    //@ts-ignore
    var habitDate = user.habitSummary.habitDate;
  } else {
    //@ts-ignore
    if (user.habitSummary != undefined) {
      //@ts-ignore
      var habitDate = user.habitSummary.habitDate;
    } else {
      var habitDate = "Mar 8, 2023";
    }
  }

  //filter the habits data for today only
  const filterHabitsByDay = habits.filter((item) =>
    //@ts-ignore
    item.days.includes(dayjs().day())
  );
  //Show habits for today
  var completedHabits = habits.filter(
    (i) => i.dateLastUpdated == moment(new Date()).format("YYYYMMDD") && i.done
  ).length;

  //calculate the percentage done for the day
  var percentageCalc =
    completedHabits >= 1
      ? //@ts-ignore
        parseInt((completedHabits / filterHabitsByDay.length) * 100)
      : 0;

  const checkExpiration = () => {
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    const dateEpoch = date.getTime();
    //console.log("date", dateEpoch);

    const convertHabitDate = new Date(habitDate);
    convertHabitDate.setDate(convertHabitDate.getDate() + 1);
    const habitDateEpoch = convertHabitDate.getTime();
    //console.log("convert date", habitDateEpoch);

    if (Number(habitDateEpoch) > Number(dateEpoch)) {
      setIsCurrent(true);
    } else {
      setIsCurrent(false);
    }
  };

  useEffect(() => {
    checkExpiration();

    return () => {};
  }, []);

  return (
    <section className={classes.sectionDopeSummary}>
      <div style={styles.button}>
        <div
          style={styles.buttonContainer}
          onClick={() => routeChange("/habits")}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              {/*<img
                style={{
                  height: "40px",
                  width: "40px",
                  verticalAlign: "middle",
                  cursor: "pointer",
                  paddingRight: 10,
                }}
                src={habiticon}
                alt="Habit Tracker"
              />*/}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                verticalAlign: "middle",
                alignContent: "center",
                alignSelf: "center",
              }}
            >
              <div style={styles.buttonTitle}>{t("dashboard-habits")}</div>
              <div style={styles.spacer}>
                <span style={styles.buttonResult}>
                  {percentageCalc.toString()}%
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

interface ToDoProps {
  todo: number;
  dueDate: number;
}

const ToDoSummary = (props: ToDoProps) => {
  const { todo, dueDate } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const routeChange = (path) => {
    history.push(path);
  };

  return (
    <section className={classes.sectionDopeSummary}>
      <div style={styles.button}>
        <div
          style={styles.buttonContainer}
          onClick={() => routeChange("/todo")}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              {/*} <img
                style={{
                  height: "40px",
                  width: "40px",
                  verticalAlign: "middle",
                  cursor: "pointer",
                  paddingRight: 10,
                }}
                src={mylisticon}
                alt="My Lists"
              />*/}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                verticalAlign: "middle",
                alignContent: "center",
                alignSelf: "center",
              }}
            >
              <div style={styles.buttonTitle}>{t("dashboard-tasks")}</div>
              <div style={styles.spacer}>
                <b style={styles.buttonResult}>
                  {todo} ({dueDate})
                </b>
                <span style={{ paddingLeft: 10, fontSize: 12 }}></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

interface NotesProps {
  notes: number;
}

const NotesSummary = (props: NotesProps) => {
  const { notes } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const routeChange = (path) => {
    history.push(path);
  };

  return (
    <section className={classes.sectionDopeSummary}>
      <div style={styles.button}>
        <div
          style={styles.buttonContainer}
          onClick={() => routeChange("/notes")}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              {/*}  <img
                style={{
                  height: "40px",
                  width: "40px",
                  verticalAlign: "middle",
                  cursor: "pointer",
                  paddingRight: 10,
                }}
                src={notesicon}
                alt="Notes"
              />*/}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                verticalAlign: "middle",
                alignContent: "center",
                alignSelf: "center",
              }}
            >
              <div style={styles.buttonTitle}>{t("header-menu-notes")}</div>
              <div style={styles.spacer}>
                <b style={styles.buttonResult}>{notes}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

interface PlannerProps {
  eventsPlanner: PlannerModel[];
}

const PlannerSummary = (props: PlannerProps) => {
  const { eventsPlanner } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const routeChange = (path) => {
    history.push(path);
  };

  const getEventsCountForDay = () => {
    const eventsForDay = eventsPlanner.filter((event) => {
      // Compare the dates (ignoring time) to check if they match
      return (
        //@ts-ignore
        new Date(event.startDate.seconds * 1000).getDate() ===
          new Date().getDate() &&
        //@ts-ignore
        new Date(event.startDate.seconds * 1000).getMonth() ===
          new Date().getMonth() &&
        //@ts-ignore
        new Date(event.startDate.seconds * 1000).getFullYear() ===
          new Date().getFullYear()
      );
    });
    return eventsForDay.length;
  };
  //console.log(formattedDate);
  // console.log("how many", getEventsCountForDay());

  const todayJournal = getEventsCountForDay();

  return (
    <section className={classes.sectionDopeSummary}>
      <div style={styles.button}>
        <div
          style={styles.buttonContainer}
          onClick={() => routeChange("/planner")}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              {/*}   <img
                style={{
                  height: "40px",
                  width: "40px",
                  verticalAlign: "middle",
                  cursor: "pointer",
                  paddingRight: 10,
                }}
                src={journalicon}
                alt="Daily Journal"
              />*/}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                verticalAlign: "middle",
                alignContent: "center",
                alignSelf: "center",
              }}
            >
              <div style={styles.buttonTitle}>{t("dashboard-events")}</div>
              <div style={styles.spacer}>
                <b
                  style={{
                    color: "purple",
                    paddingLeft: 2,
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  {todayJournal}
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

interface EventsProps {
  events: EventModel[];
  isDarkMode: boolean;
  isOnline: boolean;
}

const EventsSummary = (props: EventsProps) => {
  const { events, isDarkMode, isOnline } = props;
  const history = useHistory();
  const [isEventsModalAddNewOpen, setIsEventsModalAddNewOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<EventModel>();
  var selectSlotStart = new Date();
  const { t } = useTranslation();
  const routeChange = (path) => {
    history.push(path);
  };

  var howManyEvents = 0;

  const getupcomingevents = () => {
    const today = new Date();

    events.forEach((e) => {
      const eventDate = e.start;
      if (e.end < today) {
        howManyEvents = howManyEvents + 1;
      }
      // console.log("events", howManyEvents)
    });
    return howManyEvents;
  };

  const mostRecentDate = events.reduce((maxDate, item) => {
    //@ts-ignore
    const itemDate = new Date(item.start.seconds * 1000);
    return itemDate > maxDate ? itemDate : maxDate;
  }, new Date(0));

  const formattedDate = mostRecentDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  const getEventsCountForDay = () => {
    const eventsForDay = events.filter((event) => {
      // Compare the dates (ignoring time) to check if they match
      return (
        //@ts-ignore
        new Date(event.start.seconds * 1000).getDate() ===
          new Date().getDate() &&
        //@ts-ignore
        new Date(event.start.seconds * 1000).getMonth() ===
          new Date().getMonth() &&
        //@ts-ignore
        new Date(event.start.seconds * 1000).getFullYear() ===
          new Date().getFullYear()
      );
    });
    return eventsForDay.length;
  };
  //console.log(formattedDate);
  //console.log("how many journal", getEventsCountForDay());

  const todayJournal = getEventsCountForDay();

  getupcomingevents();

  return (
    <section className={classes.sectionDopeSummary}>
      <div style={styles.button}>
        <div style={styles.buttonContainer}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            onClick={() => {
              todayJournal != 0
                ? routeChange("/journal")
                : setIsEventsModalAddNewOpen(true);
            }}
          >
            <div>
              {" "}
              {/*}   <img
                style={{
                  height: "40px",
                  width: "40px",
                  verticalAlign: "middle",
                  cursor: "pointer",
                  paddingRight: 10,
                }}
                src={journalicon}
                alt="Daily Journal"
              />*/}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                verticalAlign: "middle",
                alignContent: "center",
                alignSelf: "center",
              }}
            >
              <div
                style={styles.buttonTitle}
                onClick={() => routeChange("/journal")}
              >
                {" "}
                {t("dashboard-journal")}
              </div>
              <div style={styles.spacer}>
                <b
                  style={{
                    color: "purple",
                    paddingLeft: 2,
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  {todayJournal != 0 ? (
                    <span
                      onClick={() => routeChange("/journal")}
                      style={{ color: "purple", cursor: "pointer", zIndex: 2 }}
                    >
                      {t("dashboard-journal-completed")}
                    </span>
                  ) : (
                    <button
                      onClick={() => setIsEventsModalAddNewOpen(true)}
                      style={{
                        color: "blue",
                        cursor: "pointer",
                        borderRadius: 40,
                        //width: "85%",
                      }}
                    >
                      {t("common-button-add")}
                    </button>
                  )}
                </b>
              </div>
            </div>
          </div>
        </div>
        {isEventsModalAddNewOpen ? (
          <EventsModalAddNew
            isOpen={isEventsModalAddNewOpen}
            event={selectedEvent}
            isDarkMode={isDarkMode}
            selectedStartTime={selectSlotStart}
            isOnline={isOnline}
            close={() => setIsEventsModalAddNewOpen(false)}
          />
        ) : null}
      </div>
    </section>
  );
};

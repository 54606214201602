import { useEffect, useState, memo } from "react";
//import { useNotesSubscription } from "Notes/useNotesSubscription";
import classes from "../../../Notes/notes.module.css";
//import { NoteWidget } from "Notes/components/NoteWidget";
import { AddNote } from "Notes/components/AddNote";
import { useUserContext } from "Authentication";
import { nanoid } from "nanoid";
import { NotesModel } from "Notes/models/notes";
import { Note } from "Notes/components/Note";
import { Link, Route, useRouteMatch } from "react-router-dom";
import { NoteLanding } from "Notes/components/NoteLanding";
//import { QuickNotes } from "Notes/components/QuickNotes";
import { useTranslation } from "react-i18next";

interface NotesProps {
  notes: NotesModel[];
  isExpired: boolean;
  CloseEditor: () => void;
  addNoteEditor: boolean;
  isDarkMode: boolean;
}

export const NotesWidget = memo((props: NotesProps) => {
  const { notes, isExpired, addNoteEditor, CloseEditor, isDarkMode } = props;
  const [localNotes, setLocalNotes] = useState([
    {
      id: nanoid(),
      text: "This is my first note!",
      tags: "need to add",
      //date: '15/04/2021',
      color: "#fef68a",
    },
    {
      id: nanoid(),
      text: "This is my second note!",
      tags: "need to add",
      //date: '21/04/2021',
      color: "#fef68a",
    },
    {
      id: nanoid(),
      text: "This is my third note!",
      tags: "need to add",
      //date: '28/04/2021',
      color: "#fef68a",
    },
    {
      id: nanoid(),
      text: "This is my new note!",
      tags: "need to add",
      //	date: '30/04/2021',
      color: "#fef68a",
    },
  ]);

  const { t } = useTranslation();
  //console.log("what is notes", notes);

  //const { notes } = useNotesSubscription();
  const [searchText, setSearchText] = useState("");
  // const [addNoteEditor, setAddNoteEditor] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const { getNotesCollection, addNewNote, deleteNote, editNote } =
    useUserContext();
  const [isNative, setIsNative] = useState(false);
  const { url } = useRouteMatch();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  var blankNote = {
    text: "",
    tags: [],
    color: "#fef68a",
  };
  const addNote = (note) => {
    //	const date = new Date();
    const newNote = {
      id: nanoid(),
      text: note.text,
      tags: note.tags,
      color: note.color,
    };
    const newNotes = [...localNotes, newNote];
    setLocalNotes(newNotes);
    //push to server
    addNewNote(note.text, note.tags, note.color);
    CloseEditor();

    //console.log("did this work?")
  };

  const linkList = notes.map((note) => {
    return (
      <div key={note.id}>
        <Link to={`notes/${note.id}`}>
          {" "}
          <Note
            key={note.id}
            id={note.id}
            text={note.text}
            tags={note.tags}
            date={note.date}
            color={note.color}
            handleDeleteNote={deleteNote}
            handleEditNote={editNote}
            isExpired={{ isExpired }}
            isLanding={false}
          />
        </Link>
      </div>
    );
  });
  //console.log("what is notes bool", addNoteEditor);

  useEffect(() => {
    return () => {};
    //@ts-ignore
  }, [notes, addNoteEditor]);

  return (
    <div>
      <AddNote
        handleAddNote={addNote}
        setIsAdding={CloseEditor}
        isAdding={addNoteEditor}
        value={blankNote}
        isDarkMode={isDarkMode}
        isNew={true}
      />

      <div style={{ paddingTop: 20 }} />

      {notes.length > 0 ? (
        <div
          className={classes.notesList}
          style={{ paddingTop: 10, padding: 10 }}
        >
          {linkList}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginTop: 0,
          }}
        >
          <p style={{ textAlign: "center" }}>{t("grocery-list-noitems")}</p>
        </div>
      )}
      <Route path={`${url}/notes/:productId`}>
        <NoteLanding
          data={notes}
          isExpired={isExpired}
          handleDeleteNote={deleteNote}
          handleEditNote={editNote}
        />
      </Route>
    </div>
  );
});

import { useEffect } from "react";
//import { getAnalytics, logEvent } from "firebase/analytics";
//import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

//const app = initializeApp(firebaseConfig);

export const FirebaseAnalytics = () => {
  useEffect(() => {
    //console.log("what is current screen", currentScreen);
    try {
      const analytics = getAnalytics();
      logEvent(analytics, "notification_received");
      logEvent(analytics, "screen_view", window.location.pathname);
      // analytics.logEvent(window.location.pathname);
      //  console.log("fired");
      // Get the current screen
      // console.log("what is current screen", currentScreen);
      //  analytics.logEvent("screen_view");
    } catch (error) {}
    // console.log("what is the error", error);
    // analytics.setCurrentScreen(window.location.pathname);
  }, []);

  return null;
};

import React, { useState, useEffect } from "react";

export const TransactionModal = ({
  isOpen,
  onClose,
  type,
  itemName,
  onConfirm,
  maxQuantity,
  totalCost,
}) => {
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    setQuantity(maxQuantity);

    return () => {};
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="game-modal-overlay">
      <div
        className="game-modal"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <h2>{type === "buy" ? `Buy ${itemName}` : `Sell ${itemName}`}</h2>
        <p>
          {type === "buy"
            ? `Max you can afford: ${Math.floor(maxQuantity)}`
            : `You can sell ${Math.floor(maxQuantity)} worth: $${Math.floor(
                totalCost
              )}`}
        </p>
        <input
          type="number"
          value={Math.floor(quantity)}
          style={{ fontSize: 14, padding: 10 }}
          onChange={(e) =>
            setQuantity(Math.max(1, Math.min(e.target.value, maxQuantity)))
          }
          min="1"
          max={maxQuantity}
        />
        <div style={{ paddingTop: 20 }} />
        <button
          onClick={() => {
            onConfirm(itemName, quantity);
            setQuantity(maxQuantity);
          }}
        >
          Confirm
        </button>
        <button onClick={onClose} className="game-close-button">
          Close
        </button>
      </div>
    </div>
  );
};

import React, { useEffect, useState, useRef } from "react";
import { Modal, Spinner } from "Common";
//import { ReadRecipeLanding } from "Recipes/RecipeModal/Read/ReadRecipeLanding";

import toast, { Toaster } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faClose,
  faPrint,
  faImage,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUserContext } from "Authentication";

export const IdeaGenerator = ({}) => {
  const [toDoName, setToDoName] = useState("");
  // const [recipeInstructions, setRecipeInstructions] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [convertedToDo, setConvertedToDo] = useState(null);
  const [isShowSearch, setIsShowSearch] = useState(true);
  const { t } = useTranslation();

  const [isAddingList, setIsAddingList] = useState(false);
  const [message, setMessage] = useState("New Idea Saved To Notes");
  const { addNewNote } = useUserContext();
  const [exampleIdeas, setExampleIdeas] = useState("");

  const [selectedIdea, setSelectedIdea] = useState("");
  const [isCustomOption, setIsCustomOption] = useState(false);

  const handleChangeType = (event) => {
    const value = event.target.value;
    setSelectedIdea(value);

    if (value === "Custom") {
      setIsCustomOption(true);
    } else {
      setIsCustomOption(false);
    }
    handleExamplesIdeas(event.target.value);
  };

  useEffect(() => {
    // console.log("Updated convertedToDo:", convertedToDo);
  }, [convertedToDo]);

  var resultArray;

  const convertLanguageCode = (data) => {
    if (data == "fr") {
      return "french";
    } else if (data == "es") {
      return "spanish";
    } else if (data == "de") {
      return "german";
    } else if (data == "en") {
      return "english";
    } else if (data == "jp") {
      return "japanese";
    } else if (data == "kr") {
      return "korean";
    } else if (data == "ar") {
      return "arabic";
    } else if (data == "se") {
      return "swedish";
    } else if (data == "cn") {
      return "chinese";
    } else {
      return "english";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsShowSearch(false);
    setIsModalOpen(true);
    const type = selectedIdea;
    const getlanguage = localStorage.getItem("userLanguage");
    var language =
      getlanguage != undefined ? convertLanguageCode(getlanguage) : "english";

    //  console.log("which language", language);
    try {
      const response = await fetch(
        "https://us-central1-dopenotes-20ee4.cloudfunctions.net/chatgpt/ideas",
        // "http://127.0.0.1:5001/dopenotes-20ee4/us-central1/chatgpt/ideas",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ toDoName, type, language }),
        }
      );

      const data = await response.json();
      if (response.status !== 200) {
        setIsShowSearch(true);
        setIsLoading(false);
        setIsModalOpen(false);
        throw (
          data.error ||
          new Error(`Request failed with status ${response.status}`)
        );
      }

      try {
        //console.log("what is the data", data);
        resultArray = data.result;
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setIsModalOpen(false);
        setIsShowSearch(true);
      }
      setIsShowSearch(false);
      //  resultArray = Object.values(JSON.parse("[" + data.result + "]"));

      //  console.log("what is it", resultArray);

      var newVar = resultArray;
      //   addRecipe(resultArray);
      // console.log("what is it newVar", newVar[0]);
      setConvertedToDo(newVar);
      //console.log("what is it converted", convertedToDo);
      setIsLoading(false);
      setMessage("New Idea Saved To Notes");
      //sting to an array
    } catch (error) {
      console.error("Error retrieving data:", error);
      setIsLoading(false);
      setMessage("Error retrieving data from ChatGPT.  Please try again.");
    }
  };

  const CloseModal = () => {
    setIsModalOpen(false);
    setToDoName("");
    setIsLoading(false);
    setConvertedToDo([]);
    // setRecipeInstructions("");
    setIsShowSearch(false);
  };

  const print = () => window.print();

  const AddToDoList = async () => {
    setIsAddingList(true);
    await addNewNote(
      `${toDoName.toUpperCase()} ${selectedIdea.toUpperCase()} <br/><br/> ${convertedToDo}`,
      ["ideas"],
      "pink"
    );
    setIsAddingList(false);
    setIsModalOpen(false);
    // setIsModalOpen(false);
    // setIsLoading(false);
    setToDoName("");
  };

  const handleExamplesIdeas = (event) => {
    if (event == "Baby Names") {
      setExampleIdeas("e.g. Hawaiian female");
      return;
    } else if (event == "Book Ideas") {
      setExampleIdeas(
        "e.g. Murder mystery set in Hawaii that involves hotels, hula dancers, and surfers"
      );
      return;
    } else if (event == "Brainstorm") {
      setExampleIdeas("e.g. Ideas for a new summer sport");
      return;
    } else if (event == "Diet") {
      setExampleIdeas("e.g. Lose weight in 30 days");
      return;
    } else if (event == "Exercise") {
      setExampleIdeas("e.g. Build strong core at home");
      return;
    } else if (event == "Game Idea") {
      setExampleIdeas("e.g. That use dices and a standard deck of cards");
      return;
    } else if (event == "Letter Drafter") {
      setExampleIdeas("e.g. Thanking my friends for their support ");
      return;
    } else if (event == "Movie Ideas") {
      setExampleIdeas("e.g. Documentary on the Old Pali Road");
      return;
    } else if (event == "New Product Ideas") {
      setExampleIdeas("e.g. Sunglasses with AR");
      return;
    } else if (event == "Prepare Interview Questions") {
      setExampleIdeas("e.g. Hotel Manager");
      return;
    } else if (event == "Pros and Cons") {
      setExampleIdeas("e.g. Living in Hawaii");
      return;
    } else if (event == "Racing") {
      setExampleIdeas("e.g. Assetto Corsa GT3 setup Red Bull Ring");
      return;
    } else if (event == "Short Essay") {
      setExampleIdeas("e.g. What is A.I.?");
      return;
    } else if (event == "Song Idea") {
      setExampleIdeas("e.g. Upbeat Hawaiian song for kids");
      return;
    } else if (event == "SWOT Analysis") {
      setExampleIdeas("e.g. Buying Bitcoin in 2024");
      return;
    } else if (event == "Travel") {
      setExampleIdeas("e.g. Things to do on Oahu for 7 days");
      return;
    } else {
      return;
    }
  };

  return (
    <div
      style={{
        paddingTop: 10,
        paddingBottom: 10,
        display: "flex",
      }}
    >
      {isShowSearch ? (
        <div
          style={{
            padding: 20,
            // backgroundColor: "lightgreen",
            borderRadius: 5,
          }}
        >
          {/*  <div
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "right",
                marginTop: 0,
                marginRight: 5,
              }}
            >
              <FontAwesomeIcon
                icon={faClose}
                size="xl"
                onClick={() => setIsShowSearch(false)}
                style={{ marginTop: -10, cursor: "pointer" }}
              />
              
            </div>*/}
          <h2 className="h2SlideIn">Idea Generator</h2>
          {/*}   <div
            style={{
              textAlign: "center",
              marginTop: -20,
              paddingBottom: 20,
              fontSize: 14,
            }}
          >
            Need help with an idea or note? Use A.I. to get started.
          </div>*/}
          <p
            style={{
              textAlign: "center",
              paddingleft: 10,
              paddingRight: 10,
              paddingBottom: 20,
            }}
          >
            Seeking assistance with an idea, letter, or template? Utilize our
            idea generator to create, save, edit, or share them directly from
            your notepad.
          </p>

          <label style={{ fontSize: 18 }}>Step 1: Select Type of Idea</label>
          <div style={{ margin: "20px", width: "185px" }}>
            {!isCustomOption ? (
              <select
                value={selectedIdea}
                onChange={handleChangeType}
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  appearance: "none",
                  backgroundColor: "white",
                  cursor: "pointer",
                }}
              >
                <option value="">Select Idea Type</option>
                <option value="Baby Names">Baby Names</option>
                <option value="Book Ideas">Book Ideas</option>
                <option value="Brainstorm">Brainstorm</option>
                <option value="Diet">Diet</option>
                <option value="Exercise">Exercise</option>
                <option value="Game Idea">Game Idea</option>
                <option value="Letter Drafter">Letter Drafter</option>
                <option value="Movie Ideas">Movie Ideas</option>
                <option value="New Product Ideas">New Product Ideas</option>
                <option value="Prepare Interview Questions">
                  Interview Questions
                </option>
                <option value="Pros and Cons">Pros and Cons</option>
                <option value="Racing">Racing</option>
                <option value="Short Essay">Short Essay</option>
                <option value="Song Idea">Song Idea</option>
                <option value="SWOT Analysis">SWOT Analysis</option>
                <option value="Travel">Travel</option>
                <option value="Custom">Custom</option>
              </select>
            ) : (
              <input
                type="text"
                value={selectedIdea !== "Custom" ? selectedIdea : ""}
                onChange={(e) => setSelectedIdea(e.target.value)}
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  backgroundColor: "white",
                  cursor: "text",
                }}
                placeholder="e.g. Executive Business Summary"
              />
            )}
          </div>
          <form onSubmit={handleSubmit}>
            <label style={{ fontSize: 18 }}>Step 2: Brief Description</label>
            <div style={{ padding: 10 }}>
              <input
                type="text"
                placeholder={exampleIdeas}
                value={toDoName}
                minLength={1}
                maxLength={100}
                required
                style={{
                  fontSize: 16,
                  fontFamily: "inherit",
                  padding: 10,
                  cursor: "pointer",
                  width: "90%",
                }}
                onChange={(e) => setToDoName(e.target.value)}
              />
            </div>
            <div style={{ paddingTop: 10 }} />
            <button
              className="metroButton"
              disabled={selectedIdea != "" && toDoName != "" ? false : true}
              style={{
                fontSize: 16,
                fontFamily: "inherit",
                paddingLeft: 30,
                paddingRight: 30,
                paddingTop: 10,
                paddingBottom: 10,
                border: 0,
                color: "white",
                backgroundColor: "blue",
                cursor: "pointer",
                width: 150,
                opacity: selectedIdea != "" && toDoName != "" ? 1 : 0.25,
              }}
              type="submit"
            >
              Generate
            </button>
            <Link to="/notes">
              <span
                // onClick={() => setIsShowSearch(false)}
                style={{
                  paddingLeft: 10,
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "blue",
                }}
              >
                Go To Notes
              </span>
            </Link>
            <p style={{ fontSize: 12, lineHeight: 1.6 }}>
              {t("recipelist-chatgpt-disclaimer")}{" "}
              <Link
                to={{
                  pathname: "https://openai.com/policies/privacy-policy",
                }}
                target="_blank"
              >
                <span
                  style={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {t("recipelist-chatgpt-policylink")}
                </span>
              </Link>
              <div style={{ paddingBottom: 200 }} />
            </p>
          </form>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            lineHeight: "26px",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: "95%",
          }}
        >
          <h2>{message}</h2>
          <Link to="/notes">
            <br />
            <button
              className="metroButton"
              style={{
                fontSize: 16,

                fontFamily: "inherit",
                paddingLeft: 30,
                paddingRight: 30,
                paddingTop: 10,
                paddingBottom: 10,
                border: 0,
                color: "white",
                backgroundColor: "blue",
                cursor: "pointer",
                width: 185,
              }}
              //  onClick={() => setIsShowSearch(true)}
            >
              Go To Notes
            </button>
          </Link>
          <br />
          <button
            className="metroButton"
            style={{
              fontSize: 16,
              width: 185,
              fontFamily: "inherit",
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 10,
              paddingBottom: 10,
              border: 0,
              color: "white",
              backgroundColor: "blue",
              cursor: "pointer",
            }}
            onClick={() => setIsShowSearch(true)}
          >
            Create Another
          </button>
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={CloseModal}
        style={{ overlay: { zIndex: 100 } }}
      >
        {!isLoading ? (
          <div style={{ padding: 20 }}>
            {/*} <div
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "right",
                marginTop: 0,
                marginRight: 5,
              }}
            >
              <FontAwesomeIcon
                icon={faClose}
                size="2x"
                onClick={CloseModal}
                style={{ marginTop: -10, cursor: "pointer" }}
              />
            </div>*/}

            <h2 style={{ textAlign: "center" }}>
              {toDoName.toUpperCase()} {selectedIdea.toUpperCase()}
            </h2>
            <label style={{ textAlign: "center" }}>Preview Only</label>
            <hr />
            <div
              dangerouslySetInnerHTML={{
                __html: convertedToDo,
              }}
            />
            <div
              style={{
                display: "flex",
                paddingTop: 20,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isAddingList ? (
                <Spinner />
              ) : (
                <>
                  {" "}
                  <button
                    style={{
                      fontSize: "inherit",
                      fontFamily: "inherit",
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 10,
                      paddingBottom: 10,
                      color: "white",
                      backgroundColor: "blue",
                      cursor: "pointer",
                      borderRadius: 40,
                    }}
                    onClick={() => AddToDoList()}
                  >
                    {t("common-button-save")}
                  </button>
                </>
              )}
              <button
                style={{
                  fontSize: "inherit",
                  fontFamily: "inherit",
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 10,
                  paddingBottom: 10,
                  cursor: "pointer",
                  backgroundColor: "transparent",
                  color: "blue",
                  border: 0,
                }}
                onClick={() => CloseModal()}
              >
                {t("common-button-cancel")}
              </button>
            </div>
            <div style={{ paddingBottom: 180 }} />
          </div>
        ) : null}

        {isLoading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
              color: "black",
            }}
          >
            <label style={{ padding: 20 }}>{t("chatgpt-todo-searching")}</label>
            <Spinner />
            <label style={{ padding: 20, fontSize: 16 }}>
              {t("recipelist-chatgpt-results-searchingbottom")}
            </label>
            <button
              style={{
                textDecoration: "underline",
                color: "blue",
                cursor: "pointer",
                paddingTop: 20,
                fontSize: 20,
                fontFamily: "inherit",
                backgroundColor: "transparent",
                border: 0,
              }}
              onClick={CloseModal}
            >
              {t("common-button-cancel")}
            </button>
          </div>
        ) : null}
      </Modal>
    </div>
  );
};

//import { useCategories } from "Lists/CategoriesContext"
//import { useCategorySubscription } from "../../../Lists/useCatgorySubscription";
import { useUserContext } from "Authentication";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faClose,
  faPalette,
} from "@fortawesome/free-solid-svg-icons";

import { Modal } from "Common";
import { useEffect, useState } from "react";
import classes from "./CategoryModal.module.css";
//import { stringify } from "querystring";
import { HexColorPicker, HexColorInput } from "react-colorful";
import { ConfirmationModal } from "Common";
import toast, { Toaster } from "react-hot-toast";
import { EditFieldModal } from "Common/Modal/EditFieldModal/EditFieldModal";
//import { doc, addDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";

interface CategoryEditModalProps {
  isOpen: boolean;
  close: () => void;
  isDarkMode: boolean;
  categories: any[];
}

export const CategoryEditModal = (props: CategoryEditModalProps) => {
  const { isOpen, close, isDarkMode, categories } = props;
  //const categories = useCategories()
  //const { categories } = useCategorySubscription();

  const [name, setName] = useState("");
  const {
    updateGroceryCategoryColor,
    updateGroceryCategoryName,
    deleteGroceryCategory,
    addGroceryCategory,
  } = useUserContext();

  const [isColorPicker, setIsColorPicker] = useState(false);
  const [colorPicker, setColorPicker] = useState("");
  const [whichCategoryID, setWhichCategoryID] = useState("");
  const [whichCategoryName, setWhichCategoryName] = useState("");
  const [orderIDNumber, setOrderIDNumber] = useState("");
  const [isAddingNewCat, setIsAddingNewCat] = useState(false);
  const [newCatName, setNewCatName] = useState("");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [deleteCatId, setDeleteCatId] = useState("");
  const [selectedCatName, setSelectedCatName] = useState("");
  const [selectedCatId, setSelectedCatId] = useState("");
  const [editingCatName, setEditingCatName] = useState(false);
  const [updatedCatName, setUpdatedCatName] = useState("");
  const { t } = useTranslation();

  var numberOfCategories = 10;
  var NewCatName = "";
  var randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);

  var newCategory = {
    name: NewCatName,
    colour: randomColor,
    order: numberOfCategories,
  };

  const openColorPicker = (id, name, color) => {
    setIsColorPicker(true);
    setColorPicker(color);
    setWhichCategoryID(id);
    setWhichCategoryName(name);
  };

  const saveNewColor = (id) => {
    updateColor(id, colorPicker);
    setIsColorPicker(false);
  };

  function updateColor(id: string, colour: string) {
    updateGroceryCategoryColor(id, colour);
  }

  function updateCategory(id: string, name: string) {
    updateGroceryCategoryName(id, name);
  }

  function deleteItemDB(id: string) {
    deleteGroceryCategory(id);
  }

  const confirmDelete = (id: string, name: string) => {
    setDeleteCatId(id);
    setConfirmModalOpen(true);
  };

  const confirmDeleteAccepted = () => {
    //console.log("what is the ID", deleteCatId)
    deleteItemDB(deleteCatId);
    setConfirmModalOpen(false);
    toast(t("generic-list-category-remove-toast"), {
      duration: 2000,
      position: "top-center",
      style: {
        backgroundColor: "lightgreen",
        border: "1px solid #713200",
        fontSize: "16px",
        padding: "10px",
        color: "black",
      },
    });
  };

  const deleteItem = (id: string) => {
    deleteItemDB(id);
    //window.location.reload()
    //  close()
  };

  function addCategoryDB(name: string, id: number, colour: string) {
    addGroceryCategory(newCategory);
    // return GroupCategory.add(newCategory);
  }

  const addCategory = (e) => {
    e.preventDefault();
    //console.log("New Cat Name", newCatName)
    toast(t("generic-list-category-add-toast"), {
      duration: 2000,
      position: "top-center",
      style: {
        backgroundColor: "lightgreen",
        border: "1px solid #713200",
        fontSize: "16px",
        padding: "10px",
        color: "black",
      },
    });

    newCategory = {
      name: newCatName,
      colour: randomColor,
      order: numberOfCategories,
    };

    addCategoryDB(newCatName, numberOfCategories, randomColor);

    //console.log("catgory should be added", NewCatName, randomColor, numberOfCategories)

    //reset everything
    numberOfCategories = null;
    setNewCatName("");
    randomColor = Math.floor(Math.random() * 16777215).toString(16);
    numberOfCategories = 0;
    //window.location.reload()
    //  close()
  };

  const EditCategoryName = (id, name, order) => {
    setSelectedCatName(name);
    setSelectedCatId(id);
    setEditingCatName(true);

    // save this updateCategory(id, name);
  };

  const updateNewCategoryName = () => {
    //console.log("did I fire,  what is infor", selectedCatId, selectedCatName);
    updateCategory(selectedCatId, updatedCatName);
    setEditingCatName(false);
    setSelectedCatName("");
    setSelectedCatId("");
    setUpdatedCatName("");
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      style={{ overlay: { zIndex: 50 } }}
    >
      <div
        style={{
          paddingBottom: 250,
          padding: 20,
          filter: !isDarkMode ? "invert(100%)" : "",
        }}
      >
        <div
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            paddingBottom: 10,
            border: 1,
            borderColor: "grey",
            borderRadius: 10,
            backgroundColor: "snow",
            //  fontWeight: "bold",
            color: "black",
            marginTop: -50,
            minHeight: "100vh",
          }}
        >
          <>
            <div
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "right",
                margin: 10,
              }}
            >
              {" "}
              <button
                style={{
                  backgroundColor: "transparent",
                  borderStyle: "none",
                  cursor: "pointer",
                  marginTop: 10,
                  marginRight: -20,
                }}
                onClick={close}
              >
                <FontAwesomeIcon icon={faClose} size="1x" />
              </button>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 0,
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              {!isAddingNewCat ? (
                <button
                  style={{
                    fontFamily: "inherit",
                    fontSize: "inherit",
                    textAlign: "right",
                    color: "white",
                    border: 1,
                    borderRadius: 20,
                    padding: 20,
                    backgroundColor: "blue",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={() => setIsAddingNewCat(true)}
                >
                  {t("generic-list-category-add-title")}
                </button>
              ) : null}
              {isAddingNewCat ? (
                <form onSubmit={addCategory}>
                  <input
                    style={{
                      width: 125,
                      padding: 10,
                      paddingRight: 20,
                      fontSize: 18,
                    }}
                    type="text"
                    minLength={2}
                    required
                    onChange={(e) => setNewCatName(e.target.value)}
                    value={newCatName}
                  />
                  <input
                    type="submit"
                    style={{
                      padding: 10,
                      marginLeft: 5,
                      width: 75,
                    }}
                    value={t("common-button-add")}
                  />
                  <p
                    onClick={() => {
                      setIsAddingNewCat(false);
                      setNewCatName("");
                    }}
                    style={{
                      textAlign: "center",
                      color: "blue",
                      fontSize: 16,
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    {t("common-button-close")}
                  </p>
                </form>
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 0,
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              <p
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  textAlign: "center",
                }}
              >
                {t("generic-list-category-add-info1")} <br /> <br />
                {t("generic-list-category-add-info2")}
              </p>
            </div>

            {categories
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((c) => (
                <div key={c.id} className={classes.gridContainer}>
                  <div
                    onClick={() => EditCategoryName(c.id, c.name, c.order)}
                    className={classes.category}
                    style={{
                      backgroundColor: `${c.colour}40`,
                      fontWeight: c.id === "" ? "bold" : "inherit",
                    }}
                  >
                    <span style={{ fontWeight: "bold", width: "90%" }}>
                      {c.name}
                    </span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <button
                      title={"Change Color"}
                      style={{
                        color: "black",
                        backgroundColor: "transparent",
                        border: 0,
                        cursor: "pointer",
                        flex: 1,
                      }}
                      onClick={() => openColorPicker(c.id, c.name, c.colour)}
                    >
                      <FontAwesomeIcon icon={faPalette} size="2x" />
                    </button>

                    <button
                      title={"Delete"}
                      style={{
                        color: "black",
                        backgroundColor: "transparent",
                        border: 0,
                        cursor: "pointer",
                        flex: 1,
                        paddingRight: 15,
                      }}
                      onClick={() => confirmDelete(c.id, c.name)}
                    >
                      <FontAwesomeIcon icon={faTrashCan} size="2x" />
                    </button>
                  </div>
                </div>
              ))}
          </>
          <ConfirmationModal
            isOpen={confirmModalOpen}
            text={t("generic-list-category-delete-confirmation")}
            onClickCancel={() => setConfirmModalOpen(false)}
            onClickConfirm={confirmDeleteAccepted}
          />
          <Modal
            isOpen={isColorPicker}
            onRequestClose={close}
            style={{ overlay: { zIndex: 100 } }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "right",
                margin: 10,
              }}
            >
              {" "}
              <button
                style={{ cursor: "pointer" }}
                onClick={() => setIsColorPicker(false)}
              >
                <FontAwesomeIcon icon={faClose} size="1x" />
              </button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 20,
                alignItems: "center",
                marginTop: -30,
                filter: "invert(100%)",
              }}
            >
              <div
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  padding: 15,
                  textAlign: "center",
                }}
              >
                {t("generic-list-category-color-info")} "{whichCategoryName}"
              </div>
              <HexColorPicker color={colorPicker} onChange={setColorPicker} />
              <HexColorInput
                color={colorPicker}
                onChange={setColorPicker}
                style={{ marginTop: 10 }}
              />
              <button
                onClick={() => saveNewColor(whichCategoryID)}
                style={{
                  marginTop: 10,
                  paddingLeft: 30,
                  paddingRight: 30,
                  paddingTop: 10,
                  paddingBottom: 10,
                  fontSize: 16,
                  backgroundColor: "#67d7cc",
                  borderRadius: 5,
                }}
              >
                {t("common-button-update")}
              </button>
            </div>
          </Modal>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            style={{
              fontFamily: "inherit",
              fontSize: "inherit",
              textAlign: "right",
              color: "white",
              border: 1,
              borderRadius: 20,
              padding: 20,
              marginTop: 40,
              backgroundColor: "blue",
              cursor: "pointer",
              fontWeight: "bold",
            }}
            onClick={() => close()}
          >
            {t("common-button-close")}
          </button>
        </div>
        <div style={{ paddingBottom: 150 }} />
      </div>
      <EditFieldModal
        isOpen={editingCatName}
        title={t("generic-list-category-edit-name")}
        text={selectedCatName}
        onClickCancel={() => setEditingCatName(false)}
        onClickConfirm={updateNewCategoryName}
        updateText={setUpdatedCatName}
      />
    </Modal>
  );
};

import React, { useEffect } from "react";

export const SquareAd = () => {
  useEffect(() => {
    // Load the ad script
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
    // Reload ads every 2 minutes

    // Cleanup the interval when the component is unmounted
  }, []); // Empty dependency array ensures useEffect runs only once

  return (
    <div>
      {/* Your component content */}
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-9685886004588833"
        data-ad-slot="3206330769"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
};

export const commonGroceryItemsFrance = [
  { category: "100100", name: "Nourriture pour bébé" }, // Baby Food
  { category: "100100", name: "Lingettes pour bébé" }, // Baby Wipes
  { category: "100100", name: "Couches pour bébé" }, // Baby Diapers
  { category: "100100", name: "Lait maternisé" }, // Baby Formula
  { category: "100100", name: "Lotion pour bébé" }, // Baby Lotion
  { category: "100200", name: "Pain" }, // Bread
  { category: "100200", name: "Croissants" }, // Croissants
  { category: "100200", name: "Baguette" }, // Baguette
  { category: "100200", name: "Brioche" }, // Brioche
  { category: "100200", name: "Pain complet" }, // Whole Wheat Bread
  { category: "100300", name: "Farine" }, // Flour
  { category: "100300", name: "Sucre" }, // Sugar
  { category: "100300", name: "Levure" }, // Yeast
  { category: "100300", name: "Chocolat à pâtisser" }, // Baking Chocolate
  { category: "100300", name: "Poudre d'amande" }, // Almond Powder
  { category: "100400", name: "Eau minérale" }, // Mineral Water
  { category: "100400", name: "Jus d'orange" }, // Orange Juice
  { category: "100400", name: "Café" }, // Coffee
  { category: "100400", name: "Thé" }, // Tea
  { category: "100400", name: "Soda" }, // Soda
  { category: "100500", name: "Détergent" }, // Detergent
  { category: "100500", name: "Liquide vaisselle" }, // Dishwashing Liquid
  { category: "100500", name: "Nettoyant multi-usage" }, // All-purpose Cleaner
  { category: "100500", name: "Essuie-tout" }, // Paper Towels
  { category: "100500", name: "Sacs poubelle" }, // Trash Bags
  { category: "100600", name: "Sauce tomate" }, // Tomato Sauce
  { category: "100600", name: "Moutarde" }, // Mustard
  { category: "100600", name: "Mayonnaise" }, // Mayonnaise
  { category: "100600", name: "Vinaigrette" }, // Salad Dressing
  { category: "100600", name: "Ketchup" }, // Ketchup
  { category: "100700", name: "Lait" }, // Milk
  { category: "100700", name: "Beurre" }, // Butter
  { category: "100700", name: "Fromage" }, // Cheese
  { category: "100700", name: "Yaourt" }, // Yogurt
  { category: "100700", name: "Crème" }, // Cream
  { category: "100800", name: "Pizza surgelée" }, // Frozen Pizza
  { category: "100800", name: "Légumes surgelés" }, // Frozen Vegetables
  { category: "100800", name: "Glace" }, // Ice Cream
  { category: "100800", name: "Plats préparés surgelés" }, // Frozen Prepared Meals
  { category: "100800", name: "Frites surgelées" }, // Frozen Fries
  { category: "100900", name: "Pommes" }, // Apples
  { category: "100900", name: "Bananes" }, // Bananas
  { category: "100900", name: "Tomates" }, // Tomatoes
  { category: "100900", name: "Salade" }, // Lettuce
  { category: "100900", name: "Carottes" }, // Carrots
  { category: "100900", name: "Citrons" }, // Lemons
  { category: "100900", name: "Courgettes" }, // Zucchini
  { category: "100900", name: "Aubergines" }, // Eggplants
  { category: "100900", name: "Poivrons" }, // Bell Peppers
  { category: "100900", name: "Concombres" }, // Cucumbers
  { category: "101100", name: "Bœuf" }, // Beef
  { category: "101100", name: "Porc" }, // Pork
  { category: "101100", name: "Poulet" }, // Chicken
  { category: "101100", name: "Dinde" }, // Turkey
  { category: "101100", name: "Agneau" }, // Lamb
  { category: "101200", name: "Paracétamol" }, // Paracetamol
  { category: "101200", name: "Ibuprofène" }, // Ibuprofen
  { category: "101200", name: "Pansements" }, // Band-Aids
  { category: "101200", name: "Thermomètre" }, // Thermometer
  { category: "101200", name: "Antiseptique" }, // Antiseptic
  { category: "101500", name: "Pâtes" }, // Pasta
  { category: "101500", name: "Riz" }, // Rice
  { category: "101500", name: "Huile d'olive" }, // Olive Oil
  { category: "101500", name: "Conserves" }, // Canned Goods
  { category: "101500", name: "Lentilles" }, // Lentils
  { category: "101700", name: "Nourriture pour chiens" }, // Dog Food
  { category: "101700", name: "Nourriture pour chats" }, // Cat Food
  { category: "101700", name: "Litière pour chats" }, // Cat Litter
  { category: "101700", name: "Jouets pour animaux" }, // Pet Toys
  { category: "101700", name: "Shampoing pour animaux" }, // Pet Shampoo
  { category: "101800", name: "Saumon" }, // Salmon
  { category: "101800", name: "Crevettes" }, // Shrimp
  { category: "101800", name: "Morue" }, // Cod
  { category: "101800", name: "Moules" }, // Mussels
  { category: "101800", name: "Huîtres" }, // Oysters
  { category: "101900", name: "Chips" }, // Chips
  { category: "101900", name: "Chocolat" }, // Chocolate
  { category: "101900", name: "Biscuits" }, // Biscuits
  { category: "101900", name: "Bonbons" }, // Candy
  { category: "101900", name: "Noix" }, // Nuts
  { category: "102000", name: "Sel" }, // Salt
  { category: "102000", name: "Poivre" }, // Pepper
  { category: "102000", name: "Curry" }, // Curry
  { category: "102000", name: "Paprika" }, // Paprika
  { category: "102000", name: "Cannelle" }, // Cinnamon
  { category: "102500", name: "Basilic" }, // Basil
  { category: "102500", name: "Persil" }, // Parsley
  { category: "102500", name: "Thym" }, // Thyme
  { category: "102500", name: "Romarin" }, // Rosemary
  { category: "102500", name: "Ciboulette" }, // Chives
  { category: "102500", name: "Ciboulette" }, // Chives
  { category: "102500", name: "Coriandre" }, // Coriander
  { category: "102500", name: "Menthe" }, // Mint
  { category: "100900", name: "Pommes de terre" }, // Potatoes
  { category: "100900", name: "Oignons" }, // Onions
  { category: "100900", name: "Champignons" }, // Mushrooms
  { category: "100900", name: "Épinards" }, // Spinach
  { category: "100900", name: "Brocolis" }, // Broccoli
  { category: "101100", name: "Saucisses" }, // Sausages
  { category: "101100", name: "Bacon" },
  { category: "101100", name: "Escalope de poulet" }, // Chicken Cutlets
  { category: "101100", name: "Steak haché" }, // Ground Beef
  { category: "101100", name: "Côtelettes d'agneau" }, // Lamb Chops
  { category: "101200", name: "Crème solaire" }, // Sunscreen
  { category: "101200", name: "Baume à lèvres" }, // Lip Balm
  { category: "101200", name: "Aspirine" }, // Aspirin
  { category: "101200", name: "Gel hydroalcoolique" }, // Hand Sanitizer
  { category: "101200", name: "Vernis à ongles" }, // Nail Polish
  { category: "101500", name: "Céréales" }, // Cereal
  { category: "101500", name: "Confiture" }, // Jam
  { category: "101500", name: "Miel" }, // Honey
  { category: "101500", name: "Vinaigre" }, // Vinegar
  { category: "101500", name: "Biscottes" }, // Crispbread
  { category: "101700", name: "Croquettes pour chiens" }, // Dog Kibble
  { category: "101700", name: "Croquettes pour chats" }, // Cat Kibble
  { category: "101700", name: "Poisson rouge" }, // Goldfish Food
  { category: "101700", name: "Laisse pour chiens" }, // Dog Leash
  { category: "101700", name: "Collier pour chats" }, // Cat Collar
  { category: "101800", name: "Filet de sole" }, // Sole Fillet
  { category: "101800", name: "Bar" }, // Sea Bass
  { category: "101800", name: "Truite" }, // Trout
  { category: "101800", name: "Homard" }, // Lobster
  { category: "101800", name: "Crabe" }, // Crab
  { category: "101900", name: "Noix de cajou" }, // Cashew Nuts
  { category: "101900", name: "Barres de céréales" }, // Cereal Bars
  { category: "101900", name: "Popcorn" },
  { category: "101900", name: "Fruits secs" }, // Dried Fruits
  { category: "101900", name: "Pistaches" }, // Pistachios
  { category: "102000", name: "Herbes de Provence" }, // Herbes de Provence
  { category: "102000", name: "Safran" }, // Saffron
  { category: "102000", name: "Curcuma" }, // Turmeric
  { category: "102000", name: "Gingembre moulu" }, // Ground Ginger
  { category: "102000", name: "Ail en poudre" }, // Garlic Powder
  { category: "100200", name: "Pâtisseries" }, // Pastries
  { category: "100200", name: "Quiche" },
  { category: "100200", name: "Tarte" }, // Pie
  { category: "100200", name: "Madeleines" },
  { category: "100200", name: "Macarons" },
  { category: "100300", name: "Bicarbonate de soude" }, // Baking Soda
  { category: "100300", name: "Extrait de vanille" }, // Vanilla Extract
  { category: "100300", name: "Levure chimique" }, // Baking Powder
  { category: "100300", name: "Gélatine" }, // Gelatin
  { category: "100300", name: "Pépites de chocolat" }, // Chocolate Chips
  { category: "100400", name: "Vin rouge" }, // Red Wine
  { category: "100400", name: "Vin blanc" }, // White Wine
  { category: "100400", name: "Bière" }, // Beer
  { category: "100400", name: "Champagne" },
  { category: "100400", name: "Eau gazeuse" }, // Sparkling Water
  { category: "100500", name: "Savon" }, // Soap
  { category: "100500", name: "Shampooing" }, // Shampoo
  { category: "100500", name: "Nettoyant pour vitres" }, // Window Cleaner
  { category: "100500", name: "Désodorisant" }, // Air Freshener
  { category: "100500", name: "Eponge" }, // Sponge
  { category: "100600", name: "Pesto" },
  { category: "100600", name: "Sauce Alfredo" }, // Alfredo Sauce
  { category: "100600", name: "Sauce soja" }, // Soy Sauce
  { category: "100600", name: "Sauce piquante" }, // Hot Sauce
  { category: "100600", name: "Sauce hollandaise" },
  { category: "100900", name: "Champignons" }, // Mushrooms
  { category: "100900", name: "Concombres" }, // Cucumbers
  { category: "100900", name: "Courges" }, // Squash
  { category: "100900", name: "Aubergines" }, // Eggplants
  { category: "100900", name: "Poivrons" }, // Bell Peppers
  { category: "100900", name: "Patates douces" }, // Sweet Potatoes
  { category: "100900", name: "Navets" }, // Turnips
  { category: "100900", name: "Épinards" }, // Spinach
  { category: "100900", name: "Artichauts" }, // Artichokes
  { category: "100900", name: "Poireaux" }, // Leeks
  { category: "101100", name: "Agneau" }, // Lamb
  { category: "101100", name: "Foie" }, // Liver
  { category: "101100", name: "Saucisson" }, // Sausage
  { category: "101100", name: "Boudin" }, // Blood Sausage
  { category: "101100", name: "Jambon" }, // Ham
  { category: "101100", name: "Crevettes" }, // Shrimp
  { category: "101100", name: "Poulpe" }, // Octopus
  { category: "101100", name: "Moules" }, // Mussels
  { category: "101100", name: "Morue" }, // Cod
  { category: "101100", name: "Saumon" }, // Salmon
  { category: "101200", name: "Huile d'olive" }, // Olive Oil
  { category: "101200", name: "Vinaigre" }, // Vinegar
  { category: "101200", name: "Confiture" }, // Jam
  { category: "101200", name: "Miel" }, // Honey
  { category: "101200", name: "Nougat" }, // Nougat
  { category: "101500", name: "Riz" }, // Rice
  { category: "101500", name: "Lentilles" }, // Lentils
  { category: "101500", name: "Pois chiches" }, // Chickpeas
  { category: "101500", name: "Pâtes" }, // Pasta
  { category: "101500", name: "Farine" }, // Flour
  { category: "101700", name: "Nourriture pour chiens" }, // Dog Food
  { category: "101700", name: "Nourriture pour chats" }, // Cat Food
  { category: "101700", name: "Litière pour chats" }, // Cat Litter
  { category: "101700", name: "Jouets pour animaux" }, // Pet Toys
  { category: "101700", name: "Shampooing pour animaux" }, // Pet Shampoo
  { category: "101800", name: "Thon" }, // Tuna
  { category: "101800", name: "Truite" }, // Trout
  { category: "101800", name: "Sole" }, // Sole
  { category: "101800", name: "Coquilles Saint-Jacques" }, // Scallops
  { category: "101800", name: "Huîtres" }, // Oysters
  { category: "101900", name: "Gâteaux" }, // Cakes
  { category: "101900", name: "Pâtisseries" }, // Pastries
  { category: "101900", name: "Pain d'épices" }, // Gingerbread
  { category: "101900", name: "Bretzels" }, // Pretzels
  { category: "101900", name: "Massepain" }, // Marzipan
  { category: "102000", name: "Paprika" }, // Paprika
  { category: "102000", name: "Safran" }, // Saffron
  { category: "102000", name: "Cumin" }, // Cumin
  { category: "102000", name: "Cannelle" }, // Cinnamon
  { category: "102000", name: "Feuilles de laurier" },
];

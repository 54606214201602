import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useUserContext } from "./UserContext";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";

// Renders errors or successfull transactions on the screen.
function Message({ content }) {
  return <p>{content}</p>;
}

export function PayPal({ userId }) {
  const { payPalLifeTimePurchase } = useUserContext();
  const initialOptions = {
    "client-id":
      "ARb29VivMjFX9SJFeey6SA6Opkgl-pZeOCnH6QdaNuDDVhsaCF5KTB4g1BR09GVxk3CRT3HarxTYo1kD",
    "enable-funding": "venmo,card",
    "disable-funding": "paylater",
    "data-sdk-integration-source": "integrationbuilder_sc",
  };

  const { t } = useTranslation();

  const [message, setMessage] = useState("");

  return (
    <div className="App">
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{
            shape: "rect",
            layout: "vertical",
            label: "buynow",
          }}
          createOrder={async () => {
            try {
              const response = await fetch(
                //    "http://127.0.0.1:5001/dopenotes-20ee4/us-central1/paypal/orders",
                "https://us-central1-dopenotes-20ee4.cloudfunctions.net/paypal/orders",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  // use the "body" param to optionally pass additional order information
                  // like product ids and quantities
                  body: JSON.stringify({
                    cart: [
                      {
                        id: "lifetime",
                        quantity: 1,
                        userId: userId,
                      },
                    ],
                  }),
                }
              );

              const orderData = await response.json();
              //  console.log("what is orderData", orderData);

              if (orderData.id) {
                return orderData.id;
              } else {
                const errorDetail = orderData?.details?.[0];
                const errorMessage = errorDetail
                  ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                  : JSON.stringify(orderData);

                throw new Error(errorMessage);
              }
            } catch (error) {
              console.error(error);
              setMessage(`Could not initiate PayPal Checkout...${error}`);
            }
          }}
          onApprove={async (data, actions) => {
            try {
              const response = await fetch(
                // `http://127.0.0.1:5001/dopenotes-20ee4/us-central1/paypal/orders/${data.orderID}/capture`,
                `https://us-central1-dopenotes-20ee4.cloudfunctions.net/paypal/orders/${data.orderID}/capture`,

                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );

              const orderData = await response.json();
              // Three cases to handle:
              //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
              //   (2) Other non-recoverable errors -> Show a failure message
              //   (3) Successful transaction -> Show confirmation or thank you message

              const errorDetail = orderData?.details?.[0];

              if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                return actions.restart();
              } else if (errorDetail) {
                // (2) Other non-recoverable errors -> Show a failure message
                throw new Error(
                  `${errorDetail.description} (${orderData.debug_id})`
                );
              } else {
                // (3) Successful transaction -> Show confirmation or thank you message
                // Or go to another URL:  actions.redirect('thank_you.html');

                const transaction =
                  orderData.purchase_units[0].payments.captures[0];

                setMessage(
                  `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`
                );
                await payPalLifeTimePurchase(transaction.id);
                toast(t("thank-you-for-support"), {
                  duration: 3000,
                  position: "top-center",
                  style: {
                    backgroundColor: "lightgreen",
                    border: "1px solid #713200",
                    fontSize: "16px",
                    padding: "10px",
                    color: "black",
                  },
                });
                setTimeout(() => {
                  window.location.reload();
                }, 3500);
                /*  console.log(
                  "Capture result",
                  orderData,
                  JSON.stringify(orderData, null, 2)
                );*/
              }
            } catch (error) {
              console.error(error);
              setMessage(
                `Sorry, your transaction could not be processed...${error}`
              );
              toast(t("purchase-fail"), {
                duration: 3000,
                position: "top-center",
                style: {
                  backgroundColor: "pink",
                  border: "1px solid #713200",
                  fontSize: "16px",
                  padding: "10px",
                  color: "black",
                },
              });
            }
          }}
        />
      </PayPalScriptProvider>
      <Message content={message} />
    </div>
  );
}

import { useState, useEffect } from "react";
import {
  MdColorLens,
  MdDeleteForever,
  MdEdit,
  MdEmail,
  // MdFullscreen,
  // MdCloseFullscreen,
  // MdNotifications,
} from "react-icons/md";
import classes from "../notes.module.css";
import Linkify from "linkify-react";
import linkifyHtml from "linkify-html";

//import TextareaAutosize from "react-textarea-autosize";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { CirclePicker, GithubPicker } from "react-color";
import { useUserContext } from "Authentication";
//import redpin from "../../Assets/redpin.png";
import { ConfirmationModal } from "Common";
import { AddNote } from "./AddNote";
//import { CustomNotification } from "Reminders/CustomNotification";
import DOMPurify from "dompurify";
import { useParams, useLocation } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const Note = ({
  id,
  text,
  date,
  color,
  tags,
  handleDeleteNote,
  handleEditNote,
  isExpired,
  isLanding,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [noteText, setNoteText] = useState(text);
  const [loadedNote, setLoadedNote] = useState({
    text: text,
    color: color,
    tags: tags,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const characterLimit = 10000;
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { productId } = useParams();
  const { editNoteColor } = useUserContext();
  //console.log("productId", productId);
  const handleChange = (event) => {
    if (characterLimit - event.target.value.length >= 0) {
      setNoteText(event.target.value);
    }
  };
  const location = useLocation();
  const { t } = useTranslation();
  const handleColorChangeComplete = (color, event) => {
    //	console.log("which color", color.hex)
    editNoteColor(id, color.hex);
    setIsColorPickerOpen(false);
  };
  const [isNative, setIsNative] = useState(false);

  const TagsList = () => {
    try {
      if (Array.isArray(tags)) {
        // Sort tags alphabetically by text property
        const sortedTags = [...tags].sort((a, b) =>
          a.text.localeCompare(b.text)
        );

        return (
          <>
            Tags:
            {sortedTags.map((tag, index) => (
              <span
                key={index}
                style={{
                  borderRadius: 10,
                  padding: 5,
                  marginLeft: 5,
                  backgroundColor: "lightblue",
                }}
              >
                {tag.text}
              </span>
            ))}
          </>
        );
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const close = () => {
    setIsOpen(false);
  };

  //export to PDF

  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("download.pdf");
    });
  };

  const handleSaveClick = (note) => {
    if (note.text.trim().length > 0) {
      setNoteText(note.text);
      handleEditNote(id, note.text, note.tags, note.color);
      setIsEditing(false);
    }
  };

  const EditNote = () => {
    //	console.log("Edit Clicked")

    setIsEditing(true);

    //handleEditNote(id, noteText)
  };

  const updateNote = async (note) => {
    //console.log("what is the notes", note);
    setNoteText(note.text);

    await handleEditNote(id, note.text, note.tags, note.color);
  };

  const DeleteNoteConfirmation = () => {
    handleDeleteNote(id);
  };

  const BGCOLOR = color;

  const Newcolors = [
    "#EB9694",
    "pink",
    "#FAD0C3",
    "#fef68a",
    "#C1E1C5",
    "#BEDADC",
    "#C4DEF6",
    "#BED3F3",
    "#D4C4FB",
    "plum",
    "azure",
    "floralwhite",
  ];
  //const Randomposition = Math.round(Math.random()) * 0.5 - 0.25;

  //new reminder start.  This is a work in progree, I need to update the APP for remove habit language first.

  const CloseMenu = () => {
    setIsSchedulingReminder(false);
  };

  const [isSchedulingReminder, setIsSchedulingReminder] = useState(false);

  const validatedHtml = DOMPurify.sanitize(noteText);

  // console.log("what is note tags", tags);

  useEffect(() => {
    if (window.isNative) {
      setIsNative(true);
    } else {
      setIsNative(false);
    }
    var current = new Date();
    const time = current.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    setLoadedNote({
      text: text,
      color: color,
      tags: tags,
    });

    //window.scrollTo(0, 0);

    return () => {};
  }, [text, color, tags]);

  const emailNote = () => {
    const body = encodeURIComponent(noteText);
    window.location.href = `mailto:?subject=&body=${body}`;
  };

  const removeTags = (str) => {
    if (typeof str === "string") {
      return str.replace(/<[^>]+>/g, "");
    }
    return str;
  };

  return (
    <>
      <div
        // className={classes.note}
        onDoubleClick={EditNote}
        style={{
          backgroundColor: BGCOLOR,
          borderRadius: 5,
          paddingTop: location.pathname != "/" ? 20 : 10,
          padding: location.pathname != "/" ? 20 : 5,
          maxHeight: location.pathname != "/" ? "100%" : 200,
          overflow:
            location.pathname == "/" ||
            (location.pathname == "/notes" && isNative)
              ? "hidden"
              : "auto",
          paddingBottom: 10,
        }}
      >
        {location.pathname.startsWith("/notes/") ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "right",
              justifyContent: "right",
            }}
          >
            <div
              style={{
                color: "blue",
                textDecoration: "underline",
                textAlign: "right",
                paddingRight: 20,
                fontSize: 14,
                cursor: "pointer",
              }}
              onClick={EditNote}
            >
              Edit
            </div>
            {!isNative ? (
              <div
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  textAlign: "right",
                  paddingRight: 20,
                  fontSize: 14,
                  cursor: "pointer",
                }}
                onClick={printDocument}
              >
                PDF Export
              </div>
            ) : null}
          </div>
        ) : null}
        {!isEditing ? (
          <div>
            <div
              id="divToPrint"
              style={{
                // backgroundColor: "#f5f5f5",
                // width: "210mm",
                //  minHeight: "297mm",
                marginLeft: "auto",
                marginRight: "auto",
                padding: 20,
              }}
            >
              <Linkify options={{ target: "_blank" }}>
                <span
                  style={{
                    fontSize: "inherit",
                    //  color: "navy",
                    overflow:
                      location.pathname == "/" ||
                      (location.pathname == "/notes" && isNative)
                        ? "hidden"
                        : "auto",
                    textOverflow: "ellipsis",
                  }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: linkifyHtml(validatedHtml),
                    }}
                  />
                </span>
              </Linkify>
            </div>
          </div>
        ) : (
          <>
            {/*}	<img style={{height: 25, width: 25,marginRight: "50%",
		marginLeft:"50%", transform: `rotate(${Randomposition}deg)`}} src={redpin} alt="RedPin" />*/}
            <AddNote
              handleAddNote={updateNote}
              setIsAdding={() => setIsEditing(false)}
              isAdding={isEditing}
              value={loadedNote}
              isNew={false}
            />
          </>
        )}
        {isColorPickerOpen ? (
          <div style={{ paddingTop: 10, paddingBottom: 10 }}>
            <GithubPicker
              onChangeComplete={handleColorChangeComplete}
              colors={Newcolors}
            />
          </div>
        ) : null}

        <div className={classes.noteFooterDate}>
          <div style={{ width: "100%" }}>
            {productId != undefined ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "right",
                  justifyContent: "space-between",
                }}
              >
                <MdEmail
                  onClick={() =>
                    (window.location.href = `mailto:?body=${removeTags(text)}`)
                  }
                  className="edit-icon"
                  size="1.3em"
                  style={{ paddingRight: 10 }}
                />
                <MdColorLens
                  onClick={() => setIsColorPickerOpen(true)}
                  className="edit-icon"
                  size="1.3em"
                  style={{ paddingRight: 10 }}
                />

                <MdEdit
                  onClick={EditNote}
                  className="edit-icon"
                  size="1.3em"
                  style={{ paddingRight: 10 }}
                />

                {/*}   <MdNotifications
                  onClick={() => setIsSchedulingReminder(true)}
                  className="edit-icon"
                  size="1.3em"
                  style={{ paddingRight: 10 }}
                />*/}
                <MdDeleteForever
                  onClick={() => setConfirmModalOpen(true)}
                  className="delete-icon"
                  size="1.3em"
                />
              </div>
            ) : null}

            <div
              style={{
                flexDirection: "column",
                textAlign: "center",
                overflow: "clip",
                paddingBottom: 10,
              }}
            >
              <small>
                <hr />

                {moment(date).format("MMMM DD, YYYY @ HH:mm A")}
              </small>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                userSelect: "none",
              }}
            >
              <small>{TagsList()}</small>
            </div>
          </div>
        </div>
      </div>
      {isLanding ? <div style={{ paddingBottom: 100 }} /> : null}

      <ConfirmationModal
        isOpen={confirmModalOpen}
        text={t("notes-note-removeconfirmation")}
        onClickCancel={() => setConfirmModalOpen(false)}
        onClickConfirm={DeleteNoteConfirmation}
      />
    </>
  );
};

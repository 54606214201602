import { useState, useEffect, useCallback, useRef } from "react";
import { storage } from "Config";

import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faClose,
  faCheck,
  faCalendarDay,
  faEdit,
  faTrash,
  faBell,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
//import CheckMark from "../../assets/img/CheckMark.svg";

import { Modal } from "Common";

import { WeekDays } from "../HabitsPage/style";

import { DayOfTheWeek } from "../DayOfTheWeek";

import classes from "../../../Journal/modals/EventsModal.module.css";

import TimePicker from "react-time-picker";
import DatePicker from "react-date-picker";
import toast, { Toaster } from "react-hot-toast";
import { google, outlook, office365, yahoo, ics } from "calendar-link";
import { useUserContext } from "Authentication";
import { useTranslation } from "react-i18next";
export function ReminderForAddNewHabit({
  data,
  user,
  isReminder,
  setIsReminder,
  isEdit,
}) {
  const [isNativeApp, setIsNativeApp] = useState(false);

  const [valueTime, setValueTime] = useState(data.time ? data.time : "12:00");

  const rRule = useRef("FREQ=DAILY");
  const { addNewHabit, deleteHabit, editHabit } = useUserContext();
  const [reminderDoneModal, setReminderDoneModal] = useState(false);
  const icsURL = useRef("");
  const [startTime, setStartTime] = useState(new Date());

  //console.log("what is data", data);
  const { t } = useTranslation();
  //calcuate preset end date
  const todayDate = new Date();
  const add60Days = new Date(todayDate);
  add60Days.setDate(todayDate.getDate() + 60);
  //console.log("what is lastDay", data.lastDay);
  const editLastDay =
    data.lastDay != undefined
      ? data.lastDay.seconds != undefined
        ? new Date(data.lastDay.seconds * 1000)
        : data.lastDay
      : add60Days;
  const editDurationMinutes = data.durationMinutes ? data.durationMinutes : 15;

  const [lastDay, setLastDay] = useState(isEdit ? editLastDay : add60Days);
  const [reminder, setReminder] = useState({
    title: data.name,
    description: t("sections-habits-reminder-message"),

    start: moment(startTime).format("YYYY-MM-DD") + valueTime,
    duration: [1, "hour"],
    rRule: "FREQ=DAILY",
  });

  const [isAlohaPlanner, setIsAlohaPlanner] = useState(false);
  const [isDopeNotesApp, setIsDopeNotesApp] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  const [exportStartDate, setExportStartDate] = useState(new Date());
  const [exportEndDate, setExportEndDate] = useState(new Date());
  const [exportTitle, setExportTitle] = useState("");
  const [exportDays, setExportDays] = useState([]);

  const CloseMenu = () => {
    //setIsHabitSchedulingReminder(false);
    setIsReminder(false);
  };

  const closeReminderFinish = () => {
    setReminderDoneModal(false);
  };

  //Planner Update
  const [durationOption, setDurationOption] = useState(
    isEdit ? editDurationMinutes : 15
  );
  const durationOptions = [
    { label: "5 " + t("common-text-minutes"), value: 5 },
    { label: "10 " + t("common-text-minutes"), value: 10 },
    { label: "15 " + t("common-text-minutes"), value: 15 },
    { label: "30 " + t("common-text-minutes"), value: 30 },
    { label: "45 " + t("common-text-minutes"), value: 45 },
    { label: "1 " + t("common-text-hours"), value: 60 },
    { label: "90 " + t("common-text-minutes"), value: 90 },
    { label: "2 " + t("common-text-hours"), value: 120 },
    { label: "3 " + t("common-text-hours"), value: 180 },
    { label: "6 " + t("common-text-hours"), value: 360 },
  ];

  const convertDate = () => {
    const convertedDate = moment(new Date(lastDay)).format("MMMM DD, YYYY");
    return convertedDate;
  };

  useEffect(() => {
    // console.log("days", data.days);
    var current = new Date();
    const time = current.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    if (data.time != undefined) {
      var fixZeroMinutes = data.time.padStart(2, "0");
      setValueTime(fixZeroMinutes);
    }

    if (isEdit && data.time != undefined) {
      var fixZeroMinutes = data.time.padStart(2, "0");
      setValueTime(fixZeroMinutes);
    }

    //getTotalDaysTracked()
    if (window.isNative) {
      // Post the message back to expo
      setIsNativeApp(true);
    }

    if (window.isAloha) {
      setIsAlohaPlanner(true);
    }

    if (window.isDopeNotesApp) {
      setIsDopeNotesApp(true);
    }

    if (window.isIOS) {
      setIsIOS(true);
    }
    // getStatusLogs();

    //console.log("how many", Total_Days_Habit_Tracking)

    //const datesCleaned = statusLog.map((date) => new Date (date));
    //  var getStreak = summary(datesCleaned)
    //   setStreak(getStreak)

    //console.log("streaks", getStreak)
    //check for new day

    return () => {};
  }, [data]);

  const HabitScheduler = () => {
    if (valueTime != null) {
      // Define an array of numbers from 0-6
      //  const days = [0, 1, 2, 3, 4, 5, 6];

      // Map the array of numbers to an array of corresponding day abbreviations
      const dayAbbreviations = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
      const shortDayAbbreviations = data.days.map(
        (dayIndex) => dayAbbreviations[dayIndex]
      );

      // Generate the RRULE string using the mapped array of day abbreviations
      rRule.current = `FREQ=WEEKLY;BYDAY=${shortDayAbbreviations};INTERVAL=1`;

      // console.log(rRule); // Output: FREQ=WEEKLY;WKST=SU;BYDAY=SU,MO,TU,WE,TH,FR,SA
      //generateICAL();

      if (window.isNative) {
        // Post the message back to expo
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "HabitReminderScheduler",
            name: data.name,
            days: data.days,
            time: valueTime,
            habitid: data.id,
          })
        );
      }

      setReminder({
        title: data.name,
        description: t("sections-habits-reminder-message"),
        start: moment(startTime).format("YYYY-MM-DD") + valueTime,
        duration: [durationOption, "minute"],
        rRule: rRule.current,
      });

      // console.log("RRULE is what", rRule.current);

      //Planner Code
      //Duration is used for End event Time for the planner
      const selectedDurationdOption = durationOptions.find(
        (option) => option.value === parseInt(durationOption)
      );
      const today = new Date();
      const [hours, minutes] = valueTime.split(":");

      const plannerTime = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        hours,
        minutes,
        0
      );
      //for calendar

      const durationTimeInMinutes = selectedDurationdOption
        ? selectedDurationdOption.value
        : 0;
      const durationTimeInMillis = durationTimeInMinutes * 60 * 1000;

      const endDate = new Date(plannerTime.getTime() + durationTimeInMillis);

      //setting React calendar
      setExportEndDate(endDate);
      setExportStartDate(plannerTime);
      setExportTitle(data.name);
      setExportDays(data.days);
      //end

      const durationHabits =
        endDate.getHours().toString() + ":" + endDate.getMinutes().toString();

      const NewHabitDate = moment(new Date()).format("MM/DD/YYYY");

      if (!isEdit) {
        addNewHabit(
          data.name,
          data.days,
          data.isTrackingReps,
          data.RepsLabel,
          NewHabitDate,
          0,
          valueTime,
          durationHabits,
          durationOption,
          data.isPlanner,
          lastDay
        );
      }

      if (isEdit) {
        const habit =
          data.isTrackingReps != undefined ? data.isTrackingReps : false;
        const habitLabel = data.RepsLabel != undefined ? data.RepsLabel : "";
        const isPlanner = data.isPlanner != undefined ? data.isPlanner : true;
        //check for legacy

        editHabit(
          data.id,
          data.name,
          data.days,
          habit,
          habitLabel,
          isPlanner,
          valueTime,
          durationHabits,
          durationOption,
          lastDay
        );
      }

      toast("New habit added", {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "lightgreen",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });

      setReminderDoneModal(true);
      setIsReminder(false);
      // CloseMenu();
    }
  };

  const GoogleCalendar = () => {
    //@ts-ignore
    window.open(google(reminder));
  };

  const ICSCalendar = () => {
    //@ts-ignore
    window.open(ics(reminder));
  };

  const addEventDeviceCalendar = () => {
    var days = exportDays.map((number) => number + 1);

    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "addEventDeviceCalendar",
        titleName: exportTitle,
        notes: "",
        startDate: moment(exportStartDate)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        endDate: moment(exportEndDate)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        allDay: false,
        interval: 1,
        weekPositionInMonth: 0,
        dateForMonthlyYearly: 0,
        repeatValue: "weekly",
        monthlyType: "day",
        days: days,
      })
    );
  };

  const maxLength = 50; // Maximum length of text to display
  const truncatedText = (text) => {
    const newText =
      text.length > maxLength
        ? text.substring(0, maxLength) + "..." // Truncate text to maximum length
        : text;
    return newText;
  };

  return (
    <div>
      <Modal
        isOpen={isReminder}
        close={CloseMenu}
        style={{ overlay: { zIndex: 100 } }}
      >
        <div style={{ padding: 20 }}>
          <div style={{ paddingTop: 5, paddingBottom: 20, paddingLeft: 5 }}>
            {/*}   <div
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "right",
                marginRight: 10,
                marginTop: 5,
                cursor: "pointer",
              }}
            >
              {" "}
              <FontAwesomeIcon
                icon={faClose}
                size="2x"
                onClick={CloseMenu}
                style={{ cursor: "pointer" }}
              />
            </div>*/}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: 20,
                marginTop: 0,
                paddingBottom: 10,
              }}
            ></div>
          </div>
          <h1
            style={{
              // paddingLeft: 15,
              fontSize: 22,
              textAlign: "center",
              marginTop: -15,
              lineHeight: 1.2,
              paddingBottom: 10,
              userSelect: "none",
            }}
          >
            {t("sections-habits-reminder-title")}{" "}
            <span style={{ color: "blue" }}>{data.name.toUpperCase()}</span>{" "}
          </h1>
          {/*}  <p
            style={{
              //  paddingLeft: 15,
              fontSize: 18,
              marginTop: -10,
              textAlign: "center",
            }}
          >
            Please select a start time for your {data.name.toUpperCase()} and
            estimated duration for the planner. It'll appear on the following
            day(s).
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: 10,
            }}
          >
            <WeekDays>
              {Array.from(Array(7).keys()).map((element) => (
                <DayOfTheWeek
                  key={element}
                  state={data}
                  createHabit={false}
                  weekday={element}
                />
              ))}
            </WeekDays>
          </div>{" "}
              <hr /> */}
          <div
            style={{
              paddingBottom: 0,
              paddingTop: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TimePicker
              onChange={(e) => setValueTime(e)}
              value={valueTime}
              maxDetail="minute"
              disableClock
              clearIcon={null}
            />
            <>
              {" "}
              <label
                style={{ paddingTop: 20, paddingBottom: 5, userSelect: "none" }}
              >
                {t("sections-habits-reminder-duration")}
              </label>
              <select
                value={durationOption}
                onChange={(e) => setDurationOption(e.target.value)}
                style={{ fontSize: 14, marginTop: 10, padding: 10 }}
              >
                <option value="0">0 Minutes</option>
                {durationOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>{" "}
              {/*}    <label
                style={{
                  paddingTop: 20,
                  paddingBottom: 15,
                  userSelect: "none",
                }}
              >
                {t("sections-habits-reminder-lastdaytarget")}{" "}
                <span style={{ fontSize: 12, userSelect: "none" }}>
                  ({t("sections-habits-reminder-lastdaytarget-default")})
                </span>
              </label>
              <DatePicker
                clearIcon={null}
                disableCalendar={false}
                style={{ padding: 0 }}
                onChange={(e) => {
                  setLastDay(e);
                }}
                value={lastDay}
                format="y MMM dd"
              />*/}
            </>
          </div>
          <div className={classes.modalActions}>
            <button
              type="button"
              onClick={CloseMenu}
              style={{
                cursor: "pointer",
                fontFamily: "inherit",
                fontSize: "inherit",
                backgroundColor: "lightgray",
                color: "gray",
              }}
              //   className={classes.secondaryButton}
            >
              {t("common-button-cancel")}
            </button>
            <button
              type="button"
              onClick={HabitScheduler}
              style={{
                cursor: "pointer",
                fontFamily: "inherit",
                fontSize: "inherit",
                paddingLeft: 15,
                paddingRight: 15,
              }}
              //   className={classes.secondaryButton}
            >
              {isEdit ? t("common-button-update") : t("common-button-save")}
            </button>

            {/*}    <button
              type="button"
              onClick={CloseMenu}
              style={{cursor:"pointer"}}
             // className={classes.secondaryButton}
            >
              Close
 </button> */}
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={reminderDoneModal}
        onRequestClose={closeReminderFinish}
        style={{ overlay: { zIndex: 100 } }}
      >
        {/*}   <div
          style={{
            display: "flex",
            alignItems: "right",
            justifyContent: "right",
            margin: 10,
          }}
        >
          {" "}
          <button
            style={{
              backgroundColor: "transparent",
              borderStyle: "none",
              cursor: "pointer",
              marginTop: 10,
              marginRight: 10,
            }}
            onClick={closeReminderFinish}
          >
            <FontAwesomeIcon icon={faClose} size="3x" />
          </button>
          </div>*/}
        <div
          style={{
            paddingBottom: 150,
            paddingLeft: 20,
            paddingRight: 20,
            userSelect: "none",
          }}
        >
          <h2 style={{ lineHeight: 1.5 }}>
            <FontAwesomeIcon icon={faCalendarDay} size="sm" />{" "}
            {isEdit
              ? t("sections-habits-reminder-habitupdated-label")
              : t("sections-habits-reminder-habitcreated-label")}
          </h2>
          {/*   <p
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              lineHeight: 1.5,
            }}
          >
            Title: {data.name}
          </p>
          <p
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              lineHeight: 1.5,
            }}
          >
            Body: Every small step you take towards your goal is a step closer
            to your dreams.
          </p>*/}

          <p
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              lineHeight: 1.5,
            }}
          >
            {t("sections-habits-reminder-repeatsdaily-label")}
          </p>

          <p
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              lineHeight: 1.5,
            }}
          >
            {t("sections-habits-reminder-time-label")} {valueTime}
          </p>
          <p
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              lineHeight: 1.5,
            }}
          >
            {t("sections-habits-reminder-durationinminutes-label")}{" "}
            {durationOption}
          </p>
          <p
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              lineHeight: 1.5,
            }}
          >
            {t("sections-habits-reminder-lastdaytarget-label")} {convertDate()}
          </p>
          <hr />
          <p
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              lineHeight: 1.5,
            }}
          >
            {t("sections-habits-reminder-thirdpartycalendar-label")}
          </p>
          <div className="reminder-button-grid">
            {isAlohaPlanner || isDopeNotesApp ? (
              <button
                className="reminder-calendar-buttons"
                onClick={addEventDeviceCalendar}
              >
                {isIOS ? "iPhone " : "Android "}Calendar
              </button>
            ) : isNativeApp && !isAlohaPlanner && !isDopeNotesApp ? (
              <div>
                <button
                  className="reminder-calendar-buttons"
                  style={{ opacity: 0.6 }}
                  disabled
                  // onClick={addEventDeviceCalendar}
                >
                  {isIOS ? "iPhone " : "Android "}Calendar
                </button>
                <br />
                <span
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    paddingLeft: 10,
                  }}
                >
                  Please update your app to enable!
                </span>
              </div>
            ) : null}
            {!isNativeApp ? (
              <>
                <button
                  className="reminder-calendar-buttons"
                  onClick={GoogleCalendar}
                >
                  Google Calendar
                </button>

                <button
                  className="reminder-calendar-buttons"
                  onClick={ICSCalendar}
                >
                  ICS/ICAL
                </button>
              </>
            ) : null}

            <button
              style={{
                fontSize: "inherit",
                fontFamily: "inherit",
                color: "white",
                backgroundColor: "#1e79c3",
                borderColor: "white",
                padding: 10,
                borderRadius: 10,
                paddingRight: 30,
                paddingLeft: 30,
                width: 200,
              }}
              onClick={closeReminderFinish}
            >
              Close
            </button>
          </div>
          <div style={{ paddingBottom: 150 }} />
        </div>
      </Modal>
    </div>
  );
}

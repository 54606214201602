import { useEffect, useState } from "react";
import { collection, query, onSnapshot } from "@firebase/firestore";
import { UserContext, useUserContext } from "Authentication";

import { ItemModel } from "./models";

export function useItemSubscriptionToDo() {
  const { getPrivateToDoItems } = useUserContext();
  const [itemsToDo, setItemsToDo] = useState<ItemModel[]>([]);
  const [addedItemsToDo, setAddedItemsToDo] = useState<ItemModel[]>([]);
  const [unaddedItemsToDo, setUnaddedItemsToDo] = useState<ItemModel[]>([]);

  useEffect(() => {
    setAddedItemsToDo(itemsToDo.filter((i) => i.added));
    setUnaddedItemsToDo(itemsToDo.filter((i) => !i.added));
  }, [itemsToDo]);

  useEffect(() => {
    const plannerCollection = getPrivateToDoItems();
    const plannerQuery = query(plannerCollection);

    const unsubscribe = onSnapshot(
      plannerQuery,
      (snapshot) => {
        const addedEvents: ItemModel[] = [];

        snapshot.docs.forEach((doc) => {
          const item = { ...doc.data(), id: doc.id } as ItemModel;
          addedEvents.push(item);
        });

        setItemsToDo(addedEvents);
      },
      (error) => {
        window.location.reload();
      }
    );

    return () => unsubscribe();
  }, [getPrivateToDoItems]);

  return { addedItemsToDo, unaddedItemsToDo, allItemsToDo: itemsToDo };
}

import { useEffect, useState } from "react";
import { SortableComponent } from "Splash/RegisteredSplash/SortableComponent";
import { useUserContext } from "Authentication";
import { Modal } from "Common";
import { ConfirmationModal } from "Common";
import { useTranslation } from "react-i18next";
import { RemoteControl } from "./RemoteControl";
import { SquareAd } from "GoogleAdSense/SquareAd";
import { MultiplexAd } from "GoogleAdSense/MultiplexAd";
import { BannerAd } from "GoogleAdSense/BannerAd";

export const SortableListWidget = ({ user, height, isExpired }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [tempDeleteIndex, setTempDeleteIndex] = useState(0);
  const [isCreateNewList, setIsCreateNewList] = useState(false);
  const [isUpdateList, setIsUpdateList] = useState(false);
  const [newListName, setNewListName] = useState("");
  const [updateListIndex, setUpdateListIndex] = useState(null);
  const { updateSortableLists } = useUserContext();
  const { t } = useTranslation();

  const [lists, setLists] = useState(
    user.sortableLists || [
      {
        name: t("dashboard-summary-goals"),
        items: [
          t("dashboard-summary-goals-water"),
          t("dashboard-summary-goals-exercise"),
          t("dashboard-summary-goals-eat"),
          t("dashboard-summary-goals-money"),
        ],
      },
    ]
  );

  const saveCreateNewList = (e) => {
    e.preventDefault();
    if (isUpdateList && updateListIndex !== null) {
      updateListName(updateListIndex, newListName);
    } else {
      addList(newListName);
    }
    setIsCreateNewList(false);
    setIsUpdateList(false);
    setNewListName("");
  };

  const cancelCreateNewList = () => {
    setIsCreateNewList(false);
    setIsUpdateList(false);
    setNewListName("");
  };

  const addList = (newListTitle) => {
    const tempList = [...lists, { name: newListTitle, items: [] }];
    setLists(tempList);
    updateSortableLists(tempList);
  };

  const deleteList = () => {
    const updatedLists = lists.filter((_, index) => index !== tempDeleteIndex);
    setLists(updatedLists);
    updateSortableLists(updatedLists);
  };

  const updateList = (index, updatedItems) => {
    const updatedLists = [...lists];
    updatedLists[index].items = updatedItems;
    setLists(updatedLists);
    updateSortableLists(updatedLists);
  };

  const updateListName = (index, newName) => {
    const updatedLists = [...lists];
    updatedLists[index].name = newName;
    setLists(updatedLists);
    updateSortableLists(updatedLists);
  };

  useEffect(() => {
    setIsMobile(window.isNative);
  }, []);

  return (
    <div>
      <div style={{ paddingTop: 5, userSelect: "none" }} />
      <div
        style={{
          paddingTop: 10,
          paddingBottom: 10,
          display: "flex",
          flexFlow: "wrap",
          flexGrow: "initial",
        }}
      >
        <div style={{ width: "95vw" }}>
          <h2 style={{ textAlign: "center" }} className="h2SlideIn">
            {t("header-menu-sortableList")}
          </h2>
          <div
            style={{
              marginTop: -10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              onClick={() => setIsCreateNewList(true)}
              style={{
                padding: 5,
                paddingLeft: 10,
                paddingRight: 10,
                borderRadius: 20,
                border: 0,
                color: "white",
                backgroundColor: "purple",
                cursor: "pointer",
                fontFamily: "inherit",
              }}
            >
              {t("dashboard-summary-add-list")}
            </button>
          </div>
          <div style={{ paddingBottom: 20 }} />
          <div style={{ height: height - 40, overflowY: "scroll" }}>
            {lists.map((list, index) => (
              <div key={`list-${index}`}>
                <SortableComponent
                  listName={list.name}
                  initialItems={list.items}
                  updateListDB={(updatedItems) =>
                    updateList(index, updatedItems)
                  }
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "right",
                    justifyContent: "right",
                  }}
                >
                  <button
                    onClick={() => {
                      setIsUpdateList(true);
                      setNewListName(list.name);
                      setUpdateListIndex(index);
                      setIsCreateNewList(true);
                    }}
                    style={{
                      fontSize: 10,
                      padding: 5,
                      paddingLeft: 10,
                      paddingRight: 10,
                      borderRadius: 5,

                      border: 0,
                      color: "black",
                      backgroundColor: "lightgrey",
                      fontFamily: "inherit",
                    }}
                  >
                    Edit Name
                  </button>
                  <span style={{ padding: 2 }} />
                  <button
                    onClick={() => {
                      setConfirmModalOpen(true);
                      setTempDeleteIndex(index);
                    }}
                    style={{
                      fontSize: 10,
                      padding: 5,
                      paddingLeft: 10,
                      paddingRight: 10,
                      borderRadius: 5,
                      border: 0,
                      color: "black",
                      backgroundColor: "lightgrey",
                      fontFamily: "inherit",
                    }}
                  >
                    {t("dashboard-summary-delete-list")}
                  </button>
                </div>
                <div style={{ paddingBottom: 50 }} />
              </div>
            ))}
            <div
              style={{
                marginTop: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                onClick={() => setIsCreateNewList(true)}
                style={{
                  fontSize: 18,
                  padding: 5,
                  paddingLeft: 40,
                  paddingRight: 40,
                  paddingTop: 20,
                  paddingBottom: 20,
                  borderRadius: 10,
                  border: 0,
                  color: "white",
                  backgroundColor: "purple",
                  cursor: "pointer",
                  fontFamily: "inherit",
                }}
              >
                {t("dashboard-summary-add-list")}
              </button>
            </div>
            <div style={{ paddingBottom: 150 }} />
          </div>
        </div>
      </div>
      <ConfirmationModal
        isOpen={confirmModalOpen}
        text={t("dashboard-summary-delete-confirmation")}
        onClickCancel={() => setConfirmModalOpen(false)}
        onClickConfirm={() => {
          deleteList();
          setConfirmModalOpen(false);
        }}
      />
      <Modal
        isOpen={isCreateNewList}
        onRequestClose={cancelCreateNewList}
        style={{ overlay: { zIndex: 100 } }}
      >
        <Modal.Body>
          <div style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Modal.Header>
              <h2>{isUpdateList ? "Update List Name" : "Create New List"}</h2>
            </Modal.Header>
            <form onSubmit={saveCreateNewList}>
              <div>
                <input
                  style={{
                    fontSize: 18,
                    padding: 10,
                    width: "75%",
                    alignSelf: "center",
                    alignItems: "center",
                  }}
                  required
                  minLength={1}
                  placeholder={"e.g. Bucket List"}
                  value={newListName}
                  onChange={(e) => setNewListName(e.target.value)}
                />
              </div>
              <div style={{ paddingTop: 20 }}>
                <button
                  onClick={cancelCreateNewList}
                  type="button"
                  className="actions_secondaryButton"
                >
                  {t("common-button-cancel")}
                </button>
                <span style={{ paddingLeft: 5 }} />
                <button type="submit" className="actions_secondaryButton">
                  {t(isUpdateList ? "Save" : "dashboard-summary-save-list")}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

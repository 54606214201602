import React, { useState, useCallback, useRef, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import {
  Link,
  Route,
  useRouteMatch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useItemsToDo } from "ListToDo";
import Select from "react-select";
import { AddPlanner } from "./AddPlanner";
import { PlannerLanding } from "./PlannerLanding";
import { Modal } from "Common";
import { getDay } from "date-fns";
import { SimplePlanner } from "./SimplePlanner";
import "../Journal/react-big-calendar.css";
//import ICAL from "ical.js";
import { useTranslation } from "react-i18next";
import { ICSImporter } from "./ICSImporter";
import { useUserContext } from "Authentication";
import { Spinner } from "Common";
import { BannerAd } from "GoogleAdSense/BannerAd";

//const localizer = momentLocalizer(moment);

const localizer = momentLocalizer(moment);

//localStorage.setItem("calendarStartWeekDay", 1);

export const Planner = ({ data, dataToDo, habits, isExpired }) => {
  const { addItem: baseAddItemToDo } = useItemsToDo();
  const [selectedEvent, setSelectedEvent] = useState();
  //const myEventsStored = localStorage.getItem("myEvents");
  const [myEvents, setEvents] = useState([]);
  //const [myEventsRepeats, setEventsRepeats] = useState([]);
  // const [myToDoReminders, setMyToDoReminders] = useState([]);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [selectedStart, setSelectedStart] = useState(new Date());
  const [selectedEnd, setSelectedEnd] = useState(new Date());
  const [isImportPreview, setIsImportPreview] = useState(false);
  const [CalendarData, setCalendarData] = useState([]);

  const mergedEvents = useRef([...data]);
  const RepeatEvents = useRef([]);
  const ToDoEvents = useRef([]);
  const HabitEvents = useRef([]);
  const location = useLocation();

  const { url } = useRouteMatch();
  const history = useHistory();

  const { t } = useTranslation();

  //from Calendar Setting, it'll change the start day of the week.
  const calendarStartDay =
    localStorage.getItem("calendarStartWeekDay") != undefined
      ? parseInt(localStorage.getItem("calendarStartWeekDay"))
      : 0;

  //console.log("what is day", calendarStartDay);

  moment.updateLocale("en", {
    week: {
      dow: calendarStartDay, // 1 = Monday is the first day of the week
    },
  });

  const { batchUploadPlannerEvents } = useUserContext();

  const removeEventFromImportPreview = (eventToRemove) => {
    setTempEvents(tempEvents.filter((event) => event !== eventToRemove));
  };

  const [isSpinner, setIsSpinner] = useState(false);
  const [isUploadComplete, setIsUploadComplete] = useState(false);

  const minTime = new Date();
  minTime.setHours(0, 0, 0); // Set to 8:00 AM
  const maxTime = new Date();
  maxTime.setHours(23, 59, 0);

  //console.log("fire");

  const [isImportDone, setIsImportDone] = useState(false);

  const handleImportEvents = async () => {
    setIsSpinner(true);
    await batchUploadPlannerEvents(tempEvents);

    setIsSpinner(false);
    setIsImportDone(true);
    isUploadComplete(true);
    setTempEvents([]);
    setIsImportPreview(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const calendarMessages = {
    today: t("common-calendar-today"),
    next: t("common-calendar-next"),
    previous: t("common-calendar-back"),
    month: t("common-calendar-month"),
    day: t("common-calendar-day"),
    agenda: t("common-calendar-agenda"),
  };

  const emptyEvent = {
    note: "",
    time: "17:00",
    name: "",
    isTask: false,
    duration: { lable: "15 Minutes", value: 15 },
    isRepeats: false,
    repeatValue: "none",
    repeatMonthlyValue: "day",
    repeatWeeklyIntervalValue: 1,

    days: [0, 1, 2, 3, 4, 5, 6],
  };

  const getIsHabitsPref = localStorage.getItem("isHabitsPlanner");
  const getIsTasksPref = localStorage.getItem("isTaskPlanner");
  const getIsPlannerAdvanceView = localStorage.getItem("isPlannerAdvanceView");

  //console.log("what is getisHabits", getIsHabitsPref);

  const [isAdvanceView, setIsAdvanceView] = useState(
    getIsPlannerAdvanceView === "true" ? true : false
  );

  const [isHabits, setIsHabits] = useState(
    getIsHabitsPref === "false" ? false : true
  );
  const [isTasks, setIsTasks] = useState(
    getIsTasksPref === "false" ? false : true
  );

  const handleFileUpload = ICSImporter();
  const fileInputRef = useRef(null);

  const [tempEvents, setTempEvents] = useState([]);

  const onFileUploadComplete = (importedEvents) => {
    // Handle the imported events here
    setTempEvents(importedEvents);
    setIsImportPreview(true);
    // console.log(importedEvents);
  };

  const toggleHabits = async () => {
    setIsHabits(!isHabits);
    //loadData();

    let newEvents = [];

    if (!isHabits) {
      // If isHabits is currently false, it will be set to true
      newEvents = isTasks
        ? [
            ...RepeatEvents.current,
            ...ToDoEvents.current,
            ...HabitEvents.current,
          ]
        : [...RepeatEvents.current, ...HabitEvents.current];
    } else {
      // If isHabits is currently true, it will be set to false
      newEvents = isTasks
        ? [...RepeatEvents.current, ...ToDoEvents.current]
        : [...RepeatEvents.current];
    }

    // Merge with existing data while filtering out duplicates
    const mergedData = Array.from(
      new Set([...newEvents].map(JSON.stringify))
    ).map(JSON.parse);
    setCalendarData(mergedData.map(mapToRBCFormat));

    localStorage.setItem("isHabitsPlanner", !isHabits ? "true" : "false");
  };

  const toggleTasks = () => {
    setIsTasks(!isTasks);

    let newEvents = [];
    // loadData();

    if (!isTasks) {
      // If isTasks is currently false, it will be set to true
      newEvents = isHabits
        ? [
            ...RepeatEvents.current,
            ...ToDoEvents.current,
            ...HabitEvents.current,
          ]
        : [...RepeatEvents.current, ...ToDoEvents.current];
    } else {
      // If isTasks is currently true, it will be set to false
      newEvents = isHabits
        ? [...RepeatEvents.current, ...HabitEvents.current]
        : [...RepeatEvents.current];
    }

    // Merge with existing data while filtering out duplicates
    const mergedData = Array.from(
      new Set([...newEvents].map(JSON.stringify))
    ).map(JSON.parse);
    setCalendarData(mergedData.map(mapToRBCFormat));

    localStorage.setItem("isTaskPlanner", !isTasks ? "true" : "false");
  };

  const findDayAndWeek = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    // const firstDay = new Date(year, month, day);
    const firstDay = new Date(year, month, 1);
    const dayOfWeek = (firstDay.getDay() + 6) % 7; // Convert monday (0) to sunday 6
    const week = Math.ceil((day - dayOfWeek) / 7);

    //const dayShort = convertDayofWeek(dayOfWeek);
    //const dayFull = convertDayofWeekFull(dayOfWeek);
    //const weekPlace = convertMonthlyPlace(week);
    //const dayFull = convertDayofWeekFull(dayOfWeek);

    return { day, week };
  };

  const addRepeatDaysPlanner = async () => {
    RepeatEvents.current = [];
    var MonthDate = new Date(calendarMonthView);

    data.forEach((repeatEvents) => {
      if (repeatEvents.isRepeats) {
        const startFromDB = new Date(repeatEvents.startDate.seconds * 1000);
        const endFromDB = new Date(repeatEvents.endDate.seconds * 1000);

        const today = new Date();
        //  const firstDate = new Date(firstDayOfMonth);

        // First day of the month
        var firstDate = new Date(
          MonthDate.getFullYear(),
          MonthDate.getMonth() - 7,
          1
        );

        // Last day of the month
        var futureDate = new Date(
          MonthDate.getFullYear(),
          MonthDate.getMonth() + 7,
          0
        );

        const startDate = new Date(startFromDB);
        let interval = repeatEvents.repeatWeeklyIntervalValue || 1;
        let weekCounter = 0;
        let beginDate = new Date(startFromDB);
        let endDate = new Date(endFromDB);
        const endHours = endDate.getHours();
        const endMinutes = endDate.getMinutes();

        // console.log("what is endTime", endFromDB);

        var startTime;
        var endTime;
        if (repeatEvents.time != undefined) {
          const [hours, minutes] = repeatEvents.time.split(":");
          startTime = {
            hours: parseInt(hours, 10),
            minutes: parseInt(minutes, 10),
          };
          endTime = {
            hours: parseInt(endHours, 10),
            minutes: parseInt(endMinutes, 10),
          }; // Assuming same time for end time
        } else {
          startTime = { hours: 19, minutes: 0 };
          endTime = { hours: 20, minutes: 0 };
        }

        let currentDate = new Date(firstDate);

        if (repeatEvents.repeatValue === "yearly") {
          // Yearly repeat logic here (unchanged from previous example)
        } else if (repeatEvents.repeatValue === "monthly") {
          // Monthly repeat logic
          for (let i = 0; i < 12; i++) {
            // Assuming repeat for 12 months
            const nextMonthDate = new Date(currentDate);

            const nextEventStart = new Date(nextMonthDate);
            nextEventStart.setHours(startTime.hours, startTime.minutes);

            const nextEventEnd = new Date(nextMonthDate);
            nextEventEnd.setHours(endTime.hours, endTime.minutes);

            const nextEvent = {
              id: repeatEvents.id,
              isTask: repeatEvents.isTask,
              name: repeatEvents.name,
              color: repeatEvents.color,
              start: nextEventStart,
              end: nextEventEnd,
            };

            RepeatEvents.current.push(nextEvent);

            currentDate.setMonth(currentDate.getMonth() + 1);
          }
        } else if (repeatEvents.repeatValue === "weekly") {
          // Adjust beginDate to the first occurrence in the current month
          while (beginDate < currentDate) {
            beginDate.setDate(beginDate.getDate() + 1);
            if (beginDate.getDay() === startFromDB.getDay()) {
              weekCounter++;
            }
          }

          // Iterate through the month
          while (beginDate <= futureDate) {
            if (
              repeatEvents.days.includes(beginDate.getDay()) &&
              weekCounter % interval === 0
            ) {
              const eventStart = new Date(beginDate);
              eventStart.setHours(startTime.hours, startTime.minutes);

              const eventEnd = new Date(beginDate);
              eventEnd.setHours(endTime.hours, endTime.minutes);

              const event = {
                id: repeatEvents.id,
                isTask: repeatEvents.isTask,
                name: repeatEvents.name,
                color: repeatEvents.color,
                start: eventStart,
                end: eventEnd,
              };

              RepeatEvents.current.push(event);
            }

            // Move to the next day
            beginDate.setDate(beginDate.getDate() + 1);
            if (beginDate.getDay() === startFromDB.getDay()) {
              weekCounter++;
            }
          }
        } else if (repeatEvents.repeatValue === "daily") {
          // Daily repeat logic
          while (currentDate <= futureDate) {
            const eventStart = new Date(currentDate);
            eventStart.setHours(startTime.hours, startTime.minutes);

            const eventEnd = new Date(currentDate);
            eventEnd.setHours(endTime.hours, endTime.minutes);

            const event = {
              id: repeatEvents.id,
              isTask: repeatEvents.isTask,
              name: repeatEvents.name,
              color: repeatEvents.color,
              start: eventStart,
              end: eventEnd,
            };

            RepeatEvents.current.push(event);
            currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
          }
        }
      } else if (!repeatEvents.isRepeats) {
        const startDB = new Date(repeatEvents.startDate.seconds * 1000);
        const endDB = new Date(repeatEvents.endDate.seconds * 1000);

        const event = {
          id: repeatEvents.id,
          isTask: repeatEvents.isTask,
          name: repeatEvents.name,
          color: repeatEvents.color,
          start: startDB,
          end: endDB,
        };

        RepeatEvents.current.push(event);
      }
    });
  };

  const habitsConversion = async () => {
    // setEvents([]);

    var newEvents = [];
    HabitEvents.current = [];

    habits.map((habit) => {
      //  console.log("what is the habit date", habit.lastDay);
      //  const startDate = new Date(habit.addeddate); // Start date of the recurring event
      var MonthDate = new Date(calendarMonthView);
      var firstDayOfMonth = new Date(
        MonthDate.getFullYear(),
        MonthDate.getMonth(),
        1
      );
      const isPlanner = habit.isPlanner != undefined ? habit.isPlanner : true;
      const todayDate = new Date(firstDayOfMonth);
      const startDate = new Date(todayDate);
      startDate.setDate(todayDate.getDate() - 3);
      const add45Days = new Date(todayDate);
      add45Days.setDate(todayDate.getDate() + 31);
      const PlusOneDay = habit.lastDay
        ? new Date(habit.lastDay.seconds * 1000)
        : new Date();

      PlusOneDay.setDate(PlusOneDay.getDate() + 1);

      //console.log("what is plus one", PlusOneDay);

      /*  const endDate = habit.lastDay
        ? new Date(PlusOneDay)
        : new Date(add45Days); */ // End date of the recurring event

      const endDate = new Date(add45Days);

      const recurringDays = habit.days; // Monday, Tuesday, Friday (0 = Sunday, 1 = Monday, ..., 6 = Saturday)

      var startTime;
      var endTime;
      if (habit.time != undefined) {
        const [hours, minutes] = habit.time.split(":");
        const [endHours, endMinutes] = habit.duration.split(":");
        startTime = { hours: hours, minutes: minutes }; // Start time of the event
        endTime = { hours: endHours, minutes: endMinutes }; // Days of the week for the recurring event
      } else {
        startTime = { hours: 19, minutes: 0 }; // Start time of the event
        endTime = { hours: 20, minutes: 0 }; // Days of the week for the recurring event
      }

      let currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        const dayOfWeek = getDay(currentDate);

        if (recurringDays.includes(dayOfWeek)) {
          const eventStart = new Date(currentDate);
          eventStart.setHours(startTime.hours, startTime.minutes);

          const eventEnd = new Date(currentDate);
          eventEnd.setHours(endTime.hours, endTime.minutes);

          const event = {
            id: habit.id,
            name: habit.name,
            textColor: "white",
            color: "#f0fff0",
            start: eventStart,
            end: eventEnd,
          };

          if (isPlanner) {
            newEvents.push(event);
            //  setEvents(newEvents);
            HabitEvents.current = newEvents;
            //  console.log("did I save");
            //  localStorage.setItem("myEvents", JSON.stringify(newEvents));
          }
          //  console.log("what is events", newEvents);
        }

        currentDate.setDate(currentDate.getDate() + 1);
      }
      //console.log("do I loop?");
    });
  };

  const ToDoReminderConversion = async () => {
    // setMyToDoReminders([]);
    var newEvents = [];
    ToDoEvents.current = [];
    await dueDateTodo.map((todo) => {
      // var newEvents = myToDoReminders;
      //  console.log("what is the habit date", habit.addeddate);
      const startDate =
        todo.dueDate != undefined ? todo.dueDate.seconds * 1000 : new Date(); // Start date of the recurring event
      //   console.log("what is date", startDate);
      const endDate = new Date(todo.dueDate); // End date of the recurring event

      var startTime;
      var endTime;
      if (todo.dueTime != undefined) {
        const [hours, minutes] = todo.dueTime.split(":");

        startTime = { hours: hours, minutes: minutes }; // Start time of the event
        endTime = { hours: hours, minutes: minutes }; // Days of the week for the recurring event
      } else {
        startTime = { hours: 10, minutes: 0 }; // Start time of the event
        endTime = { hours: 11, minutes: 0 }; // Days of the week for the recurring event
      }

      let currentDate = new Date(startDate);
      const eventStart = new Date(currentDate);
      eventStart.setHours(startTime.hours, startTime.minutes);

      const eventEnd = new Date(currentDate);
      eventEnd.setHours(endTime.hours, endTime.minutes);

      const event = {
        id: todo.id,
        name: todo.name,

        color: "pink",
        start: eventStart,
        end: eventEnd,
        isToDo: true,
      };

      //  console.log("what is todo event", event);

      newEvents.push(event);
      // setMyToDoReminders(newEvents);
      ToDoEvents.current = newEvents;
    });
  };

  const roundUpToNearestHalfHour = (date) => {
    const newDate = new Date(); // Clone the original date to avoid mutating it

    let hours = newDate.getHours();
    let minutes = newDate.getMinutes();

    // Round minutes to the nearest 30 (0 or 30)
    if (minutes < 30) {
      minutes = 30;
    } else {
      minutes = 0;
      hours++; // Increment hour if minutes round up to 60
    }

    // Set the new hours and minutes
    date.setHours(hours, minutes, 0, 0); // Reset seconds and milliseconds to 0

    return date;
  };

  var selectSlotStart = new Date();
  const handleSelectSlot = useCallback(
    ({ start, end, id }) => {
      //  const title = window.prompt('What made you happy today?')
      var startDate = start;
      var endDate = end;

      if (view === "month") {
        startDate = roundUpToNearestHalfHour(start);
        // console.log("what is start", startDate);

        // Create a new Date object for endDate
        endDate = new Date(startDate);

        // Add 1 hour to endDate
        endDate.setHours(endDate.getHours() + 1);

        // console.log("what is end", endDate);
      }

      setEvents((prev) => [...prev, { startDate, end, id }]);
      selectSlotStart = start;
      //   console.log("what is the date", selectSlotStart)
      setSelectedStart(startDate);
      setSelectedEnd(endDate);
      setIsAddingNew(true);
    },
    [setEvents]
  );

  //const DnDCalendar = withDragAndDrop(Calendar);

  const handleSelectEvent = useCallback(
    (event) => window.alert(event.title),
    []
  );

  const handleClickItem = (event) => {
    setSelectedEvent(event);
    // console.log("is clicked", event);
    // history.push(`${url}/${event.id}`);
    //  setIsEventsModalOpen(true);

    setIsAddingNew(false);

    //console.log("what is the id", event);

    if (event.isTask != undefined) {
      history.push(`planner/${event.id}`);
    } else if (event.isToDo) {
      history.push(`todo/${event.id}`);
    } else {
      history.push(`habits/${event.id}`);
    }

    setCount(count + 1);
  };

  const reloadRepeatEvents = () => {
    console.log("fired");
  };

  const close = () => {
    setIsAddingNew(false);
  };

  const mapToRBCFormat = (e) => {
    /* const convertToDate = (date) => {
      // Check if the date is in epoch time format (seconds)
      // console.log("what", date);

      return new Date(date.seconds * 1000);
    };*/

    return {
      ...e,
      title: e.name,
      start: e.startDate !== undefined ? new Date(e.start) : new Date(e.start),
      end: e.endDate !== undefined ? new Date(e.end) : new Date(e.end),
    };
  };

  const customSlotPropGetter = () => {
    return {
      className: "slot",
      label: "All Day",
      style: {
        minHeight: "1vh",
      },
    };
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    //   console.log(event);
    // var backgroundColor = "#FBEF05";
    // console.log("what is the event", event.color);

    var backgroundColor = event.color != undefined ? event.color : "#fef68a";
    // var textColor = event.textColor != "" ? event.color : "black";

    var style = {
      backgroundColor: backgroundColor,
      //   borderRadius: "0px",
      //   opacity: 0.8,
      color: "white",
      //  border: "2px",
      //  paddingBottom: 5,
    };
    return {
      style: style,
    };
  };

  const removeTags = (str) => {
    if (typeof str === "string") {
      return str.replace(/<[^>]+>/g, "");
    }
    return str;
  };

  const EventWrapper = ({ event, children }) => {
    const title = removeTags(event.title);
    const truncatedTitle = title.substring(0, 40);

    return (
      <div style={{ padding: 0 }}>
        <div
          onClick={() => handleClickItem(event)}
          style={{
            fontSize: 16,
            padding: 5,

            fontFamily: "inherit",
            textOverflow: "clip",
            display: "inline-block",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "96%",
            cursor: "pointer",
            //   backgroundColor: event.color,
            backgroundColor: "yellow",
            borderRadius: 5,
            borderStyle: "inherit",
          }}
        >
          {truncatedTitle}
        </div>
      </div>
    );
  };

  const convertDate = (date) => {
    var update;
    var today = new Date();
    var pastDue = false;

    if (date !== undefined) {
      update = new Date(date.seconds * 1000);
      update = moment(new Date(update)).format("MMMM DD, YYYY");
      var updateCompare = moment(new Date(update)).format("YYYYMMDD");
      var todayCompare = moment(new Date(today)).format("YYYYMMDD");
      pastDue = updateCompare > todayCompare ? false : true;

      return pastDue;
    } else {
      return null;
    }
  };

  //console.log("what is my todo", ToDo);
  const dueDateTodo = dataToDo.filter(
    (item) => !item.completed && item.dueDate != undefined
  );

  //console.log("what is my todo with due", dueDateTodo);

  const [view, setView] = useState(
    localStorage.getItem("plannerview") != undefined
      ? localStorage.getItem("plannerview")
      : "month"
  );
  const [date, setDate] = useState(new Date());

  const handleViewChange = (newView) => {
    setView(newView);
    localStorage.setItem("plannerview", newView);
    if (newView === "day") {
      // Set the default date to the start of the week
      setDate(moment().startOf("day").toDate());
    }
    //console.log("what is the view", view);
  };
  const [calendarMonthView, setCalendarMonthView] = useState(new Date());

  const handleNavigate = (date) => {
    setCalendarData([]);
    RepeatEvents.current = [];
    HabitEvents.current = [];
    ToDoEvents.current = [];
    setCalendarMonthView(date);
    const filteredEvents = filterEventsForMonth(date);
    setCalendarData(filteredEvents.map(mapToRBCFormat));
    //console.log("what is the length", CalendarData.length);

    // console.log("what is the length current", myEventsRepeats.current.length);
  };

  const filterEventsForMonth = (date) => {
    const startOfMonth = moment(date).startOf("month").toDate();
    const endOfMonth = moment(date).endOf("month").toDate();

    return CalendarData.filter((event) => {
      let eventStart = new Date(event.start);
      let eventEnd = new Date(event.end);
      return eventStart >= startOfMonth && eventEnd <= endOfMonth;
    });
  };

  const CustomAgendaEvent = ({ event }) => {
    return (
      <div>
        {event.title}
        <p>{event.description}</p>
      </div>
    );
  };

  const formats = {
    eventTimeRangeFormat: () => {
      return "";
    },
  };

  const CustomEvent = ({ event }) => (
    <div>
      <strong>{event.title}</strong>
    </div>
  );

  const [count, setCount] = useState(0);

  const loadData = async () => {
    //setCalendarData([]);
    // console.log("ping");
    await addRepeatDaysPlanner();
    //console.log("done repeat");
    await habitsConversion();
    //console.log("done habits");
    await ToDoReminderConversion();
    //console.log("done todo");

    let newEvents = [];

    if (isHabits) {
      // If isHabits is currently false, it will be set to true
      newEvents = isTasks
        ? [
            ...RepeatEvents.current,
            ...ToDoEvents.current,
            ...HabitEvents.current,
          ]
        : [...RepeatEvents.current, ...HabitEvents.current];
    } else {
      // If isHabits is currently true, it will be set to false
      newEvents = isTasks
        ? [...RepeatEvents.current, ...ToDoEvents.current]
        : [...RepeatEvents.current];
    }

    // Merge with existing data while filtering out duplicates
    const mergedData = Array.from(
      new Set([...newEvents].map(JSON.stringify))
    ).map(JSON.parse);
    await setCalendarData(mergedData.map(mapToRBCFormat));

    //console.log(mergedData);
  };

  const [landscapePadding, setLandscapePadding] = useState(0);

  const toggleAdvanceView = () => {
    if (isAdvanceView) {
      localStorage.setItem("isPlannerAdvanceView", "false");
      setIsAdvanceView(false);
    } else {
      localStorage.setItem("isPlannerAdvanceView", "true");
      setIsAdvanceView(true);
    }
  };

  const [calendarStep, setCalendarStep] = useState(30);
  const [isCalendarStepOpen, setIsCalendarStepOpen] = useState(false);

  const optionsStep = [
    { value: 5, label: "5" + t("common-text-minutes") },
    { value: 10, label: "10" + t("common-text-minutes") },
    { value: 15, label: "15" + t("common-text-minutes") },

    { value: 30, label: "30" + t("common-text-minutes") },
    { value: 60, label: "60" + t("common-text-minutes") },
  ];

  const optionsMonthlyCell = [
    { value: "750px", label: t("dashboard-edit-sm") },
    { value: "1250px", label: t("dashboard-edit-md") },
    { value: "2000px", label: t("dashboard-edit-lg") },

    { value: "2750px", label: t("dashboard-edit-xl") },
  ];

  const handleCalendarStartWeekDay = (e) => {
    // console.log("what is e", e);
    localStorage.setItem("calendarStartWeekDay", e.value);
    setCalendarStartWeekDay(e.value);
  };

  const optionsCalendarStartWeekDay = [
    { value: 0, label: "Sunday" },
    { value: 1, label: "Monday" },
  ];

  const plannerStartWeekDay = localStorage.getItem("calendarStartWeekDay")
    ? parseInt(localStorage.getItem("calendarStartWeekDay"))
    : 0;

  const [calendarStartWeekDay, setCalendarStartWeekDay] =
    useState(plannerStartWeekDay);

  const openCalendarStepModal = () => {
    setIsCalendarStepOpen(true);
  };

  const scrollToTime = moment().subtract(60, "minutes").toDate();

  const closeCalendarStepModal = () => {
    setIsCalendarStepOpen(false);
  };

  const updateCalendarStep = (data) => {
    //  console.log("what is the data", data);
    setCalendarStep(parseInt(data.value));
    localStorage.setItem("calendarStep", data.value);
  };

  const getCalendarDayStep = () => {
    const getStepStorage =
      localStorage.getItem("calendarStep") != undefined
        ? localStorage.getItem("calendarStep")
        : "30";

    setCalendarStep(parseInt(getStepStorage));
  };

  const [isMobile, setIsMobile] = useState(false);

  const getPlannerMonthCell = localStorage.getItem("PlannerMonthCell");
  const getPlannerWidgetMonthCell = localStorage.getItem(
    "PlannerWidgetMonthCell"
  );

  //console.log("what is getisHabits", getIsHabitsPref);

  const [plannerMonthCell, setPlannerMonthCell] = useState(
    getPlannerMonthCell != undefined ? getPlannerMonthCell : "2000px"
  );

  const [plannerWidgetMonthCell, setPlannerWidgetMonthCell] = useState(
    getPlannerWidgetMonthCell != undefined ? getPlannerWidgetMonthCell : "750px"
  );

  const handleMonthCellUpdate = (e) => {
    setPlannerMonthCell(e.value);
    localStorage.setItem("PlannerMonthCell", e.value);
  };

  const handleWidgetMonthCellUpdate = (e) => {
    setPlannerWidgetMonthCell(e.value);
    localStorage.setItem("PlannerWidgetMonthCell", e.value);
  };

  const calendarHeight = () => {
    if (view == "month" && location.pathname != "/") {
      return plannerMonthCell;
    }
    if (view == "month" && location.pathname == "/") {
      return plannerWidgetMonthCell;
    } else {
      return plannerWidgetMonthCell;
    }
  };

  useEffect(() => {
    loadData();
    if (window.isNative) {
      // Post the message back to expo
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    // setCalendarData([...data]);
    getCalendarDayStep();
    const handleResize = () => {
      // Get the current window height
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      // Conditionally hide the footer based on the screen height
      if (windowWidth * 1.5 > windowHeight) {
        setLandscapePadding(250);
        // setHideFooter(true);
      } else {
        setLandscapePadding(0);
        // setHideFooter(false);
      }
    };

    // Attach the resize event listener
    // window.addEventListener("resize", handleResize);

    //  handleResize();

    // Call the hand

    return () => {
      // window.removeEventListener("resize", handleResize);
    };
  }, [data, dataToDo, habits, calendarMonthView]);

  const getEventsCountForDay = (date) => {
    const eventsForDay = data.filter((event) => {
      // Compare the dates (ignoring time) to check if they match
      return (
        new Date(event.startDate.seconds * 1000).getDate() === date.getDate() &&
        new Date(event.startDate.seconds * 1000).getMonth() ===
          date.getMonth() &&
        new Date(event.startDate.seconds * 1000).getFullYear() ===
          date.getFullYear()
      );
    });
    return eventsForDay.length;
  };

  return (
    <div
      style={{
        backgroundColor: "transparent",
        boxShadow: "none",
        padding: 10,
        borderRadius: 10,
      }}
    >
      {" "}
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          color: "black",
        }}
      >
        <Link to="/planner">
          <h2 className="h2SlideIn">{t("planner-title")} </h2>
        </Link>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: -20,
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <button
            //  className={classes.blinkingButton}
            onClick={() => toggleAdvanceView()}
            style={{
              paddingLeft: 15,
              paddingRight: 15,
              paddingTop: 5,
              paddingBottom: 5,
              borderRadius: 5,
              border: 0,
              color: "white",
              backgroundColor: "cornflowerblue",
              fontFamily: "inherit",
              fontSize: 14,
            }}
          >
            {isAdvanceView
              ? t("planner-button-gosimple")
              : t("planner-button-goadvance")}
          </button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: 10,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              paddingRight: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <input
              type="checkbox"
              style={{ transform: "scale(1.5)" }}
              className="custom-checkbox"
              checked={isHabits}
              onChange={toggleHabits}
            />
            <span style={{ paddingLeft: 5, fontSize: 14, textAlign: "center" }}>
              {t("planner-checkbox-habits")}
            </span>
          </div>

          <div
            style={{
              paddingLeft: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <input
              type="checkbox"
              style={{ transform: "scale(1.2)" }}
              className="custom-checkbox"
              checked={isTasks}
              onChange={toggleTasks}
            />
            <span style={{ paddingLeft: 5, fontSize: 14 }}>
              {t("planner-checkbox-tasks")}
            </span>
          </div>
        </div>
      </div>
      {!isAdvanceView ? (
        <SimplePlanner data={CalendarData} isExpired={isExpired} />
      ) : null}
      {isAdvanceView ? (
        <div>
          <Calendar
            events={CalendarData}
            // events={events}
            disableMultiSelect={true}
            localizer={localizer}
            defaultDate={date}
            defaultView={view}
            messages={calendarMessages}
            //  onRangeChange={handleRangeChange}
            onNavigate={handleNavigate}
            //  formats={formats}
            // dayLayoutAlgorithm="custom"
            timeslots={1}
            //   events={calendarDates}

            style={{
              height: calendarHeight(),
              flex: 1,
              paddingBottom: 25,
            }}
            scrollToTime={scrollToTime}
            // dayLayoutAlgorithm="no-overlap"
            onSelectEvent={handleClickItem}
            onSelectSlot={handleSelectSlot}
            slotPropGetter={customSlotPropGetter}
            onView={handleViewChange}
            //showAllEvents={true}
            components={{
              agenda: {
                event: CustomAgendaEvent,
              },
              day: {
                event: CustomEvent,
              },
            }}
            step={calendarStep}
            startAccessor="start"
            endAccessor="end"
            min={minTime}
            max={maxTime}
            selectable
            showMultiDayTimes={true}
            eventPropGetter={eventStyleGetter}
            //  onSelectSlot={() => console.log("Clicked")}
            views={["month", "day", "agenda"]}
            popup
          />

          <p
            onClick={openCalendarStepModal}
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
              textAlign: "center",
              marginTop: 10,
            }}
          >
            {t("planner-calendar-settings")}
          </p>
        </div>
      ) : null}
      <div style={{ paddingTop: 20 }} />
      <div style={{ paddingBottom: landscapePadding }} />
      <Modal
        isOpen={isCalendarStepOpen}
        onRequestClose={closeCalendarStepModal}
        style={{ overlay: { zIndex: 100 } }}
      >
        <div style={{ textAlign: "left", padding: 20 }}>
          {" "}
          <h2>{t("planner-calendar-settings")}</h2>
          {/* Importer Code */}
          <label style={{ paddingBottom: 10, paddingTop: 20 }}>
            {t("planner-calendar-import-Calendar")}{" "}
          </label>
          <input
            type="file"
            accept=".ics, .ical"
            onChange={(e) => handleFileUpload(e, onFileUploadComplete)}
            ref={fileInputRef}
            style={{
              padding: 10,
              fontSize: 14,
              fontFamily: "inherit",
            }}
          />
          <br />
          <br />
          <div
            style={{
              display: "flex",
              paddingTop: 15,
              alignItems: "left",
              justifyContent: "left",
              flexWrap: "wrap",
              flexDirection: "column",
              width: "85%",
              maxWidth: 350,
            }}
          >
            <label style={{ paddingBottom: 10 }}>Calendar - Start Week</label>
            <Select
              options={optionsCalendarStartWeekDay}
              value={optionsCalendarStartWeekDay.find(
                (option) => option.value === calendarStartWeekDay
              )}
              //value={plannerMonthCell}
              // placeholder={t("planner-calendar-selectduration")}
              onChange={(e) => handleCalendarStartWeekDay(e)}
              style={{ fontSize: "inherit", fontFamily: "inherit", width: 175 }}
            />
          </div>
          <br />
          <br />
          <div
            style={{
              display: "flex",
              paddingTop: 15,
              alignItems: "left",
              justifyContent: "left",
              flexWrap: "wrap",
              flexDirection: "column",
              width: "85%",
              maxWidth: 350,
            }}
          >
            <label style={{ paddingBottom: 10 }}>
              {t("planner-calendar-monthly-cell")}{" "}
            </label>
            <Select
              options={optionsMonthlyCell}
              value={optionsMonthlyCell.find(
                (option) => option.value === plannerMonthCell
              )}
              //value={plannerMonthCell}
              // placeholder={t("planner-calendar-selectduration")}
              onChange={(e) => handleMonthCellUpdate(e)}
              style={{ fontSize: "inherit", fontFamily: "inherit", width: 175 }}
            />
          </div>
          <br />
          <br />
          <div
            style={{
              display: "flex",
              paddingTop: 15,
              alignItems: "left",
              justifyContent: "left",
              flexWrap: "wrap",
              flexDirection: "column",
              width: "85%",
              maxWidth: 350,
            }}
          >
            <label style={{ paddingBottom: 10 }}>
              {t("planner-calendar-widget-monthly-cell")}{" "}
            </label>
            <Select
              options={optionsMonthlyCell}
              value={optionsMonthlyCell.find(
                (option) => option.value === plannerWidgetMonthCell
              )}
              //value={plannerMonthCell}
              // placeholder={t("planner-calendar-selectduration")}
              onChange={(e) => handleWidgetMonthCellUpdate(e)}
              style={{ fontSize: "inherit", fontFamily: "inherit", width: 175 }}
            />
          </div>
          <br />
          <br />
          <div
            style={{
              display: "flex",
              paddingTop: 15,
              alignItems: "left",
              justifyContent: "left",
              flexWrap: "wrap",
              flexDirection: "column",
              width: "85%",
              maxWidth: 350,
            }}
          >
            <label style={{ paddingBottom: 10 }}>
              {t("planner-calendar-dayslotsettings")}{" "}
            </label>
            <Select
              options={optionsStep}
              placeholder={t("planner-calendar-selectduration")}
              value={optionsStep.find(
                (option) => option.value === calendarStep
              )} // Assuming calendarStep is the selected value
              onChange={(e) => updateCalendarStep(e)}
              style={{ fontSize: "inherit", fontFamily: "inherit", width: 175 }}
            />
          </div>
          <button
            onClick={closeCalendarStepModal}
            style={{
              marginTop: 20,
              borderRadius: 10,
              color: "white",
              backgroundColor: "blue",
              border: 1,
              borderStyle: "dashed",
              padding: 10,
              width: 175,
              textAlign: "center",
              cursor: "pointer",
              fontSize: 18,
              fontFamily: "inherit",
              // opacity: 0.25,
            }}
          >
            {t("common-button-close")}
          </button>
          <div style={{ paddingBottom: 350 }} />
        </div>
      </Modal>
      <Modal
        isOpen={isAddingNew}
        onRequestClose={close}
        style={{ overlay: { zIndex: 100 } }}
      >
        <AddPlanner
          closeModal={close}
          start={selectedStart}
          end={selectedEnd}
          isEdit={false}
          event={emptyEvent}
        />
      </Modal>
      {/* Importer Code */}
      <Modal
        isOpen={isImportPreview}
        onRequestClose={() => setIsImportPreview(false)}
        style={{ overlay: { zIndex: 100 } }}
      >
        <div>
          <label>{t("planner-calendar-import-Preview")}</label>
          <span style={{ fontSize: 14, fontFamily: "inherit" }}>
            {t("planner-calendar-import-Preview-instructions")}
          </span>

          {!isSpinner && !isImportDone ? (
            <div
              style={{
                height: 150,
                overflow: "scroll",
                padding: 10,
                marginTop: 20,
                background: "black",
                color: "white",
              }}
            >
              {tempEvents.map((event, index) => (
                <div key={index}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      fontSize: 14,
                      fontFamily: "inherit",
                    }}
                  >
                    <span
                      onClick={() => removeEventFromImportPreview(event)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "yellow",
                        fontSize: 10,
                        border: 1,

                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 2,
                        paddingBottom: 2,
                        borderStyle: "dashed",
                        // marginTop: -5,

                        cursor: "pointer",
                      }}
                    >
                      {" "}
                      {t("common-button-remove")}
                    </span>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "left",
                        justifyContent: "left",
                        // color: "yellow",
                        fontSize: 12,
                        border: 1,

                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 2,
                        paddingBottom: 2,
                        borderStyle: "dashed",
                        // marginTop: -5,
                        //  marginLeft: 10,
                        cursor: "pointer",
                      }}
                    >
                      {" "}
                      {event.name} @{" "}
                      {moment(new Date(event.start)).format(
                        "MMMM DD, YYYY HH:mm"
                      )}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
          {isImportDone ? <h2>Finished!</h2> : null}
          {isSpinner ? <Spinner /> : null}
          <span style={{ textAlign: "right", paddingBottom: 20, fontSize: 14 }}>
            {t("planner-calendar-import-count")} {tempEvents.length.toString()}
          </span>
          <br />
          <br />

          <div>
            <button
              onClick={() => {
                setIsImportPreview(false);
                fileInputRef.current.value = "";
                setIsImportDone(false);
              }}
              className="actions_button"
              style={{ color: "white" }}
            >
              {isImportDone
                ? t("common-button-close")
                : t("common-button-cancel")}
            </button>{" "}
            {!isSpinner && !isImportDone ? (
              <button
                onClick={handleImportEvents}
                className="actions_button"
                style={{ color: "black" }}
              >
                {t("common-button-import")}
              </button>
            ) : null}
          </div>
        </div>
      </Modal>
      {location.pathname != "/" ? <div style={{ paddingBottom: 150 }} /> : null}
      <Route path={`planner/:productId`}>
        <PlannerLanding data={data} isExpired={isExpired} />
      </Route>
    </div>
  );
};

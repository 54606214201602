import React from "react";

export const LoanModal = ({ isOpen, onClose, onTakeLoan }) => {
  if (!isOpen) return null;

  return (
    <div className="game-modal-overlay">
      <div className="game-modal">
        <h2>Bank Loan</h2>
        <p>Choose your loan amount (10% daily interest):</p>
        <button onClick={() => onTakeLoan(5000)}>Borrow $5,000</button>
        <button onClick={() => onTakeLoan(10000)}>Borrow $10,000</button>
        <button onClick={() => onTakeLoan(25000)}>Borrow $25,000</button>
        <button onClick={onClose} className="game-close-button">
          Close
        </button>
      </div>
    </div>
  );
};

import React, { useState, useEffect, useRef, useCallback } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import {
  //Link,
  //Route,
  //useRouteMatch,
  useHistory,
  //useLocation,
} from "react-router-dom";
//import "react-big-calendar/lib/css/react-big-calendar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faPaintRoller,
  faTrash,
  //faClose,
  //faCalendarDay,
  //faStar,
  faCar,
  faPhone,
  faKitchenSet,
  //faSmile,
  //faQuestion,
  faCalendarWeek,
  //faPen,
  faBolt,
  faEnvelope,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";

import { getDay } from "date-fns";
import DatePicker from "react-date-picker";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import { Modal } from "Common";
import "react-date-picker/dist/DatePicker.css";
import { useUserContext } from "Authentication";
//import { AddPlanner } from "Planner/AddPlanner";
import { ScheduleMeal } from "./ScheduleMeal";
import { ConfirmationModal } from "Common";
import { useTranslation } from "react-i18next";

const localizer = momentLocalizer(moment);

export const MealPlanner = ({ data, mealPlannerData }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  //const [selectedStart, setSelectedStart] = useState(new Date());
  //const [selectedEnd, setSelectedEnd] = useState(new Date());
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const mealPlannerEvents = useRef([]);
  const mealPlannerWeeklyEvents = useRef([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { deleteMealEvent } = useUserContext();
  const [selectedId, setSelectedId] = useState("");
  const [tempEvent, setTempEvent] = useState({
    id: "",
    recipeLinkId: "na",
    name: "",
    note: "",
    type: "",
    time: "",
    icon: faBolt,
    color: "",
    start: new Date(),
    end: new Date(),
    isWeekly: false,
  });
  const { t } = useTranslation();
  const calendarMessages = {
    today: t("common-calendar-today"),
    next: t("common-calendar-next"),
    previous: t("common-calendar-back"),
    month: t("common-calendar-month"),
    day: t("common-calendar-day"),
    agenda: t("common-calendar-agenda"),
  };
  const [isCardOpen, setIsCardOpen] = useState(false);

  //console.log("what is my data", data);
  const getIsMealPlannerAdvanceView = localStorage.getItem(
    "isMealPlannerAdvanceView"
  );

  //console.log("what is getisHabits", getIsHabitsPref);

  //from Calendar Setting, it'll change the start day of the week.
  const calendarStartDay =
    localStorage.getItem("calendarStartWeekDay") != undefined
      ? parseInt(localStorage.getItem("calendarStartWeekDay"))
      : 0;

  //console.log("what is day", calendarStartDay);

  moment.updateLocale("en", {
    week: {
      dow: calendarStartDay, // 1 = Monday is the first day of the week
    },
  });

  const [isAdvanceView, setIsAdvanceView] = useState(
    getIsMealPlannerAdvanceView === "true" ? true : false
  );

  const toggleAdvanceView = () => {
    if (isAdvanceView) {
      localStorage.setItem("isMealPlannerAdvanceView", "false");
      setIsAdvanceView(false);
    } else {
      localStorage.setItem("isMealPlannerAdvanceView", "true");
      setIsAdvanceView(true);
    }
  };

  const close = () => {
    setIsAddingNew(false);
  };

  const handleEditMealPlanner = () => {
    //  console.log("what is tempEvent", tempEvent);
    setIsCardOpen(false);
    setIsEditing(true);
    setIsAddingNew(true);
  };

  const ExportEmail = () => {
    const emailBody =
      `Type: ${tempEvent.type.toUpperCase()}\n\n%0A%0A` +
      `On Date: ${covertFormattedDate(tempEvent.start)}\n\n%0A%0A` +
      `For: ${tempEvent.time.toUpperCase()}\n\n%0A%0A` +
      `At: ${tempEvent.name.toUpperCase()}\n\n%0A%0A` +
      `Notes: ${tempEvent.note}\n\n%0A%0A%0A%0A` +
      `Generated by: https://AlohaPlanner.app\n\n%0A%0A`;

    window.location.href =
      window.location.href = `mailto:?subject=My%20Meal&body=${emailBody}`;
  };

  const handleRemoveConfirmation = (id) => {
    setSelectedId(id);
    setConfirmModalOpen(true);
  };

  const [key, setKey] = useState(0);

  const reloadComponent = () => {
    setKey((prevKey) => prevKey + 1);
  };

  const handleRemove = async () => {
    try {
      await deleteMealEvent(selectedId);

      setConfirmModalOpen(false);
      setSelectedId("");
      reloadComponent();

      setIsCardOpen(false);
      toast("Item Successfully Removed", {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "lightgreen",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });

      // Update events after successful deletion
      const updatedEvents = events.filter((event) => event.id !== selectedId);
      setEvents(updatedEvents);
    } catch (error) {
      console.log("what is the error", error);
    }
  };

  const addNewEvent = () => {
    setTempEvent({
      id: "",
      recipeLinkId: "na",
      name: "",
      note: "",
      type: "",
      time: "",
      icon: faBolt,
      color: "",
      start: new Date(),
      end: new Date(),
      isWeekly: false,
    });
    setIsAddingNew(true);
    setIsEditing(false);
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    //   console.log(event);
    // var backgroundColor = "#FBEF05";
    // console.log("what is the event", event.color);

    var backgroundColor = event.color != undefined ? event.color : "#fef68a";
    // var textColor = event.textColor != "" ? event.color : "black";

    var style = {
      backgroundColor: backgroundColor,
      //   borderRadius: "0px",
      //   opacity: 0.8,
      color: "white",
      //  border: "2px",
      //  paddingBottom: 5,
    };
    return {
      style: style,
    };
  };

  const customSlotPropGetter = () => {
    return {
      className: "slot",
      label: "All Day",
      style: {
        minHeight: "1vh",
      },
    };
  };

  //var selectSlotStart = new Date();
  const handleSelectSlot = useCallback(({ start, end, id }) => {
    setTempEvent({
      id: "",
      recipeLinkId: "na",
      name: "",
      note: "",
      type: "",
      time: "",
      icon: faBolt,
      color: "",
      start: start,
      end: new Date(),
      isWeekly: false,
    });
    //  const title = window.prompt('What made you happy today?')
    //  setEvents((prev) => [...prev, { start, end, id }]);
    setSelectedDate(start);
    //   console.log("what is the date", selectSlotStart)
    setIsAddingNew(true);
  }, []);
  //end manual add

  const history = useHistory();
  // const [showEvents, setShowEvents] = useState(false);

  const [events, setEvents] = useState([]);

  const covertFormattedDate = (date) => {
    const Date = moment(date).format("MMMM DD, YYYY");
    return Date;
  };

  const convertDate = (data) => {
    //convert habit start and end to epoch for sorting
    var date = new Date(data);
    date = date.getTime();
    // console.log("what is new date", date);
    return date;
  };

  const getTime = (data) => {
    //  console.log("what is data", data);

    // Check if data is in epoch format
    const isEpoch = data.seconds !== undefined;

    // Convert data to Date object
    const date = isEpoch ? new Date(data.seconds * 1000) : new Date(data);

    // Get time string
    const timeString = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    return timeString;
  };

  // const [showEvents, setShowEvents] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    // setShowEvents(true);
  };

  const handleBackButtonClick = () => {
    const newDate = moment(selectedDate).subtract(1, "days").toDate();
    handleDateChange(newDate);
  };

  const handleClickItem = (event) => {
    setTempEvent(event);
    // console.log(event);
    if (event.recipeLinkId == undefined) {
      history.push(`recipes/${event.id}`);
    } else if (event.recipeLinkId != "na") {
      history.push(`recipes/${event.recipeLinkId}`);
    } else {
      setSelectedId(event.id);
      setTempEvent(event);
      // console.log("what is the date", event.start);
      setIsCardOpen(true);
    }

    // setCount(count + 1);
  };

  /* const setNewEvent = (data) => {
    console.log("what is the data", data);
    var tempEvents = events;
    tempEvents.push(...tempEvents, data);
    setEvents(tempEvents);
  };*/

  const mapToRBCFormat = (e) =>
    Object.assign({}, e, {
      title: e.name,
      start: e.startDate != undefined ? e.startDate.toDate() : e.start,
      end: e.endDate != undefined ? e.endDate.toDate() : e.end,
    });

  const RecipeDateConversion = async () => {
    // setEvents([]);

    var newEvents = [];

    data.map((habit) => {
      // console.log("am I mapping");
      //  console.log("what is the habit date", habit.addeddate);
      //  const startDate = new Date(habit.addeddate); // Start date of the recurring event
      //  const isPlanner = habit.isPlanner != undefined ? habit.isPlanner : true;
      const todayDate = new Date();
      const startDate = new Date(todayDate);
      startDate.setDate(todayDate.getDate() - 7);
      const add45Days = new Date(todayDate);
      add45Days.setDate(todayDate.getDate() + 60);
      const PlusOneDay = habit.lastDay
        ? new Date(habit.lastDay.seconds * 1000)
        : new Date();
      PlusOneDay.setDate(PlusOneDay.getDate() + 1);

      //console.log("what is plus one", PlusOneDay);

      const endDate = habit.lastDay
        ? new Date(PlusOneDay)
        : new Date(add45Days); // End date of the recurring event

      const recurringDays = habit.days; // Monday, Tuesday, Friday (0 = Sunday, 1 = Monday, ..., 6 = Saturday)

      var startTime;
      var endTime;
      if (habit.time != undefined) {
        const [hours, minutes] = habit.time.split(":");
        const [endHours, endMinutes] = habit.duration.split(":");
        startTime = { hours: hours, minutes: minutes }; // Start time of the event
        endTime = { hours: endHours, minutes: endMinutes }; // Days of the week for the recurring event
      } else {
        startTime = { hours: 1, minutes: 0 }; // Start time of the event
        endTime = { hours: 2, minutes: 0 }; // Days of the week for the recurring event
      }

      let currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        const dayOfWeek = getDay(currentDate);

        if (recurringDays.includes(dayOfWeek)) {
          const eventStart = new Date(currentDate);
          eventStart.setHours(startTime.hours, startTime.minutes);

          const eventEnd = new Date(currentDate);
          eventEnd.setHours(endTime.hours, endTime.minutes);

          const event = {
            id: habit.id,
            name: habit.name,
            textColor: "white",
            color: "#f0fff0",
            icon: faCalendarWeek,
            time: "Weekly Recipe",
            start: eventStart,
            end: eventEnd,
            isWeekly: true,
          };
          newEvents.push(event);
          mealPlannerWeeklyEvents.current = newEvents.map(mapToRBCFormat);
          //  console.log("what is events", newEvents);
        }

        currentDate.setDate(currentDate.getDate() + 1);
      }

      // console.log("do I loop?", events);
      //  MealPlannerDatesConversion();
    });
  };

  const MealPlannerDatesConversion = async () => {
    // setMyToDoReminders([]);
    var newEvents = [];
    await mealPlannerData.map((a) => {
      // var newEvents = myToDoReminders;
      //  console.log("what is the habit date", habit.addeddate);
      const startDate = a.startDate != undefined ? a.startDate : a.start; // Start date of the recurring event
      //   console.log("what is date", startDate);
      //const endDate = new Date(a.); // End date of the recurring event

      //setting order by meal time

      var startTime;
      var endTime;
      var color;
      var icon;

      if (a.time == "breakfast") {
        startTime = { hours: 8, minutes: 0 }; // Start time of the event
        endTime = { hours: 9, minutes: 0 }; // Days of the week for the recurring event
        color = "yellow";
      } else if (a.time == "lunch") {
        startTime = { hours: 12, minutes: 0 }; // Start time of the event
        endTime = { hours: 13, minutes: 0 }; // Days of the week for the recurring event
        color = "lightgreen";
      } else if (a.time == "brunch") {
        startTime = { hours: 14, minutes: 0 }; // Start time of the event
        endTime = { hours: 15, minutes: 0 }; // Days of the week for the recurring event
        color = "lavender";
      } else if (a.time == "snacks") {
        startTime = { hours: 16, minutes: 0 }; // Start time of the event
        endTime = { hours: 17, minutes: 0 }; // Days of the week for the recurring event
      } else if (a.time == "dinner-app") {
        startTime = { hours: 18, minutes: 0 }; // Start time of the event
        endTime = { hours: 18, minutes: 0 }; // Days of the week for the recurring event
        color = "darksalmon";
      } else if (a.time == "dinner-main") {
        startTime = { hours: 19, minutes: 0 }; // Start time of the event
        endTime = { hours: 19, minutes: 0 }; // Days of the week for the recurring event
        color = "darksalmon";
      } else if (a.time == "dinner-dessert") {
        startTime = { hours: 20, minutes: 0 }; // Start time of the event
        endTime = { hours: 20, minutes: 0 }; // Days of the week for the recurring event
        color = "darksalmon";
      } else {
        startTime = { hours: 10, minutes: 0 }; // Start time of the event
        endTime = { hours: 10, minutes: 0 };
        color = "pink";
      }

      if (a.type == "dine") {
        icon = faCar;
      } else if (a.type === "takeout") {
        icon = faPhone;
      } else if (a.type === "recipe") {
        icon = faKitchenSet;
      } else {
        icon = faBolt;
      }

      let currentDate = startDate.toDate();
      const eventStart = new Date(currentDate);
      eventStart.setHours(startTime.hours, startTime.minutes);

      const eventEnd = new Date(currentDate);
      eventEnd.setHours(endTime.hours, endTime.minutes);

      const event = {
        id: a.id,
        recipeLinkId: a.recipeLinkId ? a.recipeLinkId : "na",
        name: a.name,
        note: a.note,
        type: a.type,
        time: a.time,
        icon: icon,
        color: color,
        start: eventStart,
        end: eventEnd,
        isWeekly: false,
      };
      newEvents.push(event);
      mealPlannerEvents.current = newEvents.map(mapToRBCFormat);
      //  console.log("what is todo event", event);
    });

    //  var combinedList = newEvents.map(mapToRBCFormat);

    //setEvents(combinedList);
  };

  const handleForwardButtonClick = () => {
    const newDate = moment(selectedDate).add(1, "days").toDate();
    handleDateChange(newDate);
  };

  const renderEvents = () => {
    // Render events for the selected date
    const selectedEvents = events.filter(
      (event) =>
        moment(event.start).isSame(selectedDate, "day") ||
        moment(event.end).isSame(selectedDate, "day") ||
        (moment(event.start).isBefore(selectedDate, "day") &&
          moment(event.end).isAfter(selectedDate, "day"))
    );

    selectedEvents.sort((a, b) => {
      const startDateA =
        a.startDate != undefined ? a.startDate.seconds : convertDate(a.start);
      const startDateB =
        b.startDate != undefined ? b.startDate.seconds : convertDate(b.start);
      return startDateA - startDateB;
    });

    if (selectedEvents.length > 0) {
      return selectedEvents.map((event, index) => (
        <div
          key={index + key}
          style={{
            margin: "10px",
            color: "black",
            display: "flex",
            width: "85%",
            flexDirection: "column",
            borderRadius: 5,
            backgroundColor:
              event.color !== undefined ? event.color : "lavender",
            padding: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{ fontSize: 10, paddingRight: 10, cursor: "pointer" }}
              onClick={() => handleClickItem(event)}
            >
              {" "}
              {event.time} -{" "}
              <FontAwesomeIcon
                icon={event.icon}
                size="xs"
                // onClick={close}
                style={{ cursor: "pointer" }}
              />
            </div>
            {event.isWeekly ? null : (
              <div
                style={{
                  fontSize: 10,
                  cursor: "pointer",
                  color: "white",
                  backgroundColor: "black",
                  paddingLeft: 5,
                  paddingRight: 5,
                  borderRadius: 5,
                }}
                onClick={() => handleRemoveConfirmation(event.id)}
              >
                {t("common-button-remove")}
              </div>
            )}
          </div>

          <div
            onClick={() => handleClickItem(event)}
            style={{ cursor: "pointer", paddingTop: 5 }}
          >
            {event.title} <span style={{ paddingRight: 10 }} />
          </div>
        </div>
      ));
    } else {
      return (
        <div style={{ textAlign: "center", paddingTop: 20 }}>
          {t("mealplanner-noresults")} &#128542;
        </div>
      );
    }
  };

  const loadDate = async () => {
    await MealPlannerDatesConversion();
    await RecipeDateConversion();
    setEvents([
      ...mealPlannerEvents.current,
      ...mealPlannerWeeklyEvents.current,
    ]);
  };

  useEffect(() => {
    // setEvents([]);
    //console.log("loaded");
    loadDate();

    return () => {};
  }, [data, mealPlannerData]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          // justifyContent: "space-between",
          // paddingTop: 10,
          flexDirection: "column",
          //  marginBottom: "10px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {" "}
        <button
          //  className={classes.blinkingButton}
          onClick={() => toggleAdvanceView()}
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 5,
            paddingBottom: 5,
            borderRadius: 5,
            border: 0,
            color: "black",
            color: "white",
            backgroundColor: "cornflowerblue",
            fontFamily: "inherit",
            fontSize: 14,
          }}
        >
          {isAdvanceView
            ? t("mealplanner-button-dayview")
            : t("mealplanner-button-monthview")}
        </button>
      </div>

      {!isAdvanceView ? (
        <div>
          <div
            style={{
              display: "flex",
              // justifyContent: "space-between",
              paddingTop: 10,
              flexDirection: "column",
              marginBottom: "10px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ paddingBottom: 5 }}>
              <DatePicker
                onChange={handleDateChange}
                value={selectedDate}
                clearIcon={false}
              />
            </div>
            <div style={{ flexDirection: "column" }}>
              <button
                style={{
                  fontSize: 20,
                  backgroundColor: "lightblue",
                  paddingLeft: 20,
                  paddingRight: 20,

                  // border: 1,
                  // borderStyle: "groove",
                  borderColor: "black",
                }}
                onClick={handleBackButtonClick}
              >
                {"<<<"}
              </button>

              <span style={{ paddingRight: 5 }} />
              <button
                style={{
                  fontSize: 20,
                  backgroundColor: "lightblue",
                  paddingLeft: 20,
                  paddingRight: 20,

                  // border: 1,
                  // borderStyle: "groove",
                  borderColor: "black",
                }}
                onClick={handleForwardButtonClick}
              >
                {">>>"}
              </button>
            </div>
          </div>
          <div
            style={{
              padding: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              onClick={addNewEvent}
              className="metroButton"
              style={{
                cursor: "pointer",
                userSelect: "none",
                color: "white",
                backgroundColor: "purple",
                fontFamily: "inherit",
                width: 200,
                fontSize: 16,
              }}
            >
              {t("mealplanner-button-addnewtoday")}
            </button>
          </div>

          <div
            key={key}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {renderEvents()}
          </div>
        </div>
      ) : (
        <div style={{ paddingTop: 15 }}>
          <Calendar
            events={events.map(mapToRBCFormat)}
            // events={events}
            messages={calendarMessages}
            style={{ height: "1000px" }}
            disableMultiSelect={true}
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            selectable
            onSelectEvent={handleClickItem}
            onSelectSlot={handleSelectSlot}
            slotPropGetter={customSlotPropGetter}
            eventPropGetter={eventStyleGetter}
            //showMultiDayTimes={true}

            //  onSelectSlot={() => console.log("Clicked")}
            views={["month"]}
            popup
          />
        </div>
      )}
      <ScheduleMeal
        recipes={data}
        isOpen={isAddingNew}
        preSelectedDate={selectedDate}
        updatePreSelectedDate={handleDateChange}
        closeModal={close}
        tempEvent={tempEvent}
        //  setNewEvent={setNewEvent}
        //start={selectedStart}
        //end={selectedEnd}
        isEdit={isEditing}
        //event={emptyEvent}
      />
      <Modal isOpen={isCardOpen} style={{ overlay: { zIndex: 100 } }}>
        <Modal.Body>
          <div style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Modal.Header>
              <h2>
                {" "}
                <FontAwesomeIcon
                  icon={tempEvent.icon}
                  size="xs"
                  // onClick={close}
                  style={{ cursor: "pointer" }}
                />{" "}
                - {tempEvent.title}
              </h2>
            </Modal.Header>
            {/*}   <span>Quickly add your favorite items. </span>*/}
            <div>
              <span>
                <p>
                  {tempEvent.time.toUpperCase()} on{" "}
                  {covertFormattedDate(tempEvent.start)}
                </p>
                <p>
                  {t("common-label-notes")}: {tempEvent.note}{" "}
                </p>
              </span>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", paddingTop: 30 }}>
              {" "}
              <FontAwesomeIcon
                onClick={ExportEmail}
                icon={faEnvelope}
                size="2x"
                // onClick={close}
                style={{ cursor: "pointer" }}
              />
              <div style={{ paddingLeft: 10, paddingRight: 10 }} />
              <FontAwesomeIcon
                onClick={() => handleRemoveConfirmation(tempEvent.id)}
                icon={faTrash}
                size="2x"
                // onClick={close}
                style={{ cursor: "pointer" }}
              />
              <div style={{ paddingLeft: 10, paddingRight: 10 }} />
              <FontAwesomeIcon
                onClick={handleEditMealPlanner}
                icon={faEdit}
                size="2x"
                // onClick={close}
                style={{ cursor: "pointer" }}
              />
              <div style={{ paddingRight: 10 }} />
              <button
                className="actions_secondaryButton"
                onClick={() => setIsCardOpen(false)}
              >
                {t("common-button-close")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ConfirmationModal
        isOpen={confirmModalOpen}
        text={t("mealplanner-removal-confirmation")}
        onClickCancel={() => {
          setConfirmModalOpen(false);
          setSelectedId("");
        }}
        onClickConfirm={handleRemove}
      />
      <div style={{ paddingBottom: 30 }} />
    </div>
  );
};

import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaintRoller,
  faTrash,
  faClose,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import { useUserContext } from "Authentication";
import TimePicker from "react-time-picker";
//import DateTimePicker from 'react-datetime-picker';
import DatePicker from "react-date-picker";
import notificationimage from "../Assets/customremindersample.jpg";
import classes from "./reminder.module.css";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
//import { useReminderSubscription } from './useReminderSubscription';
import { storage } from "Config";
import { google, outlook, office365, yahoo, ics } from "calendar-link";
import { Button, Modal } from "Common";
import { useItemsToDo } from "ListToDo";

export const CustomNotification = (title, body, daily) => {
  //new reminder start.  This is a work in progree, I need to update the APP for remove habit language first.

  //const { reminders } = useReminderSubscription()

  //console.log("reminder list", reminders)

  const [isSchedulingReminder, setIsSchedulingReminder] = useState(false);
  const [reminderTitle, setReminderTitle] = useState(title.title);
  const [reminderBody, setReminderBody] = useState(body.body ? body.body : "");
  const [startTime, setStartTime] = useState(new Date());
  const [valueTime, setValueTime] = useState("12:00");
  const [reminderDays, setReminderDays] = useState(new Date());
  const [repeatReminder, setRepeatReminder] = useState(false);
  const [isNative, setIsNative] = useState(true);
  const [isDaily, setIsDaily] = useState(false);
  const { addNewReminder } = useUserContext();
  const { addItemPlanner } = useItemsToDo();
  //const [icsURL, seticsURL] = useState("");
  const user = useUserContext();
  const [reminderDoneModal, setReminderDoneModal] = useState(false);

  const Reminder = useRef();
  const icsURL = useRef("");
  var rmessageID = Math.floor(Math.random() * 10000000);

  const history = useHistory();
  const routeChange = (path) => {
    history.push(path);
  };

  const changeReminderDays = (e) => {
    setReminderDays(e);
  };
  const notificationScheduler = async (e) => {
    e.preventDefault(e);

    if (valueTime != null) {
      rmessageID = Math.floor(Math.random() * 100000);
      var today = new Date();
      var scheduleDate = reminderDays;
      const timeParts = valueTime.split(":");
      const hours = Number(timeParts[0]);
      const minutes = Number(timeParts[1]);

      scheduleDate.setHours(hours);
      scheduleDate.setMinutes(minutes);
      scheduleDate.setSeconds(0);
      rmessageID = rmessageID.toString() + "dnotes";
      let seconds = Math.abs(scheduleDate.getTime() - today.getTime()) / 1000;

      if (window.isNative) {
        //Convert the difference between today and a fture date

        //var d = new Date();
        //@ts-ignore

        if (isDaily) {
          const date = new Date();
          Reminder.current = {
            title: reminderTitle,
            description: reminderBody,
            start: moment(date).format("YYYY-MM-DD") + valueTime,
            duration: [1, "hour"],
            rRule: "FREQ=DAILY",
          };
          // console.log("what is the reminder", Reminder);
          try {
            // await generateICAL(date, true);
          } catch (error) {}

          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "NoficationSelected",
              name: reminderTitle,
              body: reminderBody,
              days: [0, 1, 2, 3, 4, 5, 6],
              time: valueTime,
              id: rmessageID,
              isAdded: "create",
            })
          );
        } else {
          Reminder.current = {
            title: reminderTitle,
            description: reminderBody,
            start: moment(scheduleDate).format("YYYY-MM-DD") + valueTime,
            duration: [1, "hour"],
          };
          try {
            // await generateICAL(scheduleDate, false);
          } catch (error) {}

          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "NoficationSelectedCustom",
              name: reminderTitle,
              body: reminderBody,
              days: 1,
              seconds: Math.round(seconds),
              id: rmessageID,
              repeats: false,
              isAdded: "create",
            })
          );
        }
        addNewReminder(
          today,
          rmessageID,
          reminderTitle,
          reminderBody,
          scheduleDate,
          valueTime,
          isDaily,
          icsURL.current,
          "none",
          "day",
          1
        );
      } else {
        // browser reminder creates ICS only
        if (isDaily) {
          Reminder.current = {
            title: reminderTitle,
            description: reminderBody,
            start: moment(startTime).format("YYYY-MM-DD") + valueTime,
            duration: [1, "hour"],
            rRule: "FREQ=DAILY",
          };
          const date = new Date();

          try {
            // await generateICAL(date, true);
          } catch (error) {}
        } else {
          Reminder.current = {
            title: reminderTitle,
            description: reminderBody,
            start: moment(scheduleDate).format("YYYY-MM-DD") + valueTime,
            duration: [1, "hour"],
          };
          try {
            // await generateICAL(scheduleDate, false);
          } catch (error) {}
        }
        setTimeout(() => {
          addNewReminder(
            today,
            rmessageID,
            reminderTitle,
            reminderBody,
            scheduleDate,
            valueTime,
            isDaily,
            icsURL.current,
            "none",
            "day",
            1
          );
        }, 1500);
      }
    }
    addItemPlanner(reminderTitle, scheduleDate, valueTime, new Date());
    setReminderDoneModal(true);
  };

  const notificationCancel = (e) => {
    if (window.isNative) {
      // Post the message back to expo
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "NoficationSelectedCustom",
          id: rmessageID,
          isAdded: "remove",
        })
      );
      setIsSchedulingReminder(false);
      //alert(data.name, data.days, valueTime)
    } else {
      // Your old login method
      //  console.log("clicked")
    }
  };

  ///new reminder end

  // Generate ICAL Start

  const generateICAL = async (date, DailyBool) => {
    const randomID = Math.random() * 10000000000000000000;

    date = new Date(date).toISOString();
    const cleanDateColan = date.replaceAll(":", "");
    const cleanDatePeriod = cleanDateColan.replaceAll(".", "");
    const cleanDateDash = cleanDatePeriod.replaceAll("-", "");
    const cleanDateTrim = cleanDateDash.slice(0, 9);
    const cleanDate = cleanDateTrim + valueTime + "00";
    const FinalDate = cleanDate.replaceAll(":", "");
    //console.log("time is orginal time", cleanDate);
    //  console.log("time is UTC time", ConvertedUTC);
    let url = [
      "BEGIN:VCALENDAR",
      "VERSION:2.0",
      "PRODID:-//dopenotes.io//iCal Reminder Maker",
      "X-WR-CALNAME:" + reminderTitle,
      "NAME:" + reminderTitle,
      "CALSCALE:GREGORIAN",
      "BEGIN:VEVENT",
      "DTSTAMP:20230101T180000Z",
      `UID: ${randomID}-8888@dopenotes.io`,
      "DTSTART:" + FinalDate,
      "DTEND:" + FinalDate,
      "SUMMARY:" + reminderTitle,
      "URL:https://www.dopenotes.io",
      "BEGIN:VALARM",
      "ACTION:DISPLAY",
      "DESCRIPTION:" + reminderBody,
      "TRIGGER:-PT30M",
      "END:VALARM",
      "END:VEVENT",
      "END:VCALENDAR",
      DailyBool ? "RRULE:FREQ=DAILY" : "",
    ].join("\n");

    let blob = new Blob([url], { type: "text/calendar;charset=utf-8" });

    try {
      await uploadFiles(blob);
    } catch (error) {}

    //  console.log("What is URL", url);

    //window.open(ics(event), "_blank");

    if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
      // Open/Save link in IE and Edge
      //   window.location.href = "./reminders/myevents.ics";
    } else if (window.isNative) {
      /*    window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "CalendarICS",
          url: ICSURL,
        })
      );*/
    } else {
      // Open/Save link in Modern Browsers
      /*    window.open(
        encodeURI("data:text/calendar;charset=utf8," + url),
        "_blank"
      );*/
    }
  };

  //End ICAL

  // file uploader

  const uploadFiles = async (file) => {
    //
    //console.log("started");
    const uploadTask = storage
      //todo add remindertitle for the file name, I'll need to stripe the spaces.
      .ref(`ics/${user.user.id}/${startTime.toString()}/myreminder.ics`)
      .put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        //
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (error) => {
        alert(
          "Upload failed. Please select a valid jpg, png, gif, or jpeg image that is less then 5mb."
        );
      },
      () => {
        storage
          .ref(`ics/${user.user.id}/${startTime.toString()}`)
          .child("myreminder.ics")
          .getDownloadURL()
          .then((url) => {
            //   console.log(url);
            // seticsURL(url);
            icsURL.current = url;
            // console.log("what is the url", ICSURL);
            //    alert("My Link", url);
          });
      }
    );
  };

  //button for the end modal

  const close = () => {
    setReminderDoneModal(false);
    setIsSchedulingReminder(false);
    setReminderTitle("");
    setReminderBody("");
    var current = new Date();
    const time = current.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    setValueTime(time);
    setRepeatReminder(false);
    setReminderDays(new Date());
  };

  const downloadICSButton = () => {
    window.open(icsURL.current);
  };

  const GoogleCalendar = () => {
    //@ts-ignore
    window.open(google(Reminder.current));
  };

  //image uploader end

  // end filer uploader

  useEffect(() => {
    var current = new Date();
    const time = current.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    setValueTime(time);

    window.scrollTo({ top: 0, behavior: "smooth" });
    if (window.isNative) {
      setIsNative(true);
    } else {
      setIsNative(false);
    }

    return () => {};
  }, []);

  return (
    <section className={classes.section}>
      <h2
        style={{
          padding: 10,
          fontWeight: "bold",
          textAlign: "center",
          marginTop: -15,
        }}
      >
        Notification Reminder
      </h2>
      <p
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          marginTop: -20,
          textAlign: "center",
        }}
      >
        Please enter a title, body, and time for your reminder on this device.
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {" "}
        <img
          style={{
            width: "250px",
            verticalAlign: "middle",
            cursor: "pointer",
            paddingRight: 10,
          }}
          src={notificationimage}
          alt="Example"
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: 10,
        }}
      ></div>{" "}
      <hr />
      <form onSubmit={notificationScheduler}>
        <div
          style={{
            paddingBottom: 0,
            paddingTop: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <label style={{ paddingTop: 10, paddingBottom: 5 }}>
            Reminder Title (60 Max)
          </label>
          <input
            style={{
              width: 220,
              fontSize: "inherit",
              fontFamily: "inherit",
              padding: 10,
            }}
            type="text"
            name="title"
            required
            minLength={1}
            placeholder="Enter a title here"
            maxLength={65}
            value={reminderTitle}
            onChange={(e) => setReminderTitle(e.target.value)}
          />
          <label style={{ paddingTop: 10, paddingBottom: 5 }}>
            Reminder Body (240 max)
          </label>
          <input
            style={{
              width: 220,
              fontSize: "inherit",
              fontFamily: "inherit",
              padding: 10,
            }}
            type="text"
            name="body"
            placeholder="optional"
            value={reminderBody}
            maxLength={240}
            onChange={(e) => setReminderBody(e.target.value)}
          />
          {/*   <label style={{paddingTop: 10, paddingBottom: 5}}>Date</label>
{/*<DatePicker onChange={(e)=>setStartTime(e)} value={new Date (startTime)} clearIcon={null} format="y MMM dd" calendarIcon={null} className={classes.timepicker} disableCalendar={true}/>*/}
          {!isDaily ? (
            <>
              <label style={{ paddingTop: 10, paddingBottom: 5 }}>
                Remind Me On{" "}
              </label>
              <DatePicker
                clearIcon={null}
                disableCalendar={true}
                onChange={(e) =>
                  e > new Date()
                    ? setReminderDays(e)
                    : setReminderDays(new Date())
                }
                value={reminderDays}
                format="y MMM dd"
                minDate={new Date()}
              />
            </>
          ) : (
            <label style={{ paddingTop: 10, paddingBottom: 5 }}>
              Remind Me Daily{" "}
            </label>
          )}

          {!isDaily ? (
            <span
              style={{
                color: "blue",
                textAlign: "center",
                padding: 5,
                cursor: "pointer",
              }}
              onClick={() => setIsDaily(true)}
            >
              {" "}
              Switch To Daily Reminder
            </span>
          ) : (
            <span
              style={{
                color: "blue",
                textAlign: "center",
                padding: 5,
                cursor: "pointer",
              }}
              onClick={() => setIsDaily(false)}
            >
              {" "}
              Set Due Date
            </span>
          )}
          {/*<input style={{width:50, fontSize: "inherit", fontFamily:"inherit", padding: 10}} type="number" name="days" required minLength={1} value={reminderDays} min={1} 
//@ts-ignore
  onChange={(e)=> (e.target.value >= 1) ? changeReminderDays(e.target.value): changeReminderDays(1) }/> */}

          <label style={{ paddingTop: 10, paddingBottom: 5 }}>
            At This Time
          </label>
          <TimePicker
            onChange={(e) => setValueTime(e)}
            value={valueTime}
            maxDetail="minute"
            disableClock
            clearIcon={null}
            className={classes.timepicker}
          />
          {/*<label style={{paddingTop: 20, paddingBottom: 5}}>Repeat until canceled?  (Checked = Yes)</label><input
          type="checkbox"
          checked={repeatReminder}
         
          onChange={() => {setRepeatReminder(!repeatReminder); console.log(repeatReminder) }}
        //  className={classes.details}
        /> */}
        </div>
        <div className={classes.modalActions}>
          {/*}  <button
            type="button"
            onClick={notificationCancel}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "tomato",
              cursor: "pointer",
            }}
            //   className={classes.secondaryButton}
          >
            <FontAwesomeIcon
              icon={faTrash}
              size="2x"
              style={{ fontSize: 20, paddingRight: 2 }}
            />
          </button> */}

          <button
            type="submit"
            style={{
              cursor: "pointer",
              fontFamily: "inherit",
              fontSize: "inherit",
            }}
            //   className={classes.secondaryButton}
          >
            Set Reminder
          </button>
        </div>
      </form>
      <div
        onClick={() => routeChange("/reminderhistory")}
        style={{
          cursor: "pointer",
          fontFamily: "inherit",
          fontSize: "inherit",
          textAlign: "center",
          color: "blue",
        }}
      >
        {" "}
        See Reminder History
      </div>
      <Modal isOpen={reminderDoneModal} onRequestClose={close}>
        <div
          style={{
            display: "flex",
            alignItems: "right",
            justifyContent: "right",
            margin: 10,
          }}
        >
          {" "}
          <button
            style={{
              backgroundColor: "transparent",
              borderStyle: "none",
              cursor: "pointer",
              marginTop: 10,
              marginRight: 10,
            }}
            onClick={close}
          >
            <FontAwesomeIcon icon={faClose} size="3x" />
          </button>
        </div>
        <div style={{ paddingBottom: 150, paddingLeft: 20, paddingRight: 20 }}>
          <h2 style={{ lineHeight: 1.5 }}>
            <FontAwesomeIcon icon={faCalendarDay} size="sm" /> Reminder Created
          </h2>
          <p
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              lineHeight: 1.5,
            }}
          >
            Title: {reminderTitle}
          </p>
          <p
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              lineHeight: 1.5,
            }}
          >
            Body: {reminderBody}
          </p>
          {isDaily ? (
            <p
              style={{
                fontSize: "inherit",
                fontFamily: "inherit",
                lineHeight: 1.5,
              }}
            >
              Repeats Daily
            </p>
          ) : (
            <p
              style={{
                fontSize: "inherit",
                fontFamily: "inherit",
                lineHeight: 1.5,
              }}
            >
              Date: {moment(new Date(reminderDays)).format("MMMM DD, YYYY")}
            </p>
          )}
          <p
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              lineHeight: 1.5,
            }}
          >
            Time: {valueTime}
          </p>
          <hr />
          <p
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              lineHeight: 1.5,
            }}
          >
            Would you like to add the reminder to a different calendar? Please
            select the calendar where you would like to add this reminder:
          </p>
          <div className="reminder-button-grid">
            <button
              className="reminder-calendar-buttons"
              onClick={downloadICSButton}
            >
              Universal Calendar
            </button>
            <button
              className="reminder-calendar-buttons"
              onClick={GoogleCalendar}
            >
              Google Calendar
            </button>
            <button
              style={{
                fontSize: "inherit",
                fontFamily: "inherit",
                color: "white",
                backgroundColor: "#1e79c3",
                borderColor: "white",
                padding: 10,
                borderRadius: 10,
                paddingRight: 30,
                paddingLeft: 30,
                width: 200,
              }}
              onClick={close}
            >
              Close
            </button>
          </div>
          <div style={{ paddingBottom: 150 }} />
        </div>
      </Modal>
    </section>
  );
};

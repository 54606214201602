import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faStar,
  faClose,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import cx from "classnames/bind";

import { Button, Modal } from "Common";
import { ItemModel, useItemsToDo } from "../../../ListToDo";

import classes from "./ItemModal.module.css";
import { useUserContext } from "Authentication";
import TextareaAutosize from "react-textarea-autosize";
import { ConfirmationModal } from "Common";
import { CustomNotification } from "Reminders/CustomNotification";

const classnames = cx.bind(classes);

interface ItemModalProps {
  isOpen: boolean;
  item: ItemModel | undefined;
  close: () => void;
}

export const ItemModalToDo = (props: ItemModalProps) => {
  const { isOpen, item, close } = props;
  const [name, setName] = useState(item?.name);
  const [notes, setNotes] = useState(item?.notes);
  const [isFavorite, setIsFavorite] = useState(item?.isFavorite);
  const [flagged, setFlagged] = useState(item?.flagged);
  const [addedDate, setAddedDate] = useState(item?.addedDate);
  const [addedBy, setAddedBy] = useState(item?.addedBy);
  const [lastCompleted, setLastCompleted] = useState(item?.lastCompleted);
  const { deleteItem, removeItem, setItemFlag, setItemFavorite, updateItem } =
    useItemsToDo();
  const user = useUserContext();
  const [hideMoreInfo, setHideMoreInfo] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isSchedulingReminder, setIsSchedulingReminder] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  // var name = item?.name

  const CloseMenu = () => {
    setIsSchedulingReminder(false);
  };

  useEffect(() => {
    // Prevents old values being displayed when modal fades out.
    if (isOpen) {
      setName(item?.name);
      setNotes(item?.notes);
      setAddedDate(item?.addedDate);
      setAddedBy(item?.addedBy);
      setFlagged(item?.flagged);
      setIsFavorite(item?.isFavorite);
    }
  }, [item, isOpen]);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (notes !== item?.notes && item?.id) {
      await updateItem(item.id, {
        notes,
      });
    }

    if (name !== item?.name && item?.id) {
      await updateItem(item.id, {
        name,
        addedBy: user.user.name,
      });
    }

    close();
  }

  async function handleRemove() {
    item?.id && (await removeItem(item.id));
    close();
  }

  async function handleDelete() {
    item?.id && (await deleteItem(item?.id));
    close();
  }

  function handleFlag() {
    setFlagged((f) => !f);
    item?.id && setItemFlag(item.id, !flagged);
  }

  function handleFavorite() {
    setIsFavorite((f) => !f);
    item?.id && setItemFavorite(item.id, !isFavorite);
  }

  const DeleteItemConfirmation = () => {
    handleDelete();
    setConfirmModalOpen(false);
  };

  const updateNameValue = (e) => {
    setName(e);
    setIsUpdated(true);
  };

  const updateNotesValue = (e) => {
    setNotes(e);
    setIsUpdated(true);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={close}>
      <div style={{ paddingBottom: 100 }}>
        <Modal.Body>
          <div style={{ padding: 10 }}>
            <div
              style={{
                paddingLeft: 30,
                paddingRight: 30,
                paddingBottom: 10,
                border: 1,
                borderColor: "grey",
                borderRadius: 10,
                backgroundColor: "snow",
                color: "grey",
                boxShadow: "2px 3px 3px grey, 0 0 5px #FCFBF4 inset",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "right",
                  justifyContent: "right",
                  margin: 10,
                }}
              >
                {" "}
                <button
                  style={{
                    backgroundColor: "white",
                    borderStyle: "none",
                    cursor: "pointer",
                    marginRight: -25,
                    marginTop: 10,
                  }}
                  onClick={close}
                >
                  <FontAwesomeIcon icon={faClose} size="1x" />
                </button>
              </div>

              <form onSubmit={handleSubmit}>
                <label>Item Name</label>
                <TextareaAutosize
                  value={name}
                  onChange={(e) => updateNameValue(e.target.value)}
                  style={{
                    width: "85%",
                    fontSize: 22,
                    //    color: "navy",
                    backgroundColor: "transparent",
                    padding: 10,
                    border: 0,
                  }}
                />
                <label style={{ paddingTop: 10, paddingBottom: 5 }}>
                  Notes
                </label>
                <TextareaAutosize
                  value={notes}
                  // rows={3}
                  placeholder="Enter any notes here ..."
                  onChange={(e) => updateNotesValue(e.target.value)}
                  style={{
                    width: "85%",
                    fontSize: 20,
                    padding: 10,
                    //   color: "navy",
                    backgroundColor: "transparent",
                  }}
                />
                <div>
                  <Button
                    type="button"
                    title={isFavorite ? "Item Favorite" : "Make Favorite"}
                    onClick={handleFavorite}
                    className={classnames(classes.isFavoriteButton, {
                      [classes.isFavorite]: isFavorite,
                    })}
                  >
                    <FontAwesomeIcon
                      style={{ marginBottom: 1 }}
                      icon={faStar}
                      size="3x"
                    />
                  </Button>
                </div>
                {/*  <div>
                  <Button
                    type="button"
                    title={flagged ? "Item flagged" : "Flag item"}
                    onClick={handleFlag}
                    className={classnames(classes.flagButton, {
                      [classes.flagged]: flagged,
                    })}
                  >
                    <FontAwesomeIcon icon={faExclamationCircle} size="3x" />
                  </Button>
                
                  <Button
                    type="button"
                    title="Create Reminder"
                    onClick={() => setIsSchedulingReminder(true)}
                  >
                    <FontAwesomeIcon
                      style={{ marginBottom: 1 }}
                      icon={faBell}
                      size="3x"
                    />
                  </Button>
                  </div>*/}
                <p style={{ fontWeight: 600 }}>Date Added:</p>{" "}
                <span className={classes.infoSection}>{item?.addedDate}</span>
                {hideMoreInfo ? (
                  <div>
                    <hr />
                    <div style={{ paddingBottom: 0 }}>
                      <button
                        style={{
                          fontFamily: "inherit",
                          fontSize: "inherit",
                          backgroundColor: "#1e79c3",
                          cursor: "pointer",
                          border: 0,
                          borderRadius: 10,
                          color: "white",
                          paddingLeft: 20,
                          paddingRight: 20,
                        }}
                        onClick={() => setHideMoreInfo(false)}
                      >
                        Hide Details
                      </button>
                    </div>
                    <p style={{ fontWeight: 600 }}>Added By:</p>{" "}
                    <span className={classes.infoSection}>{item?.addedBy}</span>
                    {item?.completed ? (
                      <>
                        <p style={{ fontWeight: 600 }}>Marked Completed:</p>{" "}
                        <span className={classes.infoSection}>
                          {item?.lastCompleted}
                        </span>
                        <p style={{ fontWeight: 600 }}>Completed by:</p>{" "}
                        <span className={classes.infoSection}>
                          {item?.completedBy}
                        </span>
                      </>
                    ) : null}
                    <h4>
                      Use Count:{" "}
                      <span className={classes.infoSection}>
                        {" "}
                        {item?.count}
                      </span>
                    </h4>
                    <div>
                      <button
                        style={{
                          fontFamily: "inherit",
                          fontSize: "inherit",
                          color: "blue",
                          paddingBottom: 10,
                          paddingTop: 10,
                          backgroundColor: "transparent",
                          borderStyle: "none",
                        }}
                        type="button"
                        onClick={handleRemove}
                      >
                        Remove from list
                      </button>
                    </div>
                    <div>
                      <button
                        style={{
                          fontFamily: "inherit",
                          fontSize: "inherit",
                          color: "blue",
                          backgroundColor: "transparent",
                          borderStyle: "none",
                        }}
                        type="button"
                        onClick={() => setConfirmModalOpen(true)}
                      >
                        Delete permanently
                      </button>
                    </div>
                  </div>
                ) : (
                  <div style={{ paddingTop: 20 }}>
                    <button
                      style={{
                        fontFamily: "inherit",
                        fontSize: "inherit",
                        backgroundColor: "#1e79c3",
                        cursor: "pointer",
                        border: 0,
                        borderRadius: 10,
                        color: "white",
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}
                      onClick={() => setHideMoreInfo(true)}
                    >
                      Show More Details
                    </button>
                  </div>
                )}
                <div
                  className={classes.modalActions}
                  style={{ paddingTop: 15 }}
                >
                  <button
                    type="button"
                    onClick={handleRemove}
                    className={classes.button}
                    style={{
                      fontFamily: "inherit",
                      fontSize: "inherit",
                      padding: 10,
                      width: 125,
                      border: 0,
                      marginTop: 30,
                    }}
                  >
                    Remove
                  </button>
                  {isUpdated ? (
                    <button
                      type="submit"
                      style={{
                        fontFamily: "inherit",
                        fontSize: "inherit",
                        padding: 10,
                        width: 125,
                        border: 0,
                        marginTop: 30,
                      }}
                    >
                      Update
                    </button>
                  ) : (
                    <span />
                  )}
                </div>
                <div className={classes.modalActions} style={{ paddingTop: 0 }}>
                  <button
                    type="button"
                    onClick={close}
                    className={classes.secondaryButton}
                    style={{
                      fontFamily: "inherit",
                      fontSize: "inherit",
                      padding: 10,
                      cursor: "pointer",
                      backgroundColor: "transparent",
                      color: "blue",
                      width: 200,
                      border: 0,
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </form>
              <div style={{ paddingBottom: 150 }} />
            </div>
          </div>
        </Modal.Body>
      </div>
      <ConfirmationModal
        isOpen={confirmModalOpen}
        text="Are you sure? This action can not be undone if accepted."
        onClickCancel={() => setConfirmModalOpen(false)}
        onClickConfirm={DeleteItemConfirmation}
      />
      <Modal isOpen={isSchedulingReminder} style={{ overlay: { zIndex: 0 } }}>
        <div style={{ paddingTop: 5, paddingBottom: 20, paddingLeft: 5 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
              alignItems: "right",
              paddingLeft: 20,
              marginTop: -10,
              marginRight: 10,
              paddingBottom: 10,
              paddingTop: 30,
            }}
          >
            <button
              onClick={CloseMenu}
              style={{
                backgroundColor: "transparent",
                border: 0,
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon icon={faClose} size="1x" />
            </button>
          </div>

          <CustomNotification title={name} body="" daily={false} />
          <hr />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              style={{
                fontSize: "inherit",
                fontFamily: "inherit",
                color: "white",
                backgroundColor: "#1e79c3",
                borderColor: "white",
                padding: 10,
                borderRadius: 10,
                paddingRight: 30,
                paddingLeft: 30,
                width: 200,
                marginTop: 15,
              }}
              onClick={CloseMenu}
            >
              Close Window
            </button>
          </div>
          <div style={{ paddingBottom: 150 }} />
        </div>
      </Modal>
    </Modal>
  );
};

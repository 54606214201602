//import { useState, useEffect } from "react";
import { CreateGroupForm } from "./CreateGroupForm";
//import { useAuth } from "../Authentication/AuthContext";
//import { useUser, useUserContext } from "Authentication";
//import classes from "./Register.module.css";
//import { Helmet } from "react-helmet";

export const Register = () => {
  //  const user = useUser();
  // const [displayName, setDisplayName] = useState(false);
  // const [isDisplayName, setIsDisplayName] = useState(user.isNickNameSet);
  // const [nickName, setUserNickName] = useState(user.name);
  // const { setNickName, refreshUser } = useUserContext();

  /* const handleSubmit = async (event) => {
    var deviceOS = "web";

    if ((window as any).isNative) {
      if ((window as any).isIOS) {
        deviceOS = "iOS";
      } else {
        deviceOS = "Android";
      }
    }

    var app = "Dope Notes";

    if ((window as any).isAloha) {
      app = "Aloha Planner";
    }

    event.preventDefault();
    await setNickName(nickName, true, deviceOS, app);
    setIsDisplayName(true);
    //console.log(user.id)
    await refreshUser();
  };*/

  return (
    <article>
      <div style={{ paddingTop: 20 }} />
      {/*}  <form onSubmit={handleSubmit}>
        <div
          style={{
            textAlign: "center",
            fontSize: 20,
            fontWeight: "bold",
            marginTop: 80,
            fontFamily: "Ubuntu",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {" "}
          <p>Please enter your first name or nickname? </p>
          <input
            style={{
              fontSize: 18,
              padding: 10,
              width: "75%",
              alignSelf: "center",
              alignItems: "center",
            }}
            type="nickname"
            name="nickname"
            required
            minLength={1}
            placeholder={user.name}
            onChange={(e) => setUserNickName(e.target.value)}
          />
          <br />
          <input
            style={{
              width: "60%",
              paddingLeft: 50,
              paddingRight: 50,
              marginTop: -1,
              alignSelf: "center",
              alignItems: "center",
            }}
            type="submit"
            value="Next"
          />
        </div>
          </form>*/}

      <section>
        <CreateGroupForm />

        {/*
        
        <div className={classes.divider} />
        <span>
          If you are joining an existing group, please click the invitation link found in your email.
  </span>*/}
      </section>
    </article>
  );
};

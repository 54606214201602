//I might be able to remove this....

import { useState, useCallback, useRef, useEffect } from "react";
import { useUserContext } from "Authentication";
import { Title } from "../generic/Title";
import { Container, ListOfHabits, Percentage, TitleContainer } from "./style";
import { CenterLoader } from "../HabitsPage/style";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
//import { UserTodayHabitWidget } from "../UserTodayHabit/UserTodayHabitWidget";
//import { UserHabit } from "../UserHabit";
import { UserHabits } from "../UserTodayHabit/UserHabits.js";
//import { Menu } from "../generic/Menu";
import moment from "moment";
import ReactCanvasConfetti from "react-canvas-confetti";
import { HabitForm } from "../generic/HabitForm";

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 10,
  left: 0,
};

export const HabitTodayPageWidget = ({
  habits,
  createHabit,
  CloseNewHabit,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [reset, setReset] = useState([]);
  const [todayHabitData, setTodayHabitData] = useState([]);
  const [isAllHabits, setIsAllHabits] = useState(false);

  // const [successRemoval, setSuccessRemoval] = useState(false);

  //For Add New Reminder Modal
  const user = useUserContext();
  const [isReminder, setIsReminder] = useState(false);

  const [newHabit, setNewHabit] = useState({
    name: "",
    days: [0, 1, 2, 3, 4, 5, 6],
    isTrackingReps: false,
    RepsLabel: "",
    isPlanner: true,
  });

  //confetti

  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  const addTracking = () => {
    if (newHabit.isTrackingReps) {
      setNewHabit({ ...newHabit, ["isTrackingReps"]: false });
    } else {
      setNewHabit({ ...newHabit, ["isTrackingReps"]: true });
    }
  };

  const addPlanner = () => {
    if (newHabit.isPlanner) {
      setNewHabit({ ...newHabit, ["isPlanner"]: false });
    } else {
      setNewHabit({ ...newHabit, ["isPlanner"]: true });
    }
  };

  //filter the habits data for today only
  const filterHabitsByDay = habits.filter((item) =>
    item.days.includes(dayjs().day())
  );
  //Show habits for today
  var completedHabits = habits.filter(
    (i) => i.dateLastUpdated == moment(new Date()).format("YYYYMMDD") && i.done
  ).length;

  //calculate the percentage done for the day
  var percentage =
    completedHabits >= 1
      ? parseInt((completedHabits / filterHabitsByDay.length) * 100)
      : 0;

  const FireIfCompleted = () => {
    fire();
    // updateWidget();
  };

  //Planner Duration and DB update

  useEffect(() => {
    //  window.scrollTo({ top: 0, behavior: "smooth" });
    // updateWidget();

    return () => {};
  }, [percentage]);

  return (
    <Container>
      <div style={{ paddingTop: 20 }} />

      <>
        <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
      </>

      {isLoading && todayHabitData.length === 0 && (
        <CenterLoader></CenterLoader>
      )}

      {/*<div style={{height: "85vh", margin: 0,  overflowY:"scroll"}}>*/}

      <ListOfHabits>
        {isAllHabits
          ? habits.map((element, index) => (
              <div style={{ width: "100%" }} key={index}>
                <UserHabits
                  key={index}
                  data={element}
                  reset={setReset}
                  confetti={FireIfCompleted}
                  isDetailView={false}
                />
              </div>
            ))
          : filterHabitsByDay.map((element, index) => (
              <div style={{ width: "100%" }} key={index}>
                <UserHabits
                  key={index}
                  data={element}
                  reset={setReset}
                  confetti={FireIfCompleted}
                  isDetailView={false}
                />
              </div>
            ))}
      </ListOfHabits>
      <br />

      {/*}  </div>*/}
      {/*  <Menu /> */}
      <HabitForm
        isOpen={createHabit}
        newHabit={newHabit}
        addTracking={addTracking}
        addPlanner={addPlanner}
        setNewHabit={setNewHabit}
        close={CloseNewHabit}
        user={user}
        isEdit={false}
      />
    </Container>
  );
};

import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { RecipeModel } from "Recipes";

import classes from "./RecipeListItem.module.css";
import { Link } from "react-router-dom";

export type RecipeListItemProps = {
  recipe: RecipeModel;
  onClick?: () => void;
};

export function RecipeListItem(props: RecipeListItemProps) {
  const { recipe, onClick } = props;
  const { name, directions, recipeUrl, ingredients } = recipe;

  return (
    <div onClick={onClick} className={classes.recipe}>
      <span
        style={{
          fontSize: "inherit",
          fontFamily: "inherit",
          color: "blue",
          fontWeight: "bold",
          paddingLeft: 20,
          paddingTop: 10,
          paddingBottom: 10,
          cursor: "pointer",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {name}
      </span>

      {recipeUrl ? (
        <Link
          to={{ pathname: recipeUrl }}
          onClick={(e) => e.stopPropagation()}
          target="_blank"
          rel="noreferrer"
          style={{ paddingLeft: "10px" }}
        >
          <FontAwesomeIcon icon={faExternalLinkAlt} size="xs" />
        </Link>
      ) : null}
      {/*   <div style={{paddingBottom: 20}}>
      <label style={{paddingBottom: 10, paddingTop: 10}}>Directions/Notes</label>
      <span style={{fontSize: 16, padding: 20}}>{directions}</span>
      </div>
      <label style={{paddingBottom:7}}>Shopping Ingredients</label>
      {(ingredients ?? [])?.length > 0 ? (
        <div className={classes.ingredients}>
          <small>{ingredients?.join(", ")}</small>
        </div>
      ) : null} */}
    </div>
  );
}

export const classicRecipesES = [
  {
    id: "dnr003",
    recipeUrl: "",
    name: "Espaguetis con Albóndigas",
    directions: `
            <ol>
              <li>Precalienta el horno a 175 grados C (350 grados F).</li>
              <li>En un tazón grande, combina la carne molida, el pan rallado, el queso parmesano, los huevos, la cebolla, el ajo, la sal y la pimienta. Mezcla bien.</li>
              <li>Forma la mezcla de carne en albóndigas de aproximadamente 2.5 cm de diámetro.</li>
              <li>Calienta el aceite de oliva en una sartén grande a fuego medio.</li>
              <li>Cocina las albóndigas en el aceite caliente durante 10-12 minutos, o hasta que estén doradas por todos lados.</li>
              <li>Transfiere las albóndigas a una fuente para hornear.</li>
              <li>En una cacerola, combina la salsa de tomate, el agua y el condimento italiano. Deja que hierva a fuego medio.</li>
              <li>Vierte la salsa de tomate sobre las albóndigas.</li>
              <li>Hornea en el horno precalentado durante 20 minutos, o hasta que las albóndigas estén completamente cocidas.</li>
              <li>Sirve sobre espaguetis cocidos.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Italiana",
        color: "indigo",
      },
      {
        text: "Cena",
        color: "indigo",
      },
      {
        text: "Plato Principal",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "carne molida",
        measurement: "1 libra",
      },
      {
        name: "migas de pan",
        measurement: "1/2 taza",
      },
      {
        name: "queso parmesano rallado",
        measurement: "1/4 taza",
      },
      {
        name: "huevos",
        measurement: "2 huevos, batidos",
      },
      {
        name: "cebolla",
        measurement: "1/2 taza, picada",
      },
      {
        name: "ajo",
        measurement: "2 dientes, picados",
      },
      {
        name: "sal",
        measurement: "1 cucharadita",
      },
      {
        name: "pimienta negra",
        measurement: "1/2 cucharadita",
      },
      {
        name: "aceite de oliva",
        measurement: "1/4 taza",
      },
      {
        name: "Tomate triturado en lata",
        measurement: "1 (28 onzas)",
      },
      {
        name: "agua",
        measurement: "1 taza",
      },
      {
        name: "condimento italiano",
        measurement: "1 cucharadita",
      },
      {
        name: "espaguetis",
        measurement: "12 onzas",
      },
    ],
  },
  {
    id: "X7gR4Kz2pQ",
    recipeUrl: "",
    name: "Paella",
    directions:
      "<ol><li>Caliente el aceite en una paellera y sofría el pollo y el conejo hasta que estén dorados.</li><li>Añada los pimientos y el ajo, y cocine durante unos minutos.</li><li>Incorpore el tomate triturado, el pimentón y el azafrán, y cocine por unos minutos más.</li><li>Agregue el arroz y rehóguelo con el sofrito. Vierta el caldo de pollo y distribuya los ingredientes uniformemente.</li><li>Cocine a fuego medio durante 10 minutos, luego reduzca el fuego y cocine durante otros 10 minutos. No remueva el arroz durante la cocción.</li><li>Agregue los mariscos y los guisantes, cocine por 5 minutos más.</li><li>Retire del fuego, cubra con un paño y deje reposar durante 10 minutos antes de servir.</li></ol>",
    days: [],
    tags: [
      { text: "Plato principal", color: "indigo" },
      { text: "Cocina española", color: "indigo" },
      { text: "Arroz", color: "indigo" },
    ],
    ingredients: [
      { name: "Aceite de oliva", measurement: "50 ml" },
      { name: "Pollo", measurement: "500g" },
      { name: "Conejo", measurement: "500g" },
      { name: "Pimientos", measurement: "2" },
      { name: "Ajo", measurement: "2 dientes" },
      { name: "Tomate triturado", measurement: "100g" },
      { name: "Pimentón", measurement: "1 cucharadita" },
      { name: "Azafrán", measurement: "1 pizca" },
      { name: "Arroz", measurement: "400g" },
      { name: "Caldo de pollo", measurement: "1 litro" },
      { name: "Mariscos variados", measurement: "200g" },
      { name: "Guisantes", measurement: "100g" },
    ],
  },
  {
    id: "L9m2Zd5KvN",
    recipeUrl: "",
    name: "Gazpacho",
    directions:
      "<ol><li>Corte los tomates, el pepino, el pimiento, la cebolla y el ajo en trozos.</li><li>Ponga las verduras en una licuadora y añada el pan duro, el vinagre, el aceite de oliva, la sal y un poco de agua. Triture hasta obtener una mezcla homogénea.</li><li>Pase la mezcla por un colador fino para obtener una textura suave. Ajuste el sabor con sal y vinagre.</li><li>Refrigere el gazpacho durante al menos 2 horas antes de servir.</li><li>Sirva frío, adornado con trozos de verduras frescas y un chorrito de aceite de oliva.</li></ol>",
    days: [],
    tags: [
      { text: "Entrante", color: "indigo" },
      { text: "Cocina española", color: "indigo" },
      { text: "Sopa fría", color: "indigo" },
    ],
    ingredients: [
      { name: "Tomates", measurement: "1 kg" },
      { name: "Pepino", measurement: "1" },
      { name: "Pimiento verde", measurement: "1" },
      { name: "Cebolla", measurement: "1" },
      { name: "Ajo", measurement: "2 dientes" },
      { name: "Pan duro", measurement: "100g" },
      { name: "Vinagre", measurement: "2 cucharadas" },
      { name: "Aceite de oliva", measurement: "50 ml" },
      { name: "Sal", measurement: "Al gusto" },
      { name: "Agua", measurement: "100 ml" },
    ],
  },
  {
    id: "Q5hT9zC3Wn",
    recipeUrl: "",
    name: "Tortilla Española",
    directions:
      "<ol><li>Pele y corte las patatas en rodajas finas. Pique la cebolla.</li><li>En una sartén con aceite, fría las patatas y la cebolla hasta que estén blandas, pero sin llegar a dorarse.</li><li>En un bol grande, bata los huevos con sal. Añada las patatas y la cebolla, mezclando bien.</li><li>En una sartén más pequeña con un poco de aceite, vierta la mezcla de huevo, patatas y cebolla.</li><li>Cocine a fuego medio-bajo hasta que el huevo esté cuajado por un lado. Dé la vuelta a la tortilla con la ayuda de un plato y cocine el otro lado.</li><li>Sirva caliente o fría.</li></ol>",
    days: [],
    tags: [
      { text: "Plato principal", color: "indigo" },
      { text: "Cocina española", color: "indigo" },
      { text: "Huevo", color: "indigo" },
    ],
    ingredients: [
      { name: "Patatas", measurement: "500g" },
      { name: "Cebolla", measurement: "1" },
      { name: "Huevos", measurement: "5" },
      { name: "Aceite de oliva", measurement: "Suficiente para freír" },
      { name: "Sal", measurement: "Al gusto" },
    ],
  },
  {
    id: "T8pR9kZ3Lm",
    recipeUrl: "",
    name: "Pisto",
    directions:
      "<ol><li>Corte en dados pequeños el calabacín, el pimiento, la cebolla y el tomate.</li><li>En una sartén con aceite, sofría la cebolla y el pimiento hasta que estén tiernos.</li><li>Añada el calabacín y cocine durante unos minutos. Luego, incorpore el tomate y cocine a fuego lento hasta que todas las verduras estén blandas.</li><li>Salpimiente al gusto y añada una pizca de azúcar para reducir la acidez del tomate.</li><li>Sirva caliente, como guarnición o plato principal.</li></ol>",
    days: [],
    tags: [
      { text: "Guarnición", color: "indigo" },
      { text: "Cocina española", color: "indigo" },
      { text: "Vegetariano", color: "indigo" },
    ],
    ingredients: [
      { name: "Calabacín", measurement: "1" },
      { name: "Pimiento", measurement: "1" },
      { name: "Cebolla", measurement: "1" },
      { name: "Tomate", measurement: "3" },
      { name: "Aceite de oliva", measurement: "2 cucharadas" },
      { name: "Sal", measurement: "Al gusto" },
      { name: "Pimienta", measurement: "Al gusto" },
      { name: "Azúcar", measurement: "1 pizca" },
    ],
  },
  {
    id: "Z6fJ8Vx1pX",
    recipeUrl: "",
    name: "Fabada Asturiana",
    directions:
      "<ol><li>Ponga las fabas a remojar en agua fría la noche anterior.</li><li>En una olla grande, ponga las fabas escurridas, el chorizo, la morcilla, el tocino y el jamón. Cubra con agua fría.</li><li>Lleve a ebullición, luego reduzca el fuego y cocine a fuego lento, retirando la espuma que se forme.</li><li>Añada el azafrán, el pimentón y el laurel. Cocine a fuego lento hasta que las fabas estén tiernas, aproximadamente 2-3 horas.</li><li>Rectifique de sal y sirva caliente.</li></ol>",
    days: [],
    tags: [
      { text: "Plato principal", color: "indigo" },
      { text: "Cocina española", color: "indigo" },
      { text: "Legumbres", color: "indigo" },
    ],
    ingredients: [
      { name: "Fabas", measurement: "500g" },
      { name: "Chorizo", measurement: "2 piezas" },
      { name: "Morcilla", measurement: "1 pieza" },
      { name: "Tocino", measurement: "100g" },
      { name: "Jamón", measurement: "100g" },
      { name: "Azafrán", measurement: "1 pizca" },
      { name: "Pimentón", measurement: "1 cucharadita" },
      { name: "Laurel", measurement: "1 hoja" },
      { name: "Sal", measurement: "Al gusto" },
    ],
  },
  {
    id: "B4cK0lQ8wR",
    recipeUrl: "",
    name: "Croquetas",
    directions:
      "<ol><li>En una sartén, derrita la mantequilla y añada la harina, cocinando por un par de minutos.</li><li>Incorpore la leche gradualmente, removiendo constantemente, hasta obtener una bechamel espesa. Añada el jamón picado, sal, pimienta y nuez moscada.</li><li>Extienda la mezcla en una fuente y deje enfriar. Una vez fría, forme las croquetas con las manos.</li><li>Pase las croquetas primero por huevo batido y luego por pan rallado.</li><li>Fría las croquetas en aceite caliente hasta que estén doradas. Escúrralas sobre papel absorbente.</li><li>Sirva caliente.</li></ol>",
    days: [],
    tags: [
      { text: "Entrante", color: "indigo" },
      { text: "Cocina española", color: "indigo" },
      { text: "Frito", color: "indigo" },
    ],
    ingredients: [
      { name: "Mantequilla", measurement: "50g" },
      { name: "Harina", measurement: "50g" },
      { name: "Leche", measurement: "500 ml" },
      { name: "Jamón serrano", measurement: "100g" },
      { name: "Sal", measurement: "Al gusto" },
      { name: "Pimienta", measurement: "Al gusto" },
      { name: "Nuez moscada", measurement: "1 pizca" },
      { name: "Huevo", measurement: "1" },
      { name: "Pan rallado", measurement: "Suficiente para rebozar" },
      { name: "Aceite de oliva", measurement: "Para freír" },
    ],
  },
  {
    id: "N3gL9kZ6Wu",
    recipeUrl: "",
    name: "Calamares a la Romana",
    directions:
      "<ol><li>Corte los calamares en aros y séquelos con papel de cocina.</li><li>Prepare una mezcla con la harina, la levadura y una pizca de sal. Añada agua fría poco a poco hasta obtener una masa homogénea y sin grumos.</li><li>Sumergir los aros de calamar en la masa y luego freírlos en aceite caliente hasta que estén dorados.</li><li>Retire los calamares del aceite y escúrralos en papel absorbente para eliminar el exceso de grasa.</li><li>Sirva caliente acompañado de rodajas de limón.</li></ol>",
    days: [],
    tags: [
      { text: "Entrante", color: "indigo" },
      { text: "Cocina española", color: "indigo" },
      { text: "Mariscos", color: "indigo" },
    ],
    ingredients: [
      { name: "Calamares", measurement: "500g" },
      { name: "Harina", measurement: "100g" },
      { name: "Levadura en polvo", measurement: "1 cucharadita" },
      { name: "Sal", measurement: "Al gusto" },
      { name: "Agua fría", measurement: "Según necesidad" },
      { name: "Aceite de oliva", measurement: "Para freír" },
      { name: "Limón", measurement: "Para servir" },
    ],
  },
  {
    id: "P7rT0cE2Lq",
    recipeUrl: "",
    name: "Patatas Bravas",
    directions:
      "<ol><li>Corte las patatas en cubos y fríalas en aceite caliente hasta que estén doradas y crujientes. Escurra en papel absorbente.</li><li>Para la salsa brava, sofría el ajo picado en aceite. Añada la harina y cocine por un minuto. Incorpore el pimentón, la salsa de tomate y el caldo de pollo. Cocine a fuego lento hasta que la salsa espese. Añada tabasco o cayena al gusto.</li><li>Sirva las patatas calientes con la salsa brava por encima.</li></ol>",
    days: [],
    tags: [
      { text: "Entrante", color: "indigo" },
      { text: "Cocina española", color: "indigo" },
      { text: "Patatas", color: "indigo" },
    ],
    ingredients: [
      { name: "Patatas", measurement: "500g" },
      { name: "Aceite de oliva", measurement: "Para freír" },
      { name: "Ajo", measurement: "1 diente" },
      { name: "Harina", measurement: "1 cucharada" },
      { name: "Pimentón", measurement: "1 cucharadita" },
      { name: "Salsa de tomate", measurement: "100 ml" },
      { name: "Caldo de pollo", measurement: "100 ml" },
      { name: "Tabasco o cayena", measurement: "Al gusto" },
    ],
  },
  {
    id: "R9zE6Ks2Lf",
    recipeUrl: "",
    name: "Jamón Ibérico",
    directions:
      "<ol><li>Seleccione una pieza de calidad de Jamón Ibérico.</li><li>Utilice un jamonero y un cuchillo largo y afilado específico para cortar jamón.</li><li>Corte el jamón en lonchas muy finas, casi transparentes.</li><li>Sirva las lonchas de jamón a temperatura ambiente, permitiendo que el jamón respire unos minutos antes de servir.</li></ol>",
    days: [],
    tags: [
      { text: "Entrante", color: "indigo" },
      { text: "Cocina española", color: "indigo" },
      { text: "Carne", color: "indigo" },
    ],
    ingredients: [{ name: "Jamón Ibérico", measurement: "Según necesidad" }],
  },
  {
    id: "M0zF5Gt3Wq",
    recipeUrl: "",
    name: "Churros",
    directions:
      "<ol><li>En una olla, mezcle el agua, la mantequilla, el azúcar y la sal. Lleve a ebullición y retire del fuego.</li><li>Añada la harina y mezcle vigorosamente hasta obtener una masa homogénea. Deje enfriar la masa un poco.</li><li>Introduzca la masa en una churrera o una manga pastelera con boquilla estrellada.</li><li>Caliente aceite en una sartén profunda y exprima tiras de masa en el aceite caliente, cortando con unas tijeras. Fría hasta que estén dorados y crujientes.</li><li>Escúrralos en papel absorbente y espolvoréelos con azúcar mezclado con canela.</li><li>Sirva los churros calientes, idealmente con chocolate caliente para mojar.</li></ol>",
    days: [],
    tags: [
      { text: "Dessert", color: "indigo" },
      { text: "Cocina española", color: "indigo" },
      { text: "Frito", color: "indigo" },
    ],
    ingredients: [
      { name: "Agua", measurement: "250 ml" },
      { name: "Mantequilla", measurement: "50g" },
      { name: "Azúcar", measurement: "1 cucharada" },
      { name: "Sal", measurement: "1 pizca" },
      { name: "Harina", measurement: "150g" },
      { name: "Aceite", measurement: "Para freír" },
      { name: "Azúcar para espolvorear", measurement: "Al gusto" },
      { name: "Canela", measurement: "Al gusto" },
    ],
  },
];

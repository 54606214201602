import { Link, NavLink, Redirect, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  //faSwimmer,
  //faCog,
  //faCartShopping,
  //faUtensils,
  //faListCheck,
  //faStickyNote,
  //faTrash,
  faBars,
  faHome,
  faClose,
  //faHand,
  //faQuestion,
  faQuestionCircle,
  //faBell,
  //faPallet,
  //faPaintBrush,
  //faPaintRoller,
  faRefresh,
  faF,
  //faShoppingBasket,
  faShoppingCart,
  faSpoon,
  faNoteSticky,
  faList,
  faBookOpen,
  faRunning,
  //faPersonRunning,
  //faBackwardStep,
  //faBackspace,
  //faBackward,
  faArrowCircleLeft,
  faCalendarDays,
  faCog,
  faPaintRoller,
  faPowerOff,
  faWifi,
  faPrint,
  faKitchenSet,
  //faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import { UserExperienceRater } from "./UserExperienceRater";
import { Modal } from "Common";
import { useAuth, useIsRegistered } from "Authentication";
import { useUserContext } from "Authentication";
import classes from "./Header.module.css";
import { useState, useEffect } from "react";
//import RMModal from "react-modal";
//import logo from "../../logo192.png";
//import homeicon from "../../../src/Assets/home.png";
import sortablelistgif from "../../../src/Assets/uparrow2.gif";
import mylisticon from "../../../src/Assets/checklist.png";
import plannericon from "../../../src/Assets/planner.png";
import habiticon from "../../../src/Assets/lifestyle.png";
import journalicon from "../../../src/Assets/notebook.png";
import recipeicon from "../../../src/Assets/recipe.png";
import notesicon from "../../../src/Assets/sticky-notes.png";
import settingsicon from "../../../src/Assets/user-profile.png";
import shoppingicon from "../../../src/Assets/wishlist.png";
//import remindericon from "../../../src/Assets/notifications.png";
//import colouricon from "../../../src/Assets/colour.png";
import scoreIcon from "../../Assets/score.png";
import helpicon from "../../../src/Assets/help.png";
//import refreshicon from "../../../src/Assets/refresh.png";

import { SubscriptionChecker } from "Authentication/SubscriptionChecker";
import { FirebaseAnalytics } from "Config/FirebaseAnalytics";
import { CirclePicker, GithubPicker } from "react-color";
import { ConfirmationModal } from "Common";
//import TimePicker from "react-time-picker";
import { useHistory } from "react-router-dom";
import { FirstLaunchHelp } from "Splash/RegisteredSplash/sections/FirstLaunchHelp";
import { BuyNowModal } from "Authentication/BuyNowModal";
//import { LanguageSwitcher } from "Locales/LanguageSwitcher";
import { useTranslation } from "react-i18next";
//import { Breadcrumb } from "./Breadcrumbs";
//import TimePicker from 'react-time-picker';

export const Header = ({
  isColorPickerOpen,
  setIsColorPickerOpen,
  isDarkMode,
  setIsDarkMode,
  isOnline,
  setIsOnline,
}) => {
  const { logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isFeedBackOpen, setIsFeedbackOpen] = useState(false);
  const currentYear = new Date().getFullYear();
  const user = useUserContext();
  const { UpdateThemeColor } = useUserContext();
  const [isMobile, setIsMobile] = useState(false);
  const [headerBackButton, setHeaderBackButton] = useState(false);
  const [isGoogleMessage, setIsGoogleMessage] = useState(false);
  const [isHideFooter, setIsHideFooter] = useState(
    localStorage.getItem("isHideFooter") != undefined
      ? JSON.parse(localStorage.getItem("isHideFooter"))
      : true
  );
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalOfflineOpen, setConfirmModalOfflineOpen] = useState(false);
  const [isAlohaPlanner, setIsAlohaPlanner] = useState(false);
  const [isAdsOff, setIsAdsOff] = useState(true);
  const [isTrial, setIsTrial] = useState(true);
  const [isExpired, setIsExpired] = useState(false);
  const [isBuyNow, setIsBuyNow] = useState(false);
  const [hideFooter, setHideFooter] = useState(
    localStorage.getItem("hideFooter") != undefined
      ? JSON.parse(localStorage.getItem("hideFooter"))
      : false
  );
  // const [isOnlinex, setIsOnlinex] = useState(navigator.onLine);
  const [isFirstLaunchHelp, setIsFirstLuanchHelp] = useState(
    localStorage.getItem("firstLaunch") != undefined
      ? JSON.parse(localStorage.getItem("firstLaunch"))
      : true
  );

  //close footer menu
  var themeFont = "Ubuntu";
  const { t } = useTranslation();
  var themeColor = "#673ab7";
  var IsRegistered = useIsRegistered();

  // var currerntUserID = IsRegistered ? user.user.id : "";

  const changeIsTrialOff = () => {
    setIsTrial(false);
  };

  const changeIsTrialOn = () => {
    setIsTrial(true);
  };

  const setIsExpiredOff = () => {
    setIsExpired(false);
  };

  const setIsExpiredOn = () => {
    setIsExpired(true);
  };

  //console.log("what is the user ID header", currerntUserID);

  const CloseFirstLaunchHelp = () => {
    setIsFirstLuanchHelp(false);
    localStorage.setItem("firstLaunch", "false");
  };

  const LaunchFirstLaunchHelp = () => {
    setIsFirstLuanchHelp(true);
  };

  let history = useHistory();

  const CloseMenu = () => {
    setIsMenuOpen(false);
    setIsFeedbackOpen(false);
    setIsNotificationOpen(false);

    ScrollToTop();
  };

  const OpenMenu = () => {
    setIsMenuOpen(true);
    CloseFirstLaunchHelp();
    setIsFeedbackOpen(false);
  };

  const OpenFeedback = () => {
    setIsMenuOpen(false);
    setIsFeedbackOpen(true);
  };

  const [BGColor, setBGColor] = useState("#673ab7");
  const Newcolors = [
    "black",
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#607d8b",
  ];

  const handleColorChangeComplete = (color, event) => {
    // console.log("which color", color.hex)
    themeColor = color.hex;
    setBGColor(color.hex);
    setIsColorPickerOpen(false);
    UpdateThemeColor(color.hex);
  };

  const handleThemeSwitch = () => {
    setIsDarkMode(!isDarkMode);
  };

  const ScrollToTop = () => {
    window.onbeforeunload = function () {
      // window.scrollTo(0, 0);
    };
  };

  const location = useLocation();

  const handleBuyNow = () => {
    history.push("/buynow");
  };

  const toggleFooter = () => {
    if (!hideFooter) {
      setHideFooter(true);
      localStorage.setItem("hideFooter", "true");
    } else {
      setHideFooter(false);
      localStorage.setItem("hideFooter", "false");
    }
  };

  const toggleFooterBar = () => {
    if (!isHideFooter) {
      setIsHideFooter(true);

      localStorage.setItem("isHideFooter", "true");
    } else {
      toggleFooter();
      setIsHideFooter(false);
      localStorage.setItem("isHideFooter", "false");
    }
  };

  //console.log('pathname', location.pathname);

  const checkThemeSettings = () => {
    if (IsRegistered) {
      // @ts-ignore
      themeFont =
        // @ts-ignore
        user.user.themeFont != undefined ? user.user.themeFont : "Ubuntu";
      // @ts-ignore
      themeColor =
        // @ts-ignore
        user.user.themeColor != undefined ? user.user.themeColor : "#673ab7";

      document.body.dataset.theme = themeFont;
      setBGColor(themeColor);
    }
  };

  //console.log("is trial", isTrial);

  const [footerPaddingRight, setFooterPaddingRight] = useState(5);
  const [footerPaddingLeft, setFooterPaddingLeft] = useState(5);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    // Get the current window height
    // const windowWidth = window.innerWidth;
    // setScreenWidth(window.innerWidth);

    //  console.log("what is the screensize", window.innerWidth);

    if (window.innerWidth < 300) {
      setFooterPaddingRight(10);
      setFooterPaddingLeft(60);
      // setHideFooter(true);
    } else {
      setFooterPaddingRight(0);
      setFooterPaddingLeft(0);
    }

    // Conditionally hide the footer based on the screen height
    if (window.innerWidth > 740) {
      setHeaderBackButton(true);
      // setHideFooter(true);
    } else {
      setHeaderBackButton(true);
      // setHideFooter(false);
    }
  };

  const print = () => window.print();

  useEffect(() => {
    // Attach the resize event listener
    // window.addEventListener('resize', handleResize);
    if (localStorage.getItem("UER2023FirstDate") == undefined) {
      localStorage.setItem(
        "UER2023FirstDate",
        getEpochFutureDate(2).toString()
      );
      // console.log("what is saved date?");
    }

    // Call the handler once to set the initial state
    handleResize();
    if (isUERPending) {
      checkUERater();
    }

    //reset for testing
    //localStorage.clear();
    //logout();
    // currerntUserID = IsRegistered ? user.user.id : "";
    //console.log("what is the userID");

    //Old Google Translate
    //toggleDivVisibility();

    if ((window as any).isAloha) {
      setIsAlohaPlanner(true);
    }

    checkThemeSettings();
    if ((window as any).isNative) {
      // Post the message back to expo
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    const handleOnline = () => {
      //  setIsOnlinex(true);
      //  setIsOnline(true);
    };

    const handleOffline = () => {
      //  setIsOnlinex(false);
      // setIsOnline(false);
    };
    window.addEventListener("resize", handleResize);
    //   window.addEventListener("online", handleOnline);
    //   window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("resize", handleResize);
      //   window.removeEventListener("online", handleOnline);
      //   window.removeEventListener("offline", handleOffline);
    };
  }, [user, isAdsOff]);

  const reloadData = () => {
    //@ts-ignore
    window.location.reload(true);
    CloseFirstLaunchHelp();
    CloseMenu();
  };

  const ToggleGoogleMessage = () => {
    isGoogleMessage ? setIsGoogleMessage(false) : setIsGoogleMessage(true);
  };

  const backButtonAction = () => {
    history.goBack();
    CloseFirstLaunchHelp();
  };

  //user Experience Rater

  const [showModal, setShowModal] = useState(false);
  const getUERSPending = localStorage.getItem("UER2023");

  const [isUERPending, setIsUERPending] = useState(
    getUERSPending === "completed" ? false : true
  );

  //console.log("what is UERPENDING", getUERSPending);

  const closeUERater = () => {
    setShowModal(false);
  };
  const openUERater = () => {
    // if (IsRegistered) setShowModal(true);
  };

  const getEpochFutureDate = (days) => {
    const currentDate = new Date();
    const threeDaysLater = new Date(currentDate);
    threeDaysLater.setDate(currentDate.getDate() + days);

    const epochSecondsXDaysLater = Math.floor(threeDaysLater.getTime());
    return epochSecondsXDaysLater;
  };

  const checkUERater = () => {
    const todayDate = new Date();
    const todayEpochSeconds = Math.floor(todayDate.getTime() / 1000);

    // console.log("what is epoch time", todayEpochSeconds.toString());
    const getUERStatus =
      localStorage.getItem("UER2023FirstDate") != undefined
        ? localStorage.getItem("UER2023FirstDate")
        : getEpochFutureDate(3).toString();
    var UERStartDate = parseInt(getUERStatus);

    //console.log("what is UERVisitCount time", UERStartDate);
    //console.log("what is Today time", todayEpochSeconds * 1000);

    if (UERStartDate < todayEpochSeconds * 1000) {
      isUERPending ? openUERater() : closeUERater();
    }
  };

  return (
    <div>
      <FirebaseAnalytics />
      <UserExperienceRater
        showModal={showModal}
        close={closeUERater}
        user={user.user}
      />

      <div
        className={classes.headerTop}
        style={{
          backgroundColor: BGColor,
          flexDirection: "row",
          zIndex: 20,
        }}
      >
        {/*<FontAwesomeIcon onClick={OpenMenu} icon={faBars} size="2x" />*/}
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Link to="/">
            <FontAwesomeIcon
              icon={faHome}
              size="xl"
              style={{
                paddingLeft: 20,
                marginTop: 16,
                cursor: "pointer",
                color: "white",
              }}
              onClick={CloseFirstLaunchHelp}
            />
          </Link>
          {/*}    <LanguageSwitcher />*/}
          {/*}   <button onClick={toggleDivVisibility}>Toggle Div Visibility</button>*/}
          <div style={{ width: "97%" }}>
            {location.pathname != "/" && headerBackButton ? (
              <button
                style={{
                  position: "absolute",
                  //top: 20,

                  fontSize: 14,
                  marginLeft: 8,
                  marginTop: 5,
                  backgroundColor: "transparent",
                  border: 0,
                  // borderColor: "black",
                  color: "white",
                  fontWeight: "bold",
                  fontFamily: "Ariel",
                  alignSelf: "center",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                  paddingLeft: 5,
                  paddingRight: 5,
                  paddingTop: 5,
                  paddingBottom: 5,

                  background: "snow",
                  borderRadius: 40,
                }}
                onClick={backButtonAction}
              >
                <FontAwesomeIcon
                  //onClick={OpenMenu}
                  style={{
                    border: 1,
                    // borderColor: "blue",
                    borderStyle: "solid",

                    borderRadius: 40,
                  }}
                  color="black"
                  icon={faArrowCircleLeft}
                  size="3x"
                />{" "}
              </button>
            ) : null}
          </div>
          {useIsRegistered() ? (
            <>
              <div
                style={{
                  //   float: "right",
                  marginTop: 15,
                  height: "100%",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {isAlohaPlanner && !isAdsOff ? (
                  <div style={{ paddingLeft: 5, paddingRight: 5 }}>
                    <div
                      style={{
                        fontSize: 10,
                        lineHeight: 1,
                        padding: 4,
                        paddingLeft: 6,
                        paddingRight: 6,

                        fontWeight: "bold",
                        textAlign: "center",
                        border: 1,
                        borderColor: "white",
                        borderRadius: 30,
                        borderStyle: "solid",
                      }}
                      onClick={handleBuyNow}
                    >
                      No Ads
                    </div>
                  </div>
                ) : null}
                {isExpired || (isTrial && !isAlohaPlanner) ? (
                  <div
                    className={classes.blinkingButton}
                    style={{
                      fontSize: 12,
                      lineHeight: 1.1,
                      paddingLeft: 5,
                      paddingRight: 5,
                      fontWeight: "bold",
                      textAlign: "center",
                      fontFamily: "inherit",
                      cursor: "pointer",
                    }}
                    onClick={handleBuyNow}
                  >
                    {t("common-button-buynow")}!
                  </div>
                ) : !isMobile ? (
                  <FontAwesomeIcon
                    icon={faPrint}
                    size="lg"
                    onClick={print}
                    style={{
                      paddingRight: 10,
                      paddingLeft: 5,
                      cursor: "pointer",
                    }}
                  />
                ) : null}
                {isColorPickerOpen ? (
                  <div
                    style={{
                      marginTop: 20,
                    }}
                  >
                    <GithubPicker
                      onChangeComplete={handleColorChangeComplete}
                      colors={Newcolors}
                      value={BGColor}
                      width={175}
                      triangle="top-right"
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      {" "}
                      <button
                        onClick={() => handleThemeSwitch()}
                        style={{
                          paddingRight: 10,
                          paddingLeft: 10,
                          fontSize: 16,
                          width: "100%",
                        }}
                      >
                        {isDarkMode
                          ? t("header-button-dark")
                          : t("header-button-light")}
                      </button>
                      <button
                        onClick={() => setIsColorPickerOpen(false)}
                        style={{
                          paddingRight: 10,
                          paddingLeft: 10,
                          fontSize: 16,
                          width: "100%",
                        }}
                      >
                        {t("common-button-cancel")}
                      </button>
                    </div>
                  </div>
                ) : null}
                {!isColorPickerOpen ? (
                  <>
                    <FontAwesomeIcon
                      icon={faPaintRoller}
                      size="lg"
                      onClick={() => {
                        setIsColorPickerOpen(true);
                        CloseFirstLaunchHelp();
                      }}
                      style={{
                        paddingRight: 10,
                        paddingLeft: 10,
                        cursor: "pointer",
                      }}
                    />
                    <Link to="/settings">
                      <FontAwesomeIcon
                        icon={faCog}
                        size="lg"
                        style={{
                          paddingRight: 5,
                          paddingLeft: 5,
                          cursor: "pointer",
                          color: "white",
                        }}
                        onClick={CloseFirstLaunchHelp}
                      />
                    </Link>{" "}
                    {isOnline ? null : (
                      <FontAwesomeIcon
                        icon={faWifi}
                        size="lg"
                        onClick={() => setConfirmModalOfflineOpen(true)}
                        style={{
                          paddingRight: 5,
                          paddingLeft: 5,
                          cursor: "pointer",
                          color: "grey",
                        }}
                      />
                    )}
                    <FontAwesomeIcon
                      onClick={LaunchFirstLaunchHelp}
                      icon={faQuestionCircle}
                      size="lg"
                      style={{
                        cursor: "pointer",
                        color: "white",
                        paddingLeft: 5,
                        paddingRight: 5,
                      }}
                    />
                    <FontAwesomeIcon
                      onClick={OpenMenu}
                      style={{
                        paddingRight: 10,
                        paddingLeft: 5,
                        cursor: "pointer",
                        color: "white",
                      }}
                      icon={faBars}
                      size="xl"
                    />{" "}
                    {/*}    {isHideFooter ? (
                      <FontAwesomeIcon
                        icon={faF}
                        size="lg"
                        onClick={() => {
                          setIsHideFooter(false);
                          CloseFirstLaunchHelp();
                        }}
                        style={{
                          paddingRight: 10,
                          paddingLeft: 5,
                          cursor: "pointer",
                          color: "white",
                        }}
                      />
                    ) : (
                      <span />
                    )}*/}
                  </>
                ) : null}
              </div>{" "}
            </>
          ) : null}
        </div>
        {/*}  <Breadcrumb color={BGColor} />*/}
      </div>

      <div style={{ padding: 30 }} />

      {useIsRegistered() ? (
        <>
          <SubscriptionChecker
            user={user.user}
            //  isAdsOff={isAdsOff}
            isOnline={isOnline}
            setIsAdsOff={setIsAdsOff}
            setIsTrialOff={changeIsTrialOff}
            setIsTrialOn={changeIsTrialOn}
            setIsExpiredOff={setIsExpiredOff}
            setIsExpiredOn={setIsExpiredOn}
          />
          <FirstLaunchHelp
            isOpen={isFirstLaunchHelp}
            close={CloseFirstLaunchHelp}
            user={user.user}
          />

          {!isHideFooter || !hideFooter ? (
            <header
              className={classes.header}
              style={{
                backgroundColor: BGColor,
                paddingBottom: 10,
                zIndex: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  //marginTop: 10,
                  padding: 10,
                }}
                //  onTouchStart={handleTouchStart}
              >
                {!isColorPickerOpen ? (
                  <>
                    {/*}   <FontAwesomeIcon
                      icon={faPaintRoller}
                      size="lg"
                      onClick={() => setIsColorPickerOpen(true)}
                      style={{
                        paddingRight: 10,
                        paddingLeft: 10,
                        cursor: "pointer",
                      }}
                    />*/}

                    {location.pathname != "/" && !isHideFooter ? (
                      <div
                        style={{
                          position: "absolute",
                          //top: 20,
                          bottom: !hideFooter ? 60 : 0,
                          width: "120%",
                          marginLeft: 10,
                          // marginTop: 20,
                        }}
                      >
                        {" "}
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <button
                            style={{
                              fontSize: 20,

                              // backgroundColor: "transparent",
                              border: 0,
                              // borderColor: "black",
                              color: "white",
                              fontWeight: "bold",
                              fontFamily: "Ariel",
                              alignSelf: "center",
                              cursor: "pointer",
                              whiteSpace: "nowrap",
                              paddingLeft: 5,
                              paddingRight: 5,
                              paddingTop: 10,
                              paddingBottom: 10,
                              width: "100%",
                              marginRight: 8,
                              backgroundColor: "lime",
                              borderRadius: 0,
                            }}
                            onClick={backButtonAction}
                          >
                            <FontAwesomeIcon
                              //onClick={OpenMenu}
                              className={classes.blinkingButton}
                              style={{
                                border: 1,
                                // borderColor: "blue",
                                borderStyle: "solid",
                                color: "black",
                                borderRadius: 40,
                              }}
                              color="black"
                              icon={faArrowCircleLeft}
                              size="2x"
                            />{" "}
                            <b
                              style={{
                                position: "fixed",
                                bottom: !hideFooter ? 80 : 20,
                                left: 110,
                                right: 0,
                                fontSize: 20,
                                color: "black",
                              }}
                            >
                              {t("header-button-back")}
                            </b>
                          </button>
                          <span
                            onClick={toggleFooterBar}
                            style={{
                              position: "fixed",
                              bottom: !hideFooter ? 97 : 40,
                              left: 7,
                              right: 0,
                              fontSize: 12,
                              color: "black",
                              cursor: "pointer",
                            }}
                          >
                            Hide
                          </span>
                        </div>
                      </div>
                    ) : null}
                    <button
                      onClick={toggleFooter}
                      style={{
                        position: "fixed",
                        bottom: !hideFooter ? 60 : 0,
                        left: 0,
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 5,
                        paddingBottom: 5,
                        border: 0,
                        borderRadius: 0,

                        backgroundColor: BGColor,
                        borderTopRightRadius: 10,
                        cursor: "pointer",
                        //borderBottomRightRadius: 5,
                        color: "white",
                        //right: 0,
                        fontSize: 12,
                      }}
                    >
                      {!hideFooter
                        ? t("header-button-hide")
                        : t("introhelp-footer-title")}
                    </button>
                    {isHideFooter ? (
                      <button
                        onClick={toggleFooter}
                        style={{
                          position: "fixed",
                          bottom: !hideFooter ? 60 : 0,
                          left: 50,
                          paddingLeft: 10,
                          paddingRight: 10,
                          paddingTop: 5,
                          paddingBottom: 5,
                          border: 0,
                          borderRadius: 0,

                          backgroundColor: "lime",
                          color: "black",
                          borderTopRightRadius: 10,
                          borderTopLeftRadius: 10,
                          cursor: "pointer",
                          //borderBottomRightRadius: 5,
                          // color: "white",
                          //right: 0,
                          fontSize: 12,
                        }}
                      >
                        Show Back
                      </button>
                    ) : null}

                    {!hideFooter ? (
                      <>
                        <span
                          style={{
                            overflowX: "scroll",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            width: "100vw",
                          }}
                        >
                          <div style={{ paddingLeft: footerPaddingLeft }} />
                          <Link to="/habits">
                            <FontAwesomeIcon
                              icon={faRunning}
                              size="lg"
                              style={{
                                paddingRight: 10,
                                paddingLeft: 10,
                                cursor: "pointer",
                                color: "white",
                              }}
                              onClick={CloseFirstLaunchHelp}
                            />{" "}
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: 10,
                                marginTop: 0,
                                color: "white",
                              }}
                            >
                              {t("header-footer-habit")}
                            </div>
                          </Link>
                          <Link to="/journal">
                            <FontAwesomeIcon
                              icon={faBookOpen}
                              size="lg"
                              style={{
                                paddingRight: 10,
                                paddingLeft: 10,
                                cursor: "pointer",
                                color: "white",
                              }}
                              onClick={CloseFirstLaunchHelp}
                            />
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: 10,
                                marginTop: 0,
                                color: "white",
                              }}
                            >
                              {t("header-footer-journal")}
                            </div>
                          </Link>
                          <Link to="/todo">
                            <FontAwesomeIcon
                              icon={faList}
                              size="lg"
                              style={{
                                paddingRight: 10,
                                paddingLeft: 10,
                                cursor: "pointer",
                                color: "white",
                              }}
                              onClick={CloseFirstLaunchHelp}
                            />
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: 10,
                                marginTop: 0,
                                color: "white",
                              }}
                            >
                              {t("header-footer-tasks")}
                            </div>
                          </Link>
                          <Link to="/notes">
                            <FontAwesomeIcon
                              icon={faNoteSticky}
                              size="lg"
                              style={{
                                paddingRight: 10,
                                paddingLeft: 10,
                                cursor: "pointer",
                                color: "white",
                              }}
                              onClick={CloseFirstLaunchHelp}
                            />
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: 10,
                                marginTop: 0,
                                color: "white",
                              }}
                            >
                              {t("header-footer-notes")}
                            </div>
                          </Link>
                          <Link to="/recipes">
                            <FontAwesomeIcon
                              icon={faKitchenSet}
                              size="lg"
                              style={{
                                paddingRight: 10,
                                paddingLeft: 10,
                                cursor: "pointer",
                                color: "white",
                              }}
                              onClick={CloseFirstLaunchHelp}
                            />
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: 10,
                                marginTop: 0,
                                color: "white",
                              }}
                            >
                              {t("header-footer-meal")}
                            </div>
                          </Link>
                          <Link to="/shopping">
                            <FontAwesomeIcon
                              icon={faShoppingCart}
                              size="lg"
                              style={{
                                paddingRight: 10,
                                paddingLeft: 10,
                                cursor: "pointer",
                                color: "white",
                              }}
                              onClick={CloseFirstLaunchHelp}
                            />
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: 10,
                                marginTop: 0,
                                color: "white",
                              }}
                            >
                              {t("header-footer-grocery")}
                            </div>
                          </Link>
                          <Link to="/planner">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              size="lg"
                              style={{
                                paddingRight: 10,
                                paddingLeft: 10,
                                cursor: "pointer",
                                color: "white",
                              }}
                              onClick={CloseFirstLaunchHelp}
                            />
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: 10,
                                marginTop: 0,
                                color: "white",
                              }}
                            >
                              {t("header-footer-planner")}
                            </div>
                          </Link>
                          <div style={{ paddingRight: footerPaddingRight }} />
                        </span>
                      </>
                    ) : (
                      <div>
                        <p
                          style={{
                            position: "fixed",
                            bottom: 2,
                            right: 0,
                            left: 0,
                            zIndex: -1,
                            fontWeight: "bold",
                            textAlign: "center",
                            fontSize: 14,
                          }}
                        >
                          Made with Aloha in Hawai'i &#127796;
                        </p>
                      </div>
                    )}
                  </>
                ) : null}
              </div>
            </header>
          ) : (
            <div className={classes.footerButtonUnhide}>
              <button
                onClick={toggleFooterBar}
                style={{
                  position: "fixed",
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 5,

                  paddingBottom: 10,
                  border: 0,
                  borderRadius: 0,
                  marginBottom: 20,
                  zIndex: 20,
                  backgroundColor: BGColor,
                  borderTopRightRadius: 10,
                  cursor: "pointer",
                  //borderBottomRightRadius: 5,
                  color: "white",
                  //right: 0,
                  fontSize: 14,
                }}
              >
                {!isHideFooter
                  ? t("header-button-hide")
                  : t("header-button-show")}{" "}
              </button>
            </div>
          )}
        </>
      ) : null}
      <Modal
        isOpen={isMenuOpen}
        onRequestClose={CloseMenu}
        style={{ overlay: { zIndex: 100 } }}
      >
        <div
          style={{
            display: "flex",

            backgroundColor: "transparent",

            alignItems: "right",
            justifyContent: "right",
            cursor: "pointer",
            marginRight: 20,
          }}
        >
          <button
            onClick={CloseMenu}
            style={{ backgroundColor: "white", border: 0 }}
          >
            <FontAwesomeIcon icon={faClose} size="1x" />
          </button>
        </div>
        <div style={{ paddingBottom: 150, height: "90vh", overflow: "scroll" }}>
          <div
            style={{
              overflowY: "scroll",
              paddingBottom: 20,
              paddingLeft: 20,
            }}
          >
            <div
              style={{
                paddingBottom: 20,
                fontSize: 20,
                fontWeight: "bold",
                textAlign: "left",
                paddingLeft: 5,
              }}
            >
              <NavLink
                activeClassName={classes.active}
                to="/planner"
                title="planner"
                onClick={CloseMenu}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <img
                      style={{
                        height: "25px",
                        width: "25px",
                        cursor: "pointer",
                        paddingRight: 10,
                      }}
                      src={plannericon}
                      alt="Planner"
                    />{" "}
                  </div>
                  <div>{t("header-menu-planner")}</div>
                </div>
              </NavLink>
            </div>
            <div
              style={{
                paddingBottom: 20,
                fontSize: 20,
                fontWeight: "bold",
                textAlign: "left",
                paddingLeft: 5,
              }}
            >
              <NavLink
                activeClassName={classes.active}
                to="/habits"
                title="Habit Tracker"
                onClick={CloseMenu}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <img
                      style={{
                        height: "25px",
                        width: "25px",
                        cursor: "pointer",
                        paddingRight: 10,
                      }}
                      src={habiticon}
                      alt="Habit Tracker"
                    />{" "}
                  </div>
                  <div>{t("header-menu-habit")} </div>
                </div>
              </NavLink>
            </div>
            <div
              style={{
                paddingBottom: 20,
                fontSize: 20,
                fontWeight: "bold",
                textAlign: "left",
                paddingLeft: 5,
              }}
            >
              <NavLink
                activeClassName={classes.active}
                to="/journal"
                title="Daily Journal"
                onClick={CloseMenu}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <img
                      style={{
                        height: "25px",
                        width: "25px",
                        cursor: "pointer",
                        paddingRight: 10,
                      }}
                      src={journalicon}
                      alt="Daily Journal"
                    />{" "}
                  </div>
                  <div>{t("header-menu-journal")} </div>
                </div>
              </NavLink>
            </div>
            <div
              style={{
                paddingBottom: 20,
                fontSize: 20,
                fontWeight: "bold",
                textAlign: "left",
                paddingLeft: 5,
              }}
            >
              <NavLink
                activeClassName={classes.active}
                to="/shopping"
                title="Shopping List"
                onClick={CloseMenu}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <img
                      style={{
                        height: "25px",
                        width: "25px",
                        cursor: "pointer",
                        paddingRight: 10,
                      }}
                      src={shoppingicon}
                      alt="Shopping List"
                    />{" "}
                  </div>
                  <div>{t("header-menu-grocery")} </div>
                </div>
              </NavLink>
            </div>
            <div
              style={{
                paddingBottom: 20,
                fontSize: 20,
                fontWeight: "bold",
                textAlign: "left",
                paddingLeft: 5,
              }}
            >
              <NavLink
                activeClassName={classes.active}
                to="/todo"
                title="My Lists"
                onClick={CloseMenu}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <img
                      style={{
                        height: "25px",
                        width: "25px",
                        cursor: "pointer",
                        paddingRight: 10,
                      }}
                      src={mylisticon}
                      alt="Tasks/To-Dos"
                    />{" "}
                  </div>
                  <div>{t("header-menu-tasks")}</div>
                </div>
              </NavLink>
            </div>
            <div
              style={{
                paddingBottom: 20,
                fontSize: 20,
                fontWeight: "bold",
                textAlign: "left",
                paddingLeft: 5,
              }}
            >
              <NavLink
                activeClassName={classes.active}
                to="/notes"
                title="Notepad"
                onClick={CloseMenu}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <img
                      style={{
                        height: "25px",
                        width: "25px",
                        cursor: "pointer",
                        paddingRight: 10,
                      }}
                      src={notesicon}
                      alt="Notepad"
                    />{" "}
                  </div>
                  <div>{t("header-menu-notes")}</div>
                </div>
              </NavLink>
            </div>
            <div
              style={{
                paddingBottom: 20,
                fontSize: 20,
                fontWeight: "bold",
                textAlign: "left",
                paddingLeft: 5,
              }}
            >
              <NavLink
                activeClassName={classes.active}
                to="/sortablelists"
                title="Sortable Lists"
                onClick={CloseMenu}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <img
                      style={{
                        height: "25px",
                        width: "25px",
                        cursor: "pointer",

                        paddingRight: 10,
                      }}
                      src={sortablelistgif}
                      alt="Sortable List"
                    />{" "}
                  </div>
                  <div>{t("header-menu-sortableList")}</div>
                </div>
              </NavLink>
            </div>
            <div
              style={{
                paddingBottom: 20,
                fontSize: 20,
                fontWeight: "bold",
                textAlign: "left",
                paddingLeft: 5,
              }}
            >
              <NavLink
                activeClassName={classes.active}
                to="/scorehome"
                title="Game Scorepad"
                onClick={CloseMenu}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <img
                      style={{
                        height: "25px",
                        width: "25px",
                        cursor: "pointer",
                        paddingRight: 10,
                      }}
                      src={scoreIcon}
                      alt="Game Scorepad"
                    />{" "}
                  </div>
                  <div>{t("header-menu-score")} </div>
                </div>
              </NavLink>
            </div>
            <div
              style={{
                paddingBottom: 20,
                fontSize: 20,
                fontWeight: "bold",
                textAlign: "left",
                paddingLeft: 5,
              }}
            >
              <NavLink
                activeClassName={classes.active}
                to="/recipes"
                title="Meal Planner"
                onClick={CloseMenu}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <img
                      style={{
                        height: "25px",
                        width: "25px",
                        cursor: "pointer",
                        paddingRight: 10,
                      }}
                      src={recipeicon}
                      alt="Meal Planner"
                    />{" "}
                  </div>
                  <div>{t("header-menu-meal")} </div>
                </div>
              </NavLink>
            </div>

            <div
              style={{
                paddingBottom: 20,
                fontSize: 20,
                fontWeight: "bold",
                textAlign: "left",
                paddingLeft: 5,
              }}
            >
              <NavLink
                activeClassName={classes.active}
                to="/settings"
                title="Profile/Settings"
                onClick={CloseMenu}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <img
                      style={{
                        height: "25px",
                        width: "25px",
                        cursor: "pointer",
                        paddingRight: 10,
                      }}
                      src={settingsicon}
                      alt="Profile/Settings"
                    />{" "}
                  </div>
                  <div>{t("header-menu-settings")}</div>
                </div>
              </NavLink>
            </div>
            <div
              style={{
                paddingBottom: 20,
                fontSize: 20,
                fontWeight: "bold",
                textAlign: "left",
                paddingLeft: 5,
              }}
            >
              <NavLink
                activeClassName={classes.active}
                to="/contact"
                title="Help/Legal"
                onClick={CloseMenu}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <img
                      style={{
                        height: "25px",
                        width: "25px",
                        cursor: "pointer",
                        paddingRight: 10,
                      }}
                      src={helpicon}
                      alt="Help/Legal"
                    />{" "}
                  </div>
                  <div> {t("header-menu-help")}</div>
                </div>
              </NavLink>
            </div>

            <hr />
            <p style={{ fontSize: 12, textAlign: "center" }}>
              v.2.4.02 d:2023-11-17
            </p>
          </div>
        </div>
      </Modal>
      <ConfirmationModal
        isOpen={confirmModalOpen}
        text="Are you sure you want to hide the footer menu?  You can unhide it by selecting the F button located in the upper right corner of the header."
        onClickCancel={() => setConfirmModalOpen(false)}
        onClickConfirm={() => {
          setConfirmModalOpen(false);
          setIsHideFooter(true);
        }}
      />
      <ConfirmationModal
        isOpen={confirmModalOfflineOpen}
        text="You're currently in Offline mode. Would you like to attempt reloading all cache and reconnecting? If yes, ensure your device has an internet connection."
        onClickCancel={() => setConfirmModalOfflineOpen(false)}
        onClickConfirm={() => {
          reloadData();
          setConfirmModalOfflineOpen(false);
        }}
      />
      {/*}   {isBuyNow ? (
        <BuyNowModal
          isBuyNow={isBuyNow}
          isOnline={isOnline}
          isExpired={isExpired}
          close={() => setIsBuyNow(false)}
          user={user.user}
        />
   ) : null} */}
    </div>
  );
};

import { useEffect, useState } from "react";
import { useRecipeSubscription } from "Recipes";
import { useItemSubscription } from "Lists";
//import { Recipes } from "Recipes";
import { RecipeModal } from "Recipes/RecipeModal";
//import { WeeklyRecipes } from "Recipes/WeeklyRecipes";
//import { RecipeList } from "Recipes/RecipeList";
import { useHistory } from "react-router-dom";
//import { ScheduleMeal } from "Recipes/MealPlanner/ScheduleMeal";
import { MealPlanner } from "Recipes/MealPlanner/MealPlanner";
import { useTranslation } from "react-i18next";

export const RecipeWidget = ({ mealPlannerData }) => {
  const { recipes } = useRecipeSubscription();
  const { addedItems, allItems } = useItemSubscription();
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const [selectedRecipe, setSelectedRecipe] = useState({
    name: "",
    id: "",
    directions: "",
    days: [],
  });

  function handleCloseRecipeModal() {
    setModalOpen(false);
  }

  function handleClickRecipe(recipe) {
    history.push(`recipes/${recipe.id}`);
  }

  const routeChange = (path) => {
    history.push(path);
  };

  useEffect(() => {
    return () => {};
    //@ts-ignore
  }, []);

  return (
    <div>
      {" "}
      <RecipeModal
        isOpen={modalOpen}
        recipe={selectedRecipe}
        addedItems={addedItems}
        close={handleCloseRecipeModal}
      />
      {/*}  <WeeklyRecipes recipes={recipes} onClickRecipe={handleClickRecipe} />*/}
      <MealPlanner data={recipes} mealPlannerData={mealPlannerData} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {" "}
        <button
          style={{
            fontSize: 14,
            fontFamily: "inherit",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
            width: "99%",
            color: "white",
            backgroundColor: "#1e79c3",
            cursor: "pointer",
            borderRadius: 40,
          }}
          onClick={() => routeChange("/recipes")}
        >
          {t("dashboard-recipe-viewadd-button")}
        </button>
      </div>
      {/*} <RecipeList recipes={recipes} onClickRecipe={handleClickRecipe} />*/}
    </div>
  );
};

import { useEffect, useState } from "react";

import { Modal } from "Common";
import { ItemModel } from "Lists";
import { RecipeModel } from "Recipes";

import { ReadRecipe } from "./Read";
import { WriteRecipe } from "./Write";

enum Mode {
  "Read",
  "Write",
}

interface RecipeModalProps {
  isOpen: boolean;
  recipe: RecipeModel;
  addedItems: ItemModel[];
  close: () => void;
}

export const RecipeModal = (props: RecipeModalProps) => {
  const { isOpen, recipe: initialRecipe, addedItems, close } = props;
  const [mode, setMode] = useState(Mode.Read);
  const [recipe, setRecipe] = useState(initialRecipe);

  useEffect(() => {
    isOpen && (recipe.id ? setMode(Mode.Read) : setMode(Mode.Write));
  }, [isOpen, recipe]);

  useEffect(() => {
    setRecipe(initialRecipe);
  }, [initialRecipe]);

  function handleSave(recipe: RecipeModel) {
    setRecipe(recipe);
    setMode(Mode.Read);
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      style={{ overlay: { zIndex: 100 } }}
    >
      <div style={{ paddingBottom: 100, paddingTop: 0 }}>
        <Modal.Body>
          <div style={{ padding: 20 }}>
            <div
              style={{
                paddingLeft: 30,
                paddingRight: 30,
                paddingBottom: 10,
                border: 0,
                //borderColor: "grey",
                borderRadius: 10,
                backgroundColor: "snow",
                fontWeight: "bold",
                color: "grey",
                // boxShadow: "2px 3px 3px grey, 0 0 5px #FCFBF4 inset",
              }}
            >
              {mode === Mode.Read ? (
                <ReadRecipe
                  recipe={recipe}
                  addedItems={addedItems}
                  close={close}
                  edit={() => setMode(Mode.Write)}
                />
              ) : (
                <WriteRecipe
                  recipe={recipe}
                  close={close}
                  onSave={handleSave}
                />
              )}
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

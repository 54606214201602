import { useEffect, useState, useRef, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faStar,
  faClose,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import cx from "classnames/bind";
import { CheckBox, HabitName } from "Habits/components/UserTodayHabit/style";
import moment from "moment";
import { doc, updateDoc } from "firebase/firestore";
import { Button, Modal } from "Common";
import { useItemsToDo } from "../ListToDo/useItemsToDo";
import { useHistory } from "react-router-dom";

import classes from "././List/ItemModal/ItemModal.module.css";
import { useUserContext } from "Authentication";
import TextareaAutosize from "react-textarea-autosize";
import { ConfirmationModal } from "Common";
import { CustomNotificationMyList } from "Reminders/CustomerNotificationMyList";
import ReactCanvasConfetti from "react-canvas-confetti";
import { useTranslation } from "react-i18next";

const classnames = cx.bind(classes);

export const ListToDoSingleEvent = ({ item }) => {
  const [name, setName] = useState(item?.name);
  const [notes, setNotes] = useState(item?.notes);
  const [isFavorite, setIsFavorite] = useState(item?.isFavorite);
  const [flagged, setFlagged] = useState(item?.flagged);
  const [addedDate, setAddedDate] = useState(item?.addedDate);
  const [addedBy, setAddedBy] = useState(item?.addedBy);
  const [lastCompleted, setLastCompleted] = useState(item?.lastCompleted);
  const [isDone, setIsDone] = useState(false);
  const { deleteItem, removeItem, setItemFlag, setItemFavorite, updateItem } =
    useItemsToDo();
  const user = useUserContext();
  const [hideMoreInfo, setHideMoreInfo] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isSchedulingReminder, setIsSchedulingReminder] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const { getPrivateToDoItems } = useUserContext();
  // var name = item?.name
  const history = useHistory();
  const { t } = useTranslation();

  const CloseMenu = () => {
    setIsSchedulingReminder(false);
  };

  const formatDateWithTime = (date) => {
    // console.log("what is the date", date);
    if (date != undefined) {
      if (date.seconds != undefined) {
        // Check if date is an epoch timestamp (10 digits)
        date = new Date(date.seconds * 1000); // Convert to milliseconds
      }

      return moment(date).format("MMMM DD, YYYY @ hh:mm A");
    } else {
      return "No record";
    }
  };

  const maxLength = 50; // Maximum length of text to display
  const truncatedText = (text) => {
    const newText =
      text.length > maxLength
        ? text.substring(0, maxLength) + "..." // Truncate text to maximum length
        : text;
    return newText;
  };

  //confetti

  const canvasStyles = {
    position: "fixed",
    pointerEvents: "none",
    width: "100%",
    height: "100%",
    top: 10,
    left: 0,
  };

  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  const FireIfCompleted = () => {
    fire();
  };

  useEffect(() => {
    // Prevents old values being displayed when modal fades out.

    setName(item?.name);
    setNotes(item?.notes);
    setAddedDate(item?.addedDate);
    setAddedBy(item?.addedBy);
    setFlagged(item?.flagged);
    setIsDone(item?.completed);
    setIsFavorite(item?.isFavorite);
  }, [item]);

  async function handleSubmit(event) {
    event.preventDefault();
    if (notes !== item?.notes && item?.id) {
      await updateItem(item.id, {
        notes,
      });
    }

    if (name !== item?.name && item?.id) {
      await updateItem(item.id, {
        name,
        addedBy: user.user.name,
      });
    }
    history.push(`./`);
  }

  async function handleBackToDo() {
    history.push(`./`);
  }

  async function handleRemove() {
    item?.id && (await removeItem(item.id));
    history.push(`./`);
  }

  async function handleDelete() {
    item?.id && (await deleteItem(item?.id));
    history.push(`./`);
  }

  const handleChange = async () => {
    // ToDoItemsUpdate(item.id, !isDone, Date(), user.user.name);
    if (!isDone) {
      FireIfCompleted();
    }
    setIsDone(!isDone);
    /*toast("Item Status Updated", {
      duration: 2000,
      position: "top-center",
      style: {
        backgroundColor: "lightgreen",
        border: "1px solid #713200",
        fontSize: "16px",
        padding: "10px",
        color: "black",
      },
    });*/
    const itemsRef = await getPrivateToDoItems();
    const itemRef = doc(itemsRef, item.id);
    await updateDoc(itemRef, {
      completed: !isDone,
      lastCompleted: Date(),
      completedBy: user.user.name,
    });
  };

  const DeleteItemConfirmation = () => {
    handleDelete();
    setConfirmModalOpen(false);
  };

  const updateNameValue = (e) => {
    setName(e);
    setIsUpdated(true);
  };

  const updateNotesValue = (e) => {
    setNotes(e);
    setIsUpdated(true);
  };

  return (
    <div style={{ paddingBottom: 100 }}>
      <div style={{ padding: 10 }}>
        <CheckBox
          style={{ cursor: "pointer" }}
          onClick={() => handleChange()}
          done={isDone}
        >
          <div
            stlye={{
              disply: "flex",
              flexDirection: "column",
            }}
          >
            <HabitName
              style={{
                fontWeight: "bold",
                fontSize: 18,
                height: 75,
                width: "100%",
                justifyContent: "center",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              <span
                style={{
                  textAlign: "center",
                }}
              >
                {" "}
                {isDone
                  ? t("habits-userhabits-status-completed")
                  : t("habits-userhabits-status-pending")}
              </span>
            </HabitName>
          </div>
        </CheckBox>
        <div
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            paddingBottom: 10,
            border: 1,
            borderColor: "grey",
            borderRadius: 10,
            backgroundColor: "snow",
            opacity: 0.7,
            color: "grey",
            boxShadow: "2px 3px 3px grey, 0 0 5px #FCFBF4 inset",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "right",
              justifyContent: "right",
              margin: 10,
            }}
          >
            <FontAwesomeIcon
              style={{
                // marginBottom: 1,
                paddingTop: 20,
                paddingBottom: 10,
                marginRight: -20,

                color: "darkblue",
                //  paddingRight: 5,
                cursor: "pointer",
                alignSelf: "center",
              }}
              icon={faBell}
              size="xl"
              onClick={() => setIsSchedulingReminder(true)}
            />
          </div>

          <form onSubmit={handleSubmit}>
            <label style={{ fontSize: 16 }}>Item Name</label>
            <TextareaAutosize
              value={name}
              onChange={(e) => updateNameValue(e.target.value)}
              style={{
                width: "85%",
                fontSize: 22,
                //    color: "navy",
                backgroundColor: "transparent",
                padding: 10,
                border: 0,
              }}
            />
            <label style={{ paddingTop: 10, paddingBottom: 5, fontSize: 16 }}>
              Notes
            </label>
            <TextareaAutosize
              value={notes}
              // rows={3}
              placeholder="Enter any notes here ..."
              onChange={(e) => updateNotesValue(e.target.value)}
              style={{
                width: "85%",
                fontSize: 20,
                padding: 10,
                //   color: "navy",
                backgroundColor: "transparent",
              }}
            />
            {isUpdated ? (
              <p
                className="blinkingButton"
                style={{
                  textAlign: "center",
                  // color: "white",
                  color: "firebrick",
                  fontWeight: "bold",
                  fontSize: 16,
                  marginTop: 20,
                }}
              >
                Recent edits have been detected. Please save your changes before
                closing to prevent any loss.
              </p>
            ) : null}
            <p style={{ fontWeight: 600, fontSize: 16 }}>Due Date:</p>{" "}
            <span style={{ padding: 10, color: "black" }}>
              {item?.dueDate ? formatDateWithTime(item?.dueDate) : "None"}
            </span>
            <p style={{ fontWeight: 600, fontSize: 16 }}>Date Added:</p>{" "}
            <span style={{ padding: 10, color: "black" }}>
              {formatDateWithTime(item?.addedDate)}
            </span>
            <p style={{ fontWeight: 600, fontSize: 16 }}>
              Last Marked Completed:
            </p>{" "}
            <span style={{ padding: 10, color: "black" }}>
              {formatDateWithTime(item?.lastCompleted)}
            </span>
            <div
              className={classes.modalActions}
              style={{ paddingTop: 10, disply: "flex", flexWrap: "wrap" }}
            >
              {isUpdated ? (
                <button
                  type="submit"
                  style={{
                    fontFamily: "inherit",
                    fontSize: "inherit",
                    padding: 10,
                    width: 125,
                    border: 0,
                    marginTop: 30,
                    cursor: "pointer",
                    backgroundColor: " purple",
                  }}
                >
                  {t("common-button-save")}
                </button>
              ) : (
                <span />
              )}
            </div>
          </form>
          <div
            className={classes.modalActions}
            style={{ paddingTop: 10, disply: "flex", flexWrap: "wrap" }}
          >
            <button
              type="button"
              onClick={handleBackToDo}
              className={classes.button}
              style={{
                fontFamily: "inherit",
                fontSize: "inherit",
                padding: 10,
                width: 125,
                border: 0,
                marginTop: 10,
                cursor: "pointer",
              }}
            >
              See All Tasks
            </button>
            <button
              type="button"
              onClick={handleRemove}
              className={classes.button}
              style={{
                fontFamily: "inherit",
                fontSize: "inherit",
                padding: 10,
                width: 125,
                border: 0,
                marginTop: 10,
                cursor: "pointer",
              }}
            >
              Clear Item
            </button>
            <button
              type="button"
              onClick={() => setIsSchedulingReminder(true)}
              className={classes.button}
              style={{
                fontFamily: "inherit",
                fontSize: "inherit",
                padding: 10,
                width: 125,
                border: 0,
                marginTop: 10,
                cursor: "pointer",
              }}
            >
              Set Due Date
            </button>
          </div>
          <div
            style={{
              paddingTop: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              style={{
                fontFamily: "inherit",
                fontSize: 16,
                color: "blue",
                backgroundColor: "transparent",
                borderStyle: "none",
                textAlign: "center",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              type="button"
              onClick={() => setConfirmModalOpen(true)}
            >
              {t("grocery-list-delete-permanently")}
            </button>
          </div>
          <div style={{ paddingBottom: 150 }} />
        </div>
      </div>

      <ConfirmationModal
        isOpen={confirmModalOpen}
        text="Are you sure? This action can not be undone if accepted."
        onClickCancel={() => setConfirmModalOpen(false)}
        onClickConfirm={DeleteItemConfirmation}
      />
      <Modal isOpen={isSchedulingReminder} style={{ overlay: { zIndex: 100 } }}>
        <div style={{ paddingTop: 5, paddingBottom: 20, paddingLeft: 5 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
              alignItems: "right",
              paddingLeft: 20,
              marginTop: -40,
              marginRight: 10,
              paddingBottom: 10,
              paddingTop: 30,
            }}
          >
            <button
              onClick={CloseMenu}
              style={{
                backgroundColor: "transparent",
                border: 0,
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon icon={faClose} size="1x" />
            </button>
          </div>

          <CustomNotificationMyList
            title={item.name}
            item={item}
            CloseMenu={CloseMenu}
            setDate={item.dueDate}
          />
          <hr />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              style={{
                fontSize: "inherit",
                fontFamily: "inherit",
                color: "white",
                backgroundColor: "#1e79c3",
                borderColor: "white",
                padding: 10,
                borderRadius: 10,
                paddingRight: 30,
                paddingLeft: 30,
                width: 200,
                marginTop: 15,
              }}
              onClick={CloseMenu}
            >
              Close Window
            </button>
          </div>
          <div style={{ paddingBottom: 150 }} />
        </div>
      </Modal>

      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
    </div>
  );
};

import { Link, useHistory } from "react-router-dom";
import classes from "../RegisteredSplash.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState, memo } from "react";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import moment from "moment";
import { useUserSubscription } from "Authentication/useUserSubscription";
import { useUserContext } from "Authentication";
import habiticon from "../../../../src/Assets/lifestyle.png";
import { HabitTodayPageWidget } from "Habits/components/TodayPage/HabitTodayPageWidget";
//import { useHabitSubscription } from "Habits/useHabitSubscription";
import dayjs from "dayjs";
import { HabitModel } from "Habits/habitmodel";
import { User } from "Authentication/models/user";
import { EventModel } from "Journal/models/events";
import { useTranslation } from "react-i18next";

interface HabitsCompletedProps {
  habits: HabitModel[];
  widgetSize: number;
  isExpired: boolean;
  isSortMode: boolean;
  user: User;
}

export const HabitsCompleted = memo((props: HabitsCompletedProps) => {
  const { habits, widgetSize, isExpired, isSortMode, user } = props;
  const transferHabits = habits;
  const userProfile = useUserSubscription();
  const [createHabit, setCreateHabit] = useState(false);
  const history = useHistory();
  // const user = useUserContext();
  const [isCurrent, setIsCurrent] = useState(false);
  //const { habits } = useHabitSubscription();
  const { t } = useTranslation();

  const routeChange = (path) => {
    history.push(path);
  };

  const CloseNewHabit = () => {
    setCreateHabit(false);
  };

  const today = moment(new Date()).format("dddd");

  //filter the habits data for today only
  const filterHabitsByDay = habits.filter((item) =>
    //@ts-ignore
    item.days.includes(dayjs().day())
  );
  //Show habits for today
  var completedHabits = habits.filter(
    (i) => i.dateLastUpdated == moment(new Date()).format("YYYYMMDD") && i.done
  ).length;

  //calculate the percentage done for the day
  var percentageCalc =
    completedHabits >= 1
      ? //@ts-ignore
        parseInt((completedHabits / filterHabitsByDay.length) * 100)
      : 0;

  const checkExpiration = () => {
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    const dateEpoch = date.getTime();
  };

  useEffect(() => {
    //window.location.reload();
    checkExpiration();
    //@ts-ignore

    return () => {};
    //@ts-ignore
  }, []);

  return (
    <section className={classes.section}>
      <div>
        <div>
          {!isSortMode ? (
            <div
              onClick={() => routeChange("/habits")}
              style={{
                color: "white",
                backgroundColor: "black ",
                cursor: "pointer",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              }}
              className={classes.openSectionButton}
            >
              {t("dashboard-fullscreen")}
            </div>
          ) : null}
          <div
            onClick={() => routeChange("/habits")}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                paddingBottom: 5,
                //  display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ flexDirection: "column" }}>
                <h3
                  style={{
                    textAlign: "center",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  {t("header-menu-habit")}
                </h3>
              </div>
            </div>
          </div>
          {!isSortMode ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: -20,
                paddingBottom: 10,
              }}
            >
              <button
                //  className={classes.blinkingButton}
                onClick={() => setCreateHabit(true)}
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                  borderRadius: 40,
                  border: 0,
                  color: "white",
                  backgroundColor: "purple",
                  cursor: "pointer",
                  fontFamily: "inherit",
                  fontSize: 14,
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                {t("common-button-addnew")}
              </button>
            </div>
          ) : null}
        </div>
        <div
          style={{
            height: widgetSize - 20,
            margin: 0,
            overflowY: "scroll",
          }}
        >
          <div
            onClick={() => routeChange("/habits")}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: "inherit",
                fontFamily: "inherit",
                textAlign: "center",
                fontWeight: "bold",
                marginTop: 0,
              }}
            >
              {today}{" "}
            </div>

            <div
              style={{
                paddingTop: 5,
                paddingLeft: 0,
                width: 150,
                height: 150,
              }}
            >
              <CircularProgressbar
                value={percentageCalc}
                text={`${percentageCalc}%`}
                strokeWidth={20}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 0.25,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",

                  // Text size
                  textSize: "16px",

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 2,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  pathColor: `rgba(186, 85, 211, 1)`,
                  textColor: "black",
                  // trailColor: "grey",
                  //backgroundColor: "#3e98c7",
                })}
              >
                {" "}
              </CircularProgressbar>
            </div>
          </div>
          <HabitTodayPageWidget
            habits={transferHabits}
            createHabit={createHabit}
            CloseNewHabit={CloseNewHabit}
          />
          <div>
            {/*  <b style={{ color: "purple", paddingLeft: 5 }}>
                    {habitsCompleted}
                </b> */}
          </div>
        </div>
      </div>
    </section>
  );
});

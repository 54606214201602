import { ReactNode } from "react";
import cx from "classnames/bind";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CategoryButton } from "../CategoryButton";

import classes from "./Item.module.css";

const classnames = cx.bind(classes);

interface ItemProps {
  buttonColour: string;
  categoryButton: boolean;
  onClickCategory?: () => void;
  onClickFavorite?: () => void;
  children?: ReactNode;
  contentClassName?: string;
}

export const Item = (props: ItemProps) => {
  const {
    buttonColour,
    onClickCategory,
    onClickFavorite,
    children,
    contentClassName,
    categoryButton,
  } = props;

  return (
    <div className={classes.container}>
      <div className={classnames(classes.content, contentClassName)}>
        {children}
      </div>

      {/*}
      {categoryButton ? (
        <div style={{ marginTop: -10 }}>
          <span onClick={onClickFavorite} style={{paddingRight: 15}}>
      <FontAwesomeIcon style={{marginBottom: -7}} icon={faStar} size="2x" />
      </span>
          <CategoryButton
            buttonColour={buttonColour}
            onClick={onClickCategory}
          />
        </div>
      ) : null} */}
    </div>
  );
};

Item.defaultProps = {
  buttonColour: "var(--colour-background-tertiary)",
};

//import firebase from "firebase/app";
import firebase from "firebase/compat/app";
import moment from "moment";
import { singular } from "pluralize";

import { useUserContext } from "Authentication";
import { DatabaseItem } from "./models";
//import { useItems } from "Lists";
import { db } from "Config";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  updateDoc,
  limit,
  deleteDoc,
  Timestamp,
  query,
  where,
  setDoc,
  writeBatch,
} from "firebase/firestore";
import { CategoryModel } from "Lists";
import { useTranslation } from "react-i18next";
//import { Item } from "./List/Common/Item";
//import { userInfo } from "os";

const defaultCategoriesToDo: CategoryModel[] = [
  { id: "td100100", name: "To Do", colour: `#00ff7f`, order: 10 },
  { id: "td100200", name: "Work", colour: `#87ceeb`, order: 10 },
  { id: "td100300", name: "Personal", colour: `#20b2aa`, order: 10 },
];

export function PreloadNewTodo() {
  const {
    getPrivateToDoItems,
    addNewEventModal,
    addNewHabit,
    addNewNote,
    addNewPlannerEvent,
    addToDoCategory,
  } = useUserContext();
  const collection = getPrivateToDoItems();
  //const { AddCommonCategories, addCommonGroceryItems } = useItems();
  const user = useUserContext();
  const { t } = useTranslation();
  async function addItem(value: string, category?: string) {
    const name = value.trim();
    const lowerName = singular(name.toLowerCase());

    const q = query(collection, where("lowerName", "==", lowerName));
    const existing = await getDocs(q);

    const fieldsToUpdate = {
      name,
      added: true,
      completed: false,
      addedDate: Timestamp.fromDate(new Date()),
      addedBy: user.user.name,
      category:
        category === undefined
          ? existing.docs?.[0]?.data().category || ""
          : category,
    };

    if (existing.empty) {
      await addDoc(collection, { ...fieldsToUpdate, lowerName, count: 1 });
    } else {
      const docId = existing.docs[0].id;
      const currentCount = existing.docs[0].data().count ?? 0;
      const docRef = doc(collection, docId);
      await updateDoc(docRef, {
        ...fieldsToUpdate,
        count: currentCount + 1,
      });
    }
  }

  function addToDoCategoryDB() {
    addToDoCategory({ name: "Do", id: 7, colour: "lightgreen" });
    addToDoCategory({ name: "Schedule", id: 8, colour: "lightsalmon" });
    addToDoCategory({ name: "Delegate", id: 9, colour: "lightskyblue" });

    // return GroupCategory.add(newCategory);
  }

  const LoadGroceryItems = async () => {
    // AddCommonCategories();
    // addCommonGroceryItems();
  };

  const PlannerExample = () => {
    const durationTimeInMillis = 60 * 60 * 1000;
    const currentDate = new Date();

    // Round up to the nearest 15 minutes
    var roundedMinutes = Math.ceil(currentDate.getMinutes() / 15) * 15;

    // Set the rounded minutes and clear seconds and milliseconds
    currentDate.setMinutes(roundedMinutes, 0, 0);

    var valueName = t("example-planner-review");
    var valueNote = t("example-planner-review");
    var dueDate = currentDate;
    var durationDate = new Date(dueDate.getTime() + durationTimeInMillis);
    var valueTimeDue = "17:00";
    var isTask = false;
    var isRepeats = false;
    var repeatDays = [1, 2, 3];
    var selectedDurationdOption = 60;
    var repeatValue = "none";
    var repeatMonthlyValue = "date";
    var repeatWeeklyIntervalValue = 1;
    var eventBGColor = "lavender";

    addNewPlannerEvent(
      valueName,
      valueNote,
      dueDate,
      durationDate,
      valueTimeDue,
      isTask,
      isRepeats,
      repeatDays,
      selectedDurationdOption,
      repeatValue,
      repeatMonthlyValue,
      repeatWeeklyIntervalValue,
      eventBGColor
    );

    // durationTimeInMillis = 60 * 60 * 1000;
    // currentDate = new Date();

    // Round up to the nearest 15 minutes
    roundedMinutes = Math.ceil(currentDate.getMinutes() / 60) * 60;

    // Set the rounded minutes and clear seconds and milliseconds
    currentDate.setMinutes(roundedMinutes, 0, 0);

    valueName = t("example-planner-setup");
    valueNote = t("example-planner-setup");
    dueDate = currentDate;
    durationDate = new Date(dueDate.getTime() + durationTimeInMillis);
    valueTimeDue = "17:00";
    isTask = false;
    isRepeats = false;
    repeatDays = [1, 2, 3];
    selectedDurationdOption = 60;
    repeatValue = "none";
    repeatMonthlyValue = "date";
    repeatWeeklyIntervalValue = 1;
    eventBGColor = "lightgreen";

    addNewPlannerEvent(
      valueName,
      valueNote,
      dueDate,
      durationDate,
      valueTimeDue,
      isTask,
      isRepeats,
      repeatDays,
      selectedDurationdOption,
      repeatValue,
      repeatMonthlyValue,
      repeatWeeklyIntervalValue,
      eventBGColor
    );
  };

  const NoteExample = () => {
    var text = t("example-planner-review-desc");
    var tags = [{ text: "example", color: "indigo" }];
    var color = "lavender";

    addNewNote(text, tags, color);
  };

  const HabitExample = () => {
    var name = t("example-habit-journal");

    var days = [0, 1, 2, 3, 4, 5, 6];
    var isTrackingReps = false;
    var RepsLabel = "";
    var totalReps = 0;
    var date = moment(new Date()).format("MM/DD/YYYY");
    var time = "20:30";
    var duration = "21:30";
    var durationMinutes = 60;
    var isPlanner = true;

    const todayDate = new Date();
    const add60Days = new Date(todayDate);
    add60Days.setDate(todayDate.getDate() + 60);

    var lastDay = add60Days;

    addNewHabit(
      name,
      days,
      isTrackingReps,
      RepsLabel,
      date,
      totalReps,
      time,
      duration,
      durationMinutes,
      isPlanner,
      lastDay
    );
  };

  const JournalExample = () => {
    var title = t("example-journal");
    var description = "";
    var startTime = new Date();
    var endTime = new Date(startTime.getTime() + 864000);
    var isAllDay = false;
    var imageURL = "";
    var imageCaption = "";
    var color = "lavender";

    addNewEventModal(
      title,
      description,
      startTime,
      endTime,
      isAllDay,
      imageURL,
      imageCaption,
      color
    );
  };

  async function addItemPlanner(
    value: string,
    dueDate?: Date,
    dueTime?: string,
    endDate?: Date
  ) {
    const name = value.trim();

    const fieldsToUpdate = {
      name,
      added: true,
      completed: false,
      lowerName: name.toLowerCase(),
      //@ts-ignore
      addedDate: serverTimestamp(),
      addedBy: user.user.name,
      category: "td100100",
      dueDate: Timestamp.fromDate(new Date()),
      dueTime: dueTime,
      startDate: dueDate,
      endDate: endDate,
    };

    await addDoc(collection, { ...fieldsToUpdate });
  }

  function deleteItem(id: string) {
    return deleteDoc(doc(collection, id));
  }

  function removeItem(id: string) {
    const deletionFields = {
      // Define your deletion fields as needed
      completed: false,
      added: false,
      lastCompleted: new Date().toString(),
      //addedDate: firebase.firestore.FieldValue.delete(),
      //notes: firebase.firestore.FieldValue.delete(),
      flagged: false,
    };
    return updateDoc(doc(collection, id), deletionFields);
  }

  function updateItem(id: string, item: Partial<DatabaseItem>) {
    return updateDoc(doc(collection, id), item);
  }

  const createSampleCategories = async () => {
    const batchCategoryToDo = writeBatch(db); // Use the imported batch function

    defaultCategoriesToDo.forEach(async (category) => {
      const categoryRef = doc(collection, category.id);

      try {
        await setDoc(categoryRef, category); // Use the imported setDoc function
        // console.log("category", category.id, category.name);
      } catch (error) {
        // Handle errors if needed
        console.error("Error setting document:", error);
      }
    });
    // Wait for all setDoc operations to complete before committing the batch
    await Promise.all(defaultCategoriesToDo.map(() => {}));

    await batchCategoryToDo.commit();
  };

  function batchRemoveItems(ids: string[]) {
    const deletionFields = {
      // Define your deletion fields as needed
      completed: false,
      added: false,
      lastCompleted: new Date().toString(),
      //addedDate: firebase.firestore.FieldValue.delete(),
      //notes: firebase.firestore.FieldValue.delete(),
      flagged: false,
    };
    const batch = writeBatch(db);

    for (const id of ids) {
      const ref = doc(collection, id);
      batch.update(ref, deletionFields);
    }

    return batch.commit();
  }

  function setItemFlag(id: string, flagged: boolean) {
    return updateDoc(doc(collection, id), {
      flagged,
    });
  }

  function setSortIndex(id: string, sortIndex: number) {
    return updateDoc(doc(collection, id), {
      sortIndex,
    });
  }

  function setItemFavorite(id: string, isFavorite: boolean) {
    return updateDoc(doc(collection, id), {
      isFavorite,
    });
  }

  return {
    addItem,
    JournalExample,
    createSampleCategories,
    HabitExample,
    NoteExample,
    PlannerExample,
    LoadGroceryItems,
    addToDoCategoryDB,
  };
}

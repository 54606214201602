import React from "react";
import { RemoteButton } from "./RemoteButton";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const RemoteControl = () => {
  const { t } = useTranslation();
  return (
    <div className="remote--control-container">
      <div className="metro-grid">
        <RemoteButton
          label={t("header-menu-tasks")}
          desc="Create checklists for anything"
          color="blue"
          link="todo"
        />{" "}
        {/* Bright Blue */}
        <RemoteButton
          label={t("header-menu-notes")}
          desc="Save ideas, links, notes, ..."
          color="blue"
          link="notes"
        />{" "}
        {/* Bright Pink */}
        <RemoteButton
          label={t("header-menu-grocery")}
          desc="Sharable grocery list that saves past items"
          color="blue"
          link="shopping"
        />{" "}
        {/* Purple */}
        <RemoteButton
          label={t("header-menu-journal")}
          desc="Personal journal for reflection"
          color="blue"
          link="journal"
        />{" "}
        {/* Teal */}
        <RemoteButton
          label={t("header-menu-habit")}
          desc="Track habits & chores"
          color="blue"
          link="habits"
        />{" "}
        {/* Light Green */}
        <RemoteButton
          label={t("header-menu-planner")}
          desc="Build a simple calendar"
          color="blue"
          link="planner"
        />{" "}
        {/* Orange */}
        <RemoteButton
          label="Sortable Lists"
          desc="Create simple draggable lists"
          color="blue"
          link={"sortablelists"}
        />{" "}
        <RemoteButton
          label={t("header-menu-meal")}
          desc="Your Recipes & Meal Planner"
          color="blue"
          link="recipes"
        />{" "}
        <RemoteButton
          label="New Recipe Search"
          desc="Looking for a recipe? Let A.I. create one up for you!"
          color="purple"
          link={"recipesearch"}
        />
        <RemoteButton
          label="List Magic"
          desc="Generate a checklist with A.I."
          color="purple"
          link={"listmagic"}
        />{" "}
        <RemoteButton
          label="Idea Generator"
          desc="Kickstart a new note or idea with A.I."
          color="purple"
          link={"ideas"}
        />
        {/* Red */}
        {/* Dark Blue */}
      </div>
    </div>
  );
};

import { useEffect, useState } from "react";
import { useUserContext } from "Authentication";
import { collection, query, onSnapshot } from "@firebase/firestore";
import { NotesModel } from "./models/notes";

export function useNotesSubscription() {
  const [notes, setNotes] = useState<NotesModel[]>([]);
  const { getNotesCollection } = useUserContext();

  useEffect(() => {
    const notesCollection = getNotesCollection();
    const notesQuery = query(notesCollection);

    const unsubscribe = onSnapshot(
      notesQuery,
      (snapshot) => {
        const addedNotes: NotesModel[] = [];

        snapshot.docs.forEach((doc) => {
          const item = { ...doc.data(), id: doc.id } as NotesModel;
          addedNotes.push(item);
        });

        setNotes(addedNotes);
      },
      (error) => {
        window.location.reload();
      }
    );

    return () => unsubscribe();
  }, [getNotesCollection]);

  // Convert date to epoch
  notes.sort(
    (a, b) =>
      Math.floor(new Date(b.date).getTime() / 1000) -
      Math.floor(new Date(a.date).getTime() / 1000)
  );

  return { notes };
}

import { useState } from "react";
import { RecipeModel } from "Recipes";
import { useRecipes } from "Recipes";
import { RecipeListItem } from "./RecipeListItem";
import { RecipeLanding } from "Recipes/RecipeLanding";
import { Link, Route, useRouteMatch } from "react-router-dom";
import { RecipeCreator } from "Recipes/RecipeCreator/RecipeCreator";
import { classicRecipes } from "Recipes/RecipeData/classicRecipes";
import { classicRecipesFR } from "Recipes/RecipeData/recipe_data_fr";
import { classicRecipesES } from "Recipes/RecipeData/recipe_data_es";
import { classicRecipesDE } from "Recipes/RecipeData/recipe_data_de";
import { classicRecipesSE } from "Recipes/RecipeData/recipe_data_se";
import { classicRecipesAR } from "Recipes/RecipeData/recipe_data_ar";
import { classicRecipesCN } from "Recipes/RecipeData/recipe_data_cn";
import { classicRecipesKR } from "Recipes/RecipeData/recipe_data_kr";
import { classicRecipesJP } from "Recipes/RecipeData/recipe_data_jp";
import { FilterRecipes } from "Recipes/RecipeListActions/FilterRecipes";
import toast, { Toaster } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

interface RecipeListProps {
  recipes: RecipeModel[];
  onClickAdd: () => void;
  onFilterChange: (filter: string) => void;
  onClickRecipe: (recipe: RecipeModel) => void;
  isOnline: boolean;
}

export const RecipeList = (props: RecipeListProps) => {
  const { recipes, onClickRecipe, onClickAdd, onFilterChange, isOnline } =
    props;
  const { loadDefaultRecipes } = useRecipes();
  const [isAddNewRecipe, setIsAddNewRecipe] = useState(false);
  const { url } = useRouteMatch();
  const { t } = useTranslation();

  const LoadRecipes = () => {
    const getlanguage = localStorage.getItem("userLanguage");
    var language = getlanguage != undefined ? getlanguage : "en";

    const whichVersion = () => {
      if (language == "en") {
        return classicRecipes;
      } else if (language == "cn") {
        return classicRecipesCN;
      } else if (language == "de") {
        return classicRecipesDE;
      } else if (language == "fr") {
        return classicRecipesFR;
      } else if (language == "es") {
        return classicRecipesES;
      } else if (language == "jp") {
        return classicRecipesJP;
      } else if (language == "kr") {
        return classicRecipesKR;
      } else if (language == "ar") {
        return classicRecipesAR;
      } else if (language == "se") {
        return classicRecipesSE;
      } else {
        return classicRecipes;
      }
    };

    loadDefaultRecipes(whichVersion());
  };

  const linkList = recipes.map((recipe) => {
    return (
      <div key={recipe.id}>
        <Link to={`${url}/${recipe.id}`}>
          <RecipeListItem
            recipe={recipe}
            onClick={() => onClickRecipe(recipe)}
          />
        </Link>
      </div>
    );
  });

  return (
    <div style={{ userSelect: "none" }}>
      <Toaster containerStyle={{ marginTop: 125 }} />
      <ul
        style={{ listStyle: "none", paddingLeft: 0, margin: 0, fontSize: 16 }}
      >
        {!isAddNewRecipe ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 0,
              paddingBottom: 30,
            }}
          >
            {" "}
            <button
              onClick={() => setIsAddNewRecipe(true)}
              title="Add recipe"
              type="button"
              style={{
                fontSize: 14,
                fontFamily: "inherit",
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 10,
                paddingBottom: 10,
                color: "white",
                backgroundColor: "#1e79c3",
                borderRadius: 40,
                border: 0,
                cursor: "pointer",
              }}
            >
              +{t("common-button-addnew")}
            </button>
          </div>
        ) : (
          <div style={{ backgroundColor: "#3e363f", borderRadius: 5 }}>
            <div
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "right",
                marginTop: 0,
                marginRight: 5,

                padding: 0,
              }}
            >
              <FontAwesomeIcon
                icon={faClose}
                size="2x"
                onClick={() => setIsAddNewRecipe(false)}
                style={{
                  marginTop: 10,
                  marginRight: 10,
                  cursor: "pointer",
                  color: "white",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                paddingBottom: 10,
                paddingTop: 0,
                backgroundColor: "#3e363f",
                padding: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingBottom: 10,
                  paddingTop: 10,
                }}
              >
                <button
                  onClick={onClickAdd}
                  title="Add recipe"
                  type="button"
                  style={{
                    fontSize: 14,
                    maxWidth: 150,
                    border: 0,
                    fontFamily: "inherit",
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 10,
                    paddingBottom: 10,
                    color: "white",
                    backgroundColor: "#1e79c3",
                    borderRadius: 40,
                    cursor: "pointer",
                  }}
                >
                  {t("recipelist-add-manual")}
                </button>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  paddingBottom: 10,
                }}
              >
                {isOnline ? (
                  <>
                    <button
                      style={{
                        fontSize: 14,
                        maxWidth: 150,
                        fontFamily: "inherit",
                        border: 0,
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 10,
                        paddingBottom: 10,
                        borderRadius: 40,
                        color: "white",
                        backgroundColor: "#1e79c3",
                        cursor: "pointer",
                      }}
                      onClick={LoadRecipes}
                    >
                      {t("recipelist-add-classicrecipes")}
                    </button>
                    <RecipeCreator />
                  </>
                ) : (
                  <p style={{ marginTop: -5 }}>
                    {t("recipelist-online-notice")}
                  </p>
                )}
                <div
                  style={{
                    paddingTop: 20,
                  }}
                >
                  <button
                    onClick={() => setIsAddNewRecipe(false)}
                    style={{
                      color: "lightblue",
                      backgroundColor: "transparent",
                      fontSize: 16,
                      border: 0,
                      //  borderStyle: "dashed",
                      paddingTop: 0,
                      paddingBottom: 5,
                      borderRadius: 40,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {t("common-button-close")}
                  </button>{" "}
                </div>
                {/*} <label style={{ fontSize: 16 }}>Load the classic recipes</label>*/}
              </div>
            </div>
          </div>
        )}
        <div style={{ paddingTop: 15 }} />
        <label>{t("recipelist-search-label")}</label>
        <div>
          <FilterRecipes onFilterChange={onFilterChange} />
        </div>

        <div style={{ paddingBottom: 20 }} />

        <label style={{ paddingBottom: 10 }}>
          {t("recipelist-reciperesults-label")}
        </label>
        {recipes.length == 0 ? t("recipelist-search-noresults") : ""}
        {linkList}
      </ul>
      <div style={{ paddingBottom: 60 }} />
      <Route path={`${url}/:productId`}>
        <RecipeLanding data={recipes} />
      </Route>
    </div>
  );
};

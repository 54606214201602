import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import linkifyHtml from "linkify-html";
import moment from "moment";
import { EventsModalEdit } from "./EventsModalEdit";
import { Journal } from "./Journal";

export const JournalLanding = ({ data, isExpired, isDarkMode, isOnline }) => {
  const { productId } = useParams();
  const event = data.find((p) => p.id === productId);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {};
  }, []);

  let productData;

  if (event) {
    productData = (
      <div>
        <Journal data={event} isDarkMode={isDarkMode} isOnline={isOnline} />
      </div>
    );
  } else {
    productData = <h2> Sorry. Journal doesn't exist. </h2>;
  }

  return <div>{productData}</div>;
};

import { useEffect, useState } from "react";
import { Container } from "./style";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

export function DayOfTheWeek({
  state,
  setState,
  createHabit,
  weekday,
  AddPlannerWeeklyDay,
}) {
  const [isSelected, setSelected] = useState(false);
  const { t } = useTranslation();
  const weekDays = [
    t("common-day-sunday-abbr"),
    t("common-day-monday-abbr"),
    t("common-day-tuesday-abbr"),
    t("common-day-wednesday-abbr"),
    t("common-day-thursday-abbr"),
    t("common-day-friday-abbr"),
    t("common-day-saturday-abbr"),
  ];

  const addPlannerLock = AddPlannerWeeklyDay ? AddPlannerWeeklyDay + 1 : 100;

  useEffect(() => {
    let newState = { ...state };
    setSelected(newState.days.indexOf(weekday) !== -1);
    //  console.log("weekday", weekday);
    //  console.log("What is day of the date", AddPlannerWeeklyDay + 2);
  }, [state, weekday]);

  if (createHabit === true) {
    let newState = { ...state };

    function handleOnClick() {
      const isLastSelectedDay = newState.days.length === 1 && isSelected;

      if (isLastSelectedDay) {
        toast(t("sections-habits-form-atleastonedaywarning-toast"), {
          duration: 2000,
          position: "top-center",
          style: {
            backgroundColor: "pink",
            border: "1px solid #713200",
            fontSize: "16px",
            padding: "10px",
            color: "black",
          },
        });
        return;
      }

      if (isSelected && weekday !== addPlannerLock) {
        newState.days = newState.days.filter((element) => element !== weekday);
      } else {
        newState.days = [...newState.days, weekday];
      }

      setState(newState);
      setSelected(!isSelected);
    }

    return (
      <Container isSelected={isSelected} onClick={handleOnClick}>
        {weekDays[weekday]}
      </Container>
    );
  } else {
    return <Container isSelected={isSelected}>{weekDays[weekday]}</Container>;
  }
}

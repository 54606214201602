import { useEffect, useState } from "react";
import Linkify from "linkify-react";
import emailjs from "@emailjs/browser";
import TextareaAutosize from "react-textarea-autosize";
import toast, { Toaster } from "react-hot-toast";
import { useUserSubscription } from "Authentication/useUserSubscription";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export const FeedbackWidget = ({ close }) => {
  const user = useUserSubscription();
  const { t } = useTranslation();
  const [feedback, setFeedback] = useState("");
  const location = useLocation();
  const submitFeedback = (e) => {
    e.preventDefault();
    var templateParams = {
      message: feedback,
      email: user.userProfile.email,
    };
    try {
      emailjs
        .send(
          "service_irbu2n6",
          "template_jb9xs46",
          templateParams,
          "IbqQ9coZSkM9kJluH"
        )
        .then(
          (result) => {
            toast(t("feedback-widget-toast-thankyou"), {
              duration: 2000,
              position: "top-center",
              style: {
                backgroundColor: "lightgreen",
                border: "1px solid #713200",
                fontSize: "16px",
                padding: "10px",
                color: "black",
              },
            });
          },
          (error) => {
            toast(t("feedback-widget-toast-nointernet"), {
              duration: 2000,
              position: "top-center",
              style: {
                backgroundColor: "pink",
                border: "1px solid #713200",
                fontSize: "16px",
                padding: "10px",
                color: "black",
              },
            });
          }
        );
    } catch (error) {
      console.log(error);
    }
    setFeedback("");
    setTimeout(() => {
      close();
    }, 3000);
  };

  useEffect(() => {
    return () => {};
    //@ts-ignore
  }, []);

  return (
    <div
      style={{
        padding: 20,
        paddingTop: 20,
        textAlign: "center",
        marginTop: location.pathname != "/" ? 0 : -20,
      }}
    >
      <Toaster />
      <h3>{t("feedback-widget-title")}</h3>
      {user.email == "" || user.email == undefined ? (
        <p style={{ fontSize: 14 }}>
          Please add your email below if you need a reply.
        </p>
      ) : null}
      <form onSubmit={submitFeedback}>
        <TextareaAutosize
          required
          value={feedback}
          minLength={2}
          placeholder={t("feedback-widget-input-placeholder")}
          onChange={(e) => setFeedback(e.target.value)}
          style={{
            width: "90%",
            //  fontWeight: "bold",
            fontSize: 18,
            fontFamily: "inherit",
            lineHeight: 1.35,
            paddingTop: 5,
            paddingBottom: 20,

            border: 0,
            color: "white",
            //backgroundColor: "#67d7cc",
            backgroundColor: "black",
          }}
        />

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 5,
          }}
        >
          <button
            style={{
              cursor: "pointer",
              fontFamily: "inherit",
              fontSize: "inherit",
              border: 1,
              fontWeight: "bold",
              padding: 5,
              paddingLeft: 20,
              paddingRight: 20,
              borderRadius: 40,
              backgroundColor: "#1e79c3",
              color: "white",
            }}
            type="submit"
          >
            {t("feedback-widget-button-send")}
          </button>
        </div>
        {location.pathname != "/" ? (
          <p
            className="actions_link_default"
            style={{ paddingTop: 5, textAlign: "center" }}
            onClick={() => close()}
          >
            {t("common-button-cancel")}
          </p>
        ) : null}
      </form>
      <br />
    </div>
  );
};

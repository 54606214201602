export const commonGroceryItemsSpainSouthAmerica = [
  { category: "100100", name: "Comida para bebés" }, // Baby Food
  { category: "100100", name: "Toallitas para bebés" }, // Baby Wipes
  { category: "100100", name: "Pañales para bebés" }, // Baby Diapers
  { category: "100100", name: "Fórmula infantil" }, // Baby Formula
  { category: "100100", name: "Loción para bebés" }, // Baby Lotion
  { category: "100200", name: "Pan" }, // Bread
  { category: "100200", name: "Croissants" },
  { category: "100200", name: "Bollos" }, // Buns
  { category: "100200", name: "Pan integral" }, // Whole Wheat Bread
  { category: "100200", name: "Baguette" },
  { category: "100300", name: "Harina" }, // Flour
  { category: "100300", name: "Azúcar" }, // Sugar
  { category: "100300", name: "Levadura" }, // Yeast
  { category: "100300", name: "Chocolate para hornear" }, // Baking Chocolate
  { category: "100300", name: "Polvo de almendra" }, // Almond Powder
  { category: "100400", name: "Agua mineral" }, // Mineral Water
  { category: "100400", name: "Jugo de naranja" }, // Orange Juice
  { category: "100400", name: "Café" }, // Coffee
  { category: "100400", name: "Té" }, // Tea
  { category: "100400", name: "Refresco" }, // Soda
  { category: "100500", name: "Detergente" }, // Detergent
  { category: "100500", name: "Líquido para lavar platos" }, // Dishwashing Liquid
  { category: "100500", name: "Limpiador multiusos" }, // All-purpose Cleaner
  { category: "100500", name: "Toallas de papel" }, // Paper Towels
  { category: "100500", name: "Bolsas de basura" }, // Trash Bags
  { category: "100600", name: "Salsa de tomate" }, // Tomato Sauce
  { category: "100600", name: "Mostaza" }, // Mustard
  { category: "100600", name: "Mayonesa" }, // Mayonnaise
  { category: "100600", name: "Aderezo para ensaladas" }, // Salad Dressing
  { category: "100600", name: "Ketchup" },
  { category: "100700", name: "Leche" }, // Milk
  { category: "100700", name: "Mantequilla" }, // Butter
  { category: "100700", name: "Queso" }, // Cheese
  { category: "100700", name: "Yogur" }, // Yogurt
  { category: "100700", name: "Crema" }, // Cream
  { category: "100800", name: "Pizza congelada" }, // Frozen Pizza
  { category: "100800", name: "Verduras congeladas" }, // Frozen Vegetables
  { category: "100800", name: "Helado" }, // Ice Cream
  { category: "100800", name: "Comidas preparadas congeladas" }, // Frozen Prepared Meals
  { category: "100800", name: "Papas fritas congeladas" }, // Frozen Fries
  { category: "100900", name: "Manzanas" }, // Apples
  { category: "100900", name: "Plátanos" }, // Bananas
  { category: "100900", name: "Tomates" }, // Tomatoes
  { category: "100900", name: "Lechuga" }, // Lettuce
  { category: "100900", name: "Zanahorias" }, // Carrots
  { category: "100900", name: "Cebollas" }, // Onions
  { category: "100900", name: "Pimientos" }, // Bell Peppers
  { category: "100900", name: "Patatas" }, // Potatoes
  { category: "100900", name: "Ajos" }, // Garlic
  { category: "100900", name: "Espinacas" }, // Spinach
  { category: "101100", name: "Carne de res" }, // Beef
  { category: "101100", name: "Carne de cerdo" }, // Pork
  { category: "101100", name: "Pollo" }, // Chicken
  { category: "101100", name: "Pavo" }, // Turkey
  { category: "101100", name: "Chorizo" }, // Chorizo
  { category: "101200", name: "Paracetamol" }, // Paracetamol
  { category: "101200", name: "Ibuprofeno" }, // Ibuprofen
  { category: "101200", name: "Vendas" }, // Bandages
  { category: "101200", name: "Termómetro" }, // Thermometer
  { category: "101200", name: "Antiséptico" }, // Antiseptic
  { category: "101500", name: "Arroz" }, // Rice
  { category: "101500", name: "Aceite de oliva" }, // Olive Oil
  { category: "101500", name: "Lentejas" }, // Lentils
  { category: "101500", name: "Frijoles" }, // Beans
  { category: "101500", name: "Conservas" }, // Canned Goods
  { category: "101700", name: "Comida para perros" }, // Dog Food
  { category: "101700", name: "Comida para gatos" }, // Cat Food
  { category: "101700", name: "Arena para gatos" }, // Cat Litter
  { category: "101700", name: "Juguetes para mascotas" }, // Pet Toys
  { category: "101700", name: "Shampoo para mascotas" }, // Pet Shampoo
  { category: "101800", name: "Pescado" }, // Fish
  { category: "101800", name: "Mariscos" }, // Seafood
  { category: "101800", name: "Langostinos" }, // Prawns
  { category: "101800", name: "Calamares" }, // Squid
  { category: "101800", name: "Mejillones" }, // Mussels
  { category: "101900", name: "Papas fritas" }, // Potato Chips
  { category: "101900", name: "Chocolate" }, // Chocolate
  { category: "101900", name: "Galletas" }, // Cookies
  { category: "101900", name: "Frutos secos" }, // Dried Fruits
  { category: "101900", name: "Nueces" }, // Nuts
  { category: "102000", name: "Sal" }, // Salt
  { category: "102000", name: "Pimienta" }, // Pepper
  { category: "102000", name: "Curry" }, // Curry
  { category: "102000", name: "Pimentón" }, // Paprika
  { category: "102000", name: "Canela" }, // Cinnamon
  { category: "102500", name: "Albahaca" }, // Basil
  { category: "102500", name: "Perejil" }, // Parsley
  { category: "102500", name: "Tomillo" }, // Thyme
  { category: "102500", name: "Romero" }, // Rosemary
  { category: "102500", name: "Orégano" }, // Oregano
  { category: "100200", name: "Empanadas" }, // Empanadas
  { category: "100200", name: "Tortillas" }, // Tortillas
  { category: "100200", name: "Churros" },
  { category: "100300", name: "Azúcar moreno" }, // Brown Sugar
  { category: "100300", name: "Extracto de vainilla" }, // Vanilla Extract
  { category: "100400", name: "Vino tinto" }, // Red Wine
  { category: "100400", name: "Vino blanco" }, // White Wine
  { category: "100400", name: "Cerveza" }, // Beer
  { category: "100400", name: "Sangría" }, // Sangria
  { category: "100400", name: "Agua con gas" }, // Sparkling Water
  { category: "100500", name: "Jabón" }, // Soap
  { category: "100500", name: "Champú" }, // Shampoo
  { category: "100500", name: "Limpiador de ventanas" }, // Window Cleaner
  { category: "100500", name: "Ambientador" }, // Air Freshener
  { category: "100500", name: "Esponjas" }, // Sponges
  { category: "100600", name: "Salsa de ajo" }, // Garlic Sauce
  { category: "100600", name: "Salsa brava" }, // Brava Sauce
  { category: "100600", name: "Salsa verde" }, // Green Sauce
  { category: "100600", name: "Salsa alioli" }, // Aioli Sauce
  { category: "100900", name: "Champiñones" }, // Mushrooms
  { category: "100900", name: "Pepinos" }, // Cucumbers
  { category: "100900", name: "Calabazas" }, // Pumpkins
  { category: "100900", name: "Berenjenas" }, // Eggplants
  { category: "100900", name: "Pimientos" }, // Bell Peppers
  { category: "100900", name: "Batatas" }, // Sweet Potatoes
  { category: "100900", name: "Nabos" }, // Turnips
  { category: "100900", name: "Espinacas" }, // Spinach
  { category: "100900", name: "Alcachofas" }, // Artichokes
  { category: "100900", name: "Puerros" }, // Leeks
  { category: "101100", name: "Cordero" }, // Lamb
  { category: "101100", name: "Hígado" }, // Liver
  { category: "101100", name: "Chorizo" }, // Chorizo
  { category: "101100", name: "Morcilla" }, // Blood Sausage
  { category: "101100", name: "Jamón" }, // Ham
  { category: "101100", name: "Gambas" }, // Prawns
  { category: "101100", name: "Langostinos" }, // King Prawns
  { category: "101100", name: "Pulpo" }, // Octopus
  { category: "101100", name: "Mejillones" }, // Mussels
  { category: "101100", name: "Bacalao" }, // Cod
  { category: "101200", name: "Aceite de oliva" }, // Olive Oil
  { category: "101200", name: "Vinagre" }, // Vinegar
  { category: "101200", name: "Mermelada" }, // Jam
  { category: "101200", name: "Miel" }, // Honey
  { category: "101200", name: "Turrón" }, // Nougat
  { category: "101500", name: "Arroz" }, // Rice
  { category: "101500", name: "Lentejas" }, // Lentils
  { category: "101500", name: "Garbanzos" }, // Chickpeas
  { category: "101500", name: "Fideos" }, // Noodles
  { category: "101500", name: "Harina" }, // Flour
  { category: "101700", name: "Comida para perros" }, // Dog Food
  { category: "101700", name: "Comida para gatos" }, // Cat Food
  { category: "101700", name: "Arena para gatos" }, // Cat Litter
  { category: "101700", name: "Juguetes para mascotas" }, // Pet Toys
  { category: "101700", name: "Champú para mascotas" }, // Pet Shampoo
  { category: "101800", name: "Atún" }, // Tuna
  { category: "101800", name: "Salmón" }, // Salmon
  { category: "101800", name: "Trucha" }, // Trout
  { category: "101800", name: "Dorada" }, // Gilt-head Bream
  { category: "101800", name: "Rodaballo" }, // Turbot
  { category: "101900", name: "Churros" }, // Churros
  { category: "101900", name: "Tapas" }, // Tapas
  { category: "101900", name: "Empanadas" }, // Empanadas
  { category: "101900", name: "Tortilla Española" }, // Spanish Omelette
  { category: "101900", name: "Alfajores" }, // Alfajores
  { category: "102000", name: "Pimentón" }, // Paprika
  { category: "102000", name: "Azafrán" }, // Saffron
  { category: "102000", name: "Comino" }, // Cumin
  { category: "102000", name: "Canela" }, // Cinnamon
  { category: "102000", name: "Laurel" }, // Bay Leaf
];

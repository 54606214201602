import { useEffect, useState } from "react";
import { HabitModel } from "./habitmodel";
import { useUserContext } from "Authentication";
import { collection, query, onSnapshot } from "@firebase/firestore";

export function useHabitSubscription() {
  const { getHabitsCollection } = useUserContext();
  const [habits, setHabits] = useState<HabitModel[]>([]);

  useEffect(() => {
    const plannerCollection = getHabitsCollection();
    const plannerQuery = query(plannerCollection);

    const unsubscribe = onSnapshot(
      plannerQuery,
      (snapshot) => {
        const addedEvents: HabitModel[] = [];

        snapshot.docs.forEach((doc) => {
          const item = { ...doc.data(), id: doc.id } as HabitModel;
          addedEvents.push(item);
        });

        setHabits(addedEvents);
      },
      (error) => {
        window.location.reload();
      }
    );

    return () => unsubscribe();
  }, [getHabitsCollection]);

  // Convert date to epoch
  habits.sort((a, b) => a.name.localeCompare(b.name));

  return { habits };
}

import { useEffect, useState } from "react";
import { useUserContext } from "Authentication";
import { collection, query, onSnapshot } from "@firebase/firestore";
import { ScoreModel } from "./models/score";

export function useScoreSubscription() {
  const [scores, setScores] = useState<ScoreModel[]>([]);
  const { getScoreCollection } = useUserContext();

  useEffect(() => {
    const scoreCollection = getScoreCollection();
    const scoreQuery = query(scoreCollection);

    const unsubscribe = onSnapshot(
      scoreQuery,
      (snapshot) => {
        const addedScores: ScoreModel[] = [];

        snapshot.docs.forEach((doc) => {
          const item = { ...doc.data(), id: doc.id } as ScoreModel;
          addedScores.push(item);
        });

        setScores(addedScores);
      },
      (error) => {
        window.location.reload();
      }
    );

    return () => unsubscribe();
  }, [scores]);

  // Convert date to epoch
  scores.sort(
    (a, b) =>
      Math.floor(new Date(b.date).getTime() / 1000) -
      Math.floor(new Date(a.date).getTime() / 1000)
  );

  return { scores };
}

import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import linkifyHtml from "linkify-html";

import { ListToDoSingleEvent } from "./ListToDoSingleEvent";

export const ListToDoLanding = ({ data, isExpired }) => {
  const { productId } = useParams();
  const event = data.find((p) => p.id === productId);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {};
  }, []);

  let productData;

  if (event) {
    productData = (
      <div>
        <ListToDoSingleEvent item={event} />
      </div>
    );
  } else {
    productData = <h2> Sorry. Task/To-Do doesn't exist. </h2>;
  }

  return <div>{productData}</div>;
};

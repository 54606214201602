import React from "react";
import { Game } from "./components/Game";

export const Islandtraders = () => {
  return (
    <div className="App">
      <Game />
    </div>
  );
};

import React, { useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import TextareaAutosize from "react-textarea-autosize";
import toast, { Toaster } from "react-hot-toast";

export const UserExperienceRater = ({ showModal, close, user }) => {
  const [visitCount, setVisitCount] = useState(0);
  const [showRater, setShowRater] = useState(false);
  const [selectedRating, setSelectedRating] = useState(null);
  const [showReviewPrompt, setShowReviewPrompt] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [comments, setComments] = useState("");
  const [isNative, setIsNative] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    setShowRater(true);

    if (window.isNative) {
      setIsNative(true);
    } else {
      setIsNative(false);
    }

    if (window.isIOS) {
      setIsIOS(true);
    } else {
      setIsIOS(false);
    }
  }, []);

  const getEpochFutureDate = (days) => {
    const currentDate = new Date();
    const threeDaysLater = new Date(currentDate);
    threeDaysLater.setDate(currentDate.getDate() + days);

    const epochSecondsXDaysLater = Math.floor(threeDaysLater.getTime());
    return epochSecondsXDaysLater;
  };

  const handleDismiss = () => {
    setShowRater(true);
    setSelectedRating(null);

    localStorage.setItem("UER2023FirstDate", getEpochFutureDate(3).toString());

    close();
  };

  const handleRatingSelect = (rating) => {
    setSelectedRating(rating);
    setShowForm(true);
  };

  const handleReviewSubmit = () => {
    // Handle the submission of the review, e.g., send it to a server
    //console.log(`Rating: ${selectedRating}, Comments: ${comments}`);
    var templateParams = {
      message: selectedRating + " | " + comments,
      email: user.email ? user.email : "Guest User",
    };
    try {
      emailjs
        .send(
          "service_irbu2n6",
          "template_jb9xs46",
          templateParams,
          "IbqQ9coZSkM9kJluH"
        )
        .then(
          (result) => {
            toast("Sent!  Thank you for your feedback!", {
              duration: 2000,
              position: "top-center",
              style: {
                backgroundColor: "lightgreen",
                border: "1px solid #713200",
                fontSize: "16px",
                padding: "10px",
                color: "black",
              },
            });
          },
          (error) => {
            toast(
              "Uh oh!  Connection error.  Please check your internet connection",
              {
                duration: 2000,
                position: "top-center",
                style: {
                  backgroundColor: "pink",
                  border: "1px solid #713200",
                  fontSize: "16px",
                  padding: "10px",
                  color: "black",
                },
              }
            );
          }
        );
    } catch (error) {
      console.log(error);
      setShowReviewPrompt(false);
      setShowForm(false);
      setShowRater(false);
    }

    if (
      (isNative && selectedRating === 4) ||
      (isNative && selectedRating === 5)
    ) {
      setShowReviewPrompt(true);
      setShowForm(false);
      setShowRater(false);

      // Off for testing
      localStorage.setItem("UER2023", "completed");
    } else {
      resetRater();
      localStorage.setItem("UER2023", "completed");
    }
    // setShowForm(false);
  };

  const handleReviewPromptResponse = (response) => {
    if (response === "yes") {
      // Redirect to the app review page or open a review modal

      if (isNative && !isIOS) {
        window.open(
          " https://play.google.com/store/apps/details?id=com.holmgren.dopenotes&showAllReviews=true"
        );
      } else if (isNative && isIOS) {
        window.location.href =
          "https://apps.apple.com/app/apple-store/id1631683070?action=write-review";
      }
    }
    //  setShowForm(false);

    resetRater();
  };

  const resetRater = () => {
    setVisitCount(visitCount + 1);
    //uer2023 is userExperienceRater2023

    setShowRater(true);
    setSelectedRating(null);
    setShowReviewPrompt(false);
    setShowForm(false);
    setComments("");
    close();
  };

  const styles = {
    button: {
      paddingLeft: 10,
      width: 100,
      paddingRight: 10,
      paddingTop: 5,
      paddingBottom: 5,
      borderRadius: 20,
      border: 0,
      cursor: "pointer",
      userSelect: "none",
      color: "white",
      backgroundColor: "purple",
      fontFamily: "inherit",
      fontSize: 16,
    },
    buttonClose: {
      paddingLeft: 10,
      //width: 100,
      paddingRight: 10,
      paddingTop: 5,
      paddingBottom: 5,
      borderRadius: 20,
      border: 0,
      cursor: "pointer",
      userSelect: "none",
      color: "black",
      backgroundColor: "lightgrey",
      fontFamily: "inherit",
      fontSize: 16,
    },
    modal: {
      display: "flex",
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      overflowX: "scroll",
      background: "rgba(0, 0, 0, 0.5)",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 20,
    },
    modalContent: {
      background: "#fff",
      padding: 20,
      width: "80%",
      overflowX: "scroll",
      borderRadius: "8px",
      zIndex: "20",
    },
  };

  return (
    <div>
      {showModal ? (
        <div style={styles.modal}>
          <div style={styles.modalContent}>
            {showRater && (
              <div style={{}}>
                <p style={{ textAlign: "center", fontSize: 24 }}>
                  How would you rate your overall experience?
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {[1, 2, 3, 4, 5].map((rating, index) => (
                    <div style={{ padding: 5 }} key={index}>
                      <button
                        key={rating}
                        onClick={() => handleRatingSelect(rating)}
                        style={{
                          fontSize: 18,
                          padding: 10,
                          backgroundColor:
                            selectedRating == rating ? "lime" : "",
                        }}
                      >
                        {rating}
                      </button>
                    </div>
                  ))}
                </div>
                {selectedRating != null ? null : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      onClick={handleDismiss}
                      style={{
                        marginTop: 20,
                        padding: 5,
                        backgroundColor: "transparent",
                        color: "blue",
                        textDecoration: "underline",
                        border: 0,
                      }}
                    >
                      Dismiss
                    </button>
                  </div>
                )}
              </div>
            )}

            {showRater && showForm && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <p>Comments/Suggestions:</p>
                <TextareaAutosize
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  minRows={3}
                  style={{
                    paddingTop: 10,
                    border: 1,
                    borderStyle: "dashed",
                    backgroundColor: "lightblue",
                    color: "black",
                    width: "90%",
                    maxWidth: 300,
                    height: 50,
                    fontSize: 18,
                  }}
                />
                <div style={{ paddingTop: 20 }}>
                  <button style={styles.button} onClick={handleReviewSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            )}

            {showReviewPrompt && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                <p>
                  Mahalo! Hawaiian sunsets and app reviews – both bring joy!
                  Care to share your aloha with us on the app store?
                </p>
                <div
                  style={{
                    display: "flex",

                    flexDirection: "row",
                  }}
                >
                  {" "}
                  <button
                    onClick={() => handleReviewPromptResponse("yes")}
                    style={styles.button}
                  >
                    Yes
                  </button>{" "}
                  <span style={{ padding: 5 }} />
                  <button
                    onClick={() => handleReviewPromptResponse("no")}
                    style={styles.buttonClose}
                  >
                    No, Thanks
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

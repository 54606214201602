import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { BuyNowModal } from "Authentication/BuyNowModal";
import { EmailAuthProvider, getAuth, linkWithCredential } from "firebase/auth";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { InviteMember } from "./InviteMember";
import {
  faLink,
  faEnvelope,
  faUserPlus,
  faTrash,
  faSignOut,
  faPen,
  faClose,
  faPaintRoller,
  faArrowRight,
  faRefresh,
  faWifi,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { DiApple, DiAndroid } from "react-icons/di";
//import logo from "../Assets/logo192.png";
//import alohaicon from "../Assets/alohaicon.png";
import cx from "classnames/bind";
import { User, useUserContext } from "../Authentication";
import { useGroup, DatabaseUser } from "Authentication";
import { Spinner } from "Common";
import { useGroupSubscription } from "Authentication/useGroupSubscription";
import classes from "./Household.module.css";
import { useAuth } from "Authentication";
//import Fade from "react-reveal/Fade";
//import LightSpeed from "react-reveal/LightSpeed";
import { FeedbackWidget } from "Splash/RegisteredSplash/sections/FeedbackWidget";
import { storage } from "Config";
import { useUserSubscription } from "Authentication/useUserSubscription";
import { FirebaseAnalytics } from "Config/FirebaseAnalytics";
//import { CirclePicker, GithubPicker } from "react-color";
import { Modal } from "Common";
//import RMModal from "react-modal";
import { ConfirmationModal } from "Common";
import toast, { Toaster } from "react-hot-toast";
//import emailjs from '@emailjs/browser';
import moment from "moment";
import { GuestUserRegister } from "Splash/RegisteredSplash/GuestUserRegister";
import { LanguageSwitcher } from "Locales/LanguageSwitcher";
import { SimplePlanner } from "Planner/SimplePlanner";

interface SettingProps {
  isExpired: boolean;
  isPro: boolean;
  isAlohaPlanner: boolean;
  isDarkMode: boolean;
  setIsDarkMode: Dispatch<SetStateAction<boolean>>;
  isColorPickerOpen: boolean;
  setIsColorPickerOpen: Dispatch<SetStateAction<boolean>>;
  ExpirationDate: Date;
  isOnline: boolean;
}

export const Household = (props: SettingProps) => {
  const {
    isExpired,
    isPro,
    isDarkMode,
    setIsDarkMode,
    isAlohaPlanner,
    isColorPickerOpen,
    setIsColorPickerOpen,
    ExpirationDate,
    isOnline,
  } = props;
  const { t } = useTranslation();
  const group = useGroup();
  const user = useUserContext();
  const [inviteCode, setInviteCode] = useState("");
  const [isOwner, setIsOwner] = useState(false);
  const [groupMemberID, setGroupMemberID] = useState("");
  const {
    deleteGroupUsers,
    removeGroupFromMemberByOwner,
    deleteUser,
    changeUserName,
    linkEmailToGuest,
    UpdateThemeFont,
  } = useUserContext();
  const { groupUser } = useGroupSubscription();
  const { logout } = useAuth();
  const [showBuyButton, setShowBuyButton] = useState(true);
  const [themeFont, setThemeFont] = useState(themeCurrentFont);
  const [themeColor, setThemeColor] = useState(themeCurrentColor);
  const [isThemeEditOpen, setIsThemeEditOpen] = useState(false);
  const [UpdateBTN, setUpdateBtn] = useState(false);
  const [isContactSupport, setIsContactSupport] = useState(false);
  const [isBuyNow, setIsBuyNow] = useState(false);
  const [isNative, setIsNative] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [emailFBLink, setEmailFBLink] = useState("");
  const [passwordFBLink, setPasswordFBLink] = useState("");
  const [linkEmailForm, setLinkEmailForm] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const [confirmModalOpenGoogle, setConfirmModalOpenGoogle] = useState(false);
  const [cancelReminderConfirmModalOpen, setCancelReminderConfirmModalOpen] =
    useState(false);

  const currentYear = new Date().getFullYear();
  const userProfile = useUserSubscription();

  const [isUpdateNotice, setIsUpdateNotice] = useState(false);
  //@ts-ignore

  // @ts-ignore
  var userName = userProfile.userProfile.name;

  var currentUserID = user.user.id;

  //console.log("user Id", currentUserID, user.user.id);

  // @ts-ignore
  var email = userProfile.userProfile.email;
  // @ts-ignore
  var SubscriptDate = userProfile.userProfile.subscriptionEndDate;
  // @ts-ignore
  var SubscriptStatus = userProfile.userProfile.subscriptionStatus;
  // @ts-ignore
  var themeCurrentFont =
    // @ts-ignore
    userProfile.userProfile.themeFont != undefined
      ? // @ts-ignore
        userProfile.userProfile.themeFont
      : "CabinSketch";
  // @ts-ignore
  var themeCurrentColor =
    // @ts-ignore
    userProfile.userProfile.themeColor != undefined
      ? // @ts-ignore
        userProfile.userProfile.themeColor
      : "#673ab7";

  const today = new Date();

  const isPayPalSubscriber = localStorage.getItem("isPayPalSubscriber");

  //Firebase link Guest to an Email and Password

  const handleLinkWithEmail = () => {
    const credential = EmailAuthProvider.credential(
      emailFBLink,
      passwordFBLink
    );

    const auth = getAuth();
    linkWithCredential(auth.currentUser, credential)
      .then((usercred) => {
        const user = usercred.user;
        //  console.log("Account linking success", user);
        linkEmailToGuest(emailFBLink);
        setLinkEmailForm(false);
        toast(t("settings-toast-newaccountadded"), {
          duration: 4000,
          position: "top-center",
          style: {
            backgroundColor: "lightgreen",
            border: "1px solid #713200",
            fontSize: "16px",
            padding: "10px",
            color: "black",
          },
        });
      })
      .catch((error) => {
        // console.log("Account linking error", error);
      });
  };

  //console.log("what is Today", today)

  const notificationCancel = () => {
    if ((window as any).isNative) {
      // Post the message back to expo
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({ type: "NotificationCancelAll" })
      );
      setCancelReminderConfirmModalOpen(false);
      //alert(data.name, data.days, valueTime)
    } else {
      // Your old login method
      //  console.log("clicked")
    }
  };

  const checkMemberID = (memberName, memberID, owner) => {
    // memberName.prevent.default()
    if (memberName === user.user.name) {
      // setGroupMemberID(memberID)
      //console.log("Did it set", memberID)
    }
  };

  var isAndroid = isNative && !isIOS ? true : false;
  var isIOSDevice = isNative && isIOS ? true : false;

  const isPayPalSubscription = () => {
    //@ts-ignore
    // console.log("what is it", user.user.payPal);
    //@ts-ignore
    if (
      //@ts-ignore
      user.user.payPal != undefined &&
      //@ts-ignore
      user.user.payPal.subscriptionID != undefined
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isPayPalLifetime = () => {
    //@ts-ignore
    // console.log("what is it", user.user.payPal);
    //@ts-ignore
    if (
      //@ts-ignore
      user.user.payPal != undefined &&
      //@ts-ignore
      user.user.payPal.lifetime
    ) {
      return true;
    } else {
      return false;
    }
  };
  //console.log("is IOS", ExpirationDate);
  //console.log("is Android", isAndroid);

  //Handle calendar start of the week

  const plannerStartWeekDay = localStorage.getItem("calendarStartWeekDay")
    ? parseInt(localStorage.getItem("calendarStartWeekDay"))
    : 0;

  const [calendarStartWeekDay, setCalendarStartWeekDay] =
    useState(plannerStartWeekDay);

  const handleCalendarStartWeekDay = (e) => {
    // console.log("what is e", e);
    localStorage.setItem("calendarStartWeekDay", e.value);
    setCalendarStartWeekDay(e.value);
  };

  const optionsCalendarStartWeekDay = [
    { value: 0, label: "Sunday" },
    { value: 1, label: "Monday" },
  ];

  const ReviewApp = () => {
    if (isAlohaPlanner) {
      isAndroid
        ? window.open(
            " https://play.google.com/store/apps/details?id=com.holmgren.alohaplanner&showAllReviews=true"
          )
        : console.log("ok");
      isIOSDevice
        ? (window.location.href =
            "https://apps.apple.com/app/apple-store/id6456223469?action=write-review")
        : console.log("ok");
    } else {
      isAndroid
        ? window.open(
            " https://play.google.com/store/apps/details?id=com.holmgren.dopenotes&showAllReviews=true"
          )
        : console.log("ok");
      isIOSDevice
        ? (window.location.href =
            "https://apps.apple.com/app/apple-store/id1631683070?action=write-review")
        : console.log("ok");
    }
  };

  // @ts-ignore
  const memberAdded = user.user.added;
  // @ts-ignore

  const convertDate = (date) => {
    var update;
    var showButtonDate = today;
    if (date !== undefined) {
      update = moment(new Date(date.seconds * 1000)).format("MMMM DD, YYYY");
    }
    //  const update = date.toDate()
    return update;
  };

  const convertDateChecker = (date) => {
    var update = today;
    if (date !== undefined) {
      update = new Date(date.seconds * 1000);
    }
    //  const update = date.toDate()
    return update;
  };

  const convertExpirationDate = () => {
    //@ts-ignore
    var date = ExpirationDate;
    //console.log("what is date", date);

    var update;
    if (date !== undefined) {
      //@ts-ignore
      update = moment(new Date(date)).format("MMMM DD, YYYY");
    }
    //  const update = date.toDate()
    return update;
  };

  const [isChangingName, setIsChangingName] = useState(false);
  const [newNickname, setNewNickname] = useState("");

  const changeUserNameRequest = () => {
    setNewNickname("");
    setIsChangingName(true);
  };

  const handleUpdateChangeName = (e) => {
    setNewNickname(e.target.value);
  };

  const handleSubmitChangeName = () => {
    if (newNickname.length > 0) {
      changeUserName(newNickname);
      setNewNickname("");
      setIsChangingName(false);
      toast(t("settings-toast-newname"), {
        duration: 2500,
        position: "top-center",
        style: {
          backgroundColor: "lightgreen",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
    } else {
      toast(t("settings-toast-namewarning"), {
        duration: 2500,
        position: "top-center",
        style: {
          backgroundColor: "pink",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
    }
  };

  const deleteMemberByOwner = (id, userID) => {
    // Delete from Member List
    // console.log("ids", id, userID);
    deleteGroupUsers(id);
    //use this to remove from array
    //  removeGroupFromMemberByOwner(userID);
    //console.log("User Should be removed from member and need to reset the group")
    //Delete from user's account
  };

  const deleteAccountCheck = () => {
    removeAccount();
  };

  const removeAccount = () => {
    //delete user from DB
    deleteUser(currentUserID);
    window.location.href = `mailto:support@alohaplanner.app?subject=Remove%20Account&body=Please%20remove%20my%20account%0A%0AAccount%20ID:%20%0A${currentUserID}%0A%0AFeedback:`;
  };

  const BuyButtonYear = () => {
    if ((window as any).isNative) {
      // Post the message back to expo
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({ type: "BuyButtonSelectedYear", userid: currentUserID })
      );
    } else {
      // Your old login method
      toast(t("settings-toast-webapp-sub-warning"), {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "pink",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
      //console.log("clicked")
    }
  };

  const clearCacheData = () => {
    try {
      if ("caches" in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload();
      }
    } catch (error) {
      alert(error);
    }

    // alert('Your cache for Dopenotes.io has been cleared. You will now be logged out.')
  };

  const LogOutClearCache = () => {
    //  clearCacheData();
    localStorage.clear();
    logout();
    // window.open("/");
  };

  const history = useHistory();

  const handleBuyNow = () => {
    history.push("/buynow");
  };

  const checkInvitationCode = () => {
    var link = "/join/" + inviteCode;

    //  window.open(link);
    history.push(link);
  };

  const changeThemeFont = (e) => {
    themeCurrentFont = e.target.value;
    setThemeFont(e.target.value);
    setUpdateBtn(true);
  };

  const reloadData = () => {
    //@ts-ignore
    window.location.reload(true);
    // CloseFirstLaunchHelp();
    // CloseMenu();
  };

  const updateTheme = (e) => {
    e.preventDefault();
    themeCurrentFont = themeFont;

    UpdateThemeFont(themeFont);
    document.body.dataset.theme = themeFont;
    //console.log("success")
    setIsThemeEditOpen(false);
    setUpdateBtn(false);
  };

  const [isRestoring, setIsRestoring] = useState(false);
  const [isRestoreCheck, setIsRestoreCheck] = useState(false);

  const restoreApple = () => {
    setIsRestoring(true);
    setIsRestoreCheck(true);
    setTimeout(() => {
      setIsRestoreCheck(false);
    }, 2000);

    setTimeout(() => {
      setIsRestoring(false);
    }, 4000);
  };

  const close = () => {
    setIsThemeEditOpen(false);
  };
  const Newcolors = [
    "black",
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#607d8b",
  ];

  const handleColorChangeComplete = (color, event) => {
    //console.log("which color", color.hex)
    themeCurrentColor = color.hex;
    setThemeColor(color.hex);
  };

  const appStoreURL = () => {
    var url = isAndroid
      ? "https://play.google.com/store/apps/details?id=com.holmgren.dopenotes"
      : "https://apps.apple.com/us/app/dope-notes-life-organizer/id1631683070";

    // window.open(url, "_blank");
    if (isAndroid) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  useEffect(() => {
    var getAppVersion =
      (window as any).isAppVersion != undefined
        ? parseFloat((window as any).isAppVersion)
        : 1;
    if (getAppVersion < 2.6) {
      setIsUpdateNotice(true);
    }

    // alert("what is app" + getAppVersion);

    window.scrollTo({ top: 0, behavior: "smooth" });

    if ((window as any).isNative) {
      setIsNative(true);
    } else {
      setIsNative(false);
    }

    if ((window as any).isIOS) {
      setIsIOS(true);
    } else {
      setIsIOS(false);
    }

    return () => {};
  }, []);

  if (!group) {
    throw new Error("Should not use household if group is not defined.");
  }

  const openGoogleGDPR = () => {
    (window as any).ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "openGoogleGDPRPolicy",
        // value: "on",
      })
    );
  };

  const openDeviceSettings = () => {
    (window as any).ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "openDeviceSettings",
        // value: "on",
      })
    );
  };
  // console.log(themeCurrentFont);

  return (
    <article style={{ padding: 20 }}>
      <FirebaseAnalytics />
      <Toaster containerStyle={{ marginTop: 125 }} />
      <h2 style={{ textAlign: "center", marginTop: -5 }} className="h2SlideIn">
        {t("settings-title")}
      </h2>

      <dl>
        {user.user.DeleteAcount === true ? (
          <dd
            style={{
              fontSize: 20,
              color: "red",
              paddingBottom: 20,
              paddingLeft: 0,
            }}
          >
            <b>{t("settings-delete-flag")}</b>
          </dd>
        ) : null}
        {isNative && isUpdateNotice ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: 30,
            }}
          >
            <button
              style={{
                fontSize: 24,
                fontFamily: "inherit",
                //width: 200,
                color: "white",
                backgroundColor: "blue",
                borderColor: "transparent",
                padding: 15,

                borderRadius: 10,
                paddingRight: 15,
                marginTop: 0,
                cursor: "pointer",
              }}
              onClick={appStoreURL}
            >
              {t("settings-appupdate-title")}
              <p style={{ fontSize: 18, textAlign: "center" }}>
                {t("settings-appupdate-info")} <br />
                <br />
                <b style={{ textDecoration: "underline" }}>
                  {t("settings-appupdate-info2")}
                </b>
              </p>
            </button>
          </div>
        ) : null}
        <dd style={{ paddingBottom: 10, paddingLeft: 0 }}>
          <b>{t("settings-nickname")}</b> {userName}{" "}
          <FontAwesomeIcon
            onClick={changeUserNameRequest}
            style={{ paddingLeft: 10, cursor: "pointer" }}
            icon={faPen}
            size="sm"
          />
          {isChangingName ? (
            <div
              style={{
                padding: 20,
                backgroundColor: "lavender",
                borderRadius: 5,
                // color: "white",
              }}
            >
              <label>{t("settings-nickname-newname")}</label>
              <input
                type="text"
                placeholder=""
                value={newNickname}
                style={{
                  padding: 10,
                  fontSize: "inherit",
                  fontFamily: "inherit",
                }}
                onChange={(e) => handleUpdateChangeName(e)}
              />
              <div style={{ paddingTop: 10 }} />
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <button
                  onClick={handleSubmitChangeName}
                  style={{
                    fontSize: 16,
                    color: "white",
                    backgroundColor: "blue",
                    borderColor: "transparent",
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    borderRadius: 5,
                  }}
                >
                  {t("common-button-save")}
                </button>
                <div style={{ paddingLeft: 5 }} />
                <span
                  onClick={() => setIsChangingName(false)}
                  className="actions_link_default"
                  style={{ marginTop: 5 }}
                >
                  {t("common-button-close")}
                </span>
              </div>
            </div>
          ) : null}
        </dd>
        <dd style={{ paddingBottom: 10, paddingLeft: 0 }}>
          <b>{t("settings-email")}</b>{" "}
          {email != "" ? (
            email
          ) : (
            <>
              {t("settings-anonymous")}{" "}
              <button
                onClick={() => setLinkEmailForm(true)}
                style={{
                  color: "white",
                  backgroundColor: "blue",
                  fontFamily: "inherit",

                  borderColor: "transparent",
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 5,
                  paddingBottom: 5,
                  borderRadius: 5,
                }}
              >
                {t("settings-register-button")}
              </button>
              {linkEmailForm ? (
                <div style={{ padding: 10 }}>
                  <GuestUserRegister close={() => setLinkEmailForm(false)} />
                </div>
              ) : (
                <span
                  style={{
                    paddingLeft: 10,
                    fontSize: 14,
                    color: "firebrick",
                  }}
                >
                  {t("settings-register-warning")}
                </span>
              )}
            </>
          )}
        </dd>
        <dd style={{ paddingBottom: 10, paddingLeft: 0 }}>
          <b>{t("settings-language")}</b>
          <LanguageSwitcher />
        </dd>
        <dd style={{ paddingBottom: 10, paddingLeft: 0 }}>
          <b>{t("settings-font")}</b> {themeCurrentFont}{" "}
          <FontAwesomeIcon
            onClick={() => setIsThemeEditOpen(true)}
            style={{ paddingLeft: 10, cursor: "pointer" }}
            icon={faPen}
            size="sm"
          />
        </dd>
        <dd style={{ paddingBottom: 10, paddingLeft: 0 }}>
          <b>{t("settings-darkmode")}</b>{" "}
          {isDarkMode ? t("settings-on") : t("settings-off")}{" "}
          <FontAwesomeIcon
            onClick={() => setIsDarkMode((old) => !old)}
            style={{ paddingLeft: 10, cursor: "pointer" }}
            icon={faPen}
            size="sm"
          />
        </dd>
        <dd style={{ paddingBottom: 10, paddingLeft: 0 }}>
          <b>{t("settings-themecolor")}</b> {themeCurrentColor}{" "}
          <FontAwesomeIcon
            icon={faPaintRoller}
            size="lg"
            onClick={() => {
              setIsColorPickerOpen(true);
            }}
            style={{
              paddingRight: 10,
              paddingLeft: 10,
              cursor: "pointer",
            }}
          />
        </dd>
        <dd style={{ paddingBottom: 10, paddingLeft: 0 }}>
          <b style={{ paddingBottom: 10 }}>Calendar - Start Week</b>
          <div
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              width: 175,
              marginTop: 10,
            }}
          >
            <Select
              options={optionsCalendarStartWeekDay}
              value={optionsCalendarStartWeekDay.find(
                (option) => option.value === calendarStartWeekDay
              )}
              // value={plannerStartWeekDay}
              // placeholder={t("planner-calendar-selectduration")}
              onChange={(e) => handleCalendarStartWeekDay(e)}
              //@ts-ignore
              style={{ width: 150 }}
            />
          </div>
        </dd>
        <dd style={{ paddingBottom: 10, paddingLeft: 0 }}>
          <b>{t("settings-joined")}</b>{" "}
          {moment(new Date(memberAdded.seconds * 1000)).format("MMMM DD, YYYY")}{" "}
        </dd>
        {isNative ? (
          <dd
            style={{
              paddingBottom: 10,
              paddingLeft: 0,
              textDecoration: "underline",
              // fontSize: 14,
              color: "blue",
              // marginTop: -5,
            }}
            onClick={openDeviceSettings}
          >
            <b>{t("settings-opendevicesettings")}</b>{" "}
          </dd>
        ) : null}

        <dd style={{ paddingBottom: 10, paddingLeft: 0 }}>
          {isOnline ? (
            <div
              style={{
                padding: 10,
                paddingLeft: 0,
                textDecoration: "underline",
                cursor: "pointer",
                //  fontSize: 14,
                color: "blue",
                //  marginTop: -5,
              }}
              onClick={reloadData}
            >
              {" "}
              <b>{t("settings-reload-cache")}</b>{" "}
              <FontAwesomeIcon
                icon={faRefresh}
                size="lg"
                style={{
                  paddingRight: 5,
                  paddingLeft: 10,
                  cursor: "pointer",
                  color: "black",
                }}
              />{" "}
            </div>
          ) : null}
        </dd>

        <dd style={{ paddingBottom: 10, paddingLeft: 0 }}>
          <b>{t("settings-subscription-active")}</b>{" "}
          {!isPro || isPayPalSubscriber || isPayPalLifetime()
            ? t("settings-on")
            : t("settings-off")}
        </dd>

        {isPayPalLifetime() ? (
          <dd style={{ paddingBottom: 10, paddingLeft: 0 }}>
            <b>Lifetime:</b>{" "}
            {isPayPalLifetime() ? t("settings-on") : t("settings-off")}
          </dd>
        ) : null}

        {isPro && isAlohaPlanner ? (
          <dd
            style={{
              paddingBottom: 10,
              paddingLeft: 0,
              textDecoration: "underline",
              //  fontSize: 14,
              color: "blue",
              //  marginTop: -5,
            }}
            onClick={openGoogleGDPR}
          >
            Reset Google Ad Settings
          </dd>
        ) : null}
        {/*}   <dd style={{paddingBottom: 10, paddingLeft: 0}}><b>Expiration:</b> {SubscriptDate !== undefined ? (convertDate(SubscriptDate)): 'Not Available'}</dd>*/}

        <div
          style={{
            display: "flex",
            alignItems: "left",
            justifyContent: "left",
            padding: 10,
            marginTop: 0,
            marginBottom: 0,
            flexDirection: "column",
          }}
        >
          {isContactSupport ? (
            <div style={{ backgroundColor: "lavender", paddingTop: 20 }}>
              <button
                style={{
                  float: "right",
                  backgroundColor: "transparent",
                  borderStyle: "none",
                  cursor: "pointer",
                  marginTop: -10,
                  marginRight: 10,
                  borderRadius: 20,
                }}
                onClick={() => setIsContactSupport(false)}
              >
                <FontAwesomeIcon icon={faClose} size="1x" />
              </button>
              <FeedbackWidget close={() => setIsContactSupport(false)} />
            </div>
          ) : (
            <button
              style={{
                fontSize: "inherit",
                fontFamily: "inherit",
                width: 200,
                color: "white",
                backgroundColor: "blue",
                borderColor: "transparent",
                padding: 10,
                borderRadius: 10,
                paddingRight: 15,
                marginTop: 0,
                cursor: "pointer",
              }}
              onClick={() => setIsContactSupport(true)}
            >
              {t("introhelp-button-contact")}
            </button>
          )}

          {isNative ? (
            <div style={{ paddingTop: 20 }}>
              <button
                onClick={ReviewApp}
                style={{
                  fontSize: "inherit",
                  fontFamily: "inherit",
                  width: 200,
                  color: "white",
                  backgroundColor: "blue",
                  borderColor: "transparent",
                  padding: 10,
                  borderRadius: 10,
                  paddingRight: 15,
                  marginTop: 0,
                  cursor: "pointer",
                }}
              >
                {t("settings-review-button")} App
              </button>
              {isIOS ? (
                <div>
                  <button
                    onClick={restoreApple}
                    style={{
                      fontSize: "inherit",
                      fontFamily: "inherit",
                      width: 200,
                      color: "white",
                      backgroundColor: "blue",
                      borderColor: "transparent",
                      padding: 10,
                      borderRadius: 10,
                      paddingRight: 15,
                      marginTop: 20,
                      cursor: "pointer",
                    }}
                  >
                    Restore
                  </button>
                  {isRestoring ? (
                    isRestoreCheck ? (
                      <div style={{ paddingLeft: 70, paddingTop: 10 }}>
                        {" "}
                        <Spinner />
                      </div>
                    ) : (
                      <p style={{ fontSize: 14, padding: 20 }}>
                        {t("settings-restore-current")}
                      </p>
                    )
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : null}

          <div style={{ paddingTop: 20 }}>
            {isNative && !isPayPalSubscription() ? (
              <button
                onClick={handleBuyNow}
                style={{
                  fontSize: "inherit",
                  fontFamily: "inherit",
                  width: 200,
                  color: "white",
                  backgroundColor: "blue",
                  borderColor: "transparent",
                  padding: 10,
                  borderRadius: 10,
                  paddingRight: 15,
                  marginTop: 0,
                  cursor: "pointer",
                }}
              >
                {t("settings-subscription-button")}
              </button>
            ) : null}
            {isPayPalSubscription() ? (
              <Link
                to={{
                  //@ts-ignore
                  pathname: `https://www.paypal.com/myaccount/autopay/connect/${user.user.payPal.subscriptionID}`,
                }}
                target="_blank"
              >
                <button
                  // onClick={() => setCancelPayPalConfirmModalOpen(true)}
                  style={{
                    fontSize: "inherit",
                    fontFamily: "inherit",
                    width: 200,
                    color: "white",
                    backgroundColor: "blue",
                    borderColor: "transparent",
                    padding: 10,
                    borderRadius: 10,
                    paddingRight: 15,
                    marginTop: 0,
                    cursor: "pointer",
                  }}
                >
                  {t("buynow-subscription-management")} <br /> (PayPal)
                </button>
              </Link>
            ) : null}
          </div>
        </div>
        {/*}     <BuyNowModal
          isBuyNow={isBuyNow}
          close={() => setIsBuyNow(false)}
          isExpired={isExpired}
          isOnline={isOnline}
          user={user.user}

                />*/}
        {!isNative && isExpired ? (
          <button
            onClick={handleBuyNow}
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              width: 200,
              color: "white",
              backgroundColor: "blue",
              borderColor: "transparent",
              padding: 10,
              marginLeft: 10,
              borderRadius: 10,
              paddingRight: 15,
              marginTop: 0,
              cursor: "pointer",
            }}
          >
            {t("settings-subscription-button")}
          </button>
        ) : null}

        <hr></hr>

        <h3>
          {t("settings-shared-group")} {useGroup()?.name}{" "}
        </h3>
        {group.ownerID === currentUserID ? (
          <dd
            style={{
              fontSize: 20,
              fontWeight: "bold",
              color: "coral",
              marginTop: -15,
              paddingBottom: 20,
              paddingLeft: 0,
            }}
          >
            {t("settings-group-owner")}
          </dd>
        ) : null}
        <p style={{ marginTop: -10 }}>{t("settings-group-info")}</p>
        {groupUser.map((member) => (
          <div
            key={member.id}
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: 20,
              paddingBottom: 10,
              paddingLeft: 20,
            }}
          >
            <div style={{ width: "75%", paddingLeft: 20 }}>
              {" "}
              {member.name} {member.owner ? " (owner)" : ""}
            </div>
            <div>
              {!member.owner && group.ownerID === currentUserID ? (
                <button
                  style={{
                    marginRight: 10,
                    backgroundColor: "white",
                    borderColor: "transparent",
                    color: "red",
                  }}
                  onClick={() => deleteMemberByOwner(member.id, member.userID)}
                >
                  <FontAwesomeIcon icon={faTrash} size="1x" />
                </button>
              ) : null}
            </div>
            {checkMemberID(member.name, member.id, member.owner)}
          </div>
        ))}
      </dl>
      {group.ownerID === currentUserID ? (
        <>
          {" "}
          <hr></hr>
          <h3 id="invite">{t("settings-invite")}</h3>
          <p style={{ marginTop: -10 }}>{t("settings-invite-info")}</p>
          {isOnline ? (
            <div style={{ paddingLeft: 20, paddingBottom: 20 }}>
              <InviteMember />
            </div>
          ) : (
            <p style={{ color: "red" }}>{t("settings-online-warning")}</p>
          )}
        </>
      ) : null}
      <>
        {" "}
        <hr></hr>
        <h3 style={{ paddingTop: 15 }}>{t("settings-joingroup")}</h3>
        <p style={{ marginTop: -10 }}>{t("settings-join-info")}</p>
        <div style={{ paddingLeft: 10 }}>
          {isOnline ? (
            <form onSubmit={checkInvitationCode}>
              <div style={{ paddingBottom: 15 }} className={classes.formGroup}>
                <label htmlFor="name">{t("settings-join-code")}</label>
                <input
                  id="name"
                  minLength={4}
                  required
                  onChange={(e) => setInviteCode(e.target.value)}
                  style={{ fontSize: 20, padding: 10, width: "85%" }}
                />
              </div>
              <button
                type="submit"
                className={classes.submit}
                style={{
                  fontFamily: "inherit",
                  fontSize: "inherit",
                  backgroundColor: "blue",
                  color: "white",
                  borderRadius: 10,
                  border: 0,
                  padding: 10,
                  fontWeight: "bold",
                  paddingLeft: 20,
                  paddingRight: 20,
                  width: 150,
                  cursor: "pointer",
                }}
              >
                {t("settings-join-button")}
              </button>
            </form>
          ) : (
            <p style={{ color: "red" }}>{t("settings-online-warning")}</p>
          )}
        </div>{" "}
      </>
      <div style={{ paddingTop: 20, paddingBottom: 20 }}>
        <hr />
        {!isNative ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: 20,
              alignItems: "left",
              justifyContent: "left",
            }}
          >
            <h3>{t("settings-app-downloads")}</h3>
            <Link
              to={{
                pathname:
                  "https://apps.apple.com/us/app/dope-notes-life-organizer/id1631683070",
              }}
              target="_blank"
            >
              {" "}
              <div
                style={{
                  padding: 10,
                  fontSize: 16,
                  marginTop: 0,
                  color: "white",
                  borderRadius: 10,
                  textAlign: "center",
                  backgroundColor: "blue",
                  fontWeight: "bold",
                  width: 200,
                  cursor: "pointer",
                }}
              >
                {" "}
                <DiApple /> Apple IOS{" "}
              </div>
            </Link>
            <Link
              to={{
                pathname:
                  "https://play.google.com/store/apps/details?id=com.holmgren.dopenotes",
              }}
              target="_blank"
            >
              {" "}
              <div
                style={{
                  padding: 10,
                  fontSize: 16,
                  marginTop: 20,
                  color: "white",
                  borderRadius: 10,
                  textAlign: "center",
                  backgroundColor: "blue",
                  fontWeight: "bold",
                  width: 200,
                  cursor: "pointer",
                }}
              >
                {" "}
                <DiAndroid /> Google Android
              </div>
            </Link>
          </div>
        ) : (
          <>
            {" "}
            <h3>{t("settings-remove-notifications")}</h3>
            <p style={{ marginTop: -10 }}>
              {t("settings-remove-notifications-info")}{" "}
            </p>
            <div
              onClick={() => setCancelReminderConfirmModalOpen(true)}
              style={{
                padding: 10,
                fontSize: "inherit",
                marginTop: 20,
                color: "white",
                borderRadius: 10,
                textAlign: "center",
                backgroundColor: "blue",
                fontWeight: "bold",
                width: 150,
                cursor: "pointer",
              }}
            >
              {" "}
              {t("common-button-remove")}
            </div>
          </>
        )}
        <div style={{ paddingTop: 20 }} />
        {isOnline ? (
          <>
            {" "}
            <hr />
            {/*} <h3>{t("settings-account-related")}</h3>*/}
            <button
              onClick={() => setConfirmModalOpen(true)}
              title="Permanently Remove Account"
              style={{
                fontFamily: "inherit",
                fontSize: "inherit",
                marginTop: 20,
                color: "#1e79c3",
                cursor: "pointer",
                backgroundColor: "transparent",
                border: 0,
              }}
              className={classes.logout}
            >
              <FontAwesomeIcon icon={faTrash} size="lg" />{" "}
              {t("settings-permanently-remove")}
            </button>{" "}
            <br />
            <button
              onClick={LogOutClearCache}
              title="Log out"
              style={{
                fontFamily: "inherit",
                fontSize: "inherit",
                marginTop: 40,
                cursor: "pointer",
                color: "#1e79c3",
                backgroundColor: "transparent",
                border: 0,
              }}
              className={classes.logout}
            >
              <FontAwesomeIcon icon={faSignOut} size="lg" />{" "}
              {t("settings-logout")}
            </button>
          </>
        ) : null}
        <div
          style={{
            paddingBottom: 100,
            overflowWrap: "break-word",
            textAlign: "center",
            paddingTop: 40,
          }}
        >
          &copy; Copyright {currentYear}. All Rights Reserved. Aloha Planner,
          LLC{" "}
        </div>
      </div>
      <Modal
        isOpen={isThemeEditOpen}
        onRequestClose={close}
        style={{ overlay: { zIndex: 1000 } }}
      >
        <Modal.Body>
          <div style={{ padding: 20 }}>
            <div
              style={{
                paddingLeft: 30,
                paddingRight: 30,
                paddingBottom: 10,
                border: 1,
                borderColor: "grey",
                borderRadius: 10,
                backgroundColor: "snow",
                fontWeight: "bold",
                color: "grey",
                boxShadow: "1px 5px 9px lightgrey",
                fontSize: 16,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "right",
                  justifyContent: "right",
                  marginTop: 0,
                  marginRight: -10,
                }}
              >
                <FontAwesomeIcon
                  icon={faClose}
                  size="1x"
                  onClick={close}
                  style={{ marginTop: 15, cursor: "pointer" }}
                />
              </div>
              <form onSubmit={updateTheme}>
                <label style={{ fontSize: 22 }}>{t("settings-pickfont")}</label>
                <select
                  value={themeFont != undefined ? themeFont : themeCurrentFont}
                  onChange={changeThemeFont}
                  style={{ fontSize: 14, marginTop: 10, padding: 10 }}
                >
                  <option value="Ariel">Ariel</option>
                  <option value="CabinSketch">Cabin Sketch</option>
                  <option value="Caveat">Caveat</option>
                  <option value="DancingScript">Dancing Script</option>
                  <option value="Fondamento">Fondamento</option>
                  {/*}  <option value="HomemadeApple">Homemade Apple</option>*/}
                  <option value="IndieFlower">Indie Flower</option>
                  <option value="JosefinSlab">Josefin Slab</option>
                  <option value="Kalam">Kalam</option>
                  <option value="OleoScript">Oleo Script</option>
                  <option value="Orbitron">Orbitron</option>
                  <option value="PatrickHand">Patrick Hand*</option>
                  <option value="Roboto">Roboto</option>
                  <option value="ShadowsIntoLight">Shadows Into Light</option>
                  <option value="SourceCodePro">Source Code Pro*</option>
                  <option value="SpaceGrotesk">Space Grotesk</option>
                  <option value="Ubuntu">Ubuntu*</option>
                  <option value="VT323">VT323*</option>
                  <option value="XanhMono">Xanh Mono</option>
                </select>

                <label
                  style={{
                    fontSize: 18,
                    paddingTop: 20,
                    paddingBottom: 20,
                    fontFamily: themeFont,
                  }}
                >
                  {t("settings-pickfont-info")}
                </label>

                {UpdateBTN ? (
                  <input
                    type="submit"
                    value="Update"
                    style={{ borderRadius: 40 }}
                  />
                ) : null}
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ConfirmationModal
        isOpen={confirmModalOpen}
        text={t("settings-delete-account-warning")}
        onClickCancel={() => setConfirmModalOpen(false)}
        onClickConfirm={deleteAccountCheck}
      />

      <ConfirmationModal
        isOpen={cancelReminderConfirmModalOpen}
        text={t("settings-delete-notification-warning")}
        onClickCancel={() => setCancelReminderConfirmModalOpen(false)}
        onClickConfirm={notificationCancel}
      />
    </article>
  );
};

import React, { useEffect, useState, useRef } from "react";
import { Modal, Spinner } from "Common";
//import { ReadRecipeLanding } from "Recipes/RecipeModal/Read/ReadRecipeLanding";

import toast, { Toaster } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faClose,
  faPrint,
  faImage,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useItemsToDo } from "ListToDo/useItemsToDo";
import { IdeaGenerator } from "Notes/IdeaGenerator";

export const ListMagic = ({}) => {
  const [toDoName, setToDoName] = useState("");
  // const [recipeInstructions, setRecipeInstructions] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [convertedToDo, setConvertedToDo] = useState(null);
  const [isShowSearch, setIsShowSearch] = useState(true);
  const { t } = useTranslation();
  const { createNewListandItemsAI } = useItemsToDo();
  const [isAddingList, setIsAddingList] = useState(false);
  const [message, setMessage] = useState("New List Created");

  useEffect(() => {
    // console.log("Updated convertedToDo:", convertedToDo);
  }, [convertedToDo]);

  var resultArray;

  const convertLanguageCode = (data) => {
    if (data == "fr") {
      return "french";
    } else if (data == "es") {
      return "spanish";
    } else if (data == "de") {
      return "german";
    } else if (data == "en") {
      return "english";
    } else if (data == "jp") {
      return "japanese";
    } else if (data == "kr") {
      return "korean";
    } else if (data == "ar") {
      return "arabic";
    } else if (data == "se") {
      return "swedish";
    } else if (data == "cn") {
      return "chinese";
    } else {
      return "english";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsShowSearch(false);
    setIsModalOpen(true);
    const getlanguage = localStorage.getItem("userLanguage");
    var language =
      getlanguage != undefined ? convertLanguageCode(getlanguage) : "english";

    //  console.log("which language", language);
    try {
      const response = await fetch(
        "https://us-central1-dopenotes-20ee4.cloudfunctions.net/chatgpt/todo",
        //    "http://127.0.0.1:5001/dopenotes-20ee4/us-central1/todov1_0",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ toDoName, language }),
        }
      );

      const data = await response.json();
      if (response.status !== 200) {
        setIsShowSearch(true);
        setIsLoading(false);
        setIsModalOpen(false);
        throw (
          data.error ||
          new Error(`Request failed with status ${response.status}`)
        );
      }

      try {
        // console.log("what is the data", data);
        resultArray = JSON.parse("[" + data.result + "]");
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setIsModalOpen(false);
        setIsShowSearch(true);
      }
      setIsShowSearch(false);
      //  resultArray = Object.values(JSON.parse("[" + data.result + "]"));

      //  console.log("what is it", resultArray);

      var newVar = resultArray;
      //   addRecipe(resultArray);
      // console.log("what is it newVar", newVar[0]);
      setConvertedToDo(newVar[0]);
      //console.log("what is it converted", convertedToDo);
      setIsLoading(false);
      setMessage("New List Created");
      //sting to an array
    } catch (error) {
      console.error("Error retrieving data:", error);
      setIsLoading(false);
      setMessage("Error retrieving data from ChatGPT.  Please try again.");
    }
  };

  const CloseModal = () => {
    setIsModalOpen(false);
    setToDoName("");
    setIsLoading(false);
    setConvertedToDo([]);
    // setRecipeInstructions("");
    setIsShowSearch(false);
  };

  const print = () => window.print();

  const AddToDoList = () => {
    setIsAddingList(true);
    const category = { name: toDoName, color: "purple", order: 10 };
    createNewListandItemsAI(category, convertedToDo)
      .then(() => {
        // console.log("Category and items added successfully");
        setIsAddingList(false);
        setIsModalOpen(false);
      })
      .catch((error) =>
        console.error("Error adding category and items:", error)
      );
    // setIsModalOpen(false);
    // setIsLoading(false);
    setToDoName("");
  };

  const removeItem = (index) => {
    const newToDoList = [...convertedToDo];
    newToDoList.splice(index, 1);
    // Assuming you have a state setter named setConvertedToDo
    setConvertedToDo(newToDoList);
  };

  return (
    <div
      style={{
        paddingTop: 10,
        paddingBottom: 10,
        display: "flex",
      }}
    >
      {isShowSearch ? (
        <div
          style={{
            padding: 20,
            // backgroundColor: "lightgreen",
            borderRadius: 5,
          }}
        >
          {/*  <div
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "right",
                marginTop: 0,
                marginRight: 5,
              }}
            >
              <FontAwesomeIcon
                icon={faClose}
                size="xl"
                onClick={() => setIsShowSearch(false)}
                style={{ marginTop: -10, cursor: "pointer" }}
              />
              
            </div>*/}
          <h2 className="h2SlideIn">List Magic </h2>
          {/*}   <div
            style={{
              textAlign: "center",
              marginTop: -20,
              paddingBottom: 20,
              fontSize: 14,
            }}
          >
            Use ChatGPT A.I. to create a checklist
          </div>*/}
          <form onSubmit={handleSubmit}>
            <label style={{ fontSize: 18, textAlign: "center" }}>
              {t("chatgpt-todo-whatitems")} <br />
              <br />
            </label>
            <div style={{ padding: 10 }}>
              <input
                type="text"
                placeholder="e.g. Spring House Cleaning"
                value={toDoName}
                minLength={1}
                maxLength={100}
                required
                style={{
                  fontSize: 16,
                  fontFamily: "inherit",
                  padding: 10,
                  cursor: "pointer",
                  width: "90%",
                }}
                onChange={(e) => setToDoName(e.target.value)}
              />
            </div>
            <div style={{ paddingTop: 10 }} />
            <button
              disabled={toDoName != "" ? false : true}
              style={{
                fontSize: 16,
                fontFamily: "inherit",
                paddingLeft: 30,
                paddingRight: 30,
                paddingTop: 10,
                paddingBottom: 10,
                border: 0,
                color: "white",
                backgroundColor: "blue",
                cursor: "pointer",
                borderRadius: 10,
                opacity: toDoName != "" ? 1 : 0.25,
              }}
              type="submit"
            >
              Generate
            </button>
            <Link to="/todo">
              <span
                // onClick={() => setIsShowSearch(false)}
                style={{
                  paddingLeft: 10,
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "blue",
                }}
              >
                Go To Tasks/To-Dos
              </span>
            </Link>
            <p>{t("chatgpt-todo-examples")}</p>
            <p style={{ fontSize: 12, lineHeight: 1.6 }}>
              {t("recipelist-chatgpt-disclaimer")}{" "}
              <Link
                to={{
                  pathname: "https://openai.com/policies/privacy-policy",
                }}
                target="_blank"
              >
                <span
                  style={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {t("recipelist-chatgpt-policylink")}
                </span>
              </Link>
              <div style={{ paddingBottom: 200 }} />
            </p>
          </form>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            lineHeight: "26px",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: "95%",
          }}
        >
          <h2>{message}</h2>
          <Link to="/todo">
            <br />
            <button
              className="metroButton"
              style={{
                fontSize: 16,

                fontFamily: "inherit",
                paddingLeft: 30,
                paddingRight: 30,
                paddingTop: 10,
                paddingBottom: 10,
                border: 0,
                color: "white",
                backgroundColor: "blue",
                cursor: "pointer",
                width: 200,
              }}
              //  onClick={() => setIsShowSearch(true)}
            >
              Go To Tasks
            </button>
          </Link>
          <br />
          <button
            className="metroButton"
            style={{
              fontSize: 16,
              width: 200,
              fontFamily: "inherit",
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 10,
              paddingBottom: 10,
              border: 0,
              color: "white",
              backgroundColor: "blue",
              cursor: "pointer",
            }}
            onClick={() => setIsShowSearch(true)}
          >
            Create Another
          </button>
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={CloseModal}
        style={{ overlay: { zIndex: 100 } }}
      >
        {!isLoading ? (
          <div style={{ padding: 20 }}>
            {/*} <div
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "right",
                marginTop: 0,
                marginRight: 5,
              }}
            >
              <FontAwesomeIcon
                icon={faClose}
                size="2x"
                onClick={CloseModal}
                style={{ marginTop: -10, cursor: "pointer" }}
              />
            </div>*/}

            <h2 style={{ textAlign: "center" }}>
              {toDoName.toUpperCase()} CHECKLIST
            </h2>
            <label style={{ textAlign: "center" }}>
              {t("chatgpt-todo-previewpage")}
            </label>
            <hr />
            {convertedToDo && convertedToDo.length > 0
              ? convertedToDo.map((toDo, index) => (
                  <ol key={index}>
                    <span style={{}}>
                      {toDo.name != undefined ? toDo.name : "Not Available"} -
                    </span>
                    <span>
                      {toDo.notes != undefined ? toDo.notes : "Not Available"}
                    </span>
                    {!isAddingList ? (
                      <span
                        onClick={() => removeItem(index)}
                        style={{ paddingLeft: 10 }}
                        className="icofont-trash"
                      />
                    ) : null}
                  </ol>
                ))
              : null}
            <div
              style={{
                display: "flex",
                paddingTop: 20,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isAddingList ? (
                <Spinner />
              ) : (
                <>
                  {" "}
                  <button
                    style={{
                      fontSize: "inherit",
                      fontFamily: "inherit",
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 10,
                      paddingBottom: 10,
                      color: "white",
                      backgroundColor: "blue",
                      cursor: "pointer",
                      borderRadius: 40,
                    }}
                    onClick={() => AddToDoList()}
                  >
                    {t("common-button-save")}
                  </button>
                </>
              )}
              <button
                style={{
                  fontSize: "inherit",
                  fontFamily: "inherit",
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 10,
                  paddingBottom: 10,
                  cursor: "pointer",
                  backgroundColor: "transparent",
                  color: "blue",
                  border: 0,
                }}
                onClick={() => CloseModal()}
              >
                {t("common-button-cancel")}
              </button>
            </div>
            <div style={{ paddingBottom: 180 }} />
          </div>
        ) : null}

        {isLoading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
              color: "black",
            }}
          >
            <label style={{ padding: 20 }}>{t("chatgpt-todo-searching")}</label>
            <Spinner />
            <label style={{ padding: 20, fontSize: 16 }}>
              {t("recipelist-chatgpt-results-searchingbottom")}
            </label>
            <button
              style={{
                textDecoration: "underline",
                color: "blue",
                cursor: "pointer",
                paddingTop: 20,
                fontSize: 20,
                fontFamily: "inherit",
                backgroundColor: "transparent",
                border: 0,
              }}
              onClick={CloseModal}
            >
              {t("common-button-cancel")}
            </button>
          </div>
        ) : null}
      </Modal>
    </div>
  );
};

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const DeviceRedirect = () => {
  const history = useHistory();

  useEffect(() => {
    const userAgent = window.navigator.userAgent;

    // Check if user agent string contains iOS, Android, or any other device identifier
    if (userAgent.match(/iPad|iPhone|iPod/i)) {
      // Redirect to iOS-specific route
      //alert("what is it");
      window.location.href =
        "https://apps.apple.com/us/app/aloha-planner-note-organizer/id1631683070";
    } else if (userAgent.match(/Android/i)) {
      // Redirect to Android-specific route
      window.open(
        " https://play.google.com/store/apps/details?id=com.holmgren.dopenotes"
      );
    } else {
      // Redirect to other device route
      history.push("/");
    }
  }, [history]);

  return <div>Redirecting...</div>;
};

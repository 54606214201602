export const commonGroceryItemsJapan = [
  { category: "100100", name: "ベビーフード" }, // Baby Food
  { category: "100100", name: "ベビーワイプ" }, // Baby Wipes
  { category: "100100", name: "おむつ" }, // Diapers
  { category: "100100", name: "粉ミルク" }, // Baby Formula
  { category: "100100", name: "ベビーローション" }, // Baby Lotion
  { category: "100200", name: "パン" }, // Bread
  { category: "100200", name: "クロワッサン" }, // Croissants
  { category: "100200", name: "食パン" }, // Sliced Bread
  { category: "100200", name: "全粒粉パン" }, // Whole Wheat Bread
  { category: "100200", name: "メロンパン" }, // Melon Bread
  { category: "100300", name: "小麦粉" }, // Flour
  { category: "100300", name: "砂糖" }, // Sugar
  { category: "100300", name: "ベーキングパウダー" }, // Baking Powder
  { category: "100300", name: "バニラエッセンス" }, // Vanilla Essence
  { category: "100300", name: "アーモンドパウダー" }, // Almond Powder
  { category: "100400", name: "ミネラルウォーター" }, // Mineral Water
  { category: "100400", name: "オレンジジュース" }, // Orange Juice
  { category: "100400", name: "コーヒー" }, // Coffee
  { category: "100400", name: "お茶" }, // Tea
  { category: "100400", name: "炭酸飲料" }, // Soda
  { category: "100500", name: "洗剤" }, // Detergent
  { category: "100500", name: "食器用洗剤" }, // Dishwashing Liquid
  { category: "100500", name: "多目的クリーナー" }, // All-purpose Cleaner
  { category: "100500", name: "ペーパータオル" }, // Paper Towels
  { category: "100500", name: "ゴミ袋" }, // Trash Bags
  { category: "100600", name: "トマトソース" }, // Tomato Sauce
  { category: "100600", name: "醤油" }, // Soy Sauce
  { category: "100600", name: "マヨネーズ" }, // Mayonnaise
  { category: "100600", name: "ドレッシング" }, // Dressing
  { category: "100600", name: "ケチャップ" }, // Ketchup
  { category: "100700", name: "牛乳" }, // Milk
  { category: "100700", name: "バター" }, // Butter
  { category: "100700", name: "チーズ" }, // Cheese
  { category: "100700", name: "ヨーグルト" }, // Yogurt
  { category: "100700", name: "クリーム" }, // Cream
  { category: "100800", name: "冷凍ピザ" }, // Frozen Pizza
  { category: "100800", name: "冷凍野菜" }, // Frozen Vegetables
  { category: "100800", name: "アイスクリーム" }, // Ice Cream
  { category: "100800", name: "冷凍食品" }, // Frozen Foods
  { category: "100800", name: "冷凍フライドポテト" }, // Frozen Fries
  { category: "100900", name: "りんご" }, // Apples
  { category: "100900", name: "バナナ" }, // Bananas
  { category: "100900", name: "トマト" }, // Tomatoes
  { category: "100900", name: "レタス" }, // Lettuce
  { category: "100900", name: "にんじん" }, // Carrots
  { category: "100900", name: "玉ねぎ" }, // Onions
  { category: "100900", name: "ピーマン" }, // Bell Peppers
  { category: "100900", name: "じゃがいも" }, // Potatoes
  { category: "100900", name: "にんにく" }, // Garlic
  { category: "100900", name: "ほうれん草" }, // Spinach
  { category: "101100", name: "牛肉" }, // Beef
  { category: "101100", name: "豚肉" }, // Pork
  { category: "101100", name: "鶏肉" }, // Chicken
  { category: "101100", name: "鶏の唐揚げ" }, // Fried Chicken
  { category: "101100", name: "ベーコン" }, // Bacon
  { category: "101200", name: "頭痛薬" }, // Headache Medicine
  { category: "101200", name: "絆創膏" }, // Band-Aids
  { category: "101200", name: "体温計" }, // Thermometer
  { category: "101200", name: "消毒液" }, // Disinfectant
  { category: "101200", name: "マスク" }, // Masks
  { category: "101500", name: "ご飯" }, // Rice
  { category: "101500", name: "パスタ" }, // Pasta
  { category: "101500", name: "オリーブオイル" }, // Olive Oil
  { category: "101500", name: "缶詰" }, // Canned Goods
  { category: "101500", name: "味噌" }, // Miso
  { category: "101700", name: "ペットフード（犬用）" }, // Dog Food
  { category: "101700", name: "ペットフード（猫用）" }, // Cat Food
  { category: "101700", name: "猫砂" }, // Cat Litter
  { category: "101700", name: "ペットのおもちゃ" }, // Pet Toys
  { category: "101700", name: "ペットシャンプー" }, // Pet Shampoo
  { category: "101800", name: "鮭" }, // Salmon
  { category: "101800", name: "エビ" }, // Shrimp
  { category: "101800", name: "たら" }, // Cod
  { category: "101800", name: "イカ" }, // Squid
  { category: "101800", name: "かに" }, // Crab
  { category: "101900", name: "ポテトチップス" }, // Potato Chips
  { category: "101900", name: "チョコレート" }, // Chocolate
  { category: "101900", name: "クッキー" }, // Cookies
  { category: "101900", name: "ドライフルーツ" }, // Dried Fruits
  { category: "101900", name: "ナッツ" }, // Nuts
  { category: "102000", name: "塩" }, // Salt
  { category: "102000", name: "こしょう" }, // Pepper
  { category: "102000", name: "カレー粉" }, // Curry Powder
  { category: "102000", name: "唐辛子" }, // Chili Pepper
  { category: "102000", name: "しょうが" }, // Ginger
  { category: "102500", name: "バジル" }, // Basil
  { category: "102500", name: "パセリ" }, // Parsley
  { category: "102500", name: "ローズマリー" }, // Rosemary
  { category: "102500", name: "タイム" }, // Thyme
  { category: "102500", name: "ミント" }, // Mint
  { category: "100200", name: "和菓子" }, // Wagashi (Japanese sweets)
  { category: "100200", name: "おせんべい" }, // Rice Crackers
  { category: "100200", name: "お餅" }, // Mochi
  { category: "100300", name: "抹茶" }, // Matcha
  { category: "100300", name: "寒天" }, // Agar
  { category: "100400", name: "日本酒" }, // Sake
  { category: "100400", name: "緑茶" }, // Green Tea
  { category: "100400", name: "ウーロン茶" }, // Oolong Tea
  { category: "100400", name: "ビール" }, // Beer
  { category: "100500", name: "洗濯洗剤" }, // Laundry Detergent
  { category: "100500", name: "食器洗い用スポンジ" }, // Dish Sponge
  { category: "100500", name: "トイレクリーナー" }, // Toilet Cleaner
  { category: "100500", name: "掃除用ブラシ" }, // Cleaning Brush
  { category: "100500", name: "フロアワイパー" }, // Floor Wiper
  { category: "100600", name: "味噌ダレ" }, // Miso Sauce
  { category: "100600", name: "ポン酢" }, // Ponzu Sauce
  { category: "100600", name: "たれ" }, // Sauce (Generic)
  { category: "100600", name: "酢" }, // Vinegar
  { category: "100600", name: "ソース" }, // Worcestershire Sauce
  { category: "100900", name: "しいたけ" }, // Shiitake Mushrooms
  { category: "100900", name: "きゅうり" }, // Cucumbers
  { category: "100900", name: "かぼちゃ" }, // Pumpkin
  { category: "100900", name: "なす" }, // Eggplant
  { category: "100900", name: "ピーマン" }, // Green Peppers
  { category: "100900", name: "さつまいも" }, // Sweet Potatoes
  { category: "100900", name: "大根" }, // Daikon Radish
  { category: "100900", name: "ねぎ" }, // Leek
  { category: "100900", name: "ごぼう" }, // Burdock Root
  { category: "100900", name: "とうもろこし" }, // Corn
  { category: "101100", name: "牛肉" }, // Beef
  { category: "101100", name: "豚肉" }, // Pork
  { category: "101100", name: "鶏肉" }, // Chicken
  { category: "101100", name: "鴨肉" }, // Duck
  { category: "101100", name: "馬肉" }, // Horse
  { category: "101100", name: "鹿肉" }, // Venison
  { category: "101100", name: "かに" }, // Crab
  { category: "101100", name: "えび" }, // Shrimp
  { category: "101100", name: "いか" }, // Squid
  { category: "101100", name: "たこ" }, // Octopus
  { category: "101200", name: "日本の漢方薬" }, // Japanese Herbal Medicine
  { category: "101200", name: "風邪薬" }, // Cold Medicine
  { category: "101200", name: "胃薬" }, // Stomach Medicine
  { category: "101200", name: "ビタミン剤" }, // Vitamins
  { category: "101200", name: "整腸剤" }, // Digestive Medicine
  { category: "101500", name: "そば" }, // Buckwheat Noodles
  { category: "101500", name: "うどん" }, // Udon Noodles
  { category: "101500", name: "ラーメン" }, // Ramen
  { category: "101500", name: "お米" }, // Rice
  { category: "101500", name: "もち米" }, // Glutinous Rice
  { category: "101700", name: "ペットフード（犬）" }, // Dog Food
  { category: "101700", name: "ペットフード（猫）" }, // Cat Food
  { category: "101700", name: "ペット用おやつ" }, // Pet Snacks
  { category: "101700", name: "ペット用品" }, // Pet Supplies
  { category: "101700", name: "ペット用シャンプー" }, // Pet Shampoo
  { category: "101800", name: "マグロ" }, // Tuna
  { category: "101800", name: "サーモン" }, // Salmon
  { category: "101800", name: "サバ" }, // Mackerel
  { category: "101800", name: "ホタテ" }, // Scallops
  { category: "101800", name: "アワビ" }, // Abalone
  { category: "101900", name: "和菓子" }, // Wagashi
  { category: "101900", name: "おせんべい" }, // Rice Crackers
  { category: "101900", name: "だんご" }, // Dango
  { category: "101900", name: "お餅" }, // Mochi
  { category: "101900", name: "ドライフルーツ" }, // Dried Fruits
  { category: "102000", name: "醤油" }, // Soy Sauce
  { category: "102000", name: "味噌" }, // Miso
  { category: "102000", name: "わさび" }, // Wasabi
  { category: "102000", name: "しょうが" }, // Ginger
  { category: "102000", name: "ごま油" }, // Sesame Oil
];

import { useState, useEffect, useRef, useCallback } from "react";
import { UserHabits } from "../UserTodayHabit/UserHabits.js";
import ReactCanvasConfetti from "react-canvas-confetti";

export const HabitSingelEvent = ({ data, stepCount, journal }) => {
  const [reset, setReset] = useState([]);

  //confetti

  const canvasStyles = {
    position: "fixed",
    pointerEvents: "none",
    width: "100%",
    height: "100%",
    top: 10,
    left: 0,
  };

  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  const FireIfCompleted = () => {
    fire();
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {};
  }, [data]);

  return (
    <div>
      <UserHabits
        key={data.id}
        data={data}
        reset={setReset}
        confetti={FireIfCompleted}
        // stepCount={stepCount}
        // journal={journal}
        isDetailView={true}
      />
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
    </div>
  );
};

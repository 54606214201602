import { useEffect, useState } from "react";

import { useUserContext } from "Authentication";
import { collection, query, onSnapshot } from "@firebase/firestore";
import { RecipeModel } from "./models";

export function useRecipeSubscription() {
  const [recipes, setRecipes] = useState<RecipeModel[]>([]);
  const { getRecipesCollection } = useUserContext();

  useEffect(() => {
    const plannerCollection = getRecipesCollection();
    const plannerQuery = query(plannerCollection);

    const unsubscribe = onSnapshot(
      plannerQuery,
      (snapshot) => {
        const addedEvents: RecipeModel[] = [];

        snapshot.docs.forEach((doc) => {
          const item = { ...doc.data(), id: doc.id } as RecipeModel;
          addedEvents.push(item);
        });

        setRecipes(addedEvents);
      },
      (error) => {
        window.location.reload();
      }
    );

    return () => unsubscribe();
  }, [getRecipesCollection]);

  // Convert date to epoch
  recipes.sort((a, b) =>
    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
  );

  return { recipes };
}

import { useRef, useState, useEffect, memo } from "react";

import { useUserContext } from "Authentication";
import { useItemsToDo } from "../../ListToDo/useItemsToDo";
import { doc, updateDoc } from "firebase/firestore";
import { ItemModel } from "../models";
import { Modal } from "Common";
import { ConfirmationModal } from "Common";
import { AddItemToDo, SelectRef } from "../../ListToDo/List/AddItem";
import { CategoryModalToDo } from "./CategoryModal";

import { ListActions } from "./ListActions";
import { CategoriesToDo } from "../../ListToDo/List/Categories/CategoriesToDo";
import { useCategorySubscriptionToDo } from "../../ListToDo/useCategorySubscriptionToDo";

import { FirebaseAnalytics } from "Config/FirebaseAnalytics";
//import { AddItemToDoHeader } from "./AddItem/AddItemToDoHeader";
import { useTranslation } from "react-i18next";
//import { BannerAd } from "GoogleAdSense/BannerAd";

export interface ListProps {
  addedItemsToDo: ItemModel[];
  allItemsToDo: ItemModel[];
  isExpired: boolean;
  isDarkMode: boolean;
}

export const ListToDo = memo((props: ListProps) => {
  const { addedItemsToDo, allItemsToDo, isExpired, isDarkMode } = props;
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isItemModalOpen, setIsItemModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ItemModel>();
  const [showCompleted, setShowCompleted] = useState(checkShowCommpleted);
  const [showAllCat, setShowAllCat] = useState(checkShowAllCat);
  const { batchRemoveItems } = useItemsToDo();
  const { getPrivateToDoItems } = useUserContext();
  const [selectedCategory, setSelectedCategory] = useState("");
  const inputRef = useRef<SelectRef>(null);
  const [isAddingNewItem, setIsAddingNewItem] = useState(false);
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const [isTabClosed, setIsTabClosed] = useState(true);
  const [toolBarPadding, setToolBarPadding] = useState(120);
  const [confirmationModal, setConfirmModalOpen] = useState(false);

  const completedItems = addedItemsToDo.filter((i) => i.completed);

  const { t } = useTranslation();

  const [hideAddInput, setHideAddInput] = useState(
    localStorage.getItem("hideToDoAddItem") != undefined
      ? JSON.parse(localStorage.getItem("hideToDoAddItem"))
      : true
  );

  const [addItemPadding, setAddItemPadding] = useState(hideAddInput ? -80 : 80);

  const toggleAddInput = () => {
    if (!hideAddInput) {
      setHideAddInput(true);
      localStorage.setItem("hideToDoAddItem", "true");
      setAddItemPadding(-80);
    } else {
      setHideAddInput(false);
      localStorage.setItem("hideToDoAddItem", "false");
      setAddItemPadding(80);
    }
  };

  const { categoriesToDo } = useCategorySubscriptionToDo();

  const handleOpenNewItem = () => {
    setIsAddingNewItem(true);
  };

  const handleClickItemCategory = (item: ItemModel) => {
    setSelectedItem(item);
    setIsCategoryModalOpen(true);
  };

  const handleClickItem = (item: ItemModel) => {
    setSelectedItem(item);
    setIsItemModalOpen(true);
  };

  const handleSelectCategory = async (category: string) => {
    const itemsRef = await getPrivateToDoItems();
    const itemRef = doc(itemsRef, selectedItem?.id);
    await updateDoc(itemRef, {
      category,
    });
  };

  const handleClearCompleted = async () => {
    const addedIds = completedItems.map((i) => i.id);
    await batchRemoveItems(addedIds);
    //console.log("clear completed")
  };

  const handleClickAddCategoryItem = (category: string | undefined) => {
    setSelectedCategory(category ?? "");
    inputRef.current?.focus();
  };

  const uncategorisedItems = addedItemsToDo.filter(
    (i) =>
      !i.category || categoriesToDo.findIndex((c) => c.id === i.category) < 0
  );

  const groupedItems = [...categoriesToDo]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((c) => ({
      ...c,
      items: addedItemsToDo
        .filter((i) => i.category === c.id)
        .sort((a, b) => a.lowerName.localeCompare(b.lowerName)),
    }));

  if (uncategorisedItems.length > 0) {
    groupedItems.splice(0, 0, {
      id: "-1",
      name: t("generic-list-general"),
      order: -1,
      colour: "var(--colour-uncategorised)",
      items: uncategorisedItems.sort((a, b) =>
        a.lowerName.localeCompare(b.lowerName)
      ),
    });
  }

  const exportMail = () => {
    const Cat = groupedItems.filter((i) => i.items.length >= 1);

    {
      /*   The below works but doesn't filter the items... just categories top level
 
 let filterResult = Cat.filter((component) => {
      return component.items.findIndex((sub) => {
       
        return sub.completed != true;
        
      }) >= 0;
    }); */
    }

    //window.location.href = window.location.href = `mailto:?subject=My%20Lists&body=My%20list%20%0A${Cat.map((a) =>  "%0A" + a.name + "%0A" + a.items.map((b) => "-" + b.name +  (b.notes != undefined ? ("%20-%20Notes:%20" + b.notes + "%0A"): "%0A")).join("")).join("")}`

    window.location.href =
      window.location.href = `mailto:?subject=My%20Lists&body=My%20list%20%0A${Cat.map(
        (a) =>
          "%0A" +
          a.name +
          "%0A" +
          a.items
            .map((b) =>
              b.completed != true
                ? "-" +
                  b.name +
                  (b.notes != undefined
                    ? "%20-%20Notes:%20" + b.notes + "%0A"
                    : "%0A")
                : null
            )
            .join("")
      ).join("")}`;
  };

  var checkShowCommpleted = JSON.parse(
    localStorage.getItem("ShowCompletedToDo")
  );
  var checkShowAllCat = JSON.parse(localStorage.getItem("ShowAllCatToDo"));

  //get tool bar padding for devices
  const getDeviceToolBarPadding = () => {
    // console.log("did I fire?");
    // console.log("what is the device width", window.innerWidth);
    var deviceWindow = window.innerWidth;
    var padding = 80;
    if (deviceWindow < 295) {
      padding = 90;
    }
    if (deviceWindow > 640) {
      padding = 80;
    }
    if (isTabClosed) {
      padding = 60;
    }
    setToolBarPadding(padding);
  };

  const lengthCheckedItems = completedItems.length;

  useEffect(() => {
    //console.log("showcompletedToDo", checkShowCommpleted)
    if (checkShowCommpleted != null) {
      setShowCompleted(checkShowCommpleted);
    } else {
      setShowCompleted(true);
    }

    getDeviceToolBarPadding();
    //console.log("showAllCatToDo", checkShowAllCat)
    //I'm using the checkShowCompleted so that they match

    if (checkShowAllCat != null) {
      setShowAllCat(checkShowAllCat);
    } else {
      setShowAllCat(true);
    }

    window.addEventListener("resize", getDeviceToolBarPadding);
    //window.scrollTo({ top: 0, behavior: "smooth" });

    return () => {
      window.removeEventListener("resize", getDeviceToolBarPadding);
    };
  }, [isTabClosed, isAddingNewItem, completedItems]);

  return (
    <div>
      <FirebaseAnalytics />

      <div
        style={{
          position: "fixed",
          // backgroundColor: "snow",
          opacity: 1,
          zIndex: 1,
          width: "100vw",
          marginTop: -15,
        }}
      >
        {!isTabClosed ? (
          <Modal
            isOpen={!isTabClosed}
            style={{
              overlay: {
                zIndex: 100,
                opacity: 0.98,
              },
            }}
          >
            <ListActions
              //  disableClearCompleted={completedItems.length === 0}
              items={allItemsToDo}
              showCompleted={showCompleted}
              setShowCompleted={setShowCompleted}
              showAllCat={showAllCat}
              setShowAllCat={setShowAllCat}
              clearCompleted={handleClearCompleted}
              emailList={exportMail}
              isDarkMode={isDarkMode}
              isAddingNewItem={isAddingNewItem}
              setIsAddingNewItem={() => setIsAddingNewItem(true)}
              isTabClosed={isTabClosed}
              setIsTabClosed={setIsTabClosed}
              LengthCheckedItems={lengthCheckedItems}
            />
          </Modal>
        ) : null}

        <div
          style={{
            //  filter: "invert(100%)",
            // backgroundColor: "ivory",
            marginLeft: -10,
            zIndex: 2,
            width: "100vw",
          }}
        >
          <div>
            {!hideAddInput ? (
              <AddItemToDo
                addedItemsToDo={addedItemsToDo}
                allItemsToDo={allItemsToDo}
                categoryToDo={selectedCategory}
                ref={inputRef}
                setCategoryToDo={setSelectedCategory}
                isDarkMode={isDarkMode}
                setIsAddingNewItemCompleted={() => setIsAddingNewItem(false)}
                disableCategorySelect={false}
              />
            ) : null}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              //  marginRight: 25,
              // maxWidth: 1048,
            }}
          >
            <button
              onClick={toggleAddInput}
              style={{
                maxWidth: "1000px",
                marginTop: 0,
                padding: 15,
                paddingBottom: 10,
                backgroundColor: hideAddInput ? "purple" : "lightblue",
                color: hideAddInput ? "white" : "navy",
                fontSize: 14,
                //  backgroundColor: "#1e79c3",
                //  fontWeight: "bold",

                cursor: "pointer",
                border: 0,
                //  boxShadow: "2px 3px 3px lightgrey",
                borderRadius: "0px 0px 10px 10px",

                marginLeft: 1,
              }}
            >
              {!hideAddInput
                ? t("generic-list-add-input-hide")
                : t("generic-list-add-input-show")}
            </button>
            {lengthCheckedItems >= 1 ? (
              <button
                onClick={() => setConfirmModalOpen(true)}
                style={{
                  // width: "90vw",
                  maxWidth: "1000px",
                  marginTop: 0,
                  padding: 15,
                  paddingBottom: 10,
                  backgroundColor: "coral",
                  fontSize: 14,
                  //  backgroundColor: "#1e79c3",
                  //  fontWeight: "bold",
                  color: "white",
                  cursor: "pointer",
                  border: 0,
                  // boxShadow: "2px 3px 3px lightgrey",
                  borderRadius: "0px 0px 10px 10px",

                  marginLeft: 1,
                }}
              >
                {t("generic-list-clear-checked")}
              </button>
            ) : null}
            <button
              onClick={() => setIsTabClosed(false)}
              style={{
                //  width: "90vw",
                maxWidth: "1000px",
                marginTop: 0,
                padding: 15,
                paddingBottom: 10,
                backgroundColor: "cornflowerblue",
                color: "white",
                cursor: "pointer",
                border: 0,
                //  boxShadow: "2px 3px 3px lightgrey",
                borderRadius: "0px 0px 10px 10px",
                fontSize: 14,
                marginLeft: 1,
              }}
            >
              Options
            </button>
          </div>
        </div>
      </div>

      <div style={{ paddingTop: toolBarPadding }} />
      <div style={{ paddingTop: toolBarPadding + addItemPadding + 50 }} />
      <h3 style={{ textAlign: "center" }}>{t("tasks-list-title")}</h3>

      <CategoriesToDo
        addedItems={addedItemsToDo}
        showCompleted={showCompleted}
        showAllCat={showAllCat}
        onClickAddCategoryItem={handleClickAddCategoryItem}
        onClickItem={handleClickItem}
        onClickItemCategory={handleClickItemCategory}
        isDarkMode={isDarkMode}
        addNewItem={handleOpenNewItem}
      />
      {/*</div>
  </div>*/}

      {isCategoryModalOpen ? (
        <CategoryModalToDo
          isOpen={isCategoryModalOpen}
          selectedCategoryId={selectedItem?.category}
          close={() => setIsCategoryModalOpen(false)}
          select={handleSelectCategory}
          isDarkMode={isDarkMode}
        />
      ) : null}

      <div style={{ paddingTop: 20 }} />

      {/*}  <ItemModalToDo
        isOpen={isItemModalOpen}
        item={selectedItem}
        close={() => setIsItemModalOpen(false)}
/>*/}
      <ConfirmationModal
        isOpen={confirmationModal}
        text={t("generic-list-action-del-warning")}
        onClickCancel={() => setConfirmModalOpen(false)}
        onClickConfirm={() => {
          handleClearCompleted();
          setConfirmModalOpen(false);
        }}
      />
      <div style={{ paddingBottom: 200 }} />
    </div>
  );
});

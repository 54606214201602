//can be deleted... thinking of using addplanner with this too.

import React, { useState, useEffect, useRef } from "react";
//import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  //faPaintRoller,
  //faTrash,
  faClose,
  //faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import { useUserContext } from "Authentication";
//import { useItemsToDo } from "ListToDo";
//import TimePicker from "react-time-picker";
//import DateTimePicker from 'react-datetime-picker';
import DatePicker from "react-date-picker";
//import "./DatePicker.css";
//import "./TimePicker.css";
import classes from "../../Reminders/reminder.module.css";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
//import { useReminderSubscription } from './useReminderSubscription';
//import { storage } from "Config";
//import { google, outlook, office365, yahoo, ics } from "calendar-link";
import { Button, Modal } from "Common";
//import { ConfirmationModal } from "Common";
import TextareaAutosize from "react-textarea-autosize";
//import { WeekDays } from "Habits/components/HabitsPage/style";
//import { CirclePicker, GithubPicker } from "react-color";
//import { DayOfTheWeek } from "Habits/components/DayOfTheWeek";
//import RRule from "rrule";
import { useTranslation } from "react-i18next";

export const ScheduleMeal = ({
  recipes,
  isOpen,
  preSelectedDate,
  updatePreSelectedDate,
  tempEvent,
  // setNewEvent,
  //title,
  //event,
  //item,
  closeModal,
  //start,
  //end,
  isEdit,
}) => {
  const [selectedRecipe, setSelectedRecipe] = useState("");
  const [mealType, setMealType] = useState("recipe");
  const [mealTime, setMealTime] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  // const [events, setEvents] = useState([]);
  const [isOther, setIsOther] = useState(false);
  const [valueName, setValueName] = useState("");
  const [valueNote, setValueNote] = useState("");
  const { addNewMealEvent, editMealEvent, deleteMealEvent } = useUserContext();
  const { t } = useTranslation();
  useEffect(() => {
    if (isEdit) {
      setIsOther(true);
      setMealType(tempEvent.type);
      setValueName(tempEvent.title);
      setValueNote(tempEvent.note);
      setMealTime(tempEvent.time);
    }

    //console.log("what is it here", tempEvent);
    // Fetch recipes from your API or source
    setSelectedDate(isEdit ? tempEvent.start : preSelectedDate);
  }, [tempEvent, isEdit]);

  const handleRecipeChange = (event) => {
    // Get the selected option index
    const selectedIndex = event.target.selectedIndex;

    // Extract recipe details from the selected option
    const selectedRecipeId = event.target.options[selectedIndex].value;
    const selectedRecipeName = event.target.options[selectedIndex].text;

    // Store both recipe.id and recipe.name in selectedRecipe
    setSelectedRecipe({ id: selectedRecipeId, name: selectedRecipeName });
  };

  const handleMealTypeChange = (event) => {
    setMealType(event.target.value);
    if (event.target.value != "recipe") {
      setIsOther(true);
    } else {
      setIsOther(false);
    }
  };

  const handleMealTimeChange = (event) => {
    setMealTime(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    updatePreSelectedDate(date);
  };

  const handleSaveMeal = () => {
    // Implement the logic to save the selected meal

    //console.log("valueName", valueName);
    //console.log("valueNote", valueNote);
    //console.log("Meal Type:", mealType);
    if (selectedRecipe != null) {
      //console.log("Selected Recipe:", selectedRecipe.name, selectedRecipe.id);
    }
    //console.log("Meal Time:", mealTime);
    //console.log("Selected Date:", selectedDate);

    if (mealType != "recipe" && isOther && valueName === "") {
      toast(t("schedulemeal-toast-add"), {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "pink",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
      return;
    }

    if (
      mealType === "recipe" &&
      (selectedRecipe.id === undefined || recipes.length == 0)
    ) {
      toast(t("schedulemeal-toast-recipe"), {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "pink",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
      return;
    }

    if (mealTime === "") {
      toast(t("schedulemeal-toast-mealtime"), {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "pink",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
      return;
    }

    isEdit
      ? editMealEvent(
          tempEvent.id,
          isOther ? "na" : selectedRecipe.id,
          isOther ? valueName : selectedRecipe.name,
          valueNote,
          mealType,
          mealTime,
          selectedDate,
          selectedDate,
          new Date()
        )
      : addNewMealEvent(
          isOther ? "na" : selectedRecipe.id,
          isOther ? valueName : selectedRecipe.name,
          valueNote,
          mealType,
          mealTime,
          selectedDate,
          selectedDate,
          new Date()
        );

    // setNewEvent(newEvent);

    // Add your save logic here

    //clear the var
    // setValueName("");
    // setValueNote("");
    // setMealType("recipe");
    // setMealTime("");
    // setIsOther(false);
    // setSelectedRecipe("");
    // setSelectedDate(preSelectedDate);
    closeModal();
  };

  return (
    <Modal isOpen={isOpen} style={{ overlay: { zIndex: 20 } }}>
      <Toaster containerStyle={{ marginTop: 225 }} />
      <div style={{ padding: 10 }}>
        <div
          style={{
            display: "flex",
            alignItems: "right",
            justifyContent: "right",
            marginTop: -25,
            // marginRight: -10,
          }}
        >
          {" "}
          <FontAwesomeIcon
            icon={faClose}
            size="1x"
            onClick={closeModal}
            style={{
              //  marginTop: 15,
              marginRight: 15,
              cursor: "pointer",
              color: "black",
            }}
          />
        </div>
        <h2>{t("schedulemeal-title")}</h2>

        <div>
          <label style={{ paddingTop: 0, paddingBottom: 5 }}>
            {t("schedulemeal-input-mealtype-label")}
          </label>
          <select
            onChange={handleMealTypeChange}
            value={mealType}
            style={{ fontFamily: "inherit", fontSize: 16, padding: 10 }}
          >
            <option value="recipe">{t("schedulemeal-input-mealtype1")}</option>
            <option value="takeout">{t("schedulemeal-input-mealtype2")}</option>
            <option value="dine">{t("schedulemeal-input-mealtype3")}</option>
            <option value="other">{t("schedulemeal-input-mealtype4")}</option>
          </select>
        </div>
        <div style={{ paddingTop: 10 }}>
          <label style={{ paddingTop: 10, paddingBottom: 5 }}>
            {isOther
              ? t("schedulemeal-input-entername-label")
              : t("schedulemeal-input-selectrecipe-label")}
          </label>
          {isOther ? (
            <p style={{ fontSize: 12, marginTop: -5 }}>
              {t("schedulemeal-input-entername-desc")}
            </p>
          ) : null}
          {isOther ? (
            <div>
              {" "}
              <TextareaAutosize
                // placeholder="Enter items here to add to your list below ... "
                //  textContentType="none"
                // onKeyDown={handleEnterPressed}
                // onBlur={handleEnterPressed}
                type="text"
                required
                maxLength={144}
                value={valueName}
                onChange={(e) => setValueName(e.target.value)}
                style={{
                  fontFamily: "inherit",
                  fontSize: 18,
                  padding: 10,
                  width: "80%",
                  //  backgroundColor: "lavender",
                  color: "black",
                }}
                placeholder=""
                className={classes.myform}
              />
              <label style={{ paddingTop: 10, paddingBottom: 5 }}>
                {t("common-button-details")}:
              </label>
              <TextareaAutosize
                // placeholder="Enter items here to add to your list below ... "
                // textContentType="none"
                //  onKeyDown={handleEnterPressed}
                //  onBlur={handleEnterPressed}
                minRows={2}
                type="text"
                value={valueNote}
                onChange={(e) => setValueNote(e.target.value)}
                style={{
                  fontFamily: "inherit",
                  fontSize: 18,
                  padding: 10,
                  //   backgroundColor: "lavender",
                  color: "black",
                  width: "80%",
                }}
              />
            </div>
          ) : (
            <select
              onChange={handleRecipeChange}
              value={selectedRecipe.id}
              style={{
                fontFamily: "inherit",
                fontSize: 16,
                padding: 10,
                width: "90%",
              }}
            >
              <option value="">
                {t("schedulemeal-input-option-selectrecipe")}
              </option>
              {recipes.map((recipe) => (
                <option key={recipe.id} value={recipe.id}>
                  {recipe.name}
                </option>
              ))}
            </select>
          )}
        </div>
        <div style={{ paddingTop: 10 }}>
          <label style={{ paddingTop: 10, paddingBottom: 5 }}>
            {t("schedulemeal-input-mealtime-label")}
          </label>
          <select
            onChange={handleMealTimeChange}
            value={mealTime}
            style={{
              fontFamily: "inherit",
              fontSize: 16,
              padding: 10,
              width: "90%",
            }}
          >
            <option value="">{t("schedulemeal-input-mealtime-option1")}</option>
            <option value="breakfast">
              {t("schedulemeal-input-mealtime-option2")}
            </option>
            <option value="lunch">
              {t("schedulemeal-input-mealtime-option3")}
            </option>
            <option value="brunch">
              {t("schedulemeal-input-mealtime-option4")}
            </option>
            <option value="snack">
              {t("schedulemeal-input-mealtime-option5")}
            </option>
            <option value="dinner-app">
              {t("schedulemeal-input-mealtime-option6")}
            </option>
            <option value="dinner-main">
              {t("schedulemeal-input-mealtime-option7")}
            </option>
            <option value="dinner-dessert">
              {t("schedulemeal-input-mealtime-option8")}
            </option>
          </select>
        </div>
        <div style={{ paddingTop: 10 }}>
          <label style={{ paddingTop: 10, paddingBottom: 5 }}>
            {t("schedulemeal-input-selectdate-label")}
          </label>
          <DatePicker
            onChange={handleDateChange}
            value={selectedDate}
            clearIcon={false}
          />
        </div>
        <div style={{ paddingTop: 20 }} />

        <button onClick={closeModal} className="actions_secondaryButton">
          {t("common-button-cancel")}
        </button>
        <button
          onClick={handleSaveMeal}
          className="actions_button"
          style={{ paddingLeft: 20, paddingRight: 20 }}
        >
          {isEdit ? t("common-button-update") : t("common-button-save")}
        </button>

        <div style={{ paddingBottom: 100 }} />
      </div>
    </Modal>
  );
};

import React from "react";

export const TravelOptions = ({ islands, currentIsland, onTravel }) => {
  return (
    <div
      style={{
        paddingTop: 20,
        paddingBottom: 20,
        paddingRight: 10,
        paddingLeft: 10,
      }}
    >
      Your Location: <b>{currentIsland.name}</b>, Where To Next?
      <br />
      <br />
      <select
        style={{ fontSize: 14, padding: 10 }}
        value={currentIsland.name}
        onChange={(e) => onTravel(e.target.value)}
      >
        {islands.map((island) => (
          <option key={island.name} value={island.name}>
            {island.name}
          </option>
        ))}
      </select>
      <div
        style={{
          marginTop: 20,
          padding: 10,
          borderRadius: 8,
          fontSize: 12,
          backgroundColor: "pink",
        }}
      >
        About <b>{currentIsland.name}</b> : {currentIsland.description}
      </div>
    </div>
  );
};

//import firebase from "firebase/app";
import firebase from "firebase/compat/app";

import { singular } from "pluralize";

import { useUserContext } from "Authentication";
import { DatabaseItem } from "./models";

import { db } from "Config";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  updateDoc,
  limit,
  deleteDoc,
  Timestamp,
  query,
  where,
  setDoc,
  writeBatch,
} from "firebase/firestore";
//import { Item } from "./List/Common/Item";
//import { userInfo } from "os";

export function useItemsToDo() {
  const { getPrivateToDoItems, getPrivateToDoCategories } = useUserContext();
  const collection = getPrivateToDoItems();
  const user = useUserContext();

  async function addItem(value: string, category?: string) {
    const name = value.trim();
    const lowerName = singular(name.toLowerCase());

    const q = query(collection, where("lowerName", "==", lowerName));
    const existing = await getDocs(q);

    const fieldsToUpdate = {
      name,
      added: true,
      completed: false,
      addedDate: Timestamp.fromDate(new Date()),
      addedBy: user.user.name,
      category:
        category === undefined
          ? existing.docs?.[0]?.data().category || ""
          : category,
    };

    if (existing.empty) {
      await addDoc(collection, { ...fieldsToUpdate, lowerName, count: 1 });
    } else {
      const docId = existing.docs[0].id;
      const currentCount = existing.docs[0].data().count ?? 0;
      const docRef = doc(collection, docId);
      await updateDoc(docRef, {
        ...fieldsToUpdate,
        count: currentCount + 1,
      });
    }
  }

  async function addItemPlanner(
    value: string,
    dueDate?: Date,
    dueTime?: string,
    endDate?: Date
  ) {
    const name = value.trim();

    const fieldsToUpdate = {
      name,
      added: true,
      completed: false,
      lowerName: name.toLowerCase(),
      //@ts-ignore
      addedDate: serverTimestamp(),
      addedBy: user.user.name,
      category: "td100100",
      dueDate: Timestamp.fromDate(new Date()),
      dueTime: dueTime,
      startDate: dueDate,
      endDate: endDate,
    };

    await addDoc(collection, { ...fieldsToUpdate });
  }

  function deleteItem(id: string) {
    return deleteDoc(doc(collection, id));
  }

  function removeItem(id: string) {
    const deletionFields = {
      // Define your deletion fields as needed
      completed: false,
      added: false,
      lastCompleted: new Date().toString(),
      //addedDate: firebase.firestore.FieldValue.delete(),
      //notes: firebase.firestore.FieldValue.delete(),
      flagged: false,
    };
    return updateDoc(doc(collection, id), deletionFields);
  }

  function updateItem(id: string, item: Partial<DatabaseItem>) {
    return updateDoc(doc(collection, id), item);
  }

  async function createNewListandItemsAI(newCategory, items) {
    // Create a new category
    const categoriesRef = await getPrivateToDoCategories();
    const categoryDocRef = await addDoc(categoriesRef, newCategory);
    const categoryId = categoryDocRef.id;

    // Start a batch operation
    const batch = writeBatch(db);

    for (const value of items) {
      const name = value.name.trim();
      const lowerName = singular(name.toLowerCase());

      const q = query(collection, where("lowerName", "==", lowerName));
      const existing = await getDocs(q);

      const fieldsToUpdate = {
        name,
        added: true,
        completed: false,
        notes: value.notes,
        addedDate: Timestamp.fromDate(new Date()),
        addedBy: user.user.name,
        category: categoryId,
      };

      if (existing.empty) {
        // If the item doesn't exist, create a new document
        const newDocRef = doc(collection);
        batch.set(newDocRef, { ...fieldsToUpdate, lowerName, count: 1 });
      } else {
        // If the item exists, update the existing document
        const docId = existing.docs[0].id;
        const currentCount = existing.docs[0].data().count ?? 0;
        const docRef = doc(collection, docId);
        batch.update(docRef, {
          ...fieldsToUpdate,
          count: currentCount + 1,
        });
      }
    }

    // Commit the batch operation
    await batch.commit();
  }

  function batchRemoveItems(ids: string[]) {
    const deletionFields = {
      // Define your deletion fields as needed
      completed: false,
      added: false,
      lastCompleted: new Date().toString(),
      //addedDate: firebase.firestore.FieldValue.delete(),
      //notes: firebase.firestore.FieldValue.delete(),
      flagged: false,
    };
    const batch = writeBatch(db);

    for (const id of ids) {
      const ref = doc(collection, id);
      batch.update(ref, deletionFields);
    }

    return batch.commit();
  }

  function setItemFlag(id: string, flagged: boolean) {
    return updateDoc(doc(collection, id), {
      flagged,
    });
  }

  function setSortIndex(id: string, sortIndex: number) {
    return updateDoc(doc(collection, id), {
      sortIndex,
    });
  }

  function setItemFavorite(id: string, isFavorite: boolean) {
    return updateDoc(doc(collection, id), {
      isFavorite,
    });
  }

  return {
    addItem,
    batchRemoveItems,
    createNewListandItemsAI,
    addItemPlanner,
    deleteItem,
    removeItem,
    setItemFlag,
    setSortIndex,
    setItemFavorite,
    updateItem,
  };
}

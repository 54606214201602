import { useEffect, useState } from "react";
import { useUserContext } from "Authentication";
import { CategoryModel } from "./models/";
import { collection, query, onSnapshot } from "@firebase/firestore";

export function useCategorySubscription() {
  const [categories, setCategories] = useState<CategoryModel[]>([]);
  const { getCategoriesForGroup } = useUserContext();

  useEffect(() => {
    const plannerCollection = getCategoriesForGroup();
    const plannerQuery = query(plannerCollection);

    const unsubscribe = onSnapshot(
      plannerQuery,
      (snapshot) => {
        const addedEvents: CategoryModel[] = [];

        snapshot.docs.forEach((doc) => {
          const item = { ...doc.data(), id: doc.id } as CategoryModel;
          addedEvents.push(item);
        });

        setCategories(addedEvents);
      },
      (error) => {
        window.location.reload();
      }
    );

    // Removed categories from the dependency array
    return () => unsubscribe();
  }, []); // Dependency array is now empty

  // Convert date to epoch
  const sortedCategories = categories.sort((a, b) =>
    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
  );

  return { categories: sortedCategories };
}

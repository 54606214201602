export const classicRecipesAR = [
  {
    id: "Hummus123",
    recipeUrl: "",
    name: "وصفة الحمص (Hummus)",
    directions:
      "<ol><li>اغسل حمص الطهي واتركه في الماء لليلة كاملة.</li><li>صفي الماء واغسل الحمص جيدًا.</li><li>قم بغلي الحمص في ماء جديد حتى يصبح ناعمًا ويمكن طحنه بسهولة.</li><li>قم بخلط الحمص المطبوخ مع عصير الليمون، زيت الزيتون، الثوم، والطحينة في الخلاط.</li><li>قدم الحمص في طبق التقديم وزينه برشة من زيت الزيتون ورشة من البقدونس المفروم.</li></ol>",
    days: [],
    tags: [
      { text: "مقبلات", color: "indigo" },
      { text: "طهي منزلي", color: "indigo" },
      { text: "مأكولات شرق أوسطية", color: "indigo" },
    ],
    ingredients: [
      { name: "حمص مجروش", measurement: "1 كوب" },
      { name: "عصير ليمون", measurement: "2 ملعقة كبيرة" },
      { name: "زيت زيتون", measurement: "2 ملعقة كبيرة" },
      { name: "ثوم مفروم", measurement: "2 فص" },
      { name: "طحينة", measurement: "2 ملعقة كبيرة" },
      { name: "زيت زيتون للتزيين", measurement: "1 ملعقة كبيرة" },
      { name: "بقدونس مفروم للتزيين", measurement: "1 ملعقة كبيرة" },
    ],
  },
  {
    id: "Manakeesh456",
    recipeUrl: "",
    name: "وصفة المناقيش (Manakeesh)",
    directions:
      "<ol><li>قم بتفعيل الخميرة في الماء الدافئ مع ملعقة صغيرة من السكر واتركها تتفاعل لمدة 10 دقائق.</li><li>في وعاء كبير، امزج الدقيق والملح وزيت الزيتون.</li><li>أضف الخميرة المفعلة وامزج المكونات جيدًا لعمل عجينة ناعمة ومتجانسة.</li><li>غطِّ العجينة واتركها ترتاح لمدة ساعة حتى تتضاعف حجمها.</li><li>بعد التخمير، افردي العجينة على صينية فرن مغطاة بورق الخبز.</li><li>قم بتجعيد العجينة بأصابعك لعمل التغاضيات (التجاعيد) على سطحها.</li><li>في وعاء صغير، قم بتحضير خليط الزعتر وزيت الزيتون.</li><li>ادهن سطح العجينة بالخليط السابق.</li><li>أدخل الصينية إلى الفرن المُسخن مُسبقًا على درجة حرارة 220 درجة مئوية واخبز لمدة 12-15 دقيقة أو حتى يصبح السطح ذهبيًا ومقرمشًا.</li><li>قدم المناقيش ساخنة وبالهناء والعافية!</li></ol>",
    days: [],
    tags: [
      { text: "خبز", color: "indigo" },
      { text: "طهي منزلي", color: "indigo" },
      { text: "مأكولات شرق أوسطية", color: "indigo" },
    ],
    ingredients: [
      { name: "دقيق", measurement: "2 كوب" },
      { name: "خميرة جافة", measurement: "1 ملعقة صغيرة" },
      { name: "ماء دافئ", measurement: "1 كوب" },
      { name: "سكر", measurement: "1 ملعقة صغيرة" },
      { name: "ملح", measurement: "1 ملعقة صغيرة" },
      { name: "زيت زيتون", measurement: "1/4 كوب" },
      { name: "زعتر", measurement: "2 ملعقة كبيرة" },
      { name: "زيت زيتون للتزيين", measurement: "2 ملعقة كبيرة" },
    ],
  },
  {
    id: "Fattoush789",
    recipeUrl: "",
    name: "وصفة الفتوش (Fattoush)",
    directions:
      "<ol><li>قطع الخبز البلدي إلى مكعبات صغيرة واحمصها في الفرن حتى تصبح ذهبية اللون.</li><li>في وعاء كبير، قم بخلط الخس والخيار والبندورة والبصل الأخضر والنعناع.</li><li>في وعاء صغير، قم بتحضير صلصة الليمون وزيت الزيتون والثوم والملح والفلفل الأسود والزعتر.</li><li>صب صلصة الليمون فوق الخضروات وامزجها جيدًا.</li><li>أضف الخبز المحمص وامزجه مع السلطة.</li><li>قدم الفتوش وزيِّنه بشرائح الليمون وزيت الزيتون.</li><li>استمتع بالفتوش المنعش!</li></ol>",
    days: [],
    tags: [
      { text: "سلطة", color: "indigo" },
      { text: "طهي منزلي", color: "indigo" },
      { text: "مأكولات شرق أوسطية", color: "indigo" },
    ],
    ingredients: [
      { name: "خبز بلدي", measurement: "2 قطع" },
      { name: "خس مفروم", measurement: "2 أكواب" },
      { name: "خيار مقطع صغير", measurement: "1 حبة" },
      { name: "بندورة مقطعة صغير", measurement: "1 حبة" },
      { name: "بصل أخضر مفروم", measurement: "1 حبة" },
      { name: "نعناع طازج مفروم", measurement: "2 ملعقة كبيرة" },
      { name: "ليمون عصيره", measurement: "1 حبة" },
      { name: "زيت زيتون", measurement: "1/4 كوب" },
      { name: "ثوم مفروم", measurement: "2 فص" },
      { name: "ملح", measurement: "1 ملعقة صغيرة" },
      { name: "فلفل أسود", measurement: "1/2 ملعقة صغيرة" },
      { name: "زعتر", measurement: "1/2 ملعقة صغيرة" },
    ],
  },
  {
    id: "Baklava123",
    recipeUrl: "",
    name: "وصفة البقلاوة (Baklava)",
    directions:
      "<ol><li>سخن الفرن إلى 180 درجة مئوية.</li><li>في وعاء كبير، قم بتذويب الزبدة.</li><li>قم بفرد ورق السمبوسة في صينية تخبز وزيِّن قاعها ببعض من الزبدة المذابة.</li><li>ضع طبقة من ورق السمبوسة فوقها وزيِّنها بزبدة أخرى.</li><li>كرر هذه الخطوة حتى تكون لديك عدة طبقات من ورق السمبوسة مع الزبدة في الوسط.</li><li>استخدم سكينًا حادة لتقطيع البقلاوة إلى مربعات أو مثلثات.</li><li>ضع قطع البقلاوة في الفرن المسخن لمدة 40-45 دقيقة أو حتى تصبح ذهبية اللون.</li><li>أثناء طهي البقلاوة، قم بتحضير القطر بغلي الماء والسكر والليمون حتى يصبح قوامه ثقيل.</li><li>عندما تخرج البقلاوة من الفرن، صب القطر الساخن فوقها.</li><li>اترك البقلاوة تبرد تمامًا قبل التقديم.</li><li>قدم البقلاوة وزيِّنها بالفستق المفروم إذا رغبت.</li><li>استمتع بمذاق البقلاوة الشهي!</li></ol>",
    days: [],
    tags: [
      { text: "حلوى", color: "indigo" },
      { text: "طهي منزلي", color: "indigo" },
      { text: "مأكولات شرق أوسطية", color: "indigo" },
    ],
    ingredients: [
      { name: "ورق السمبوسة", measurement: "1 عبوة" },
      { name: "زبدة مذابة", measurement: "200 غرام" },
      { name: "فستق مفروم", measurement: "200 غرام (اختياري)" },
      { name: "ماء", measurement: "1 كوب" },
      { name: "سكر", measurement: "2 أكواب" },
      { name: "عصير ليمون", measurement: "1 ملعقة كبيرة" },
    ],
  },
  {
    id: "Maqluba123",
    recipeUrl: "",
    name: "وصفة مقلوبة (Maqluba)",
    directions:
      "<ol><li>قم بغسل الأرز جيدًا واتركه جانبًا ليجف.</li><li>قطّع البصل إلى شرائح رقيقة والبطاطس إلى شرائح سميكة.</li><li>في مقلاة عميقة، قم بتسخين الزيت واقلي فيه البصل حتى يصبح ذهبي اللون، ثم قم بإخراجه واتركه جانبًا.</li><li>أضف زيت إضافي واقلي البطاطس حتى تصبح ذهبية اللون واسكبها في مصفاة للتخلص من الزيت الزائد.</li><li>في نفس المقلاة، ضع اللحم المفروم وقلبه حتى ينضج.</li><li>أضف البهارات والملح واستمر في القلي حتى يتحمر اللحم.</li><li>في قاعدة قدر كبير، ضع الأرز ثم البطاطس والبصل فوقه.</li><li>سكب الماء الساخن حتى يغطي الكل بشكل جيد.</li><li>غطّي القدر واتركه على نار هادئة حتى ينضج الأرز ويمتص الماء.</li><li>عند التقديم، قلب القدر ببطء على طبق التقديم وقدم المقلوبة ساخنة.</li></ol>",
    days: [],
    tags: [
      { text: "وجبة رئيسية", color: "indigo" },
      { text: "مأكولات شرق أوسطية", color: "indigo" },
      { text: "طهي منزلي", color: "indigo" },
    ],
    ingredients: [
      { name: "أرز", measurement: "2 كوب" },
      { name: "بصل", measurement: "1 حبة كبيرة" },
      { name: "بطاطس", measurement: "2 حبة كبيرة" },
      { name: "لحم مفروم", measurement: "500 غرام" },
      { name: "زيت نباتي", measurement: "1/4 كوب" },
      { name: "بهارات مشكلة", measurement: "1 ملعقة صغيرة" },
      { name: "ملح", measurement: "حسب الذوق" },
      { name: "ماء ساخن", measurement: "4 أكواب" },
    ],
  },
  {
    id: "Loubieh123",
    recipeUrl: "",
    name: "وصفة لوبيه بالزيت (Loubieh bi Zeit)",
    directions:
      "<ol><li>قطع الفاصوليا الخضراء إلى قسمين.</li><li>سخن زيت الزيتون في مقلاة على نار متوسطة.</li><li>أضف الفاصوليا الخضراء وقلبها حتى تصبح زاهية اللون ومستوية قليلاً.</li><li>أضف الثوم المفروم وقلبه لمدة دقيقة حتى يصبح رائحته طيبة.</li><li>أضف الطماطم المفرومة وقلبها مع الفاصوليا لمدة 2-3 دقائق.</li><li>أضف عصير الليمون والملح والفلفل واتركها على نار هادئة لمدة 5 دقائق أخرى.</li><li>عند التقديم، قدم اللوبيه بالزيت مع رشة من الزيتون الأخضر والبصل المفروم.</li></ol>",
    days: [],
    tags: [
      { text: "وجبة جانبية", color: "indigo" },
      { text: "مأكولات شرق أوسطية", color: "indigo" },
      { text: "طهي منزلي", color: "indigo" },
    ],
    ingredients: [
      { name: "فاصوليا خضراء", measurement: "500 غرام" },
      { name: "زيت زيتون", measurement: "3 ملاعق كبيرة" },
      { name: "ثوم مفروم", measurement: "2 فصوص" },
      { name: "طماطم مفرومة", measurement: "2 حبة متوسطة" },
      { name: "عصير ليمون", measurement: "2 ملعقة كبيرة" },
      { name: "ملح", measurement: "حسب الذوق" },
      { name: "فلفل أسود", measurement: "حسب الذوق" },
      { name: "زيتون أخضر (للتزيين)", measurement: "اختياري" },
      { name: "بصل مفروم (للتزيين)", measurement: "اختياري" },
    ],
  },
  {
    id: "Shawarma123",
    recipeUrl: "",
    name: "وصفة شاورما (Shawarma)",
    directions:
      "<ol><li>اختار نوع اللحم المفضل لديك (ديك رومي أو لحم خروف أو لحم دجاج) واقطعه إلى شرائح رقيقة.</li><li>في وعاء، ضع اللحم المقطع وأضف البهارات والتوابل (كمون، كزبرة، كمون أسود، كمون أبيض، ملح، فلفل) وزيت الزيتون وعصير الليمون. قلب المكونات جيدًا واتركها تتخمر لمدة ساعة على الأقل.</li><li>قم بشوي اللحم على الشواية أو في مقلاة على نار عالية حتى ينضج ويصبح ذهبي اللون من الخارج.</li><li>سخن خبز التورتيلا على مقلاة جافة حتى يصبح لونه ذهبيًا.</li><li>ضع شرائح اللحم المشوية على خبز التورتيلا وقم بلفها.</li><li>أضف مكملات الساندويتش المفضلة لديك مثل الطماطم، الخس، البصل، والثوم.</li><li>قدم الشاورما اللذيذة واستمتع بها!</li></ol>",
    days: [],
    tags: [
      { text: "وجبة رئيسية", color: "indigo" },
      { text: "مأكولات شرق أوسطية", color: "indigo" },
      { text: "طهي منزلي", color: "indigo" },
    ],
    ingredients: [
      {
        name: "لحم (ديك رومي أو لحم خروف أو لحم دجاج)",
        measurement: "500 غرام",
      },
      { name: "بهارات وتوابل مشكلة", measurement: "1 ملعقة صغيرة" },
      { name: "كمون", measurement: "1 ملعقة صغيرة" },
      { name: "كزبرة", measurement: "1 ملعقة صغيرة" },
      { name: "كمون أسود", measurement: "1/2 ملعقة صغيرة" },
      { name: "كمون أبيض", measurement: "1/2 ملعقة صغيرة" },
      { name: "ملح", measurement: "1/2 ملعقة صغيرة" },
      { name: "فلفل", measurement: "1/4 ملعقة صغيرة" },
      { name: "زيت زيتون", measurement: "2 ملعقة كبيرة" },
      { name: "عصير ليمون", measurement: "2 ملعقة كبيرة" },
      { name: "خبز تورتيلا", measurement: "4 قطع" },
      { name: "مكملات اختيارية (طماطم، خس، بصل، ثوم)", measurement: "اختياري" },
    ],
  },
  {
    id: "Falafel456",
    recipeUrl: "",
    name: "وصفة فلافل (Falafel)",
    directions:
      "<ol><li>قم بنقع حمصة جافة في الماء لمدة 12 ساعة على الأقل.</li><li>صفي الحمصة واشطفها جيدًا.</li><li>في محضر الطعام، ضع الحمصة المصفاة، البصل، الثوم، البقدونس، بودرة الكمون، بودرة الكركم، بيكربونات الصودا، وملح. اخلط المكونات جيدًا حتى تصبح عجينة ناعمة.</li><li>سخن زيت القلي في مقلاة عميقة إلى درجة حرارة 180 درجة مئوية (350 درجة فهرنهايت).</li><li>اشكل العجينة إلى كرات صغيرة أو أقراص واتركها ترتاح لبضع دقائق.</li><li>اقلي الفلافل في الزيت الساخن حتى تصبح ذهبية اللون من الخارج.</li><li>استخرج الفلافل وضعها على ورق مطبخ لامتصاص الزيت الزائد.</li><li>قدم الفلافل ساخنة مع الخبز والطحينة أو في سندويش.</li></ol>",
    days: [],
    tags: [
      { text: "وجبة خفيفة", color: "indigo" },
      { text: "مأكولات شرق أوسطية", color: "indigo" },
      { text: "طهي منزلي", color: "indigo" },
    ],
    ingredients: [
      { name: "حمص جاف", measurement: "1 كوب" },
      { name: "بصل مفروم", measurement: "1/2 كوب" },
      { name: "ثوم مفروم", measurement: "2 فص" },
      { name: "بقدونس مفروم", measurement: "1/2 كوب" },
      { name: "بودرة كمون", measurement: "1 ملعقة صغيرة" },
      { name: "بودرة كركم", measurement: "1 ملعقة صغيرة" },
      { name: "بيكربونات الصودا", measurement: "1/2 ملعقة صغيرة" },
      { name: "ملح", measurement: "1 ملعقة صغيرة" },
      { name: "زيت للقلي", measurement: "للقلي" },
      { name: "خبز أبيض", measurement: "اختياري للتقديم" },
      { name: "صلصة طحينة", measurement: "اختياري للتقديم" },
    ],
  },
  {
    id: "Pita12345",
    recipeUrl: "",
    name: "وصفة خبز البيتا (Pita Bread)",
    directions:
      "<ol><li>في وعاء كبير، امزجي الماء الدافئ مع الخميرة وقليل من السكر. اتركيها لتفعيل الخميرة لمدة 10 دقائق.</li><li>أضيفي الزيت والملح والطحين تدريجياً إلى الخليط وابدئي بالعجن.</li><li>قومي بعجن العجينة حتى تصبح ملساء ومطاطية. اتركيها ترتاح لمدة ساعة تحت غطاء في مكان دافئ.</li><li>بعد الراحة، شكلي العجين إلى كرات صغيرة وقمي بفردها إلى أشكال دائرية رقيقة.</li><li>سخني مقلاة غير لاصقة على نار متوسطة. اشوي الأقراص لمدة دقيقة على كل جانب أو حتى تتورد.</li><li>قدمي الخبز البيتا ساخناً مع الحشوات التي تفضلينها.</li></ol>",
    days: [],
    tags: [
      { text: "خبز", color: "indigo" },
      { text: "مأكولات شرق أوسطية", color: "indigo" },
      { text: "طهي منزلي", color: "indigo" },
    ],
    ingredients: [
      { name: "ماء دافئ", measurement: "1 كوب" },
      { name: "خميرة جافة نشطة", measurement: "2 ملعقة صغيرة" },
      { name: "سكر", measurement: "1 ملعقة صغيرة" },
      { name: "زيت زيتون", measurement: "2 ملعقة كبيرة" },
      { name: "ملح", measurement: "1 ملعقة صغيرة" },
      { name: "طحين", measurement: "2 ½ - 3 كوب" },
    ],
  },
  {
    id: "Moussaka6789",
    recipeUrl: "",
    name: "وصفة مسقعة (Moussaka)",
    directions:
      "<ol><li>قشّري الباذنجان وقطعيه إلى شرائح رقيقة. ضعيه في مصفاة ورشي الملح عليه واتركيه ليتخلص من الماء.</li><li>سخني الزيت في مقلاة كبيرة وقلي الباذنجان حتى يتحمّر. ثم قومي بوضعه على ورق مناديل لامتصاص الزيت الزائد.</li><li>في نفس المقلاة، قلّبي البصل والثوم حتى يذهبان للشفافية.</li><li>أضيفي اللحم المفروم وقلبي حتى ينضج ويتحمر.</li><li>أضيفي الطماطم المعلبة وصلصة الطماطم والبهارات والملح. اتركيها تغلي ثم اخفضي الحرارة واتركيها تنضج لمدة 15 دقيقة.</li><li>في صينية فرن كبيرة، ضعي طبقة من الباذنجان المقلي.</li><li>ثم ضعي اللحم المفروم المطهو فوق الباذنجان.</li><li>سكبي الباشميل فوق اللحم وامزجيه بلطف.</li><li>قدّمي الطبق بعد خروجه من الفرن ساخناً ومزيّناً بالبقدونس المفروم.</li></ol>",
    days: [],
    tags: [
      { text: "مأكولات شرق أوسطية", color: "indigo" },
      { text: "لحم", color: "indigo" },
      { text: "فطائر ومعجنات", color: "indigo" },
    ],
    ingredients: [
      { name: "باذنجان", measurement: "3 حبات" },
      { name: "ملح", measurement: "للتخلص من الماء" },
      { name: "زيت زيتون", measurement: "للقلي" },
      { name: "بصل مفروم", measurement: "1 حبة" },
      { name: "ثوم مفروم", measurement: "2 فص" },
      { name: "لحم مفروم", measurement: "500 غرام" },
      { name: "طماطم معلبة مهروسة", measurement: "400 غرام" },
      { name: "صلصة طماطم", measurement: "2 ملعقة كبيرة" },
      { name: "بهارات مشكلة", measurement: "1 ملعقة صغيرة" },
      { name: "ملح", measurement: "حسب الذوق" },
      { name: "صلصة الباشميل", measurement: "2 كوب" },
      { name: "بقدونس مفروم للتزيين", measurement: "حسب الذوق" },
    ],
  },
];

import { useEffect, useState } from "react";

import { useUserContext } from "Authentication";
import { collection, query, onSnapshot } from "@firebase/firestore";

export function useMealPlannerSubscription() {
  const [mealPlanner, setMealPlanner] = useState([]);
  const { getMealPlannerCollection } = useUserContext();

  useEffect(() => {
    const plannerCollection = getMealPlannerCollection();
    const plannerQuery = query(plannerCollection);

    const unsubscribe = onSnapshot(
      plannerQuery,
      (snapshot) => {
        const addedEvents = [];

        snapshot.docs.forEach((doc) => {
          const item = { ...doc.data(), id: doc.id };
          addedEvents.push(item);
        });

        setMealPlanner(addedEvents);
      },
      (error) => {
        window.location.reload();
      }
    );

    return () => unsubscribe();
  }, [getMealPlannerCollection]);

  // Convert date to epoch
  mealPlanner.sort((a, b) => (a.start > b.start ? 1 : -1));

  return { mealPlanner };
}

import { useState } from "react";
import cx from "classnames/bind";

import { weekDays } from "Constants";
import { useRecipes } from "Recipes/useRecipes";
import classes from "./Days.module.css";
import toast, { Toaster } from "react-hot-toast";

const classnames = cx.bind(classes);

interface DaysProps {
  id: string;
  recipeDays: number[];
}

export const Days = (props: DaysProps) => {
  const { id, recipeDays: initialDays } = props;
  const [recipeDays, setRecipeDays] = useState(initialDays);
  const { assignRecipe } = useRecipes();

  const days = weekDays.map((d, i) => ({
    name: d,
    selected: recipeDays.findIndex((rd) => rd === i) >= 0,
  }));

  async function handleClick(day: number) {
    let updatedDays: number[] = [];
    if (days[day].selected) {
      updatedDays = recipeDays.filter((d) => d !== day);
      toast("Recipe Removed", {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "pink",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
    } else {
      updatedDays = [...recipeDays, day];
      toast("Recipe Added", {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "lightgreen",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
    }
    setRecipeDays(updatedDays);
    await assignRecipe(id, updatedDays);
  }

  return (
    <div className={classes.container}>
      {days.map((d, i) => (
        <button
          key={d.name}
          onClick={() => handleClick(i)}
          className={classnames("button", { selected: d.selected })}
        >
          {d.name}
        </button>
      ))}
    </div>
  );
};

import React, { useState } from "react";
import "./FAQ.css"; // Import CSS file for styling

export const FAQ = () => {
  const faqData = [
    {
      question: "Can I share my login?",
      answer:
        "Yes, sharing your login is a great way for families to easily access and share the content.",
      // image: "react_logo.png",
    },

    {
      question: "How can I sync the data between my laptop and mobile device?",
      answer:
        "All data is automatically synced when online. If one of your devices is offline, please make sure to go online for synchronization.",
      // image: "jsx_logo.png",
    },
    {
      question: "Can I use Aloha Planner offline?",
      answer:
        "Yes, you can use Aloha Planner offline if you leave the app open before going offline. However, some features may require an internet connection for full functionality.",
    },

    {
      question: "Is my data secure in Aloha Planner?",
      answer:
        "Yes, we take data security seriously. Aloha Planner uses encryption to protect your data, and we adhere to strict privacy practices to keep your information safe.",
    },
    {
      question: "Can I set reminders for events in Aloha Planner?",
      answer:
        "Yes, you can set reminders for events, habits and tasks in Aloha Planner.",
    },

    {
      question: "Does Aloha Planner support recurring events?",
      answer:
        "Yes, Aloha Planner supports recurring events. When creating or editing an event, you can specify how often it repeats, such as daily, weekly, or monthly.",
    },
    {
      question: "Can I categorize my events in Aloha Planner?",
      answer:
        "Yes, you can categorize your events in Aloha Planner by color. This helps you organize your schedule better. Simply assign a color to each event when creating or editing it.",
    },

    {
      question: "Can I export to PDF?",
      answer: "Yes, Aloha Planner supports export to PDF using your laptop.",
    },
    {
      question:
        "Can I import events from other calendar apps into Aloha Planner?",
      answer:
        "Yes, you can import events from other calendar apps into Aloha Planner. Look for the import option in the settings menu and follow the instructions to import your events.",
    },
    {
      question: "How can I change the theme or appearance of Aloha Planner?",
      answer:
        "You can change the theme or appearance of Aloha Planner by going to the settings menu and selecting the 'Theme' option. From there, you can choose from a variety of themes to customize the app's look.",
    },
    {
      question: "Is Aloha Planner available on multiple platforms?",
      answer:
        "Yes, Aloha Planner is available on multiple platforms, including iOS, Android, and the web. You can access your schedule from any device with an internet connection.",
    },
    {
      question: "Can I download the web app to my laptop?",
      answer:
        "Yes, you can download the web app to your laptop. If you are using Chrome, simply select the download icon located in your browser.",
      // image: "install_react.png",
    },
    // Add more FAQ items as needed
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      {faqData.map((item, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleAccordion(index)}>
            <span className="question-text">{item.question}</span>
            <span className={`icon ${activeIndex === index ? "active" : ""}`}>
              &#9660;
            </span>{" "}
            {/* Downward triangle */}
          </div>
          {activeIndex === index && (
            <div className="faq-answer">
              {item.answer}
              <br />
              <br />
              {item.image && (
                <img src={item.image} alt="FAQ Image" className="faq-image" />
              )}{" "}
              {/* Optional image */}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

import React, { useState } from "react";
import { render } from "react-dom";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { ConfirmationModal } from "Common";
import { useUserContext } from "Authentication";
import { useTranslation } from "react-i18next";
import { Modal } from "Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import TextareaAutosize from "react-textarea-autosize";

const SortableItem = SortableElement(({ value, onDelete, onEdit }) => (
  <div
    style={{
      paddingBottom: 10,
      paddingTop: 10,
      paddingLeft: 10,
      paddingRight: 10,
      border: 1,
      fontSize: 14,
      borderStyle: "groove",
      backgroundColor: "navy",
      color: "white",
      display: "flex",
      justifyContent: "space-between",
      //  alignItems: "center",
      flexDirection: "column",
    }}
  >
    <span>{value}</span>
    <div
      style={{
        marginTop: 5,

        display: "flex",
        alignItems: "right",
        justifyContent: "right",
      }}
    >
      <button
        onClick={() => onEdit(value)}
        style={{
          fontSize: 12,
          padding: 5,
          backgroundColor: "transparent",
          color: "yellow",
          border: 0,
          marginRight: 5,
          cursor: "pointer",
        }}
      >
        Edit
      </button>

      <button
        onClick={() => onDelete(value)}
        style={{
          fontSize: 12,
          padding: 5,
          backgroundColor: "transparent",
          color: "lightblue",
          border: 0,
          cursor: "pointer",
        }}
      >
        {/*} <FontAwesomeIcon
          icon={faTrashCan}
          size="1x"
          style={{ paddingRight: 5 }}
      />*/}{" "}
        Remove
      </button>
    </div>
  </div>
));

const SortableList = SortableContainer(
  ({ items, onSortEnd, onDelete, onEdit }) => {
    return (
      <div style={{ userSelect: "none" }}>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${value}`}
            index={index}
            value={value}
            onDelete={() => onDelete(index)}
            onEdit={() => onEdit(index)}
          />
        ))}
      </div>
    );
  }
);

export function SortableComponent({ initialItems, listName, updateListDB }) {
  const [items, setItems] = useState(initialItems || []);
  const [isCollapsed, setIsCollapsed] = useState(
    localStorage.getItem(`sortablelist-${listName}`) != undefined
      ? JSON.parse(localStorage.getItem(`sortablelist-${listName}`))
      : true
  );
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [tempDeleteIndex, setTempDeleteIndex] = useState();
  const [newItem, setNewItem] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [editValue, setEditValue] = useState("");
  const { updateSortableLists } = useUserContext();
  const { t } = useTranslation();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const updatedItems = arrayMoveImmutable(items, oldIndex, newIndex);
      setItems(updatedItems);
      updateListDB(updatedItems);
    }
  };

  const deleteItemConfirmation = (index) => {
    setConfirmModalOpen(true);
    setTempDeleteIndex(index);
  };

  const handleDelete = () => {
    const updatedItems = [...items];
    updatedItems.splice(tempDeleteIndex, 1);
    setItems(updatedItems);
    updateListDB(updatedItems);
    setConfirmModalOpen(false);
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setEditValue(items[index]);
  };

  const saveEdit = () => {
    const updatedItems = [...items];
    updatedItems[editIndex] = editValue;
    setItems(updatedItems);
    updateListDB(updatedItems);
    setEditIndex(null);
    setEditValue("");
  };

  const handleAddNew = () => {
    if (newItem.trim() !== "") {
      const updatedItems = [...items, newItem];
      setItems(updatedItems);
      updateListDB(updatedItems);
      setNewItem("");
    }
  };

  const toggleCollapse = () => {
    if (isCollapsed == true) {
      setIsCollapsed(false);
      localStorage.setItem(`sortablelist-${listName}`, "false");
    } else {
      setIsCollapsed(true);
      localStorage.setItem(`sortablelist-${listName}`, "true");
    }
  };

  return (
    <div
      style={{
        border: 1,
        borderColor: "white",
        borderStyle: "groove",
        background: "floralwhite",
        padding: 10,
        borderRadius: 5,
      }}
    >
      <div onClick={toggleCollapse} style={{ cursor: "pointer" }}>
        <h3>{listName}</h3>
      </div>
      {!isCollapsed && (
        <div
          style={{
            display: "flex",
            alignItems: "left",
            justifyContent: "left",
            flexDirection: "column",
          }}
        >
          <div style={{ paddingTop: 0 }} />
          <TextareaAutosize
            type="text"
            style={{
              fontSize: 16,
              padding: 10,
              borderRadius: 5,
              cursor: "pointer",
              // width: "85%",
              color: "black",
              backgroundColor: "snow",
              border: 1,
              borderStyle: "dotted",
              //  filter: "invert(100%)",
            }}
            value={newItem}
            //  style={{ padding: 5 }}
            onChange={(e) => setNewItem(e.target.value)}
            placeholder={t("sortable-list-placeholder")}
          />

          <button
            onClick={handleAddNew}
            style={{
              fontSize: 14,
              //  width: "150",
              marginTop: 5,
              padding: 10,
              borderRadius: 10,
              border: 0,
              color: "black",
              backgroundColor: "lightskyblue",
              fontFamily: "inherit",
            }}
          >
            Add Item
          </button>

          <div style={{ paddingBottom: 20 }} />

          <SortableList
            items={items}
            onSortEnd={onSortEnd}
            onDelete={deleteItemConfirmation}
            onEdit={handleEdit}
          />
        </div>
      )}
      <div
        style={{
          paddingTop: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button
          onClick={toggleCollapse}
          style={{
            fontSize: 12,
            padding: 5,
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 20,
            marginTop: isCollapsed ? -10 : 0,
            border: 0,
            color: "blue",
            backgroundColor: "transparent",
            textDecoration: "underline",
            cursor: "pointer",
            fontFamily: "inherit",
          }}
        >
          {isCollapsed
            ? t("sortable-list-show-items")
            : t("sortable-list-hide-items")}
        </button>
      </div>

      <ConfirmationModal
        isOpen={confirmModalOpen}
        text={t("sortable-list-delete-confirmation")}
        onClickCancel={() => setConfirmModalOpen(false)}
        onClickConfirm={handleDelete}
      />
      {editIndex !== null && (
        <Modal
          isOpen={true}
          //onRequestClose={close}
          style={{ overlay: { zIndex: 100 } }}
        >
          <div
            style={{
              paddingBottom: 100,
              padding: 20,
              display: "flex",
              alignItems: "left",
              justifyContent: "left",
              flexDirection: "colum",
              width: "100%",
            }}
          >
            <div style={{ width: "85%" }}>
              <h2> Edit Item</h2>
              <div style={{ width: 250, width: "100%", resize: "both" }}>
                {" "}
                <TextareaAutosize
                  type="text"
                  style={{ fontSize: 16, padding: 10, width: "85%" }}
                  value={editValue}
                  onChange={(e) => setEditValue(e.target.value)}
                />
              </div>

              <div style={{ marginTop: 20 }}>
                {" "}
                <button
                  style={{ fontSize: 16 }}
                  className="actions_button"
                  onClick={saveEdit}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

render(<SortableComponent />, document.getElementById("root"));

export const classicRecipes = [
  {
    id: "dnr003",
    recipeUrl: "",
    name: "Spaghetti and Meatballs",
    directions: `
            <ol>
              <li>Preheat oven to 350 degrees F (175 degrees C).</li>
              <li>In a large bowl, combine ground beef, bread crumbs, Parmesan cheese, eggs, onion, garlic, salt, and pepper. Mix well.</li>
              <li>Shape the meat mixture into meatballs about 1 inch in diameter.</li>
              <li>Heat olive oil in a large skillet over medium heat.</li>
              <li>Cook meatballs in hot oil for 10-12 minutes, or until browned on all sides.</li>
              <li>Transfer meatballs to a baking dish.</li>
              <li>In a saucepan, combine tomato sauce, water, and Italian seasoning. Bring to a simmer over medium heat.</li>
              <li>Pour tomato sauce over meatballs.</li>
              <li>Bake in preheated oven for 20 minutes, or until meatballs are cooked through.</li>
              <li>Serve over cooked spaghetti.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Italian",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "ground beef",
        measurement: "1 pound",
      },
      {
        name: "bread crumbs",
        measurement: "1/2 cup",
      },
      {
        name: "grated Parmesan cheese",
        measurement: "1/4 cup",
      },
      {
        name: "eggs",
        measurement: "2 eggs, beaten",
      },
      {
        name: "onion",
        measurement: "1/2 cup, chopped ",
      },
      {
        name: "garlic",
        measurement: "2 cloves, minced",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
      {
        name: "olive oil",
        measurement: "1/4 cup",
      },
      {
        name: "Can crushed tomatoes",
        measurement: "1 (28 ounce)",
      },
      {
        name: "water",
        measurement: "1 cup",
      },
      {
        name: "Italian seasoning",
        measurement: "1 teaspoon",
      },
      {
        name: "spaghetti",
        measurement: "12 ounces",
      },
    ],
  },
  {
    id: "dnr004",
    recipeUrl: "",
    name: "Macaroni and Cheese",
    directions: `
            <ol>
              <li>Preheat oven to 350 degrees F (175 degrees C).</li>
              <li>Grease a 9x13 inch baking dish.</li>
              <li>In a large saucepan, melt butter over medium heat.</li>
              <li>Stir in flour and cook for 1 minute.</li>
              <li>Gradually whisk in milk until smooth.</li>
              <li>Bring to a simmer and cook for 5 minutes, or until thickened.</li>
              <li>Remove from heat and stir in cheddar cheese, Gruyère cheese, and salt and pepper to taste.</li>
              <li>In a separate bowl, combine elbow macaroni and milk.</li>
              <li>Pour macaroni mixture into prepared baking dish.</li>
              <li>Pour cheese sauce over macaroni mixture.</li>
              <li>Bake in preheated oven for 20 minutes, or until bubbly and golden brown.</li>
              <li>Let stand for 10 minutes before serving.</l1>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "elbow macaroni",
        measurement: "1 pound",
      },
      {
        name: "butter",
        measurement: "1/2 cup",
      },
      {
        name: "all-purpose flour",
        measurement: "1/2 cup",
      },
      {
        name: "milk",
        measurement: "4 cups",
      },
      {
        name: "shredded cheddar cheese",
        measurement: "1 cup",
      },
      {
        name: "shredded Gruyère cheese",
        measurement: "1/2 cup",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
    ],
  },
  {
    id: "dnr005",
    recipeUrl: "",
    name: "Carbonara",
    directions: `
            <ol>
              <li>Bring a large pot of salted water to a boil.</li>
              <li>Cook the spaghetti according to the package directions.</li>
              <li>While the pasta is cooking, heat a large skillet over medium heat.</li>
              <li>Add the guanciale or pancetta and cook until crisp.</li>
              <li>Remove the guanciale or pancetta from the skillet with a slotted spoon and set aside.</li>
              <li>In the same skillet, over medium heat, whisk together the eggs, Parmesan cheese, and black pepper.</li>
              <li>Once the pasta is cooked, add it to the skillet with the egg mixture.</li>
              <li>Toss to coat the pasta in the egg mixture.</li>
              <li>Add the reserved guanciale or pancetta and toss again.</li>
              <li>Serve immediately.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Italian",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "spaghetti",
        measurement: "1 pound",
      },
      {
        name: "pancetta or bacon",
        measurement: "1/2 pound",
      },
      {
        name: "grated Parmesan cheese",
        measurement: "1/2 cup",
      },
      {
        name: "freshly ground black pepper",
        measurement: "1/2 teaspoon",
      },
      {
        name: "large eggs",
        measurement: "4",
      },
    ],
  },
  {
    id: "dnr006",
    recipeUrl: "",
    name: "Pasta Alfredo",
    directions: `
            <ol>
              <li>Bring a large pot of salted water to a boil.</li>
              <li>Cook the fettuccine according to the package directions.</li>
              <li>While the pasta is cooking, melt the butter and cream in a large skillet over medium heat.</li>
              <li>Whisk in the Parmesan cheese and salt and pepper to taste.</li>
              <li>Once the pasta is cooked, add it to the skillet with the Alfredo sauce.</li>
              <li>Toss to coat the pasta in the sauce.</li>
              <li>Serve immediately.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Italian",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "fettuccine",
        measurement: "1 pound",
      },
      {
        name: "butter",
        measurement: "1/2 cup",
      },
      {
        name: "heavy cream",
        measurement: "1 cup",
      },
      {
        name: "grated Parmesan cheese",
        measurement: "1 cup",
      },
      {
        name: "salt",
        measurement: "1/2 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/4 teaspoon",
      },
    ],
  },
  {
    id: "dnr007",
    recipeUrl: "",
    name: "Bolognese Pasta",
    directions: `
            <ol>
              <li>Heat the olive oil in a large pot over medium heat.</li>
              <li>Add the ground beef and cook until browned.</li>
              <li>Drain off any excess fat.</li>
              <li>Add the onion, carrots, and celery to the pot and cook until softened.</li>
              <li>Add the garlic, oregano, and basil and cook for 1 minute more.</li>
              <li>Stir in the crushed tomatoes, tomato paste, and red wine.</li>
              <li>Bring to a simmer and cook for 1 hour, or until the sauce has thickened.</li>
              <li>Season with salt and pepper to taste.</li>
              <li>Cook the pasta according to the package directions.</li>
              <li>Toss the pasta with the sauce and serve.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Italian",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "ground beef",
        measurement: "1 pound",
      },
      {
        name: "olive oil",
        measurement: "1/4 cup",
      },
      {
        name: "onion",
        measurement: "1 onion, chopped",
      },
      {
        name: "carrots",
        measurement: "2 carrots, chopped",
      },
      {
        name: "celery",
        measurement: "2 celery stalks, chopped",
      },
      {
        name: "garlic",
        measurement: "4 cloves, minced",
      },
      {
        name: "dried oregano",
        measurement: "1 teaspoon",
      },
      {
        name: "dried basil",
        measurement: "1/2 teaspoon",
      },
      {
        name: "can crushed tomatoes",
        measurement: "1 (28 ounce)",
      },
      {
        name: "can tomato paste",
        measurement: "1 (6 ounce)",
      },
      {
        name: "red wine",
        measurement: "1/2 cup",
      },
      {
        name: "salt",
        measurement: "1/2 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/4 teaspoon",
      },
      {
        name: "pasta",
        measurement: "12 ounces",
      },
    ],
  },
  {
    id: "dnr008",
    recipeUrl: "",
    name: "Pesto Pasta",
    directions: `
            <ol>
              <li>Cook the pasta according to the package directions.</li>
              <li>While the pasta is cooking, make the pesto sauce.</li>
              <li>In a food processor, combine the basil, pine nuts, garlic, Parmesan cheese, and olive oil.</li>
              <li>Pulse until smooth.</li>
              <li>Toss the cooked pasta with the pesto sauce.</li>
              <li>Serve immediately.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Italian",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "pasta",
        measurement: "12 ounces",
      },
      {
        name: "basil leaves",
        measurement: "1/2 cup",
      },
      {
        name: "pine nuts",
        measurement: "1/2 cup",
      },
      {
        name: "garlic",
        measurement: "2 cloves, minced",
      },
      {
        name: "grated Parmesan cheese",
        measurement: "1/2 cup",
      },
      {
        name: "olive oil",
        measurement: "1/4 cup",
      },
    ],
  },
  {
    id: "dnr009",
    recipeUrl: "",
    name: "Linguine with Clams",
    directions: `
            <ol>
              <li>Bring a large pot of salted water to a boil.</li>
              <li>Add the linguine and cook according to the package directions.</li>
              <li>While the pasta is cooking, heat the olive oil in a large skillet over medium heat.</li>
              <li>Add the garlic and cook for 1 minute, or until fragrant.</li>
              <li>Add the clams and cook for 5 minutes, or until they open.</li>
              <li>Remove the clams from the skillet and set aside.</li>
              <li>Add the wine to the skillet and cook for 1 minute, or until reduced by half.</li>
              <li>Stir in the parsley and lemon juice.</li>
              <li>Add the cooked linguine and clams to the skillet and toss to coat.</li>
              <li>Serve immediately.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Italian",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "linguine",
        measurement: "12 ounces",
      },
      {
        name: "olive oil",
        measurement: "1/4 cup",
      },
      {
        name: "garlic",
        measurement: "4 cloves, minced",
      },
      {
        name: "littleneck clams",
        measurement: "1 pound, scrubbed",
      },
      {
        name: "dry white wine",
        measurement: "1/2 cup",
      },
      {
        name: "fresh parsley",
        measurement: "1/4 cup, chopped ",
      },
      {
        name: "lemon",
        measurement: "2 tablespoons, juiced",
      },
    ],
  },
  {
    id: "dnr010",
    recipeUrl: "",
    name: "Pancakes",
    directions: `
            <ol>
              <li>In a large bowl, whisk together the flour, baking powder, baking soda, and salt.</li>
              <li>In a separate bowl, whisk together the eggs, milk, and butter.</li>
              <li>Add the wet ingredients to the dry ingredients and whisk until just combined. Do not overmix.</li>
              <li>Heat a lightly oiled griddle or frying pan over medium heat.</li>
              <li>Pour 1/4 cup of batter onto the hot griddle for each pancake.</li>
              <li>Cook for 2-3 minutes per side, or until golden brown.</li>
              <li>Serve immediately with your favorite toppings.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Breakfast",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "all-purpose flour",
        measurement: "1 cup",
      },
      {
        name: "baking powder",
        measurement: "2 teaspoons",
      },
      {
        name: "baking soda",
        measurement: "1/2 teaspoon",
      },
      {
        name: "salt",
        measurement: "1/2 teaspoon",
      },
      {
        name: "eggs",
        measurement: "2",
      },
      {
        name: "milk",
        measurement: "1 cup",
      },
      {
        name: "butter",
        measurement: "1/4 cup, melted",
      },
      {
        name: "Oil ",
        measurement: "for greasing the pan",
      },
    ],
  },
  {
    id: "dnr011",
    recipeUrl: "",
    name: "Omelet",
    directions: `
            <ol>
              <li>In a bowl, whisk together the eggs, milk, salt, and pepper.</li>
              <li>Heat a small amount of oil or butter in a nonstick skillet over medium heat.</li>
              <li>Pour the egg mixture into the skillet and cook for 2-3 minutes, or until the bottom is set.</li>
              <li>Add your desired fillings to the omelet, such as cheese, vegetables, or meat.</li>
              <li>Fold the omelet in half and cook for an additional 1-2 minutes, or until the filling is cooked through.</li>
              <li>Serve immediately.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Breakfast",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "eggs",
        measurement: "2",
      },
      {
        name: "milk",
        measurement: "1/4 cup",
      },
      {
        name: "salt",
        measurement: "1/4 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/4 teaspoon",
      },
      {
        name: "Oil or butter ",
        measurement: "for greasing the pan",
      },
      {
        name: "Your desired fillings",
        measurement: "Fillings",
      },
    ],
  },
  {
    id: "dnr012",
    recipeUrl: "",
    name: "Eggs Benedict",
    directions: `
            <ol>
              <li>Cook the English muffins according to the package directions.</li>
              <li>Poach the eggs in a simmering pot of water for 3-4 minutes, or until cooked through.</li>
              <li>Meanwhile, make the Hollandaise sauce by whisking together the egg yolks, lemon juice, and melted butter in a heatproof bowl over a simmering pot of water.</li>
              <li>Remove the Hollandaise sauce from the heat and whisk in the cayenne pepper and salt to taste.</li>
              <li>To assemble the eggs Benedict, place an English muffin on a plate, top with a poached egg, and then top with the Hollandaise sauce.</li>
              <li>Garnish with fresh herbs and serve immediately.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Breakfast",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "English muffins",
        measurement: "2",
      },
      {
        name: "eggs",
        measurement: "6",
      },
      {
        name: "unsalted butter",
        measurement: "1/2 cup (1 stick)",
      },
      {
        name: "egg yolks",
        measurement: "3",
      },
      {
        name: "lemon juice",
        measurement: "1 tablespoon",
      },
      {
        name: "cayenne pepper",
        measurement: "1/4 teaspoon",
      },
      {
        name: "Salt and pepper",
        measurement: "to taste",
      },
      {
        name: "Fresh herbs",
        measurement: "for garnish",
      },
    ],
  },
  {
    id: "dnr013",
    recipeUrl: "",
    name: "Quiche",
    directions: `
            <ol>
              <li>Preheat the oven to 375 degrees F (190 degrees C).</li>
              <li>In a large bowl, whisk together the eggs, milk, cream, salt, and pepper.</li>
              <li>Add the cheese, vegetables, and ham to the egg mixture and stir to combine.</li>
              <li>Pour the egg mixture into a greased 9x13 inch baking dish.</li>
              <li>Bake for 45-50 minutes, or until the quiche is set and the crust is golden brown.</li>
              <li>Let the quiche cool slightly before serving.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Breakfast",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "pie crust",
        measurement: "1 (9-inch)",
      },
      {
        name: "eggs",
        measurement: "6",
      },
      {
        name: "milk",
        measurement: "1 cup",
      },
      {
        name: "heavy cream",
        measurement: "1/2 cup",
      },
      {
        name: "salt",
        measurement: "1/2 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/4 teaspoon",
      },
      {
        name: "shredded cheddar cheese",
        measurement: "1 cup",
      },
      {
        name: "chopped vegetables",
        measurement: "1/2 cup",
      },
      {
        name: "chopped ham",
        measurement: "1/2 cup",
      },
    ],
  },
  {
    id: "dnr014",
    recipeUrl: "",
    name: "Meatloaf",
    directions: `
            <ol>
              <li>Preheat oven to 350 degrees F (175 degrees C).</li>
              <li>In a large bowl, combine ground beef, bread crumbs, eggs, ketchup, onion, garlic powder, salt, and pepper.</li>
              <li>Mix well.</li>
              <li>Form the meat mixture into a loaf in a greased 9x5 inch loaf pan.</li>
              <li>Bake for 1 hour, or until a meat thermometer inserted into the center reads 160 degrees F (71 degrees C).</li>
              <li>Let meatloaf cool slightly before serving.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Main Course",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "ground beef",
        measurement: "1 pound",
      },
      {
        name: "bread crumbs",
        measurement: "1 cup",
      },
      {
        name: "eggs",
        measurement: "2",
      },
      {
        name: "ketchup",
        measurement: "1/2 cup",
      },
      {
        name: "onion",
        measurement: "1/2, chopped",
      },
      {
        name: "garlic powder",
        measurement: "1 teaspoon",
      },
      {
        name: "salt",
        measurement: "1/2 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/4 teaspoon",
      },
    ],
  },
  {
    id: "dnr015",
    recipeUrl: "",
    name: "Beef Stew",
    directions: `
            <ol>
              <li>Heat the oil in a large Dutch oven over medium heat.</li>
              <li>Add the beef and cook until browned on all sides.</li>
              <li>Remove the beef from the pot and set aside.</li>
              <li>Add the onion, carrots, and celery to the pot and cook until softened, about 5 minutes.</li>
              <li>Add the garlic, thyme, and rosemary and cook for 1 minute more.</li>
              <li>Return the beef to the pot and add the beef broth, red wine, and tomatoes.</li>
              <li>Bring to a boil, then reduce heat to low and simmer for 2-3 hours, or until the beef is tender.</li>
              <li>Season with salt and pepper to taste.</li>
              <li>Serve hot with your favorite sides.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Main Course",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "beef chuck",
        measurement: "2 pounds, cut into 1-inch cubes",
      },
      {
        name: "olive oil",
        measurement: "2 tablespoons",
      },
      {
        name: "onion",
        measurement: "1, chopped",
      },
      {
        name: "carrots",
        measurement: "2 carrots, chopped",
      },
      {
        name: "celery",
        measurement: "2 stalks, chopped",
      },
      {
        name: "garlic",
        measurement: "2 cloves, minced",
      },
      {
        name: "dried thyme",
        measurement: "1 teaspoon",
      },
      {
        name: "dried rosemary",
        measurement: "1 teaspoon",
      },
      {
        name: "beef broth",
        measurement: "4 cups",
      },
      {
        name: "red wine",
        measurement: "1 cup",
      },
      {
        name: "can crushed tomatoes",
        measurement: "1 (28-ounce)",
      },
      {
        name: "Salt and pepper",
        measurement: "to taste",
      },
    ],
  },
  {
    id: "dnr016",
    recipeUrl: "",
    name: "Chili",
    directions: `
            <ol>
              <li>Heat the oil in a large pot over medium heat.</li>
              <li>Add the ground beef and cook until browned.</li>
              <li>Drain off any excess grease.</li>
              <li>Add the onion, carrots, and celery to the pot and cook until softened, about 5 minutes.</li>
              <li>Add the garlic, chili powder, cumin, oregano, and salt and pepper to taste.</li>
              <li>Cook for 1 minute more.</li>
              <li>Add the beans, tomatoes, and beef broth to the pot.</li>
              <li>Bring to a boil, then reduce heat to low and simmer for 1-2 hours, or until the chili has thickened.</li>
              <li>Serve hot with your favorite toppings.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Main Course",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "ground beef",
        measurement: "1 pound",
      },
      {
        name: "olive oil",
        measurement: "2 tablespoons",
      },
      {
        name: "onion",
        measurement: "1, chopped",
      },
      {
        name: "carrots",
        measurement: "2, chopped",
      },
      {
        name: "celery",
        measurement: "2 stalks, chopped",
      },
      {
        name: "garlic",
        measurement: "2, minced",
      },
      {
        name: "chili powder",
        measurement: "1 tablespoon",
      },
      {
        name: "cumin",
        measurement: "1 teaspoon",
      },
      {
        name: "oregano",
        measurement: "1 teaspoon",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
      {
        name: "can kidney beans",
        measurement: "1 (15-ounce), drained and rinsed",
      },
      {
        name: "can black beans",
        measurement: "1 (15-ounce), drained and rinsed",
      },
      {
        name: "can crushed tomatoes",
        measurement: "1 (28-ounce)",
      },
      {
        name: "beef broth",
        measurement: "1 cup",
      },
      {
        name: "Your favorite toppings, such as shredded cheese, sour cream, and chopped onions",
        measurement: "Your favorite toppings",
      },
    ],
  },
  {
    id: "dnr017",
    recipeUrl: "",
    name: "Risotto",
    directions: `
            <ol>
              <li>Heat the butter and olive oil in a large saucepan over medium heat.</li>
              <li>Add the onion and cook until softened, about 5 minutes.</li>
              <li>Add the rice and stir to coat in the butter and oil.</li>
              <li>Add the wine and cook until it is absorbed, about 2 minutes.</li>
              <li>Add 1 cup of the hot broth and cook, stirring constantly, until the broth is absorbed.</li>
              <li>Continue adding the broth 1 cup at a time, stirring constantly, until the rice is cooked through and the risotto is creamy, about 20 minutes total.</li>
              <li>Remove from the heat and stir in the Parmesan cheese, butter, and salt and pepper to taste.</li>
              <li>Serve immediately.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Main Course",
        color: "indigo",
      },
      {
        text: "Italian",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "butter",
        measurement: "1 tablespoon",
      },
      {
        name: "olive oil",
        measurement: "1 tablespoon",
      },
      {
        name: "onion",
        measurement: "1/2 cup, chopped",
      },
      {
        name: "arborio rice",
        measurement: "1 cup",
      },
      {
        name: "dry white wine",
        measurement: "1/2 cup",
      },
      {
        name: "chicken or vegetable broth",
        measurement: "4 cups, hot",
      },
      {
        name: "grated Parmesan cheese",
        measurement: "1/2 cup",
      },
      {
        name: "butter",
        measurement: "2 tablespoons",
      },
      {
        name: "salt",
        measurement: "1/2 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/4 teaspoon",
      },
    ],
  },
  {
    id: "dnr018",
    recipeUrl: "",
    name: "Vegan Chili",
    directions: `
            <ol>
              <li>Heat the oil in a large pot over medium heat.</li>
              <li>Add the onion and cook until softened, about 5 minutes.</li>
              <li>Add the garlic, chili powder, cumin, oregano, and salt and pepper to taste.</li>
              <li>Cook for 1 minute more.</li>
              <li>Add the beans, tomatoes, and vegetable broth to the pot.</li>
              <li>Bring to a boil, then reduce heat to low and simmer for 1-2 hours, or until the chili has thickened.</li>
              <li>Serve hot with your favorite toppings.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Main Course",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "olive oil",
        measurement: "1 tablespoon",
      },
      {
        name: "onion",
        measurement: "1 onion, chopped",
      },
      {
        name: "garlic",
        measurement: "2 cloves, minced",
      },
      {
        name: "chili powder",
        measurement: "1 tablespoon",
      },
      {
        name: "cumin",
        measurement: "1 teaspoon",
      },
      {
        name: "oregano",
        measurement: "1 teaspoon",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
      {
        name: "can kidney beans",
        measurement: "1 (15-ounce), drained and rinsed",
      },
      {
        name: "can black beans",
        measurement: "1 (15-ounce), drained and rinsed",
      },
      {
        name: "can crushed tomatoes",
        measurement: "1 (28-ounce) can",
      },
      {
        name: "vegetable broth",
        measurement: "1 cup",
      },
      {
        name: "Your favorite toppings, such as shredded cheese, sour cream, and chopped onions",
        measurement: "Your favorite toppings",
      },
    ],
  },
  {
    id: "dnr019",
    recipeUrl: "",
    name: "Shepherd's Pie",
    directions: `
            <ol>
              <li>Preheat oven to 375 degrees F (190 degrees C).</li>
              <li>In a large pot, brown the ground beef over medium heat.</li>
              <li>Drain off any excess grease.</li>
              <li>Add the onion, carrots, and celery to the pot and cook until softened, about 5 minutes.</li>
              <li>Add the garlic, thyme, and rosemary and cook for 1 minute more.</li>
              <li>Add the beef broth, tomatoes, and peas to the pot.</li>
              <li>Bring to a boil, then reduce heat to low and simmer for 15 minutes, or until the sauce has thickened.</li>
              <li>In a separate bowl, mash the potatoes with the milk, butter, and salt and pepper to taste.</li>
              <li>Pour the beef mixture into a greased 9x13 inch baking dish.</li>
              <li>Top with the mashed potatoes and bake for 20 minutes, or until the potatoes are golden brown.</li>
              <li>Let cool slightly before serving.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Main Course",
        color: "indigo",
      },
      {
        text: "British",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "ground beef",
        measurement: "1 pound",
      },
      {
        name: "onion",
        measurement: "1, chopped",
      },
      {
        name: "carrots",
        measurement: "2, chopped",
      },
      {
        name: "celery",
        measurement: "2 stalks, chopped",
      },
      {
        name: "cloves",
        measurement: "2 cloves, minced",
      },
      {
        name: "dried thyme",
        measurement: "1 teaspoon",
      },
      {
        name: "dried rosemary",
        measurement: "1 teaspoon",
      },
      {
        name: "can beef broth",
        measurement: "1 (14.5 ounce)",
      },
      {
        name: "can diced tomatoes",
        measurement: "1 (14.5 ounce) can",
      },
      {
        name: "frozen peas",
        measurement: "1 (10 ounce) package",
      },
      {
        name: "mashed potatoes",
        measurement: "2 cups",
      },
      {
        name: "milk",
        measurement: "1/2 cup",
      },
      {
        name: "butter",
        measurement: "1/4 cup",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
    ],
  },
  {
    id: "dnr020",
    recipeUrl: "",
    name: "Tofu Scramble",
    directions: `
            <ol>
              <li>Press the tofu to remove excess water.</li>
              <li>Crumble the tofu into a bowl.</li>
              <li>Add the vegetables, spices, and salt and pepper to taste.</li>
              <li>Mix well.</li>
              <li>Heat a large skillet or wok over medium heat.</li>
              <li>Add the tofu mixture to the skillet and cook, stirring occasionally, until heated through and the vegetables are tender.</li>
              <li>Serve immediately.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Breakfast",
        color: "indigo",
      },
      {
        text: "Vegan",
        color: "indigo",
      },
      {
        text: "Tofu",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "extra-firm tofu",
        measurement: "1 block",
      },
      {
        name: "onion",
        measurement: "1/2, chopped",
      },
      {
        name: "green bell pepper",
        measurement: "1, chopped",
      },
      {
        name: "red bell pepper",
        measurement: "1, chopped",
      },
      {
        name: "cumin",
        measurement: "1 teaspoon",
      },
      {
        name: "chili powder",
        measurement: "1 teaspoon",
      },
      {
        name: "salt",
        measurement: "1/2 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/4 teaspoon",
      },
    ],
  },
  {
    id: "dnr021",
    recipeUrl: "",
    name: "Steak Fajitas",
    directions: `
            <ol>
              <li>Heat a large skillet or grill pan over medium-high heat.</li>
              <li>Add the beef and cook until browned on all sides.</li>
              <li>Remove the beef from the pan and set aside.</li>
              <li>Add the onions and peppers to the pan and cook until softened, about 5 minutes.</li>
              <li>Add the garlic and cook for 1 minute more.</li>
              <li>Return the beef to the pan and season with salt and pepper to taste.</li>
              <li>Cook for an additional 2-3 minutes, or until the beef is heated through.</li>
              <li>Serve immediately with your favorite toppings, such as tortillas, rice, beans, and salsa.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Main Course",
        color: "indigo",
      },
      {
        text: "Mexican",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "flank steak",
        measurement: "1 pound, thinly sliced",
      },
      {
        name: "onion",
        measurement: "1, chopped",
      },
      {
        name: "green bell pepper",
        measurement: "1, chopped",
      },
      {
        name: "red bell pepper",
        measurement: "1, chopped",
      },
      {
        name: "garlic",
        measurement: "2 cloves, minced",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
      {
        name: "tortillas",
        measurement: "12",
      },
      {
        name: "rice",
        measurement: "1 cup, cooked",
      },
      {
        name: "black beans",
        measurement: "1 can (15 ounces), rinsed and drained",
      },
      {
        name: "salsa",
        measurement: "1 cup",
      },
    ],
  },
  {
    id: "dnr022",
    recipeUrl: "",
    name: "Chicken Fajitas",
    directions: `
            <ol>
              <li>Heat a large skillet or grill pan over medium-high heat.</li>
              <li>Season the chicken with salt, pepper, and cumin.</li>
              <li>Cook the chicken in the skillet or grill pan until browned on all sides.</li>
              <li>Remove the chicken from the pan and set aside.</li>
              <li>Add the onions and peppers to the pan and cook until softened, about 5 minutes.</li>
              <li>Add the garlic and cook for 1 minute more.</li>
              <li>Return the chicken to the pan and cook for an additional 2-3 minutes, or until the chicken is cooked through.</li>
              <li>Serve immediately with your favorite toppings, such as tortillas, rice, beans, and salsa.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Main Course",
        color: "indigo",
      },
      {
        text: "Mexican",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "skinless chicken breasts",
        measurement: "1 pound, cut into strips",
      },
      {
        name: "onion",
        measurement: "1, chopped",
      },
      {
        name: "green bell pepper",
        measurement: "1, chopped",
      },
      {
        name: "red bell pepper",
        measurement: "1, chopped",
      },
      {
        name: "garlic",
        measurement: "2 cloves, minced",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
      {
        name: "tortillas",
        measurement: "12",
      },
      {
        name: "rice",
        measurement: "1 cup, cooked",
      },
      {
        name: "can black beans",
        measurement: "1 can (15 ounces), rinsed and drained",
      },
      {
        name: "salsa",
        measurement: "1 cup",
      },
    ],
  },
  {
    id: "dnr023",
    recipeUrl: "",
    name: "Slow Cooker Chicken Pot Pie",
    directions: `
            <ol>
              <li>In a large bowl, combine the chicken, vegetables, broth, and seasonings.</li>
              <li>Transfer the mixture to a slow cooker and cook on low for 6-8 hours, or until the chicken is cooked through.</li>
              <li>In a small bowl, whisk together the flour and milk.</li>
              <li>Add the flour mixture to the slow cooker and cook on high for 15 minutes, or until the sauce has thickened.</li>
              <li>Stir in the peas and carrots and cook for an additional 5 minutes.</li>
              <li>Serve hot with your favorite toppings, such as mashed potatoes, biscuits, or rolls.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Main Course",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "skinless chicken breasts",
        measurement: "1 pound, cut into 1-inch pieces",
      },
      {
        name: "onion",
        measurement: "1 onion, chopped",
      },
      {
        name: "carrot",
        measurement: "1, chopped",
      },
      {
        name: "celery",
        measurement: "1 stalk, chopped",
      },
      {
        name: "can cream of chicken soup",
        measurement: "1 (10.75 ounce)",
      },
      {
        name: "can condensed cream of mushroom soup",
        measurement: "1",
      },
      {
        name: "chicken broth",
        measurement: "1 cup",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
      {
        name: "frozen peas",
        measurement: "1 cup",
      },
      {
        name: "frozen carrots",
        measurement: "1 cup",
      },
    ],
  },
  {
    id: "dnr024",
    recipeUrl: "",
    name: "Slow Cooker Pulled Pork",
    directions: `
            <ol>
              <li>In a slow cooker, combine the pork shoulder, barbecue sauce, and seasonings.</li>
              <li>Cook on low for 8-10 hours, or until the pork is tender and easily pulls apart with a fork.</li>
              <li>Shred the pork with two forks.</li>
              <li>Serve on buns with your favorite toppings, such as coleslaw, barbecue sauce, and pickles.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Main Course",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "bone-in pork shoulder",
        measurement: "3 pounds",
      },
      {
        name: "barbecue sauce",
        measurement: "1 cup",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
    ],
  },
  {
    id: "dnr025",
    recipeUrl: "",
    name: "Beef Burrito",
    directions: `
            <ol>
              <li>Heat a large skillet over medium heat.</li>
              <li>Add the ground beef and cook until browned.</li>
              <li>Drain off any excess grease.</li>
              <li>Add the taco seasoning mix and water according to the package directions.</li>
              <li>Bring to a boil, then reduce heat and simmer for 5 minutes, or until the sauce has thickened.</li>
              <li>Warm the tortillas in the microwave or in a pan over medium heat.</li>
              <li>To assemble the burritos, spread 1/2 cup of the beef mixture in the center of each tortilla.</li>
              <li>Top with your favorite toppings, such as cheese, lettuce, tomatoes, sour cream, and guacamole.</li>
              <li>Fold the tortillas in half, then in half again, to form a triangle.</li>
              <li>Serve immediately.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Main Course",
        color: "indigo",
      },
      {
        text: "Mexican",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "ground beef",
        measurement: "1 pound",
      },
      {
        name: "taco seasoning mix",
        measurement: "1 packet",
      },
      {
        name: "water",
        measurement: "1 cup",
      },
      {
        name: "flour tortillas",
        measurement: "8",
      },
      {
        name: "Shredded cheese",
        measurement: "1 cup",
      },
      {
        name: "Lettuce",
        measurement: "1 cup",
      },
      {
        name: "Tomatoes",
        measurement: "1 cup",
      },
      {
        name: "Sour cream",
        measurement: "1/2 cup",
      },
      {
        name: "Guacamole",
        measurement: "1/2 cup",
      },
    ],
  },
  {
    id: "dnr026",
    recipeUrl: "",
    name: "Chicken Burrito",
    directions: `
            <ol>
              <li>Heat a large skillet over medium heat.</li>
              <li>Add the chicken and cook until browned.</li>
              <li>Drain off any excess grease.</li>
              <li>Add the taco seasoning mix and water according to the package directions.</li>
              <li>Bring to a boil, then reduce heat and simmer for 5 minutes, or until the sauce has thickened.</li>
              <li>Warm the tortillas in the microwave or in a pan over medium heat.</li>
              <li>To assemble the burritos, spread 1/2 cup of the chicken mixture in the center of each tortilla.</li>
              <li>Top with your favorite toppings, such as cheese, lettuce, tomatoes, sour cream, and guacamole.</li>
              <li>Fold the tortillas in half, then in half again, to form a triangle.</li>
              <li>Serve immediately.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Main Course",
        color: "indigo",
      },
      {
        text: "Mexican",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "skinless chicken breasts",
        measurement: "1 pound, cooked and shredded",
      },
      {
        name: "taco seasoning mix",
        measurement: "1 packet",
      },
      {
        name: "water",
        measurement: "1 cup",
      },
      {
        name: "flour tortillas",
        measurement: "8",
      },
      {
        name: "Shredded cheese",
        measurement: "1 cup",
      },
      {
        name: "Lettuce",
        measurement: "1 cup",
      },
      {
        name: "Tomatoes",
        measurement: "1 cup",
      },
      {
        name: "Sour cream",
        measurement: "1/2 cup",
      },
      {
        name: "Guacamole",
        measurement: "1/2 cup",
      },
    ],
  },
  {
    id: "dnr027",
    recipeUrl: "",
    name: "Baked Chicken with Lemon and Herbs",
    directions: `
            <ol>
              <li>Preheat oven to 400 degrees F (200 degrees C).</li>
              <li>Rinse chicken breasts and pat dry.</li>
              <li>In a small bowl, combine olive oil, lemon juice, garlic, thyme, and rosemary.</li>
              <li>Brush chicken breasts with the olive oil mixture.</li>
              <li>Place chicken breasts in a greased 9x13 inch baking dish.</li>
              <li>Bake in preheated oven for 20-25 minutes, or until chicken is cooked through.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Main Course",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "boneless, skinless chicken breasts",
        measurement: "4",
      },
      {
        name: "olive oil",
        measurement: "2 tablespoons",
      },
      {
        name: "lemon juice",
        measurement: "1 tablespoon",
      },
      {
        name: "garlic",
        measurement: "2 cloves, minced",
      },
      {
        name: "dried thyme",
        measurement: "1 teaspoon",
      },
      {
        name: "dried rosemary",
        measurement: "1 teaspoon",
      },
    ],
  },
  {
    id: "dnr028",
    recipeUrl: "",
    name: "Baked Salmon with Lemon and Dill",
    directions: `
            <ol>
              <li>Preheat oven to 400 degrees F (200 degrees C).</li>
              <li>Rinse salmon fillets and pat dry.</li>
              <li>In a small bowl, combine lemon juice, olive oil, dill, salt, and pepper.</li>
              <li>Brush salmon fillets with the lemon juice mixture.</li>
              <li>Place salmon fillets in a greased 9x13 inch baking dish.</li>
              <li>Bake in preheated oven for 15-20 minutes, or until salmon is cooked through.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Main Course",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "salmon fillets",
        measurement: "2",
      },
      {
        name: "lemon juice",
        measurement: "1 tablespoon",
      },
      {
        name: "olive oil",
        measurement: "2 tablespoons",
      },
      {
        name: "fresh dill",
        measurement: "1 tablespoon, chopped",
      },
      {
        name: "salt",
        measurement: "1/2 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/4 teaspoon",
      },
    ],
  },
  {
    id: "dnr029",
    recipeUrl: "",
    name: "Baked Cod with Garlic and Herbs",
    directions: `
            <ol>
              <li>Preheat oven to 400 degrees F (200 degrees C).</li>
              <li>Rinse cod fillets and pat dry.</li>
              <li>In a small bowl, combine garlic, olive oil, thyme, rosemary, salt, and pepper.</li>
              <li>Brush cod fillets with the garlic mixture.</li>
              <li>Place cod fillets in a greased 9x13 inch baking dish.</li>
              <li>Bake in preheated oven for 15-20 minutes, or until cod is cooked through.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Main Course",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "cod fillets",
        measurement: "2",
      },
      {
        name: "garlic",
        measurement: "2 cloves, minced",
      },
      {
        name: "olive oil",
        measurement: "2 tablespoons",
      },
      {
        name: "dried thyme",
        measurement: "1 teaspoon",
      },
      {
        name: "dried rosemary",
        measurement: "1 teaspoon",
      },
      {
        name: "salt",
        measurement: "1/2 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/4 teaspoon",
      },
    ],
  },
  {
    id: "dnr030",
    recipeUrl: "",
    name: "Chicken Fried Rice",
    directions: `
            <ol>
              <li>Cook the rice according to the package directions.</li>
              <li>In a large skillet or wok, heat the oil over medium heat.</li>
              <li>Add the chicken and cook until browned.</li>
              <li>Add the vegetables and cook until softened.</li>
              <li>Add the cooked rice, soy sauce, and sesame oil.</li>
              <li>Cook until heated through, about 5 minutes.</li>
              <li>Serve immediately.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Main Course",
        color: "indigo",
      },
      {
        text: "Asian",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "rice",
        measurement: "1 cup, cooked",
      },
      {
        name: "chicken",
        measurement: "1/2 cup, cooked and shredded",
      },
      {
        name: "vegetables (carrots, celery, onions)",
        measurement: "1/2 cup, chopped",
      },
      {
        name: "soy sauce",
        measurement: "2 tablespoons",
      },
      {
        name: "sesame oil",
        measurement: "1 tablespoon",
      },
    ],
  },
  {
    id: "dnr031",
    recipeUrl: "",
    name: "Stir-Fried Shrimp with Broccoli",
    directions: `
            <ol>
              <li>Heat the oil in a large skillet or wok over medium-high heat.</li>
              <li>Add the shrimp and cook until pink and cooked through.</li>
              <li>Remove the shrimp from the skillet and set aside.</li>
              <li>Add the broccoli to the skillet and cook until softened.</li>
              <li>Add the garlic and ginger and cook for 1 minute.</li>
              <li>Add the soy sauce, sesame oil, and honey and cook for 1 minute more.</li>
              <li>Return the shrimp to the skillet and cook until heated through.</li>
              <li>Serve immediately.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Main Course",
        color: "indigo",
      },
      {
        text: "Asian",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "shrimp",
        measurement: "1 pound, peeled and deveined",
      },
      {
        name: "vegetable oil",
        measurement: "1 tablespoon",
      },
      {
        name: "broccoli",
        measurement: "1 head, cut into florets",
      },
      {
        name: "garlic",
        measurement: "2 cloves, minced",
      },
      {
        name: "ginger",
        measurement: "1 teaspoon, grated",
      },
      {
        name: "soy sauce",
        measurement: "2 tablespoons",
      },
      {
        name: "sesame oil",
        measurement: "1 tablespoon",
      },
      {
        name: "honey",
        measurement: "1 tablespoon",
      },
    ],
  },
  {
    id: "dnr032",
    recipeUrl: "",
    name: "Souvlaki",
    directions: `
            <ol>
              <li>Preheat the oven to 400 degrees F (200 degrees C).</li>
              <li>In a large bowl, combine the chicken, olive oil, lemon juice, oregano, garlic, salt, and pepper.</li>
              <li>Marinate the chicken for at least 30 minutes, or up to overnight.</li>
              <li>Thread the chicken onto skewers.</li>
              <li>Grill the chicken for 10-12 minutes per side, or until cooked through.</li>
              <li>Serve with your favorite sides, such as pita bread, tzatziki sauce, and grilled vegetables.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Main Course",
        color: "indigo",
      },
      {
        text: "Greek",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "skinless chicken breasts",
        measurement: "1 pound, cut into 1-inch cubes",
      },
      {
        name: "olive oil",
        measurement: "1/4 cup",
      },
      {
        name: "lemon juice",
        measurement: "1/4 cup",
      },
      {
        name: "dried oregano",
        measurement: "1 tablespoon",
      },
      {
        name: "garlic",
        measurement: "2 cloves, minced",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
      {
        name: "wooden skewers",
        measurement: "12",
      },
    ],
  },
  {
    id: "dnr033",
    recipeUrl: "",
    name: "Tzatziki Sauce",
    directions: `
            <ol>
              <li>In a medium bowl, combine the yogurt, cucumber, garlic, lemon juice, olive oil, salt, and pepper.</li>
              <li>Mix until well combined.</li>
              <li>Cover and refrigerate for at least 30 minutes, or up to overnight.</li>
              <li>Serve with your favorite grilled meats or vegetables.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Side Dish",
        color: "indigo",
      },
      {
        text: "Greek",
        color: "indigo",
      },
      {
        text: "Dip",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "plain yogurt",
        measurement: "1 cup",
      },
      {
        name: "cucumber",
        measurement: "1, peeled, seeded, and grated",
      },
      {
        name: "garlic",
        measurement: "2, minced",
      },
      {
        name: "lemon juice",
        measurement: "2 tablespoons",
      },
      {
        name: "olive oil",
        measurement: "2 tablespoons",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
    ],
  },
  {
    id: "dnr034",
    recipeUrl: "",
    name: "Caprese Salad",
    directions: `
            <ol>
              <li>Wash and dry the tomatoes, mozzarella, and basil leaves.</li>
              <li>Arrange the tomatoes, mozzarella, and basil leaves on a serving platter.</li>
              <li>Drizzle with olive oil and balsamic vinegar.</li>
              <li>Season with salt and pepper to taste.</li>
              <li>Serve immediately.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Salad",
        color: "indigo",
      },
      {
        text: "Italian",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "ripe tomatoes",
        measurement: "2",
      },
      {
        name: "ball mozzarella cheese",
        measurement: "1 ball",
      },
      {
        name: "basil leaves",
        measurement: "1 handful",
      },
      {
        name: "olive oil",
        measurement: "2 tablespoons",
      },
      {
        name: "balsamic vinegar",
        measurement: "1 tablespoon",
      },
      {
        name: "Salt",
        measurement: "1/2 teaspoon",
      },
      {
        name: "Pepper",
        measurement: "1/4 teaspoon",
      },
    ],
  },
  {
    id: "dnr035",
    recipeUrl: "",
    name: "Greek Salad",
    directions: `
            <ol>
              <li>Wash and dry the tomatoes, cucumbers, onions, and olives.</li>
              <li>Chop the tomatoes, cucumbers, onions, and olives into bite-sized pieces.</li>
              <li>In a large bowl, combine the tomatoes, cucumbers, onions, olives, feta cheese, and oregano.</li>
              <li>Drizzle with olive oil and red wine vinegar.</li>
              <li>Season with salt and pepper to taste.</li>
              <li>Serve immediately.
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Salad",
        color: "indigo",
      },
      {
        text: "Greek",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "tomatoes",
        measurement: "2",
      },
      {
        name: "cucumber",
        measurement: "1",
      },
      {
        name: "onion",
        measurement: "1/2",
      },
      {
        name: "pitted black olives",
        measurement: "1/2 cup",
      },
      {
        name: "crumbled feta cheese",
        measurement: "1/2 cup",
      },
      {
        name: "dried oregano",
        measurement: "1 tablespoon",
      },
      {
        name: "olive oil",
        measurement: "2 tablespoons",
      },
      {
        name: "red wine vinegar",
        measurement: "1 tablespoon",
      },
      {
        name: "Salt",
        measurement: "1/2 teaspoon",
      },
      {
        name: "Pepper",
        measurement: "1/4 teaspoon",
      },
    ],
  },
  {
    id: "dnr036",
    recipeUrl: "",
    name: "Caesar Salad",
    directions: `
            <ol>
              <li>Croutons: Preheat oven to 350 degrees F (175 degrees C). Cut the bread into 1-inch cubes. Toss with olive oil, salt, and pepper. Spread on a baking sheet and bake for 10-12 minutes, or until golden brown.</li>
              <li>Dressing: In a food processor, combine the anchovies, garlic, egg yolks, Dijon mustard, Worcestershire sauce, lemon juice, olive oil, and Parmesan cheese. Process until smooth. Season with salt and pepper to taste.</li>
              <li>Salad: In a large bowl, combine the romaine lettuce, croutons, and dressing. Toss to coat.</li>
              <li>Garnish: Garnish with Parmesan cheese and croutons.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Salad",
        color: "indigo",
      },
      {
        text: "Italian",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "French bread",
        measurement: "1 loaf",
      },
      {
        name: "olive oil",
        measurement: "1/4 cup",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
      {
        name: "grated Parmesan cheese",
        measurement: "1/2 cup",
      },
      {
        name: "mayonnaise",
        measurement: "1/4 cup",
      },
      {
        name: "Dijon mustard",
        measurement: "2 tablespoons",
      },
      {
        name: "Worcestershire sauce",
        measurement: "1 teaspoon",
      },
      {
        name: "lemon juice",
        measurement: "1 tablespoon",
      },
      {
        name: "romaine lettuce",
        measurement: "1 head",
      },
    ],
  },
  {
    id: "dnr037",
    recipeUrl: "",
    name: "Chicken Noodle Soup",
    directions: `
            <ol>
              <li>In a large pot, heat the olive oil over medium heat.</li>
              <li>Add the onion and carrots and cook until softened, about 5 minutes.</li>
              <li>Add the celery and garlic and cook for 1 minute more.</li>
              <li>Add the chicken broth, water, and salt and bring to a boil.</li>
              <li>Reduce heat to low and simmer for 15 minutes.</li>
              <li>Add the noodles and cook until they are al dente, about 5 minutes.</li>
              <li>Add the chicken and cook until heated through, about 1 minute more.</li>
              <li>Season with pepper to taste.</li>
              <li>Ladle into bowls and serve hot.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Soup",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "olive oil",
        measurement: "1 tablespoon",
      },
      {
        name: "onion",
        measurement: "1, chopped",
      },
      {
        name: "carrots",
        measurement: "2, chopped",
      },
      {
        name: "celery",
        measurement: "2 stalks, chopped",
      },
      {
        name: "garlic",
        measurement: "2 cloves, minced",
      },
      {
        name: "chicken broth",
        measurement: "6 cups",
      },
      {
        name: "water",
        measurement: "4 cups",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "egg noodles",
        measurement: "12 ounces",
      },
      {
        name: "chicken breast",
        measurement: "1 cup cooked and shredded",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
    ],
  },
  {
    id: "dnr038",
    recipeUrl: "",
    name: "Clam Chowder",
    directions: `
            <ol>
              <li>In a large pot, heat the olive oil over medium heat.</li>
              <li>Add the onion and cook until softened, about 5 minutes.</li>
              <li>Add the garlic and cook for 1 minute more.</li>
              <li>Add the clams, broth, potatoes, and bay leaf and bring to a boil.</li>
              <li>Reduce heat to low and simmer for 20 minutes, or until the clams are open.</li>
              <li>Remove the bay leaf and stir in the cream, salt, and pepper.</li>
              <li>Ladle into bowls and serve hot.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Soup",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "olive oil",
        measurement: "1 tablespoon",
      },
      {
        name: "onion",
        measurement: "1, chopped",
      },
      {
        name: "garlic",
        measurement: "2 cloves, minced",
      },
      {
        name: "can clams",
        measurement: "1 (12 ounce), minced",
      },
      {
        name: "chicken broth",
        measurement: "6 cups",
      },
      {
        name: "potatoes",
        measurement: "2, peeled and diced",
      },
      {
        name: "bay leaf",
        measurement: "1 bay leaf",
      },
      {
        name: "heavy cream",
        measurement: "1 cup",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
    ],
  },
  {
    id: "dnr039",
    recipeUrl: "",
    name: "Tomato Soup",
    directions: `
            <ol>
              <li>In a large pot, heat the olive oil over medium heat.</li>
              <li>Add the onion and cook until softened, about 5 minutes.</li>
              <li>Add the garlic and cook for 1 minute more.</li>
              <li>Add the tomatoes, tomato juice, broth, and bay leaf and bring to a boil.</li>
              <li>Reduce heat to low and simmer for 30 minutes.</li>
              <li>Remove the bay leaf and puree the soup with an immersion blender or in a blender until smooth.</li>
              <li>Season with salt and pepper to taste.</li>
              <li>Ladle into bowls and serve hot.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Soup",
        color: "indigo",
      },
      {
        text: "Italian",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "olive oil",
        measurement: "1 tablespoon",
      },
      {
        name: "onion",
        measurement: "1, chopped",
      },
      {
        name: "garlic",
        measurement: "2 cloves, minced",
      },
      {
        name: "cans diced tomatoes",
        measurement: "2 (14.5 ounce), undrained",
      },
      {
        name: "can tomato juice",
        measurement: "1 (14.5 ounce) can",
      },
      {
        name: "chicken broth",
        measurement: "6 cups",
      },
      {
        name: "bay leaf",
        measurement: "1 bay leaf",
      },
      {
        name: "heavy cream",
        measurement: "1 cup",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
    ],
  },
  {
    id: "dnr040",
    recipeUrl: "",
    name: "Potato Leek Soup",
    directions: `
            <ol>
              <li>Heat the olive oil in a large pot over medium heat.</li>
              <li>Add the leeks and cook until softened, about 5 minutes.</li>
              <li>Add the garlic and cook for 1 minute more.</li>
              <li>Add the potatoes, broth, and bay leaf and bring to a boil.</li>
              <li>Reduce heat to low and simmer for 20 minutes, or until the potatoes are fork-tender.</li>
              <li>Remove the bay leaf and puree the soup with an immersion blender or in a blender until smooth.</li>
              <li>Season with salt and pepper to taste.</li>
              <li>Ladle into bowls and serve hot.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Soup",
        color: "indigo",
      },
      {
        text: "French",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "olive oil",
        measurement: "2 tablespoons",
      },
      {
        name: "leeks",
        measurement: "2, white and light green parts only, chopped",
      },
      {
        name: "garlic",
        measurement: "2 cloves, minced",
      },
      {
        name: "Yukon Gold potatoes",
        measurement: "1 pound, peeled and diced",
      },
      {
        name: "chicken or vegetable broth",
        measurement: "6 cups",
      },
      {
        name: "bay leaf",
        measurement: "1 bay leaf",
      },
      {
        name: "heavy cream",
        measurement: "1/2 cup",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
    ],
  },
  {
    id: "dnr041",
    recipeUrl: "",
    name: "Minestrone Soup",
    directions: `
            <ol>
              <li>Heat the olive oil in a large pot over medium heat.</li>
              <li>Add the onion, carrots, celery, and garlic and cook until softened, about 5 minutes.</li>
              <li>Add the tomatoes, tomato juice, broth, beans, pasta, and bay leaf and bring to a boil.</li>
              <li>Reduce heat to low and simmer for 30 minutes, or until the pasta is cooked through.</li>
              <li>Remove the bay leaf and stir in the Parmesan cheese.</li>
              <li>Season with salt and pepper to taste.</li>
              <li>Ladle into bowls and serve hot.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Soup",
        color: "indigo",
      },
      {
        text: "Italian",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "olive oil",
        measurement: "2 tablespoons",
      },
      {
        name: "onion",
        measurement: "1, chopped",
      },
      {
        name: "carrots",
        measurement: "2, chopped",
      },
      {
        name: "celery",
        measurement: "2 stalks, chopped",
      },
      {
        name: "garlic",
        measurement: "2 cloves, minced",
      },
      {
        name: "can diced tomatoes",
        measurement: "1 (28 ounce), undrained",
      },
      {
        name: "can tomato juice",
        measurement: "1 (14.5 ounce)",
      },
      {
        name: "chicken or vegetable broth",
        measurement: "6 cups",
      },
      {
        name: "can cannellini beans",
        measurement: "1 (15 ounce), drained and rinsed",
      },
      {
        name: "can diced tomatoes with green chilies",
        measurement: "1 (15 ounce), undrained",
      },
      {
        name: "small pasta, such as ditalini or macaroni",
        measurement: "1 cup",
      },
      {
        name: "bay leaf",
        measurement: "1",
      },
      {
        name: "grated Parmesan cheese",
        measurement: "1/2 cup",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
    ],
  },
  {
    id: "dnr042",
    recipeUrl: "",
    name: "Chocolate Chip Cookies",
    directions: `
            <ol>
              <li>Preheat oven to 375 degrees F (190 degrees C).</li>
              <li>Cream together the butter, brown sugar, and white sugar until light and fluffy.</li>
              <li>Beat in the eggs one at a time, then stir in the vanilla.</li>
              <li>In a separate bowl, whisk together the flour, baking soda, and salt.</li>
              <li>Gradually add the dry ingredients to the wet ingredients, mixing until just combined.</li>
              <li>Fold in the chocolate chips.</li>
              <li>Drop by rounded tablespoons onto ungreased baking sheets.</li>
              <li>Bake for 10-12 minutes, or until golden brown.</li>
              <li>Let cool on baking sheets for a few minutes before transferring to a wire rack to cool completely.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Cookie",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Dessert",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "unsalted butter",
        measurement: "1 cup (2 sticks), softened",
      },
      {
        name: "light brown sugar",
        measurement: "1 cup packed",
      },
      {
        name: "granulated sugar",
        measurement: "1/2 cup",
      },
      {
        name: "large eggs",
        measurement: "2",
      },
      {
        name: "pure vanilla extract",
        measurement: "2 teaspoons",
      },
      {
        name: "all-purpose flour",
        measurement: "2 1/4 cups",
      },
      {
        name: "baking soda",
        measurement: "1 teaspoon",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "semisweet chocolate chips",
        measurement: "1 cup",
      },
    ],
  },
  {
    id: "dnr043",
    recipeUrl: "",
    name: "Sugar Cookies",
    directions: `
            <ol>
              <li>Preheat oven to 350 degrees F (175 degrees C).</li>
              <li>Cream together the butter, sugar, and eggs until light and fluffy.</li>
              <li>In a separate bowl, whisk together the flour, baking soda, and salt.</li>
              <li>Gradually add the dry ingredients to the wet ingredients, mixing until just combined.</li>
              <li>Chill the dough for at least 30 minutes.</li>
              <li>Roll out the dough on a lightly floured surface to 1/4-inch thickness.</li>
              <li>Cut out cookies with a cookie cutter and place them on ungreased baking sheets.</li>
              <li>Bake for 10-12 minutes, or until golden brown.</li>
              <li>Let cool on baking sheets for a few minutes before transferring to a wire rack to cool completely.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Cookie",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Dessert",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "unsalted butter",
        measurement: "1 cup (2 sticks), softened",
      },
      {
        name: "granulated sugar",
        measurement: "1 cup",
      },
      {
        name: "large egg",
        measurement: "1 large egg",
      },
      {
        name: "pure vanilla extract",
        measurement: "1 teaspoon",
      },
      {
        name: "all-purpose flour",
        measurement: "2 1/4 cups",
      },
      {
        name: "baking soda",
        measurement: "1 teaspoon",
      },
      {
        name: "salt",
        measurement: "1/2 teaspoon",
      },
    ],
  },
  {
    id: "dnr044",
    recipeUrl: "",
    name: "Classic Brownies",
    directions: `
            <ol>
              <li>Preheat oven to 350 degrees F (175 degrees C).</li>
              <li>Grease and flour an 8x8 inch baking pan.</li>
              <li>In a large bowl, cream together the butter and sugar until light and fluffy.</li>
              <li>Beat in the eggs one at a time, then stir in the vanilla.</li>
              <li>In a separate bowl, whisk together the flour, cocoa powder, baking powder, and salt.</li>
              <li>Gradually add the dry ingredients to the wet ingredients, mixing until just combined.</li>
              <li>Pour the batter into the prepared pan and bake for 25-30 minutes, or until a toothpick inserted into the center comes out clean.</li>
              <li>Let the brownies cool completely before cutting into squares and serving.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Brownie",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Dessert",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "unsalted butter",
        measurement: "1 cup (2 sticks), softened",
      },
      {
        name: "granulated sugar",
        measurement: "1 cup",
      },
      {
        name: "large eggs",
        measurement: "2",
      },
      {
        name: "pure vanilla extract",
        measurement: "1 teaspoon",
      },
      {
        name: "all-purpose flour",
        measurement: "1 cup",
      },
      {
        name: "unsweetened cocoa powder",
        measurement: "1/2 cup",
      },
      {
        name: "baking powder",
        measurement: "1 teaspoon",
      },
      {
        name: "salt",
        measurement: "1/2 teaspoon",
      },
    ],
  },
  {
    id: "dnr045",
    recipeUrl: "",
    name: "Baked Ribs",
    directions: `
            <ol>
              <li>Preheat oven to 300 degrees F (150 degrees C).</li>
              <li>In a large bowl, combine the ribs, barbecue sauce, brown sugar, and garlic powder.</li>
              <li>Cover and refrigerate for at least 2 hours, or overnight.</li>
              <li>Place the ribs in a baking dish and bake for 2-2 1/2 hours, or until the meat is tender and falling off the bone.</li>
              <li>Brush with more barbecue sauce during the last 30 minutes of cooking.</li>
              <li>Serve immediately with your favorite sides.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Ribs",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "racks pork ribs",
        measurement: "2",
      },
      {
        name: "barbecue sauce",
        measurement: "1 cup",
      },
      {
        name: "brown sugar",
        measurement: "1/2 cup",
      },
      {
        name: "garlic powder",
        measurement: "1 teaspoon",
      },
    ],
  },
  {
    id: "dnr046",
    recipeUrl: "",
    name: "Grilled Lamb Chops",
    directions: `
            <ol>
              <li>Preheat grill to medium-high heat.</li>
              <li>Season lamb chops with salt and pepper.</li>
              <li>Grill lamb chops for 3-4 minutes per side, or until cooked to desired doneness.</li>
              <li>Let lamb chops rest for a few minutes before serving.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Lamb",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "lamb chops",
        measurement: "4",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
    ],
  },
  {
    id: "dnr047",
    recipeUrl: "",
    name: "Lamb Curry",
    directions: `
            <ol>
              <li>Heat the oil in a large pot over medium heat.</li>
              <li>Add the onion and cook until softened, about 5 minutes.</li>
              <li>Add the garlic and ginger and cook for 1 minute more.</li>
              <li>Add the lamb and cook until browned on all sides.</li>
              <li>Add the tomatoes, coconut milk, curry powder, turmeric, and garam masala.</li>
              <li>Bring to a boil, then reduce heat to low and simmer for 1 hour, or until the lamb is cooked through.</li>
              <li>Serve with rice or naan bread.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Lamb",
        color: "indigo",
      },
      {
        text: "Indian",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "olive oil",
        measurement: "1 tablespoon",
      },
      {
        name: "onion",
        measurement: "1, chopped",
      },
      {
        name: "garlic",
        measurement: "2 cloves, minced",
      },
      {
        name: "lamb stew meat",
        measurement: "1 pound, cut into 1-inch cubes",
      },
      {
        name: "can diced tomatoes",
        measurement: "1 (14.5 ounce), undrained",
      },
      {
        name: "can coconut milk",
        measurement: "1 (13.5 ounce)",
      },
      {
        name: "curry powder",
        measurement: "1 tablespoon",
      },
      {
        name: "turmeric powder",
        measurement: "1 teaspoon",
      },
      {
        name: "garam masala",
        measurement: "1 teaspoon",
      },
      {
        name: "salt",
        measurement: "1/2 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/4 teaspoon",
      },
    ],
  },
  {
    id: "dnr048",
    recipeUrl: "",
    name: "Popcorn",
    directions: `
            <ol>
              <li>Heat 1 tablespoon of oil in a large pot over medium heat.</li>
              <li>Add 1/2 cup of popcorn kernels and cover the pot with a lid.</li>
              <li>Cook the popcorn, shaking the pot occasionally, until the popping slows down.</li>
              <li>Remove the pot from the heat and let the popcorn cool for a few minutes.</li>
              <li>Pour the popcorn into a large bowl and add your favorite toppings, such as butter, salt, or cheese.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Popcorn",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Snack",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "oil",
        measurement: "1 tablespoon",
      },
      {
        name: "popcorn kernels",
        measurement: "1/2 cup",
      },
      {
        name: "Butter",
        measurement: "To taste",
      },
      {
        name: "Salt",
        measurement: "To taste",
      },
      {
        name: "Cheese",
        measurement: "To taste",
      },
    ],
  },
  {
    id: "dnr049",
    recipeUrl: "",
    name: "Nachos",
    directions: `
            <ol>
              <li>Preheat oven to 400 degrees F (200 degrees C).</li>
              <li>Spread tortilla chips on a baking sheet.</li>
              <li>Top with your favorite toppings, such as cheese, beans, meat, vegetables, and sour cream.</li>
              <li>Bake for 10-15 minutes, or until the cheese is melted and bubbly.</li>
              <li>Serve immediately and enjoy!</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Nachos",
        color: "indigo",
      },
      {
        text: "Mexican",
        color: "indigo",
      },
      {
        text: "Appetizer",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "tortilla chips",
        measurement: "1 bag",
      },
      {
        name: "shredded cheese",
        measurement: "1 cup",
      },
      {
        name: "can refried beans",
        measurement: "1",
      },
      {
        name: "ground beef",
        measurement: "1 pound",
      },
      {
        name: "onion",
        measurement: "1, chopped",
      },
      {
        name: "green pepper",
        measurement: "1, chopped",
      },
      {
        name: "can diced tomatoes",
        measurement: "1 (14.5 ounce), undrained",
      },
      {
        name: "can diced green chilies",
        measurement: "1 (10 ounce) can, undrained",
      },
      {
        name: "sour cream",
        measurement: "1/2 cup",
      },
      {
        name: "cilantro",
        measurement: "1/4 cup, chopped",
      },
    ],
  },
  {
    id: "dnr050",
    recipeUrl: "",
    name: "Tuna Noodle Casserole",
    directions: `
            <ol>
              <li>Preheat oven to 350 degrees F (175 degrees C).</li>
              <li>Grease a 9x13 inch baking dish.</li>
              <li>In a large bowl, combine tuna, noodles, soup, milk, and cheese.</li>
              <li>Pour into prepared baking dish.</li>
              <li>Bake for 30 minutes, or until bubbly and heated through.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Tuna Noodle Casserole",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Dinner",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "can (12 ounces) tuna",
        measurement: "1, drained",
      },
      {
        name: "elbow macaroni",
        measurement: "1 cup cooked",
      },
      {
        name: "can (10.75 ounces) condensed cream of mushroom soup",
        measurement: "1",
      },
      {
        name: "milk",
        measurement: "1 cup",
      },
      {
        name: "shredded cheddar cheese",
        measurement: "1 cup",
      },
    ],
  },
  {
    id: "dnr051",
    recipeUrl: "",
    name: "Grilled Salmon",
    directions: `
            <ol>
              <li>Preheat grill to medium-high heat.</li>
              <li>Season salmon fillets with salt and pepper.</li>
              <li>Grill salmon for 4-5 minutes per side, or until cooked through.</li>
              <li>Serve immediately with your favorite sides.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Grilled Salmon",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "salmon fillets (about 6 ounces each)",
        measurement: "2",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
    ],
  },
  {
    id: "dnr052",
    recipeUrl: "",
    name: "Salmon with Lemon Butter Sauce",
    directions: `
            <ol>
              <li>Preheat oven to 400 degrees F (200 degrees C).</li>
              <li>In a small bowl, whisk together the butter, lemon juice, lemon zest, salt, and pepper.</li>
              <li>Place the salmon fillets in a baking dish and brush with the lemon butter sauce.</li>
              <li>Bake for 12-15 minutes, or until the salmon is cooked through.</li>
              <li>Serve immediately with your favorite sides.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Salmon with Lemon Butter Sauce",
        color: "indigo",
      },
      {
        text: "French",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "salmon fillets (about 6 ounces each)",
        measurement: "2",
      },
      {
        name: "butter",
        measurement: "1/4 cup, melted",
      },
      {
        name: "lemon juice",
        measurement: "1 tablespoon",
      },
      {
        name: "lemon",
        measurement: "1 teaspoon, zest",
      },
      {
        name: "salt",
        measurement: "1/2 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/4 teaspoon",
      },
    ],
  },
  {
    id: "dnr053",
    recipeUrl: "",
    name: "Shrimp Scampi",
    directions: `
            <ol>
              <li>In a large skillet, heat the olive oil over medium heat.</li>
              <li>Add the garlic and cook until fragrant, about 1 minute.</li>
              <li>Add the shrimp and cook until pink and cooked through, about 2 minutes per side.</li>
              <li>Add the white wine and cook until reduced by half, about 2 minutes.</li>
              <li>Add the lemon juice, butter, salt, and pepper.</li>
              <li>Cook until the butter is melted and the sauce is thickened, about 1 minute.</li>
              <li>Serve immediately over pasta.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Shrimp Scampi",
        color: "indigo",
      },
      {
        text: "Italian",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "olive oil",
        measurement: "1 tablespoon",
      },
      {
        name: "garlic",
        measurement: "2 cloves, minced",
      },
      {
        name: "shrimp",
        measurement: "1 pound, peeled and deveined",
      },
      {
        name: "white wine",
        measurement: "1/2 cup",
      },
      {
        name: "lemon juice",
        measurement: "2 tablespoons",
      },
      {
        name: "butter",
        measurement: "2 tablespoons",
      },
      {
        name: "salt",
        measurement: "1/2 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/4 teaspoon",
      },
    ],
  },
  {
    id: "dnr054",
    recipeUrl: "",
    name: "Grilled Shrimp",
    directions: `
            <ol>
              <li>Preheat grill to medium-high heat.</li>
              <li>In a large bowl, combine the shrimp, olive oil, garlic, lemon juice, salt, and pepper.</li>
              <li>Marinate the shrimp for at least 30 minutes, or up to overnight.</li>
              <li>Grill the shrimp for 2-3 minutes per side, or until cooked through.</li>
              <li>Serve immediately with your favorite sides.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Grilled Shrimp",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "shrimp",
        measurement: "1 pound, peeled and deveined",
      },
      {
        name: "olive oil",
        measurement: "2 tablespoons",
      },
      {
        name: "garlic",
        measurement: "2 cloves, minced",
      },
      {
        name: "lemon juice",
        measurement: "1 tablespoon",
      },
      {
        name: "salt",
        measurement: "1/2 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/4 teaspoon",
      },
    ],
  },
  {
    id: "dnr055",
    recipeUrl: "",
    name: "Grilled Ribeye Steak",
    directions: `
            <ol>
              <li>Preheat grill to medium-high heat.</li>
              <li>Season the steak with salt and pepper.</li>
              <li>Grill the steak for 4-5 minutes per side for medium-rare, or to your desired doneness.</li>
              <li>Let the steak rest for 5 minutes before slicing and serving.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Grilled Ribeye Steak",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "1 (1-inch thick) ribeye steak",
        measurement: "1",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
    ],
  },
  {
    id: "dnr056",
    recipeUrl: "",
    name: "Grilled Filet Mignon",
    directions: `
            <ol>
              <li>Preheat grill to medium-high heat.</li>
              <li>Season the filet mignon with salt and pepper.</li>
              <li>Grill the filet mignon for 3-4 minutes per side for medium-rare, or to your desired doneness.</li>
              <li>Let the filet mignon rest for 5 minutes before slicing and serving.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Grilled Filet Mignon",
        color: "indigo",
      },
      {
        text: "French",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "1 (1-inch thick) filet mignon",
        measurement: "1",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
    ],
  },
  {
    id: "dnr057",
    recipeUrl: "",
    name: "Vanilla Cupcakes",
    directions: `
            <ol>
              <li>Preheat oven to 350 degrees F (175 degrees C).</li>
              <li>Line a muffin tin with paper liners.</li>
              <li>In a large bowl, cream together the butter and sugar until light and fluffy.</li>
              <li>Beat in the eggs one at a time, then stir in the vanilla.</li>
              <li>In a separate bowl, whisk together the flour, baking powder, and salt.</li>
              <li>Add the dry ingredients to the wet ingredients alternately with the milk, beginning and ending with the dry ingredients.</li>
              <li>Fill each muffin liner about 2/3 full.</li>
              <li>Bake for 20-25 minutes, or until a toothpick inserted into the center comes out clean.</li>
              <li>Let cool completely before frosting.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Vanilla Cupcakes",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Dessert",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "unsalted butter",
        measurement: "1 cup (2 sticks), softened",
      },
      {
        name: "granulated sugar",
        measurement: "1 3/4 cups",
      },
      {
        name: "large eggs",
        measurement: "3",
      },
      {
        name: "pure vanilla extract",
        measurement: "2 teaspoons",
      },
      {
        name: "all-purpose flour",
        measurement: "2 1/4 cups",
      },
      {
        name: "baking powder",
        measurement: "1 teaspoon",
      },
      {
        name: "salt",
        measurement: "1/2 teaspoon",
      },
      {
        name: "milk",
        measurement: "1 cup",
      },
    ],
  },
  {
    id: "dnr058",
    recipeUrl: "",
    name: "Beef Enchiladas",
    directions: `
            <ol>
              <li>Preheat oven to 350 degrees F (175 degrees C).</li>
              <li>In a large skillet, brown the ground beef over medium heat.</li>
              <li>Drain off any excess grease.</li>
              <li>Stir in the taco seasoning mix and water according to package directions.</li>
              <li>Simmer for 5 minutes, or until the sauce is thickened.</li>
              <li>In a medium bowl, combine the shredded cheese, sour cream, and salsa.</li>
              <li>Spread about 1/2 cup of the beef mixture in the center of each tortilla.</li>
              <li>Top with the cheese mixture and roll up the tortillas.</li>
              <li>Place the enchiladas in a greased 9x13 inch baking dish.</li>
              <li>Pour the remaining beef mixture over the enchiladas.</li>
              <li>Sprinkle with the remaining cheese.</li>
              <li>Bake for 20-25 minutes, or until the cheese is melted and bubbly.</li>
              <li>Let cool for a few minutes before serving.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Beef Enchiladas",
        color: "indigo",
      },
      {
        text: "Mexican",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "ground beef",
        measurement: "1 pound",
      },
      {
        name: "taco seasoning mix",
        measurement: "1 packet",
      },
      {
        name: "water",
        measurement: "1 cup",
      },
      {
        name: "shredded cheddar cheese",
        measurement: "1 cup",
      },
      {
        name: "sour cream",
        measurement: "1/2 cup",
      },
      {
        name: "salsa",
        measurement: "1/2 cup",
      },
      {
        name: "corn tortillas",
        measurement: "12",
      },
    ],
  },
  {
    id: "dnr060",
    recipeUrl: "",
    name: "Chicken Enchiladas",
    directions: `
            <ol>
              <li>Preheat oven to 350 degrees F (175 degrees C).</li>
              <li>Heat 1 tablespoon of olive oil in a large skillet over medium heat.</li>
              <li>Add the chicken and cook until browned on all sides.</li>
              <li>Drain off any excess grease.</li>
              <li>Stir in the taco seasoning mix and water according to package directions.</li>
              <li>Simmer for 5 minutes, or until the sauce is thickened.</li>
              <li>In a medium bowl, combine the shredded cheese, sour cream, and salsa.</li>
              <li>Spread about 1/2 cup of the chicken mixture in the center of each tortilla.</li>
              <li>Top with the cheese mixture and roll up the tortillas.</li>
              <li>Place the enchiladas in a greased 9x13 inch baking dish.</li>
              <li>Pour the remaining chicken mixture over the enchiladas.</li>
              <li>Sprinkle with the remaining cheese.</li>
              <li>Bake for 20-25 minutes, or until the cheese is melted and bubbly.</li>
              <li>Let cool for a few minutes before serving.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Chicken Enchiladas",
        color: "indigo",
      },
      {
        text: "Mexican",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "chicken breasts",
        measurement: "1 pound boneless, skinless",
      },
      {
        name: "taco seasoning mix",
        measurement: "1 packet",
      },
      {
        name: "water",
        measurement: "1 cup",
      },
      {
        name: "shredded cheddar cheese",
        measurement: "1 cup",
      },
      {
        name: "sour cream",
        measurement: "1/2 cup",
      },
      {
        name: "salsa",
        measurement: "1/2 cup",
      },
      {
        name: "corn tortillas",
        measurement: "12 tortillas",
      },
    ],
  },
  {
    id: "dnr061",
    recipeUrl: "",
    name: "Roasted Vegetables",
    directions: `
            <ol>
              <li>Preheat oven to 400 degrees F (200 degrees C).</li>
              <li>Wash and cut your vegetables into bite-sized pieces.</li>
              <li>Toss the vegetables with olive oil, salt, and pepper.</li>
              <li>Spread the vegetables in a single layer on a baking sheet.</li>
              <li>Roast for 20-25 minutes, or until the vegetables are tender and browned.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Roasted Vegetables",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Side Dish",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "(16-ounce) bag frozen vegetables",
        measurement: "1",
      },
      {
        name: "olive oil",
        measurement: "1 tablespoon",
      },
      {
        name: "salt",
        measurement: "1/2 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/4 teaspoon",
      },
    ],
  },
  {
    id: "dnr062",
    recipeUrl: "",
    name: "Steamed Vegetables",
    directions: `
            <ol>
              <li>Fill a large pot with water and bring to a boil.</li>
              <li>Add your vegetables to the pot and steam for 5-7 minutes, or until tender.</li>
              <li>Drain the vegetables and serve immediately.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Steamed Vegetables",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Side Dish",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "vegetables",
        measurement: "1 pound",
      },
      {
        name: "olive oil",
        measurement: "1 tablespoon",
      },
      {
        name: "salt",
        measurement: "1/2 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/4 teaspoon",
      },
    ],
  },
  {
    id: "dnr063",
    recipeUrl: "",
    name: "Classic Mashed Potatoes",
    directions: `
            <ol>
              <li>Peel and quarter the potatoes.</li>
              <li>Place the potatoes in a large pot and cover with cold water.</li>
              <li>Bring the water to a boil, then reduce the heat to low and simmer for 15-20 minutes, or until the potatoes are fork-tender.</li>
              <li>Drain the potatoes and return them to the pot.</li>
              <li>Add the butter, milk, salt, and pepper and mash until smooth.</li>
              <li>Taste and adjust the seasonings as needed.</li>
              <li>Serve immediately.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Mashed Potatoes",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Side Dish",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "Yukon Gold potatoes",
        measurement: "2 pounds",
      },
      {
        name: "unsalted butter",
        measurement: "1/4 cup (1/2 stick)",
      },
      {
        name: "milk",
        measurement: "1 cup",
      },
      {
        name: "salt",
        measurement: "1/2 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/4 teaspoon",
      },
    ],
  },
  {
    id: "dnr064",
    recipeUrl: "",
    name: "Stuffing",
    directions: `
            <ol>
              <li>Preheat oven to 350 degrees F (175 degrees C).</li>
              <li>In a large bowl, combine the bread cubes, celery, onion, and herbs.</li>
              <li>In a separate bowl, whisk together the eggs, broth, and melted butter.</li>
              <li>Pour the egg mixture over the bread mixture and toss to coat.</li>
              <li>Transfer the stuffing to a greased 9x13 inch baking dish.</li>
              <li>Bake for 30-35 minutes, or until the stuffing is golden brown and cooked through.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Stuffing",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Side Dish",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "bread cubes",
        measurement: "12 cups",
      },
      {
        name: "celery",
        measurement: "1 cup, chopped",
      },
      {
        name: "onion",
        measurement: "1 cup, chopped",
      },
      {
        name: "fresh herbs (such as sage, rosemary, and thyme)",
        measurement: "1/2 cup, chopped",
      },
      {
        name: "4 eggs",
        measurement: "4",
      },
      {
        name: "chicken broth",
        measurement: "4 cups",
      },
      {
        name: "butter",
        measurement: "1/2 cup, melted",
      },
    ],
  },
  {
    id: "dnr065",
    recipeUrl: "",
    name: "Roasted Turkey",
    directions: `
            <ol>
              <li>Preheat oven to 325 degrees F (165 degrees C).</li>
              <li>Remove the giblets and neck from the turkey cavity.</li>
              <li>Rinse the turkey inside and out with cold water.</li>
              <li>Pat the turkey dry with paper towels.</li>
              <li>Place the turkey in a roasting pan.</li>
              <li>Season the turkey with salt, pepper, and your favorite herbs and spices.</li>
              <li>Roast the turkey for 3-4 hours, or until the internal temperature reaches 165 degrees F (74 degrees C).</li>
              <li>Let the turkey rest for 15 minutes before carving.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Roasted Turkey",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "(12- to 14-pound) turkey",
        measurement: "1",
      },
      {
        name: "salt",
        measurement: "1 tablespoon",
      },
      {
        name: "black pepper",
        measurement: "1 teaspoon",
      },
      {
        name: "garlic powder",
        measurement: "1 teaspoon",
      },
      {
        name: "onion powder",
        measurement: "1 teaspoon",
      },
      {
        name: "paprika",
        measurement: "1/2 teaspoon",
      },
      {
        name: "dried thyme",
        measurement: "1/4 teaspoon",
      },
      {
        name: "dried rosemary",
        measurement: "1/4 teaspoon",
      },
    ],
  },
  {
    id: "dnr066",
    recipeUrl: "",
    name: "Classic Salsa",
    directions: `
            <ol>
              <li>Core and seed the tomatoes.</li>
              <li>Chop the tomatoes, onion, and cilantro.</li>
              <li>Combine the tomatoes, onion, cilantro, lime juice, salt, and pepper in a bowl.</li>
              <li>Refrigerate for at least 30 minutes before serving.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Salsa",
        color: "indigo",
      },
      {
        text: "Mexican",
        color: "indigo",
      },
      {
        text: "Appetizer",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "tomatoes",
        measurement: "2 tomatoes",
      },
      {
        name: "onion",
        measurement: "1/2 onion",
      },
      {
        name: "cilantro",
        measurement: "1/4 cup",
      },
      {
        name: "lime",
        measurement: "1, juiced",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
    ],
  },
  {
    id: "dnr067",
    recipeUrl: "",
    name: "Classic Guacamole",
    directions: `
            <ol>
              <li>Halve, pit, and peel the avocados.</li>
              <li>Mash the avocados with a fork until smooth.</li>
              <li>Add the onion, cilantro, lime juice, salt, and pepper to the avocado and mash until well combined.</li>
              <li>Taste and adjust the seasonings as needed.</li>
              <li>Serve immediately with tortilla chips or vegetables.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Guacamole",
        color: "indigo",
      },
      {
        text: "Mexican",
        color: "indigo",
      },
      {
        text: "Dip",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "avocados",
        measurement: "2, ripe",
      },
      {
        name: "onion",
        measurement: "1/2, finely chopped",
      },
      {
        name: "cilantro",
        measurement: "1/4 cup, chopped",
      },
      {
        name: "lime",
        measurement: "1 lime, juiced",
      },
      {
        name: "salt",
        measurement: "1/2 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/4 teaspoon",
      },
    ],
  },
  {
    id: "dnr068",
    recipeUrl: "",
    name: "Classic Bean Dip",
    directions: `
            <ol>
              <li>Drain and rinse the beans.</li>
              <li>In a food processor, combine the beans, corn, salsa, cheese, and spices.</li>
              <li>Pulse until smooth.</li>
              <li>Taste and adjust the seasonings as needed.</li>
              <li>Serve with tortilla chips or vegetables.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Bean Dip",
        color: "indigo",
      },
      {
        text: "Mexican",
        color: "indigo",
      },
      {
        text: "Dip",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "can (15 ounces) black beans",
        measurement: "1, drained and rinsed",
      },
      {
        name: "can (15 ounces) corn",
        measurement: "1, drained",
      },
      {
        name: "salsa",
        measurement: "1 cup",
      },
      {
        name: "shredded cheddar cheese",
        measurement: "1 cup",
      },
      {
        name: "chili powder",
        measurement: "1 teaspoon",
      },
      {
        name: "cumin",
        measurement: "1/2 teaspoon",
      },
      {
        name: "salt",
        measurement: "1/4 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/4 teaspoon",
      },
    ],
  },
  {
    id: "dnr069",
    recipeUrl: "",
    name: "Classic Buffalo Wings",
    directions: `
            <ol>
              <li>Preheat oven to 400 degrees F (200 degrees C).</li>
              <li>In a large bowl, toss the chicken wings with the oil and garlic powder.</li>
              <li>Spread the chicken wings in a single layer on a baking sheet.</li>
              <li>Bake for 20-25 minutes, or until the chicken is cooked through.</li>
              <li>While the chicken is cooking, make the sauce. In a small saucepan, combine the butter, hot sauce, and Worcestershire sauce.</li>
              <li>Bring the sauce to a simmer over medium heat.</li>
              <li>Remove the chicken from the oven and toss with the sauce.</li>
              <li>Serve immediately with your favorite sides.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Buffalo Wings",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Appetizer",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "chicken wings",
        measurement: "1 pound",
      },
      {
        name: "olive oil",
        measurement: "1/4 cup",
      },
      {
        name: "garlic powder",
        measurement: "1 teaspoon",
      },
      {
        name: "butter",
        measurement: "1/2 cup",
      },
      {
        name: "hot sauce",
        measurement: "1/2 cup",
      },
      {
        name: "Worcestershire sauce",
        measurement: "2 tablespoons",
      },
    ],
  },
  {
    id: "dnr070",
    recipeUrl: "",
    name: "Roasted Cauliflower",
    directions: `
            <ol>
              <li>Preheat oven to 400 degrees F (200 degrees C).</li>
              <li>Cut the cauliflower into florets.</li>
              <li>In a large bowl, toss the cauliflower with the olive oil, salt, and pepper.</li>
              <li>Spread the cauliflower in a single layer on a baking sheet.</li>
              <li>Roast for 20-25 minutes, or until the cauliflower is tender and golden brown.</li>
              <li>Serve immediately.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Roasted Cauliflower",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Side Dish",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "cauliflower",
        measurement: "1 head",
      },
      {
        name: "olive oil",
        measurement: "1/4 cup",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
    ],
  },
  {
    id: "dnr071",
    recipeUrl: "",
    name: "Roasted Brussels Sprouts with Lemon and Thyme",
    directions: `
            <ol>
              <li>Preheat oven to 400 degrees F (200 degrees C).</li>
              <li>Cut the Brussels sprouts in half.</li>
              <li>In a large bowl, toss the Brussels sprouts with the olive oil, lemon juice, thyme, salt, and pepper.</li>
              <li>Spread the Brussels sprouts in a single layer on a baking sheet.</li>
              <li>Roast for 20-25 minutes, or until the Brussels sprouts are tender and browned.</li>
              <li>Serve immediately.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Roasted Brussels Sprouts",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Side Dish",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "Brussels sprouts",
        measurement: "1 pound",
      },
      {
        name: "olive oil",
        measurement: "1/4 cup",
      },
      {
        name: "lemon juice",
        measurement: "1/4 cup",
      },
      {
        name: "thyme",
        measurement: "1 tablespoon",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
    ],
  },
  {
    id: "dnr072",
    recipeUrl: "",
    name: "Roasted Broccoli with Garlic and Parmesan",
    directions: `
            <ol>
              <li>Preheat oven to 400 degrees F (200 degrees C).</li>
              <li>Cut the broccoli into florets.</li>
              <li>In a large bowl, toss the broccoli with the olive oil, garlic, Parmesan cheese, salt, and pepper.</li>
              <li>Spread the broccoli in a single layer on a baking sheet.</li>
              <li>Roast for 20-25 minutes, or until the broccoli is tender and browned.</li>
              <li>Serve immediately.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Roasted Broccoli",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Side Dish",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "broccoli",
        measurement: "1 head",
      },
      {
        name: "olive oil",
        measurement: "1/4 cup",
      },
      {
        name: "garlic",
        measurement: "2 cloves, minced",
      },
      {
        name: "Parmesan cheese",
        measurement: "1/4 cup",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
    ],
  },
  {
    id: "dnr073",
    recipeUrl: "",
    name: "Classic Pizza",
    directions: `
            <ol>
              <li>Preheat oven to 450 degrees F (230 degrees C).</li>
              <li>Roll out the pizza dough on a lightly floured surface.</li>
              <li>Spread the tomato sauce evenly over the dough.</li>
              <li>Sprinkle the mozzarella cheese over the sauce.</li>
              <li>Add your favorite toppings.</li>
              <li>Bake for 15-20 minutes, or until the crust is golden brown and the cheese is melted and bubbly.</li>
              <li>Let cool for a few minutes before slicing and serving.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Pizza",
        color: "indigo",
      },
      {
        text: "Italian",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "(16-ounce) ball of pizza dough",
        measurement: "1",
      },
      {
        name: "(15-ounce) can of tomato sauce",
        measurement: "1",
      },
      {
        name: "shredded mozzarella cheese",
        measurement: "1 cup",
      },
      {
        name: "Your favorite toppings",
        measurement: "Your choice",
      },
    ],
  },
  {
    id: "dnr074",
    recipeUrl: "",
    name: "Classic Lasagna",
    directions: `
            <ol>
              <li>Preheat oven to 375 degrees F (190 degrees C).</li>
              <li>Cook the lasagna noodles according to package directions.</li>
              <li>In a large pot, brown the ground beef over medium heat.</li>
              <li>Drain off any excess grease.</li>
              <li>Stir in the tomato sauce, tomato paste, Italian seasoning, salt, and pepper.</li>
              <li>Bring to a simmer and cook for 15 minutes, or until the sauce has thickened.</li>
              <li>In a small bowl, combine the ricotta cheese, Parmesan cheese, and egg.</li>
              <li>Spread a thin layer of the tomato sauce in the bottom of a 9x13 inch baking dish.</li>
              <li>Top with a layer of lasagna noodles.</li>
              <li>Spread with a layer of the ricotta cheese mixture.</li>
              <li>Top with a layer of the meat sauce.</li>
              <li>Repeat layers, ending with a layer of meat sauce.</li>
              <li>Cover with foil and bake for 30 minutes.</li>
              <li>Uncover and bake for an additional 15 minutes, or until the lasagna is hot and bubbly.</li>
              <li>Let stand for 10 minutes before serving.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Lasagna",
        color: "indigo",
      },
      {
        text: "Italian",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "ground beef",
        measurement: "1 pound",
      },
      {
        name: "(28-ounce) can of crushed tomatoes",
        measurement: "1",
      },
      {
        name: "(6-ounce) can of tomato paste",
        measurement: "1",
      },
      {
        name: "Italian seasoning",
        measurement: "1 teaspoon",
      },
      {
        name: "salt",
        measurement: "1 teaspoon",
      },
      {
        name: "black pepper",
        measurement: "1/2 teaspoon",
      },
      {
        name: "(15-ounce) container of ricotta cheese",
        measurement: "1",
      },
      {
        name: "grated Parmesan cheese",
        measurement: "1 cup",
      },
      {
        name: "egg",
        measurement: "1",
      },
      {
        name: "lasagna noodles",
        measurement: "12",
      },
    ],
  },
  {
    id: "dnr075",
    recipeUrl: "",
    name: "Scrambled Eggs",
    directions: `
            <ol>
              <li>Crack the eggs into a bowl and whisk until well combined.</li>
              <li>Heat a small amount of butter or oil in a nonstick skillet over medium heat.</li>
              <li>Add the eggs to the skillet and cook, stirring occasionally, until they are cooked through.</li>
              <li>Season with salt and pepper to taste.</li>
              <li>Serve immediately.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "Scrambled Eggs",
        color: "indigo",
      },
      {
        text: "American",
        color: "indigo",
      },
      {
        text: "Breakfast",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "eggs",
        measurement: "2",
      },
      {
        name: "butter or oil",
        measurement: "1 tablespoon",
      },
      {
        name: "Salt and pepper",
        measurement: "to taste",
      },
    ],
  },
  {
    id: "dnr076",
    recipeUrl: "",
    name: "French Onion Soup",
    directions: `
            <ol>
              <li>Heat the olive oil in a large pot over medium heat.</li>
              <li>Add the onions and cook, stirring occasionally, until they are soft and translucent, about 15 minutes.</li>
              <li>Add the garlic and cook for 1 minute more.</li>
              <li>Add the wine and cook until it has evaporated, about 5 minutes.</li>
              <li>Add the beef broth, thyme, and bay leaves.</li>
              <li>Bring to a boil, then reduce heat to low and simmer for 1 hour, or until the onions are very tender.</li>
              <li>Remove the bay leaves and thyme sprigs.</li>
              <li>Using an immersion blender, puree the soup until smooth.</li>
              <li>Season with salt and pepper to taste.</li>
              <li>Ladle the soup into bowls and top with Gruyère cheese.</li>
              <li>Broil until the cheese is melted and bubbly, about 2 minutes.</li>
              <li>Serve immediately.</li>
            </ol>
          `,
    days: [],
    tags: [
      {
        text: "French Onion Soup",
        color: "indigo",
      },
      {
        text: "French",
        color: "indigo",
      },
      {
        text: "Soup",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "olive oil",
        measurement: "2 tablespoons",
      },
      {
        name: "large onion",
        measurement: "1, thinly sliced",
      },
      {
        name: "garlic",
        measurement: "3 cloves, minced",
      },
      {
        name: "dry red wine",
        measurement: "1 cup",
      },
      {
        name: "beef broth",
        measurement: "6 cups",
      },
      {
        name: "dried thyme",
        measurement: "1 teaspoon",
      },
      {
        name: "bay leaves",
        measurement: "2 bay leaves",
      },
      {
        name: "shredded Gruyère cheese",
        measurement: "1 cup",
      },
      {
        name: "Salt and pepper",
        measurement: "to taste",
      },
    ],
  },
  {
    id: "dnr077",
    recipeUrl: "",
    name: "Chicken Parmesan",
    directions: `
        <ol>
          <li>Preheat oven to 400 degrees F (200 degrees C).</li>
          <li>Flatten the chicken breasts to 1/4-inch thickness with a meat mallet.</li>
          <li>In a shallow dish, combine the bread crumbs, Parmesan cheese, garlic powder, and salt.</li>
          <li>Dip each chicken breast in the egg, then coat in the bread crumb mixture.</li>
          <li>Heat the olive oil in a large skillet over medium heat.</li>
          <li>Cook the chicken breasts for 2-3 minutes per side, or until golden brown.</li>
          <li>Place the chicken breasts in a baking dish.</li>
          <li>Top with the tomato sauce and mozzarella cheese.</li>
          <li>Bake for 15-20 minutes, or until the cheese is melted and bubbly.</li>
          <li>Serve immediately.</li>
        </ol>
      `,
    days: [],
    tags: [
      {
        text: "Chicken Parmesan",
        color: "indigo",
      },
      {
        text: "Italian",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "chicken breasts",
        measurement: "4 boneless, skinless",
      },
      {
        name: "bread crumbs",
        measurement: "1/2 cup",
      },
      {
        name: "grated Parmesan cheese",
        measurement: "1/2 cup",
      },
      {
        name: "garlic powder",
        measurement: "1 teaspoon",
      },
      {
        name: "salt",
        measurement: "1/2 teaspoon",
      },
      {
        name: "egg",
        measurement: "1, beaten",
      },
      {
        name: "olive oil",
        measurement: "1/4 cup",
      },
      {
        name: "tomato sauce",
        measurement: "1 cup",
      },
      {
        name: "shredded mozzarella cheese",
        measurement: "1 cup",
      },
    ],
  },
  {
    id: "1234567890",
    recipeUrl:
      "https://www.allrecipes.com/recipe/227529/classic-beef-stroganoff/",
    name: "Classic Beef Stroganoff",
    directions: `
        <ol>
          <li>Heat the olive oil in a large skillet over medium heat.</li>
          <li>Add the beef and cook, stirring occasionally, until browned on all sides.</li>
          <li>Remove the beef from the skillet and set aside.</li>
          <li>Add the onion and mushrooms to the skillet and cook, stirring occasionally, until softened.</li>
          <li>Add the garlic and cook for 1 minute more.</li>
          <li>Stir in the beef broth, Worcestershire sauce, and Dijon mustard.</li>
          <li>Bring to a boil, then reduce heat to low and simmer for 10 minutes.</li>
          <li>Return the beef to the skillet and cook until heated through.</li>
          <li>Stir in the sour cream and parsley.</li>
          <li>Season with salt and pepper to taste.</li>
          <li>Serve over egg noodles.</li>
        </ol>
      `,
    days: [],
    tags: [
      {
        text: "Beef Stroganoff",
        color: "indigo",
      },
      {
        text: "Russian",
        color: "indigo",
      },
      {
        text: "Main Course",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "beef sirloin, cut into 1-inch cubes",
        measurement: "1 pound",
      },
      {
        name: "olive oil",
        measurement: "1 tablespoon",
      },
      {
        name: "onion",
        measurement: "1, chopped",
      },
      {
        name: "mushrooms",
        measurement: "1 pound, sliced",
      },
      {
        name: "garlic",
        measurement: "2 cloves, minced",
      },
      {
        name: "beef broth",
        measurement: "1 cup ",
      },
      {
        name: "Worcestershire sauce",
        measurement: "2 tablespoons ",
      },
      {
        name: "Dijon mustard",
        measurement: "1 tablespoon ",
      },
      {
        name: "sour cream",
        measurement: "1/2 cup ",
      },
      {
        name: "fresh parsley",
        measurement: "1/4 cup chopped ",
      },
      {
        name: "Salt and pepper",
        measurement: "to taste",
      },
      {
        name: "Egg noodles",
        measurement: "8 oz",
      },
    ],
  },
];

import { DatabaseUser, UserGroup, UserState } from "Authentication";
import { CategoryModel } from "Lists";
import { ItemModel, DatabaseItem, DefaultItemImport } from "Lists";
import { db } from "Config";
import { faYenSign } from "@fortawesome/free-solid-svg-icons";
import { singular } from "pluralize";
import { commonGroceryCategories } from "./data/commonCategories";
import { commonGroceryItems2 } from "./data/commonGroceryItems2";
import { commonGroceryItems1 } from "./data/commonGroceryItems1";
import { HabitModel } from "Habits/habitmodel";
import moment from "moment";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
  writeBatch,
} from "firebase/firestore";

const randomColor = () => {
  var color = "#" + Math.floor(Math.random() * 16777215).toString(16);
  return color;
};

// Initial categories.
const defaultCategories: CategoryModel[] = commonGroceryCategories;
// Initial categories for Private To Do List.
const defaultCategoriesToDo: CategoryModel[] = [
  { id: "td100100", name: "To Do", colour: `#00ff7f`, order: 10 },
  { id: "td100200", name: "Work", colour: `#87ceeb`, order: 10 },
  { id: "td100300", name: "Personal", colour: `#20b2aa`, order: 10 },
];

// Initial Items with Categories.
const defaultItems1: DefaultItemImport[] = commonGroceryItems1;

const defaultItems2: DefaultItemImport[] = commonGroceryItems2;

const defaultItems3: ItemModel[] = [];

const defaultItems4: ItemModel[] = [];

export async function createGroup(
  groupName: string,
  userId: string,
  displayName: string
): Promise<UserGroup> {
  const groupsCollection = collection(db, "groups");

  const groupDocRef = await addDoc(groupsCollection, {
    name: groupName,
    users: [userId],
    ownerID: userId,
    ownerName: displayName,
    createdDate: serverTimestamp(),
    createdDateSort: serverTimestamp(),
    userDetails: [{ id: userId, name: displayName }],
  });

  const listsCollection = collection(groupDocRef, "lists");
  const listDocRef = await addDoc(listsCollection, { name: "shopping" });

  const userGroup: UserGroup = {
    id: groupDocRef.id,
    name: groupName,
    defaultList: listDocRef.id,
    rollBackList: listDocRef.id,
    rollBackId: groupDocRef.id,
    rollBackGroupName: groupName,
    ownerID: userId,
    ownerName: displayName,
    createdDate: new Date().toString(),
  };
  const user: Partial<DatabaseUser> = {
    state: UserState.Registered,
    group: userGroup,
  };

  const groupRef = doc(db, "users", userId);
  await updateDoc(groupRef, {
    defaultList: listDocRef.id,
    ownerID: userId,
    ownerName: displayName,
    createdDate: new Date().toString(),
  });

  const defaultListIDRef = doc(db, "groups", user?.group?.id);
  try {
    await updateDoc(defaultListIDRef, {
      defaultList: listDocRef.id,
    });
  } catch (error) {
    window.location.reload();
  }

  try {
    const membersRef = collection(db, `groups/${user?.group?.id}/members`);
    await addDoc(membersRef, {
      defaultList: listDocRef.id,
      id: userId,
      name: displayName,
      owner: true,
      addDate: new Date(),
    });
  } catch (error) {
    window.location.reload();
  }

  const userRef = doc(db, "users", userId);
  await updateDoc(userRef, user);

  return userGroup;
}

export const commonGroceryItemsAsia = [
  { category: "100100", name: "婴儿食品" }, // Baby Food
  { category: "100100", name: "婴儿湿巾" }, // Baby Wipes
  { category: "100100", name: "婴儿尿布" }, // Diapers
  { category: "100100", name: "婴儿配方奶粉" }, // Baby Formula
  { category: "100100", name: "婴儿乳液" }, // Baby Lotion
  { category: "100200", name: "面包" }, // Bread
  { category: "100200", name: "羊角面包" }, // Croissants
  { category: "100200", name: "全麦面包" }, // Whole Wheat Bread
  { category: "100200", name: "法棍面包" }, // Baguette
  { category: "100200", name: "甜面包" }, // Sweet Bread
  { category: "100300", name: "面粉" }, // Flour
  { category: "100300", name: "糖" }, // Sugar
  { category: "100300", name: "泡打粉" }, // Baking Powder
  { category: "100300", name: "香草精" }, // Vanilla Extract
  { category: "100300", name: "可可粉" }, // Cocoa Powder
  { category: "100400", name: "矿泉水" }, // Mineral Water
  { category: "100400", name: "橙汁" }, // Orange Juice
  { category: "100400", name: "咖啡" }, // Coffee
  { category: "100400", name: "茶" }, // Tea
  { category: "100400", name: "汽水" }, // Soda
  { category: "100500", name: "洗涤剂" }, // Detergent
  { category: "100500", name: "洗碗液" }, // Dishwashing Liquid
  { category: "100500", name: "多用途清洁剂" }, // All-purpose Cleaner
  { category: "100500", name: "纸巾" }, // Paper Towels
  { category: "100500", name: "垃圾袋" }, // Trash Bags
  { category: "100600", name: "番茄酱" }, // Tomato Sauce
  { category: "100600", name: "芥末" }, // Mustard
  { category: "100600", name: "蛋黄酱" }, // Mayonnaise
  { category: "100600", name: "沙拉酱" }, // Salad Dressing
  { category: "100600", name: "番茄酱" }, // Ketchup
  { category: "100700", name: "牛奶" }, // Milk
  { category: "100700", name: "黄油" }, // Butter
  { category: "100700", name: "奶酪" }, // Cheese
  { category: "100700", name: "酸奶" }, // Yogurt
  { category: "100700", name: "奶油" }, // Cream
  { category: "100800", name: "冷冻比萨" }, // Frozen Pizza
  { category: "100800", name: "冷冻蔬菜" }, // Frozen Vegetables
  { category: "100800", name: "冰淇淋" }, // Ice Cream
  { category: "100800", name: "冷冻食品" }, // Frozen Foods
  { category: "100800", name: "冷冻薯条" }, // Frozen Fries
  { category: "100900", name: "苹果" }, // Apples
  { category: "100900", name: "香蕉" }, // Bananas
  { category: "100900", name: "西红柿" }, // Tomatoes
  { category: "100900", name: "生菜" }, // Lettuce
  { category: "100900", name: "胡萝卜" }, // Carrots
  { category: "100900", name: "洋葱" }, // Onions
  { category: "100900", name: "青椒" }, // Bell Peppers
  { category: "100900", name: "土豆" }, // Potatoes
  { category: "100900", name: "大蒜" }, // Garlic
  { category: "100900", name: "菠菜" }, // Spinach
  { category: "101100", name: "牛肉" }, // Beef
  { category: "101100", name: "猪肉" }, // Pork
  { category: "101100", name: "鸡肉" }, // Chicken
  { category: "101100", name: "火鸡肉" }, // Turkey
  { category: "101100", name: "羊肉" }, // Lamb
  { category: "101200", name: "头痛药" }, // Headache Medicine
  { category: "101200", name: "创可贴" }, // Band-Aids
  { category: "101200", name: "体温计" }, // Thermometer
  { category: "101200", name: "消毒液" }, // Disinfectant
  { category: "101200", name: "口罩" }, // Face Masks
  { category: "101500", name: "大米" }, // Rice
  { category: "101500", name: "意面" }, // Pasta
  { category: "101500", name: "橄榄油" }, // Olive Oil
  { category: "101500", name: "豆类" }, // Beans
  { category: "101500", name: "豆腐" }, // Tofu
  { category: "101700", name: "宠物食品（狗）" }, // Dog Food
  { category: "101700", name: "宠物食品（猫）" }, // Cat Food
  { category: "101700", name: "猫砂" }, // Cat Litter
  { category: "101700", name: "宠物玩具" }, // Pet Toys
  { category: "101700", name: "宠物洗发水" }, // Pet Shampoo
  { category: "101800", name: "三文鱼" }, // Salmon
  { category: "101800", name: "虾" }, // Shrimp
  { category: "101800", name: "鳕鱼" }, // Cod
  { category: "101800", name: "鱿鱼" }, // Squid
  { category: "101800", name: "螃蟹" }, // Crab
  { category: "101900", name: "薯片" }, // Potato Chips
  { category: "101900", name: "巧克力" }, // Chocolate
  { category: "101900", name: "饼干" }, // Biscuits
  { category: "101900", name: "干果" }, // Dried Fruits
  { category: "101900", name: "坚果" }, // Nuts
  { category: "102000", name: "盐" }, // Salt
  { category: "102000", name: "胡椒" }, // Pepper
  { category: "102000", name: "咖喱粉" }, // Curry Powder
  { category: "102000", name: "辣椒粉" }, // Chili Powder
  { category: "102000", name: "生姜" }, // Ginger
  { category: "102500", name: "罗勒" }, // Basil
  { category: "102500", name: "香菜" }, // Cilantro
  { category: "102500", name: "迷迭香" }, // Rosemary
  { category: "102500", name: "百里香" }, // Thyme
  { category: "102500", name: "薄荷" }, // Mint
  { category: "100200", name: "月饼" }, // Mooncake
  { category: "100200", name: "粽子" }, // Zongzi
  { category: "100200", name: "春卷" }, // Spring Rolls
  { category: "100300", name: "红糖" }, // Brown Sugar
  { category: "100300", name: "糯米粉" }, // Glutinous Rice Flour
  { category: "100400", name: "绿茶" }, // Green Tea
  { category: "100400", name: "白酒" }, // Baijiu
  { category: "100400", name: "黄酒" }, // Huangjiu
  { category: "100500", name: "洗衣液" }, // Laundry Liquid
  { category: "100500", name: "洗手液" }, // Hand Soap
  { category: "100500", name: "玻璃清洁剂" }, // Glass Cleaner
  { category: "100500", name: "地板清洁剂" }, // Floor Cleaner
  { category: "100500", name: "抹布" }, // Cleaning Cloth
  { category: "100600", name: "酱油" }, // Soy Sauce
  { category: "100600", name: "豆瓣酱" }, // Doubanjiang
  { category: "100600", name: "芝麻酱" }, // Sesame Paste
  { category: "100600", name: "醋" }, // Vinegar
  { category: "100600", name: "鱼露" }, // Fish Sauce
  { category: "100900", name: "菇类" }, // Mushrooms
  { category: "100900", name: "黄瓜" }, // Cucumbers
  { category: "100900", name: "南瓜" }, // Pumpkin
  { category: "100900", name: "茄子" }, // Eggplant
  { category: "100900", name: "青菜" }, // Green Vegetables
  { category: "100900", name: "豆芽" }, // Bean Sprouts
  { category: "100900", name: "芹菜" }, // Celery
  { category: "100900", name: "苦瓜" }, // Bitter Melon
  { category: "100900", name: "莲藕" }, // Lotus Root
  { category: "100900", name: "白菜" }, // Chinese Cabbage
  { category: "101100", name: "鱼肉" }, // Fish
  { category: "101100", name: "虾肉" }, // Shrimp
  { category: "101100", name: "蟹肉" }, // Crab Meat
  { category: "101100", name: "墨鱼" }, // Cuttlefish
  { category: "101100", name: "牡蛎" }, // Oysters
  { category: "101100", name: "海参" }, // Sea Cucumber
  { category: "101100", name: "鸭肉" }, // Duck
  { category: "101100", name: "鹅肉" }, // Goose
  { category: "101100", name: "兔肉" }, // Rabbit
  { category: "101100", name: "羊肚" }, // Lamb Tripe
  { category: "101200", name: "皮肤药膏" }, // Skin Ointment
  { category: "101200", name: "感冒药" }, // Cold Medicine
  { category: "101200", name: "胃药" }, // Stomach Medicine
  { category: "101200", name: "维生素" }, // Vitamins
  { category: "101200", name: "钙片" }, // Calcium Tablets
  { category: "101500", name: "酱油" }, // Soy Sauce
  { category: "101500", name: "米醋" }, // Rice Vinegar
  { category: "101500", name: "豆腐乳" }, // Fermented Tofu
  { category: "101500", name: "糯米" }, // Glutinous Rice
  { category: "101500", name: "粉丝" }, // Glass Noodles
  { category: "101700", name: "宠物零食" }, // Pet Snacks
  { category: "101700", name: "宠物维生素" }, // Pet Vitamins
  { category: "101700", name: "宠物玩具" }, // Pet Toys
  { category: "101700", name: "宠物床垫" }, // Pet Bed
  { category: "101700", name: "宠物梳子" }, // Pet Comb
  { category: "101800", name: "带鱼" }, // Hairtail
  { category: "101800", name: "鲈鱼" }, // Bass
  { category: "101800", name: "鳗鱼" }, // Eel
  { category: "101800", name: "鲍鱼" }, // Abalone
  { category: "101800", name: "扇贝" }, // Scallop
  { category: "101900", name: "月饼" }, // Mooncakes
  { category: "101900", name: "糕点" }, // Pastries
  { category: "101900", name: "瓜子" }, // Melon Seeds
  { category: "101900", name: "话梅" }, // Preserved Plums
  { category: "101900", name: "果脯" }, // Candied Fruit
  { category: "102000", name: "五香粉" }, // Five-spice Powder
  { category: "102000", name: "八角" }, // Star Anise
  { category: "102000", name: "桂皮" }, // Cinnamon Bark
  { category: "102000", name: "花椒" }, // Sichuan Pepper
  { category: "102000", name: "豆蔻" }, // Cardamom
];

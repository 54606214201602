import { useEffect, useState } from "react";
import { useUserContext } from "Authentication";
import { CategoryModel } from "./models/";
import { collection, query, onSnapshot } from "@firebase/firestore";

export function useCategorySubscriptionToDo() {
  const [categoriesToDo, setCategoriesToDo] = useState<CategoryModel[]>([]);
  const { getPrivateToDoCategories } = useUserContext();

  useEffect(() => {
    const plannerCollection = getPrivateToDoCategories();
    const plannerQuery = query(plannerCollection);

    const unsubscribe = onSnapshot(
      plannerQuery,
      (snapshot) => {
        const addedEvents: CategoryModel[] = [];

        snapshot.docs.forEach((doc) => {
          const item = { ...doc.data(), id: doc.id } as CategoryModel;
          addedEvents.push(item);
        });

        setCategoriesToDo(addedEvents);
      },
      (error) => {
        window.location.reload();
      }
    );

    // Removed categoriesToDo from the dependency array
    return () => unsubscribe();
  }, []); // Dependency array is now empty

  // Sort categories without mutating the original state
  const sortedCategoriesToDo = [...categoriesToDo].sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  );

  return { categoriesToDo: sortedCategoriesToDo };
}

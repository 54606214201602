export const classicRecipesKR = [
  {
    id: "B1b1mB4p",
    recipeUrl: "",
    name: "비빔밥 (Bibimbap)",
    directions:
      "<ol><li>밥을 삶아주세요.</li><li>새우, 소고기, 계란 등 재료를 각각 볶아주세요.</li><li>양념장을 만들어주세요: 고추장, 간장, 설탕, 참기름을 섞어주세요.</li><li>비빔밥 그릇에 삶은 밥을 담고, 볶은 재료와 양념장을 올려주세요.</li><li>고추장을 넣고, 모든 재료를 골고루 섞어주세요.</li><li>겨자 소스를 곁들여 맛을 더해주세요.</li><li>양념 고추장과 김을 올려 마무리하세요.</li></ol>",
    days: [],
    tags: [
      { text: "한식", color: "indigo" },
      { text: "밥요리", color: "indigo" },
      { text: "전통음식", color: "indigo" },
    ],
    ingredients: [
      { name: "쌀", measurement: "2컵" },
      { name: "새우", measurement: "100g" },
      { name: "소고기 (불고기용)", measurement: "200g" },
      { name: "계란", measurement: "2개" },
      { name: "고추장", measurement: "2큰술" },
      { name: "간장", measurement: "2큰술" },
      { name: "설탕", measurement: "1큰술" },
      { name: "참기름", measurement: "1큰술" },
      { name: "겨자 소스", measurement: "1큰술" },
      { name: "김 (김가루)", measurement: "적당량" },
    ],
  },
  {
    id: "K1mch1J1g",
    recipeUrl: "",
    name: "김치찌개 (Kimchi Jjigae)",
    directions:
      "<ol><li>냄비에 김치, 돼지고기, 두부를 넣고 물을 붓습니다.</li><li>고추장, 고추가루, 간장, 설탕을 넣고 끓입니다.</li><li>끓기 시작하면 김치찌개의 육수가 생기는데, 무릎이 오른쪽으로 기울어지게 해 육수가 있는 왼쪽에 계란을 풉니다.</li><li> 계란이 익으면 김을 뿌리고, 들기름을 넣습니다.</li><li>직접 먹으며 편의에 따라 밥과 함께 먹습니다.</li></ol>",
    days: [],
    tags: [
      { text: "한식", color: "indigo" },
      { text: "찌개", color: "indigo" },
      { text: "김치", color: "indigo" },
    ],
    ingredients: [
      { name: "배추김치", measurement: "1컵" },
      { name: "돼지고기 (목살 또는 삼겹살)", measurement: "150g" },
      { name: "두부", measurement: "1/4모" },
      { name: "물", measurement: "4컵" },
      { name: "고추장", measurement: "1큰술" },
      { name: "고추가루", measurement: "1큰술" },
      { name: "간장", measurement: "1큰술" },
      { name: "설탕", measurement: "1작은술" },
      { name: "계란", measurement: "1개" },
      { name: "김 (김가루)", measurement: "적당량" },
      { name: "들기름", measurement: "적당량" },
    ],
  },
  {
    id: "Japchae789",
    recipeUrl: "",
    name: "잡채 (Japchae)",
    directions:
      "<ol><li>당면을 물에 불려 부드럽게 만듭니다.</li><li>양파, 당근, 무, 파프리카 등 다양한 채소를 준비하고 채 썰어 준비합니다.</li><li>버섯 소스를 만들기 위해 간장, 설탕, 참기름, 참깨, 다진 마늘, 다진 파를 섞어둡니다.</li><li>팬에 식용유를 두르고 준비한 채소를 볶아줍니다.</li><li>불린 당면을 넣고 볶아주며 양념장을 조금씩 넣어가면서 볶아줍니다.</li><li>완성된 잡채를 접시에 담아 마무리합니다.</li></ol>",
    days: [],
    tags: [
      { text: "한식", color: "indigo" },
      { text: "당면요리", color: "indigo" },
      { text: "채소요리", color: "indigo" },
    ],
    ingredients: [
      { name: "당면", measurement: "200g" },
      { name: "양파", measurement: "1개" },
      { name: "당근", measurement: "1개" },
      { name: "무", measurement: "1/4개" },
      { name: "파프리카", measurement: "1개" },
      { name: "간장", measurement: "2큰술" },
      { name: "설탕", measurement: "1큰술" },
      { name: "참기름", measurement: "1큰술" },
      { name: "참깨", measurement: "1큰술" },
      { name: "다진 마늘", measurement: "1큰술" },
      { name: "다진 파", measurement: "1큰술" },
    ],
  },
  {
    id: "Tt3okb0kk1",
    recipeUrl: "",
    name: "떡볶이 (Tteokbokki)",
    directions:
      "<ol><li>떡을 물에 불려 푹 불려 준비합니다.</li><li>팬에 물과 다진 마늘, 고추장, 고추가루, 설탕, 소금을 넣고 끓입니다.</li><li>끓는 물에 불린 떡과 어묵을 넣고 조리합니다.</li><li>양배추와 파를 넣고 살짝 더 끓입니다.</li><li>완성된 떡볶이를 접시에 담아 마무리합니다.</li></ol>",
    days: [],
    tags: [
      { text: "한식", color: "indigo" },
      { text: "떡요리", color: "indigo" },
      { text: "양념요리", color: "indigo" },
    ],
    ingredients: [
      { name: "떡", measurement: "200g" },
      { name: "다진 마늘", measurement: "1큰술" },
      { name: "고추장", measurement: "2큰술" },
      { name: "고추가루", measurement: "1큰술" },
      { name: "설탕", measurement: "1큰술" },
      { name: "소금", measurement: "1/2작은술" },
      { name: "어묵", measurement: "4조각" },
      { name: "양배추", measurement: "1/4개" },
      { name: "파", measurement: "1대" },
    ],
  },
  {
    id: "B1b1mbap2",
    recipeUrl: "",
    name: "비빔밥 (Bibimbap)",
    directions:
      "<ol><li>콩나물과 시금치를 삶아 준비합니다.</li><li>돼지고기와 당근을 먼저 볶고, 소금, 설탕, 다진 마늘을 넣어 볶습니다.</li><li>콩나물과 시금치를 각각 조립합니다.</li><li>계란후라이와 돌솥에서 지른 밥을 올려 완성합니다.</li></ol>",
    days: [],
    tags: [
      { text: "한식", color: "indigo" },
      { text: "비빔밥", color: "indigo" },
      { text: "고기요리", color: "indigo" },
      { text: "야채요리", color: "indigo" },
    ],
    ingredients: [
      { name: "콩나물", measurement: "100g" },
      { name: "시금치", measurement: "100g" },
      { name: "돼지고기", measurement: "150g" },
      { name: "당근", measurement: "1/2개" },
      { name: "소금", measurement: "1/2작은술" },
      { name: "설탕", measurement: "1큰술" },
      { name: "다진 마늘", measurement: "1작은술" },
      { name: "계란", measurement: "1개" },
      { name: "밥", measurement: "2공기" },
    ],
  },
  {
    id: "K1mch1Fr13dR1c3",
    recipeUrl: "",
    name: "김치 볶음밥 (Kimchi Fried Rice)",
    directions:
      "<ol><li>김치를 잘게 다지고 기름 없이 볶아줍니다.</li><li>다진 마늘과 참기름을 넣고 볶아 향을 낸 후, 밥을 넣고 볶습니다.</li><li>소금, 설탕, 간장을 넣고 볶아 간을 조절합니다.</li><li>계란 후라이와 파슬리를 올려 완성합니다.</li></ol>",
    days: [],
    tags: [
      { text: "한식", color: "indigo" },
      { text: "김치요리", color: "indigo" },
      { text: "볶음밥", color: "indigo" },
    ],
    ingredients: [
      { name: "김치", measurement: "1컵" },
      { name: "다진 마늘", measurement: "1작은술" },
      { name: "참기름", measurement: "1큰술" },
      { name: "밥", measurement: "2공기" },
      { name: "소금", measurement: "1/2작은술" },
      { name: "설탕", measurement: "1작은술" },
      { name: "간장", measurement: "1큰술" },
      { name: "계란", measurement: "2개" },
      { name: "파슬리", measurement: "적당량" },
    ],
  },
];

//new firebase

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  initializeFirestore,
  persistentLocalCache,
  CACHE_SIZE_UNLIMITED,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

//Old to be removed
//import firebase from "firebase/compat/app";
//import "firebase/compat/storage";
//import "firebase/compat/firestore";
// end old Firebase

import fbConfig from "./firebase.json";

/*try {
  firebase.initializeApp(fbConfig);
  //firebase.firestore().enablePersistence({ synchronizeTabs: true });

  firebase.firestore().settings({
    cacheSizeBytes: 300 * 1024 * 1024, // 200 MB
  });
} catch (error) {
  console.log(error);
}*/

//export const db = firebase.firestore();
//const storage = firebase.storage();
//export { storage, firebase as default };

//import fbConfig from "./firebase.json";

const app = initializeApp(fbConfig);
initializeFirestore(app, {
  localCache: persistentLocalCache(
    /*settings*/ { cacheSizeBytes: 120 * 1024 * 1024 }
  ),

  experimentalAutoDetectLongPolling: true,
});
const db = getFirestore(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);

const reconnectFirestore = () => {
  // alert("Launching db ...");
  // No direct reconnection method for Firestore in Firebase v10, but we can reinitialize if needed
  return getFirestore(app);
};

// Error handling function for Firestore
const handleFirestoreErrors = (error) => {
  if (error.message.includes("Connection to indexed database server lost")) {
    console.error(
      "Connection to indexed database server lost. Refreshing page..."
    );
    window.location.reload();
  }
  if (error.message.includes("Missing or insufficient permissions")) {
    console.error("Missing or insufficient permissions. Refreshing page...");
    window.location.reload();
  }
};

export {
  reconnectFirestore,
  db,
  storage,
  app,
  analytics,
  handleFirestoreErrors,
};

export const commonGroceryItemsKorea = [
  { category: "100100", name: "아기식품" }, // Baby Food
  { category: "100100", name: "아기용 물티슈" }, // Baby Wipes
  { category: "100100", name: "기저귀" }, // Diapers
  { category: "100100", name: "분유" }, // Baby Formula
  { category: "100100", name: "아기 로션" }, // Baby Lotion
  { category: "100200", name: "빵" }, // Bread
  { category: "100200", name: "크루아상" }, // Croissants
  { category: "100200", name: "식빵" }, // Sliced Bread
  { category: "100200", name: "바게트" }, // Baguette
  { category: "100200", name: "멜론빵" }, // Melon Bread
  { category: "100300", name: "밀가루" }, // Flour
  { category: "100300", name: "설탕" }, // Sugar
  { category: "100300", name: "베이킹 파우더" }, // Baking Powder
  { category: "100300", name: "바닐라 추출물" }, // Vanilla Extract
  { category: "100300", name: "코코아 파우더" }, // Cocoa Powder
  { category: "100400", name: "생수" }, // Mineral Water
  { category: "100400", name: "오렌지주스" }, // Orange Juice
  { category: "100400", name: "커피" }, // Coffee
  { category: "100400", name: "차" }, // Tea
  { category: "100400", name: "탄산음료" }, // Soda
  { category: "100500", name: "세제" }, // Detergent
  { category: "100500", name: "주방 세정제" }, // Dishwashing Liquid
  { category: "100500", name: "다목적 세정제" }, // All-purpose Cleaner
  { category: "100500", name: "키친타올" }, // Paper Towels
  { category: "100500", name: "쓰레기봉투" }, // Trash Bags
  { category: "100600", name: "토마토소스" }, // Tomato Sauce
  { category: "100600", name: "간장" }, // Soy Sauce
  { category: "100600", name: "마요네즈" }, // Mayonnaise
  { category: "100600", name: "샐러드 드레싱" }, // Salad Dressing
  { category: "100600", name: "케첩" }, // Ketchup
  { category: "100700", name: "우유" }, // Milk
  { category: "100700", name: "버터" }, // Butter
  { category: "100700", name: "치즈" }, // Cheese
  { category: "100700", name: "요거트" }, // Yogurt
  { category: "100700", name: "크림" }, // Cream
  { category: "100800", name: "냉동피자" }, // Frozen Pizza
  { category: "100800", name: "냉동채소" }, // Frozen Vegetables
  { category: "100800", name: "아이스크림" }, // Ice Cream
  { category: "100800", name: "냉동식품" }, // Frozen Foods
  { category: "100800", name: "냉동감자튀김" }, // Frozen Fries
  { category: "100900", name: "사과" }, // Apples
  { category: "100900", name: "바나나" }, // Bananas
  { category: "100900", name: "토마토" }, // Tomatoes
  { category: "100900", name: "상추" }, // Lettuce
  { category: "100900", name: "당근" }, // Carrots
  { category: "100900", name: "양파" }, // Onions
  { category: "100900", name: "파프리카" }, // Bell Peppers
  { category: "100900", name: "감자" }, // Potatoes
  { category: "100900", name: "마늘" }, // Garlic
  { category: "100900", name: "시금치" }, // Spinach
  { category: "101100", name: "소고기" }, // Beef
  { category: "101100", name: "돼지고기" }, // Pork
  { category: "101100", name: "닭고기" }, // Chicken
  { category: "101100", name: "칠면조" }, // Turkey
  { category: "101100", name: "양고기" }, // Lamb
  { category: "101200", name: "두통약" }, // Headache Medicine
  { category: "101200", name: "밴드" }, // Bandages
  { category: "101200", name: "체온계" }, // Thermometer
  { category: "101200", name: "소독제" }, // Disinfectant
  { category: "101200", name: "마스크" }, // Face Masks
  { category: "101500", name: "쌀" }, // Rice
  { category: "101500", name: "파스타" }, // Pasta
  { category: "101500", name: "올리브 오일" }, // Olive Oil
  { category: "101500", name: "렌즈콩" }, // Lentils
  { category: "101500", name: "콩" }, // Beans
  { category: "101700", name: "개 사료" }, // Dog Food
  { category: "101700", name: "고양이 사료" }, // Cat Food
  { category: "101700", name: "고양이 모래" }, // Cat Litter
  { category: "101700", name: "애완 동물용 장난감" }, // Pet Toys
  { category: "101700", name: "애완 동물용 샴푸" }, // Pet Shampoo
  { category: "101800", name: "연어" }, // Salmon
  { category: "101800", name: "새우" }, // Shrimp
  { category: "101800", name: "대구" }, // Cod
  { category: "101800", name: "오징어" }, // Squid
  { category: "101800", name: "게" }, // Crab
  { category: "101900", name: "감자칩" }, // Potato Chips
  { category: "101900", name: "초콜릿" }, // Chocolate
  { category: "101900", name: "과자" }, // Snacks
  { category: "101900", name: "건조 과일" }, // Dried Fruits
  { category: "101900", name: "견과류" }, // Nuts
  { category: "102000", name: "소금" }, // Salt
  { category: "102000", name: "후추" }, // Pepper
  { category: "102000", name: "카레 가루" }, // Curry Powder
  { category: "102000", name: "고추가루" }, // Chili Powder
  { category: "102000", name: "생강" }, // Ginger
  { category: "102500", name: "바질" }, // Basil
  { category: "102500", name: "파슬리" }, // Parsley
  { category: "102500", name: "타임" }, // Thyme
  { category: "102500", name: "로즈마리" }, // Rosemary
  { category: "102500", name: "민트" }, // Mint
  { category: "100200", name: "한과" }, // Traditional Korean Sweets
  { category: "100200", name: "떡" }, // Rice Cake
  { category: "100200", name: "만두" }, // Dumplings
  { category: "100300", name: "갈색 설탕" }, // Brown Sugar
  { category: "100300", name: "바닐라 추출물" }, // Vanilla Extract
  { category: "100400", name: "홍차" }, // Black Tea
  { category: "100400", name: "녹차" }, // Green Tea
  { category: "100400", name: "맥주" }, // Beer
  { category: "100400", name: "소주" }, // Soju
  { category: "100400", name: "막걸리" }, // Makgeolli
  { category: "100500", name: "세탁 세제" }, // Laundry Detergent
  { category: "100500", name: "주방 세정제" }, // Kitchen Cleaner
  { category: "100500", name: "유리 세정제" }, // Glass Cleaner
  { category: "100500", name: "욕실 세정제" }, // Bathroom Cleaner
  { category: "100500", name: "청소용 걸레" }, // Cleaning Mop
  { category: "100600", name: "간장" }, // Soy Sauce
  { category: "100600", name: "된장" }, // Doenjang
  { category: "100600", name: "고추장" }, // Gochujang
  { category: "100600", name: "쌈장" }, // Ssamjang
  { category: "100600", name: "식초" }, // Vinegar
  { category: "100900", name: "마늘쫑" }, // Garlic Scapes
  { category: "100900", name: "부추" }, // Chives
  { category: "100900", name: "깻잎" }, // Perilla Leaves
  { category: "100900", name: "무" }, // Radish
  { category: "100900", name: "오이" }, // Cucumber
  { category: "100900", name: "가지" }, // Eggplant
  { category: "100900", name: "고추" }, // Chili Peppers
  { category: "100900", name: "당근" }, // Carrots
  { category: "100900", name: "토란대" }, // Taro Stem
  { category: "100900", name: "연근" }, // Lotus Root
  { category: "101100", name: "조기" }, // Croaker
  { category: "101100", name: "갈치" }, // Hairtail
  { category: "101100", name: "고등어" }, // Mackerel
  { category: "101100", name: "꽁치" }, // Pacific Saury
  { category: "101100", name: "문어" }, // Octopus
  { category: "101100", name: "전복" }, // Abalone
  { category: "101100", name: "홍합" }, // Mussels
  { category: "101100", name: "멍게" }, // Sea Squirt
  { category: "101100", name: "소라" }, // Conch
  { category: "101100", name: "한우" }, // Hanwoo Beef
  { category: "101200", name: "한방약재" }, // Traditional Korean Medicinal Herbs
  { category: "101200", name: "침향환" }, // Ginseng Pills
  { category: "101200", name: "유산균" }, // Probiotics
  { category: "101200", name: "종합 비타민" }, // Multivitamins
  { category: "101200", name: "오메가-3" }, // Omega-3
  { category: "101500", name: "찹쌀" }, // Glutinous Rice
  { category: "101500", name: "현미" }, // Brown Rice
  { category: "101500", name: "보리" }, // Barley
  { category: "101500", name: "메밀" }, // Buckwheat
  { category: "101500", name: "흑미" }, // Black Rice
  { category: "101700", name: "애견 간식" }, // Dog Snacks
  { category: "101700", name: "애견 용품" }, // Dog Supplies
  { category: "101700", name: "애묘 간식" }, // Cat Snacks
  { category: "101700", name: "애묘 용품" }, // Cat Supplies
  { category: "101700", name: "애완용 샴푸" }, // Pet Shampoo
  { category: "101800", name: "홍어" }, // Skate
  { category: "101800", name: "가리비" }, // Scallops
  { category: "101800", name: "넙치" }, // Flounder
  { category: "101800", name: "우렁쉥이" }, // Cockle
  { category: "101800", name: "새우젓" }, // Shrimp Paste
  { category: "101900", name: "떡" }, // Rice Cakes
  { category: "101900", name: "한과" }, // Traditional Korean Sweets
  { category: "101900", name: "유과" }, // Yuga (Deep-fried Honey Cookies)
  { category: "101900", name: "약과" }, // Yakgwa (Honey Cookies)
  { category: "101900", name: "과일칩" }, // Fruit Chips
  { category: "102000", name: "김" }, // Dried Seaweed
  { category: "102000", name: "고추가루" }, // Red Pepper Powder
  { category: "102000", name: "간장" }, // Soy Sauce
  { category: "102000", name: "된장" }, // Doenjang (Soybean Paste)
  { category: "102000", name: "쌈장" }, // Ssamjang (Soybean Paste Sauce)
  { category: "102000", name: "고추장" },
];

//todo: Need to combine with categorymodal... same with add...

//import { useCategories } from "Lists/CategoriesContext"
import { useCategorySubscriptionToDo } from "ListToDo/useCategorySubscriptionToDo";
import { Modal } from "Common";
import { useUserContext } from "Authentication";
import { useState } from "react";
import classes from "./CategoryModal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faClose,
  faPalette,
} from "@fortawesome/free-solid-svg-icons";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

interface CategoryModalProps {
  isOpen: boolean;
  selectedCategoryId: string | undefined;
  close: () => void;
  select: (categoryId: string) => Promise<void>;
  isDarkMode: boolean;
}

export const CategoryModalToDo = (props: CategoryModalProps) => {
  const { isOpen, selectedCategoryId, close, select, isDarkMode } = props;
  const { addToDoCategory } = useUserContext();
  const { t } = useTranslation();
  const [isAddingNewCat, setIsAddingNewCat] = useState(false);
  const [newCatName, setNewCatName] = useState("");
  //  const categories = useCategories()
  const { categoriesToDo } = useCategorySubscriptionToDo();

  var numberOfCategories = 10;
  var NewCatName = "";
  var randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);

  var newCategory = {
    name: NewCatName,
    colour: randomColor,
    order: numberOfCategories,
  };

  const handleClick = async (category: string) => {
    // console.log("what", category);
    if (category === selectedCategoryId) {
      close();
    }

    try {
      await select(category);
      close();
    } catch (err) {
      console.error(err);
    }
  };

  function addCategoryDB(name: string, id: number, colour: string) {
    addToDoCategory(newCategory);
    // return GroupCategory.add(newCategory);
  }

  const addCategory = (e) => {
    e.preventDefault();
    //console.log("New Cat Name", newCatName)

    toast(t("generic-list-category-add-toast"), {
      duration: 2000,
      position: "top-center",
      style: {
        backgroundColor: "lightgreen",
        border: "1px solid #713200",
        fontSize: "16px",
        padding: "10px",
        color: "black",
      },
    });

    newCategory = {
      name: newCatName,
      colour: randomColor,
      order: numberOfCategories,
    };

    addCategoryDB(newCatName, numberOfCategories, randomColor);

    //console.log("catgory should be added", NewCatName, randomColor, numberOfCategories)

    //reset everything
    numberOfCategories = null;
    setNewCatName("");
    randomColor = Math.floor(Math.random() * 16777215).toString(16);
    numberOfCategories = 0;
    //window.location.reload()
    //  close()
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      style={{ overlay: { zIndex: 50 } }}
    >
      <div
        style={{
          paddingBottom: 250,
          padding: 20,

          filter: isDarkMode ? "" : "invert(100%)",
        }}
      >
        <div
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            paddingBottom: 10,
            border: 1,
            borderColor: "grey",
            borderRadius: 10,
            backgroundColor: "snow",
            fontWeight: "inherit",
            color: "black",
            minHeight: "100vh",
            marginTop: -40,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "right",
              justifyContent: "right",
              margin: 10,
            }}
          >
            {" "}
            <button
              style={{
                backgroundColor: "transparent",
                borderStyle: "none",
                cursor: "pointer",
                marginTop: 10,
                marginRight: -30,
              }}
              onClick={close}
            >
              <FontAwesomeIcon icon={faClose} size="1x" />
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: -10,
              paddingTop: 10,
              paddingBottom: 10,
              fontSize: 20,
              fontWeight: "bold",
            }}
          >
            {" "}
            <span>{t("generic-list-category-move-title")}</span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 0,
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            {!isAddingNewCat ? (
              <button
                style={{
                  fontFamily: "inherit",
                  fontSize: 16,
                  textAlign: "right",
                  color: "white",
                  border: 1,
                  borderRadius: 20,
                  padding: 10,
                  paddingLeft: 20,
                  paddingRight: 20,
                  backgroundColor: "blue",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
                onClick={() => setIsAddingNewCat(true)}
              >
                {t("generic-list-category-add-title")}
              </button>
            ) : null}
            {isAddingNewCat ? (
              <form onSubmit={addCategory}>
                <input
                  style={{
                    width: 125,
                    padding: 10,
                    paddingRight: 20,
                    fontSize: 18,
                  }}
                  type="text"
                  minLength={2}
                  required
                  onChange={(e) => setNewCatName(e.target.value)}
                  value={newCatName}
                />
                <input
                  type="submit"
                  style={{ padding: 10, marginLeft: 5, width: 75 }}
                  value={t("common-button-add")}
                />
                <p
                  onClick={() => {
                    setIsAddingNewCat(false);
                    setNewCatName("");
                  }}
                  style={{
                    textAlign: "center",
                    color: "blue",
                    fontSize: 16,
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  {t("common-button-close")}
                </p>
              </form>
            ) : null}
          </div>
          {categoriesToDo
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((c) => (
              <div
                key={c.id} // Use a unique identifier (e.g., c.id) as the key
                onClick={() => handleClick(c.id || "")}
                className={classes.category}
                style={{
                  backgroundColor: `${c.colour}40`,
                  fontWeight: c.id === selectedCategoryId ? "bold" : "inherit",
                }}
              >
                <span>{c.name}</span>
              </div>
            ))}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            style={{
              fontFamily: "inherit",
              fontSize: "inherit",
              textAlign: "right",
              color: "white",
              border: 1,
              borderRadius: 20,
              padding: 20,
              marginTop: 40,
              backgroundColor: "blue",
              cursor: "pointer",
              fontWeight: "bold",
            }}
            onClick={() => close()}
          >
            {t("common-button-close")}
          </button>
        </div>
        <div style={{ paddingBottom: 150 }} />
      </div>
    </Modal>
  );
};

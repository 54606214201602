import classes from "./GoogleLoginButton.module.css"

interface EmailLinkLoginButtonProps {
  onClick?: () => void
}

export const EmailLinkLoginButton = (props: EmailLinkLoginButtonProps) => {
  const { onClick } = props

  return (
    <div onClick={onClick} className={classes.googleBtn} role="button">
      <div className={classes.googleIconWrapper}>
        <img
          className={classes.googleIcon}
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/ec/Circle-icons-mail.svg/1024px-Circle-icons-mail.svg.png"
          alt="Email logo"
        />
      </div>
      <p className={classes.btnText}>
        <b>Email Link</b>
      </p>
    </div>
  )
}
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useEffect, useRef, useState, Dispatch, SetStateAction } from "react";
import { useIsRegistered } from "Authentication";
import { JoinHousehold, Register } from "Register";
import { Household } from "Household";
import { NotesHome } from "Notes";
import { useNotesSubscription } from "Notes/useNotesSubscription";
import { useHabitSubscription } from "Habits/useHabitSubscription";
import { RegisteredSplash } from "Splash";
import { CategoriesContextProvider, List, useItemSubscription } from "Lists";
import { useCategorySubscription } from "Lists/useCatgorySubscription";
//import { useCategorySubscriptionToDo } from "../../ListToDo";
import { ListToDo, useItemSubscriptionToDo } from "../../ListToDo";
import { Recipes, useRecipeSubscription } from "Recipes";
import { useMealPlannerSubscription } from "Recipes/useMealPlannerSubscription";
import { MyJournal } from "Journal/journal";
import { useEventSubscription } from "../../Journal/useEventSubscription";
import { usePlannerSubscription } from "Planner/usePlannerSubscription";
import { HabitSectionPage } from "Habits/components/TodayPage/HabitSectionPage.js";
import { Help } from "./help";
import { CustomNotification } from "../../Reminders/CustomNotification";
import { ReminderHistory } from "Reminders/ReminderHistory";
//import { ScoreSheet } from "Scoresheet/Scoresheet";
import { Scorehome } from "Scoresheet/Scorehome";
//import { useUserSubscription } from "Authentication/useUserSubscription";
import { useUserContext } from "Authentication";
import moment from "moment";
//import toast, { Toaster } from "react-hot-toast";
import { User } from "../../Authentication/models";
//@ts-ignore
import { NoteLanding } from "Notes/components/NoteLanding";
import { JournalLanding } from "Journal/modals/JournalLanding";
import { JournalLog } from "Journal/modals/JournalLog";
import { RecipeLanding } from "Recipes/RecipeLanding";
import { Planner } from "Planner/Planner";
import { PlannerLanding } from "Planner/PlannerLanding";
import { Test } from "Testing/test";
import { HabitLanding } from "Habits/components/TodayPage/HabitLanding";
import { ListToDoLanding } from "ListToDo/ListToDoLanding";
import { SortableListWidget } from "Splash/RegisteredSplash/SortableListWidget";
import { ListMagic } from "ListToDo/List/AIListGenerator/ListMagic";
import { IdeaGenerator } from "Notes/IdeaGenerator";
import { RecipeCreator } from "Recipes/RecipeCreator/RecipeCreator";
import { RecipeSearch } from "Recipes/RecipeCreator/RecipeSearch";
import { BuyNowModal } from "Authentication/BuyNowModal";
import { EventsModalEdit } from "Journal/modals/EventsModalEdit";
import { JournalEditLanding } from "Journal/modals/JournalEditLanding";
import { NoteEditLanding } from "Notes/components/NoteEditLanding";

interface RegisteredContentProps {
  user: User;
  isDarkMode: boolean;
  setIsDarkMode: Dispatch<SetStateAction<boolean>>;
  isColorPickerOpen: boolean;
  setIsColorPickerOpen: Dispatch<SetStateAction<boolean>>;
  stepCount: number;
  isOnline: boolean;
}

export const RegisteredContent = (props: RegisteredContentProps) => {
  const {
    user,
    isDarkMode,
    setIsDarkMode,
    isColorPickerOpen,
    setIsColorPickerOpen,
    isOnline,
    // stepCount,
  } = props;
  const { addedItems, allItems, isGroupPermissionOK } = useItemSubscription();
  const { addedItemsToDo, allItemsToDo } = useItemSubscriptionToDo();
  const { categories } = useCategorySubscription();

  const { recipes } = useRecipeSubscription();
  const { mealPlanner } = useMealPlannerSubscription();
  const { notes } = useNotesSubscription();
  const { events } = useEventSubscription();
  const { eventsPlanner } = usePlannerSubscription();
  const { habits } = useHabitSubscription();
  const [stepCount, setStepCount] = useState(0);
  // const user = useUserSubscription();

  const history = useHistory();
  const routeExpired = () => {
    history.push("/settings");
  };

  //Subscription checker

  const [isExpired, setIsExpired] = useState(false);
  const [isProValid, setIsProValid] = useState(false);
  const [isProTrue, setIsProTrue] = useState(false);
  //const userProfile = useUserSubscription();
  //const user = useUserContext();
  const { deleteNote, editNote } = useUserContext();
  const [isMobile, setIsMobile] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [updateMessage, setUpdateMessage] = useState(false);
  const [isAlohaPlanner, setIsAlohaPlanner] = useState(false);
  const [expirationDate, setExpirationDate] = useState(new Date());
  const isProCheck = useRef([]);

  //Check Dope Notes API to check Revenue Cat for entitlements
  const fetchData = async () => {
    try {
      return await fetch(
        `https://us-central1-dopenotes-20ee4.cloudfunctions.net/checksubscriptionstatus/${user.id}`
      )
        //  return fetch(`https://us-central1-dopenotes-20ee4.cloudfunctions.net/checksubscriptionstatus/xxxx`)
        // We get the API response and receive data in JSON format
        .then((response) => response.json())
        //    .then((data) => console.log(data.subscriber.entitlements))
        // .then((data) => setRCEntitlements(data.subscriber.entitlements.pro.expires_date))
        .then((data) => {
          //  console.log("entitlement", data.subscriber.entitlements.pro)
          var isPro = data.subscriber.entitlements.pro;
          checkSubscription(isPro);
          isProCheck.current = isPro;
          //  console.log("is Pro", isProCheck.current);
          //@ts-ignore
          if (isProCheck.current != undefined) {
            //@ts-ignore
            setExpirationDate(isProCheck.current.expires_date);
          }
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.log(error);
    }
  };

  // @ts-ignore

  // @ts-ignore
  const SubscriptiontDate = user.subscriptionEndDate;
  //console.log("what is the subscription date", SubscriptiontDate);

  var convertedDate;

  const convertDate = async (date) => {
    // @ts-ignore
    const update = new Date(date.seconds * 1000);
    convertedDate = moment(update).format("YYYYMMDD");
    //setting Holiday promotion
    if (convertedDate < 20230915) {
      // convertedDate = 20230915;
    }
    //end
    return convertedDate;
  };

  const today = new Date();
  const subscription =
    // @ts-ignore
    SubscriptiontDate !== undefined || ""
      ? convertDate(SubscriptiontDate)
      : new Date();

  const checkSubscription = async (isPro) => {
    if ((window as any).isAloha) {
      if (isPro === undefined) {
        setIsExpired(false);
        setIsProValid(true);
        setIsAlohaPlanner(true);
        try {
          (window as any).ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "CheckGoogleGDPRPolicy",
              // value: "on",
            })
          );
        } catch (error) {}
        try {
          (window as any).ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "bannerAdStatus",
              value: "on",
            })
          );
        } catch (error) {}

        //  if (!(window as any).isIOS) {

        // }
        if ((window as any).isNative) {
          // Post the message back to expo

          try {
            (window as any).ReactNativeWebView.postMessage(
              JSON.stringify({
                type: "changeAppOpenCountForAds",
                value: "2",
              })
            );
          } catch (error) {}

          /* Remove comment to turn adkeywords then set value to either false or true  
         (window as any).ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "adkeywordson",
              // value: "true",
            })
          );
          (window as any).ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "adkeywordslist",
              value:
                "fashion, clothing, shopping, grocery, recipe, auto, boat, family, lawyer, parent, children, sports, game, free, diet, focus, fitness, health, insurance, banking, travel, dining, deliver, transportation, ride share",
            })
          );*/
        }
      } else {
        setIsExpired(false);
        setIsProValid(false);
        setIsProTrue(true);
        setIsAlohaPlanner(true);
        //turning of until Apple Approves, set to OFF after.

        try {
          (window as any).ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "bannerAdStatus",
              value: "off",
            })
          );
        } catch (error) {}
      }
    } else {
      // console.log("what is date", subscription);
      // console.log("what is today", moment(new Date()).format("YYYYMMDD"));
      if (isPro === undefined) {
        setIsProValid(true);
        setIsProTrue(false);
      }

      if (
        isPro === undefined &&
        Number(moment(new Date()).format("YYYYMMDD")) > Number(convertedDate)
      ) {
        //Temp Fix
        setIsExpired(true);
      } else {
        setIsExpired(false);
      }
    }
  };
  // console.log("subDate", subscription)
  //  console.log("today", today)

  const launchAds = () => {
    if (isAlohaPlanner) {
      if (!isProTrue) {
        try {
          (window as any).ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "bannerAdStatus",
              value: "on",
            })
          );
        } catch (error) {}
      }
    }
  };

  const backButtonAction = () => {
    history.goBack();
  };

  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    fetchData();
    launchAds();

    //fireInterstitialAd();
    if (
      (window as any).isNative &&
      (window as any).isIOS &&
      (window as any).isStepAvailable
    ) {
      var handleMessage;
      try {
        handleMessage = (event) => {
          if (event.data != null || event.data != JSON) {
            const message = JSON.parse(event.data);

            if (message.type === "stepCount") {
              //     alert("what is the message: " + message.value);
              const tempSteps = message.value;
              if (tempSteps != 0) {
                setStepCount(tempSteps);
              }
            }
          }
        };
      } catch (error) {
        console.log("parse error ", error);
      }

      if ((window as any).isStepAvailable) {
        window.addEventListener("message", handleMessage);
      }
      return () => {
        if ((window as any).isStepAvailable) {
          window.removeEventListener("message", handleMessage);
        }
      };
    }

    return () => {};
  }, []);

  return (
    <Switch>
      <Route path="/test" exact>
        <Test recipes={recipes} />
      </Route>
      <Route path="/join/:id" exact>
        <JoinHousehold />
      </Route>
      <Route path="/settings" exact>
        <Household
          isExpired={isExpired}
          isOnline={isOnline}
          //@ts-ignore
          ExpirationDate={
            //@ts-ignore
            expirationDate
          }
          isPro={isProValid}
          isAlohaPlanner={isAlohaPlanner}
          isDarkMode={isDarkMode}
          setIsDarkMode={setIsDarkMode}
          isColorPickerOpen={isColorPickerOpen}
          setIsColorPickerOpen={setIsColorPickerOpen}
        />
      </Route>
      <Route path="/customnotification" exact>
        <CustomNotification title="" body="" daily={true} />
      </Route>
      <Route path="/reminderhistory" exact>
        <ReminderHistory />
      </Route>

      <Route path="/planner" exact>
        <Planner
          data={eventsPlanner}
          dataToDo={allItemsToDo}
          habits={habits}
          isExpired={isExpired}
        />
      </Route>
      <Route path="/planner/:productId" exact>
        <PlannerLanding data={eventsPlanner} isExpired={isExpired} />
      </Route>
      <Route path="/habits" exact>
        <HabitSectionPage
          habits={habits}
          stepCount={stepCount}
          journal={events}
          isExpired={isExpired}
        />
      </Route>
      <Route path="/habits/:productId" exact>
        <HabitLanding
          habits={habits}
          isExpired={isExpired}
          stepCount={stepCount}
          journal={events}
        />
      </Route>
      <Route path="/scorehome" exact>
        <Scorehome />
      </Route>
      <Route path="/scorepad" exact>
        <Scorehome />
      </Route>
      <Route path="/notes" exact>
        <NotesHome
          notes={notes}
          isExpired={isExpired}
          isDarkMode={isDarkMode}
        />
      </Route>
      <Route path="/notes/:productId" exact>
        <NoteLanding
          data={notes}
          isExpired={isExpired}
          handleDeleteNote={deleteNote}
          handleEditNote={editNote}
        />
      </Route>
      {/*}   <Route path="/notes/edit/:productId" exact>
        <NoteEditLanding
          data={notes}
          isExpired={isExpired}
          isDarkMode={isDarkMode}
          isOnline={isOnline}
        />
        </Route>*/}

      <Route path="/journal" exact>
        {/*}  <JournalLog
          data={events}
          isExpired={isExpired}
          isDarkMode={isDarkMode}
          isOnline={isOnline}
        />*/}
        <MyJournal
          oldevents={events}
          isExpired={isExpired}
          isDarkMode={isDarkMode}
          isOnline={isOnline}
        />
      </Route>
      <Route path="/journal/calendar" exact>
        <MyJournal
          oldevents={events}
          isExpired={isExpired}
          isDarkMode={isDarkMode}
          isOnline={isOnline}
        />
      </Route>
      <Route path="/journal/listview" exact>
        <JournalLog
          data={events}
          isExpired={isExpired}
          isDarkMode={isDarkMode}
          isOnline={isOnline}
        />
      </Route>
      <Route path="/journal/edit/:productId" exact>
        <JournalEditLanding
          data={events}
          isExpired={isExpired}
          isDarkMode={isDarkMode}
          isOnline={isOnline}
        />
      </Route>
      <Route path="/journal/:productId" exact>
        <JournalLanding
          data={events}
          isExpired={isExpired}
          isDarkMode={isDarkMode}
          isOnline={isOnline}
        />
      </Route>
      <Route path="/recipes" exact>
        <Recipes
          addedItems={addedItems}
          recipes={recipes}
          mealPlanner={mealPlanner}
          isGroupValid={isGroupPermissionOK}
          isOnline={isOnline}
          isExpired={isExpired}
        />
      </Route>
      <Route path="/recipes/:productId" exact>
        <RecipeLanding data={recipes} />
      </Route>
      <Route path="/shopping" exact>
        <List
          addedItems={addedItems}
          isOnline={isOnline}
          allItems={allItems}
          isExpired={isExpired}
          isDarkMode={isDarkMode}
          isGroupValid={isGroupPermissionOK}
          categories={categories}
        />
      </Route>
      <Route path="/sortablelists" exact>
        <SortableListWidget user={user} height="100%" isExpired={isExpired} />
      </Route>
      <Route path="/listmagic" exact>
        <ListMagic />
      </Route>
      <Route path="/buynow" exact>
        <BuyNowModal
          isBuyNow={true}
          close={backButtonAction}
          isExpired={isExpired}
          isOnline={isOnline}
          user={user}
        />
      </Route>
      <Route path="/recipesearch" exact>
        <RecipeSearch />
      </Route>
      <Route path="/ideas" exact>
        <IdeaGenerator />
      </Route>
      <Route path="/todo" exact>
        <ListToDo
          addedItemsToDo={addedItemsToDo}
          allItemsToDo={allItemsToDo}
          isExpired={isExpired}
          isDarkMode={isDarkMode}
        />
      </Route>
      <Route path="/todo/:productId" exact>
        <ListToDoLanding data={allItemsToDo} isExpired={isExpired} />
      </Route>
      <Route path="/privacy" exact>
        <div>
          {" "}
          <object
            data="https://www.iubenda.com/privacy-policy/54575108"
            width="97%"
            height="650px"
            type="text/html"
          ></object>
        </div>
      </Route>
      <Route path="/terms" exact>
        <div>
          {" "}
          <object
            data="https://www.iubenda.com/terms-and-conditions/54575108"
            width="97%"
            height="650px"
            type="text/html"
          ></object>
        </div>
      </Route>
      <Route path="/cookiepolicy" exact>
        <div>
          {" "}
          <object
            data="https://www.iubenda.com/privacy-policy/54575108/cookie-policy"
            width="97%"
            height="650px"
            type="text/html"
          ></object>
        </div>
      </Route>
      <Route path="/" exact>
        <RegisteredSplash
          //    itemCount={addedItems.length}
          notes={notes}
          stepCount={stepCount}
          addedItems={addedItems}
          allItems={allItems}
          todo={addedItemsToDo}
          todoAll={allItemsToDo}
          events={events}
          //@ts-ignore
          mealPlannerData={mealPlanner}
          //@ts-ignore
          recipes={recipes}
          habits={habits}
          user={user}
          eventsPlanner={eventsPlanner}
          isDarkMode={isDarkMode}
          isExpired={isExpired}
          isOnline={isOnline}
          categories={categories}
        />
      </Route>
      <Route path="/feedback" exact>
        <div>
          <object
            data="https://docs.google.com/forms/d/e/1FAIpQLSdQ5bGWWFtr30QdC6Y2XDBZ8QSeLh9TVthcyivECif5o2tmww/viewform?embedded=true"
            width="97%"
            height="1250px"
            type="text/html"
          ></object>
        </div>
      </Route>
      <Route path="/contact" exact>
        <Help />
      </Route>

      <Redirect to="/" />
    </Switch>
  );
};

const UnregisteredContent = () => (
  <Switch>
    <Route path="/join/:id" exact>
      <JoinHousehold />
    </Route>
    <Route path="/register" exact>
      <Register />
    </Route>

    <Redirect to="/register" />
  </Switch>
);

interface AuthenticateProps {
  //  itemCount: number;
  isDarkMode: boolean;
  setIsDarkMode: Dispatch<SetStateAction<boolean>>;
  isColorPickerOpen: boolean;
  setIsColorPickerOpen: Dispatch<SetStateAction<boolean>>;
  isOnline: boolean;
  // stepCount: number;
}

export const AuthenticatedContent = (props: AuthenticateProps) => {
  const {
    isDarkMode,
    setIsDarkMode,
    isColorPickerOpen,
    setIsColorPickerOpen,
    isOnline,
    //  stepCount,
  } = props;
  const user = useUserContext();

  //console.log("what is user fromt content", user.user);
  const userData = user.user;

  return useIsRegistered() ? (
    //@ts-ignore
    <RegisteredContent
      user={userData}
      isDarkMode={isDarkMode}
      setIsDarkMode={setIsDarkMode}
      isColorPickerOpen={isColorPickerOpen}
      setIsColorPickerOpen={setIsColorPickerOpen}
      isOnline={isOnline}
      // stepCount={stepCount}
    />
  ) : (
    <UnregisteredContent />
  );
};

import React from "react";
import { goods } from "../data/goods";

export const Market = ({
  marketPrices,
  openTransactionModal,
  inventory,
  marketState,
}) => {
  return (
    <div className="market-section">
      <div className="market-items">
        {Object.entries(marketPrices).map(([item, price]) => (
          <div key={item} style={{ width: 120 }} className="market-item">
            <div
              style={{
                paddingBottom: 5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {item}
              <span style={{ fontSize: 12, marginTop: -5 }}>
                {inventory[item] ? `${inventory[item]}` : "None"}
              </span>
              <div
                className="market-item-inventory"
                onClick={() => openTransactionModal("buy", item)}
              >
                Buy @ ${price}
              </div>
            </div>

            <div
              className="market-item-inventory"
              onClick={() => openTransactionModal("sell", item)}
            >
              Sell
            </div>
            {/*}     <br />
            <span style={{ fontSize: 12 }}>
              {" "}
              Supply: {marketState[item].supply} / Demand:{" "}
              {marketState[item].demand}
            </span>*/}
            {/*}     <button onClick={() => buyItem(item, 1)}>Buy</button>
            <button
              onClick={() => sellItem(item, 1)}
              disabled={!inventory[item]}
            >
              Sell
        </button>*/}
          </div>
        ))}
      </div>
    </div>
  );
};

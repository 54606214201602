import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  faLink,
  faEnvelope,
  faUserPlus,
  faTrash,
  faSignOut,
  faPen,
  faClose,
  faPaintRoller,
  faArrowRight,
  faRefresh,
  faWifi,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner } from "Common";
import classes from "./Household.module.css";
import cx from "classnames/bind";
import { useGroup, DatabaseUser } from "Authentication";
import { getFunctions, httpsCallable } from "firebase/functions";

const classnames = cx.bind(classes);
const functions = getFunctions();
const createInvitation = httpsCallable(functions, "createInvitation");

export const InviteMember = () => {
  const group = useGroup();
  const [working, setWorking] = useState(false);
  const [error, setError] = useState("");
  const [url, setUrl] = useState("");
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();

  if (!group) {
    throw new Error("Should not use household if group is not defined.");
  }

  const handleClick = async () => {
    //console.log("what is the url", url)
    try {
      setWorking(true);
      const { data: inviteId } = await createInvitation({ groupId: group.id });
      //@ts-ignore
      setUrl(inviteId);
    } catch (err) {
      console.error(err);
      setError(err);
    } finally {
      setWorking(false);
    }
  };

  const handleClickCopy = () => {
    //  console.log("what is the url", url)
    if (!url) {
      return;
    }
    try {
      navigator.clipboard.writeText(url);
      setCopied(true);
    } catch (err) {
      console.error(err);
      setError(err);
    }
  };

  if (working) {
    return (
      <>
        <span>{t("settings-create-invite-text")}...</span>
        <Spinner className={classes.spinner} />{" "}
      </>
    );
  }

  if (error) {
    return (
      <span>
        {t("settings-create-invite-warning")} {error}
      </span>
    );
  }

  if (url) {
    return (
      <>
        <p style={{ color: "red" }}>{t("settings-create-info")}</p>
        <p style={{ fontSize: 20, color: "red", fontWeight: "bold" }}>
          {" "}
          {url}{" "}
        </p>

        <div style={{ paddingTop: 0 }}>
          <button
            style={{
              fontFamily: "inherit",
              fontSize: "inherit",
              padding: 10,
              backgroundColor: "transparent",
              color: "blue",
              border: 0,
            }}
            onClick={() =>
              (window.location.href = `mailto:?body=Invitation%20Code%3A%20%0A${url}%0A%0AInvitation%20Link%3A%0Ahttps%3A%2F%2Fdopenotes.io%2Fjoin%2F${url}%0A%0APlease%20make%20sure%20to%20login%20to%20your%20account%20first%20or%20create%20a%20new%20account.%0A%0A`)
            }
          >
            <FontAwesomeIcon icon={faEnvelope} size="4x" />{" "}
            <p style={{ marginTop: -5 }}>{t("settings-create-emailcode")}</p>
          </button>
          <div>
            {" "}
            <button
              style={{
                fontFamily: "inherit",
                fontSize: "inherit",
                padding: 10,
                backgroundColor: "lightgrey",
                color: "blue",
                border: 1,
                borderStyle: "dashed",
              }}
              onClick={() => handleClick()}
            >
              {t("settings-create-generate-newcode")}
            </button>
          </div>
        </div>
      </>
    );
  }

  return (
    <button
      onClick={handleClick}
      className={classnames(classes.button)}
      style={{ backgroundColor: "transparent" }}
    >
      <FontAwesomeIcon icon={faUserPlus} size="2x" />
    </button>
  );
};

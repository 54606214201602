import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./Locales/en.json";
import frTranslation from "./Locales/fr.json";
import esTranslation from "./Locales/es.json";
import itTranslation from "./Locales/it.json";
import deTranslation from "./Locales/de.json";
import arTranslation from "./Locales/ar.json";
import cnTranslation from "./Locales/cn.json";
import krTranslation from "./Locales/kr.json";
import jpTranslation from "./Locales/jp.json";
import seTranslation from "./Locales/se.json";

export const resources = {
  en: { translation: enTranslation },
  fr: { translation: frTranslation },
  es: { translation: esTranslation },
  it: { translation: itTranslation },
  de: { translation: deTranslation },
  ar: { translation: arTranslation },
  cn: { translation: cnTranslation },
  kr: { translation: krTranslation },
  jp: { translation: jpTranslation },
  se: { translation: seTranslation },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // Default language
  fallbackLng: {
    fr: ["en"], // For French, use English translations as fallback for missing keys
    default: ["en"], // Default fallback language is English for other languages
  },
  interpolation: {
    escapeValue: false, // React already escapes values
  },
});

import React, { useState, useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useUserContext } from "./UserContext";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";

// Renders errors or successful transactions on the screen.
function Message({ content }) {
  return <p>{content}</p>;
}

export function PayPalSubscription({ userId, planId }) {
  const [selectedPlanId, setSelectedPlanId] = useState(planId);
  const { payPalSubscription } = useUserContext();
  const [isProcessing, setIsProcessing] = useState(false);
  const [seed, setSeed] = useState(1);
  const reset = () => {
    setSeed(Math.random());
    setSelectedPlanId(planId);
    setIsProcessing(false);
  };

  const { t } = useTranslation();

  const initialOptions = {
    "client-id":
      "ARb29VivMjFX9SJFeey6SA6Opkgl-pZeOCnH6QdaNuDDVhsaCF5KTB4g1BR09GVxk3CRT3HarxTYo1kD",

    "enable-funding": "paylater,card,applepay",
    "disable-funding": "",
    "data-sdk-integration-source": "integrationbuilder_sc",

    vault: "true",
    intent: "subscription",
  };

  const [message, setMessage] = useState("");

  // Effect to update selectedPlanId whenever the planId prop changes
  useEffect(() => {
    //  setSelectedPlanId(planId);
    //  setIsProcessing(false);
    reset();
  }, [planId]);

  return (
    <div className="App">
      <PayPalScriptProvider options={initialOptions} key={seed}>
        <PayPalButtons
          style={{
            shape: "rect",
            layout: "vertical",
            label: "subscribe",
          }}
          disabled={isProcessing}
          onCancel={() => {
            reset();
            setMessage("");
          }}
          createSubscription={async () => {
            var sendUserId = userId;
            console.log("Selected Plan ID", selectedPlanId);
            try {
              const response = await fetch(
                //  "http://127.0.0.1:5001/dopenotes-20ee4/us-central1/paypal/create-subscription",
                "https://us-central1-dopenotes-20ee4.cloudfunctions.net/paypal/create-subscription",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    userAction: "SUBSCRIBE_NOW",
                    planId: selectedPlanId,
                    userId: sendUserId,
                  }),
                }
              );
              const data = await response.json();
              if (data?.id) {
                setMessage(`Processing...`);
                setIsProcessing(true);

                return data.id;
              } else {
                console.error(
                  { callback: "createSubscription", serverResponse: data },
                  JSON.stringify(data, null, 2)
                );
                const errorDetail = data?.details?.[0];
                setMessage(
                  `Could not initiate PayPal Subscription...${
                    errorDetail?.issue || ""
                  } ${errorDetail?.description || data?.message || ""} ` +
                    (data?.debug_id ? `(${data.debug_id})` : "")
                );
              }
            } catch (error) {
              console.error(error);
              setMessage(`Could not initiate PayPal Subscription...${error}`);
              toast(t("purchase-fail"), {
                duration: 3000,
                position: "top-center",
                style: {
                  backgroundColor: "pink",
                  border: "1px solid #713200",
                  fontSize: "16px",
                  padding: "10px",
                  color: "black",
                },
              });
            }
          }}
          onApprove={async (data, actions) => {
            if (data.orderID) {
              /*  setMessage(
                `You have successfully subscribed to the plan. Your subscription id is: ${data.subscriptionID}`
              );*/
              await payPalSubscription(data.subscriptionID, selectedPlanId);
              toast(t("thank-you-for-support"), {
                duration: 3000,
                position: "top-center",
                style: {
                  backgroundColor: "lightgreen",
                  border: "1px solid #713200",
                  fontSize: "16px",
                  padding: "10px",
                  color: "black",
                },
              });
              setTimeout(() => {
                window.location.reload();
              }, 3500);
            } else {
              /*  setMessage(
                `Failed to activate the subscription: ${data.subscriptionID}`
              );*/
            }
          }}
        />
      </PayPalScriptProvider>

      <Message content={message} />
    </div>
  );
}

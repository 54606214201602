import React, { useState } from "react";
import classes from "./scoresheet.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faPlus,
  faTrashCan,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { ToggleSwitch } from "Common/ToggleSwitch/ToggleSwitch";
import { ScoreSheet } from "./Scoresheet";
import { useScoreSubscription } from "./useScoreSubscription";
import { useUserContext } from "Authentication";
import moment from "moment";
import { Button, Modal } from "Common";
import { ConfirmationModal } from "Common";
import toast, { Toaster } from "react-hot-toast";

export const Scorehome = () => {
  const { scores } = useScoreSubscription();
  const { addNewGame, deleteGame, editPlayers } = useUserContext();
  const [newGame, setNewGame] = useState({
    name: "",
    type: "General",
    players: [],
    isHighestScore: true,
  });
  const [isPadOpen, setIsPadOpen] = useState(false);
  const [selectedPadData, setSelectedPadData] = useState([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [tempRemovalID, setTempRemovalID] = useState();
  const [tempName, setTempName] = useState("");

  const createNewGame = (e) => {
    e.preventDefault();
    const index = scores.findIndex((item) => item.name === newGame.name);
    if (index > -1) {
      alert("Please enter a unique name");
    } else {
      addNewGame(newGame.name, newGame.type, newGame.isHighestScore);
      //  console.log("new game add", newGame);
      setNewGame({
        name: "",
        type: "General",
        players: [],
        isHighestScore: true,
      });
      toast("New Scorepad Added", {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "lightgreen",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
      setTempName("");
    }
  };

  const close = () => {
    setIsPadOpen(false);
  };

  const DeleteReminderConfirmation = () => {
    deleteGame(tempRemovalID);
    setConfirmModalOpen(false);
    toast("Scorepad Removed", {
      duration: 2000,
      position: "top-center",
      style: {
        backgroundColor: "lightgreen",
        border: "1px solid #713200",
        fontSize: "16px",
        padding: "10px",
        color: "black",
      },
    });
  };

  const removeGame = (gameID) => {
    setTempRemovalID(gameID);
    setConfirmModalOpen(true);
  };

  const convertDate = (date) => {
    const update = new Date(date.seconds * 1000);
    const updateFormatted = moment(update).format("MMM DD, YYYY");
    //  const update = date.toDate()
    return updateFormatted;
  };

  const toggleIsHighScore = () => {
    if (newGame.isHighestScore === false) {
      var updateGame = newGame;
      updateGame.isHighestScore = true;
      setNewGame(updateGame);
    } else {
      var updateGame = newGame;
      updateGame.isHighestScore = false;
      setNewGame(updateGame);
    }
  };

  const UpdateNewGameName = (e) => {
    setTempName(e.target.value);
    var updateGame = newGame;
    updateGame.name = e.target.value;
    setNewGame(updateGame);
  };

  const UpdateNewGameType = (e) => {
    var updateGame = newGame;
    updateGame.type = "General";
    setNewGame(updateGame);
  };

  const LaunchScorePad = (ScorePadName) => {
    setSelectedPadData(ScorePadName);
    setIsPadOpen(true);
  };

  return (
    <div style={{ textAlign: "center", fontWeight: "bold" }}>
      <h1>Game Scorepad</h1> <div style={{ paddingBottom: 5 }} />
      <p
        style={{
          paddingBottom: 10,
          fontSize: 18,
          lineHeight: 1.2,
          fontWeight: "normal",
          marginTop: -5,
        }}
      >
        Whether you're a fan of classic board games, fast-paced card games, or
        the thrill of a good roll, our score tracker has got you covered. With
        new templates on the way, you'll never run out of games to play and
        scores to keep. Let the games begin!
      </p>
      <form onSubmit={createNewGame}>
        <input
          type="text"
          name="name"
          value={tempName}
          required="required"
          minLength={1}
          style={{ fontSize: "inherit", fontFamily: "inherit", padding: 20 }}
          placeholder="Enter Game's Name"
          onChange={(e) => UpdateNewGameName(e)}
        />{" "}
        <br />
        {/*}  <input
          type="text"
          name="type"
          value={newGame.type}
          required="required"
          minLength={1}
          style={{fontSize: "inherit", fontFamily: "inherit", padding: 20}}
          placeholder="General"
          onChange={(e)=>UpdateNewGameType(e)}
  /> */}
        <ToggleSwitch label="Lowest Score Wins" onChange={toggleIsHighScore} />
        <br />{" "}
        <div style={{ marginTop: 0, paddingBottom: 30 }}>
          <button
            type="submit"
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              padding: 20,
              backgroundColor: "#3e98c7",
              border: 1,
              color: "white",
              borderRadius: 5,
              //  boxShadow: "2px 3px 3px grey, 0 0 5px #FCFBF4 inset",
              borderColor: "white",
              cursor: "pointer",
            }}
          >
            Create New Gamepad
          </button>
        </div>
      </form>
      {/* holding for later
{scores.map((games) => (<ScoreSheet data={games} />))}
*/}
      <div className={classes.gridHome}>
        {scores.map((games) => (
          <div
            style={{
              backgroundColor: "navajowhite",
              padding: 20,
              borderRadius: 20,
              boxShadow: "2px 3px 3px grey, 0 0 5px #FCFBF4 inset",
            }}
          >
            <h2 style={{ lineHeight: 1.2 }}>{games.name}</h2>
            <button
              style={{
                fontSize: "inherit",
                fontFamily: "inherit",
                padding: 2,
                backgroundColor: "#3e98c7",
                border: 1,
                color: "white",
                padding: 10,
                borderRadius: 5,
                borderColor: "white",
                cursor: "pointer",
              }}
              onClick={() => LaunchScorePad(games)}
            >
              Open Scorepad
            </button>
            <br />
            <hr />
            <span
              style={{
                fontWeight: "normal",
                fontFamily: "inherit",
                fontSize: 16,
              }}
            >
              {" "}
              Type: {games.type} <br />
              Leader is: {games.isHighestScore
                ? "Highest"
                : "Lowest"} score <br />
              Created: {convertDate(games.date)} <br />
              <button
                style={{
                  fontSize: "inherit",
                  fontFamily: "inherit",
                  padding: 2,
                  backgroundColor: "transparent",
                  border: 0,
                  color: "blue",
                }}
                onClick={() => removeGame(games.id)}
              >
                Delete Game
              </button>
            </span>
          </div>
        ))}
      </div>
      <Modal isOpen={isPadOpen} onRequestClose={close}>
        <div
          style={{
            display: "flex",
            alignItems: "right",
            justifyContent: "right",
            margin: 10,
          }}
        >
          {" "}
          <button
            style={{
              backgroundColor: "transparent",
              borderStyle: "none",
              cursor: "pointer",
              marginTop: 10,
              marginRight: 10,
            }}
            onClick={close}
          >
            <FontAwesomeIcon icon={faClose} size="3x" />
          </button>
        </div>
        <div style={{ paddingBottom: 150, paddingLeft: 20, paddingRight: 20 }}>
          <ScoreSheet data={selectedPadData} />
          <div style={{ paddingBottom: 150 }} />
        </div>
      </Modal>
      <ConfirmationModal
        isOpen={confirmModalOpen}
        text="Are you sure you want to remove this scorepad?"
        onClickCancel={() => setConfirmModalOpen(false)}
        onClickConfirm={DeleteReminderConfirmation}
      />
      <div style={{ paddingBottom: 150 }} />
    </div>
  );
};

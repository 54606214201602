import { Button } from "Common";
import { useState, Dispatch, SetStateAction } from "react";

import { Modal, ModalProps } from "../Modal";
import { useTranslation } from "react-i18next";
import classes from "../ConfirmationModal/ConfirmationModal.module.css";

export interface ConfirmationModalProps extends Omit<ModalProps, "children"> {
  title: string;
  text: string;
  updateText: React.Dispatch<React.SetStateAction<string>>;
  onClickCancel: () => void;
  onClickConfirm: () => void;
}

export const EditFieldModal = (props: ConfirmationModalProps) => {
  const {
    title,
    text,
    onClickCancel,
    onClickConfirm,
    updateText,

    ...modalProps
  } = props;
  const [NewText, setNewText] = useState("");
  const { t } = useTranslation();
  const updateCatName = () => {
    onClickConfirm();
    setNewText("");
  };

  const handleUpdate = (event) => {
    setNewText(event.target.value);
    updateText(event.target.value);
  };

  return (
    <Modal
      onRequestClose={onClickCancel}
      {...modalProps}
      style={{ overlay: { zIndex: 100 } }}
    >
      <h3 style={{ paddingLeft: 20, paddingRight: 20, fontSize: 24 }}>
        {title}
      </h3>
      <p
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          fontSize: 20,
          paddingBottom: 10,
        }}
      >
        {t("common-field-change")} "{text}"" {t("common-field-to")}:
      </p>

      <div
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          fontSize: 20,
          paddingBottom: 10,
        }}
      >
        <input
          style={{ width: 275, fontSize: 16, padding: 10 }}
          value={NewText}
          required
          onChange={(e) => handleUpdate(e)}
        />
      </div>
      <div
        className={classes.actions}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button
          onClick={onClickCancel}
          className={classes.secondaryButton}
          style={{
            fontFamily: "inherit",
            fontSize: "inherit",
            fontWeight: "bold",
            border: 0,
          }}
        >
          {t("common-button-cancel")}
        </button>
        {NewText.length >= 1 ? (
          <button
            style={{
              fontFamily: "inherit",
              fontSize: "inherit",
              fontWeight: "bold",
              border: 0,
            }}
            onClick={updateCatName}
          >
            {t("common-button-update")}
          </button>
        ) : (
          <button
            style={{
              fontFamily: "inherit",
              fontSize: "inherit",
              fontWeight: "bold",
              border: 0,
              opacity: 0.4,
            }}
            // onClick={onClickConfirm}
          >
            {t("common-button-update")}
          </button>
        )}
      </div>
    </Modal>
  );
};

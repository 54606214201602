import { useEffect, useState, memo } from "react";
import Linkify from "linkify-react";
import linkifyHtml from "linkify-html";
//import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import moment from "moment";
import { useEventSubscription } from "Journal/useEventSubscription";
import { EventsModalAddNew } from "Journal/modals/EventsModalAddNew";
import { EventsModalEdit } from "Journal/modals/EventsModalEdit";
import DOMPurify from "dompurify";
import { Link, Route, useRouteMatch, useHistory } from "react-router-dom";
import { JournalLanding } from "Journal/modals/JournalLanding";
import { useTranslation } from "react-i18next";

export const JournalWidget = ({
  isExpired,
  isEventsModalAddNewOpen,
  CloseAddNew,
  isDarkMode,
  isOnline,
}) => {
  const { events } = useEventSubscription();
  const [selectedEvent, setSelectedEvent] = useState();
  const [isEventsModalOpen, setIsEventsModalOpen] = useState(false);
  const [isEventsModalEditOpen, setIsEventsModalEditOpen] = useState(false);
  // const [isEventsModalAddNewOpen, setIsEventsModalAddNewOpen] = useState(false);
  const { url } = useRouteMatch();
  const { t } = useTranslation();

  var selectSlotStart = new Date();

  const convertDate = (date) => {
    const update = new Date(date.seconds * 1000);
    const updateFormatted = moment(update).format("MMMM DD, YYYY");
    //  const update = date.toDate()
    return updateFormatted;
  };

  const handleClickItemMore = (event) => {
    setSelectedEvent(event);
    setIsEventsModalOpen(true);
    // console.log("what is event", event);
  };

  const linkList = events.map((event) => {
    return (
      <div key={event.id}>
        <Link to={`journal/${event.id}`}>
          <div style={{ paddingBottom: 10 }} key={event.id}>
            <div
              style={{
                paddingLeft: 30,
                paddingRight: 30,
                paddingBottom: 10,
                border: 1,
                borderColor: "grey",
                borderRadius: 10,
                backgroundColor: event?.color,
                color: "black",
                boxShadow: "2px 3px 3px grey, 0 0 5px #FCFBF4 inset",
              }}
              key={event.id}
            >
              <Linkify options={{ target: "_blank" }}>
                <p
                  style={{
                    paddingTop: 20,
                    textAlign: "center",
                    fontStyle: "italic",
                  }}
                >
                  <span style={{ color: "teal " }}>
                    {convertDate(event.start)}{" "}
                  </span>
                </p>
                {/*<p>Entry Title:<br/>*/}{" "}
                <span>
                  <div
                    style={{
                      //   color: "navy",
                      letterSpacing: 2,
                      wordSpacing: 2,
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: linkifyHtml(DOMPurify.sanitize(event.title)),
                      }}
                    />
                  </div>
                </span>
                {event.imageURL != "" || undefined || null ? (
                  <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                    {" "}
                    <img
                      src={event.imageURL}
                      width="80%"
                      style={{
                        borderRadius: 10,
                        filter: isDarkMode ? "invert(100%)" : "none",
                      }}
                    />
                  </div>
                ) : null}
                {/* <p> Details:<br/>*/}{" "}
                <span>
                  <div
                    style={{
                      // color: "navy",
                      letterSpacing: 2,
                      wordSpacing: 2,
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: linkifyHtml(
                          DOMPurify.sanitize(event.description)
                        ),
                      }}
                    />
                  </div>
                </span>
                <div style={{ paddingBottom: 10, textAlign: "center" }}>
                  <button
                    onClick={() => handleClickItemMore(event)}
                    style={{
                      fontFamily: "inherit",
                      fontSize: "inherit",
                      backgroundColor: "transparent",
                      color: "teal",
                      border: 0,
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    {t("common-button-view")}
                  </button>
                </div>
              </Linkify>
            </div>
          </div>
        </Link>
      </div>
    );
  });

  useEffect(() => {
    // console.log("what is isExpire Journal Widget", isExpired);
    return () => {};
    //@ts-ignore
  }, []);

  return (
    <div>
      {events.length > 0 ? (
        <div style={{ padding: 10 }}>
          <div>{linkList}</div>
        </div>
      ) : (
        <p style={{ textAlign: "center" }}>{t("journal-log-noresults")}</p>
      )}
      {/*} <EventsModal
        isOpen={isEventsModalOpen}
        event={selectedEvent}
        close={() => setIsEventsModalOpen(false)}
        edit={() => {
          setIsEventsModalOpen(false);
          setIsEventsModalEditOpen(true);
        }}
      />*/}

      {isEventsModalAddNewOpen ? (
        <EventsModalAddNew
          isOpen={isEventsModalAddNewOpen}
          event={selectedEvent}
          selectedStartTime={selectSlotStart}
          close={CloseAddNew}
          isOnline={isOnline}
          isDarkMode={isDarkMode}
        />
      ) : null}

      <Route path={`${url}/:productId`}>
        <JournalLanding data={events} isExpired={isExpired} />
      </Route>
    </div>
  );
};

import { useCallback, useState, useEffect } from "react";
//import { ItemModel } from "Lists";
//import { SubscriptionChecker } from "Authentication/SubscriptionChecker";
import { RecipeModel } from "./models";
import { RecipeList } from "./RecipeList";
import { RecipeModal } from "./RecipeModal";
//import { WeeklyRecipes } from "./WeeklyRecipes";
//import { RecipeListActions } from "./RecipeListActions";
import { CheckGroupStatus } from "Authentication/checkGroupStatus";
import { useHistory } from "react-router-dom";
//import Fade from "react-reveal/Fade";
//import FadeIn from "react-fade-in/lib/FadeIn";
import { FirebaseAnalytics } from "Config/FirebaseAnalytics";
//import { ScheduleMeal } from "./MealPlanner/ScheduleMeal";
import { MealPlanner } from "./MealPlanner/MealPlanner";
//import LightSpeed from "react-reveal/LightSpeed";
import { useTranslation } from "react-i18next";
import { InviteMember } from "Household/InviteMember";
//import { BannerAd } from "GoogleAdSense/BannerAd";
//import { MultiplexAd } from "GoogleAdSense/MultiplexAd";

export const Recipes = ({
  addedItems,
  recipes,
  isGroupValid,
  isOnline,
  mealPlanner,
  isExpired,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState<RecipeModel>({
    name: "",
    id: "",
    directions: "",
    days: [],
  });
  const [filteredRecipes, setFilteredRecipes] = useState(recipes);
  const history = useHistory();
  const { t } = useTranslation();
  const getIsRecipeManagerOpen = localStorage.getItem("isRecipeManagerOpen");
  const [isInvitation, setIsInvitation] = useState(false);
  //console.log("what is getisHabits", getIsHabitsPref);

  const [isRecipeManagerOpen, setIsRecipeManagerOpen] = useState(
    getIsRecipeManagerOpen === "true" ? true : false
  );

  const toggleRecipeManagerView = () => {
    if (isRecipeManagerOpen) {
      localStorage.setItem("isRecipeManagerOpen", "false");
      setIsRecipeManagerOpen(false);
    } else {
      localStorage.setItem("isRecipeManagerOpen", "true");
      setIsRecipeManagerOpen(true);
    }
  };

  const goToProfile = () => {
    history.push("/settings");
  };

  function handleClickRecipe(recipe: RecipeModel) {
    setSelectedRecipe(recipe);
    setModalOpen(true);
  }

  function handleCloseRecipeModal() {
    setModalOpen(false);
  }

  function handleClickAdd() {
    setSelectedRecipe({
      name: "",
      id: "",
      directions: "",
      days: [],
    });
    setModalOpen(true);
  }

  const checkWeekly = () => {
    var count = 0;
    const updatedRecipes = recipes.map((recipe) => {
      if (recipe.days.length > 0) {
        // Add another day to the days array
        count++;
      }
    });

    if (count >= 1) {
      return true;
    } else {
      return false;
    }
  };

  //console.log("what is checkWeekly", checkWeekly());
  //console.log("what is mealplanner", mealPlanner);

  const handleFilterChanged = useCallback(
    (filter: string) => {
      const lowerFilter = filter.toLowerCase();
      if (lowerFilter === "") {
        setFilteredRecipes(recipes);
      } else {
        const filteredNotes = recipes.filter((item) => {
          return (
            item.name.toLowerCase().includes(lowerFilter) ||
            (item.tags != undefined &&
              item.tags.some(
                (tag) =>
                  typeof tag.text === "string" &&
                  tag.text.toLowerCase().includes(lowerFilter)
              ))
          );
        });

        setFilteredRecipes(filteredNotes);
      }
    },
    [recipes]
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    return () => {};
  }, []);

  return (
    <div style={{ backgroundColor: "ivory", borderRadius: 5 }}>
      <FirebaseAnalytics />

      <RecipeModal
        isOpen={modalOpen}
        recipe={selectedRecipe}
        addedItems={addedItems}
        close={handleCloseRecipeModal}
      />
      <div style={{ paddingTop: 20 }} />

      <h2 style={{ textAlign: "center" }} className="h2SlideIn">
        {" "}
        {t("recipe-title")}
      </h2>
      {/*}   <WeeklyRecipes recipes={recipes} onClickRecipe={handleClickRecipe} />*/}
      <MealPlanner data={recipes} mealPlannerData={mealPlanner} />

      {/*}   <RecipeListActions recipes={recipes} />*/}

      {isRecipeManagerOpen ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "lavender",
            padding: 10,
            borderRadius: 5,
          }}
        >
          <div
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
              textAlign: "right",

              fontSize: 14,
              // marginTop: -5,
            }}
            onClick={toggleRecipeManagerView}
          >
            {t("common-button-hide")}
          </div>
          <div>
            <h2 style={{ textAlign: "center", fontSize: 18 }}>
              {t("recipe-recipemanager-title")}
            </h2>
          </div>

          <RecipeList
            recipes={filteredRecipes}
            onClickRecipe={handleClickRecipe}
            onClickAdd={handleClickAdd}
            onFilterChange={handleFilterChanged}
            isOnline={isOnline}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <button
            className="metroButton"
            style={{
              backgroundColor: "blue",
              color: "white",
              width: 200,
              fontSize: 16,
            }}
            onClick={toggleRecipeManagerView}
          >
            {t("recipe-recipemanager-show-button")}
          </button>
        </div>
      )}
      {isOnline ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginTop: 20,
          }}
        >
          {!isInvitation ? (
            <button
              className="metroButton"
              onClick={() => setIsInvitation(true)}
              style={{
                backgroundColor: "blue",
                color: "white",
                width: 200,
                fontSize: 16,
              }}
            >
              Share Grocery List and Meal Planner
            </button>
          ) : (
            <span
              style={{
                fontSize: 14,
                fontWeight: "bold",
                paddingTop: 10,
                marginTop: 20,
                textAlign: "center",
                // backgroundColor: "yellow",
              }}
            >
              Click on the icon below to create a shareable invitation code for
              adding another member to Grocery List. <br />
              <br />
              <InviteMember />
              <p
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => setIsInvitation(false)}
              >
                Close Invitation
              </p>
            </span>
          )}
        </div>
      ) : null}
      <div style={{ paddingTop: 20 }} />

      <CheckGroupStatus isGroupValid={isGroupValid} />
      <div style={{ paddingBottom: 150 }} />
    </div>
  );
};

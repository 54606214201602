import { useState, useEffect, useRef } from "react";
import { Note } from "./Note";
//import { AddNote } from "./AddNote";
import classes from "../notes.module.css";
//import uparrowgif from "../../Assets/uparrow2.gif";
//import Draggable, { DraggableCore } from "react-draggable";
//import { ToggleSwitch } from "Common/ToggleSwitch/ToggleSwitch";
//import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Link, Route, useRouteMatch } from "react-router-dom";
import { NoteLanding } from "./NoteLanding";

export const NotesList = ({
  notes,
  handleAddNote,
  handleDeleteNote,
  handleEditNote,
  isExpired,
}) => {
  const [notesArray, setNotesArray] = useState([notes]);
  const [isColorSort, setIsColorSort] = useState(false);
  const [isDateSort, setIsDateSort] = useState(false);
  const [isReverse, setIsReverse] = useState(false);
  const filterType = useRef({ value: "" });
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const updateNewArray = () => {
    setNotesArray(notes);
    // console.log("filterType", filterType.current);
    filterResults(filterType.current);
  };

  //console.log("what is notelist", isExpired);

  useEffect(() => {
    if (notes) {
      updateNewArray();
      //  console.log("filterType", filterType.current);
    }
    return () => {};
  }, [notes, notesArray]);

  const toggleReverse = () => {
    isReverse ? setIsReverse(false) : setIsReverse(true);
  };

  const options = [
    { value: "dateasc", label: "by Date (ASC)" },
    { value: "datedesc", label: "by Date (DESC)" },
    { value: "nameasc", label: "by Name (ASC)" },
    { value: "namedesc", label: "by Name (DESC)" },
    { value: "color", label: "by Color" },
  ];

  const filterResults = (e) => {
    const value = e.value;
    // console.log(value);
    if (value === "color") {
      const temp = notes.sort((a, b) => b.color.localeCompare(a.color));
      setNotesArray(temp);
      filterType.current = { value: "color" };
      //  console.log("1");
    } else if (value === "namedesc") {
      const temp = notes.sort((a, b) => b.text.localeCompare(a.text));
      setNotesArray(temp);
      filterType.current = { value: "namedesc" };
      //  console.log("2");
    } else if (value === "nameasc") {
      const temp = notes.sort((a, b) => a.text.localeCompare(b.text));
      setNotesArray(temp);
      filterType.current = { value: "nameasc" };
      //  console.log("2");
    } else if (value === "datedesc") {
      const temp = notes.sort(
        (a, b) =>
          Math.floor(new Date(b.date).getTime() / 1000) -
          Math.floor(new Date(a.date).getTime() / 1000)
      );
      setNotesArray(temp);
      filterType.current = { value: "datedesc" };
      // console.log("3");
    } else if (value === "dateasc") {
      const temp = notes.sort(
        (a, b) =>
          Math.floor(new Date(a.date).getTime() / 1000) -
          Math.floor(new Date(b.date).getTime() / 1000)
      );
      setNotesArray(temp);
      filterType.current = { value: "dateasc" };
      // console.log("3");
    }

    isColorSort ? setIsColorSort(false) : setIsColorSort(true);
  };

  const linkList = notesArray.map((note, index) => {
    return (
      <div
        key={index}
        style={{
          maxHeight: 300,
          paddingBottom: 30,

          //maxWidth: "80%",
          /* Add other styles as required */
        }}
      >
        <Link to={`${url}/${note.id}`} key={note.id}>
          <Note
            key={note.id}
            id={note.id}
            text={note.text}
            tags={note.tags}
            date={note.date}
            color={note.color}
            handleDeleteNote={handleDeleteNote}
            handleEditNote={handleEditNote}
            isExpired={{ isExpired }}
            isLanding={false}
          />
        </Link>
      </div>
    );
  });

  return (
    <div>
      <div styles={{}}>
        {/*}   <Select
          options={options}
          placeholder="Select Filter"
          onChange={(e) => filterResults(e)}
          style={{ fontSize: "inherit", fontFamily: "inherit" }}
  />*/}
      </div>
      {notes.length > 0 ? (
        <div
          className={classes.notesList}
          style={{
            paddingTop: 10,
          }}
        >
          {linkList}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginTop: 0,
          }}
        >
          <p style={{ textAlign: "center", opacity: 8 }}>
            {t("notes-addnew-dication")}
          </p>
        </div>
      )}
      <Route path={`${url}/:productId`}>
        <NoteLanding
          data={notesArray}
          isExpired={isExpired}
          handleDeleteNote={handleDeleteNote}
          handleEditNote={handleEditNote}
        />
      </Route>
      <Route exact path={url}></Route>
    </div>
  );
};

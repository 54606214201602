export const classicRecipesDE = [
  {
    id: "dnr003",
    recipeUrl: "",
    name: "Spaghetti mit Fleischbällchen",
    directions: `
                <ol>
                  <li>Heizen Sie den Ofen auf 175 Grad C (350 Grad F) vor.</li>
                  <li>In einer großen Schüssel Rinderhackfleisch, Paniermehl, Parmesankäse, Eier, Zwiebel, Knoblauch, Salz und Pfeffer vermischen. Gut vermengen.</li>
                  <li>Formen Sie die Fleischmischung zu Fleischbällchen von etwa 2,5 cm Durchmesser.</li>
                  <li>Erhitzen Sie Olivenöl in einer großen Pfanne bei mittlerer Hitze.</li>
                  <li>Kochen Sie die Fleischbällchen im heißen Öl für 10-12 Minuten, oder bis sie von allen Seiten gebräunt sind.</li>
                  <li>Übertragen Sie die Fleischbällchen in eine Auflaufform.</li>
                  <li>In einem Topf Tomatensoße, Wasser und italienische Gewürze kombinieren. Bei mittlerer Hitze zum Köcheln bringen.</li>
                  <li>Gießen Sie die Tomatensoße über die Fleischbällchen.</li>
                  <li>Backen Sie im vorgeheizten Ofen für 20 Minuten, oder bis die Fleischbällchen durchgegart sind.</li>
                  <li>Servieren Sie über gekochten Spaghetti.</li>
                </ol>
              `,
    days: [],
    tags: [
      {
        text: "Italienisch",
        color: "indigo",
      },
      {
        text: "Abendessen",
        color: "indigo",
      },
      {
        text: "Hauptgericht",
        color: "indigo",
      },
    ],
    ingredients: [
      {
        name: "Rinderhackfleisch",
        measurement: "1 Pfund",
      },
      {
        name: "Paniermehl",
        measurement: "1/2 Tasse",
      },
      {
        name: "geriebener Parmesankäse",
        measurement: "1/4 Tasse",
      },
      {
        name: "Eier",
        measurement: "2 Eier, geschlagen",
      },
      {
        name: "Zwiebel",
        measurement: "1/2 Tasse, gehackt",
      },
      {
        name: "Knoblauch",
        measurement: "2 Zehen, gehackt",
      },
      {
        name: "Salz",
        measurement: "1 Teelöffel",
      },
      {
        name: "schwarzer Pfeffer",
        measurement: "1/2 Teelöffel",
      },
      {
        name: "Olivenöl",
        measurement: "1/4 Tasse",
      },
      {
        name: "Geschälte Tomaten in Dose",
        measurement: "1 (28 Unzen)",
      },
      {
        name: "Wasser",
        measurement: "1 Tasse",
      },
      {
        name: "Italienische Gewürzmischung",
        measurement: "1 Teelöffel",
      },
      {
        name: "Spaghetti",
        measurement: "12 Unzen",
      },
    ],
  },
  {
    id: "X7gR4Kz2pQ",
    recipeUrl: "",
    name: "Schnitzel",
    directions:
      "<ol><li>Das Fleisch flach klopfen, mit Salz und Pfeffer würzen.</li><li>In einer Schüssel die Eier verquirlen. Mehl und Paniermehl jeweils in separate Teller geben.</li><li>Das Fleisch zuerst im Mehl, dann im Ei und zuletzt im Paniermehl wenden.</li><li>In einer Pfanne reichlich Öl erhitzen und die Schnitzel von beiden Seiten goldbraun braten.</li><li>Auf Küchenpapier abtropfen lassen und heiß servieren.</li></ol>",
    days: [],
    tags: [
      { text: "Hauptgericht", color: "indigo" },
      { text: "Deutsche Küche", color: "indigo" },
      { text: "Fleisch", color: "indigo" },
    ],
    ingredients: [
      { name: "Schweine- oder Kalbfleisch", measurement: "4 Scheiben" },
      { name: "Salz", measurement: "Nach Geschmack" },
      { name: "Pfeffer", measurement: "Nach Geschmack" },
      { name: "Eier", measurement: "2" },
      { name: "Mehl", measurement: "100g" },
      { name: "Paniermehl", measurement: "100g" },
      { name: "Öl zum Braten", measurement: "Nach Bedarf" },
    ],
  },
  {
    id: "L9m2Zd5KvN",
    recipeUrl: "",
    name: "Bratwurst",
    directions:
      "<ol><li>Bratwürste aus der Verpackung nehmen und bei Zimmertemperatur ruhen lassen.</li><li>Eine Pfanne bei mittlerer Hitze erwärmen und etwas Öl hinzufügen.</li><li>Die Bratwürste in die Pfanne geben und von allen Seiten gleichmäßig bräunen, etwa 8-10 Minuten.</li><li>Regelmäßig wenden, um ein gleichmäßiges Garen zu gewährleisten.</li><li>Bratwürste aus der Pfanne nehmen und kurz ruhen lassen. Mit Senf und Brötchen servieren.</li></ol>",
    days: [],
    tags: [
      { text: "Hauptgericht", color: "indigo" },
      { text: "Deutsche Küche", color: "indigo" },
      { text: "Fleisch", color: "indigo" },
    ],
    ingredients: [
      { name: "Bratwürste", measurement: "4" },
      { name: "Öl", measurement: "2 EL" },
      { name: "Senf", measurement: "Nach Geschmack" },
      { name: "Brötchen", measurement: "4" },
    ],
  },
  {
    id: "Q5hT9zC3Wn",
    recipeUrl: "",
    name: "Sauerbraten",
    directions:
      "<ol><li>Das Fleisch in einer Marinade aus Essig, Wasser, Lorbeerblättern, Wacholderbeeren, Nelken und Zwiebeln mindestens 24 Stunden einlegen.</li><li>Das Fleisch aus der Marinade nehmen, abtrocknen, salzen und pfeffern.</li><li>In einem Bräter Öl erhitzen und das Fleisch von allen Seiten scharf anbraten.</li><li>Die Marinade durch ein Sieb geben und zum Fleisch in den Bräter geben. Bei niedriger Hitze 2-3 Stunden schmoren lassen.</li><li>Fleisch herausnehmen, Soße mit Salz, Pfeffer und evtl. etwas Zucker abschmecken. Mit Kartoffelklößen oder Spätzle servieren.</li></ol>",
    days: [],
    tags: [
      { text: "Hauptgericht", color: "indigo" },
      { text: "Deutsche Küche", color: "indigo" },
      { text: "Fleisch", color: "indigo" },
    ],
    ingredients: [
      { name: "Rindfleisch", measurement: "1 kg" },
      { name: "Essig", measurement: "250 ml" },
      { name: "Wasser", measurement: "250 ml" },
      { name: "Lorbeerblätter", measurement: "2" },
      { name: "Wacholderbeeren", measurement: "5" },
      { name: "Nelken", measurement: "3" },
      { name: "Zwiebeln", measurement: "2" },
      { name: "Öl", measurement: "2 EL" },
      { name: "Salz", measurement: "Nach Geschmack" },
      { name: "Pfeffer", measurement: "Nach Geschmack" },
      { name: "Zucker", measurement: "Nach Bedarf" },
      { name: "Kartoffelklöße", measurement: "Begleitung" },
      { name: "Spätzle", measurement: "Begleitung" },
    ],
  },
  {
    id: "T8pR9kZ3Lm",
    recipeUrl: "",
    name: "Spätzle",
    directions:
      "<ol><li>In einer Schüssel Mehl, Eier, Salz und Wasser zu einem glatten Teig verrühren.</li><li>Einen großen Topf mit Salzwasser zum Kochen bringen.</li><li>Den Teig durch eine Spätzlepresse oder ein Spätzlesieb ins kochende Wasser drücken. Alternativ den Teig von einem Brett schaben.</li><li>Die Spätzle sind fertig, wenn sie an der Wasseroberfläche schwimmen. Mit einem Schaumlöffel herausnehmen und abtropfen lassen.</li><li>In einer Pfanne Butter erhitzen und die Spätzle darin schwenken. Mit frisch geriebenem Käse servieren.</li></ol>",
    days: [],
    tags: [
      { text: "Beilage", color: "indigo" },
      { text: "Deutsche Küche", color: "indigo" },
      { text: "Teigwaren", color: "indigo" },
    ],
    ingredients: [
      { name: "Mehl", measurement: "500g" },
      { name: "Eier", measurement: "4" },
      { name: "Salz", measurement: "1 TL" },
      { name: "Wasser", measurement: "200 ml" },
      { name: "Butter", measurement: "2 EL" },
      { name: "Käse", measurement: "Nach Geschmack" },
    ],
  },
  {
    id: "Z6fJ8Vx1pX",
    recipeUrl: "",
    name: "Rouladen",
    directions:
      "<ol><li>Die Rinderrouladen ausbreiten, mit Senf bestreichen, mit Speck, Zwiebeln und Gewürzgurken belegen. Salzen und pfeffern.</li><li>Die Rouladen aufrollen und mit Küchengarn oder Rouladennadeln fixieren.</li><li>In einem Bräter Öl erhitzen und die Rouladen von allen Seiten scharf anbraten.</li><li>Zwiebeln und Tomatenmark hinzufügen und kurz mitbraten. Mit Brühe ablöschen und etwa 1 Stunde schmoren lassen.</li><li>Die Rouladen aus der Soße nehmen, diese nach Bedarf binden und abschmecken. Rouladen mit der Soße servieren.</li></ol>",
    days: [],
    tags: [
      { text: "Hauptgericht", color: "indigo" },
      { text: "Deutsche Küche", color: "indigo" },
      { text: "Fleisch", color: "indigo" },
    ],
    ingredients: [
      { name: "Rinderrouladen", measurement: "4 Stück" },
      { name: "Senf", measurement: "4 TL" },
      { name: "Speck", measurement: "4 Scheiben" },
      { name: "Zwiebeln", measurement: "2" },
      { name: "Gewürzgurken", measurement: "4 Stück" },
      { name: "Salz", measurement: "Nach Geschmack" },
      { name: "Pfeffer", measurement: "Nach Geschmack" },
      { name: "Öl", measurement: "2 EL" },
      { name: "Tomatenmark", measurement: "2 EL" },
      { name: "Rinderbrühe", measurement: "500 ml" },
    ],
  },
  {
    id: "B4cK0lQ8wR",
    recipeUrl: "",
    name: "Kartoffelsalat",
    directions:
      "<ol><li>Kartoffeln kochen, abkühlen lassen und in Scheiben schneiden.</li><li>In einer Schüssel eine Vinaigrette aus Essig, Öl, Salz, Pfeffer und einer Prise Zucker anrühren.</li><li>Zwiebel fein würfeln und zu den Kartoffeln geben.</li><li>Die Vinaigrette über die Kartoffeln und Zwiebeln gießen und vorsichtig vermischen.</li><li>Den Salat vor dem Servieren mindestens eine Stunde ziehen lassen.</li></ol>",
    days: [],
    tags: [
      { text: "Beilage", color: "indigo" },
      { text: "Deutsche Küche", color: "indigo" },
      { text: "Salat", color: "indigo" },
    ],
    ingredients: [
      { name: "Kartoffeln", measurement: "1 kg" },
      { name: "Essig", measurement: "3 EL" },
      { name: "Öl", measurement: "5 EL" },
      { name: "Salz", measurement: "Nach Geschmack" },
      { name: "Pfeffer", measurement: "Nach Geschmack" },
      { name: "Zucker", measurement: "1 Prise" },
      { name: "Zwiebel", measurement: "1" },
    ],
  },
  {
    id: "N3gL9kZ6Wu",
    recipeUrl: "",
    name: "Brezel",
    directions:
      "<ol><li>In einer Schüssel Mehl, Hefe, Salz, Zucker und Wasser zu einem Teig verkneten und etwa 1 Stunde gehen lassen.</li><li>Den Teig in 8 gleich große Stücke teilen und zu langen Strängen rollen. Jeden Strang zu einer Brezelform biegen.</li><li>Einen großen Topf mit Wasser zum Kochen bringen und Natron hinzufügen.</li><li>Die Brezeln einzeln für etwa 30 Sekunden im Wasserbad kochen, dann herausnehmen und abtropfen lassen.</li><li>Die Brezeln auf ein Backblech legen, mit grobem Salz bestreuen und im vorgeheizten Ofen bei 200°C etwa 15-20 Minuten backen.</li></ol>",
    days: [],
    tags: [
      { text: "Snack", color: "indigo" },
      { text: "Deutsche Küche", color: "indigo" },
      { text: "Gebäck", color: "indigo" },
    ],
    ingredients: [
      { name: "Mehl", measurement: "500g" },
      { name: "Frische Hefe", measurement: "1 Würfel" },
      { name: "Salz", measurement: "1 TL" },
      { name: "Zucker", measurement: "1 TL" },
      { name: "Wasser", measurement: "300 ml" },
      { name: "Natron", measurement: "3 EL" },
      { name: "Grobe Salz", measurement: "Zum Bestreuen" },
    ],
  },
  {
    id: "P7rT0cE2Lq",
    recipeUrl: "",
    name: "Käsespätzle",
    directions:
      "<ol><li>In einer großen Schüssel Mehl, Eier, Salz und Wasser zu einem glatten Teig verrühren.</li><li>Einen großen Topf mit Salzwasser zum Kochen bringen.</li><li>Den Teig durch eine Spätzlepresse oder ein Spätzlesieb ins kochende Wasser drücken, bis die Spätzle an die Oberfläche kommen. Dann herausnehmen und abtropfen lassen.</li><li>In einer Pfanne Butter erhitzen und die Zwiebeln goldbraun braten.</li><li>In einer Auflaufform abwechselnd Schichten von Spätzle und geriebenem Käse anlegen. Die gebratenen Zwiebeln darüber geben.</li><li>Im vorgeheizten Backofen bei 200°C für ca. 10 Minuten backen, bis der Käse geschmolzen ist.</li></ol>",
    days: [],
    tags: [
      { text: "Hauptgericht", color: "indigo" },
      { text: "Deutsche Küche", color: "indigo" },
      { text: "Teigwaren", color: "indigo" },
    ],
    ingredients: [
      { name: "Mehl", measurement: "500g" },
      { name: "Eier", measurement: "4" },
      { name: "Salz", measurement: "1 TL" },
      { name: "Wasser", measurement: "200 ml" },
      { name: "Butter", measurement: "2 EL" },
      { name: "Zwiebeln", measurement: "2" },
      { name: "Geriebener Käse", measurement: "200g" },
    ],
  },
  {
    id: "R9zE6Ks2Lf",
    recipeUrl: "",
    name: "Apfelstrudel",
    directions:
      "<ol><li>Für den Teig Mehl, Öl, eine Prise Salz und Wasser zu einem geschmeidigen Teig kneten und 30 Minuten ruhen lassen.</li><li>Äpfel schälen, entkernen und in dünne Scheiben schneiden. Mit Zitronensaft, Zucker, Rosinen und Zimt mischen.</li><li>Den Teig auf einem bemehlten Tuch dünn ausrollen, mit geschmolzener Butter bestreichen.</li><li>Die Apfelmischung auf dem Teig verteilen, dabei einen Rand frei lassen.</li><li>Den Strudel mit Hilfe des Tuches aufrollen, auf ein Backblech legen und mit Butter bestreichen.</li><li>Bei 180°C für etwa 40 Minuten backen, bis der Strudel goldbraun ist.</li></ol>",
    days: [],
    tags: [
      { text: "Dessert", color: "indigo" },
      { text: "Deutsche Küche", color: "indigo" },
      { text: "Gebäck", color: "indigo" },
    ],
    ingredients: [
      { name: "Mehl", measurement: "300g" },
      { name: "Öl", measurement: "2 EL" },
      { name: "Salz", measurement: "1 Prise" },
      { name: "Wasser", measurement: "150 ml" },
      { name: "Äpfel", measurement: "4" },
      { name: "Zitronensaft", measurement: "1 EL" },
      { name: "Zucker", measurement: "100g" },
      { name: "Rosinen", measurement: "50g" },
      { name: "Zimt", measurement: "1 TL" },
      { name: "Geschmolzene Butter", measurement: "Zum Bestreichen" },
    ],
  },
  {
    id: "M0zF5Gt3Wq",
    recipeUrl: "",
    name: "Schwarzwälder Kirschtorte",
    directions:
      "<ol><li>Backofen auf 180°C vorheizen. Drei Springformen einfetten und den Boden mit Backpapier auslegen.</li><li>Schokolade schmelzen und abkühlen lassen. Eier und Zucker schaumig schlagen, dann die abgekühlte Schokolade unterrühren.</li><li>Mehl, Backpulver und Kakao sieben und unter die Eier-Schokoladen-Mischung heben. Den Teig gleichmäßig auf die Formen verteilen und etwa 25 Minuten backen.</li><li>Kirschen abtropfen lassen, Saft auffangen. Sahne mit Vanillezucker und Sahnesteif schlagen.</li><li>Böden abkühlen lassen, dann mit Kirschsaft tränken. Sahne und Kirschen zwischen den Böden verteilen und die Torte zusammensetzen.</li><li>Die Torte mit Sahne einstreichen und mit Schokoraspeln und Kirschen dekorieren.</li></ol>",
    days: [],
    tags: [
      { text: "Dessert", color: "indigo" },
      { text: "Deutsche Küche", color: "indigo" },
      { text: "Kuchen", color: "indigo" },
    ],
    ingredients: [
      { name: "Schokolade", measurement: "100g" },
      { name: "Eier", measurement: "4" },
      { name: "Zucker", measurement: "200g" },
      { name: "Mehl", measurement: "200g" },
      { name: "Backpulver", measurement: "1 TL" },
      { name: "Kakao", measurement: "2 EL" },
      { name: "Kirschen", measurement: "1 Glas" },
      { name: "Sahne", measurement: "500 ml" },
      { name: "Vanillezucker", measurement: "2 Päckchen" },
      { name: "Sahnesteif", measurement: "2 Päckchen" },
      { name: "Schokoraspeln", measurement: "Zum Dekorieren" },
    ],
  },
];

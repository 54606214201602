import React from "react";
import { TravelOptions } from "./TravelOptions";

export const TravelModal = ({
  isOpen,
  onClose,
  islands,
  currentIsland,
  handleTravel,
}) => {
  if (!isOpen) return null;

  return (
    <div className="game-modal-overlay">
      <div className="game-modal">
        <h2>Where To Next?</h2>
        <TravelOptions
          islands={islands}
          currentIsland={currentIsland}
          onTravel={handleTravel}
        />
        <button onClick={onClose} className="game-close-button">
          Close
        </button>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export const TagCreator = ({ pushTags, data }) => {
  const [tags, setTags] = useState(data != "Test Tag" || undefined ? data : []);
  const { t } = useTranslation();
  const [newTag, setNewTag] = useState("");
  const [newTagColor, setNewTagColor] = useState("");

  const handleTagChange = (event) => {
    setNewTag(event.target.value);
  };

  const handleColorChange = (event) => {
    setNewTagColor(event.target.value);
  };

  const handleTagSubmit = (event) => {
    event.preventDefault();

    if (newTag) {
      const newTagObj = {
        text: newTag,
        color: "indigo",
      };

      setTags([...tags, newTagObj]);
      pushTags([...tags, newTagObj]);
      setNewTag("");
      setNewTagColor("");
    }
  };

  const handleTagRemove = (index) => {
    const updatedTags = [...tags];

    updatedTags.splice(index, 1);
    setTags(updatedTags);
    pushTags(updatedTags);
  };

  const sortedTags = [...tags].sort((a, b) => a.text.localeCompare(b.text));

  useEffect(() => {
    return () => {};
  }, [data.tags]);

  return (
    <div
      style={{
        paddingTop: 20,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <form onSubmit={handleTagSubmit}>
          <div
            style={{ fontStyle: "italic", textAlign: "center", color: "white" }}
          >
            {t("tag-generator-label")}
          </div>
          <br />
          <input
            type="text"
            placeholder=""
            maxLength={60}
            value={newTag}
            onChange={handleTagChange}
            style={{
              fontSize: 16,
              fontFamily: "inherit",
              padding: 10,
              marginTop: -15,
            }}
          />
          {/*}   <input type="color" value={newTagColor} onChange={handleColorChange} />*/}
          <br />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 10,
            }}
          >
            <button
              style={{
                fontSize: 14,
                fontFamily: "inherit",
                padding: 5,
                borderRadius: 10,

                textAlign: "center",
                paddingLeft: 20,
                paddingRight: 20,
              }}
              type="submit"
            >
              {t("common-button-addnew")}
            </button>
          </div>
        </form>
      </div>
      <div style={{ paddingTop: 10 }}>
        {sortedTags.map((tag, index) => (
          <div
            key={index}
            style={{
              backgroundColor: tag.color,
              padding: "5px",
              paddingLeft: "10px",
              paddingRight: "10px",
              margin: "5px",
              borderRadius: "5px",
              color: "#fff",
              fontSize: 14,
            }}
          >
            {tag.text}
            <button
              style={{
                float: "right",
                backgroundColor: "transparent",
                color: "white",
              }}
              onClick={() => handleTagRemove(index)}
            >
              X
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

//import { useCategories } from "Lists/CategoriesContext"
import { useUserContext } from "Authentication";
//import { useCategorySubscription } from "../../../Lists/useCatgorySubscription";
import { Modal } from "Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import classes from "./CategoryModal.module.css";
import { useItems } from "../../useItems";
import { addDoc } from "firebase/firestore";
import { useState } from "react";

interface CategoryAddItemModalProps {
  isOpen: boolean;
  quantity: string;
  value: string;
  close: () => void;
  categories: any[];
}

export const CategoryAddItemModal = (props: CategoryAddItemModalProps) => {
  const { isOpen, close, value, quantity, categories } = props;
  //const categories = useCategories()
  //  const { categories } = useCategorySubscription();
  const useItemAddThis = useItems();
  const { getCategoriesForGroup } = useUserContext();
  const GroupCategory = getCategoriesForGroup();
  // const [quantity, setQuantity] = useState(1)
  const handleClick = async (category: string) => {
    await useItemAddThis.addItem(value, quantity, category);
    //  console.log("what is the value at submission", value, category)
    //  window.location.reload()
    close();
  };

  var numberOfCategories = 10;
  var NewCatName = "";
  var randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);

  var newCategory = {
    name: NewCatName,
    colour: randomColor,
    order: numberOfCategories,
  };

  const addCategoryDB = async (name, id, color) => {
    const categoriesRef = GroupCategory;
    const newCategory = {
      name,
      id,
      color,
    };

    await addDoc(categoriesRef, newCategory);
  };

  const addCategory = () => {
    // console.log("home many categories: ", numberOfCategories)

    //get name
    NewCatName = window.prompt("Please enter category name to create");
    // set order ID

    newCategory = {
      name: NewCatName,
      colour: randomColor,
      order: numberOfCategories,
    };

    if (NewCatName === null) {
      //  console.log("no name");
    } else {
      addCategoryDB(NewCatName, numberOfCategories, randomColor);
    }
    //console.log("catgory should be added", NewCatName, randomColor, numberOfCategories)

    //reset everything
    numberOfCategories = null;
    NewCatName = "";
    randomColor = Math.floor(Math.random() * 16777215).toString(16);
    numberOfCategories = 0;
    //window.location.reload()
    //  close()
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={close}>
      <div style={{ paddingBottom: 250, padding: 20 }}>
        <div
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            paddingBottom: 10,
            border: 1,
            borderColor: "grey",
            borderRadius: 10,
            backgroundColor: "snow",
            fontWeight: "inherit !important",
            color: "black",
            boxShadow: "2px 3px 3px grey, 0 0 5px #FCFBF4 inset",
          }}
        >
          <>
            <div
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "right",
                margin: 10,
              }}
            >
              {" "}
              <button
                style={{
                  backgroundColor: "transparent",
                  borderStyle: "none",
                  cursor: "pointer",
                  marginTop: 10,
                  marginRight: -30,
                }}
                onClick={close}
              >
                <FontAwesomeIcon icon={faClose} size="1x" />
              </button>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 10,
                paddingTop: 10,
                paddingBottom: 10,
                fontSize: 20,
                fontWeight: "bold",
              }}
            >
              {" "}
              <span>SELECT A CATEGORY</span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 0,
                paddingTop: 0,
                paddingBottom: 20,
              }}
            >
              <button
                style={{
                  fontSize: 16,
                  textAlign: "right",
                  color: "blue",
                  border: 0,
                  backgroundColor: "transparent",
                  cursor: "pointer",
                }}
                onClick={addCategory}
              >
                ADD NEW
              </button>
            </div>
            {categories
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((c) => (
                <div
                  key={c.id} // Use a unique identifier (e.g., c.id) as the key
                  onClick={() => handleClick(c.id || "")}
                  className={classes.category}
                  style={{
                    backgroundColor: `${c.colour}40`,
                    fontWeight: c.id === "" ? "bold" : "inherit",
                  }}
                >
                  <span>{c.name}</span>
                </div>
              ))}
          </>
        </div>{" "}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            style={{
              fontFamily: "inherit",
              fontSize: "inherit",
              textAlign: "right",
              color: "white",
              border: 1,
              borderRadius: 20,
              padding: 20,
              marginTop: 40,
              backgroundColor: "blue",
              cursor: "pointer",
              fontWeight: "bold",
            }}
            onClick={() => close()}
          >
            Close Window
          </button>
        </div>
        <div style={{ paddingBottom: 150 }} />
      </div>
    </Modal>
  );
};

//import firebase from "firebase/app";
//import firebase from "firebase/compat/app";
import { Timestamp } from "firebase/firestore";

export enum UserState {
  Unregistered = "Unregistered",
  Registered = "Registered",
  NewGroup = "NewGroupRequired",
}

/** The object exposed by firebase authentication */
export interface AuthUser {
  id: string;
  email: string;
  phone: string;
  name: string;
  isNickNameSet?: boolean;
  deviceOS?: string;
  deviceVersion?: string;
  userId: string;
  subscriptionEndDate?: Date;
  theme: string;
  subscriptionStatus?: string;
  updateNotice106?: boolean;
}

/**
 * The object stored in the database
 */
export interface DatabaseUser {
  name: string;
  added: Timestamp;
  email: string;
  phone: string;
  isNickNameSet?: boolean;
  deviceOS?: string;
  deviceVersion?: string;
  state: UserState;
  group?: UserGroup;
  userId: string;
  subscriptionEndDate?: Date;
  subscriptionStatus?: string;
  updateNotice106?: boolean;
}

/**
 * The object used throughout the application
 */
export interface User {
  id: string;
  name: string;
  state: UserState;
  group?: UserGroup;
  isNickNameSet?: boolean;
  deviceOS?: string;
  deviceVersion?: string;
  DeleteAcount?: boolean;
  subscriptionEndDate?: Date;
  subscriptionStatus?: string;
  updateNotice106?: boolean;
}

/** The nested group data stored on a user */
export interface UserGroup {
  id: string;
  name: string;
  DeleteAcount?: boolean;
  defaultList: string;
  rollBackList: string;
  rollBackId: string;
  rollBackGroupName: string;
  subscriptionEndDate?: Date;
  subscriptionStatus?: string;
  updateNotice106?: boolean;
  ownerID: string;
  ownerName?: string;
  createdDate?: string;
  users?: string[];
  userDetails?: {
    id: string;
    name?: string;
  }[];
}

import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import linkifyHtml from "linkify-html";
import moment from "moment";
//import { EditPlanner } from "./EditPlanner";
import { AddPlanner } from "./AddPlanner";
import { WeekDays } from "Habits/components/HabitsPage/style";
import { DayOfTheWeek } from "Habits/components/DayOfTheWeek";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faClose,
  faImage,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Modal } from "Common";

export const PlannerSingelEvent = ({ data }) => {
  const { productId } = useParams();
  const event = data;
  const [title, setTitle] = useState(event?.name);
  const [description, setDescription] = useState(event?.note);
  const [isAllDay, setIsAllDay] = useState(event?.allDay);
  const [startTime, setStartTime] = useState(event?.startDate);
  const [endTime, setEndTime] = useState(event?.endDate);
  const [isEditing, setIsEditing] = useState(false);
  const [repeatDays, setRepeatDays] = useState({
    name: "days",
    days: event?.days,
  });
  const [repeatDaysxx, setRepeatDaysDummy] = useState({
    name: "days",
    days: event?.days,
  });

  var validatedHtmlDescription = DOMPurify.sanitize(description);
  var validatedHtmlTitle = DOMPurify.sanitize(title);
  const [isEventsModalEditOpen, setIsEventsModalEditOpen] = useState(false);
  const { t } = useTranslation();
  //console.log("what is startTime", startTime);

  const convertStartDate = new Date(startTime.seconds * 1000);
  const convertStartEnd = new Date(endTime.seconds * 1000);

  const translateRepeatValue = (data) => {
    if (data === "daily") {
      return t("planner-form-daily");
    }
    if (data === "weekly") {
      return t("planner-form-weekly");
    }
    if (data === "monthly") {
      return t("planner-form-monthly");
    }
    if (data === "yealy") {
      return t("planner-form-yearly");
    } else {
      return "";
    }
  };

  const convertDayofWeekFull = (data) => {
    if (data === 6) {
      return t("planner-form-sunday");
    }
    if (data === 0) {
      return t("planner-form-monday");
    }
    if (data === 1) {
      return t("planner-form-tuesday");
    }
    if (data === 2) {
      return t("planner-form-wednesday");
    }
    if (data === 3) {
      return t("planner-form-thursday");
    }
    if (data === 4) {
      return t("planner-form-friday");
    }
    if (data === 5) {
      return t("planner-form-saturday");
    }
  };

  const convertMonthlyPlace = (data) => {
    if (data === 1) {
      return t("planner-form-first");
    }
    if (data === 2) {
      return t("planner-form-second");
    }
    if (data === 3) {
      return t("planner-form-third");
    }
    if (data === 4) {
      return t("planner-form-fourth");
    }
    if (data === 5) {
      return t("planner-form-fifth");
    }
    if (data === 6) {
      return t("planner-form-sixth");
    }
  };

  const findDayAndWeek = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const dayLabel = (date.getDay() + 6) % 7;

    const firstDay = new Date(year, month, 1);
    const dayOfWeek = (firstDay.getDay() + 6) % 7; // Convert monday (0) to sunday 6
    const week = Math.ceil((day - dayOfWeek) / 7);

    // const dayShort = convertDayofWeek(dayLabel);
    //const dayFull = convertDayofWeekFull(dayOfWeek);
    const weekPlace = convertMonthlyPlace(week);
    const dayFull = convertDayofWeekFull(dayLabel);

    //console.log("what is day?", day);

    return { day, week, dayFull, weekPlace };
  };

  const close = () => {
    setIsEditing(false);
  };

  const convertDate = (date) => {
    if (moment(date).isValid()) {
      const update = new Date(date);
      const updateFormatted = moment(update).format("MMMM DD, YYYY");
      return updateFormatted;
    } else {
      const update = new Date(date.seconds * 1000);
      const updateFormatted = moment(update).format("MMMM DD, YYYY");
      return updateFormatted;
    }
    //  const update = date.toDate()
  };

  const getTimeFromDate = (date) => {
    if (moment(date).isValid()) {
      const update = new Date(date);
      const updateFormatted = moment(update).format("HH:mm");
      return updateFormatted;
    } else {
      const update = new Date(date.seconds * 1000);
      const updateFormatted = moment(update).format("HH:mm");
      return updateFormatted;
    }
  };

  useEffect(() => {
    // console.log("what is the event", data);
    window.scrollTo(0, 0);
    setTitle(event.name);
    setDescription(event.note);
    setStartTime(event?.startDate);
    setEndTime(event?.endDate);
    setRepeatDays({
      name: "days",
      days: event?.days,
    });
    validatedHtmlDescription = DOMPurify.sanitize(description);
    validatedHtmlTitle = DOMPurify.sanitize(title);

    return () => {};
  }, [event, data, isEditing]);

  return (
    <div style={{ backgroundColor: "blue !important" }}>
      <div
        style={{
          paddingTop: 10,
          paddingRight: 0,
          textAlign: "right",
          //backgroudColor: event.color,
        }}
      >
        <button
          style={{
            cursor: "pointer",

            fontFamily: "inherit",
            fontSize: 16,
            border: 0,
            fontWeight: "bold",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: 10,
            color: "blue",
            backgroundColor: "transparent",
          }}
          type="button"
          onClick={() => setIsEditing(true)}
        >
          {t("common-button-edit")}
        </button>
      </div>
      <div
        style={{
          textAlign: "center",
          paddingBottom: 5,
          fontSize: 20,
          fontStyle: "italic",
        }}
      >
        {convertDate(startTime)}
        <hr style={{ opacity: 0.6 }} />
      </div>

      <div
        style={{ padding: 20, borderRadius: 10, backgroundColor: event.color }}
      >
        <label>{t("planner-form-name")}</label>
        <div
          style={{
            width: "85%",
            fontSize: 20,
            marginTop: 0,
            fontFamily: "inherit",
            // padding: 5,
            paddingTop: 0,
            paddingBottom: 20,
            border: 0,
            //  color: "navy",
            backgroundColor: "transparent",
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: linkifyHtml(validatedHtmlTitle),
            }}
          />
        </div>
        {/*}  <label style={{paddingTop: 5, paddingBottom: 0,  fontSize:16,  color: "grey"}}>Details</label>*/}
        <label>{t("planner-form-details")}</label>
        <div
          style={{
            width: "85%",
            fontSize: 20,
            paddingTop: 0,
            marginTop: 0,
            paddingBottom: 20,
            backgroundColor: "transparent",
            //  color: "navy",
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: linkifyHtml(validatedHtmlDescription),
            }}
          />
        </div>
        {/*   <div style={{ paddingTop: 10 }} />
        <label>Type</label>
        {event.isTask ? "Task" : "Event"}*/}
        <div style={{ paddingTop: 0 }} />
        <label>{t("planner-landing-start-label")}</label>
        {convertDate(startTime)} @ {getTimeFromDate(startTime)}
        <div style={{ paddingTop: 20 }} />
        <label>{t("planner-landing-end-label")}</label>
        {convertDate(endTime)} @ {getTimeFromDate(endTime)}
        <div style={{ paddingTop: 0 }} />
        {event?.isRepeats ? (
          <div style={{ paddingTop: 20 }}>
            <label>{t("planner-landing-repeats-label")}</label>
            <span style={{ textTransform: "capitalize" }}>
              {" "}
              {translateRepeatValue(event?.repeatValue)}
            </span>
            {event?.repeatValue == "monthly"
              ? event?.isRepeats && (
                  <div>
                    {event?.repeatMonthlyValue === "day" ? (
                      <span style={{}}>
                        on the{" "}
                        {
                          findDayAndWeek(new Date(startTime.seconds * 1000))
                            .weekPlace
                        }{" "}
                        {
                          findDayAndWeek(new Date(startTime.seconds * 1000))
                            .dayFull
                        }
                      </span>
                    ) : (
                      <div> by {event?.repeatMonthlyValue}</div>
                    )}
                  </div>
                )
              : null}
          </div>
        ) : null}
        {event?.isRepeats && event?.repeatValue == "weekly" ? (
          <div>
            <div style={{ paddingTop: 20 }} />
            <label>{t("planner-landing-repeatsdays")}</label>
            <div style={{ paddingBottom: 10 }} />
            <WeekDays>
              {Array.from(Array(7).keys()).map((element, index) => (
                <div style={{ paddingLeft: 5 }} key={index}>
                  <DayOfTheWeek
                    key={element}
                    state={repeatDays}
                    createHabit={false}
                    weekday={element}
                  />
                </div>
              ))}
            </WeekDays>
            {event?.isRepeats && event?.repeatValue == "weekly" ? (
              <div style={{ paddingTop: 20 }}>
                <label>
                  {t("planner-form-interval")}{" "}
                  {event?.repeatWeeklyIntervalValue}{" "}
                </label>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>

      <Modal
        isOpen={isEditing}
        onRequestClose={close}
        style={{ overlay: { zIndex: 100 } }}
      >
        <AddPlanner
          event={event}
          closeModal={close}
          start={convertStartDate}
          end={convertStartEnd}
          isEdit={true}
        />
      </Modal>
      <div style={{ paddingBottom: 100 }} />
    </div>
  );
};

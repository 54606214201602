import { useEffect, useState } from "react";
import { collection, query, onSnapshot } from "@firebase/firestore";
import { UserContext, useUserContext } from "Authentication";
import { ConfirmationModal } from "Common";
import { ItemModel } from "./models";

export function useItemSubscription() {
  const { getDefaultItemsCollection, checkGroupAccess } = useUserContext();
  const [items, setItems] = useState<ItemModel[]>([]);
  const [addedItems, setAddedItems] = useState<ItemModel[]>([]);
  const [unaddedItems, setUnaddedItems] = useState<ItemModel[]>([]);
  const [isGroupPermissionOK, setIsGroupPremissionOK] = useState(true);
  //const { removeGroupFromMemberByOwner } = useUserContext();

  const checkStatus = () => {
    checkGroupAccess()
      //@ts-ignore
      .then((hasAccess) => {
        //console.log("User with userId 'ABC' exists:", hasAccess);
        setIsGroupPremissionOK(hasAccess);
        // console.log("what is the status", hasAccess);
        if (hasAccess === false) {
          // removeGroupFromMemberByOwner("123");
        }
      })
      .catch((error) => {
        // Handle error if any
        console.log("Error checking group access:", error);
      });
  };

  useEffect(() => {
    // console.log("did I fire?");

    setAddedItems(items.filter((i) => i.added));
    setUnaddedItems(items.filter((i) => !i.added));
  }, [items]);

  useEffect(() => {
    checkStatus();
    const plannerCollection = getDefaultItemsCollection();
    const plannerQuery = query(plannerCollection);

    const unsubscribe = onSnapshot(
      plannerQuery,
      (snapshot) => {
        const addedEvents: ItemModel[] = [];

        snapshot.docs.forEach((doc) => {
          const item = { ...doc.data(), id: doc.id } as ItemModel;
          addedEvents.push(item);
        });

        setItems(addedEvents);
      },
      (error) => {
        window.location.reload();
      }
    );

    return () => unsubscribe();
  }, [getDefaultItemsCollection]);

  return { addedItems, unaddedItems, allItems: items, isGroupPermissionOK };
}

import React from "react";
import { MdSearch } from "react-icons/md";
import classes from "../notes.module.css";
import { useTranslation } from "react-i18next";

export const Search = ({ handleSearchNote }) => {
  const { t } = useTranslation();
  return (
    <div className={classes.search}>
      <MdSearch
        className="search-icons"
        size="1.3em"
        style={{ paddingLeft: 10 }}
      />
      <input
        onChange={(event) => handleSearchNote(event.target.value.toLowerCase())}
        style={{ fontSize: "inherit", fontFamily: "inherit" }}
        type="text"
        placeholder={t("common-placeholder-search")}
      />
    </div>
  );
};

import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getStorage,
  ref,
  deleteObject,
  getDownloadURL,
  uploadBytes,
  uploadBytesResumable,
} from "@firebase/storage";
import { onSnapshot } from "@firebase/firestore";
import {
  faExclamationCircle,
  faClose,
  faImage,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import cx from "classnames/bind";

import { Button, Modal } from "Common";
import { EventModel } from "../models/events";
import { useHistory } from "react-router-dom";
import classes from "./EventsModal.module.css";
import { useUserContext } from "Authentication";
import DateTimePicker from "react-datetime-picker";
import { storage } from "Config";
import Linkify from "linkify-react";
import linkifyHtml from "linkify-html";
import TextareaAutosize from "react-textarea-autosize";
import { ConfirmationModal } from "Common";
import { CirclePicker, GithubPicker } from "react-color";
import {
  BtnBold,
  BtnItalic,
  Editor,
  BtnBulletList,
  HtmlButton,
  BtnNumberedList,
  BtnUnderline,
  BtnStrikeThrough,
  EditorProvider,
  Toolbar,
} from "react-simple-wysiwyg";
import { useTranslation } from "react-i18next";
import { CountdownTimer } from "Common/Timer/CountdownTimer";

const classnames = cx.bind(classes);

interface EventsModalEditProps {
  isOpen: boolean;
  event: EventModel | undefined;
  isDarkMode: boolean;
  isOnline: boolean;
  close: () => void;
}

export const EventsModalEdit = (props: EventsModalEditProps) => {
  const { isOpen, isDarkMode, event, isOnline, close } = props;
  const [title, setTitle] = useState(event?.title);
  const [description, setDescription] = useState(event?.description);
  const [isAllDay, setIsAllDay] = useState(event?.allDay);
  const [startTime, setStartTime] = useState(event?.start);
  const [endTime, setEndTime] = useState(event?.end);
  const [imageURL, setImageURL] = useState(event?.imageURL);
  const [imageCaption, setImageCaption] = useState(
    event.imageCaption != undefined ? event.imageCaption : ""
  );
  // const [startTime, setStartTime] = useState(event.start.toString())
  // const [endTime, setEndTime] = useState(event.end.toString())
  const [addedBy, setAddedBy] = useState(event?.addedBy);
  // const { deleteItem, removeItem, setItemFlag, setItemFavorite, updateItem } = useItems()
  const user = useUserContext();
  const { updateEventCal, deleteEventCal, updateEventCalModal } =
    useUserContext();
  // var name = item?.name
  const [tempImage, setTempImage] = useState("");
  const [isAddingImage, setIsAddImage] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isOpenImage, setIsOpenImage] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  const [options, setOptions] = useState(false);

  const history = useHistory();

  const { t } = useTranslation();
  const [color, setColor] = useState(
    event?.color != undefined || null || NaN ? event?.color : "snow"
  );
  const [progress, setProgress] = useState(0);
  const formHandler = (e) => {
    e.preventDefault();
    setIsUploading(true);

    // Handle removing the temporary image if it exists
    if (tempImage !== "") {
      const storageRef = ref(storage, tempImage);
      deleteObject(storageRef)
        .then(() => {
          // File deleted successfully
          console.log("Removed temporary image:", tempImage);
        })
        .catch((error) => {
          console.error("Error deleting temporary image:", error);
        });
    }

    // Get the selected file from the form
    const file = e.target.files[0];

    // Upload the file using the 'uploadFiles' function
    uploadFiles(file);
  };

  const handleColorChangeComplete = (color, event) => {
    //	console.log("which color", color.hex)
    setColor(color.hex);
    //setIsColorPickerOpen(false)
  };

  const Newcolors = [
    "snow",
    "azure",
    "floralwhite",
    "#EB9694",
    "pink",
    "#FAD0C3",
    "#fef68a",
    "#C1E1C5",
    "#BEDADC",
    "#C4DEF6",
    "#BED3F3",
    "#D4C4FB",
    "plum",
  ];

  const closeImage = () => {
    setIsOpenImage(false);
  };

  const uploadFiles = (file) => {
    // Set the temporary image URL
    setTempImage(`images/${user.user.id}/${startTime.toString()}/${file.name}`);

    // Create a reference to the file location in Cloud Storage
    const storageRef = ref(
      storage,
      `images/${user.user.id}/${startTime.toString()}/${file.name}`
    );

    // Upload the file to Cloud Storage
    const uploadTask = uploadBytesResumable(storageRef, file);

    // Monitor the upload task's progress and state changes
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error("Error uploading file: ", error);
        setIsUploading(false);
        setProgress(0);
      },
      () => {
        // Upload complete, get download URL
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setImageURL(url);
          setIsUploading(false);
          setProgress(0);
          setIsAddImage(false);
          setIsImage(true);
          updateEventCalModal(event.id, { imageURL: imageURL });
        });
      }
    );
  };

  //image uploader end

  const removeImage = () => {
    if (tempImage != "") {
      const storageRef = ref(storage, tempImage);
      deleteObject(storageRef)
        .then(() => {
          // File deleted successfully
          // console.log("remove image", tempImage);
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
        });
    }
    updateEventCalModal(event.id, { imageURL: imageURL });
    setTempImage("");
    setImageURL("");
    setIsImage(false);
    setProgress(0);
    setIsUploading(false);

    // console.log("what is image at delete", imageURL)
  };

  const SetNewStartTime = (e) => {
    const newTime = e;
    if (newTime < startTime) {
      setStartTime(newTime);
      //  console.log("what Time", e)
    }
  };

  const SetNewEndTime = (e) => {
    const newTime = e;
    if (newTime > startTime) {
      setEndTime(newTime);
      // console.log("what Time", e)
    }
  };

  //console.log("image url", imageURL)

  //Auto Save after 10 seconds
  const [count, setCount] = useState(10);
  const [autoSaveTimer, setAutoSaveTimer] = useState<NodeJS.Timeout | null>(
    null
  );

  // Function to handle auto-saving
  const handleAutoSave = () => {
    // Clear any existing auto-save timer
    if (autoSaveTimer) {
      clearTimeout(autoSaveTimer);
    }
    setCount(10);
    // Start a new auto-save timer
    const timer = setTimeout(() => {
      // Perform the auto-save action

      handleSubmit();
    }, 10000); // 10 seconds

    // Set the new timer
    setAutoSaveTimer(timer);
  };

  useEffect(() => {
    handleAutoSave();
    // Cleanup function to clear the auto-save timer on unmount or when title/description changes
    return () => {
      if (autoSaveTimer) {
        clearTimeout(autoSaveTimer);
      }
    };
  }, [title, description]);

  useEffect(() => {
    // Prevents old values being displayed when modal fades out.
    if (isOpen) {
      setTitle(event.title);
      setDescription(event.description);
      setIsAllDay(event?.allDay);
      setStartTime(event?.start);
      setEndTime(event?.end);
      setImageURL(event?.imageURL);
      setImageCaption(
        event.imageCaption != undefined ? event.imageCaption : ""
      );
      setColor(event?.color);
      // setStartTime(event.start.toString())
      // setEndTime(event.end.toString())
      setAddedBy(event.addedBy);
    }
  }, [event, isOpen]);

  async function handleSubmit(e?: React.FormEvent<HTMLFormElement>) {
    if (e) {
      e.preventDefault();
    }

    if (title !== event?.title && event?.id) {
      updateEventCalModal(event.id, { title: title });
      // console.log("did this update?  Title")
    }

    if (description !== event?.description && event?.id) {
      updateEventCalModal(event.id, { description: description });
      //   console.log("did this update?  description")
      //addedBy: user.user.name
    }
    var isImageChanged = false;
    if (imageURL !== event?.imageURL && event?.id) {
      updateEventCalModal(event.id, { imageURL: imageURL });
      isImageChanged = true;
      // console.log("did this update?",  imageURL)
      //addedBy: user.user.name
    }

    if (imageCaption !== event?.imageCaption && event?.id) {
      updateEventCalModal(event.id, { imageCaption: imageCaption });

      // console.log("did this update?",  imageURL)
      //addedBy: user.user.name
    }

    if (color !== event?.color && event?.id) {
      updateEventCalModal(event.id, { color: color });
      // console.log("did this update?",  imageURL)
      //addedBy: user.user.name
    }

    if (startTime !== event?.start && event?.id) {
      updateEventCalModal(event.id, { start: startTime });
      //    console.log("did this update?", startTime)
      //addedBy: user.user.name
    }

    if (endTime !== event?.end && event?.id) {
      updateEventCalModal(event.id, { end: endTime });
      //   console.log("did this update?", endTime)
      //addedBy: user.user.name
    }

    if (isAllDay !== event?.allDay && event?.id) {
      updateEventCalModal(event.id, { allDay: isAllDay });
      //   console.log("did this update?  is all day")
      //   console.log("value", isAllDay)
      //addedBy: user.user.name
    }
    if (e) {
      history.goBack();
    }
  }

  async function handleDelete() {
    event?.id && (await deleteEventCal(event?.id));
    history.goBack();
  }

  function handleIsAllDay() {
    setIsAllDay((f) => !f);
    //   event?.id && setIsAllDay(event.id, !isAllDay)

    event?.id && setIsAllDay(!isAllDay);
  }

  const DeleteItemConfirmation = () => {
    handleDelete();
  };

  const CloseConfirmation = () => {
    history.goBack();
  };

  const SaveItemConfirmation = (e: React.FormEvent<HTMLFormElement>) => {
    handleSubmit(e);
  };

  const toolbar = {
    container: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ color: [] }, { background: [] }], // add color and background tools
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };

  const modules = {
    toolbar: toolbar,
  };

  const handleContentChange = (value) => {
    setTitle(value);
    if (value.length > 11) {
      setEnableButton(true);
    } else {
      setEnableButton(false);
    }

    // console.log("what is the length", value.length, title);
  };

  const handleContentChangeDescription = (value) => {
    setDescription(value);
    if (title.length > 11) {
      setEnableButton(true);
    } else {
      setEnableButton(false);
    }

    // console.log("what is the length", value.length, title);
  };

  const textColor = isDarkMode ? "black" : "black";

  const handleAddImage = () => {
    setIsAddImage(true);
    if ((window as any).isIOS) {
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "requestCameraPermission",
          // value: "on",
        })
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={CloseConfirmation}
      style={{ overlay: { zIndex: 100 } }}
    >
      <Modal.Body>
        <div
          style={{
            border: 0,
            borderStyle: "none",
            padding: 0,
            filter: isDarkMode ? "" : "invert(100%)",
          }}
          data-openreplay-obscured
        >
          <div
            style={{
              marginTop: -20,
              paddingLeft: 0,
              paddingRight: 0,
              paddingBottom: 0,
              border: 1,
              borderColor: "grey",
              borderRadius: 10,
              backgroundColor: color,
              fontWeight: "bold",
              color: textColor,
              boxShadow: "2px 3px 3px grey, 0 0 5px #FCFBF4 inset",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "right",
                marginTop: -10,
                marginRight: 10,
              }}
            >
              <FontAwesomeIcon
                icon={faClose}
                size="1x"
                onClick={CloseConfirmation}
                style={{ marginTop: 10, marginRight: 5, cursor: "pointer" }}
              />
            </div>

            {isAddingImage ? (
              <div style={{ padding: 20 }}>
                <p>{t("journal-form-fileformats")} </p>
                <p
                  style={{
                    fontSize: "inherit",
                    marginTop: -15,
                    paddingBottom: 20,
                  }}
                >
                  {t("journal-form-filesize")}{" "}
                </p>
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png, gif"
                  className="input"
                  required
                  onChange={formHandler}
                  style={{
                    paddingBottom: 20,
                    fontSize: "inherit",
                    fontFamily: "inherit",
                  }}
                />

                <hr />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <button
                    style={{
                      fontFamily: "inherit",
                      fontSize: "inherit",
                      fontWeight: "bold",
                      border: 0,
                      paddingLeft: 40,
                      paddingRight: 40,
                      color: "blue",
                      cursor: "pointer",
                      backgroundColor: "transparent",
                    }}
                    onClick={() => setIsAddImage(false)}
                  >
                    {t("common-button-cancel")}
                  </button>
                </div>
                {isUploading ? (
                  <p>
                    {t("journal-form-uploading")} {progress}%
                  </p>
                ) : null}
              </div>
            ) : null}

            {!isAddingImage ? (
              <form onSubmit={SaveItemConfirmation}>
                <p
                  style={{ fontSize: 24, textAlign: "center", marginTop: -10 }}
                >
                  {t("journal-form-edit-title")}{" "}
                </p>
                <p
                  style={{
                    fontSize: 14,
                    paddingTop: 5,
                    textAlign: "center",
                    marginTop: -20,
                    lineHeight: 1.3,
                  }}
                >
                  {t("journal-form-edit-subtitle")}
                </p>
                <hr />

                <div
                  style={{
                    backgroundColor: "snow",
                    padding: 5,
                    borderRadius: 10,
                    // color: "black",
                  }}
                >
                  <EditorProvider>
                    <Editor
                      value={title}
                      placeholder={t("journal-form-text-placeholder")}
                      containerProps={{
                        style: {
                          backgroundColor: "snow",
                          resize: "both",
                          overflow: "auto",
                          color: isDarkMode ? "darkred" : "black",
                        },
                      }}
                      onChange={(e) => setTitle(e.target.value)}
                    >
                      {" "}
                      <Toolbar>
                        <BtnBold />
                        <BtnItalic />
                        <BtnUnderline />
                        <BtnBulletList />
                        <BtnNumberedList />
                        <BtnStrikeThrough />
                        <HtmlButton />
                      </Toolbar>
                    </Editor>
                  </EditorProvider>
                  {event.title != title ? (
                    <p
                      // className="blinkingButton"
                      style={{
                        textAlign: "center",
                        // color: "white",
                        color: "firebrick",
                        fontWeight: "bold",
                        fontSize: 16,
                        // marginTop: -10,
                      }}
                    >
                      Your changes are not yet saved. Automatic saving will
                      occur in{" "}
                      <CountdownTimer count={count} setCount={setCount} />{" "}
                      seconds.
                    </p>
                  ) : null}
                </div>
                {imageURL != "" || undefined || null ? (
                  <div
                    style={{ paddingBottom: 20, padding: 5 }}
                    // onClick={() => setIsOpenImage(true)}
                  >
                    {" "}
                    <div className="polaroid">
                      <img
                        src={imageURL}
                        width="245px"
                        style={{
                          borderRadius: 10,
                          //filter: !isDarkMode ? "invert(100%)" : "none",
                        }}
                      />
                      <span
                        style={{
                          fontSize: 12,
                          fontFamily: "inherit",
                          color: "grey",
                        }}
                      >
                        {imageCaption}
                      </span>
                    </div>
                  </div>
                ) : null}

                {imageURL != "" || undefined || null ? (
                  <div style={{ paddingTop: 0, paddingBottom: 10 }}>
                    <label
                      style={{
                        paddingTop: 10,
                        paddingBottom: 5,
                        paddingLeft: 5,
                      }}
                    >
                      {t("journal-form-captiontext")}
                    </label>
                    <input
                      type="text"
                      value={imageCaption}
                      style={{
                        fontSize: 16,
                        fontFamily: "inherit",
                        padding: 10,
                        marginLeft: 5,
                      }}
                      onChange={(e) => setImageCaption(e.target.value)}
                    />
                  </div>
                ) : null}

                {description != undefined && description != "" ? (
                  <>
                    {" "}
                    <label
                      style={{
                        fontStyle: "inherit",
                        paddingTop: 20,
                        paddingBottom: 20,
                      }}
                    >
                      {t("journal-form-additional-text")}
                    </label>
                    <div
                      style={{
                        display: "flex !important",
                        backgroundColor: "lightyellow",
                        padding: 10,
                      }}
                    >
                      <EditorProvider>
                        <Editor
                          value={title}
                          placeholder={t("journal-form-text-placeholder")}
                          containerProps={{
                            style: { backgroundColor: color, resize: "both" },
                          }}
                          onChange={(e) => setDescription(e.target.value)}
                        >
                          <Toolbar>
                            <BtnBold />
                            <BtnItalic />
                            <BtnUnderline />
                            <BtnBulletList />
                            <BtnNumberedList />
                            <BtnStrikeThrough />
                            <HtmlButton />
                          </Toolbar>
                        </Editor>
                      </EditorProvider>
                    </div>
                  </>
                ) : null}
                {options ? (
                  <>
                    <div style={{ paddingBottom: 20 }}>
                      {isOnline ? (
                        <button
                          onClick={handleAddImage}
                          style={{
                            fontFamily: "inherit",
                            fontSize: "inherit",
                            border: 0,
                            backgroundColor: "transparent",
                            borderStyle: "none",
                            paddingTop: 20,
                            paddingBottom: 10,
                            color: textColor,
                            cursor: "pointer",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faImage}
                            size="2x"
                            style={{
                              paddingRight: 10,
                              fontSize: 20,
                              color: textColor,
                            }}
                          />
                          {t("journal-form-upload-image")}
                        </button>
                      ) : null}
                      {imageURL != "" || undefined || null ? (
                        <button
                          onClick={removeImage}
                          style={{
                            fontFamily: "inherit",
                            fontSize: "inherit",
                            border: 0,
                            backgroundColor: "transparent",
                            borderStyle: "none",
                            paddingTop: 10,
                            paddingBottom: 10,
                            marginLeft: 0,
                            cursor: "pointer",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            size="2x"
                            style={{ paddingRight: 5, fontSize: 20 }}
                          />
                          {t("journal-form-delete-image")}
                        </button>
                      ) : null}
                    </div>

                    <div style={{ paddingTop: 30, paddingLeft: 10 }} />
                    <label
                      style={{
                        paddingTop: 10,
                        paddingBottom: 15,
                        paddingLeft: 10,
                      }}
                    >
                      {t("journal-form-color")}
                    </label>
                    <div style={{ padding: 10 }}>
                      <GithubPicker
                        onChangeComplete={handleColorChangeComplete}
                        width={150}
                        clearIcon={null}
                        colors={Newcolors}
                      />
                    </div>

                    <button
                      onClick={() => setOptions(false)}
                      style={{
                        textDecoration: "underline",
                        backgroundColor: "transparent",
                        color: "blue",
                        border: 0,
                        padding: 10,
                        fontSize: 14,
                        fontFamily: "inherit",
                        cursor: "pointer",
                      }}
                    >
                      {t("journal-form-hide")}
                    </button>
                  </>
                ) : (
                  <button
                    onClick={() => setOptions(true)}
                    style={{
                      textDecoration: "underline",
                      backgroundColor: "transparent",
                      color: "blue",
                      border: 0,
                      fontSize: 14,
                      fontFamily: "inherit",
                      cursor: "pointer",
                    }}
                  >
                    {t("journal-form-showoptions")}
                  </button>
                )}

                <div className={classes.modalActions}>
                  <button
                    type="button"
                    onClick={() => setConfirmModalOpen(true)}
                    style={{
                      backgroundColor: "tomato",
                      cursor: "pointer",
                      border: 0,
                    }}
                    //   className={classes.secondaryButton}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      size="2x"
                      style={{ fontSize: 20, paddingRight: 2 }}
                    />
                  </button>

                  {/*}   <button
              type="button"
              onClick={close}
              style={{cursor:"pointer"}}
             // className={classes.secondaryButton}
            >
              Close
    </button>*/}
                  <button
                    // disabled={title.length == 0}
                    style={{
                      fontFamily: "inherit",
                      fontSize: "inherit",
                      fontWeight: "bold",
                      border: 0,
                      cursor: "pointer",

                      width: "65%",
                      //  opacity: enableButton ? 1 : 0.3,
                    }}
                    type="submit"
                  >
                    {t("common-button-update")}
                  </button>
                </div>
                <div style={{ paddingBottom: 200 }} />
              </form>
            ) : null}

            <div style={{ paddingBottom: 50 }} />
          </div>
        </div>
      </Modal.Body>
      <Modal
        isOpen={isOpenImage}
        onRequestClose={closeImage}
        style={{ overlay: { zIndex: 100 } }}
      >
        <div style={{ padding: 0 }}>
          <div
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              paddingBottom: 0,
              border: 1,
              borderColor: "grey",
              borderRadius: 10,
              backgroundColor: "snow",
              fontWeight: "bold",
              color: "grey",
              boxShadow: "2px 3px 3px grey, 0 0 5px #FCFBF4 inset",
              fontSize: 16,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "right",
                marginTop: -10,
                marginRight: 10,
              }}
            >
              <FontAwesomeIcon
                icon={faClose}
                size="2x"
                onClick={closeImage}
                style={{ marginTop: 15, cursor: "pointer" }}
              />
            </div>
            <div className="polaroid">
              <img
                src={imageURL}
                width="100%"
                style={{ borderRadius: 10, paddingTop: 20 }}
              />
              <p>{event.imageCaption != undefined ? event.imageCaption : ""}</p>
            </div>
          </div>
          <div style={{ paddingTop: 10, paddingRight: 0, textAlign: "center" }}>
            <button
              style={{
                cursor: "pointer",

                fontFamily: "inherit",
                fontSize: 20,
                border: 0,
                fontWeight: "bold",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: 10,
                color: "blue",
                backgroundColor: "transparent",
                textDecoration: "underline",
              }}
              type="button"
              onClick={() => closeImage()}
            >
              {t("common-button-close")}
            </button>
          </div>
          <div style={{ paddingBottom: 50 }} />
        </div>
      </Modal>
      {confirmModalOpen ? (
        <ConfirmationModal
          isOpen={confirmModalOpen}
          text={t("journal-form-deleteconfirmation")}
          onClickCancel={() => setConfirmModalOpen(false)}
          onClickConfirm={DeleteItemConfirmation}
        />
      ) : null}
      <div style={{ paddingTop: 0, paddingRight: 0, textAlign: "center" }}>
        <button
          style={{
            cursor: "pointer",

            fontFamily: "inherit",
            fontSize: 20,
            border: 0,
            fontWeight: "bold",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: 10,
            color: "blue",
            backgroundColor: "transparent",
            textDecoration: "underline",
          }}
          type="button"
          onClick={() => CloseConfirmation()}
        >
          {t("common-button-close")}
        </button>
      </div>
      <div style={{ paddingBottom: 50 }} />
    </Modal>
  );
};
